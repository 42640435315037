import { createEntityAdapter, createSlice, type PayloadAction } from "@reduxjs/toolkit";
import { isDate } from "lodash";
import { type DeepPartial } from "react-hook-form";
import { all } from "redux-saga/effects";

import { DetailsTableDataTypeEnum, MainTableDataTypeEnum } from "@ds/modules/table-data/utils/model";

import { getIds } from "@ds/utils/entities";
import { LoadStatus, type BaseMeta, type PatchedModel } from "@ds/utils/reducer";
import { type SagaOptions } from "@ds/utils/saga-helpers";

import { DeploymentTableTypeEnum, isDeployment } from "../utils/model";
import { normalize } from "../utils/normalizer";
import { watchDeployments } from "./sagas";

export enum DeploymentsRemoteOperation {
  CRUD = "crud",
  CHECKBOXES = "checkboxes",
  DEPLOYING = "deploying",
  IOT_FETCH = "iot_fetch",
}

export type DeploymentsMeta = BaseMeta & {
  remoteOperation: DeploymentsRemoteOperation;
  filters?: KeysToCamelCase<DeploymentFilters>;
};

interface DeploymentsState {
  viewType: DeploymentTableTypeEnum | undefined;
  loadStatusMap: {
    [DeploymentsRemoteOperation.CRUD]: LoadStatus;
    [DeploymentsRemoteOperation.CHECKBOXES]: LoadStatus;
    [DeploymentsRemoteOperation.DEPLOYING]: LoadStatus;
    [DeploymentsRemoteOperation.IOT_FETCH]: LoadStatus;
  };

  error: string | null;

  entities: {
    [deploymentId: number]: Deployment;
  };

  ids: number[];
  tableIds: Record<string, number[]>;
  selectedIds: Record<string, number[]>;
}

const entityAdapter = createEntityAdapter<Deployment>();

export const initialState: DeploymentsState = entityAdapter.getInitialState({
  viewType: undefined,
  loadStatusMap: {
    [DeploymentsRemoteOperation.CRUD]: LoadStatus.Idle,
    [DeploymentsRemoteOperation.CHECKBOXES]: LoadStatus.Idle,
    [DeploymentsRemoteOperation.DEPLOYING]: LoadStatus.Idle,
    [DeploymentsRemoteOperation.IOT_FETCH]: LoadStatus.Idle,
  },

  error: null,

  tableIds: { [MainTableDataTypeEnum.Deployments]: [] },
  selectedIds: { [MainTableDataTypeEnum.Deployments]: [] },
}) as DeploymentsState;

const getTableTypeOrDefault = (
  tableType: MainTableDataTypeEnum | DetailsTableDataTypeEnum = MainTableDataTypeEnum.Deployments,
) => tableType;

const removeEntities = (state: DeploymentsState, payload: number[]) => {
  entityAdapter.removeMany(state, payload);

  state.tableIds[MainTableDataTypeEnum.Deployments] = state.tableIds[MainTableDataTypeEnum.Deployments].filter(
    id => !payload.includes(id),
  );

  state.selectedIds[MainTableDataTypeEnum.Deployments] = state.selectedIds[MainTableDataTypeEnum.Deployments].filter(
    id => !payload.includes(id),
  );
};

const sliceType = "DEPLOYMENTS";
const slice = createSlice({
  name: sliceType,
  initialState,
  reducers: {
    switchView: (state, { payload }: PayloadAction<DeploymentTableTypeEnum.Deployments>) => {
      state.viewType = payload;
    },
    fetchDeployments: {
      reducer(state, { meta }: PayloadAction<undefined, string, DeploymentsMeta>) {
        state.loadStatusMap[meta.remoteOperation] = LoadStatus.Loading;
        state.error = null;

        if (meta.remoteOperation === DeploymentsRemoteOperation.CHECKBOXES) {
          state.selectedIds[getTableTypeOrDefault(meta.options?.tableType)] =
            state.tableIds[getTableTypeOrDefault(meta.options?.tableType)];
        }
      },
      prepare(
        filters: KeysToCamelCase<DeploymentFilters> = {},
        options: SagaOptions = {},
        remoteOperation: DeploymentsRemoteOperation = DeploymentsRemoteOperation.CRUD,
      ) {
        return {
          payload: undefined,
          meta: { remoteOperation, filters, options },
        };
      },
    },
    fetchDeploymentsFailed: {
      reducer(state, { meta, error }: PayloadAction<undefined, string, DeploymentsMeta, string>) {
        state.loadStatusMap[meta.remoteOperation] = LoadStatus.Failed;
        state.error = error;

        if (meta.remoteOperation === DeploymentsRemoteOperation.CHECKBOXES) {
          state.selectedIds[getTableTypeOrDefault(meta.options?.tableType)] = [];
        }
      },
      prepare(meta: DeploymentsMeta, { message }: ErrorLike) {
        return { payload: undefined, meta, error: message };
      },
    },
    fetchDeploymentsSucceeded: {
      reducer(state, { payload, meta }: PayloadAction<QueryOutput<Deployment>, string, DeploymentsMeta>) {
        state.loadStatusMap[meta.remoteOperation] = LoadStatus.Succeeded;
        state.error = null;

        entityAdapter.setMany(state, payload.items);

        if (meta.remoteOperation === DeploymentsRemoteOperation.CHECKBOXES) {
          state.selectedIds[getTableTypeOrDefault(meta.options?.tableType)] = payload.items.map(({ id }) => id);
        } else if (meta.options?.tableType) {
          state.tableIds[getTableTypeOrDefault(meta.options?.tableType)] = payload.items.map(({ id }) => id);
          state.selectedIds[getTableTypeOrDefault(meta.options?.tableType)] = state.selectedIds[
            getTableTypeOrDefault(meta.options?.tableType)
          ].filter(id => state.ids.includes(id));
        }
      },
      prepare(payload: QueryOutput<Deployment>, meta: DeploymentsMeta) {
        return { payload, meta };
      },
    },
    fetchDeployment: {
      reducer(state, { meta }: PayloadAction<number, string, DeploymentsMeta>) {
        state.loadStatusMap[meta.remoteOperation] = LoadStatus.Loading;
        state.error = null;
      },
      prepare(payload: number, options: SagaOptions = {}) {
        return {
          payload,
          meta: { remoteOperation: DeploymentsRemoteOperation.CRUD, options },
        };
      },
    },
    fetchDeploymentFailed: {
      reducer(state, { meta, error }: PayloadAction<number, string, DeploymentsMeta, string>) {
        state.loadStatusMap[meta.remoteOperation] = LoadStatus.Failed;
        state.error = error;
      },
      prepare(payload: number, meta: DeploymentsMeta, { message }: ErrorLike) {
        return { payload, meta, error: message };
      },
    },
    fetchDeploymentSucceeded: {
      reducer(state, { payload, meta }: PayloadAction<Deployment, string, DeploymentsMeta>) {
        state.loadStatusMap[meta.remoteOperation] = LoadStatus.Succeeded;
        state.error = null;

        entityAdapter.setOne(state, payload);
      },
      prepare(payload: Deployment, meta: DeploymentsMeta) {
        return { payload, meta };
      },
    },
    createDeployment: {
      reducer(state, { meta }: PayloadAction<Deployment, string, DeploymentsMeta>) {
        state.loadStatusMap[meta.remoteOperation] = LoadStatus.Loading;
        state.error = null;
      },
      prepare(payload: Deployment, meta: DeploymentsMeta = { remoteOperation: DeploymentsRemoteOperation.CRUD }) {
        return { payload, meta };
      },
    },
    createDeploymentFailed: {
      reducer(state, { meta, error }: PayloadAction<Deployment, string, DeploymentsMeta, string>) {
        state.loadStatusMap[meta.remoteOperation] = LoadStatus.Failed;
        state.error = error;
      },
      prepare(payload: Deployment, meta: DeploymentsMeta, { message }: ErrorLike) {
        return { payload, meta, error: message };
      },
    },
    createDeploymentSucceeded: {
      reducer(state, { payload, meta }: PayloadAction<Deployment, string, DeploymentsMeta>) {
        state.loadStatusMap[meta.remoteOperation] = LoadStatus.Succeeded;
        state.error = null;

        entityAdapter.setOne(state, payload);
      },
      prepare(payload: Deployment, meta: DeploymentsMeta) {
        return { payload, meta };
      },
    },
    updateDeployments: {
      reducer(state, _action: PayloadAction<PatchedModel<Deployment>>) {
        state.loadStatusMap[DeploymentsRemoteOperation.CRUD] = LoadStatus.Loading;
        state.error = null;
      },
      prepare(changedIds: number[], changedFields: DeepPartial<Deployment>) {
        return { payload: { ids: changedIds, data: changedFields } };
      },
    },
    updateDeploymentsFailed: {
      reducer(state, { error }: PayloadAction<PatchedModel<Deployment>, string, never, string>) {
        state.loadStatusMap[DeploymentsRemoteOperation.CRUD] = LoadStatus.Failed;
        state.error = error;
      },
      prepare(payload: PatchedModel<Deployment>, { message }: ErrorLike) {
        return { payload, error: message };
      },
    },
    updateDeploymentsSucceeded: (state, { payload }: PayloadAction<Deployment[]>) => {
      state.loadStatusMap[DeploymentsRemoteOperation.CRUD] = LoadStatus.Succeeded;
      state.error = null;

      entityAdapter.setMany(state, payload);
    },
    deleteDeployments: {
      reducer(state, { meta }: PayloadAction<number[], string, DeploymentsMeta>) {
        state.loadStatusMap[meta.remoteOperation] = LoadStatus.Loading;
        state.error = null;
      },
      prepare(deploymentsOrIds: Deployment[] | number[], redirectTo?: string) {
        return {
          payload: getIds(deploymentsOrIds),
          meta: { remoteOperation: DeploymentsRemoteOperation.CRUD, options: { redirectTo } },
        };
      },
    },
    deleteDeploymentsFailed: {
      reducer(state, { meta, error }: PayloadAction<number[], string, DeploymentsMeta, string>) {
        state.loadStatusMap[meta.remoteOperation] = LoadStatus.Failed;
        state.error = error;
      },
      prepare(idsToDelete: number[], meta: DeploymentsMeta, { message }: ErrorLike) {
        return { payload: idsToDelete, meta, error: message };
      },
    },
    deleteDeploymentsSucceeded: {
      reducer(state, { payload, meta }: PayloadAction<number[], string, DeploymentsMeta>) {
        state.loadStatusMap[meta.remoteOperation] = LoadStatus.Succeeded;
        state.error = null;

        removeEntities(state, payload);
      },
      prepare(payload: number[], meta: DeploymentsMeta) {
        return { payload, meta };
      },
    },
    scheduleDeployments: {
      reducer(state, _action: PayloadAction<{ deploymentIds: number[]; startAt?: string }>) {
        state.loadStatusMap[DeploymentsRemoteOperation.CRUD] = LoadStatus.Loading;
        state.error = null;
      },
      prepare(deploymentsOrIds: Deployment[] | number[], startAt?: Date | string) {
        return {
          payload: {
            deploymentIds: getIds(deploymentsOrIds),
            startAt: isDate(startAt) ? startAt.toISOString() : startAt,
          },
        };
      },
    },
    scheduleDeployment: {
      reducer(state, _action: PayloadAction<{ deploymentId: number; startAt?: string }>) {
        state.loadStatusMap[DeploymentsRemoteOperation.CRUD] = LoadStatus.Loading;
        state.error = null;
      },
      prepare(deployment: Deployment | number, startAt?: Date | string) {
        return {
          payload: {
            deploymentId: isDeployment(deployment) ? deployment.id : deployment,
            startAt: isDate(startAt) ? startAt.toISOString() : startAt,
          },
        };
      },
    },
    scheduleDeploymentFailed: {
      reducer(state, { error }: PayloadAction<number, string, never, string>) {
        state.loadStatusMap[DeploymentsRemoteOperation.CRUD] = LoadStatus.Failed;
        state.error = error;
      },
      prepare(payload: number, { message }: ErrorLike) {
        return { payload, error: message };
      },
    },
    scheduleDeploymentSucceeded: (state, { payload }: PayloadAction<Deployment>) => {
      state.loadStatusMap[DeploymentsRemoteOperation.CRUD] = LoadStatus.Succeeded;
      state.error = null;

      entityAdapter.setOne(state, payload);
    },
    unscheduleDeployments: {
      reducer(state, _action: PayloadAction<{ deploymentIds: number[]; startAt?: string }>) {
        state.loadStatusMap[DeploymentsRemoteOperation.CRUD] = LoadStatus.Loading;
        state.error = null;
      },
      prepare(deploymentsOrIds: Deployment[] | number[], startAt?: Date | string) {
        return {
          payload: {
            deploymentIds: getIds(deploymentsOrIds),
            startAt: isDate(startAt) ? startAt?.toISOString() : startAt,
          },
        };
      },
    },
    unscheduleDeployment: {
      reducer(state, _action: PayloadAction<{ deploymentId: number; startAt?: string }>) {
        state.loadStatusMap[DeploymentsRemoteOperation.CRUD] = LoadStatus.Loading;
        state.error = null;
      },
      prepare(deployment: Deployment | number, startAt?: Date | string) {
        return {
          payload: {
            deploymentId: isDeployment(deployment) ? deployment.id : deployment,
            startAt: isDate(startAt) ? startAt.toISOString() : startAt,
          },
        };
      },
    },
    unscheduleDeploymentFailed: {
      reducer(state, { error }: PayloadAction<number, string, never, string>) {
        state.loadStatusMap[DeploymentsRemoteOperation.CRUD] = LoadStatus.Failed;
        state.error = error;
      },
      prepare(payload: number, { message }: ErrorLike) {
        return { payload, error: message };
      },
    },
    unscheduleDeploymentSucceeded: (state, { payload }: PayloadAction<Deployment>) => {
      state.loadStatusMap[DeploymentsRemoteOperation.CRUD] = LoadStatus.Succeeded;
      state.error = null;

      entityAdapter.setOne(state, payload);
    },
    selectDeployments: {
      reducer(state, { payload, meta }: PayloadAction<number[], string, DeploymentsMeta>) {
        state.loadStatusMap[meta.remoteOperation] = LoadStatus.Succeeded;

        state.selectedIds[getTableTypeOrDefault(meta.options?.tableType)] = payload;
      },
      prepare(deploymentsOrIds: Deployment[] | number[], options: SagaOptions = {}) {
        return {
          payload: getIds(deploymentsOrIds),
          meta: { remoteOperation: DeploymentsRemoteOperation.CHECKBOXES, options },
        };
      },
    },
    toggleSelected: {
      reducer(state, { payload, meta }: PayloadAction<number, string, DeploymentsMeta>) {
        state.loadStatusMap[meta.remoteOperation] = LoadStatus.Succeeded;

        state.selectedIds[getTableTypeOrDefault(meta.options?.tableType)] = state.selectedIds[
          getTableTypeOrDefault(meta.options?.tableType)
        ].includes(payload)
          ? state.selectedIds[getTableTypeOrDefault(meta.options?.tableType)].filter(id => id !== payload)
          : [...state.selectedIds[getTableTypeOrDefault(meta.options?.tableType)], payload];
      },
      prepare(deploymentId: number, options: SagaOptions = {}) {
        return {
          payload: deploymentId,
          meta: { remoteOperation: DeploymentsRemoteOperation.CHECKBOXES, options },
        };
      },
    },
    iotUpdate: (state, { payload }: PayloadAction<Deployment>) => {
      if (payload.audit?.deleted_at) {
        removeEntities(state, [payload.id]);
      } else {
        entityAdapter.setOne(state, normalize(payload));
      }
    },
  },
});

export const { name: deploymentsType, actions: deploymentsActions, reducer: deploymentsReducer } = slice;

export const entitySelectors = entityAdapter.getSelectors();

export function* rootDeploymentsSaga() {
  yield all([watchDeployments()]);
}
