import { createContext, useContext, useRef, type FC, type ReactNode } from "react";
import { create, useStore } from "zustand";
import { devtools } from "zustand/middleware";

import { createExperienceModel } from "@ds/modules/experiences/utils/model";

interface CreateExperienceState {
  experience: Experience;
  players: Player[];
  setFormData: (formData: ExperienceFormData) => void;
}

const createStore = (initialExperience?: Partial<Experience>) =>
  create<CreateExperienceState>()(
    devtools(
      set => ({
        experience: createExperienceModel(initialExperience),
        players: [],
        setFormData: formData =>
          set(() => formData, false, {
            type: "setFormData",
            formData,
          }),
      }),
      { name: "CreateExperience Modal" },
    ),
  );

type CreateExperienceStore = ReturnType<typeof createStore>;

const CreateExperienceContext = createContext<CreateExperienceStore | null>(null);

export const CreateExperienceProvider: FC<{ initialValue: Partial<Experience>; children: ReactNode }> = ({
  children,
  initialValue,
}) => {
  const storeRef = useRef<CreateExperienceStore>();
  if (!storeRef.current) {
    storeRef.current = createStore(initialValue);
  }

  return <CreateExperienceContext.Provider value={storeRef.current}>{children}</CreateExperienceContext.Provider>;
};

export const useCreateExperienceContext = <T = CreateExperienceState,>(
  selector: (state: CreateExperienceState) => T = s => s as T,
  equalityFn?: (left: T, right: T) => boolean,
): T => {
  const store = useContext(CreateExperienceContext);
  if (!store) {
    throw new Error("Missing Context.Provider in the tree");
  }

  return useStore(store, selector, equalityFn);
};
