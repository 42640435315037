import { produce } from "immer";
import { isString } from "lodash";
import { type Task } from "redux-saga";
import { call, cancel, delay, fork, put, select, take, takeEvery, type SagaReturnType } from "redux-saga/effects";

import { StatusEnum } from "@ds/model/status-model";

import {
  CommandTargetEnum,
  createCommandModel,
  isScreenshotCommand,
} from "@ds/modules/devices/players/utils/command-model";
import { iotEventCommandUpdate } from "@ds/modules/iot/redux/actions";
import { toastShowErrorAction } from "@ds/modules/notifications/redux/actions";

import { storageService } from "@ds/services/storage";
import { UnexpectedError } from "@ds/utils/errors";
import { logger } from "@ds/utils/logger";
import { LoadStatus } from "@ds/utils/reducer";
import { isErrorLike } from "@ds/utils/type-guards/error-guards";

import { PlayerCommands } from "../../../constants/common";
import { playersService } from "../../../utils/api";
import { PlayerStateEnum } from "../../../utils/model";
import { selectPlayerById, selectScreenshotStatus } from "../../selectors";
import { playersActions } from "../../slice";

const SCREENSHOT_REQUEST_INTERVAL = 10000;
const SCREENSHOT_REQUEST_COUNT = 5;

function* processScreenshot({ payload }: ReturnType<typeof iotEventCommandUpdate>) {
  if (!isScreenshotCommand(payload) || payload.status !== StatusEnum.Succeeded) {
    return;
  }

  try {
    const publicPrefixLength = 8;
    const screenshotFileURL = new URL(payload.data);
    const key = new URL(screenshotFileURL).pathname.slice(publicPrefixLength);
    const screenshotUrl: SagaReturnType<typeof storageService.get> = yield call(
      [storageService, storageService.get],
      key,
      {
        level: "public",
        download: false,
      },
    );

    if (!isString(screenshotUrl)) {
      throw new Error("Failed to fetch screenshot from storage");
    }

    yield put(playersActions.processScreenshots({ screenshotUrl }));
  } catch (err) {
    const errorTitle = "Process screenshot for player";
    if (isErrorLike(err)) {
      const entity = produce(payload, draft => {
        draft.status = StatusEnum.Failed;
        draft.message = "Failed to process screenshot";
      });

      yield put(iotEventCommandUpdate(entity));
      yield put(toastShowErrorAction(err, errorTitle));
    } else {
      logger.error(`${errorTitle}: ${UnexpectedError}`);
      yield put(toastShowErrorAction(UnexpectedError, errorTitle));
    }
  }
}

function* fetchScreenshot({ payload }: ReturnType<typeof playersActions.startFetchingScreenshots>) {
  let count = 0;
  while (count++ < SCREENSHOT_REQUEST_COUNT) {
    const status = selectScreenshotStatus(yield select());
    if (status === LoadStatus.Idle) {
      return;
    }

    const { state } = selectPlayerById(yield select(), payload);
    if (document.visibilityState === "visible" && state === PlayerStateEnum.Online) {
      const command = createCommandModel({
        device_id: payload,
        target: CommandTargetEnum.Core,
        name: PlayerCommands.SCREENSHOT,
        data: "",
      });

      try {
        yield call([playersService, playersService.sendCommand], command);
      } catch (err) {
        const errorTitle = "Fetch screenshot for player";
        if (isErrorLike(err)) {
          yield put(toastShowErrorAction(err, errorTitle));
        } else {
          logger.error(`${errorTitle}: ${UnexpectedError}`);
          yield put(toastShowErrorAction(UnexpectedError, errorTitle));
        }
      }
    }

    yield delay(SCREENSHOT_REQUEST_INTERVAL);
  }

  yield put(playersActions.stopFetchingScreenshots());
}

export function* watchPlayerScreenshotCommand() {
  yield takeEvery(iotEventCommandUpdate, processScreenshot);
  while (true) {
    const screenshotTask: Task = yield fork(fetchScreenshot, yield take(playersActions.startFetchingScreenshots));
    yield take(playersActions.stopFetchingScreenshots);
    yield cancel(screenshotTask);
  }
}
