import { AutoComplete as PrimeAutoComplete } from "primereact/autocomplete";
import styled, { css } from "styled-components";

import { TextInputMixin } from "../inputs/styles";
import { type AutoCompleteGroupItemProps } from "./types";

export const AutocompleteWrapper = styled.div`
  position: relative;
  width: 100%;
`;

export const ClearIcon = styled.i<{ $hasDropdown?: boolean }>`
  color: var(--textColor030);
  position: absolute;
  right: ${({ $hasDropdown }) => ($hasDropdown ? 45 : 10)}px;
  top: 50%;
  margin-top: -8px;
  cursor: pointer;

  &:hover {
    color: var(--textColor060);
  }
`;

export const ClearButton = styled.button`
  display: contents;
`;

export const StyledAutoComplete = styled(PrimeAutoComplete)<{ err?: Error }>`
  width: 100%;

  .p-button {
    border: none;
    background: var(--primaryColor090);

    &:enabled:hover,
    :not(button):not(a):not(.p-disabled):hover {
      border: none;
      background: var(--primaryColor100);
    }

    &:focus {
      box-shadow: var(--button-light-box-shadow);
    }
  }

  .p-autocomplete-input {
    ${TextInputMixin}

    width: 100%;
    padding-right: 40px;
    text-overflow: ellipsis;
  }

  .p-autocomplete-multiple-container {
    width: 100%;
    padding: 0;
    box-shadow: var(--button-light-box-shadow) !important;
    ${TextInputMixin}

    > .p-autocomplete-input-token {
      height: 100%;
      height: fill-available;
      padding: 0;

      input {
        width: 100%;
        padding: 8px;
        border: 0;

        ${TextInputMixin}
      }
    }
  }

  &.p-autocomplete.p-autocomplete-dd .p-autocomplete-loader,
  &.p-autocomplete .p-autocomplete-loader {
    background: transparent;
    z-index: 1;
    color: var(--primaryColor100);
    font-weight: bold;
  }

  &.p-autocomplete.p-autocomplete-dd .p-autocomplete-loader {
    right: 45px;
  }

  &.p-autocomplete .p-autocomplete-loader {
    right: 10px;
  }
`;

export const AutoCompleteGroupLabel = styled.div`
  font-size: 0.95rem;
  margin-left: 0;
  color: var(--textColor060);
`;

export const AutoCompleteGroupItem = styled.div<AutoCompleteGroupItemProps>`
  font-size: 0.95rem;
  margin-left: 15px;
  user-select: none;
  ${({ $isInvalid }) =>
    $isInvalid &&
    css`
      color: var(--textColor030);
    `}
`;

export const ChipsWrapper = styled.div`
  --chip-height: 32px;

  display: flex;
  flex-flow: row wrap;
  margin-top: 10px;

  .p-chip {
    height: var(--chip-height);
    padding: 0 12px;
    margin-right: 6px;
    margin-bottom: 6px;
    font-size: 0.95rem;
    font-weight: 500;
    line-height: var(--chip-height);
    color: var(--textColor060);
    background: var(--button-ultra-light-background);
    border-radius: 12px;
    user-select: none;
  }

  .p-chip-remove-icon {
    margin-left: 5px;
    font-size: 0.95rem;
    line-height: var(--chip-height);
  }
`;
