import { type FC } from "react";
import { Controller, type Control } from "react-hook-form";

import { useDetailsInlineSaving } from "@ds/components/details-view/hooks";
import { Dropdown } from "@ds/components/forms/inputs";
import { LabelWrapper } from "@ds/components/forms/labels";
import { useOnMouseEventPreventDefaultHandler } from "@ds/utils/handlers";

import { TenantRoleEnum } from "../../../utils/model";

const UserRoleOptions = [
  { label: "TenantAdmin", value: TenantRoleEnum.TenantAdmin },
  { label: "TenantUser", value: TenantRoleEnum.TenantUser },
];

export const UserRoleControl: FC<{
  control: Control<User> | Control<Invitation> | Control<InvitationFormData>;
  name?: "role" | "user_role" | "invitation.user_role";
  label?: string;
  autoFocus?: boolean;
  excludeLabel?: boolean;
  onBlur?: () => void;
}> = ({ control, name = "role", label = "Role", autoFocus, excludeLabel, onBlur }) => {
  const onBlurHandler = useDetailsInlineSaving(onBlur);
  const onMouseDownHandler = useOnMouseEventPreventDefaultHandler();

  return (
    <Controller
      name={name}
      control={control as Control<User | Invitation | InvitationFormData>}
      render={({ field: { ref, onBlur: onBlurFn, ...restFields }, fieldState }) => {
        const element = (
          <Dropdown
            inputRef={ref}
            autoFocus={autoFocus}
            options={UserRoleOptions}
            onBlur={onBlurHandler(onBlurFn)}
            onMouseDown={onMouseDownHandler}
            {...restFields}
          />
        );

        if (excludeLabel) {
          return element;
        }

        return (
          <LabelWrapper label={label} className="col-8" error={fieldState.error} isRequired>
            {element}
          </LabelWrapper>
        );
      }}
    />
  );
};
