import { useCallback, useEffect, type FC } from "react";
import styled from "styled-components";

import { useGetAggregatedEntityIds } from "@ds/hooks/use-get-aggregated-entity-ids";
import { useVisibility } from "@ds/hooks/use-visibility";

import { isAggregatedEntity } from "@ds/model/entity-model";

import { selectAuthIsSucceeded } from "@ds/modules/auth/redux/selectors";

import { ActionsBar } from "@ds/components/actions-bar";
import { Button, ButtonActionType, ButtonType } from "@ds/components/forms/buttons";
import { IconType } from "@ds/components/icons";
import { MenuWithButton } from "@ds/components/menu-dropdown/menu-with-button";
import { confirmWarning } from "@ds/components/popups/confirmation-dialog";
import { SETTINGS_USERS_ROUTE } from "@ds/constants/router";
import { useRootDispatch, useRootSelector } from "@ds/hooks";
import { pluralizeDeleteMessage } from "@ds/utils/localization/common";

import { selectCurrentUser, selectCurrentUserIsTenantAdmin, selectUserIsTenantOwnerById } from "../../redux/selectors";
import { usersActions } from "../../redux/slice";
import { ChangePasswordModal } from "../forms/change-password-form/change-password-modal";

const Wrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

export const UserDetailsInfoCardActionsMenu: FC<{ user: User }> = ({ user }) => {
  const [isChangePasswordModalVisible, setIsChangePasswordModalVisible] = useVisibility();

  const aggregatedUserIds = useGetAggregatedEntityIds(user);
  const isSucceeded = useRootSelector(selectAuthIsSucceeded);
  const isAdmin = useRootSelector(selectCurrentUserIsTenantAdmin);
  const isUserModifyHimself = useRootSelector(state => aggregatedUserIds.includes(selectCurrentUser(state)?.id || 0));
  const isUserModifyTenantOwner = useRootSelector(state =>
    aggregatedUserIds.some(userId => selectUserIsTenantOwnerById(state, userId)),
  );

  const isUserOpenedProfile = useRootSelector(
    state => user && !isAggregatedEntity(user) && selectCurrentUser(state)?.id === user?.id,
  );

  useEffect(() => {
    if (isSucceeded) {
      setIsChangePasswordModalVisible(false)();
    }
  }, [isSucceeded, setIsChangePasswordModalVisible]);

  const dispatch = useRootDispatch();
  const onDeleteClickHandler = useCallback(
    () =>
      confirmWarning({
        message: pluralizeDeleteMessage("user", aggregatedUserIds.length),
        accept: () => dispatch(usersActions.deleteUsers(aggregatedUserIds, SETTINGS_USERS_ROUTE)),
      }),
    [dispatch, aggregatedUserIds],
  );

  return (
    <Wrapper>
      <ActionsBar>
        {isUserOpenedProfile && (
          <MenuWithButton
            menuItems={[
              {
                label: "Change Password",
                icon: "pi pi-fw pi-key",
                command: setIsChangePasswordModalVisible(true),
              },
            ]}
          />
        )}

        <Button
          label="Delete"
          ariaLabel="delete button"
          buttonType={ButtonType.Round}
          actionType={ButtonActionType.Error}
          icon={IconType.Delete}
          isDisabled={!isAdmin || isUserModifyHimself || isUserModifyTenantOwner}
          onClick={onDeleteClickHandler}
        />
      </ActionsBar>

      {isChangePasswordModalVisible && <ChangePasswordModal onClose={setIsChangePasswordModalVisible(false)} />}
    </Wrapper>
  );
};
