import log from "loglevel";

type NbLogLevel = {
  TRACE: 0;
  DEBUG: 1;
  INFO: 2;
  WARN: 3;
  ERROR: 4;
  SILENT: 5;
};

type NbLogLevelNumbers = NbLogLevel[keyof NbLogLevel];
type NbLogLevelDesc = NbLogLevelNumbers | "trace" | "debug" | "info" | "warn" | "error" | "silent" | keyof NbLogLevel;

export const logger = {
  setLevel: (level: NbLogLevelDesc, persist?: boolean) => log.setLevel(level, persist),
  trace: (...msg: unknown[]) => log.trace(msg),
  debug: (...msg: unknown[]) => log.debug(msg),
  info: (...msg: unknown[]) => log.info(msg),
  warn: (...msg: unknown[]) => log.warn(msg),
  error: (...msg: unknown[]) => log.error(msg),
};

export const dbg = <T>(obj: T, message?: string) => {
  if (message) {
    window.console.log("%c" + message, "color: green; font-weight:bold; background-color: white;");
  }

  window.console.log(obj);
  return obj;
};

export const dbgif = <T>(isTrue: boolean, obj: T, message?: string) => {
  if (isTrue) {
    return dbg(obj, message);
  }

  return obj;
};
