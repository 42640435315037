import { type FC } from "react";
import { Route, Routes, useLocation } from "react-router-dom";

import { DEPLOYMENT_ID_PARAM, MULTIPLE_IDS_PARAM } from "@ds/constants/router";

import { DeploymentDetailsPage } from "./deployment-details-page";
import { DeploymentsMainPage } from "./deployments-main-page";

const DeploymentsRouteSwitch = () => {
  const { search } = useLocation();
  return search.startsWith(`?${MULTIPLE_IDS_PARAM}`) ? <DeploymentDetailsPage /> : <DeploymentsMainPage />;
};

export const DeploymentsRouter: FC = () => (
  <Routes>
    <Route path="*" element={<DeploymentsRouteSwitch />} />
    <Route path={`:${DEPLOYMENT_ID_PARAM}`} element={<DeploymentDetailsPage />} />
  </Routes>
);
