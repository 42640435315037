import { yupResolver } from "@hookform/resolvers/yup";
import { useCallback, useEffect, type FC } from "react";
import { useForm } from "react-hook-form";

import { WizardPage, type WizardChildStepProps } from "@ds/components/wizard";

import { createValidationSchema } from "../../../utils/validation-schema";
import { AddressCountryControl } from "../controls/address-country-control";
import { LocationAddressForm } from "../location-address-form";
import { useCreateLocation } from "./create-location-context";

export const Step1Address: FC<WizardChildStepProps> = ({ nextStep, ...rest }) => {
  const [currentLocation, submitLocation] = useCreateLocation();
  const { control, setValue, getValues, handleSubmit, reset } = useForm<Locality>({
    mode: "all",
    reValidateMode: "onChange",
    defaultValues: currentLocation,
    resolver: yupResolver(createValidationSchema.step1),
  });

  useEffect(() => void reset(currentLocation), [currentLocation, reset]);

  const onNextClickHandler = useCallback(() => {
    submitLocation(getValues());
    if (nextStep) {
      nextStep();
    }
  }, [nextStep, submitLocation, getValues]);

  return (
    <WizardPage {...rest} title="Add location" nextStep={handleSubmit(onNextClickHandler)}>
      <AddressCountryControl control={control} setValue={setValue} />
      <LocationAddressForm control={control} setValue={setValue} />
    </WizardPage>
  );
};
