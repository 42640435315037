export enum SeverityEnum {
  none = "300",
  info = "200",
  warning = "100",
  error = "000",
}

export enum StatusEnum {
  Info = "200#info",
  Pending = "200#pending",
  Executing = "200#executing",
  Scheduled = "200#scheduled",
  Initializing = "200#initializing",
  Warning = "100#warning",
  Deferred = "100#deferred",
  Failure = "000#failure",
  Failed = "000#failed",
  Error = "000#error",
  Created = "300#created",
  Ok = "300#ok",
  Success = "300#success",
  Succeeded = "300#succeeded",
  Cancelled = "100#cancelled",
  Banned = "100#banned",
  Incomplete = "010#incomplete",
  Unknown = "000#unknown",
}

export const StatusColor: Record<Status, string> = {
  [StatusEnum.Info]: "var(--infoColor)",
  [StatusEnum.Pending]: "var(--neutralColor)",
  [StatusEnum.Initializing]: "var(--neutralColor)",
  [StatusEnum.Executing]: "var(--progressColor)",
  [StatusEnum.Scheduled]: "var(--progressColor)",
  [StatusEnum.Warning]: "var(--warningColor)",
  [StatusEnum.Deferred]: "var(--warningColor)",
  [StatusEnum.Failure]: "var(--red-color)",
  [StatusEnum.Failed]: "var(--red-color)",
  [StatusEnum.Banned]: "var(--red-color)",
  [StatusEnum.Error]: "var(--red-color)",
  [StatusEnum.Created]: "var(--infoColor)",
  [StatusEnum.Ok]: "var(--positiveColor)",
  [StatusEnum.Success]: "var(--positiveColor)",
  [StatusEnum.Succeeded]: "var(--positiveColor)",
  [StatusEnum.Cancelled]: "var(--passiveColor)",
  [StatusEnum.Unknown]: "var(--passiveColor)",
  [StatusEnum.Incomplete]: "var(--passiveColor)",
};

export const StatusIconClassName: Record<Status, string> = {
  [StatusEnum.Info]: "pi pi-info-circle",
  [StatusEnum.Pending]: "pi pi-spin pi-spinner",
  [StatusEnum.Initializing]: "",
  [StatusEnum.Executing]: "pi pi-spin pi-spinner",
  [StatusEnum.Scheduled]: "",
  [StatusEnum.Warning]: "pi pi-exclamation-triangle",
  [StatusEnum.Deferred]: "",
  [StatusEnum.Failure]: "pi pi-exclamation-circle",
  [StatusEnum.Failed]: "pi pi-exclamation-circle",
  [StatusEnum.Banned]: "pi pi-ban",
  [StatusEnum.Error]: "pi pi-exclamation-circle",
  [StatusEnum.Created]: "",
  [StatusEnum.Ok]: "pi pi-check",
  [StatusEnum.Success]: "pi pi-check",
  [StatusEnum.Succeeded]: "pi pi-check",
  [StatusEnum.Cancelled]: "pi pi-times",
  [StatusEnum.Unknown]: "pi pi-question-circle",
  [StatusEnum.Incomplete]: "",
};

export type StatusNames = keyof typeof StatusEnum;
