import { Children, type FC, type ReactNode } from "react";
import styled from "styled-components";

const StyledList = styled.ul`
  display: flex;
  flex: none;
  justify-content: center;
  align-items: center;
  margin: 0;
  padding: 0;

  .p-button {
    padding-left: 10px;
    padding-right: 10px;
  }
`;

const StyledListItem = styled.li`
  margin: 0;
  padding: 0;
  text-indent: 0;
  list-style-type: none;
`;

export const ActionsBar: FC<{ children: ReactNode }> = ({ children }) => (
  <StyledList>
    {Children.map(children, child => (
      <StyledListItem>{child}</StyledListItem>
    ))}
  </StyledList>
);
