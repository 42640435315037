import { useCallback, type FC } from "react";
import styled from "styled-components";

import { useGetAggregatedEntityIds } from "@ds/hooks/use-get-aggregated-entity-ids";

import { ActionsBar } from "@ds/components/actions-bar";
import { Button, ButtonActionType, ButtonType } from "@ds/components/forms/buttons";
import { IconType } from "@ds/components/icons";
import { MenuWithButton } from "@ds/components/menu-dropdown/menu-with-button";
import { confirmSuccess, confirmWarning } from "@ds/components/popups/confirmation-dialog";
import { SETTINGS_INVITATIONS_ROUTE } from "@ds/constants/router";
import { useRootDispatch } from "@ds/hooks";
import { pluralizeActionMessage, pluralizeDeleteMessage } from "@ds/utils/localization/common";

import { invitationsActions } from "../../redux/slice";

const Wrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

export const InvitationDetailsInfoCardActionsMenu: FC<{ invitation: Invitation }> = ({ invitation }) => {
  const aggregatedUserIds = useGetAggregatedEntityIds(invitation);

  const dispatch = useRootDispatch();
  const onResendActivationClickHandler = useCallback(() => {
    confirmSuccess({
      message: pluralizeActionMessage("invitation", aggregatedUserIds.length, "resend"),
      accept: () => dispatch(invitationsActions.resendInvitations(aggregatedUserIds)),
    });
  }, [dispatch, aggregatedUserIds]);

  const onDeleteClickHandler = useCallback(
    () =>
      confirmWarning({
        message: pluralizeDeleteMessage("invitation", aggregatedUserIds.length),
        accept: () => dispatch(invitationsActions.deleteInvitations(aggregatedUserIds, SETTINGS_INVITATIONS_ROUTE)),
      }),
    [dispatch, aggregatedUserIds],
  );

  return (
    <Wrapper>
      <ActionsBar>
        <MenuWithButton
          menuItems={[
            {
              label: "Resend Invitation",
              icon: "pi pi-fw pi-envelope",
              command: onResendActivationClickHandler,
            },
          ]}
        />

        <Button
          label="Delete"
          ariaLabel="delete button"
          buttonType={ButtonType.Round}
          actionType={ButtonActionType.Error}
          icon={IconType.Delete}
          onClick={onDeleteClickHandler}
        />
      </ActionsBar>
    </Wrapper>
  );
};
