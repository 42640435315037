import { isArray } from "lodash";

import { EntityTypeName, isEntity } from "@ds/model/entity-model";

import { isPlainObject } from "@ds/utils/type-guards/common-guards";

export enum TenantRoleEnum {
  TenantAdmin = "TenantAdmin",
  TenantUser = "TenantUser",
}

export const isUser = (obj: unknown): obj is User => isEntity(obj) && obj.__typename === EntityTypeName.SETTINGS_USER;

export const isApiUser = (obj: unknown): obj is ApiUser => isUser(obj) && "projects" in obj;

export const isUserArray = (obj: unknown): obj is User[] => isArray(obj) && obj.every(isUser);

export const isApiUserArray = (obj: unknown): obj is ApiUser[] => isArray(obj) && obj.every(isApiUser);

export const isUserQueryOutput = (obj: unknown): obj is QueryOutput<User> =>
  isPlainObject(obj) && "items" in obj && isUserArray(obj.items);

export const isApiUserQueryOutput = (obj: unknown): obj is QueryOutput<ApiUser> =>
  isPlainObject(obj) && "items" in obj && isApiUserArray(obj.items);
