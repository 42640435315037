import { type Path } from "react-hook-form";

import { getEntityTypeName } from "@ds/model/helpers";

import { isContentAsset } from "@ds/modules/content/assets/utils/model";
import { isInvitation } from "@ds/modules/settings/invitations/utils/model";
import { isUser } from "@ds/modules/settings/users/utils/model";

import { pluralizeTotalSelectedTitle } from "@ds/utils/localization/common";
import { isMultiple } from "@ds/utils/numbers";
import { getProperty } from "@ds/utils/properties";
import { composeDetailsRouteByEntityType } from "@ds/utils/router";

import { AggregatedValue } from "../entities";
import { TextLink } from "../text-link";

export const getInfoViewTitle = <T extends AggregatedEntity<T>>(aggregatedEntity: T) => {
  const aggregatedEntityField = isUser(aggregatedEntity) || isInvitation(aggregatedEntity) ? "email" : "name";
  const value = (
    <AggregatedValue
      entity={aggregatedEntity}
      field={aggregatedEntityField as Path<T>}
      formatter={(entity, field) =>
        `${getProperty(entity, field)}` +
        (isMultiple(entity?.metadata?.totalAggregated) ? ` (${entity?.metadata?.totalAggregated} selected)` : "")
      }
      multipleFormatter={entity =>
        pluralizeTotalSelectedTitle(getEntityTypeName(entity), entity?.metadata?.totalAggregated)
      }
    />
  );

  if (isContentAsset(aggregatedEntity)) {
    return value;
  }

  return <TextLink to={composeDetailsRouteByEntityType(aggregatedEntity)}>{value}</TextLink>;
};
