import { useAsyncAbortable } from "@react-hookz/web";
import axios from "axios";

import { REACT_APP_GOOGLE_TIMEZONE_API_KEY } from "@ds/constants/environment";

import { type GoogleTimeZoneResult } from "./types";

const GOOGLE_TIMEZONE_API_URL = "https://maps.googleapis.com/maps/api/timezone/json";

const fetchGoogleAddressTimeZone = async (coordinates: string): Promise<GoogleTimeZoneResult> => {
  const params = new URLSearchParams();
  params.append("location", coordinates);
  params.append("timestamp", Math.floor(Date.now() / 1000).toString());
  params.append("key", REACT_APP_GOOGLE_TIMEZONE_API_KEY);

  const response = await axios.get<GoogleTimeZoneResult>(`${GOOGLE_TIMEZONE_API_URL}?${params.toString()}`);
  if (response.data.status !== "OK") {
    throw Error(response.data.status);
  }

  return response.data;
};

export const useFetchGoogleAddressTimeZone = () => {
  const [{ result: data, status, error }, actions, meta] = useAsyncAbortable(async (_, coordinates: string) =>
    fetchGoogleAddressTimeZone(coordinates),
  );

  return [
    {
      data,
      status,
      error,
    },
    actions,
    meta,
  ] as const;
};
