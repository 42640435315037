import { isBoolean, isNumber } from "lodash";

import { isPlainObject } from "@ds/utils/type-guards/common-guards";

export const isFormationPropertyValidationRule = (obj: unknown): obj is FormationPropertyValidationRule =>
  isPlainObject(obj) && "type" in obj && "value" in obj;

export const isTypeFormationPropertyValidationRule = (obj: unknown): obj is TypeFormationPropertyValidationRule =>
  isFormationPropertyValidationRule(obj) && obj.type === "type" && ["string", "number", "boolean"].includes(obj.value);

export const isRequiredFormationPropertyValidationRule = (
  obj: unknown,
): obj is RequiredFormationPropertyValidationRule =>
  isFormationPropertyValidationRule(obj) && obj.type === "required" && isBoolean(obj.value);

export const isMinFormationPropertyValidationRule = (obj: unknown): obj is MinFormationPropertyValidationRule =>
  isFormationPropertyValidationRule(obj) && obj.type === "min" && isNumber(obj.value);

export const isMaxFormationPropertyValidationRule = (obj: unknown): obj is MaxFormationPropertyValidationRule =>
  isFormationPropertyValidationRule(obj) && obj.type === "max" && isNumber(obj.value);
