export enum AddressCountry {
  USA = "US",
  CANADA = "CA",
  PUERTO_RICO = "PR",
  VIRGIN_ISLANDS = "VI",
  GUAM = "GU",
  NORTHERN_MARIANA_ISLANDS = "MP",
}

export enum GooglePlaceResultEnum {
  ADDRESS_COMPONENTS = "address_components",
  FORMATTED_ADDRESS = "formatted_address",
  PLACE_ID = "place_id",
  GEOMETRY = "geometry",
  OPENING_HOURS = "opening_hours",
}

export interface GoogleTimeZoneResult {
  dstOffset: number;
  rawOffset: number;
  status: string;
  timeZoneId: string;
  timeZoneName: string;
}

export enum GoogleSearchTypesEnum {
  ESTABLISHMENT = "establishment",
  ADDRESS = "address",
}
