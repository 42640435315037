import { isArray } from "lodash";

import { EntityTypeName, isEntity } from "@ds/model/entity-model";

import { isPlainObject } from "@ds/utils/type-guards/common-guards";

export const isDeviceSync = (obj: unknown): obj is DeviceSync =>
  isEntity(obj) && obj.__typename === EntityTypeName.DEVICE_SYNC;

export const isApiDeviceSync = (obj: unknown): obj is ApiDeviceSync => isDeviceSync(obj) && "device" in obj;

export const isDeviceSyncArray = (obj: unknown): obj is DeviceSync[] => isArray(obj) && obj.every(isDeviceSync);

export const isApiDeviceSyncArray = (obj: unknown): obj is ApiDeviceSync[] =>
  isArray(obj) && obj.every(isApiDeviceSync);

export const isDeviceSyncQueryOutput = (obj: unknown): obj is QueryOutput<DeviceSync> =>
  isPlainObject(obj) && "items" in obj && isDeviceSyncArray(obj.items);

export const isApiDeviceSyncQueryOutput = (obj: unknown): obj is QueryOutput<ApiDeviceSync> =>
  isPlainObject(obj) && "items" in obj && isApiDeviceSyncArray(obj.items);
