import { createSelector } from "@reduxjs/toolkit";
import { produce } from "immer";

import { selectTablePlaylistAssetIds } from "@ds/modules/content/playlist-assets/redux/selectors/common-selectors";
import { selectTablePlaylistIds } from "@ds/modules/content/playlists/redux/selectors";
import { selectTableDeploymentIds } from "@ds/modules/deployments/redux/selectors";
import { selectTableDeviceSyncIds } from "@ds/modules/device-syncs/redux/selectors";
import { selectTablePeripheralIds } from "@ds/modules/devices/peripherals/redux/selectors";
import { selectTablePlayerIds } from "@ds/modules/devices/players/redux/selectors";
import { selectTableExperienceIds } from "@ds/modules/experiences/redux/selectors";
import { selectTableLocationIds } from "@ds/modules/locations/redux/selectors";
import { selectTableProjectIds } from "@ds/modules/settings/projects/redux/selectors/common-selectors";
import { selectTableUserIds } from "@ds/modules/settings/users/redux/selectors";
import { DetailsTableDataTypeEnum, MainTableDataTypeEnum } from "@ds/modules/table-data/utils/model";

import { type RootState } from "@ds/store/bootstrap";

import { normalizeFilters, snakifyFilters } from "../utils/common";
import { selectTableContentAssetIds } from "./../../content/assets/redux/selectors/common-selectors";
import { DEFAULT_TABLE_TAB, getBaseEntityPerCategorySlice } from "./slice";
import { type BaseTableDataSlice } from "./types";

const extractTableFilters = (
  _: RootState,
  _tableType: MainTableDataTypeEnum | DetailsTableDataTypeEnum,
  _tableTab = DEFAULT_TABLE_TAB,
  filters?: KeysToCamelCase<QueryFilters>,
) => filters;

const selectBaseState = (state: RootState) => state.tableData;

const selectBaseTableData = (
  state: RootState,
  tableType: MainTableDataTypeEnum | DetailsTableDataTypeEnum,
  tableTab = DEFAULT_TABLE_TAB,
) => selectBaseState(state)[tableType][tableTab] || getBaseEntityPerCategorySlice(tableType);

export const selectTableData = createSelector(selectBaseTableData, extractTableFilters, (baseTableData, filters) =>
  filters
    ? (produce(baseTableData, draft => {
        draft.queryInfo.filters = normalizeFilters(snakifyFilters(filters));
      }) as BaseTableDataSlice)
    : baseTableData,
);

export const selectIsInfoViewVisible = (
  state: RootState,
  tableType: MainTableDataTypeEnum | DetailsTableDataTypeEnum,
  tableTab = DEFAULT_TABLE_TAB,
) => selectBaseTableData(state, tableType, tableTab).showInfoView;

export const isIotTableEntityAddedOrDeleted = (
  state: RootState,
  payload: Entity<number>,
  tableType: MainTableDataTypeEnum | DetailsTableDataTypeEnum,
) => {
  let tableIds: number[] = [];

  switch (tableType) {
    case MainTableDataTypeEnum.Experiences:
    case DetailsTableDataTypeEnum.LocationExperiences:
      tableIds = selectTableExperienceIds(state, tableType);
      break;
    case MainTableDataTypeEnum.DevicePlayers:
    case DetailsTableDataTypeEnum.LocationPlayers:
      tableIds = selectTablePlayerIds(state, tableType);
      break;
    case MainTableDataTypeEnum.DevicePeripherals:
      tableIds = selectTablePeripheralIds(state, tableType);
      break;
    case MainTableDataTypeEnum.Locations:
      tableIds = selectTableLocationIds(state, tableType);
      break;
    case MainTableDataTypeEnum.ContentPlaylists:
      tableIds = selectTablePlaylistIds(state, tableType);
      break;
    case MainTableDataTypeEnum.ContentVideos:
    case MainTableDataTypeEnum.ContentImages:
    case MainTableDataTypeEnum.ContentAudio:
      tableIds = selectTableContentAssetIds(state, tableType);
      break;
    case MainTableDataTypeEnum.Deployments:
      tableIds = selectTableDeploymentIds(state, tableType);
      break;
    case MainTableDataTypeEnum.Projects:
      tableIds = selectTableProjectIds(state, tableType);
      break;
    case MainTableDataTypeEnum.Users:
      tableIds = selectTableUserIds(state, tableType);
      break;
    case DetailsTableDataTypeEnum.ContentPlaylistAssets:
      tableIds = selectTablePlaylistAssetIds(state, tableType);
      break;
    case DetailsTableDataTypeEnum.DeploymentDeviceSyncs:
      tableIds = selectTableDeviceSyncIds(state, tableType);
      break;
  }

  return (!payload.audit?.deleted_at && !tableIds.includes(payload.id)) || payload.audit?.deleted_at;
};
