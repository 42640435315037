import { type FC } from "react";

import { Button, ButtonType } from "@ds/components/forms/buttons";
import { IconType } from "@ds/components/icons";
import { InfoSection } from "@ds/components/info-view";

export const PlayerPairingInfoSection: FC<{ onPairPlayer: () => void }> = ({ onPairPlayer }) => (
  <InfoSection title="Pairing" icon={`pi ${IconType.Pair}`}>
    <Button
      label="Pair player"
      ariaLabel="pair button"
      buttonType={ButtonType.Outlined}
      icon={IconType.Pair}
      onClick={onPairPlayer}
    />
  </InfoSection>
);
