import { type FC } from "react";
import { Controller, type Control } from "react-hook-form";
import styled from "styled-components";

import { Checkbox } from "@ds/components/forms/inputs";

const CheckboxWrapper = styled.label`
  display: flex;
  align-items: center;
  margin-top: 20px;
`;

const WrappedCheckbox = styled(Checkbox)`
  margin-right: 16px;
`;

export const DeploymentDeferredExecutionControl: FC<{ control: Control<Deployment> }> = ({ control }) => (
  <Controller
    name="deferred_execution"
    control={control}
    render={({ field: { ref, value, ...restFields } }) => (
      <CheckboxWrapper className="col-8">
        <WrappedCheckbox ref={ref} checked={value} {...restFields} />
        <span>Schedule deployment manually once ready</span>
      </CheckboxWrapper>
    )}
  />
);
