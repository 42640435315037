import { type FC } from "react";
import { Controller, type Control } from "react-hook-form";
import styled from "styled-components";

import { type UserChangePasswordModel } from "@ds/modules/auth/redux/types";

import { Password } from "@ds/components/forms/inputs";
import { LabelWrapper } from "@ds/components/forms/labels";

const AuthPassword = styled(Password)`
  width: 100%;
`;

export const UserPasswordControl: FC<{
  control: Control<UserChangePasswordModel>;
  name?: "password" | "newPassword" | "newPasswordConfirmation";
  label?: string;
  autoComplete?: string;
}> = ({ control, name = "password", label = "Current Password", autoComplete = "current-password" }) => (
  <Controller
    name={name}
    control={control}
    render={({ field: { ref, ...restFields }, fieldState }) => (
      <LabelWrapper label={label} className="col-8" error={fieldState.error} isRequired>
        <AuthPassword
          ref={ref}
          placeholder={`Enter ${label}`}
          {...restFields}
          autoComplete={autoComplete}
          autoFocus
          required
        />
      </LabelWrapper>
    )}
  />
);
