import { type FC } from "react";
import { Navigate, Route, Routes } from "react-router-dom";

import { DEVICES_PERIPHERALS_SEGMENT, DEVICES_PLAYERS_SEGMENT } from "@ds/constants/router";
import { useRootSelector } from "@ds/hooks";

import { PeripheralsRouter } from "./peripherals/peripherals-router";
import { PlayersRouter } from "./players/players-router";
import { selectCurrentView } from "./redux/selectors";

const DevicesRouteSwitch = () => {
  const currentView = useRootSelector(selectCurrentView);
  if (currentView) {
    return <Navigate to={currentView} replace />;
  }

  return <Navigate to={DEVICES_PLAYERS_SEGMENT} replace />;
};

export const DevicesRouter: FC = () => (
  <Routes>
    <Route path="*" element={<DevicesRouteSwitch />} />
    <Route path={`${DEVICES_PLAYERS_SEGMENT}/*`} element={<PlayersRouter />} />
    <Route path={`${DEVICES_PERIPHERALS_SEGMENT}/*`} element={<PeripheralsRouter />} />
  </Routes>
);
