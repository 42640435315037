import { produce } from "immer";

import { normalizeBase } from "@ds/model/helpers";

import { isLocationQueryOutput, isLocationArray } from "./model";

const normalizer = (entity: Locality): Locality =>
  normalizeBase({
    store_id: undefined,
    description: undefined,
    address: undefined,
    time_zone: undefined,
    ...entity,
  });

export const normalize = ((
  entityOrEntities: Locality | Locality[] | QueryOutput<Locality>,
): Locality | Locality[] | QueryOutput<Locality> => {
  if (isLocationQueryOutput(entityOrEntities)) {
    return produce(entityOrEntities, draft => {
      draft.items = entityOrEntities.items.map(normalizer);
    });
  }

  return isLocationArray(entityOrEntities) ? entityOrEntities.map(normalizer) : normalizer(entityOrEntities);
}) as {
  (location: Locality): Locality;
  (locations: Locality[]): Locality[];
  (locationsQueryOutput: QueryOutput<Locality>): QueryOutput<Locality>;
};
