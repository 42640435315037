import { channel, type Channel } from "redux-saga";
import { all, call, put, take, takeEvery, takeLeading, type SagaReturnType } from "redux-saga/effects";

import { contentAssetsActions, type ContentAssetsProgressChannelEvent } from "@ds/modules/content/assets/redux/slice";
import { toastShowErrorAction, toastShowSuccessAction } from "@ds/modules/notifications/redux/actions";

import { storageService, type ProgressInfo, type StorageMethodConfig } from "@ds/services/storage";
import { UnexpectedError } from "@ds/utils/errors";
import { logger } from "@ds/utils/logger";
import { isErrorLike } from "@ds/utils/type-guards/error-guards";

import { getMainTableTypeByApiContentType } from "../../utils/helpers";
import { createThumbnailModel } from "../../utils/thumbnail-model";
import { thumbnailsService } from "../../utils/thumbnails-api";

function* deleteContentAssetThumbnail({
  payload,
  meta,
}: ReturnType<typeof contentAssetsActions.deleteContentAssetThumbnail>) {
  try {
    yield call([thumbnailsService, thumbnailsService.deleteThumbnail], payload);
    yield put(contentAssetsActions.deleteContentAssetThumbnailSucceeded(payload, meta));
    yield put(toastShowSuccessAction("Content asset thumbnail was deleted successfully"));
  } catch (err) {
    const errorTitle = "Delete content asset thumbnail";
    if (isErrorLike(err)) {
      yield put(contentAssetsActions.deleteContentAssetThumbnailFailed(payload, meta, err));
      yield put(toastShowErrorAction(err, errorTitle));
    } else {
      logger.error(`${errorTitle}: ${UnexpectedError}`);
      yield put(toastShowErrorAction(UnexpectedError, errorTitle));
    }
  }
}

function* uploadContentAssetThumbnail(
  progressChannel: Channel<ContentAssetsProgressChannelEvent>,
  { payload, meta }: ReturnType<typeof contentAssetsActions.uploadContentAssetThumbnail>,
) {
  try {
    const uniqueKey = `assets/${new Date().getTime()}_${payload.thumbnailFile.name}`;
    const storageConfig: StorageMethodConfig = {
      level: "public",
      contentType: payload.thumbnailFile.type,
      progressCallback: (progressInfo: ProgressInfo) => progressChannel.put({ progressInfo }),
    };

    const { key }: { key: string } = yield call(
      [storageService, storageService.put],
      uniqueKey,
      payload.thumbnailFile,
      storageConfig,
    );

    const result: SagaReturnType<typeof thumbnailsService.createThumbnail> = yield call(
      [thumbnailsService, thumbnailsService.createThumbnail],
      createThumbnailModel({
        key,
        asset_id: payload.contentAsset.id,
        file_name: payload.thumbnailFile.name,
        file_size: payload.thumbnailFile.size,
        content_type: payload.thumbnailFile.type,
      }),
    );

    yield put(contentAssetsActions.uploadContentAssetThumbnailSucceeded(result, meta));
    yield put(toastShowSuccessAction("Content asset thumbnail was successfully uploaded"));
  } catch (err) {
    const errorTitle = "Upload content asset thumbnail";
    if (isErrorLike(err)) {
      yield put(contentAssetsActions.uploadContentAssetThumbnailFailed(payload, meta, err));
      yield put(toastShowErrorAction(err, errorTitle));
    } else {
      logger.error(`${errorTitle}: ${UnexpectedError}`);
      yield put(toastShowErrorAction(UnexpectedError, errorTitle));
    }
  }
}

function* uploadContentAssetThumbnails(
  progressChannel: Channel<ContentAssetsProgressChannelEvent>,
  { payload, meta }: ReturnType<typeof contentAssetsActions.uploadContentAssetThumbnails>,
) {
  yield all(
    payload.map(entity =>
      call(
        uploadContentAssetThumbnail,
        progressChannel,
        contentAssetsActions.uploadContentAssetThumbnail(
          entity,
          getMainTableTypeByApiContentType(meta.filters?.contentType),
        ),
      ),
    ),
  );
}

export function* watchContentAssetThumbnails() {
  yield takeLeading(contentAssetsActions.deleteContentAssetThumbnail, deleteContentAssetThumbnail);

  const progressChannel: Channel<ContentAssetsProgressChannelEvent> = yield call(channel);
  yield takeEvery(contentAssetsActions.uploadContentAssetThumbnails, uploadContentAssetThumbnails, progressChannel);

  while (true) {
    const { progressInfo }: ContentAssetsProgressChannelEvent = yield take(progressChannel);
    yield put(contentAssetsActions.progressUploadingContentAssetThumbnail(progressInfo));
  }
}
