import { useCallback, useMemo, type FC } from "react";
import styled from "styled-components";

import { contentActions } from "@ds/modules/content/redux/slice";
import { ContentTableTypeEnum } from "@ds/modules/content/utils/model";
import { DeploymentTableTypeEnum } from "@ds/modules/deployments/utils/model";
import { devicesActions } from "@ds/modules/devices/redux/slice";
import { DeviceTableTypeEnum } from "@ds/modules/devices/utils/model";
import { ExperienceTableTypeEnum } from "@ds/modules/experiences/utils/model";
import { LocationTableTypeEnum } from "@ds/modules/locations/utils/model";
import { settingsActions } from "@ds/modules/settings/redux/slice";
import { selectCurrentUserIsTenantAdmin } from "@ds/modules/settings/users/redux/selectors";
import { SettingsTableTypeEnum } from "@ds/modules/settings/utils/model";
import { MainTableDataTypeEnum } from "@ds/modules/table-data/utils/model";

import { TabMenu, type TabMenuTabChangeEvent } from "@ds/components/tab-menu";
import { useRootDispatch, useRootSelector } from "@ds/hooks";

const ContentSeparator = styled.div`
  border-bottom: 1px solid var(--blackShadowColor005);
  width: 100%;
`;

const TableWrapper = styled.div`
  height: var(--tab-menu-header-height);
  padding: 0 16px;
  display: flex;

  .p-tabmenu-nav .p-tabmenuitem .p-menuitem-link {
    font-size: 1.2rem;
    font-weight: 500;
  }
`;

const devicesOptions = [{ label: DeviceTableTypeEnum.Players }, { label: DeviceTableTypeEnum.Peripherals }];
const contentOptions = [
  { label: ContentTableTypeEnum.Playlists },
  { label: ContentTableTypeEnum.Videos },
  { label: ContentTableTypeEnum.Images },
  { label: ContentTableTypeEnum.Audio },
];

const getOptionsByTableType = (isTenantAdmin = false) => {
  const optionsByTableType = {
    [MainTableDataTypeEnum.Experiences]: [{ label: ExperienceTableTypeEnum.Experiences }],
    [MainTableDataTypeEnum.DevicePlayers]: devicesOptions,
    [MainTableDataTypeEnum.DevicePeripherals]: devicesOptions,
    [MainTableDataTypeEnum.Locations]: [{ label: LocationTableTypeEnum.Locations }],
    [MainTableDataTypeEnum.ContentPlaylists]: contentOptions,
    [MainTableDataTypeEnum.ContentVideos]: contentOptions,
    [MainTableDataTypeEnum.ContentImages]: contentOptions,
    [MainTableDataTypeEnum.ContentAudio]: contentOptions,
    [MainTableDataTypeEnum.Deployments]: [{ label: DeploymentTableTypeEnum.Deployments }],
  } as Record<
    MainTableDataTypeEnum,
    {
      label:
        | ExperienceTableTypeEnum
        | DeviceTableTypeEnum
        | LocationTableTypeEnum
        | ContentTableTypeEnum
        | DeploymentTableTypeEnum
        | SettingsTableTypeEnum;
    }[]
  >;

  const settingsOptions = isTenantAdmin
    ? [
        { label: SettingsTableTypeEnum.Projects },
        { label: SettingsTableTypeEnum.Users },
        { label: SettingsTableTypeEnum.Invitations },
      ]
    : [{ label: SettingsTableTypeEnum.Projects }];

  return isTenantAdmin
    ? {
        ...optionsByTableType,
        [MainTableDataTypeEnum.Projects]: settingsOptions,
        [MainTableDataTypeEnum.Users]: settingsOptions,
        [MainTableDataTypeEnum.Invitations]: settingsOptions,
      }
    : { ...optionsByTableType, [MainTableDataTypeEnum.Projects]: settingsOptions };
};

export const TableTabMenu: FC<{
  tableType: MainTableDataTypeEnum;
  currentView:
    | ExperienceTableTypeEnum
    | DeviceTableTypeEnum
    | LocationTableTypeEnum
    | ContentTableTypeEnum
    | DeploymentTableTypeEnum
    | SettingsTableTypeEnum;
}> = ({ tableType, currentView }) => {
  const isUserTabVisible = useRootSelector(selectCurrentUserIsTenantAdmin);

  const optionsByTableType = useMemo(() => getOptionsByTableType(isUserTabVisible), [isUserTabVisible]);

  const dispatch = useRootDispatch();
  const onSelectHandler = useCallback(
    ({ index }: TabMenuTabChangeEvent) => {
      if (tableType === MainTableDataTypeEnum.DevicePlayers || tableType === MainTableDataTypeEnum.DevicePeripherals) {
        dispatch(devicesActions.switchView(optionsByTableType[tableType][index].label as DeviceTableTypeEnum));
      }

      if (
        tableType === MainTableDataTypeEnum.ContentPlaylists ||
        tableType === MainTableDataTypeEnum.ContentImages ||
        tableType === MainTableDataTypeEnum.ContentVideos ||
        tableType === MainTableDataTypeEnum.ContentAudio
      ) {
        dispatch(contentActions.switchView(optionsByTableType[tableType][index].label as ContentTableTypeEnum));
      }

      if (
        tableType === MainTableDataTypeEnum.Projects ||
        tableType === MainTableDataTypeEnum.Users ||
        tableType === MainTableDataTypeEnum.Invitations
      ) {
        dispatch(settingsActions.switchView(optionsByTableType[tableType][index].label as SettingsTableTypeEnum));
      }
    },
    [dispatch, optionsByTableType, tableType],
  );

  return (
    <>
      <TableWrapper>
        <TabMenu selectedOption={currentView} options={optionsByTableType[tableType]} onTabChange={onSelectHandler} />
      </TableWrapper>
      <ContentSeparator />
    </>
  );
};
