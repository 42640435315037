import { useDebouncedCallback } from "@react-hookz/web";
import { produce } from "immer";
import { useCallback, useEffect, useState } from "react";

import { selectTableData } from "@ds/modules/table-data/redux/selectors";
import { DEFAULT_TABLE_TAB, tableDataActions } from "@ds/modules/table-data/redux/slice";
import { DetailsTableDataTypeEnum, MainTableDataTypeEnum } from "@ds/modules/table-data/utils/model";

import { useDeepRootSelector, useRootDispatch } from "./redux-hooks";

const SEARCH_INPUT_TIMEOUT = 300;

export const useTableDataSearch = (
  tableType: MainTableDataTypeEnum | DetailsTableDataTypeEnum,
  tableTab = DEFAULT_TABLE_TAB,
  queryFilters?: KeysToCamelCase<QueryFilters>,
) => {
  const { queryInfo } = useDeepRootSelector(state => selectTableData(state, tableType, tableTab, queryFilters));
  const [query, setQuery] = useState(queryInfo.pattern || "");

  useEffect(() => setQuery(queryInfo.pattern || ""), [queryInfo.pattern]);

  const dispatch = useRootDispatch();
  const startSearching = useDebouncedCallback(
    (newPattern: string) => {
      dispatch(
        tableDataActions.updateTableData({
          tableType,
          tableTab,
          queryInfo: produce(queryInfo, draft => {
            draft.pattern = newPattern;
          }),
          options: { shouldDisableSagaThrottling: true },
        }),
      );
    },
    [dispatch, tableType, tableTab, queryInfo],
    SEARCH_INPUT_TIMEOUT,
  );

  return [
    query,
    useCallback(
      (newPattern: string) => {
        setQuery(newPattern);
        startSearching(newPattern);
      },
      [startSearching],
    ),
  ] as const;
};
