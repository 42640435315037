import { yupResolver } from "@hookform/resolvers/yup";
import { noop } from "lodash";
import { useCallback, type FC } from "react";
import { useForm } from "react-hook-form";
import styled from "styled-components";

import { useRootDispatch, useRootSelector } from "@ds/hooks/redux-hooks";
import { useTypedLocation } from "@ds/hooks/use-typed-location";

import { Button, ButtonType } from "@ds/components/forms/buttons";
import { IconType } from "@ds/components/icons";
import { Dialog } from "@ds/components/modals";
import { NavTextLink } from "@ds/components/text-link";
import { FORGOT_PASSWORD_ROUTE } from "@ds/constants/router";

import { selectAuthIsLoading } from "../../redux/selectors";
import { authActions } from "../../redux/slice";
import { type UserRestorePasswordModel } from "../../redux/types";
import { restorePasswordValidationSchema } from "../../utils/validation-schema";
import { PasswordControl } from "./controls/password-control";
import { RestoreCodeControl } from "./controls/restore-code-control";
import { UsernameControl } from "./controls/username-signin-control";

const Form = styled.form`
  width: 400px;
  padding: 0 30px;
`;

export const UserRestorePassword: FC = () => {
  const { state: routeState } = useTypedLocation<{
    username: string;
  }>();

  const { control, watch, getValues, handleSubmit } = useForm<UserRestorePasswordModel>({
    mode: "all",
    reValidateMode: "onChange",
    defaultValues: {
      username: routeState?.username || "",
      password: "",
      code: "",
    },
    resolver: yupResolver(restorePasswordValidationSchema),
  });

  const isLoading = useRootSelector(selectAuthIsLoading);

  const dispatch = useRootDispatch();
  const onSubmitHandler = useCallback(() => dispatch(authActions.restorePassword(getValues())), [dispatch, getValues]);

  const currentUsername = watch("username");
  return (
    <Dialog modal visible showHeader={false} onHide={noop}>
      <Form onSubmit={handleSubmit(onSubmitHandler)} noValidate>
        <h2 className="text-center">Restore password</h2>
        <UsernameControl control={control} autoComplete="email" />
        <PasswordControl control={control} label="New Password" autoComplete="new-password" />
        <RestoreCodeControl control={control} />

        <div className="grid m-0">
          <div className="col">
            <NavTextLink to={{ pathname: FORGOT_PASSWORD_ROUTE }} state={{ username: currentUsername }}>
              Resend email
            </NavTextLink>
          </div>

          <Button
            label="Restore password"
            type="submit"
            buttonType={ButtonType.Raised}
            icon={IconType.Right}
            iconPosition="right"
            isLoading={isLoading}
          />
        </div>
      </Form>
    </Dialog>
  );
};
