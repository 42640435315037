import { type FC } from "react";
import { Controller, type Control } from "react-hook-form";

import { LabelWrapper } from "@ds/components/forms/labels";

import { LocationTargets } from "./targets/location-targets";

export const DeploymentTargetsLocationsControl: FC<{ control: Control<Deployment> }> = ({ control }) => (
  <Controller
    name="targets"
    control={control}
    render={({ field: { ref, ...restFields }, fieldState }) => (
      <LabelWrapper label="Locations" className="col-8" error={fieldState.error} isRequired>
        <LocationTargets inputRef={ref} {...restFields} />
      </LabelWrapper>
    )}
  />
);
