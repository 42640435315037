import { useAsyncAbortable, useDebouncedCallback, useIsMounted, type UseAsyncAbortableActions } from "@react-hookz/web";
import { useMemo, useState } from "react";

import { logger } from "@ds/utils/logger";

export const AUTOCOMPLETE_DEBOUNCE_TIMEOUT = 200;
const DEFAULT_AUTOCOMPLETE_SORT_FIELD = "id";
const DEFAULT_AUTOCOMPLETE_SORT_ORDER = "desc";
const DEFAULT_AUTOCOMPLETE_QUERY_LIMIT = 7;

export enum AutocompleteTypeEnum {
  Experiences = "EXPERIENCES",
  Players = "PLAYERS",
  Locations = "LOCATIONS",
  Playlists = "PLAYLISTS",
  ContentAssets = "CONTENT_ASSETS",
  Projects = "PROJECTS",
}

type AutocompleteOptions<T> = {
  sortField?: "id" | "experience_id";
  sortOrder?: SortOrder;
  queryFilter?: string;
  queryLimit?: number;
  filterPredicate?: (entry: T, index: number, array: T[]) => boolean;
};

const useFetchEntities = <T>(entityType: AutocompleteTypeEnum, options?: AutocompleteOptions<T>) => {
  const [{ result, status, error }, actions, meta] = useAsyncAbortable(
    async (_, searchQuery: string, queryFilter?: string) => {
      const filterToUse = queryFilter || options?.queryFilter || "";
      const queryInfo: QueryInfo = {
        pattern: searchQuery,
        sortField: options?.sortField || DEFAULT_AUTOCOMPLETE_SORT_FIELD,
        sortOrder: options?.sortOrder || DEFAULT_AUTOCOMPLETE_SORT_ORDER,
      };

      const pagination: Pagination = {
        pageNumber: 1,
        pageSize: options?.queryLimit || DEFAULT_AUTOCOMPLETE_QUERY_LIMIT,
      };

      let module = null;
      switch (entityType) {
        case AutocompleteTypeEnum.Experiences:
          module = await import("@ds/modules/experiences/utils/api");
          return module.experiencesService.getExperiences(queryInfo, pagination);
        case AutocompleteTypeEnum.Players:
          module = await import("@ds/modules/devices/players/utils/api");
          return module.playersService.getPlayers(queryInfo, pagination);
        case AutocompleteTypeEnum.Locations:
          module = await import("@ds/modules/locations/utils/api");
          return module.locationsService.getLocations(queryInfo, pagination);
        case AutocompleteTypeEnum.Playlists:
          module = await import("@ds/modules/content/playlists/utils/api");
          return module.playlistsService.getPlaylists(queryInfo, pagination);
        case AutocompleteTypeEnum.ContentAssets:
          queryInfo.filters = filterToUse ? { content_type: filterToUse as ApiContentType } : undefined;
          module = await import("@ds/modules/content/assets/utils/content-assets-api");
          return module.contentAssetsService.getAssets(queryInfo, pagination);
        case AutocompleteTypeEnum.Projects:
          module = await import("@ds/modules/settings/projects/utils/api");
          return module.projectsService.getProjects(queryInfo, pagination);
        default:
          logger.error(`module ${entityType} not found`);
      }
    },
  );

  return [
    {
      data: (result?.items || new Array<T>()) as T[],
      status,
      error,
    },
    actions as unknown as UseAsyncAbortableActions<QueryOutput<T>, Parameters<typeof actions.execute>>,
    meta,
  ] as const;
};

export const useFetchEntitiesAutocomplete = <T>(
  entityType: AutocompleteTypeEnum,
  options?: AutocompleteOptions<T>,
  debounceTimeout = AUTOCOMPLETE_DEBOUNCE_TIMEOUT,
) => {
  const isMounted = useIsMounted();
  const [suggestions, setSuggestions] = useState<T[]>([]);
  const [state, actions, meta] = useFetchEntities<T>(entityType, options);
  const filteredSuggestions = useMemo(
    () => (suggestions.length && options?.filterPredicate ? suggestions.filter(options.filterPredicate) : suggestions),
    [suggestions, options?.filterPredicate],
  );

  return [
    filteredSuggestions,
    useDebouncedCallback(
      async (searchQuery: string, queryFilter?: string) => {
        const result = await actions.execute(searchQuery, queryFilter);
        if (isMounted()) {
          setSuggestions(result.items);
        }
      },
      [actions, isMounted],
      debounceTimeout,
    ),
    { state, actions, meta },
  ] as const;
};
