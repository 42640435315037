import { isNumber, uniq } from "lodash";
import { useEffect, useMemo, type FC } from "react";

import { useGetAggregatedEntityIds } from "@ds/hooks/use-get-aggregated-entity-ids";

import { EntityTypeName } from "@ds/model/entity-model";

import { getExperienceStatusIndicatorWithNameAndLink } from "@ds/modules/experiences/components/common/status-indicator";
import { selectExperienceById, selectExperiencesIsLoading } from "@ds/modules/experiences/redux/selectors";
import { experiencesActions } from "@ds/modules/experiences/redux/slice";

import { MultipleValuesLabelWithLink, UnassignedLabel } from "@ds/components/forms/labels/common";
import { InfoSection } from "@ds/components/info-view";
import { SpinnerLoader2 } from "@ds/components/loaders/loaders";
import { useDeepRootSelector, useRootDispatch, useRootSelector } from "@ds/hooks";
import { isMultipleFieldValuesInAggregatedEntity } from "@ds/utils/entities";
import { composeDetailsRouteByEntityIds } from "@ds/utils/router";

import { selectPlayersByIds } from "../../redux/selectors";

export const PlayerExperienceInfoSection: FC<{ player: Player }> = ({ player }) => {
  const aggregatedPlayerIds = useGetAggregatedEntityIds(player);
  const aggregatedPlayersExperienceIds = useDeepRootSelector(state => {
    const aggregatedPlayers = selectPlayersByIds(state, aggregatedPlayerIds);
    return uniq(aggregatedPlayers.map(({ experience_id }) => experience_id)).filter(isNumber);
  });

  const experience = useRootSelector(state => selectExperienceById(state, player.experience_id || 0));
  const isLoading = useRootSelector(selectExperiencesIsLoading);

  const dispatch = useRootDispatch();
  useEffect(() => {
    if (player.experience_id) {
      dispatch(experiencesActions.fetchExperience(player.experience_id));
    }
  }, [dispatch, player.experience_id]);

  const infoBody = useMemo(() => {
    let infoData = <SpinnerLoader2 />;
    if (!isLoading) {
      infoData = isMultipleFieldValuesInAggregatedEntity(player, "experience_id") ? (
        <MultipleValuesLabelWithLink
          to={composeDetailsRouteByEntityIds(aggregatedPlayersExperienceIds, EntityTypeName.EXPERIENCE)}
        />
      ) : (
        <>{experience?.name || <UnassignedLabel text="Player(s) has no experience" />}</>
      );
    }

    return experience ? getExperienceStatusIndicatorWithNameAndLink(experience) : infoData;
  }, [isLoading, player, experience, aggregatedPlayersExperienceIds]);

  return (
    <InfoSection title="Experience" icon="pi pi-play">
      {infoBody}
    </InfoSection>
  );
};
