import { yupResolver } from "@hookform/resolvers/yup";
import { type FC } from "react";
import { type DeepPartial } from "react-hook-form";
import styled from "styled-components";

import {
  DetailsCard,
  DetailsCardEditActionsMenu,
  DetailsCardRow,
  EditableDetailsCardDecorator,
  EditableDetailsCardRowDecorator,
} from "@ds/components/details-view";
import { AggregatedValue } from "@ds/components/entities";
import { GoogleSearchTypesEnum } from "@ds/services/google-api";

import { updateValidationSchema } from "../../utils/validation-schema";
import { LocationMap } from "../common/location-map";
import { AddressAutoCompleteControl } from "../forms/controls/address-autocomplete-control";
import { AddressCountryControl } from "../forms/controls/address-country-control";
import { AddressStreet2Control } from "../forms/controls/address-street-2-control";

const MapWrapper = styled.div`
  margin-bottom: 30px;
`;

export const LocationDetailsAddressCard: FC<{
  location: Locality;
  onSubmit: (changedFields: DeepPartial<Locality>) => void;
}> = ({ location, onSubmit }) => (
  <EditableDetailsCardDecorator
    initialValue={location}
    validationResolver={yupResolver(updateValidationSchema.address)}
    onSubmit={onSubmit}
  >
    {cardProps => (
      <DetailsCard
        title="Address"
        icon="map-marker"
        actions={
          <DetailsCardEditActionsMenu
            isEditing={cardProps.isEditing}
            isDirty={cardProps.isDirty}
            onReset={cardProps.onResetForm}
            onSubmit={cardProps.handleSubmit(cardProps.onSubmitForm)}
          />
        }
      >
        <MapWrapper>
          <LocationMap coordinates={cardProps.entity.address?.coordinates} height={250} />
        </MapWrapper>

        <EditableDetailsCardRowDecorator
          field="address.country"
          input={
            <AddressCountryControl control={cardProps.control} setValue={cardProps.setValue} excludeLabel autoFocus />
          }
          {...cardProps}
        >
          {rowProps => (
            <DetailsCardRow label="Country" editableSettings={rowProps}>
              <AggregatedValue entity={cardProps.entity} field="address.country" />
            </DetailsCardRow>
          )}
        </EditableDetailsCardRowDecorator>

        <EditableDetailsCardRowDecorator
          field="address.organization"
          input={
            <AddressAutoCompleteControl
              control={cardProps.control}
              setValue={cardProps.setValue}
              name="address.organization"
              label="Search Business"
              placeholder="Enter business name"
              searchType={GoogleSearchTypesEnum.ESTABLISHMENT}
              shouldUpdateOpenHours={false}
              excludeLabel
              autoFocus
            />
          }
          {...cardProps}
        >
          {rowProps => (
            <DetailsCardRow label="Business Name" editableSettings={rowProps}>
              <AggregatedValue entity={cardProps.entity} field="address.organization" />
            </DetailsCardRow>
          )}
        </EditableDetailsCardRowDecorator>

        <EditableDetailsCardRowDecorator
          field="address.street_address.0"
          input={
            <AddressAutoCompleteControl
              control={cardProps.control}
              setValue={cardProps.setValue}
              name="address.street_address.0"
              label="Address Line 1"
              placeholder="Street address or P.O. Box"
              searchType={GoogleSearchTypesEnum.ADDRESS}
              shouldUpdateOpenHours={false}
              excludeLabel
              autoFocus
            />
          }
          {...cardProps}
        >
          {rowProps => (
            <DetailsCardRow label="Street 1" editableSettings={rowProps}>
              <AggregatedValue entity={cardProps.entity} field="address.street_address.0" />
            </DetailsCardRow>
          )}
        </EditableDetailsCardRowDecorator>

        <EditableDetailsCardRowDecorator
          field="address.street_address.1"
          input={<AddressStreet2Control control={cardProps.control} excludeLabel autoFocus />}
          {...cardProps}
        >
          {rowProps => (
            <DetailsCardRow label="Street 2" editableSettings={rowProps}>
              <AggregatedValue entity={cardProps.entity} field="address.street_address.1" />
            </DetailsCardRow>
          )}
        </EditableDetailsCardRowDecorator>

        <DetailsCardRow label="City">
          <AggregatedValue entity={cardProps.entity} field="address.locality" />
        </DetailsCardRow>

        <DetailsCardRow label="State">
          <AggregatedValue entity={cardProps.entity} field="address.administrative_area" />
        </DetailsCardRow>

        <DetailsCardRow label="ZIP Code">
          <AggregatedValue entity={cardProps.entity} field="address.post_code" />
        </DetailsCardRow>
      </DetailsCard>
    )}
  </EditableDetailsCardDecorator>
);
