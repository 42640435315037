import { StatusColor, StatusEnum, StatusIconClassName, type StatusNames } from "@ds/model/status-model";

import { LoadStatus } from "./reducer";

export const getStatusColorByLoadStatus = (loadStatus: LoadStatus) => {
  switch (loadStatus) {
    case LoadStatus.Failed:
      return StatusColor["000#error"];
    case LoadStatus.Succeeded:
      return StatusColor["300#succeeded"];
    case LoadStatus.Loading:
      return StatusColor["200#pending"];
  }
};

export const getStatusIconClassNameByLoadStatus = (loadStatus: LoadStatus) => {
  switch (loadStatus) {
    case LoadStatus.Failed:
      return StatusIconClassName["000#error"];
    case LoadStatus.Succeeded:
      return StatusIconClassName["300#succeeded"];
    case LoadStatus.Loading:
      return StatusIconClassName["200#pending"];
    default:
      return StatusIconClassName["200#pending"];
  }
};

export const getStatusIconClassNameByStatus = (status: Status) => {
  switch (status) {
    case StatusEnum.Failed:
      return StatusIconClassName["000#error"];
    case StatusEnum.Succeeded:
      return StatusIconClassName["300#succeeded"];
    case StatusEnum.Cancelled:
      return StatusIconClassName["100#cancelled"];
    case StatusEnum.Banned:
      return StatusIconClassName["100#banned"];
    default:
      return StatusIconClassName["200#pending"];
  }
};

export const getPrintableStatus = (status: Status = StatusEnum.Unknown) => status.split("#")[1] as StatusNames;
