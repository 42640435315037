export enum MainTableDataTypeEnum {
  Experiences = "EXPERIENCES",
  DevicePlayers = "DEVICE_PLAYERS",
  DevicePeripherals = "DEVICE_PERIPHERALS",
  Locations = "LOCATIONS",
  ContentPlaylists = "CONTENT_PLAYLISTS",
  ContentVideos = "CONTENT_VIDEOS",
  ContentImages = "CONTENT_IMAGES",
  ContentAudio = "CONTENT_AUDIO",
  Deployments = "DEPLOYMENTS",
  Projects = "PROJECTS",
  Users = "USERS",
  Invitations = "INVITATIONS",
}

export enum DetailsTableDataTypeEnum {
  LocationExperiences = "LOCATION_EXPERIENCES",
  LocationPlayers = "LOCATION_PLAYERS",
  ContentPlaylistAssets = "CONTENT_PLAYLIST_ASSETS",
  DeploymentDeviceSyncs = "DEPLOYMENT_DEVICE_SYNCS",
}
