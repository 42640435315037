import { createSlice, type PayloadAction } from "@reduxjs/toolkit";
import { isBoolean } from "lodash";
import { all } from "redux-saga/effects";

import { contentAssetsActions } from "@ds/modules/content/assets/redux/slice";
import { playlistAssetsActions, type PlaylistAssetsMeta } from "@ds/modules/content/playlist-assets/redux/slice";
import { playlistsActions } from "@ds/modules/content/playlists/redux/slice";
import { deploymentsActions } from "@ds/modules/deployments/redux/slice";
import { deviceSyncsActions, DeviceSyncsMeta } from "@ds/modules/device-syncs/redux/slice";
import { peripheralsActions } from "@ds/modules/devices/peripherals/redux/slice";
import { DevicePlayersMeta, playersActions } from "@ds/modules/devices/players/redux/slice";
import { experiencesActions, ExperiencesMeta } from "@ds/modules/experiences/redux/slice";
import { locationsActions } from "@ds/modules/locations/redux/slice";
import { invitationsActions } from "@ds/modules/settings/invitations/redux/slice";
import { projectsActions } from "@ds/modules/settings/projects/redux/slice";
import { usersActions } from "@ds/modules/settings/users/redux/slice";

import { REACT_APP_PAGE_SIZE } from "@ds/constants/environment";
import { SagaOptions } from "@ds/utils/saga-helpers";

import { snakifyFilters } from "../utils/common";
import { isMainTableDataTypeEnum } from "../utils/helpers";
import { DetailsTableDataTypeEnum, MainTableDataTypeEnum } from "../utils/model";
import { type BaseMeta } from "./../../../utils/reducer";
import { watchTableData } from "./sagas";
import { type BaseTableDataSlice, type TableDataPerEntitySlice } from "./types";

export const DEFAULT_TABLE_TAB = "common";
export const DEFAULT_SORT_FIELD = "id";
export const DEFAULT_SORT_ORDER = "desc";

export const PLAYLIST_ASSETS_SORT_FIELD = "playlist_order";
export const PLAYLIST_ASSETS_SORT_ORDER = "asc";

export const getBaseEntityPerCategorySlice = (tableType: MainTableDataTypeEnum | DetailsTableDataTypeEnum) => {
  let state: BaseTableDataSlice = {
    isFetchedAlready: false,
    sorting: {
      sortField: DEFAULT_SORT_FIELD,
      sortOrder: DEFAULT_SORT_ORDER,
    },
    pagination: {
      pageNumber: 1,
      pageSize: REACT_APP_PAGE_SIZE,
      total: undefined,
    },
    queryInfo: {
      filters: {},
      pattern: "",
    },
  };

  if (isMainTableDataTypeEnum(tableType)) {
    state = {
      showInfoView: true,
      ...state,
    };
  }

  if (tableType === DetailsTableDataTypeEnum.ContentPlaylistAssets) {
    state.sorting.sortField = PLAYLIST_ASSETS_SORT_FIELD;
    state.sorting.sortOrder = PLAYLIST_ASSETS_SORT_ORDER;
  }

  return state;
};

const initialState: TableDataPerEntitySlice = {
  [MainTableDataTypeEnum.Experiences]: {},
  [MainTableDataTypeEnum.DevicePlayers]: {},
  [MainTableDataTypeEnum.DevicePeripherals]: {},
  [MainTableDataTypeEnum.Locations]: {},
  [DetailsTableDataTypeEnum.LocationExperiences]: {},
  [DetailsTableDataTypeEnum.LocationPlayers]: {},
  [MainTableDataTypeEnum.ContentPlaylists]: {},
  [DetailsTableDataTypeEnum.ContentPlaylistAssets]: {},
  [MainTableDataTypeEnum.ContentVideos]: {},
  [MainTableDataTypeEnum.ContentImages]: {},
  [MainTableDataTypeEnum.ContentAudio]: {},
  [MainTableDataTypeEnum.Deployments]: {},
  [DetailsTableDataTypeEnum.DeploymentDeviceSyncs]: {},
  [MainTableDataTypeEnum.Projects]: {},
  [MainTableDataTypeEnum.Users]: {},
  [MainTableDataTypeEnum.Invitations]: {},
};

const sliceType = "TABLE_DATA";
const slice = createSlice({
  name: sliceType,
  initialState,
  reducers: {
    toggleInfoView: (
      state,
      {
        payload: { tableType, tableTab = DEFAULT_TABLE_TAB, showInfoView },
      }: PayloadAction<{
        tableType: MainTableDataTypeEnum | DetailsTableDataTypeEnum;
        tableTab?: string;
        showInfoView?: boolean;
      }>,
    ) => {
      state[tableType][tableTab] = state[tableType][tableTab] || getBaseEntityPerCategorySlice(tableType);
      state[tableType][tableTab].showInfoView = isBoolean(showInfoView)
        ? showInfoView
        : !state[tableType][tableTab].showInfoView;
    },
    updateTableData: (
      state,
      {
        payload: { tableType, tableTab = DEFAULT_TABLE_TAB, sorting, pagination, queryInfo },
      }: PayloadAction<{
        tableType: MainTableDataTypeEnum | DetailsTableDataTypeEnum;
        tableTab?: string;
        sorting?: SortingSlice;
        pagination?: PaginationSlice;
        queryInfo?: QuerySlice;
        options?: SagaOptions;
      }>,
    ) => {
      state[tableType][tableTab] = state[tableType][tableTab] || getBaseEntityPerCategorySlice(tableType);
      if (sorting) {
        state[tableType][tableTab].sorting = sorting;
      }

      if (pagination) {
        state[tableType][tableTab].pagination.pageNumber = pagination.pageNumber;
        state[tableType][tableTab].pagination.pageSize = pagination.pageSize;
      }

      if (queryInfo) {
        state[tableType][tableTab].queryInfo.pattern = queryInfo.pattern;
        state[tableType][tableTab].queryInfo.filters = queryInfo.filters;
      }
    },
  },
  extraReducers: builder => {
    const handleResponse =
      (tableTab = DEFAULT_TABLE_TAB) =>
      (
        state: TableDataPerEntitySlice,
        {
          payload,
          meta,
        }: PayloadAction<
          QueryOutput,
          string,
          BaseMeta & {
            filters?: CamelCaseQueryFilters;
          }
        >,
      ) => {
        if (meta.options?.tableType) {
          state[meta.options.tableType][tableTab] =
            state[meta.options.tableType][tableTab] || getBaseEntityPerCategorySlice(meta.options.tableType);

          if (
            meta.options?.tableType !== MainTableDataTypeEnum.Invitations &&
            meta.options?.tableType !== DetailsTableDataTypeEnum.LocationExperiences &&
            meta.options?.tableType !== DetailsTableDataTypeEnum.LocationPlayers &&
            meta.options?.tableType !== DetailsTableDataTypeEnum.ContentPlaylistAssets &&
            meta.options?.tableType !== DetailsTableDataTypeEnum.DeploymentDeviceSyncs
          ) {
            state[meta.options.tableType][tableTab].isFetchedAlready = true;
          }

          if (payload.pagination) {
            state[meta.options.tableType][tableTab].pagination = payload.pagination;
          }

          state[meta.options.tableType][tableTab].queryInfo.filters = snakifyFilters(meta.filters || {});
        }
      };

    builder.addCase(
      experiencesActions.fetchExperiencesSucceeded,
      (state: TableDataPerEntitySlice, action: PayloadAction<QueryOutput<Experience>, string, ExperiencesMeta>) =>
        handleResponse(
          action.meta.filters?.locationId?.length === 1
            ? action.meta.filters.locationId[0].toString()
            : DEFAULT_TABLE_TAB,
        )(state, action),
    );

    builder.addCase(
      playersActions.fetchPlayersSucceeded,
      (state: TableDataPerEntitySlice, action: PayloadAction<QueryOutput<Player>, string, DevicePlayersMeta>) =>
        handleResponse(
          action.meta.filters?.locationId?.length === 1
            ? action.meta.filters.locationId[0].toString()
            : DEFAULT_TABLE_TAB,
        )(state, action),
    );

    builder.addCase(peripheralsActions.fetchPeripheralsSucceeded, handleResponse());
    builder.addCase(locationsActions.fetchLocationsSucceeded, handleResponse());
    builder.addCase(playlistsActions.fetchPlaylistsSucceeded, handleResponse());
    builder.addCase(
      playlistAssetsActions.fetchPlaylistAssetsSucceeded,
      (state: TableDataPerEntitySlice, action: PayloadAction<QueryOutput<PlaylistAsset>, string, PlaylistAssetsMeta>) =>
        handleResponse(action.meta.filters?.playlistId?.toString())(state, action),
    );

    builder.addCase(contentAssetsActions.fetchContentAssetsSucceeded, handleResponse());
    builder.addCase(deploymentsActions.fetchDeploymentsSucceeded, handleResponse());
    builder.addCase(
      deviceSyncsActions.fetchDeviceSyncsSucceeded,
      (state: TableDataPerEntitySlice, action: PayloadAction<QueryOutput<DeviceSync>, string, DeviceSyncsMeta>) =>
        handleResponse(action.meta.filters?.deploymentId?.toString())(state, action),
    );

    builder.addCase(projectsActions.fetchProjectsSucceeded, handleResponse());
    builder.addCase(usersActions.fetchUsersSucceeded, handleResponse());
    builder.addCase(invitationsActions.fetchInvitationsSucceeded, handleResponse());
  },
});

export const { name: tableDataType, actions: tableDataActions, reducer: tableDataReducer } = slice;

export function* rootTableDataSaga() {
  yield all([watchTableData()]);
}
