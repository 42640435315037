import { snakifyProps } from "@ds/utils/strings";
import { isNumberArray } from "@ds/utils/type-guards/common-guards";

export const convertToApiQueryInfo = (
  { sortField, sortOrder }: SortingSlice,
  { filters, pattern }: QuerySlice,
): QueryInfo => ({
  sortField,
  sortOrder,
  filters,
  pattern,
});

export const normalizeFilters = (obj: Record<string, unknown>) =>
  Object.fromEntries(Object.entries(obj).map(([k, v]) => (isNumberArray(v) ? [k, `[${v.join(",")}]`] : [k, v])));

export const snakifyFilters = (obj: KeysToCamelCase<QueryFilters>): QueryFilters => snakifyProps(obj);
