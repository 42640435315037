import { Storage as AmplifyStorage } from "aws-amplify";
import { useAsyncAbortable, useIsMounted } from "@react-hookz/web";
import { useCallback, useState } from "react";
import { isString } from "lodash";

export const useFetchAmplifyStorageData = () => {
  const [{ result: data = [], status, error }, actions, meta] = useAsyncAbortable(async (_, key: string) =>
    AmplifyStorage.get(key),
  );

  return [
    {
      data,
      status,
      error,
    },
    actions,
    meta,
  ] as const;
};

export const useFetchAmplifyStorageDataSignedUrl = () => {
  const isMounted = useIsMounted();
  const [signedUrl, setSignedUrl] = useState<string | undefined>();
  const [state, actions, meta] = useFetchAmplifyStorageData();
  return [
    signedUrl,
    useCallback(
      async (key: string | undefined) => {
        if (key) {
          const result = await actions.execute(key);
          if (isString(result) && isMounted()) {
            setSignedUrl(result);
          }
        } else {
          setSignedUrl(undefined);
        }
      },
      [actions, isMounted],
    ),
    { state, actions, meta },
  ] as const;
};
