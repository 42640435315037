export enum AuthErrorCode {
  NotConfiguredAmplify = "NotConfiguredAmplify",
}

export class AuthError extends Error {
  constructor(code: AuthErrorCode) {
    super(code.toString());
    this.code = code;
  }

  code: AuthErrorCode;
}

const isAuthError = (obj: unknown): obj is AuthError => obj instanceof AuthError;

export const isAuthNotConfiguredAmplifyError = (obj: unknown): obj is AuthError =>
  isAuthError(obj) && obj.code === AuthErrorCode.NotConfiguredAmplify;
