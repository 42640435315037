import { nanoid } from "@reduxjs/toolkit";
import { useCallback, type ComponentProps, type FC } from "react";

import { getExperienceStatusIndicatorWithName } from "@ds/modules/experiences/components/common/status-indicator";
import { ExperiencesAutoComplete } from "@ds/modules/experiences/components/experiences-autocomplete";
import { selectExperiencesByIds } from "@ds/modules/experiences/redux/selectors";

import { AutoCompleteMultipleDecorator } from "@ds/components/forms/autocomplete";
import { useRootSelector } from "@ds/hooks";

import { Wrapper } from "./styles";
import { type DeploymentTargetsProps } from "./types";

export const ExperienceTargets: FC<DeploymentTargetsProps> = ({ inputRef, value, onChange }) => {
  const experiences = useRootSelector(state => selectExperiencesByIds(state, value));

  const onChangeHandler = useCallback(
    (entities: Experience[]) => {
      const ids = entities.map(({ id }) => id);
      onChange(ids);
    },
    [onChange],
  );

  return (
    <Wrapper>
      <AutoCompleteMultipleDecorator
        inputRef={inputRef}
        value={experiences}
        onChange={onChangeHandler}
        chipsMapper={(experience: Experience) => [
          `deployment-experience-chip-${nanoid()}`,
          experience.name,
          {
            template: getExperienceStatusIndicatorWithName(experience),
          },
        ]}
      >
        {(props: ComponentProps<typeof ExperiencesAutoComplete>) => <ExperiencesAutoComplete {...props} />}
      </AutoCompleteMultipleDecorator>
    </Wrapper>
  );
};
