import { isContentTypeAudio, isContentTypeImage, isContentTypeVideo } from "@ds/modules/content/assets/utils/helpers";

import { assetMediaTypes } from "../../utils/constants";

export const isPlaylistSupportVideos = (playlist: Playlist) => playlist.media_types.some(isContentTypeVideo);

export const isPlaylistSupportImages = (playlist: Playlist) => playlist.media_types.some(isContentTypeImage);

export const isPlaylistSupportAudio = (playlist: Playlist) => playlist.media_types.some(isContentTypeAudio);

export const getSupportedMediaTypes = (playlist: Playlist) => {
  let result = {};
  if (isPlaylistSupportVideos(playlist)) {
    result = { ...result, ...assetMediaTypes.video };
  }

  if (isPlaylistSupportImages(playlist)) {
    result = { ...result, ...assetMediaTypes.image };
  }
  if (isPlaylistSupportAudio(playlist)) {
    result = { ...result, ...assetMediaTypes.audio };
  }

  return result;
};

export const getContentTypesFilterByPlaylist = (playlist: Playlist) => {
  const isVideoCompatible = isPlaylistSupportVideos(playlist);
  const isImageCompatible = isPlaylistSupportImages(playlist);
  const isAudioCompatible = isPlaylistSupportAudio(playlist);
  if (!isVideoCompatible && !isImageCompatible && !isAudioCompatible) {
    return undefined;
  }

  let result: string[] = [];
  if (isVideoCompatible) {
    result = result.concat(Object.keys(assetMediaTypes.video));
  }

  if (isImageCompatible) {
    result = result.concat(Object.keys(assetMediaTypes.image));
  }

  if (isAudioCompatible) {
    result = result.concat(Object.keys(assetMediaTypes.audio));
  }

  return `[${result.join(",")}]`;
};
