import { isNumber, isString } from "lodash";
import { type ChangeEvent } from "react";
import { type ControllerFieldState, type FieldError } from "react-hook-form";

export const isFieldError = (obj: unknown): obj is FieldError =>
  !!obj &&
  "ref" in <FieldError>obj &&
  "message" in <FieldError>obj &&
  ("type" in <FieldError>obj || "types" in <FieldError>obj);

export const isControllerFieldState = (obj: unknown): obj is ControllerFieldState =>
  !!obj &&
  "invalid" in <ControllerFieldState>obj &&
  "isTouched" in <ControllerFieldState>obj &&
  "isDirty" in <ControllerFieldState>obj &&
  "error" in <ControllerFieldState>obj &&
  isFieldError((<ControllerFieldState>obj).error);

export const isHtmlInputChangeEvent = (obj: unknown): obj is ChangeEvent<HTMLInputElement> =>
  !!obj && !isNumber(obj) && !isString(obj) && "target" in <ChangeEvent<HTMLInputElement>>obj;
