import { wait } from "./wait";

export const callWithRetry = async (fn: () => Promise<unknown>, depthLimit = 8, depth = 0): Promise<unknown> => {
  try {
    return await fn();
  } catch (e) {
    if (depth > depthLimit) {
      throw e;
    }

    await wait(2 ** depth * 10);
    return callWithRetry(fn, depthLimit, depth + 1).catch(() => {
      console.warn("call with retry was failed with depth limit: ", depthLimit);
    });
  }
};
