import { cloneDeep } from "lodash";
import { replace } from "redux-first-history";
import { call, put, race, select, take, takeLeading, type SagaReturnType } from "redux-saga/effects";

import { toastShowErrorAction, toastShowSuccessAction } from "@ds/modules/notifications/redux/actions";
import { selectTableData } from "@ds/modules/table-data/redux/selectors";
import { convertToApiQueryInfo } from "@ds/modules/table-data/utils/common";
import { MainTableDataTypeEnum } from "@ds/modules/table-data/utils/model";

import { UnexpectedError } from "@ds/utils/errors";
import { logger } from "@ds/utils/logger";
import { UNLIMITED_PAGINATION } from "@ds/utils/query";
import { takeLatestOrEvery } from "@ds/utils/saga-helpers";
import { isErrorLike } from "@ds/utils/type-guards/error-guards";

import { invitationsService } from "../utils/api";
import { isApiInvitationQueryOutput } from "../utils/model";
import { normalize } from "../utils/normalizer";
import { selectInvitationById, selectInvitationsByIds, selectTableInvitations } from "./selectors";
import { invitationsActions } from "./slice";

function* fetchInvitations({ meta }: ReturnType<typeof invitationsActions.fetchInvitations>) {
  try {
    const { sorting, pagination, queryInfo, isFetchedAlready } = selectTableData(
      yield select(),
      meta.options.tableType || MainTableDataTypeEnum.Invitations,
      undefined,
      meta.filters,
    );

    const newMeta = cloneDeep(meta);
    const resultFromCache: QueryOutput<Invitation> = { items: [] };

    if (!newMeta.options.cache?.disableCache) {
      if (isFetchedAlready && newMeta.options.tableType) {
        resultFromCache.items = selectTableInvitations(yield select(), newMeta.options.tableType);
        newMeta.options.cache = {
          ...newMeta.options.cache,
          fetchedFromCache: true,
        };
      } else if (newMeta.filters.id && Object.keys(newMeta.filters).length === 1) {
        resultFromCache.items = selectInvitationsByIds(yield select(), newMeta.filters.id);
        if (resultFromCache.items.length === newMeta.filters.id.length) {
          newMeta.options.cache = {
            ...newMeta.options.cache,
            fetchedFromCache: true,
          };
        }
      }
    }

    let result: QueryOutput<Invitation> | QueryOutput<ApiInvitation> = newMeta.options.cache?.fetchedFromCache
      ? resultFromCache
      : yield call(
          [invitationsService, invitationsService.getInvitations],
          convertToApiQueryInfo(sorting, queryInfo),
          newMeta.options.tableType ? pagination : UNLIMITED_PAGINATION,
        );

    result = isApiInvitationQueryOutput(result) ? normalize(result) : result;
    result = newMeta.options.tableType ? result : { items: result.items };

    yield put(invitationsActions.fetchInvitationsSucceeded(result, newMeta));
  } catch (err) {
    const errorTitle = "Fetch invitations";
    if (isErrorLike(err)) {
      yield put(invitationsActions.fetchInvitationsFailed(meta, err));
      yield put(toastShowErrorAction(err, errorTitle));
    } else {
      logger.error(`${errorTitle}: ${UnexpectedError}`);
      yield put(toastShowErrorAction(UnexpectedError, errorTitle));
    }
  }
}

function* fetchInvitation({ payload, meta }: ReturnType<typeof invitationsActions.fetchInvitation>) {
  try {
    const newMeta = cloneDeep(meta);
    let result: Invitation | ApiInvitation = selectInvitationById(yield select(), payload) as ApiInvitation;
    if (result && !newMeta.options.cache?.disableCache) {
      newMeta.options.cache = {
        ...newMeta.options.cache,
        fetchedFromCache: true,
      };
    } else {
      result = yield call([invitationsService, invitationsService.getInvitation], payload);
      result = normalize(result as ApiInvitation);
    }

    yield put(invitationsActions.fetchInvitationSucceeded(result, newMeta));
  } catch (err) {
    const errorTitle = "Fetch invitation";
    if (isErrorLike(err)) {
      yield put(invitationsActions.fetchInvitationFailed(payload, meta, err));
      yield put(toastShowErrorAction(err, errorTitle));
    } else {
      logger.error(`${errorTitle}: ${UnexpectedError}`);
      yield put(toastShowErrorAction(UnexpectedError, errorTitle));
    }
  }
}

function* createInvitation({ payload, meta }: ReturnType<typeof invitationsActions.createInvitation>) {
  try {
    const result: SagaReturnType<typeof invitationsService.createInvitation> = yield call(
      [invitationsService, invitationsService.createInvitation],
      payload,
    );

    yield put(invitationsActions.createInvitationSucceeded(normalize(result), meta));
    yield put(toastShowSuccessAction("Invitation was created successfully"));
    yield put(invitationsActions.selectInvitations([result]));
    yield put(invitationsActions.fetchInvitations(undefined, { tableType: MainTableDataTypeEnum.Invitations }));
  } catch (err) {
    const errorTitle = "Create invitation";
    if (isErrorLike(err)) {
      yield put(invitationsActions.createInvitationFailed(payload, meta, err));
      yield put(toastShowErrorAction(err, errorTitle));
    } else {
      logger.error(`${errorTitle}: ${UnexpectedError}`);
      yield put(toastShowErrorAction(UnexpectedError, errorTitle));
    }
  }
}

function* updateInvitations({ payload }: ReturnType<typeof invitationsActions.updateInvitations>) {
  try {
    const result: SagaReturnType<typeof invitationsService.updateInvitations> = yield call(
      [invitationsService, invitationsService.updateInvitations],
      payload.ids,
      payload.data,
    );

    yield put(invitationsActions.updateInvitationsSucceeded(normalize(result)));
    yield put(toastShowSuccessAction("Invitation(s) were updated successfully"));
  } catch (err) {
    const errorTitle = "Update invitation(s)";
    if (isErrorLike(err)) {
      yield put(invitationsActions.updateInvitationsFailed(payload, err));
      yield put(toastShowErrorAction(err, errorTitle));
    } else {
      logger.error(`${errorTitle}: ${UnexpectedError}`);
      yield put(toastShowErrorAction(UnexpectedError, errorTitle));
    }
  }
}

function* deleteInvitations({ payload, meta }: ReturnType<typeof invitationsActions.deleteInvitations>) {
  try {
    yield call([invitationsService, invitationsService.deleteInvitations], payload);
    yield put(invitationsActions.deleteInvitationsSucceeded(payload, meta));
    yield put(toastShowSuccessAction("Invitation(s) were deleted successfully"));

    if (meta.options?.redirectTo) {
      yield put(replace(meta.options.redirectTo));
    }

    yield put(invitationsActions.fetchInvitations(undefined));
  } catch (err) {
    const errorTitle = "Delete invitation(s)";
    if (isErrorLike(err)) {
      yield put(invitationsActions.deleteInvitationsFailed(payload, meta, err));
      yield put(toastShowErrorAction(err, errorTitle));
    } else {
      logger.error(`${errorTitle}: ${UnexpectedError}`);
      yield put(toastShowErrorAction(UnexpectedError, errorTitle));
    }
  }
}

function* resendInvitations({ payload, meta }: ReturnType<typeof invitationsActions.resendInvitations>) {
  try {
    const result: SagaReturnType<typeof invitationsService.resendInvitations> = yield call(
      [invitationsService, invitationsService.resendInvitations],
      payload,
    );

    yield put(invitationsActions.resendInvitationsSucceeded(normalize(result), meta));
    yield put(toastShowSuccessAction("Invitation(s) were resent successfully"));
  } catch (err) {
    const errorTitle = "Resend invitation(s)";
    if (isErrorLike(err)) {
      yield put(invitationsActions.resendInvitationsFailed(payload, meta, err));
      yield put(toastShowErrorAction(err, errorTitle));
    } else {
      logger.error(`${errorTitle}: ${UnexpectedError}`);
      yield put(toastShowErrorAction(UnexpectedError, errorTitle));
    }
  }
}

export function* watchInvitations() {
  yield takeLatestOrEvery(invitationsActions.fetchInvitations, function* (action) {
    yield race({
      task: call(fetchInvitations, action),
      cancel: take([invitationsActions.selectInvitations, invitationsActions.toggleSelected]),
    });
  });

  yield takeLatestOrEvery(invitationsActions.fetchInvitation, fetchInvitation);
  yield takeLeading(invitationsActions.createInvitation, createInvitation);
  yield takeLeading(invitationsActions.updateInvitations, updateInvitations);
  yield takeLeading(invitationsActions.deleteInvitations, deleteInvitations);
  yield takeLeading(invitationsActions.resendInvitations, resendInvitations);
}
