import { cloneDeep } from "lodash";
import { replace } from "redux-first-history";
import { call, put, race, select, take, takeLatest, takeLeading, type SagaReturnType } from "redux-saga/effects";

import { iotEventDeviceUpdate } from "@ds/modules/iot/redux/actions";
import { toastShowErrorAction, toastShowSuccessAction } from "@ds/modules/notifications/redux/actions";
import { isIotTableEntityAddedOrDeleted, selectTableData } from "@ds/modules/table-data/redux/selectors";
import { convertToApiQueryInfo } from "@ds/modules/table-data/utils/common";
import { MainTableDataTypeEnum } from "@ds/modules/table-data/utils/model";

import { UnexpectedError } from "@ds/utils/errors";
import { logger } from "@ds/utils/logger";
import { UNLIMITED_PAGINATION } from "@ds/utils/query";
import { takeLatestOrEvery } from "@ds/utils/saga-helpers";
import { isErrorLike } from "@ds/utils/type-guards/error-guards";

import { playersService } from "../../utils/api";
import { normalize } from "../../utils/normalizer";
import { selectPlayerById, selectPlayersByIds, selectTablePlayers } from "../selectors";
import { DevicePlayersRemoteOperation, playersActions } from "../slice";

function* fetchPlayers({ meta }: ReturnType<typeof playersActions.fetchPlayers>) {
  try {
    const { sorting, pagination, queryInfo, isFetchedAlready } = selectTableData(
      yield select(),
      meta.options.tableType || MainTableDataTypeEnum.DevicePlayers,
      meta.filters?.locationId?.toString(),
      meta.filters,
    );

    const newMeta = cloneDeep(meta);
    const resultFromCache: QueryOutput<Player> = { items: [] };

    if (!newMeta.options.cache?.disableCache) {
      if (isFetchedAlready && newMeta.options.tableType) {
        resultFromCache.items = selectTablePlayers(yield select(), newMeta.options.tableType);
        newMeta.options.cache = {
          ...newMeta.options.cache,
          fetchedFromCache: true,
        };
      } else if (newMeta.filters.id && Object.keys(newMeta.filters).length === 1) {
        resultFromCache.items = selectPlayersByIds(yield select(), newMeta.filters.id);
        if (resultFromCache.items.length === newMeta.filters.id.length) {
          newMeta.options.cache = {
            ...newMeta.options.cache,
            fetchedFromCache: true,
          };
        }
      }
    }

    let result: QueryOutput<Player> = newMeta.options.cache?.fetchedFromCache
      ? resultFromCache
      : yield call(
          [playersService, playersService.getPlayers],
          convertToApiQueryInfo(sorting, queryInfo),
          newMeta.options.tableType ? pagination : UNLIMITED_PAGINATION,
        );

    result = newMeta.options.cache?.fetchedFromCache ? result : normalize(result);
    result = newMeta.options.tableType ? result : { items: result.items };

    yield put(playersActions.fetchPlayersSucceeded(result, newMeta));
  } catch (err) {
    const errorTitle = "Fetch players";
    if (isErrorLike(err)) {
      yield put(playersActions.fetchPlayersFailed(meta, err));
      yield put(toastShowErrorAction(err, errorTitle));
    } else {
      logger.error(`${errorTitle}: ${UnexpectedError}`);
      yield put(toastShowErrorAction(UnexpectedError, errorTitle));
    }
  }
}

function* fetchPlayer({ payload, meta }: ReturnType<typeof playersActions.fetchPlayer>) {
  try {
    const newMeta = cloneDeep(meta);
    let result = selectPlayerById(yield select(), payload);
    if (result && !newMeta.options.cache?.disableCache) {
      newMeta.options.cache = {
        ...newMeta.options.cache,
        fetchedFromCache: true,
      };
    } else {
      result = yield call([playersService, playersService.getPlayer], payload);
      result = normalize(result);
    }

    yield put(playersActions.fetchPlayerSucceeded(result, newMeta));
  } catch (err) {
    const errorTitle = "Fetch player";
    if (isErrorLike(err)) {
      yield put(playersActions.fetchPlayerFailed(payload, meta, err));
      yield put(toastShowErrorAction(err, errorTitle));
    } else {
      logger.error(`${errorTitle}: ${UnexpectedError}`);
      yield put(toastShowErrorAction(UnexpectedError, errorTitle));
    }
  }
}

function* createPlayer({ payload, meta }: ReturnType<typeof playersActions.createPlayer>) {
  try {
    const result: SagaReturnType<typeof playersService.createPlayer> = yield call(
      [playersService, playersService.createPlayer],
      payload,
    );

    yield put(
      playersActions.createPlayerSucceeded({ player: normalize(result), pairingCode: payload.pairingCode }, meta),
    );

    yield put(toastShowSuccessAction("Player was created successfully"));
    yield put(playersActions.selectPlayers([result]));
  } catch (err) {
    const errorTitle = "Create player";
    if (isErrorLike(err)) {
      yield put(playersActions.createPlayerFailed(payload, meta, err));
      yield put(toastShowErrorAction(err, errorTitle));
    } else {
      logger.error(`${errorTitle}: ${UnexpectedError}`);
      yield put(toastShowErrorAction(UnexpectedError, errorTitle));
    }
  }
}

function* updatePlayers({ payload }: ReturnType<typeof playersActions.updatePlayers>) {
  try {
    const result: SagaReturnType<typeof playersService.updatePlayers> = yield call(
      [playersService, playersService.updatePlayers],
      payload.ids,
      payload.data,
    );

    yield put(playersActions.updatePlayersSucceeded(normalize(result)));
    yield put(toastShowSuccessAction("Player(s) were updated successfully"));
  } catch (err) {
    const errorTitle = "Update player(s)";
    if (isErrorLike(err)) {
      yield put(playersActions.updatePlayersFailed(payload, err));
      yield put(toastShowErrorAction(err, errorTitle));
    } else {
      logger.error(`${errorTitle}: ${UnexpectedError}`);
      yield put(toastShowErrorAction(UnexpectedError, errorTitle));
    }
  }
}

function* deletePlayers({ payload, meta }: ReturnType<typeof playersActions.deletePlayers>) {
  try {
    yield call([playersService, playersService.deletePlayers], payload);
    yield put(playersActions.deletePlayersSucceeded(payload, meta));
    yield put(toastShowSuccessAction("Player(s) were deleted successfully"));

    if (meta.options?.redirectTo) {
      yield put(replace(meta.options.redirectTo));
    }
  } catch (err) {
    const errorTitle = "Delete player(s)";
    if (isErrorLike(err)) {
      yield put(playersActions.deletePlayersFailed(payload, meta, err));
      yield put(toastShowErrorAction(err, errorTitle));
    } else {
      logger.error(`${errorTitle}: ${UnexpectedError}`);
      yield put(toastShowErrorAction(UnexpectedError, errorTitle));
    }
  }
}

function* pairPlayer({ payload }: ReturnType<typeof playersActions.pairPlayer>) {
  try {
    const player = selectPlayerById(yield select(), payload.playerId);
    const result: SagaReturnType<typeof playersService.pairPlayer> = yield call(
      [playersService, playersService.pairPlayer],
      player.id,
      {
        pairing_code: payload.pairingCode,
      },
    );

    yield put(playersActions.pairPlayerSucceeded(normalize(result)));
    yield put(toastShowSuccessAction("Player was successfully paired"));
    yield put(playersActions.selectPlayers([result]));
  } catch (err) {
    const errorTitle = "Pair player";
    if (isErrorLike(err)) {
      yield put(playersActions.pairPlayerFailed(payload, err));
      yield put(toastShowErrorAction(err, errorTitle));
    } else {
      logger.error(`${errorTitle}: ${UnexpectedError}`);
      yield put(toastShowErrorAction(UnexpectedError, errorTitle));
    }
  }
}

function* refetchPlayers({ payload }: ReturnType<typeof iotEventDeviceUpdate>) {
  const isAddedOrDeleted = isIotTableEntityAddedOrDeleted(yield select(), payload, MainTableDataTypeEnum.DevicePlayers);
  if (isAddedOrDeleted) {
    yield call(
      fetchPlayers,
      playersActions.fetchPlayers(
        undefined,
        {
          tableType: MainTableDataTypeEnum.DevicePlayers,
          cache: { disableCache: true },
        },
        DevicePlayersRemoteOperation.IOT_FETCH,
      ),
    );
  }

  yield put(playersActions.iotUpdate(payload));
}

export function* watchPlayers() {
  yield takeLatestOrEvery(playersActions.fetchPlayers, function* (action) {
    yield race({
      task: call(fetchPlayers, action),
      cancel: take([playersActions.selectPlayers, playersActions.toggleSelected]),
    });
  });

  yield takeLatestOrEvery(playersActions.fetchPlayer, fetchPlayer);
  yield takeLeading(playersActions.createPlayer, createPlayer);
  yield takeLeading(playersActions.updatePlayers, updatePlayers);
  yield takeLeading(playersActions.deletePlayers, deletePlayers);
  yield takeLeading(playersActions.pairPlayer, pairPlayer);
  yield takeLatest(iotEventDeviceUpdate, refetchPlayers);
}
