import { useCallback, type FC } from "react";
import { useNavigate } from "react-router-dom";

import { useReduxEntitiesFetch } from "@ds/hooks/use-redux-entities-fetch";

import { EntityTypeName } from "@ds/model/entity-model";

import { MainTableDataTypeEnum } from "@ds/modules/table-data/utils/model";

import { ActionsBar } from "@ds/components/actions-bar";
import { Button, ButtonActionType, ButtonType } from "@ds/components/forms/buttons";
import { IconType } from "@ds/components/icons";
import { InfoViewToggleRibbonButton } from "@ds/components/info-view/info-view-toggle-ribbon-button";
import { confirmWarning } from "@ds/components/popups/confirmation-dialog";
import { useDeepRootSelector, useRootDispatch, useRootSelector } from "@ds/hooks";
import { pluralizeDeleteMessage } from "@ds/utils/localization/common";
import { composeDetailsRouteByEntityType } from "@ds/utils/router";

import { selectAggregatedPlaylistForSelectedEntities, selectSelectedPlaylistIds } from "../../redux/selectors";
import { playlistsActions } from "../../redux/slice";

export const PlaylistsTableActionsMenu: FC<{ onCreate: () => void }> = ({ onCreate }) => {
  const navigate = useNavigate();

  const aggregatedPlaylist = useDeepRootSelector(selectAggregatedPlaylistForSelectedEntities);
  const selectedPlaylistIds = useRootSelector(selectSelectedPlaylistIds);

  const [toggleRefetch] = useReduxEntitiesFetch(
    { entityType: EntityTypeName.CONTENT_PLAYLIST },
    { tableType: MainTableDataTypeEnum.ContentPlaylists },
  );

  const dispatch = useRootDispatch();
  const onUpdateClickHandler = useCallback(
    () => navigate(composeDetailsRouteByEntityType(aggregatedPlaylist)),
    [navigate, aggregatedPlaylist],
  );

  const onDeleteClickHandler = useCallback(
    () =>
      confirmWarning({
        message: pluralizeDeleteMessage("playlist", selectedPlaylistIds.length),
        accept: () => dispatch(playlistsActions.deletePlaylists(selectedPlaylistIds)),
      }),
    [dispatch, selectedPlaylistIds],
  );

  return (
    <ActionsBar>
      <Button ariaLabel="create button" buttonType={ButtonType.Round} icon={IconType.Create} onClick={onCreate} />
      <Button
        ariaLabel="edit button"
        buttonType={ButtonType.Round}
        icon={IconType.Update}
        isDisabled={!selectedPlaylistIds.length}
        onClick={onUpdateClickHandler}
      />

      <Button
        ariaLabel="delete button"
        buttonType={ButtonType.Round}
        actionType={ButtonActionType.Error}
        icon={IconType.Delete}
        isDisabled={!selectedPlaylistIds.length}
        onClick={onDeleteClickHandler}
      />

      <Button
        ariaLabel="refresh button"
        buttonType={ButtonType.Round}
        icon={IconType.Refresh}
        onClick={toggleRefetch}
      />

      <InfoViewToggleRibbonButton />
    </ActionsBar>
  );
};
