import { useMemo } from "react";

import {
  isPlaylistFormationProperty,
  isAssetFormationProperty,
  isBooleanFormationProperty,
} from "@ds/modules/settings/projects/utils/formation-guards";

import { FormationAssetDisplayValue } from "../components/formation/formation-asset-display-value";
import { FormationPlaylistDisplayValue } from "../components/formation/formation-playlist-card-row";
import { FormationBooleanDisplayValue } from "../components/formation/formation-boolean-display-value";
import { FormationStringDisplayValue } from "../components/formation/formation-string-display-value";

export const useGetFormationDisplayValue = (
  entity: Experience | Project,
  field: string,
  property: EntityFormationProperty | TemplateFormationProperty,
  isDirty = false,
) => {
  const formationDisplayValue = useMemo(() => {
    if (isPlaylistFormationProperty(property)) {
      return <FormationPlaylistDisplayValue entity={entity} field={field} isDirty={isDirty} />;
    }

    if (isAssetFormationProperty(property)) {
      return <FormationAssetDisplayValue entity={entity} field={field} isDirty={isDirty} />;
    }

    if (isBooleanFormationProperty(property)) {
      return <FormationBooleanDisplayValue entity={entity} field={field} isDirty={isDirty} />;
    }

    return <FormationStringDisplayValue entity={entity} field={field} isDirty={isDirty} />;
  }, [entity, field, property, isDirty]);

  return [formationDisplayValue];
};
