import { type FC } from "react";

import { BooleanDisplayValue } from "@ds/components/forms/control/boolean-display-value";
import { InfoAttribute, InfoSection } from "@ds/components/info-view";

export const UserNotificationsInfoSection: FC<{ user: User }> = ({ user }) => (
  <InfoSection title="Notifications" icon="pi pi-cog">
    <InfoAttribute
      name="Deployment Notifications"
      value={<BooleanDisplayValue entity={user} field="is_deployment_notifications_enabled" />}
    />

    <InfoAttribute
      name="Device Offline Notifications"
      value={<BooleanDisplayValue entity={user} field="is_device_offline_notifications_enabled" />}
    />
  </InfoSection>
);
