import { cloneDeep } from "lodash";
import { call, put, select, takeLatest } from "redux-saga/effects";

import { selectDeploymentById } from "@ds/modules/deployments/redux/selectors";
import { iotEventDeviceSyncUpdate } from "@ds/modules/iot/redux/actions";
import { toastShowErrorAction } from "@ds/modules/notifications/redux/actions";
import { selectTableData } from "@ds/modules/table-data/redux/selectors";
import { convertToApiQueryInfo } from "@ds/modules/table-data/utils/common";
import { DetailsTableDataTypeEnum } from "@ds/modules/table-data/utils/model";

import { UnexpectedError } from "@ds/utils/errors";
import { logger } from "@ds/utils/logger";
import { UNLIMITED_PAGINATION } from "@ds/utils/query";
import { takeLatestOrEvery } from "@ds/utils/saga-helpers";
import { isErrorLike } from "@ds/utils/type-guards/error-guards";

import { deviceSyncsService } from "../utils/api";
import { isApiDeviceSyncQueryOutput } from "../utils/model";
import { normalize } from "../utils/normalizer";
import { selectDeviceSyncsByDeploymentId, selectTableDeviceSyncs } from "./selectors";
import { deviceSyncsActions } from "./slice";

function* fetchDeviceSyncs({ meta }: ReturnType<typeof deviceSyncsActions.fetchDeviceSyncs>) {
  try {
    const { sorting, pagination, queryInfo, isFetchedAlready } = selectTableData(
      yield select(),
      meta.options.tableType || DetailsTableDataTypeEnum.DeploymentDeviceSyncs,
      meta.filters?.deploymentId?.toString(),
      meta.filters,
    );

    const newMeta = cloneDeep(meta);
    const resultFromCache: QueryOutput<DeviceSync> = { items: [] };

    if (!newMeta.options.cache?.disableCache) {
      if (isFetchedAlready && newMeta.options.tableType) {
        resultFromCache.items = selectTableDeviceSyncs(yield select(), newMeta.options.tableType);
        newMeta.options.cache = {
          ...newMeta.options.cache,
          fetchedFromCache: true,
        };
      } else if (newMeta.filters.deploymentId && Object.keys(newMeta.filters).length === 1) {
        const deployment = selectDeploymentById(yield select(), newMeta.filters.deploymentId);
        resultFromCache.items = selectDeviceSyncsByDeploymentId(yield select(), [newMeta.filters.deploymentId]);
        if (resultFromCache.items.length === deployment.total_count) {
          newMeta.options.cache = {
            ...newMeta.options.cache,
            fetchedFromCache: true,
          };
        }
      }
    }

    let result: QueryOutput<DeviceSync> | QueryOutput<ApiDeviceSync> = newMeta.options.cache?.fetchedFromCache
      ? resultFromCache
      : yield call(
          [deviceSyncsService, deviceSyncsService.getDeviceSyncs],
          convertToApiQueryInfo(sorting, queryInfo),
          newMeta.options.tableType ? pagination : UNLIMITED_PAGINATION,
        );

    result = isApiDeviceSyncQueryOutput(result) ? normalize(result) : result;
    result = newMeta.options.tableType ? result : { items: result.items };

    yield put(deviceSyncsActions.fetchDeviceSyncsSucceeded(result, newMeta));
  } catch (err) {
    const errorTitle = "Fetch device syncs";
    if (isErrorLike(err)) {
      yield put(deviceSyncsActions.fetchDeviceSyncsFailed(meta, err));
      yield put(toastShowErrorAction(err, errorTitle));
    } else {
      logger.error(`${errorTitle}: ${UnexpectedError}`);
      yield put(toastShowErrorAction(UnexpectedError, errorTitle));
    }
  }
}

function* refetchDeviceSyncs({ payload }: ReturnType<typeof iotEventDeviceSyncUpdate>) {
  yield put(deviceSyncsActions.iotUpdate(payload));
}

export function* watchDeviceSyncs() {
  yield takeLatestOrEvery(deviceSyncsActions.fetchDeviceSyncs, fetchDeviceSyncs);
  yield takeLatest(iotEventDeviceSyncUpdate, refetchDeviceSyncs);
}
