import { useCallback, type FC } from "react";
import { useNavigate } from "react-router-dom";

import { useReduxEntitiesFetch } from "@ds/hooks/use-redux-entities-fetch";

import { EntityTypeName } from "@ds/model/entity-model";

import {
  selectAggregatedUserForSelectedEntities,
  selectCurrentUser,
  selectCurrentUserIsTenantAdmin,
  selectSelectedUserIds,
  selectUserIsTenantOwnerById,
} from "@ds/modules/settings/users/redux/selectors";
import { MainTableDataTypeEnum } from "@ds/modules/table-data/utils/model";

import { ActionsBar } from "@ds/components/actions-bar";
import { Button, ButtonActionType, ButtonType } from "@ds/components/forms/buttons";
import { IconType } from "@ds/components/icons";
import { InfoViewToggleRibbonButton } from "@ds/components/info-view/info-view-toggle-ribbon-button";
import { MenuWithButton } from "@ds/components/menu-dropdown/menu-with-button";
import { confirmSuccess, confirmWarning } from "@ds/components/popups/confirmation-dialog";
import { useDeepRootSelector, useRootDispatch, useRootSelector } from "@ds/hooks";
import { isMultipleFieldValuesInAggregatedEntity } from "@ds/utils/entities";
import { pluralizeActionMessage, pluralizeDeleteMessage } from "@ds/utils/localization/common";
import { composeDetailsRouteByEntityType } from "@ds/utils/router";

import { usersActions } from "../../redux/slice";

export const UsersTableActionsMenu: FC = () => {
  const navigate = useNavigate();

  const aggregatedUser = useDeepRootSelector(selectAggregatedUserForSelectedEntities);
  const selectedUserIds = useRootSelector(selectSelectedUserIds);

  const isAdmin = useRootSelector(selectCurrentUserIsTenantAdmin);
  const isUserModifyHimself = useRootSelector(state => selectedUserIds.includes(selectCurrentUser(state)?.id || 0));
  const isUserModifyTenantOwner = useRootSelector(state =>
    selectedUserIds.some(userId => selectUserIsTenantOwnerById(state, userId)),
  );

  const [toggleRefetch] = useReduxEntitiesFetch(
    { entityType: EntityTypeName.SETTINGS_USER },
    { tableType: MainTableDataTypeEnum.Users },
  );

  const dispatch = useRootDispatch();
  const onToggleActiveClickHandler = useCallback(() => {
    confirmSuccess({
      message: pluralizeActionMessage(
        "user",
        aggregatedUser?.metadata?.totalAggregated || 0,
        aggregatedUser?.is_active ? "deactivate" : "activate",
      ),
      accept: () => dispatch(usersActions.updateUsers(selectedUserIds, { is_active: !aggregatedUser?.is_active })),
    });
  }, [dispatch, aggregatedUser?.is_active, aggregatedUser?.metadata?.totalAggregated, selectedUserIds]);

  const onUpdateClickHandler = useCallback(
    () => navigate(composeDetailsRouteByEntityType(aggregatedUser)),
    [navigate, aggregatedUser],
  );

  const onDeleteClickHandler = useCallback(
    () =>
      confirmWarning({
        message: pluralizeDeleteMessage("user", selectedUserIds.length),
        accept: () => dispatch(usersActions.deleteUsers(selectedUserIds)),
      }),
    [dispatch, selectedUserIds],
  );

  return (
    <ActionsBar>
      <MenuWithButton
        menuItems={[
          {
            label: aggregatedUser?.is_active ? "Deactivate" : "Activate",
            icon: `pi pi-fw ${aggregatedUser?.is_active ? "pi-lock" : "pi-lock-open"}`,
            command: onToggleActiveClickHandler,
            disabled: !isAdmin || isUserModifyHimself || isUserModifyTenantOwner,
          },
        ]}
        isDisabled={
          !selectedUserIds.length ||
          !aggregatedUser ||
          isMultipleFieldValuesInAggregatedEntity(aggregatedUser, "is_active")
        }
      />

      <Button
        ariaLabel="edit button"
        buttonType={ButtonType.Round}
        icon={IconType.Update}
        isDisabled={!selectedUserIds.length}
        onClick={onUpdateClickHandler}
      />

      <Button
        ariaLabel="delete button"
        buttonType={ButtonType.Round}
        actionType={ButtonActionType.Error}
        icon={IconType.Delete}
        isDisabled={!selectedUserIds.length || !isAdmin || isUserModifyHimself || isUserModifyTenantOwner}
        onClick={onDeleteClickHandler}
      />

      <Button
        ariaLabel="refresh button"
        buttonType={ButtonType.Round}
        icon={IconType.Refresh}
        onClick={toggleRefetch}
      />

      <InfoViewToggleRibbonButton />
    </ActionsBar>
  );
};
