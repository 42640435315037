import { useCallback, useEffect, useRef, useState, type FC } from "react";
import styled from "styled-components";

import { TableFilterOptions } from "@ds/modules/table-data/redux/types";

import { useTableDataSearch } from "@ds/hooks";

import { InputText } from "./forms/inputs";

const Wrapper = styled.span`
  > input {
    width: 400px;
    border-radius: 8px;
  }
`;

const Loop = styled.i`
  width: 16px;
`;

const Clear = styled.i`
  width: 15px;
  cursor: pointer;
`;

export const TableSearchBar: FC<TableFilterOptions> = ({ tableType, tableTab, queryFilters }) => {
  const [query, setQuery] = useTableDataSearch(tableType, tableTab, queryFilters);
  const [isClearVisible, toggleClearVisibility] = useState(false);

  useEffect(() => void toggleClearVisibility(!!query.length), [query]);

  const onChangeHandler = useCallback(
    ({ target: { value: targetValue } }: { target: { value: string } }) => {
      setQuery(targetValue);
    },
    [setQuery],
  );

  const onKeyPressHandler = useCallback(
    ({ key }: { key: string }) => {
      if (key === "Enter") {
        setQuery(query);
      }
    },
    [query, setQuery],
  );

  const searchInputRef = useRef<HTMLInputElement>(null);
  const onLoopClickHandler = useCallback(() => {
    searchInputRef.current?.focus();
  }, []);

  const onClearClickHandler = useCallback(() => {
    toggleClearVisibility(false);
    setQuery("");
  }, [setQuery]);

  return (
    <Wrapper className="p-input-icon-left p-input-icon-right">
      <Loop className="pi pi-search" onClick={onLoopClickHandler} />
      <InputText
        value={query}
        inputRef={searchInputRef}
        autoFocus
        placeholder="Search..."
        onChange={onChangeHandler}
        onKeyPress={onKeyPressHandler}
      />

      {isClearVisible && <Clear className="pi pi-times-circle" onClick={onClearClickHandler} />}
    </Wrapper>
  );
};
