import { type FC } from "react";

import { WizardFinalStepWrapper, WizardPage, type WizardChildStepProps } from "@ds/components/wizard";
import { getStatusMessage } from "@ds/components/wizard/helpers";
import { useRootSelector } from "@ds/hooks";

import { selectInvitationsError, selectInvitationsIsLoading } from "../../../redux/selectors";

export const StepFinal: FC<WizardChildStepProps> = ({ firstStep, ...rest }) => {
  const isSaving = useRootSelector(selectInvitationsIsLoading);
  const errorMessage = useRootSelector(selectInvitationsError);

  return (
    <WizardPage {...rest} isValid={!errorMessage} isLoading={isSaving} previousStep={firstStep}>
      <WizardFinalStepWrapper>{getStatusMessage({ isLoading: isSaving, errorMessage })}</WizardFinalStepWrapper>
    </WizardPage>
  );
};
