import { Toolbar as PrimeToolbar } from "primereact/toolbar";
import styled from "styled-components";

export const Toolbar = styled(PrimeToolbar)`
  background: transparent !important;
  border: none !important;
  width: 100%;
`;

export const ToolbarWrapper = styled.div`
  display: flex;
  align-self: center;
  justify-content: center;
  padding-top: 60px;
  width: 80%;
`;
