import { yupResolver } from "@hookform/resolvers/yup";
import { type ComponentProps, type FC } from "react";
import { type DeepPartial } from "react-hook-form";

import { FormationCardRow } from "@ds/modules/settings/projects/components/formation/formation-card-row";
import { FormationControl } from "@ds/modules/settings/projects/components/forms/controls/formation-control";
import { selectTemplateExperienceFormationValidationData } from "@ds/modules/settings/projects/redux/selectors/experience-formation-selectors";
import { selectCurrentUserProjectId } from "@ds/modules/settings/users/redux/selectors";

import {
  DetailsCard,
  DetailsCardEditActionsMenu,
  EditableDetailsCardDecorator,
  EditableDetailsCardRowDecorator,
} from "@ds/components/details-view";
import { useDeepRootSelector, useRootSelector } from "@ds/hooks";

import { updateValidationSchema } from "../../utils/validation-schema";
import { ExperienceFormationControlDecorator } from "../forms/controls/experience-formation-control-decorator";

export const ExperienceDetailsFormationCard: FC<{
  cardTitle: string;
  experience: Experience;
  templateProperties: [string, TemplateFormationProperty][];
  onSubmit: (changedFields: DeepPartial<Experience>) => void;
}> = ({ cardTitle, experience, templateProperties, onSubmit }) => {
  const projectId = useRootSelector(selectCurrentUserProjectId);
  const validationData = useDeepRootSelector(state =>
    selectTemplateExperienceFormationValidationData(state, projectId, "details", templateProperties),
  );

  return (
    <EditableDetailsCardDecorator
      initialValue={experience}
      validationResolver={yupResolver(updateValidationSchema.formation(validationData))}
      onSubmit={onSubmit}
    >
      {cardProps => (
        <DetailsCard
          title={cardTitle}
          icon="desktop"
          actions={
            <DetailsCardEditActionsMenu
              isEditing={cardProps.isEditing}
              isDirty={cardProps.isDirty}
              onReset={cardProps.onResetForm}
              onSubmit={cardProps.handleSubmit(cardProps.onSubmitForm)}
            />
          }
        >
          {templateProperties.map(([field, property]) => (
            <EditableDetailsCardRowDecorator
              key={`experience-formation-property-${field}`}
              field={`formation.properties.${field}`}
              shouldHideEdit={property.readonly}
              input={
                <ExperienceFormationControlDecorator
                  field={field}
                  control={cardProps.control}
                  setValue={cardProps.setValue}
                  property={property}
                  enableConstructors={false}
                >
                  {(props: ComponentProps<typeof FormationControl>) => (
                    <FormationControl autoFocus excludeLabel {...props} />
                  )}
                </ExperienceFormationControlDecorator>
              }
              {...cardProps}
            >
              {rowProps => (
                <FormationCardRow
                  entity={cardProps.entity}
                  field={field}
                  property={property}
                  editableSettings={rowProps}
                />
              )}
            </EditableDetailsCardRowDecorator>
          ))}
        </DetailsCard>
      )}
    </EditableDetailsCardDecorator>
  );
};
