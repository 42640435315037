import pluralize from "pluralize";

export const pluralizeDeployName = (entities: Locality[] | Experience[] | Player[]) =>
  entities.length === 1
    ? `Content to ${entities[0].name} ${entities[0].__typename}`
    : `Content to ${pluralize(entities[0].__typename, entities.length, true)}`;

export const pluralizeDeployDescription = (entities: Locality[] | Experience[] | Player[]) =>
  entities.length === 1
    ? `Content deployment to ${entities[0].name} ${entities[0].__typename}`
    : `Content deployment to ${pluralize(entities[0].__typename, entities.length, true)}`;

export const pluralizeDeployMessage = (entityName: string, count: number) =>
  `Are you sure you want to deploy the latest content to ${pluralize(entityName, count, true)}?`;

export const pluralizeExecuteMessage = (entityName: string, count: number) =>
  `Are you sure you want to execute this ${pluralize(entityName, count, true)}?`;

export const pluralizeUnscheduleMessage = (entityName: string, count: number) =>
  `Are you sure you want to unschedule this ${pluralize(entityName, count, true)}?`;
