import { yupResolver } from "@hookform/resolvers/yup";
import { useCallback, useEffect, type FC } from "react";
import { useForm } from "react-hook-form";

import { useRootDispatch } from "@ds/hooks/redux-hooks";

import { playersActions } from "@ds/modules/devices/players/redux/slice";
import { createValidationSchema } from "@ds/modules/devices/players/utils/validation-schema";

import { WizardPage, type WizardChildStepProps } from "@ds/components/wizard";

import { PlayerExperienceControl } from "../controls/player-experience-control";
import { useCreatePlayer } from "./create-player-context";

export const Step2Experience: FC<WizardChildStepProps> = ({ isActive, nextStep, previousStep, ...rest }) => {
  const [currentPlayerData, submitPlayerData] = useCreatePlayer();
  const { control, getValues, setValue, handleSubmit, reset } = useForm<PlayerFormData>({
    mode: "all",
    reValidateMode: "onChange",
    defaultValues: currentPlayerData,
    resolver: yupResolver(createValidationSchema.step2),
  });

  useEffect(() => {
    if (isActive) {
      reset(currentPlayerData);
    }
  }, [currentPlayerData, isActive, reset]);

  const dispatch = useRootDispatch();
  const onNextClickHandler = useCallback(() => {
    const dataToSubmit = getValues();
    submitPlayerData(dataToSubmit);
    dispatch(playersActions.createPlayer({ player: dataToSubmit.player, pairingCode: dataToSubmit.pairingCode }));
    if (nextStep) {
      nextStep();
    }
  }, [dispatch, submitPlayerData, getValues, nextStep]);

  const onBackClickHandler = useCallback(() => {
    submitPlayerData(getValues());
    if (previousStep) {
      previousStep();
    }
  }, [submitPlayerData, getValues, previousStep]);

  return (
    <WizardPage
      {...rest}
      title="Assign experience"
      previousStep={onBackClickHandler}
      nextStep={handleSubmit(onNextClickHandler)}
    >
      <PlayerExperienceControl control={control} setValue={setValue} />
    </WizardPage>
  );
};
