import { createSelector } from "@reduxjs/toolkit";

import { selectCurrentUserProjectId } from "@ds/modules/settings/users/redux/selectors";
import { DetailsTableDataTypeEnum, MainTableDataTypeEnum } from "@ds/modules/table-data/utils/model";

import { type RootState } from "@ds/store/bootstrap";
import { selectorsExtractIds, selectorsOutputEntitiesByIds } from "@ds/store/global-selectors";

import { getAggregatedModel } from "@ds/utils/entities";
import { LoadStatus } from "@ds/utils/reducer";

import { entitySelectors, ProjectsRemoteOperation } from "./../slice";

export const selectBaseState = (state: RootState) => state.settings.projects;
const selectLoadStatus = (state: RootState, operation = ProjectsRemoteOperation.CRUD) =>
  selectBaseState(state).loadStatusMap[operation];

export const selectProjectsIsLoading = (state: RootState, operation = ProjectsRemoteOperation.CRUD) =>
  selectLoadStatus(state, operation) === LoadStatus.Loading;

export const selectTableProjectIds = (
  state: RootState,
  tableType: MainTableDataTypeEnum | DetailsTableDataTypeEnum = MainTableDataTypeEnum.Projects,
) => selectBaseState(state).tableIds[tableType];

export const selectSelectedProjectIds = (
  state: RootState,
  tableType: MainTableDataTypeEnum | DetailsTableDataTypeEnum = MainTableDataTypeEnum.Projects,
) => selectBaseState(state).selectedIds[tableType];

export const selectProjectById = (state: RootState, id: number) =>
  entitySelectors.selectById(selectBaseState(state), id) as Project;

export const selectProjectIds = (state: RootState) => entitySelectors.selectIds(selectBaseState(state));
export const selectProjects = (state: RootState) => entitySelectors.selectAll(selectBaseState(state));

export const selectProjectsByIds = createSelector(
  selectBaseState,
  selectorsExtractIds,
  selectorsOutputEntitiesByIds(() => entitySelectors),
);

export const selectTableProjects = createSelector(
  selectBaseState,
  selectTableProjectIds,
  selectorsOutputEntitiesByIds(() => entitySelectors),
);

export const selectSelectedProjects = createSelector(
  selectBaseState,
  selectSelectedProjectIds,
  selectorsOutputEntitiesByIds(() => entitySelectors),
);

export const selectAggregatedProjectByIds = createSelector(selectBaseState, selectorsExtractIds, (state, ids) =>
  ids?.length ? getAggregatedModel(selectorsOutputEntitiesByIds(entitySelectors)(state, ids)) : null,
);

export const selectAggregatedProjectForSelectedEntities = createSelector(selectSelectedProjects, selectedProjects =>
  getAggregatedModel(selectedProjects),
);

export const selectCurrentUserProject = createSelector(
  selectBaseState,
  selectCurrentUserProjectId,
  (state, userProjectId) => entitySelectors.selectById(state, userProjectId),
);

export const selectProjectDashboardData = createSelector(selectBaseState, projects => projects.dashboardInfo);
