import { useCallback, useEffect, useState, type FC } from "react";
import styled from "styled-components";

import {
  selectProjectsByIds,
  selectProjectsIsLoading,
} from "@ds/modules/settings/projects/redux/selectors/common-selectors";
import { projectsActions } from "@ds/modules/settings/projects/redux/slice";

import { Button, ButtonActionType, ButtonType } from "@ds/components/forms/buttons";
import { LabelRadio } from "@ds/components/forms/labels";
import { IconType } from "@ds/components/icons";
import { Dialog } from "@ds/components/modals";
import { useDeepRootSelector, useRootDispatch, useRootSelector } from "@ds/hooks";

import { selectCurrentUserProjectIds, selectUsersIsLoading } from "../../redux/selectors";
import { usersActions } from "../../redux/slice";

const ProjectsWrapper = styled.div`
  min-width: 500px;
`;

const RadioButtonWrapper = styled.div`
  padding: 12px 0;
`;

const ProjectDescription = styled.small`
  margin: -5px 0 0 32px;
  display: block;
  color: var(--textColor060);
`;

export const SwitchProjectModal: FC<{
  currentProjectId: number;
  onClose: () => void;
}> = ({ currentProjectId, onClose }) => {
  const currentUserProjectIds = useRootSelector(selectCurrentUserProjectIds);
  const projects = useDeepRootSelector(state =>
    selectProjectsByIds(state, currentUserProjectIds).filter(({ is_active }) => !!is_active),
  );

  const isProjectsLoading = useRootSelector(selectProjectsIsLoading);
  const isUsersLoading = useRootSelector(selectUsersIsLoading);

  const [selectedProjectId, setSelectedProjectId] = useState(currentProjectId);

  const dispatch = useRootDispatch();
  const onSubmitHandler = useCallback(
    async () => dispatch(usersActions.updateUserCurrentProjectId(selectedProjectId)),
    [dispatch, selectedProjectId],
  );

  const onChangeHandler = useCallback(({ value }: { value: number }) => setSelectedProjectId(value), []);
  const renderFooter = useCallback(
    () => (
      <div className="flex justify-content-end align-items-center">
        <Button
          label="Cancel"
          ariaLabel="cancel button"
          buttonType={ButtonType.Outlined}
          actionType={ButtonActionType.Secondary}
          icon={IconType.Close}
          iconPosition="right"
          isDisabled={isUsersLoading}
          onClick={onClose}
        />

        <Button
          label="Switch"
          ariaLabel="save button"
          buttonType={ButtonType.Raised}
          icon={IconType.Save}
          iconPosition="right"
          isLoading={isUsersLoading || isProjectsLoading}
          isDisabled={!selectedProjectId}
          onClick={onSubmitHandler}
        />
      </div>
    ),
    [selectedProjectId, isUsersLoading, isProjectsLoading, onClose, onSubmitHandler],
  );

  useEffect(() => {
    if (currentUserProjectIds.length) {
      dispatch(projectsActions.fetchProjects({ id: currentUserProjectIds }));
    }
  }, [dispatch, currentUserProjectIds]);

  return (
    <Dialog modal visible header="Switch Project" footer={renderFooter} onHide={onClose}>
      <ProjectsWrapper>
        {projects.map(project => (
          <RadioButtonWrapper key={`project-${project.id}`}>
            <LabelRadio
              label={project.name}
              value={project.id}
              checked={project.id === selectedProjectId}
              onChange={onChangeHandler}
            />
            <ProjectDescription>{project.description}</ProjectDescription>
          </RadioButtonWrapper>
        ))}
      </ProjectsWrapper>
    </Dialog>
  );
};
