import { useCallback, type ComponentProps, type FC } from "react";
import { useWatch, type Control, type UseFormSetValue } from "react-hook-form";

import { FormationControl } from "@ds/modules/settings/projects/components/forms/controls/formation-control";
import { type FormationChangeHandlerDecorator } from "@ds/modules/settings/projects/components/forms/controls/types";
import { selectTemplateExperienceFormation } from "@ds/modules/settings/projects/redux/selectors/experience-formation-selectors";
import { selectCurrentUserProjectId } from "@ds/modules/settings/users/redux/selectors";

import { useRootSelector } from "@ds/hooks";

export const ExperienceFormationControlDecorator: FC<{
  field: string;
  control: Control<Experience>;
  setValue: UseFormSetValue<Experience>;
  property: TemplateFormationProperty;
  children: (props: ComponentProps<typeof FormationControl>) => JSX.Element;
  enableConstructors?: boolean;
  onBlur?: () => void;
}> = ({ field, control, setValue, property, children, enableConstructors = true, onBlur }) => {
  const projectId = useRootSelector(selectCurrentUserProjectId);
  const nameConstructorRules = useRootSelector(state =>
    selectTemplateExperienceFormation(state, projectId),
  )?.name_constructor;

  const experienceName = useWatch({ name: "name", control });

  const shouldConstructNames = nameConstructorRules?.properties.includes(field) && enableConstructors;
  const onBeforeChangeHandlerDecorator = useCallback<FormationChangeHandlerDecorator>(
    (onChange, currentValue, valueExtractorFn) => inputData => {
      const newValue = valueExtractorFn(inputData);
      if (experienceName && currentValue && newValue && shouldConstructNames) {
        setValue("name", experienceName.replaceAll(currentValue.toString(), newValue.toString()), {
          shouldDirty: true,
          shouldTouch: true,
          shouldValidate: true,
        });
      }

      onChange(inputData);
    },
    [experienceName, shouldConstructNames, setValue],
  );

  const childProps: ComponentProps<typeof FormationControl> = {
    name: `formation.properties.${field}`,
    control: control as Control<Experience | Project>,
    setValue: setValue as UseFormSetValue<Experience | Project>,
    property,
    onBeforeChangeHandlerDecorator,
    onBlur,
  };

  return <>{children(childProps)}</>;
};
