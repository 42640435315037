import { useMemo } from "react";

import { MULTIPLE_IDS_PARAM } from "@ds/constants/router";

import { QueryParamType, useUrlQueryParams, useUrlRouterParams } from "./query";

export const useParseQueryIds = ((queryIdParam: string, queryParamType: QueryParamType = QueryParamType.String) => {
  const id = useUrlRouterParams(queryIdParam, queryParamType);
  const ids = useUrlQueryParams(MULTIPLE_IDS_PARAM, queryParamType);

  return useMemo(() => (id ? [id] : ids), [id, ids]);
}) as {
  (queryIdParam: string): string[];
  (queryIdParam: string, queryParamType: QueryParamType.String): string[];
  (queryIdParam: string, queryParamType: QueryParamType.Email): string[];
  (queryIdParam: string, queryParamType: QueryParamType.Number): number[];
  (queryIdParam: string, queryParamType: QueryParamType): number[] | string[];
};
