import { type FC } from "react";
import { Route, Routes } from "react-router-dom";

import { useRootSelector } from "@ds/hooks";

import { selectCurrentUserProject } from "../settings/projects/redux/selectors/common-selectors";
import { DashboardMainPage } from "./dashboard-main-page";

export const DashboardRouter: FC = () => {
  const shouldShowDashboardPage = !!useRootSelector(selectCurrentUserProject)?.dashboard_id;
  return <Routes>{shouldShowDashboardPage && <Route path="*" element={<DashboardMainPage />} />}</Routes>;
};
