import { put, takeLatest } from "redux-saga/effects";
import { push } from "redux-first-history";

import {
  CONTENT_PLAYLISTS_ROUTE,
  CONTENT_IMAGES_ROUTE,
  CONTENT_VIDEOS_ROUTE,
  CONTENT_AUDIO_ROUTE,
} from "@ds/constants/router";

import { contentActions } from "./slice";
import { ContentTableTypeEnum } from "../utils/model";

function* switchRoute({ payload }: ReturnType<typeof contentActions.switchView>) {
  let routeToSwitch = CONTENT_PLAYLISTS_ROUTE;
  switch (payload) {
    case ContentTableTypeEnum.Videos:
      routeToSwitch = CONTENT_VIDEOS_ROUTE;
      break;
    case ContentTableTypeEnum.Images:
      routeToSwitch = CONTENT_IMAGES_ROUTE;
      break;
    case ContentTableTypeEnum.Audio:
      routeToSwitch = CONTENT_AUDIO_ROUTE;
      break;
  }

  yield put(push(routeToSwitch));
}

export function* watchCommon() {
  yield takeLatest(contentActions.switchView, switchRoute);
}
