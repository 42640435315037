import { createSelector } from "@reduxjs/toolkit";

import { entitySelectors, UsersRemoteOperation } from "@ds/modules/settings/users/redux/slice";
import { TenantRoleEnum } from "@ds/modules/settings/users/utils/model";
import { DetailsTableDataTypeEnum, MainTableDataTypeEnum } from "@ds/modules/table-data/utils/model";

import { type RootState } from "@ds/store/bootstrap";
import { selectorsExtractIds, selectorsOutputEntitiesByIds } from "@ds/store/global-selectors";

import { getAggregatedModel } from "@ds/utils/entities";
import { LoadStatus } from "@ds/utils/reducer";

const selectBaseState = (state: RootState) => state.settings.users;
const selectLoadStatus = (state: RootState, operation = UsersRemoteOperation.CRUD) =>
  selectBaseState(state).loadStatusMap[operation];

export const selectUsersIsLoading = (state: RootState, operation = UsersRemoteOperation.CRUD) =>
  selectLoadStatus(state, operation) === LoadStatus.Loading;

export const selectUsersIsSucceeded = (state: RootState, operation = UsersRemoteOperation.CRUD) =>
  selectLoadStatus(state, operation) === LoadStatus.Succeeded;

export const selectTableUserIds = (
  state: RootState,
  tableType: MainTableDataTypeEnum | DetailsTableDataTypeEnum = MainTableDataTypeEnum.Users,
) => selectBaseState(state).tableIds[tableType];

export const selectSelectedUserIds = (
  state: RootState,
  tableType: MainTableDataTypeEnum | DetailsTableDataTypeEnum = MainTableDataTypeEnum.Users,
) => selectBaseState(state).selectedIds[tableType];

export const selectUserById = (state: RootState, id: number) =>
  entitySelectors.selectById(selectBaseState(state), id) as User;

export const selectUserIds = (state: RootState) => entitySelectors.selectIds(selectBaseState(state));
export const selectUsers = (state: RootState) => entitySelectors.selectAll(selectBaseState(state));

export const selectUsersByIds = createSelector(
  selectBaseState,
  selectorsExtractIds,
  selectorsOutputEntitiesByIds(() => entitySelectors),
);

export const selectTableUsers = createSelector(
  selectBaseState,
  selectTableUserIds,
  selectorsOutputEntitiesByIds(() => entitySelectors),
);

export const selectSelectedUsers = createSelector(
  selectBaseState,
  selectSelectedUserIds,
  selectorsOutputEntitiesByIds(() => entitySelectors),
);

export const selectAggregatedUserByIds = createSelector(selectBaseState, selectorsExtractIds, (state, ids) =>
  ids?.length ? getAggregatedModel(selectorsOutputEntitiesByIds(entitySelectors)(state, ids)) : null,
);

export const selectAggregatedUserForSelectedEntities = createSelector(selectSelectedUsers, selectedUsers =>
  getAggregatedModel(selectedUsers),
);

export const selectCurrentUser = (state: RootState) => selectUserById(state, selectBaseState(state).currentUserId || 0);

export const selectUserFullNameById = (state: RootState, id: number) => {
  const user = selectUserById(state, id);
  if (!user) {
    return "";
  }

  const { given_name, family_name } = user;
  const space = given_name.length && family_name.length ? " " : "";
  return `${given_name}${space}${family_name}`;
};

export const selectUserIsTenantOwnerById = (state: RootState, id?: number) => {
  const user = selectUserById(state, id || 0);
  return !!user?.email && user?.email === user.tenant?.owner;
};

export const selectCurrentUserIsTenantAdmin = (state: RootState) => {
  const currentUser = selectCurrentUser(state);
  return currentUser?.role === TenantRoleEnum.TenantAdmin || selectUserIsTenantOwnerById(state, currentUser.id);
};

export const selectCurrentUserProjectId = (state: RootState) => selectCurrentUser(state)?.current_project_id;
export const selectCurrentUserProjectIds = createSelector(
  selectCurrentUser,
  currentUser => currentUser?.project_ids || [],
);
