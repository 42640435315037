import { type FC } from "react";
import { Controller, type Control } from "react-hook-form";

import { useDetailsInlineSaving } from "@ds/components/details-view/hooks";
import { InputText } from "@ds/components/forms/inputs";
import { LabelWrapper } from "@ds/components/forms/labels";

export const ProjectNameControl: FC<{
  control: Control<Project>;
  autoFocus?: boolean;
  excludeLabel?: boolean;
  onBlur?: () => void;
}> = ({ control, autoFocus, excludeLabel, onBlur }) => {
  const onBlurHandler = useDetailsInlineSaving(onBlur);
  return (
    <Controller
      name="name"
      control={control}
      render={({ field: { ref, onBlur: onBlurFn, ...restFields }, fieldState }) => {
        const element = (
          <InputText
            inputRef={ref}
            placeholder="Enter project name"
            autoFocus={autoFocus}
            onBlur={onBlurHandler(onBlurFn)}
            {...restFields}
          />
        );

        if (excludeLabel) {
          return element;
        }

        return (
          <LabelWrapper label="Project Name" className="col-8" error={fieldState.error} isRequired>
            {element}
          </LabelWrapper>
        );
      }}
    />
  );
};
