import { yupResolver } from "@hookform/resolvers/yup";
import { sumBy } from "lodash";
import { type FC } from "react";
import { type DeepPartial } from "react-hook-form";

import { useGetAggregatedEntityIds } from "@ds/hooks/use-get-aggregated-entity-ids";

import {
  DetailsCard,
  DetailsCardEditActionsMenu,
  DetailsCardRow,
  EditableDetailsCardDecorator,
  EditableDetailsCardRowDecorator,
} from "@ds/components/details-view";
import { AggregatedValue } from "@ds/components/entities";
import { DescriptionControl } from "@ds/components/forms/control/description-control";
import { useRootSelector } from "@ds/hooks";
import { formatAbsoluteDate, formatRelativeDate } from "@ds/utils/date";
import { getProperty } from "@ds/utils/properties";
import { convertToUpperCaseFirst } from "@ds/utils/strings";

import { selectDeploymentsByIds } from "../../redux/selectors";
import { updateValidationSchema } from "../../utils/validation-schema";
import { ChipCount } from "../common/chip-count";
import { getDeploymentStatusIndicator } from "../common/status-indicator";
import { DeploymentNameControl } from "../forms/controls/deployment-name-control";
import { DeploymentDetailsInfoCardActionsMenu } from "./deployment-details-info-card-actions-menu";

export const DeploymentDetailsInfoCard: FC<{
  deployment: Deployment;
  onSubmit: (changedFields: DeepPartial<Deployment>) => void;
}> = ({ deployment, onSubmit }) => {
  const aggregatedDeploymentIds = useGetAggregatedEntityIds(deployment);
  const aggregatedDeployments = useRootSelector(state => selectDeploymentsByIds(state, aggregatedDeploymentIds));

  const succeededCount = sumBy(aggregatedDeployments, "succeeded_count");
  const failedCount = sumBy(aggregatedDeployments, "failed_count");

  return (
    <EditableDetailsCardDecorator
      initialValue={deployment}
      validationResolver={yupResolver(updateValidationSchema.info)}
      onSubmit={onSubmit}
    >
      {cardProps => (
        <DetailsCard
          title="Info"
          icon="info-circle"
          actions={
            !cardProps.isDirty && !cardProps.isEditing ? (
              <DeploymentDetailsInfoCardActionsMenu deployment={cardProps.entity} />
            ) : (
              <DetailsCardEditActionsMenu
                isEditing={cardProps.isEditing}
                isDirty={cardProps.isDirty}
                onReset={cardProps.onResetForm}
                onSubmit={cardProps.handleSubmit(cardProps.onSubmitForm)}
              />
            )
          }
        >
          <EditableDetailsCardRowDecorator
            field="name"
            input={<DeploymentNameControl control={cardProps.control} autoFocus excludeLabel />}
            {...cardProps}
          >
            {rowProps => (
              <DetailsCardRow label="Name" editableSettings={rowProps} isRequired>
                <AggregatedValue entity={cardProps.entity} field="name" shouldShowEditedValue={rowProps.isDirty} />
              </DetailsCardRow>
            )}
          </EditableDetailsCardRowDecorator>

          <DetailsCardRow label="Status">
            <AggregatedValue
              entity={deployment}
              field="status"
              formatter={entity => getDeploymentStatusIndicator(entity)}
            />
          </DetailsCardRow>

          <DetailsCardRow label="Message">
            <AggregatedValue entity={cardProps.entity} field="message" />
          </DetailsCardRow>

          <EditableDetailsCardRowDecorator
            field="description"
            input={<DescriptionControl control={cardProps.control} autoFocus excludeLabel />}
            {...cardProps}
          >
            {rowProps => (
              <DetailsCardRow label="Description" editableSettings={rowProps}>
                <AggregatedValue
                  entity={cardProps.entity}
                  field="description"
                  shouldShowEditedValue={rowProps.isDirty}
                />
              </DetailsCardRow>
            )}
          </EditableDetailsCardRowDecorator>

          <DetailsCardRow label="Type">
            <AggregatedValue
              entity={cardProps.entity}
              field="type"
              formatter={(entity, field) => convertToUpperCaseFirst(getProperty(entity, field) || "")}
            />
          </DetailsCardRow>

          {!!deployment.params?.scheduled_time && (
            <DetailsCardRow label="Schedule">
              <AggregatedValue
                entity={cardProps.entity}
                field="params.scheduled_time"
                formatter={(entity, field) => formatAbsoluteDate(getProperty(entity, field))}
              />
            </DetailsCardRow>
          )}

          {!!deployment.started_at && (
            <DetailsCardRow label="Started">
              <AggregatedValue
                entity={cardProps.entity}
                field="started_at"
                formatter={(entity, field) => formatRelativeDate(getProperty(entity, field))}
              />
            </DetailsCardRow>
          )}

          {!!deployment.ended_at && (
            <DetailsCardRow label="Ended">
              <AggregatedValue
                entity={cardProps.entity}
                field="ended_at"
                formatter={(entity, field) => formatRelativeDate(getProperty(entity, field))}
              />
            </DetailsCardRow>
          )}

          <DetailsCardRow label="# Players">
            <div>
              <ChipCount label={`${sumBy(aggregatedDeployments, "total_count")} total`} />
              {succeededCount > 0 && <ChipCount label={`${succeededCount} succeeded`} $isSucceeded />}
              {failedCount > 0 && <ChipCount label={`${failedCount} failed`} $isFailed />}
            </div>
          </DetailsCardRow>

          <DetailsCardRow label="Last Updated">
            <AggregatedValue
              entity={cardProps.entity}
              field="audit.updated_at"
              formatter={(entity, field) => formatRelativeDate(getProperty(entity, field))}
            />
          </DetailsCardRow>
        </DetailsCard>
      )}
    </EditableDetailsCardDecorator>
  );
};
