import * as yup from "yup";

import { type Shape } from "@ds/utils/validation";

const nameSchema = yup.string().required("Please specify location name");
const addressSchema = yup.object().shape<Shape<Address>>({
  country: yup.string().required("Please specify country"),
});

const openHoursSchema = yup.array<LocalityOpeningHours>();

export const createValidationSchema = {
  step1: yup.object().shape<Shape<Locality>>({
    address: addressSchema,
  }),
  step2: yup.object().shape<Shape<Locality>>({
    open_hours: openHoursSchema,
  }),
  step3: yup.object().shape<Shape<Locality>>({
    name: nameSchema,
  }),
};

export const updateValidationSchema = {
  info: yup.object().shape<Shape<Locality>>({
    name: nameSchema,
  }),
  address: yup.object().shape<Shape<Locality>>({
    address: addressSchema,
  }),
  openHours: yup.object().shape<Shape<Locality>>({
    open_hours: openHoursSchema,
  }),
};
