import { type FC } from "react";

import { DetailsCard, DetailsCardRow } from "@ds/components/details-view";
import { AggregatedValue } from "@ds/components/entities";

export const UserDetailsOrganizationCard: FC<{ user: User }> = ({ user }) => (
  <DetailsCard title="Organization" icon="building">
    <DetailsCardRow label="Name">
      <AggregatedValue entity={user} field="tenant.company_name" />
    </DetailsCardRow>

    <DetailsCardRow label="Owner">
      <AggregatedValue entity={user} field="tenant.owner" />
    </DetailsCardRow>

    <DetailsCardRow label="Tenant ID">
      <AggregatedValue entity={user} field="tenant.id" />
    </DetailsCardRow>
  </DetailsCard>
);
