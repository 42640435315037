import { type FC } from "react";

import { isAggregatedEntity } from "@ds/model/entity-model";

import { AggregatedValue } from "@ds/components/entities";
import { InfoView, InfoViewCallToAction } from "@ds/components/info-view";
import { getInfoViewTitle } from "@ds/components/info-view/helpers";
import { useDeepRootSelector } from "@ds/hooks";

import { selectAggregatedProjectForSelectedEntities } from "../redux/selectors/common-selectors";
import { selectTemplateExperienceFormationEntriesFilteredPerGroup } from "../redux/selectors/experience-formation-selectors";
import { ProjectFormationInfoSection } from "./common/project-formation-info-section";
import { ProjectInfoSection } from "./common/project-info-section";
import { ProjectSettingsSection } from "./common/project-settings-section";

export const ProjectInfo: FC = () => {
  const aggregatedProject = useDeepRootSelector(selectAggregatedProjectForSelectedEntities);
  const [infoPropertiesEntries, otherPropertiesPerGroup] = useDeepRootSelector(state => {
    const [infoData, otherData] = selectTemplateExperienceFormationEntriesFilteredPerGroup(
      state,
      aggregatedProject?.id,
      "details",
      ["Info"],
    );

    return [Object.entries(infoData).flatMap(([, properties]) => properties), otherData] as const;
  });

  if (aggregatedProject) {
    return (
      <InfoView
        infoTitle={getInfoViewTitle(aggregatedProject)}
        infoDescription={<AggregatedValue entity={aggregatedProject} field="description" />}
      >
        <ProjectInfoSection project={aggregatedProject} templateProperties={infoPropertiesEntries} />
        <ProjectSettingsSection project={aggregatedProject} />

        {!isAggregatedEntity(aggregatedProject) &&
          Object.entries(otherPropertiesPerGroup).map(([group, properties]) => (
            <ProjectFormationInfoSection
              key={`experience-formation-card-${group}`}
              cardTitle={properties[0][1].metadata?.details?.group_title || ""}
              project={aggregatedProject}
              templateProperties={properties}
            />
          ))}
      </InfoView>
    );
  }

  return (
    <InfoViewCallToAction infoTitle="Select project" callToActionText="Select a project in the table for more info." />
  );
};
