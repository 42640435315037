import { isArray } from "lodash";

import { EntityTypeName, isEntity } from "@ds/model/entity-model";
import { StatusEnum } from "@ds/model/status-model";

import { isPlainObject } from "@ds/utils/type-guards/common-guards";

export enum DeploymentTableTypeEnum {
  Deployments = "deployments",
}

export enum DeploymentTypeEnum {
  Code = "code",
  Content = "content",
  Firmware = "firmware",
}

export enum DeploymentScopeEnum {
  All = "all",
  Location = "location",
  Device = "device",
  Experience = "experience",
}

export const createDeploymentModel = (options?: {
  id?: number;
  name?: string;
  scope?: DeploymentScope;
  targets?: number[];
}): Deployment => ({
  __typename: EntityTypeName.DEPLOYMENT,
  id: options?.id || 0,
  name: "",
  description: "",
  type: DeploymentTypeEnum.Content,
  scope: DeploymentScopeEnum.All,
  targets: [],
  status: StatusEnum.Initializing,
  message: "",
  total_count: 0,
  succeeded_count: 0,
  failed_count: 0,
  deferred_execution: false,
});

export const isDeployment = (obj: unknown): obj is Deployment =>
  isEntity(obj) && obj.__typename === EntityTypeName.DEPLOYMENT;

export const isDeploymentArray = (obj: unknown): obj is Deployment[] => isArray(obj) && obj.every(isDeployment);

export const isDeploymentQueryOutput = (obj: unknown): obj is QueryOutput<Deployment> =>
  isPlainObject(obj) && "items" in obj && isArray(obj.items) && obj.items.every(isDeployment);
