import { type FC } from "react";
import styled from "styled-components";

import { StatusColor, StatusEnum } from "@ds/model/status-model";

import { getPrintableStatus, getStatusIconClassNameByStatus } from "@ds/utils/status-helpers";

export type ProgressIndicatorProps = {
  status?: Status;
  title?: string;
};

const Icon = styled.i<{ $status: Status }>`
  margin-right: 0.5em;
  color: ${({ $status }) => StatusColor[$status]} !important;
`;

export const ProgressIndicator: FC<ProgressIndicatorProps> = ({ status = StatusEnum.Unknown, title = "" }) => (
  <Icon
    $status={status}
    className={getStatusIconClassNameByStatus(status)}
    title={title || getPrintableStatus(status as Status)}
  />
);
