import { type FC } from "react";
import styled from "styled-components";

import { useGetFormationDisplayValue } from "@ds/modules/settings/projects/hooks/use-get-formation-display-value";

import {
  DetailsCardRow,
  EditableDetailsCardRow,
  type EditableDetailsCardRowChildProps,
} from "@ds/components/details-view";
import { isFormationPropertyRequired } from "@ds/utils/validation";

import { isBooleanFormationProperty } from "../../utils/formation-guards";

const NotRequiredFormationCardRowWrapper = styled.span`
  span::after {
    content: "";
  }
`;

export const FormationCardRow: FC<{
  entity: Project | Experience;
  field: string;
  property: TemplateFormationProperty;
  editableSettings: EditableDetailsCardRowChildProps;
}> = ({ entity, field, property, editableSettings }) => {
  const [formationDisplayValue] = useGetFormationDisplayValue(entity, field, property, editableSettings.isDirty);
  if (isBooleanFormationProperty(property)) {
    return (
      <EditableDetailsCardRow
        label={property.title}
        editableSettings={editableSettings}
        isRequired={isFormationPropertyRequired(property.metadata?.validation_rules)}
      >
        {formationDisplayValue}
      </EditableDetailsCardRow>
    );
  }

  return (
    <DetailsCardRow
      label={property.title}
      editableSettings={editableSettings}
      isRequired={isFormationPropertyRequired(property.metadata?.validation_rules)}
    >
      {formationDisplayValue}
    </DetailsCardRow>
  );
};

export const NotRequiredFormationCardRow: FC<{
  entity: Project | Experience;
  field: string;
  property: TemplateFormationProperty;
  editableSettings: EditableDetailsCardRowChildProps;
}> = props => (
  <NotRequiredFormationCardRowWrapper>
    <FormationCardRow {...props} />
  </NotRequiredFormationCardRowWrapper>
);
