import { createEntityAdapter, createSlice, PayloadAction } from "@reduxjs/toolkit";
import { all } from "redux-saga/effects";

import { deploymentsActions } from "@ds/modules/deployments/redux/slice";
import { iotEventUpdate } from "@ds/modules/iot/redux/actions";
import { IoTTopicPathEnum } from "@ds/modules/iot/utils/model";
import { DetailsTableDataTypeEnum, MainTableDataTypeEnum } from "@ds/modules/table-data/utils/model";

import { type ProgressInfo } from "@ds/services/storage";
import { getIds } from "@ds/utils/entities";
import { LoadStatus, type BaseMeta } from "@ds/utils/reducer";
import { type SagaOptions } from "@ds/utils/saga-helpers";

import { playlistAssetsActions } from "../../playlist-assets/redux/slice";
import { isApiPlaylistAssetQueryOutput } from "../../playlist-assets/utils/model";
import { contentActions } from "../../redux/slice";
import { createContentAssetFiltersByTableType, getContentAssetFileNameFromProgressInfo } from "../utils/helpers";
import { isContentAsset } from "../utils/model";
import { watchContentAssets } from "./sagas/content-assets";
import { watchContentAssetThumbnails } from "./sagas/thumbnails";

export enum ContentAssetsRemoteOperation {
  CRUD = "crud",
  CHECKBOXES = "checkboxes",
  THUMBNAILS = "thumbnails",
  IOT_FETCH = "iot_fetch",
}

export type ContentAssetsProgressChannelEvent = {
  progressInfo: ProgressInfo;
};

export type ContentAssetsMeta = BaseMeta & {
  remoteOperation: ContentAssetsRemoteOperation;
  filters?: KeysToCamelCase<ContentAssetFilters>;
};

export type ContentAssetUploadsMap = {
  [fileName: string]: {
    loadStatus: LoadStatus;
    error: string | null;

    progressInfo: ProgressInfo | null;
  };
};

export type ContentAssetThumbnailUploadsMap = {
  [fileName: string]: {
    loadStatus: LoadStatus;
    error: string | null;

    contentAssetId: number | null;
    progressInfo: ProgressInfo | null;
  };
};

interface ContentAssetsState {
  loadStatusMap: {
    [ContentAssetsRemoteOperation.CRUD]: LoadStatus;
    [ContentAssetsRemoteOperation.CHECKBOXES]: LoadStatus;
    [ContentAssetsRemoteOperation.THUMBNAILS]: LoadStatus;
    [ContentAssetsRemoteOperation.IOT_FETCH]: LoadStatus;
  };

  error: string | null;

  entities: {
    [contentAssetId: number]: ContentAsset;
  };

  ids: number[];

  tableIds: Record<string, number[]>;
  selectedIds: Record<string, number[]>;

  isUploadModalMinimized: boolean;
  uploadsByFilename: ContentAssetUploadsMap;
  thumbnailUploadsByFilename: ContentAssetThumbnailUploadsMap;
}
const entityAdapter = createEntityAdapter<ContentAsset>();

export const initialState: ContentAssetsState = entityAdapter.getInitialState({
  loadStatusMap: {
    [ContentAssetsRemoteOperation.CRUD]: LoadStatus.Idle,
    [ContentAssetsRemoteOperation.CHECKBOXES]: LoadStatus.Idle,
    [ContentAssetsRemoteOperation.THUMBNAILS]: LoadStatus.Idle,
    [ContentAssetsRemoteOperation.IOT_FETCH]: LoadStatus.Idle,
  },

  error: null,

  tableIds: {
    [MainTableDataTypeEnum.ContentVideos]: [],
    [MainTableDataTypeEnum.ContentImages]: [],
    [MainTableDataTypeEnum.ContentAudio]: [],
  },
  selectedIds: {
    [MainTableDataTypeEnum.ContentVideos]: [],
    [MainTableDataTypeEnum.ContentImages]: [],
    [MainTableDataTypeEnum.ContentAudio]: [],
  },

  isUploadModalMinimized: false,
  uploadsByFilename: {},
  thumbnailUploadsByFilename: {},
}) as ContentAssetsState;

const getTableTypeOrDefault = (
  tableType: MainTableDataTypeEnum | DetailsTableDataTypeEnum = MainTableDataTypeEnum.ContentVideos,
) => tableType;

const removeEntities = (
  state: ContentAssetsState,
  payload: number[],
  tableType:
    | MainTableDataTypeEnum.ContentVideos
    | MainTableDataTypeEnum.ContentImages
    | MainTableDataTypeEnum.ContentAudio,
) => {
  entityAdapter.removeMany(state, payload);

  state.tableIds[tableType] = state.tableIds[tableType].filter(id => !payload.includes(id));
  state.selectedIds[tableType] = state.selectedIds[tableType].filter(id => !payload.includes(id));
};

const sliceType = "CONTENT_ASSETS";
const slice = createSlice({
  name: sliceType,
  initialState,
  reducers: {
    fetchContentAssets: {
      reducer(state, { meta }: PayloadAction<undefined, string, ContentAssetsMeta>) {
        state.loadStatusMap[meta.remoteOperation] = LoadStatus.Loading;
        state.error = null;

        if (meta.remoteOperation === ContentAssetsRemoteOperation.CHECKBOXES) {
          state.selectedIds[getTableTypeOrDefault(meta.options?.tableType)] =
            state.tableIds[getTableTypeOrDefault(meta.options?.tableType)];
        }
      },
      prepare(
        filters: KeysToCamelCase<ContentAssetFilters> = {},
        options: SagaOptions = {},
        remoteOperation: ContentAssetsRemoteOperation = ContentAssetsRemoteOperation.CRUD,
      ) {
        return {
          payload: undefined,
          meta: {
            remoteOperation,
            filters,
            options,
          },
        };
      },
    },
    fetchContentAssetsFailed: {
      reducer(state, { meta, error }: PayloadAction<undefined, string, ContentAssetsMeta, string>) {
        state.loadStatusMap[meta.remoteOperation] = LoadStatus.Failed;
        state.error = error;

        if (meta.remoteOperation === ContentAssetsRemoteOperation.CHECKBOXES) {
          state.selectedIds[getTableTypeOrDefault(meta.options?.tableType)] = [];
        }
      },
      prepare(meta: ContentAssetsMeta, { message }: ErrorLike) {
        return { payload: undefined, meta, error: message };
      },
    },
    fetchContentAssetsSucceeded: {
      reducer(state, { payload, meta }: PayloadAction<QueryOutput<ContentAsset>, string, ContentAssetsMeta>) {
        state.loadStatusMap[meta.remoteOperation] = LoadStatus.Succeeded;
        state.error = null;

        entityAdapter.setMany(state, payload.items);

        if (meta.remoteOperation === ContentAssetsRemoteOperation.CHECKBOXES) {
          state.selectedIds[getTableTypeOrDefault(meta.options?.tableType)] = payload.items.map(({ id }) => id);
        } else {
          state.tableIds[getTableTypeOrDefault(meta.options?.tableType)] = payload.items.map(({ id }) => id);
          state.selectedIds[getTableTypeOrDefault(meta.options?.tableType)] = state.selectedIds[
            getTableTypeOrDefault(meta.options?.tableType)
          ].filter(id => state.ids.includes(id));
        }
      },
      prepare(payload: QueryOutput<ContentAsset>, meta: ContentAssetsMeta) {
        return { payload, meta };
      },
    },
    fetchContentAsset: {
      reducer(state, { meta }: PayloadAction<number, string, ContentAssetsMeta, never>) {
        state.loadStatusMap[meta.remoteOperation] = LoadStatus.Loading;
        state.error = null;
      },
      prepare(payload: number, options: SagaOptions = {}) {
        return { payload, meta: { remoteOperation: ContentAssetsRemoteOperation.CRUD, options } };
      },
    },
    fetchContentAssetFailed: {
      reducer(state, { meta, error }: PayloadAction<number, string, ContentAssetsMeta, string>) {
        state.loadStatusMap[meta.remoteOperation] = LoadStatus.Failed;
        state.error = error;
      },
      prepare(payload: number, meta: ContentAssetsMeta, { message }: ErrorLike) {
        return { payload, meta, error: message };
      },
    },
    fetchContentAssetSucceeded: {
      reducer(state, { payload, meta }: PayloadAction<ContentAsset, string, ContentAssetsMeta>) {
        state.loadStatusMap[meta.remoteOperation] = LoadStatus.Succeeded;
        state.error = null;

        entityAdapter.setOne(state, payload);

        state.tableIds[getTableTypeOrDefault(meta.options?.tableType)] = state.tableIds[
          getTableTypeOrDefault(meta.options?.tableType)
        ].filter(id => id !== payload.id);

        state.selectedIds[getTableTypeOrDefault(meta.options?.tableType)] = state.selectedIds[
          getTableTypeOrDefault(meta.options?.tableType)
        ].filter(id => id !== payload.id);
      },
      prepare(payload: ContentAsset, meta: ContentAssetsMeta) {
        return { payload, meta };
      },
    },
    deleteContentAssets: {
      reducer(state, { meta }: PayloadAction<number[], string, ContentAssetsMeta>) {
        state.loadStatusMap[meta.remoteOperation] = LoadStatus.Loading;
        state.error = null;
      },
      prepare(idsToDelete: number[], redirectTo?: string) {
        return {
          payload: idsToDelete,
          meta: {
            remoteOperation: ContentAssetsRemoteOperation.CRUD,
            options: { redirectTo },
          } as ContentAssetsMeta,
        };
      },
    },
    deleteContentAssetsFailed: {
      reducer(state, { meta, error }: PayloadAction<number[], string, ContentAssetsMeta, string>) {
        state.loadStatusMap[meta.remoteOperation] = LoadStatus.Failed;
        state.error = error;
      },
      prepare(idsToDelete: number[], meta: ContentAssetsMeta, { message }: ErrorLike) {
        return { payload: idsToDelete, meta, error: message };
      },
    },
    deleteContentAssetsSucceeded: {
      reducer(state, { payload, meta }: PayloadAction<number[], string, ContentAssetsMeta>) {
        state.loadStatusMap[meta.remoteOperation] = LoadStatus.Succeeded;
        state.error = null;

        removeEntities(state, payload, MainTableDataTypeEnum.ContentVideos);
        removeEntities(state, payload, MainTableDataTypeEnum.ContentImages);
        removeEntities(state, payload, MainTableDataTypeEnum.ContentAudio);
      },
      prepare(idsToDelete: number[], meta: ContentAssetsMeta) {
        return { payload: idsToDelete, meta };
      },
    },
    selectContentAssets: {
      reducer(state, { payload, meta }: PayloadAction<number[], string, ContentAssetsMeta>) {
        state.loadStatusMap[meta.remoteOperation] = LoadStatus.Succeeded;
        state.selectedIds[getTableTypeOrDefault(meta.options?.tableType)] = payload;
      },
      prepare(contentAssetsOrIds: ContentAsset[] | number[], tableType: MainTableDataTypeEnum) {
        return {
          payload: getIds(contentAssetsOrIds),
          meta: {
            remoteOperation: ContentAssetsRemoteOperation.CHECKBOXES,
            options: { tableType },
            filters: createContentAssetFiltersByTableType({ tableType }),
          },
        };
      },
    },
    toggleSelected: {
      reducer(state, { payload, meta }: PayloadAction<number, string, ContentAssetsMeta>) {
        state.loadStatusMap[meta.remoteOperation] = LoadStatus.Succeeded;

        state.selectedIds[getTableTypeOrDefault(meta.options?.tableType)] = state.selectedIds[
          getTableTypeOrDefault(meta.options?.tableType)
        ].includes(payload)
          ? state.selectedIds[getTableTypeOrDefault(meta.options?.tableType)].filter(id => id !== payload)
          : [...state.selectedIds[getTableTypeOrDefault(meta.options?.tableType)], payload];
      },
      prepare(payload: number, tableType: MainTableDataTypeEnum) {
        return {
          payload,
          meta: {
            remoteOperation: ContentAssetsRemoteOperation.CHECKBOXES,
            options: { tableType },
            filters: createContentAssetFiltersByTableType({ tableType }),
          },
        };
      },
    },
    uploadContentAssets: {
      reducer(state, { payload, meta }: PayloadAction<File[], string, ContentAssetsMeta>) {
        state.loadStatusMap[meta.remoteOperation] = LoadStatus.Loading;

        const allFinished = Object.keys(state.uploadsByFilename).every(
          fileName => state.uploadsByFilename[fileName].loadStatus !== LoadStatus.Loading,
        );

        if (allFinished) {
          state.uploadsByFilename = {};
        }

        payload.forEach(({ name }) => {
          state.uploadsByFilename[name] = { loadStatus: LoadStatus.Loading, error: null, progressInfo: null };
        });
      },
      prepare(payload: File[], tableType: MainTableDataTypeEnum) {
        return {
          payload,
          meta: {
            remoteOperation: ContentAssetsRemoteOperation.CRUD,
            filters: createContentAssetFiltersByTableType({ tableType }),
          },
        };
      },
    },
    uploadContentAsset: {
      reducer(state, { payload, meta }: PayloadAction<File, string, ContentAssetsMeta>) {
        state.loadStatusMap[meta.remoteOperation] = LoadStatus.Loading;
        const allFinished = Object.keys(state.uploadsByFilename).every(
          fileName => state.uploadsByFilename[fileName].loadStatus !== LoadStatus.Loading,
        );

        if (allFinished) {
          state.uploadsByFilename = {};
        }

        state.uploadsByFilename[payload.name] = {
          loadStatus: LoadStatus.Loading,
          error: null,

          progressInfo: null,
        };
      },
      prepare(payload: File, tableType: MainTableDataTypeEnum) {
        return {
          payload,
          meta: {
            remoteOperation: ContentAssetsRemoteOperation.CRUD,
            filters: createContentAssetFiltersByTableType({ tableType, name: payload.name }),
          },
        };
      },
    },
    uploadContentAssetFailed: {
      reducer(state, { payload, meta, error }: PayloadAction<string, string, ContentAssetsMeta, string>) {
        state.loadStatusMap[meta.remoteOperation] = LoadStatus.Failed;
        state.uploadsByFilename[payload].loadStatus = LoadStatus.Failed;
        state.uploadsByFilename[payload].error = error;
      },
      prepare({ name }: File, meta: ContentAssetsMeta, { message }: ErrorLike) {
        return { payload: name, meta, error: message };
      },
    },
    uploadContentAssetSucceeded: {
      reducer(state, { payload, meta }: PayloadAction<ContentAsset, string, ContentAssetsMeta>) {
        state.loadStatusMap[meta.remoteOperation] = LoadStatus.Succeeded;
        state.uploadsByFilename[payload.name].loadStatus = LoadStatus.Succeeded;
        state.uploadsByFilename[payload.name].error = null;

        entityAdapter.setOne(state, payload);
      },
      prepare(payload: ContentAsset, meta: ContentAssetsMeta) {
        return { payload, meta };
      },
    },
    progressUploadingContentAsset: (state, { payload }: PayloadAction<ProgressInfo>) => {
      const fileName = getContentAssetFileNameFromProgressInfo(payload);
      if (fileName) {
        state.uploadsByFilename[fileName].progressInfo = payload;
      }
    },
    deleteContentAssetThumbnail: {
      reducer(state, { meta }: PayloadAction<Thumbnail, string, ContentAssetsMeta>) {
        state.loadStatusMap[meta.remoteOperation] = LoadStatus.Loading;
        state.error = null;
      },
      prepare(payload: Thumbnail, tableType: MainTableDataTypeEnum) {
        return {
          payload,
          meta: {
            remoteOperation: ContentAssetsRemoteOperation.THUMBNAILS,
            filters: createContentAssetFiltersByTableType({ tableType }),
          },
        };
      },
    },
    deleteContentAssetThumbnailFailed: {
      reducer(state, { meta, error }: PayloadAction<Thumbnail, string, ContentAssetsMeta, string>) {
        state.loadStatusMap[meta.remoteOperation] = LoadStatus.Failed;
        state.error = error;
      },
      prepare(payload: Thumbnail, meta: ContentAssetsMeta, { message }: ErrorLike) {
        return { payload, meta, error: message };
      },
    },
    deleteContentAssetThumbnailSucceeded: {
      reducer(state, { payload, meta }: PayloadAction<Thumbnail, string, ContentAssetsMeta>) {
        state.loadStatusMap[meta.remoteOperation] = LoadStatus.Succeeded;
        state.error = null;

        entityAdapter.updateOne(state, {
          id: payload.asset_id,
          changes: {
            thumbnail: undefined,
          },
        });
      },
      prepare(payload: Thumbnail, meta: ContentAssetsMeta) {
        return { payload, meta };
      },
    },
    uploadContentAssetThumbnails: {
      reducer(
        state,
        {
          payload,
          meta,
        }: PayloadAction<[{ thumbnailFile: File; contentAsset: ContentAsset }], string, ContentAssetsMeta>,
      ) {
        state.loadStatusMap[meta.remoteOperation] = LoadStatus.Loading;
        const allFinished = Object.keys(state.thumbnailUploadsByFilename).every(
          fileName => state.thumbnailUploadsByFilename[fileName].loadStatus !== LoadStatus.Loading,
        );

        if (allFinished) {
          state.thumbnailUploadsByFilename = {};
        }

        payload.forEach(({ thumbnailFile: { name } }) => {
          state.thumbnailUploadsByFilename[name] = {
            loadStatus: LoadStatus.Loading,
            error: null,

            contentAssetId: null,
            progressInfo: null,
          };
        });
      },
      prepare(payload: [{ thumbnailFile: File; contentAsset: ContentAsset }], tableType: MainTableDataTypeEnum) {
        return {
          payload,
          meta: {
            remoteOperation: ContentAssetsRemoteOperation.THUMBNAILS,
            filters: createContentAssetFiltersByTableType({ tableType }),
          },
        };
      },
    },
    uploadContentAssetThumbnail: {
      reducer(
        state,
        {
          payload,
          meta,
        }: PayloadAction<{ thumbnailFile: File; contentAsset: ContentAsset }, string, ContentAssetsMeta, never>,
      ) {
        state.loadStatusMap[meta.remoteOperation] = LoadStatus.Loading;
        state.thumbnailUploadsByFilename[payload.thumbnailFile.name] = {
          loadStatus: LoadStatus.Loading,
          error: null,

          contentAssetId: null,
          progressInfo: null,
        };
      },
      prepare(payload: { thumbnailFile: File; contentAsset: ContentAsset }, tableType: MainTableDataTypeEnum) {
        return {
          payload,
          meta: {
            remoteOperation: ContentAssetsRemoteOperation.THUMBNAILS,
            filters: createContentAssetFiltersByTableType({ tableType }),
          },
        };
      },
    },
    uploadContentAssetThumbnailFailed: {
      reducer(state, { payload, meta, error }: PayloadAction<string, string, ContentAssetsMeta, string>) {
        state.loadStatusMap[meta.remoteOperation] = LoadStatus.Failed;
        state.thumbnailUploadsByFilename[payload].loadStatus = LoadStatus.Failed;
        state.thumbnailUploadsByFilename[payload].error = error;
      },
      prepare(
        payload: { thumbnailFile: File; contentAsset: ContentAsset },
        meta: ContentAssetsMeta,
        { message }: ErrorLike,
      ) {
        return { payload: payload.thumbnailFile.name, meta, error: message };
      },
    },
    uploadContentAssetThumbnailSucceeded: {
      reducer(state, { payload, meta }: PayloadAction<Thumbnail, string, ContentAssetsMeta>) {
        state.loadStatusMap[meta.remoteOperation] = LoadStatus.Succeeded;
        state.thumbnailUploadsByFilename[payload.file_name].loadStatus = LoadStatus.Succeeded;
        state.thumbnailUploadsByFilename[payload.file_name].error = null;

        entityAdapter.updateOne(state, {
          id: payload.asset_id,
          changes: {
            thumbnail: payload,
          },
        });
      },
      prepare(payload: Thumbnail, meta: ContentAssetsMeta) {
        return { payload, meta };
      },
    },
    progressUploadingContentAssetThumbnail: (state, { payload }: PayloadAction<ProgressInfo>) => {
      const fileName = getContentAssetFileNameFromProgressInfo(payload);
      if (fileName) {
        state.thumbnailUploadsByFilename[fileName].progressInfo = payload;
      }
    },
    setUploadModalMinimized: (state, { payload }: PayloadAction<boolean>) => {
      state.isUploadModalMinimized = payload;
    },
    iotUpdate: (state, { payload }: PayloadAction<ContentAsset>) => {
      if (payload.audit?.deleted_at) {
        removeEntities(state, [payload.id], MainTableDataTypeEnum.ContentVideos);
        removeEntities(state, [payload.id], MainTableDataTypeEnum.ContentImages);
        removeEntities(state, [payload.id], MainTableDataTypeEnum.ContentAudio);
      } else {
        entityAdapter.setOne(state, payload);
      }
    },
  },
  extraReducers: builder => {
    builder.addCase(contentActions.switchView, state => {
      state.loadStatusMap[ContentAssetsRemoteOperation.CRUD] = LoadStatus.Idle;
      state.error = null;
    });

    builder.addCase(playlistAssetsActions.fetchPlaylistAssetsSucceeded, (state, { payload }) => {
      if (isApiPlaylistAssetQueryOutput(payload)) {
        entityAdapter.setMany(
          state,
          payload.items.map(({ asset }) => asset),
        );
      }
    });

    builder.addCase(playlistAssetsActions.createPlaylistAssetSucceeded, (state, { payload }) => {
      entityAdapter.setOne(state, payload.asset);
    });

    builder.addCase(playlistAssetsActions.updatePlaylistAssetsSucceeded, (state, { payload }) => {
      entityAdapter.setMany(state, payload.flatMap(({ asset }) => asset || []).filter(isContentAsset));
    });

    builder.addCase(playlistAssetsActions.uploadPlaylistAssetSucceeded, (state, { payload }) => {
      entityAdapter.setOne(state, payload.asset);
    });

    builder.addCase(deploymentsActions.fetchDeploymentsSucceeded, (state, { payload, meta }) => {
      if (!meta.options?.cache?.fetchedFromCache) {
        entityAdapter.setMany(
          state,
          payload.items.flatMap(({ details }) => details?.entities || []).filter(isContentAsset),
        );
      }
    });

    builder.addCase(deploymentsActions.fetchDeploymentSucceeded, (state, { payload, meta }) => {
      if (!meta.options?.cache?.fetchedFromCache) {
        entityAdapter.setMany(state, (payload.details?.entities || []).filter(isContentAsset));
      }
    });

    builder.addCase(deploymentsActions.createDeploymentSucceeded, (state, { payload }) => {
      entityAdapter.setMany(state, (payload.details?.entities || []).filter(isContentAsset));
    });

    builder.addCase(deploymentsActions.updateDeploymentsSucceeded, (state, { payload }) => {
      entityAdapter.setMany(state, payload.flatMap(({ details }) => details?.entities || []).filter(isContentAsset));
    });

    builder.addCase(iotEventUpdate(IoTTopicPathEnum.PlaylistAssets), (state, { payload }) => {
      entityAdapter.setOne(state, payload.asset);
    });

    builder.addCase(iotEventUpdate(IoTTopicPathEnum.ContentThumbnails), (state, { payload }) => {
      if (state.entities[payload.asset_id]) {
        state.entities[payload.asset_id].thumbnail = payload.audit?.deleted_at ? undefined : payload;
      }
    });
  },
});

export const { name: contentAssetsType, actions: contentAssetsActions, reducer: contentAssetsReducer } = slice;

export const entitySelectors = entityAdapter.getSelectors();

export function* rootContentAssetsSaga() {
  yield all([watchContentAssets(), watchContentAssetThumbnails()]);
}
