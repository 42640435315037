import styled from "styled-components";

export enum IconType {
  Upload = "pi-upload",
  Create = "pi-plus",
  Update = "pi-pencil",
  Delete = "pi-trash",
  Save = "pi-save",
  Undo = "pi-undo",
  Deploy = "pi-sitemap",
  Pair = "pi-sort-alt",
  Refresh = "pi-refresh",
  ListView = "pi-align-justify",
  TilesView = "pi-th-large",
  InfoView = "pi-book",
  Previous = "pi-chevron-left",
  Next = "pi-chevron-right",
  Left = "pi-arrow-left",
  Right = "pi-arrow-right",
  Up = "pi-angle-up",
  Down = "pi-angle-down",
  SortUp = "pi-sort-up",
  SortDown = "pi-sort-down",
  Close = "pi-times",
  Ok = "pi-check",
  InProgress = "pi-spin pi-spinner",
  Replay = "pi-replay",
  Bars = "pi-bars",
  Maximize = "pi-window-maximize",
  Eye = "pi-eye",
  Cog = "pi-cog",
}

export const SmallIcon = styled.i`
  cursor: pointer;
  color: var(--primaryColor100) !important;
`;
