import * as yup from "yup";

import { type Shape } from "@ds/utils/validation";

const nameSchema = yup.string().required("Please specify playlist name");
const mediaTypesSchema = yup.array().of(yup.string().required()).min(1, "Please specify at least one media type");

export const createValidationSchema = {
  step1: yup.object().shape<Shape<Playlist>>({
    name: nameSchema,
    media_types: mediaTypesSchema,
  }),
};

export const updateValidationSchema = {
  info: yup.object().shape<Shape<Playlist>>({
    name: nameSchema,
    media_types: mediaTypesSchema,
  }),
};
