export const MULTIPLE_IDS_PARAM = "ids";

export const EXPERIENCE_ID_PARAM = "experienceId";
export const PLAYER_ID_PARAM = "playerId";
export const PERIPHERAL_ID_PARAM = "peripheralId";
export const LOCATION_ID_PARAM = "locationId";
export const PLAYLIST_ID_PARAM = "playlistId";
export const DEPLOYMENT_ID_PARAM = "deploymentId";
export const PROJECT_ID_PARAM = "projectId";
export const USER_ID_PARAM = "userId";
export const INVITATION_ID_PARAM = "invitationId";
