import { useCallback, type FC } from "react";
import { type DeepPartial } from "react-hook-form";

import { useGetAggregatedEntityIds } from "@ds/hooks/use-get-aggregated-entity-ids";
import { useParseQueryIds } from "@ds/hooks/use-parse-query-ids";
import { useReduxEntitiesFetch } from "@ds/hooks/use-redux-entities-fetch";

import { EntityTypeName } from "@ds/model/entity-model";

import { MainTableDataTypeEnum } from "@ds/modules/table-data/utils/model";

import { DetailsView } from "@ds/components/details-view";
import { AbsoluteLoader } from "@ds/components/loaders/loading";
import { INVITATION_ID_PARAM } from "@ds/constants/router";
import { QueryParamType, useDeepRootSelector, useRootDispatch, useRootSelector } from "@ds/hooks";
import { pluralizeTotalSelectedTitle } from "@ds/utils/localization/common";
import { isMultiple } from "@ds/utils/numbers";

import { InvitationDetailsInfoCard } from "./components/details/invitation-details-info-card";
import { InvitationDetailsProjectsCard } from "./components/details/invitation-details-projects-card";
import { selectAggregatedInvitationByIds, selectInvitationsIsLoading } from "./redux/selectors";
import { invitationsActions } from "./redux/slice";

export const InvitationDetailsPage: FC = () => {
  const queryIds = useParseQueryIds(INVITATION_ID_PARAM, QueryParamType.Email);
  const invitation = useDeepRootSelector(state => selectAggregatedInvitationByIds(state, queryIds));
  const aggregatedInvitationIds = useGetAggregatedEntityIds(invitation);

  const isLoading = useRootSelector(selectInvitationsIsLoading);

  const [toggleRefetch] = useReduxEntitiesFetch(
    { entityType: EntityTypeName.SETTINGS_INVITATION, filters: { id: queryIds } },
    { initialLoad: true },
  );

  const dispatch = useRootDispatch();
  const onSubmitHandler = useCallback(
    (changedFields: DeepPartial<Invitation>) =>
      dispatch(invitationsActions.updateInvitations(aggregatedInvitationIds, changedFields)),
    [dispatch, aggregatedInvitationIds],
  );

  if (!invitation) {
    return <AbsoluteLoader isLoading />;
  }

  return (
    <DetailsView
      title={
        isMultiple(invitation?.metadata?.totalAggregated)
          ? pluralizeTotalSelectedTitle("invitation", invitation?.metadata?.totalAggregated)
          : invitation.email
      }
      tableType={MainTableDataTypeEnum.Invitations}
      isLoading={isLoading}
      onRefresh={toggleRefetch}
    >
      <InvitationDetailsInfoCard invitation={invitation} onSubmit={onSubmitHandler} />
      <InvitationDetailsProjectsCard invitation={invitation} onSubmit={onSubmitHandler} />
    </DetailsView>
  );
};
