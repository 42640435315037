import { UnassignedLabel } from "@ds/components/forms/labels/common";
import { ShadedLabel } from "@ds/components/forms/labels/styles";
import { StatusIndicator } from "@ds/components/status-indicator";
import type { ColumnBodyOptions } from "@ds/components/table/table-columns";
import { TextLink } from "@ds/components/text-link";
import { composeDetailsRouteByEntityType } from "@ds/utils/router";
import { getPrintableStatus } from "@ds/utils/status-helpers";

export const getExperienceStatusIndicator = (
  experience: Experience,
  options?: ColumnBodyOptions & { key?: string },
) => (
  <StatusIndicator key={options?.key} status={experience.status} title={experience.status_message}>
    {getPrintableStatus(experience.status)}
  </StatusIndicator>
);

export const getExperienceStatusIndicatorWithName = (experience: Experience, options?: { key?: string }) => (
  <StatusIndicator key={options?.key} status={experience.status} title={experience.status_message}>
    {experience.name || <UnassignedLabel />}
    <ShadedLabel className="ml-2">{`[ ${getPrintableStatus(experience.status)} ]`}</ShadedLabel>
  </StatusIndicator>
);

export const getExperienceStatusIndicatorWithNameAndLink = (experience: Experience, options?: { key?: string }) => {
  const status = `[ ${getPrintableStatus(experience.status)} ]`;
  return (
    <StatusIndicator key={options?.key} status={experience.status} title={`${experience.name} ${status}`}>
      <TextLink to={composeDetailsRouteByEntityType(experience)}>{experience.name || <UnassignedLabel />}</TextLink>
      <ShadedLabel className="ml-2">{status}</ShadedLabel>
    </StatusIndicator>
  );
};
