import { useCallback, useState, type FC } from "react";
import styled, { css } from "styled-components";

const GroupWrapper = styled.div`
  padding: 10px;
`;

const ItemWrapper = styled.section<{ isSelected: boolean }>`
  display: flex;
  align-items: center;
  padding: 20px;
  border-radius: 8px;
  cursor: pointer;

  ${props =>
    props.isSelected
      ? css`
          outline: 2px solid var(--primaryColor060);
          box-shadow: 0 4px 16px var(--primaryColor015);
          background: linear-gradient(0deg, var(--whiteShadowColor100), var(--primaryColor005)),
            var(--whiteShadowColor100);
        `
      : css`
          outline: 1px solid var(--textColor060);
          box-shadow: 0 0px 4px var(--textColor030);
        `};

  & + & {
    margin-top: 24px;
  }
`;

const ItemIcon = styled.i`
  font-size: 1.4rem;
  margin-right: 30px;
`;

const ItemInfo = styled.p`
  margin: 0;
  font-size: 0.95rem;
`;

const ItemLabel = styled.h5`
  margin: 0;
  font-size: 1.2rem;
  font-weight: 600;
  margin-bottom: 10px;
`;

type ValueType = string | number;

export type SelectButtonElementOptions = {
  value: ValueType;
  label: string;
  infoText?: string;
  icon?: string;
};

const SelectButtonListItem: FC<{
  item: SelectButtonElementOptions;
  isSelected: boolean;
  onClick: () => void;
}> = ({ item, onClick, isSelected }) => (
  <ItemWrapper isSelected={isSelected} onClick={onClick}>
    {!!item.icon && <ItemIcon className={`pi pi-${item.icon}`} />}
    <div>
      <ItemLabel>{item.label}</ItemLabel>
      <ItemInfo>{item.infoText}</ItemInfo>
    </div>
  </ItemWrapper>
);

export const SelectButtonsList: FC<{
  items: SelectButtonElementOptions[];
  selected?: ValueType;
  onChange: (value: ValueType) => void;
}> = ({ items, selected, onChange }) => {
  const [selectedItem, setSelectedItem] = useState(selected);

  const onItemClickHandler = useCallback(
    (item: SelectButtonElementOptions) => () => {
      setSelectedItem(item.value);
      onChange(item.value);
    },
    [onChange],
  );

  return (
    <GroupWrapper>
      {items.map(item => (
        <SelectButtonListItem
          key={`select-button-${item.value}`}
          item={item}
          isSelected={selectedItem === item.value}
          onClick={onItemClickHandler(item)}
        />
      ))}
    </GroupWrapper>
  );
};
