import { EntitySubtypeName } from "@ds/model/entity-model";

import { DetailsTableDataTypeEnum, MainTableDataTypeEnum } from "@ds/modules/table-data/utils/model";

import { type ProgressInfo } from "@ds/services/storage";

import { assetMediaTypes } from "./../../utils/constants";
import { ApiContentTypeEnum, ContentTypeNameEnum } from "./model";

export const getSupportedFormatsByTableType = (tableType: MainTableDataTypeEnum) => {
  switch (tableType) {
    case MainTableDataTypeEnum.ContentVideos:
      return assetMediaTypes.video;
    case MainTableDataTypeEnum.ContentImages:
      return assetMediaTypes.image;
    case MainTableDataTypeEnum.ContentAudio:
      return assetMediaTypes.audio;
    default:
      return {};
  }
};

export const getSupportedFormatsByEntityType = (entityType?: EntitySubtypeName) => {
  switch (entityType) {
    case EntitySubtypeName.CONTENT_ASSET_VIDEO:
      return assetMediaTypes.video;
    case EntitySubtypeName.CONTENT_ASSET_IMAGE:
      return assetMediaTypes.image;
    case EntitySubtypeName.CONTENT_ASSET_AUDIO:
      return assetMediaTypes.audio;
    default:
      return {};
  }
};

export const getApiContentTypeByTableType = (tableType: MainTableDataTypeEnum | DetailsTableDataTypeEnum) => {
  switch (tableType) {
    case MainTableDataTypeEnum.ContentVideos:
      return ApiContentTypeEnum.Video;
    case MainTableDataTypeEnum.ContentImages:
      return ApiContentTypeEnum.Image;
    case MainTableDataTypeEnum.ContentAudio:
      return ApiContentTypeEnum.Audio;
    default:
      throw new Error("Unsupported content type");
  }
};

export const getApiContentTypeByEntitySubtype = (entityType: EntitySubtypeName) => {
  switch (entityType) {
    case EntitySubtypeName.CONTENT_ASSET_VIDEO:
      return ApiContentTypeEnum.Video;
    case EntitySubtypeName.CONTENT_ASSET_IMAGE:
      return ApiContentTypeEnum.Image;
    case EntitySubtypeName.CONTENT_ASSET_AUDIO:
      return ApiContentTypeEnum.Audio;
    default:
      throw new Error("Unsupported content type");
  }
};

export const getMainTableTypeByApiContentType = (apiContentType?: ApiContentType) => {
  switch (apiContentType) {
    case ApiContentTypeEnum.Video:
      return MainTableDataTypeEnum.ContentVideos;
    case ApiContentTypeEnum.Image:
      return MainTableDataTypeEnum.ContentImages;
    case ApiContentTypeEnum.Audio:
      return MainTableDataTypeEnum.ContentAudio;
    default:
      throw new Error("Unsupported content type");
  }
};

export const getEntitySubtypeByApiContentType = (apiContentType?: ApiContentType) => {
  switch (apiContentType) {
    case ApiContentTypeEnum.Video:
      return EntitySubtypeName.CONTENT_ASSET_VIDEO;
    case ApiContentTypeEnum.Image:
      return EntitySubtypeName.CONTENT_ASSET_IMAGE;
    case ApiContentTypeEnum.Audio:
      return EntitySubtypeName.CONTENT_ASSET_AUDIO;
    default:
      throw new Error("Unsupported content type");
  }
};

export const getMainTableDataTypeByEntitySubtype = (entityType: EntitySubtypeName) => {
  switch (entityType) {
    case EntitySubtypeName.CONTENT_ASSET_VIDEO:
      return MainTableDataTypeEnum.ContentVideos;
    case EntitySubtypeName.CONTENT_ASSET_IMAGE:
      return MainTableDataTypeEnum.ContentImages;
    case EntitySubtypeName.CONTENT_ASSET_AUDIO:
      return MainTableDataTypeEnum.ContentAudio;
    default:
      throw new Error("Unsupported content type");
  }
};

export const getEntitySubtypeByTableType = (tableType: MainTableDataTypeEnum | DetailsTableDataTypeEnum) => {
  switch (tableType) {
    case MainTableDataTypeEnum.ContentVideos:
      return EntitySubtypeName.CONTENT_ASSET_VIDEO;
    case MainTableDataTypeEnum.ContentImages:
      return EntitySubtypeName.CONTENT_ASSET_IMAGE;
    case MainTableDataTypeEnum.ContentAudio:
      return EntitySubtypeName.CONTENT_ASSET_AUDIO;
    default:
      throw new Error("Unsupported content type");
  }
};

export const getContentTypeNameByTableType = (tableType: MainTableDataTypeEnum | DetailsTableDataTypeEnum) => {
  switch (tableType) {
    case MainTableDataTypeEnum.ContentVideos:
      return ContentTypeNameEnum.Video;
    case MainTableDataTypeEnum.ContentImages:
      return ContentTypeNameEnum.Image;
    case MainTableDataTypeEnum.ContentAudio:
      return ContentTypeNameEnum.Audio;
    default:
      throw new Error("Unsupported content type");
  }
};

export const createContentAssetFiltersByEntityType = ({
  entityType,
  name,
}: {
  entityType?: EntitySubtypeName;
  name?: string;
}): KeysToCamelCase<ContentAssetFilters> =>
  entityType ? { contentType: getApiContentTypeByEntitySubtype(entityType), name } : { name };

export const createContentAssetFiltersByTableType = ({
  tableType,
  name,
}: {
  tableType?: MainTableDataTypeEnum;
  name?: string;
}): KeysToCamelCase<ContentAssetFilters> =>
  tableType ? { contentType: getApiContentTypeByTableType(tableType), name } : { name };

export const getContentAssetFileNameFromProgressInfo = (progressInfo: ProgressInfo) => {
  let assetFileName = progressInfo.name;
  if (!assetFileName && progressInfo.key) {
    const keyPart = progressInfo.key.substring(progressInfo.key.lastIndexOf("/") + 1);
    assetFileName = keyPart.substring(keyPart.indexOf("_") + 1);
  }

  return assetFileName;
};

export const isContentTypeVideo = (contentType: string) =>
  contentType.toLowerCase().startsWith(ContentTypeNameEnum.Video);

export const isContentTypeImage = (contentType: string) =>
  contentType.toLowerCase().startsWith(ContentTypeNameEnum.Image);

export const isContentTypeAudio = (contentType: string) =>
  contentType.toLowerCase().startsWith(ContentTypeNameEnum.Audio);

export const getMainTableTypeByContentType = (contentType: string) => {
  if (isContentTypeVideo(contentType)) {
    return MainTableDataTypeEnum.ContentVideos;
  }

  if (isContentTypeImage(contentType)) {
    return MainTableDataTypeEnum.ContentImages;
  }

  if (isContentTypeAudio(contentType)) {
    return MainTableDataTypeEnum.ContentAudio;
  }

  throw new Error("Unsupported content type");
};
