import { type FC } from "react";

import { Wizard } from "@ds/components/wizard";

import { StepFinal } from "./step-final";
import { Step1Main } from "./step1-main";

export const CreateInvitationModal: FC<{ onClose: () => void }> = ({ onClose }) => (
  <Wizard width="40vw" height="80vh" onClose={onClose}>
    <Step1Main />
    <StepFinal />
  </Wizard>
);
