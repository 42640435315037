import { type FC } from "react";
import { Route, Routes, useLocation } from "react-router-dom";

import { LocationDetailsPage } from "@ds/modules/locations/location-details-page";
import { LocationsMainPage } from "@ds/modules/locations/locations-main-page";

import { LOCATION_ID_PARAM, MULTIPLE_IDS_PARAM } from "@ds/constants/router";

const LocationsRouteSwitch = () => {
  const { search } = useLocation();
  return search.startsWith(`?${MULTIPLE_IDS_PARAM}`) ? <LocationDetailsPage /> : <LocationsMainPage />;
};

export const LocationsRouter: FC = () => (
  <Routes>
    <Route path="*" element={<LocationsRouteSwitch />} />
    <Route path={`:${LOCATION_ID_PARAM}`} element={<LocationDetailsPage />} />
  </Routes>
);
