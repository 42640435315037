import { combineReducers, type AnyAction, type Reducer } from "@reduxjs/toolkit";
import { produce } from "immer";
import { type RouterState } from "redux-first-history";

import { appActions, appReducer } from "@ds/modules/app/redux/slice";
import { authReducer } from "@ds/modules/auth/redux/slice";
import { contentAssetsReducer } from "@ds/modules/content/assets/redux/slice";
import { playlistAssetsReducer } from "@ds/modules/content/playlist-assets/redux/slice";
import { playlistsReducer } from "@ds/modules/content/playlists/redux/slice";
import { contentReducer } from "@ds/modules/content/redux/slice";
import { deploymentsReducer } from "@ds/modules/deployments/redux/slice";
import { deviceSyncsReducer } from "@ds/modules/device-syncs/redux/slice";
import { peripheralsReducer } from "@ds/modules/devices/peripherals/redux/slice";
import { playersReducer } from "@ds/modules/devices/players/redux/slice";
import { devicesReducer } from "@ds/modules/devices/redux/slice";
import { experiencesReducer } from "@ds/modules/experiences/redux/slice";
import { locationsReducer } from "@ds/modules/locations/redux/slice";
import { notificationsReducer } from "@ds/modules/notifications/redux/slice";
import { invitationsReducer } from "@ds/modules/settings/invitations/redux/slice";
import { projectsReducer } from "@ds/modules/settings/projects/redux/slice";
import { settingsReducer } from "@ds/modules/settings/redux/slice";
import { usersActions, usersReducer } from "@ds/modules/settings/users/redux/slice";
import { tableDataReducer } from "@ds/modules/table-data/redux/slice";

export const createRootReducer = (routerReducer: Reducer<RouterState, AnyAction>) => {
  const rootReducerFn = combineReducers({
    app: appReducer,
    auth: authReducer,
    experiences: experiencesReducer,
    devices: combineReducers({
      common: devicesReducer,
      players: playersReducer,
      peripherals: peripheralsReducer,
    }),
    locations: locationsReducer,
    content: combineReducers({
      common: contentReducer,
      playlists: playlistsReducer,
      playlistAssets: playlistAssetsReducer,
      assets: contentAssetsReducer,
    }),
    deployments: deploymentsReducer,
    deviceSyncs: deviceSyncsReducer,
    settings: combineReducers({
      common: settingsReducer,
      projects: projectsReducer,
      users: usersReducer,
      invitations: invitationsReducer,
    }),
    notifications: notificationsReducer,
    tableData: tableDataReducer,
    router: routerReducer,
  });

  return (state: Parameters<typeof rootReducerFn>[0], action: Parameters<typeof rootReducerFn>[1]) => {
    if (appActions.reset.match(action)) {
      return rootReducerFn(undefined, action);
    }

    if (usersActions.updateUserCurrentProjectIdSucceeded.match(action) && state) {
      const initialState = rootReducerFn(undefined, action);
      const nextState = produce(initialState, draft => {
        draft.app = state.app;
        draft.auth = state.auth;
        draft.notifications = state.notifications;
        draft.settings = produce(state.settings, draftSettings => {
          // eslint-disable-next-line no-param-reassign
          draftSettings.projects.dashboardInfo = initialState.settings.projects.dashboardInfo;
        });
      });

      return rootReducerFn(nextState, action);
    }

    return rootReducerFn(state, action);
  };
};
