import { type FC } from "react";
import { Controller, type Control } from "react-hook-form";

import { useDetailsInlineSaving } from "@ds/components/details-view/hooks";
import { InputNumber } from "@ds/components/forms/inputs";
import { LabelWrapper } from "@ds/components/forms/labels";

export const PlaylistAssetDurationControl: FC<{
  control: Control<PlaylistAsset>;
  autoFocus?: boolean;
  excludeLabel?: boolean;
  onBlur?: () => void;
}> = ({ control, autoFocus, excludeLabel, onBlur }) => {
  const onBlurHandler = useDetailsInlineSaving(onBlur);
  return (
    <Controller
      name="duration"
      control={control as Control<PlaylistAsset>}
      render={({ field: { ref, value, onBlur: onBlurFn, ...restFields }, fieldState }) => {
        const element = (
          <InputNumber
            inputRef={ref}
            value={Number(value || 0)}
            min={0}
            placeholder="Enter playlist asset duration"
            autoFocus={autoFocus}
            onBlur={onBlurHandler(onBlurFn)}
            {...restFields}
          />
        );

        if (excludeLabel) {
          return element;
        }

        return (
          <LabelWrapper label="Playlist Asset Duration" className="col-8" error={fieldState.error} isRequired>
            {element}
          </LabelWrapper>
        );
      }}
    />
  );
};
