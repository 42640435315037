import { type FC } from "react";

import { useToggleInfoView } from "@ds/hooks/use-toggle-info-view";

import { Button, ButtonType } from "../forms/buttons";
import { IconType } from "../icons";

export const InfoViewToggleRibbonButton: FC = () => {
  const [, onToggleClick] = useToggleInfoView();
  return (
    <Button
      ariaLabel="toggle info view button"
      buttonType={ButtonType.Round}
      icon={IconType.InfoView}
      onClick={onToggleClick()}
    />
  );
};
