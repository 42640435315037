import { isArray } from "lodash";

import { EntityTypeName, isEntity } from "@ds/model/entity-model";
import { StatusEnum } from "@ds/model/status-model";

import { isPlainObject } from "@ds/utils/type-guards/common-guards";

export enum PeripheralTypeEnum {
  TV = "tv",
  LGDisplay = "lg-display",
  ButtonsPad11 = "buttons_pad_11",
  ButtonsPad4 = "buttons_pad_4",
  Audio = "audio",
  Receiver = "receiver",
  HLS71 = "hls71",
}

export enum PeripheralStatusEnum {
  Plugged = "plugged",
  Unplugged = "unplugged",
  On = "on",
  Off = "off",
  Muted = "muted",
  Unmuted = "unmuted",
  Connected = "connected",
  Disconnected = "disconnected",
  Unknown = "unknown",
}

export const isPeripheral = (obj: unknown): obj is Peripheral =>
  isEntity(obj) && obj.__typename === EntityTypeName.DEVICE_PERIPHERAL;

export const isApiPeripheral = (obj: unknown): obj is ApiPeripheral => isPeripheral(obj) && "device" in obj;

export const isPeripheralArray = (obj: unknown): obj is Peripheral[] => isArray(obj) && obj.every(isPeripheral);

export const isApiPeripheralArray = (obj: unknown): obj is ApiPeripheral[] =>
  isArray(obj) && obj.every(isApiPeripheral);

export const isPeripheralQueryOutput = (obj: unknown): obj is QueryOutput<Peripheral> =>
  isPlainObject(obj) && "items" in obj && isPeripheralQueryOutput(obj.items);

export const isApiPeripheralQueryOutput = (obj: unknown): obj is QueryOutput<ApiPeripheral> =>
  isPlainObject(obj) && "items" in obj && isApiPeripheralQueryOutput(obj.items);

export const getPeripheralPrintableStatus = (state: PeripheralStatus) =>
  ({
    [PeripheralStatusEnum.Plugged]: StatusEnum.Ok,
    [PeripheralStatusEnum.Unplugged]: StatusEnum.Failure,
    [PeripheralStatusEnum.On]: StatusEnum.Ok,
    [PeripheralStatusEnum.Off]: StatusEnum.Warning,
    [PeripheralStatusEnum.Muted]: StatusEnum.Info,
    [PeripheralStatusEnum.Unmuted]: StatusEnum.Info,
    [PeripheralStatusEnum.Connected]: StatusEnum.Ok,
    [PeripheralStatusEnum.Disconnected]: StatusEnum.Failure,
    [PeripheralStatusEnum.Unknown]: StatusEnum.Unknown,
  })[state as PeripheralStatusEnum];
