import { type FC } from "react";

import { createDeploymentModel, DeploymentTypeEnum } from "@ds/modules/deployments/utils/model";

import { Wizard } from "@ds/components/wizard";

import { CreateDeploymentProvider, useCreateDeployment } from "./create-deployment-context";
import { StepFinal } from "./step-final";
import { Step1Type } from "./step1-type";
import { Step2Scope } from "./step2-scope";
import { Step3Upload } from "./step3-upload";
import { Step4Info } from "./step4-info";

const DeploymentWizard: FC<{ onClose: () => void }> = ({ onClose }) => {
  const [currentDeployment] = useCreateDeployment();

  return (
    <Wizard width="40vw" height="80vh" onClose={onClose}>
      <Step1Type />
      <Step2Scope />
      {(currentDeployment.type === DeploymentTypeEnum.Code ||
        currentDeployment.type === DeploymentTypeEnum.Firmware) && <Step3Upload />}

      <Step4Info />
      <StepFinal />
    </Wizard>
  );
};

export const CreateDeploymentModal: FC<{ onClose: () => void }> = ({ onClose }) => {
  const initialValue = createDeploymentModel();

  return (
    <CreateDeploymentProvider initialValue={initialValue}>
      <DeploymentWizard onClose={onClose} />
    </CreateDeploymentProvider>
  );
};
