import pluralize from "pluralize";
import { useCallback, useEffect, useRef, type FC } from "react";
import { useWatch, type Control, type UseFormSetValue } from "react-hook-form";
import styled from "styled-components";

import { getDefaultOpenHours } from "@ds/modules/locations/utils/model";

import { useDetailsInlineDivSaving } from "@ds/components/details-view/hooks";
import {
  Checkbox,
  Dropdown,
  InputSwitch,
  type CheckboxChangeEvent,
  type DropdownChangeEvent,
  type InputSwitchChangeEvent,
} from "@ds/components/forms/inputs";
import { LabelWrapper } from "@ds/components/forms/labels";
import { useOnMouseEventPreventDefaultHandler } from "@ds/utils/handlers";

import { calculateHours, DOW, isOpen24Hours } from "../../../utils/datetime-helpers";

const OpenHoursWrapper = styled.div`
  display: flex;
  flex-flow: column;
  justify-content: center;
  row-gap: 10px;
`;

const OpenHoursRow = styled.div`
  height: 36px;
  display: flex;
  align-items: center;
`;

const OpenHoursDay = styled.span`
  min-width: 110px;
`;

const SwitchWrapper = styled(LabelWrapper)`
  margin-bottom: 0px;

  > label {
    width: 110px;
    margin-right: 10px;
    display: flex;
    flex-flow: row-reverse;
    justify-content: space-around;
    align-items: center;

    > span {
      padding-bottom: 0px;
    }
  }
`;

const DropdownsWrapper = styled.div`
  display: flex;
  flex: 1;
  justify-content: center;
  align-items: center;
`;

const DropdownWrapper = styled(Dropdown)`
  flex: 1;
  flex-basis: 150px;
`;

const OpenHoursTime = styled.div`
  width: 70px;
  text-align: right;
  font-size: 0.95rem;
  color: var(--textColor060);
`;

const CheckboxWrapper = styled(LabelWrapper)`
  margin-left: 5px;
  margin-bottom: 0px;

  > label {
    width: 75px;
    display: flex;
    flex-flow: row-reverse;
    justify-content: space-around;
    align-items: center;

    > span {
      padding-bottom: 0px;
    }
  }
`;

const MIDNIGHT_HOUR = 24;
const OPTIONS = [
  { hour: MIDNIGHT_HOUR, label: "0:00am", value: "0000" },
  { hour: 1, label: "1:00am", value: "0100" },
  { hour: 2, label: "2:00am", value: "0200" },
  { hour: 3, label: "3:00am", value: "0300" },
  { hour: 4, label: "4:00am", value: "0400" },
  { hour: 5, label: "5:00am", value: "0500" },
  { hour: 6, label: "6:00am", value: "0600" },
  { hour: 7, label: "7:00am", value: "0700" },
  { hour: 8, label: "8:00am", value: "0800" },
  { hour: 9, label: "9:00am", value: "0900" },
  { hour: 10, label: "10:00am", value: "1000" },
  { hour: 11, label: "11:00am", value: "1100" },
  { hour: 12, label: "12:00pm", value: "1200" },
  { hour: 13, label: "1:00pm", value: "1300" },
  { hour: 14, label: "2:00pm", value: "1400" },
  { hour: 15, label: "3:00pm", value: "1500" },
  { hour: 16, label: "4:00pm", value: "1600" },
  { hour: 17, label: "5:00pm", value: "1700" },
  { hour: 18, label: "6:00pm", value: "1800" },
  { hour: 19, label: "7:00pm", value: "1900" },
  { hour: 20, label: "8:00pm", value: "2000" },
  { hour: 21, label: "9:00pm", value: "2100" },
  { hour: 22, label: "10:00pm", value: "2200" },
  { hour: 23, label: "11:00pm", value: "2300" },
];

export const LocationOpenHoursControl: FC<{
  control: Control<Locality>;
  setValue: UseFormSetValue<Locality>;
  onBlur?: () => void;
}> = ({ control, setValue, onBlur }) => {
  const wrapperRef = useRef<HTMLDivElement>(null);
  const onBlurHandler = useDetailsInlineDivSaving(onBlur);
  const onMouseDownHandler = useOnMouseEventPreventDefaultHandler();

  let openHours = useWatch({ control, name: "open_hours" });
  openHours = openHours?.length ? openHours : getDefaultOpenHours();

  useEffect(() => wrapperRef?.current?.focus(), []);

  const onChangeHandler = useCallback(
    (i: number) =>
      ({ value }: InputSwitchChangeEvent) => {
        const newOpenHours = [...openHours];
        newOpenHours[i].closed = !value;

        setValue("open_hours", newOpenHours, {
          shouldDirty: true,
          shouldTouch: true,
          shouldValidate: true,
        });
      },
    [openHours, setValue],
  );

  const onDropdownSelectHandler = useCallback(
    (i: number, isCloseHoursSelected: boolean) =>
      ({ value }: DropdownChangeEvent) => {
        const newOpenHours = [...openHours];
        if (isCloseHoursSelected) {
          newOpenHours[i].close = value;
        } else {
          newOpenHours[i].open = value.toString();
        }

        setValue("open_hours", newOpenHours, {
          shouldDirty: true,
          shouldTouch: true,
          shouldValidate: true,
        });
      },
    [openHours, setValue],
  );

  const onCheckboxClickHandler = useCallback(
    (i: number) =>
      ({ checked }: CheckboxChangeEvent) => {
        const newOpenHours = [...openHours];
        if (checked) {
          newOpenHours[i].open = "0000";
          newOpenHours[i].close = "0000";
        } else {
          newOpenHours[i].open = "1000";
          newOpenHours[i].close = "1800";
        }

        setValue("open_hours", newOpenHours, {
          shouldDirty: true,
          shouldTouch: true,
          shouldValidate: true,
        });
      },
    [openHours, setValue],
  );

  const getAvailableOptions = useCallback((openCloseTime: string) => {
    const closeHour = OPTIONS.find(({ value }) => value === openCloseTime)?.hour || MIDNIGHT_HOUR;
    return closeHour === MIDNIGHT_HOUR
      ? OPTIONS
      : OPTIONS.filter(entry => entry.hour === MIDNIGHT_HOUR || entry.hour !== closeHour);
  }, []);

  return (
    <OpenHoursWrapper
      ref={wrapperRef}
      className="col-12"
      tabIndex={0}
      onBlur={onBlurHandler}
      onMouseDown={onMouseDownHandler}
    >
      {DOW.map((day, index) => (
        <OpenHoursRow key={`open-hours-day-${day}`}>
          <OpenHoursDay className="font-medium">{day}</OpenHoursDay>
          <SwitchWrapper label={openHours[index].closed ? "Closed" : "Open"}>
            <InputSwitch
              checked={!openHours[index].closed}
              onChange={onChangeHandler(index)}
              onMouseDown={onMouseDownHandler}
            />
          </SwitchWrapper>

          {openHours[index].closed || (
            <>
              <DropdownsWrapper>
                <DropdownWrapper
                  disabled={isOpen24Hours(openHours[index])}
                  options={getAvailableOptions(openHours[index].close)}
                  value={openHours[index].open}
                  onChange={onDropdownSelectHandler(index, false)}
                  onMouseDown={onMouseDownHandler}
                />

                <span className="ml-1 mr-1">-</span>
                <DropdownWrapper
                  disabled={isOpen24Hours(openHours[index])}
                  options={getAvailableOptions(openHours[index].open)}
                  value={openHours[index].close}
                  onChange={onDropdownSelectHandler(index, true)}
                  onMouseDown={onMouseDownHandler}
                />

                <OpenHoursTime>{pluralize("hours", calculateHours(openHours[index]), true)}</OpenHoursTime>
              </DropdownsWrapper>

              <CheckboxWrapper label="all-day">
                <Checkbox
                  checked={isOpen24Hours(openHours[index])}
                  onChange={onCheckboxClickHandler(index)}
                  onMouseDown={onMouseDownHandler}
                />
              </CheckboxWrapper>
            </>
          )}
        </OpenHoursRow>
      ))}
    </OpenHoursWrapper>
  );
};
