import { EntityTypeName, isEntity } from "@ds/model/entity-model";

export const createThumbnailModel = (options?: Partial<Thumbnail>): Thumbnail => ({
  __typename: EntityTypeName.THUMBNAIL,
  id: options?.id || 0,
  key: options?.key || "",
  asset_id: options?.asset_id || 0,
  file_name: options?.file_name || "",
  file_size: options?.file_size || 0,
  content_type: options?.content_type || "",
  dimensions: undefined,
});

export const isThumbnail = (obj: unknown): obj is Thumbnail =>
  isEntity(obj) && obj.__typename === EntityTypeName.THUMBNAIL;
