import { createSlice, type PayloadAction } from "@reduxjs/toolkit";
import { all } from "redux-saga/effects";

import { LoadStatus, type BaseMeta } from "@ds/utils/reducer";

import { watchAuth } from "./sagas";
import {
  type InvitedUser,
  type UserChangePasswordModel,
  type UserForgotPasswordModel,
  type UserRestorePasswordModel,
  type UserSignInModel,
  type UserSignUpModel,
} from "./types";

export enum AuthRemoteOperation {
  AUTH = "auth",
  REFRESH = "refresh",
  INVITE = "invite",
}

export type AuthMeta = BaseMeta & {
  remoteOperation: AuthRemoteOperation;
};

interface AuthState {
  loadStatusMap: {
    [AuthRemoteOperation.AUTH]: LoadStatus;
    [AuthRemoteOperation.REFRESH]: LoadStatus;
    [AuthRemoteOperation.INVITE]: LoadStatus;
  };

  error: string | null;

  cognitoUser: AuthUser | null;
  invitedUser: InvitedUser | null;
}

export const initialState: AuthState = {
  loadStatusMap: {
    [AuthRemoteOperation.AUTH]: LoadStatus.Idle,
    [AuthRemoteOperation.REFRESH]: LoadStatus.Idle,
    [AuthRemoteOperation.INVITE]: LoadStatus.Idle,
  },

  error: null,

  cognitoUser: null,
  invitedUser: null,
};

const sliceType = "AUTH";
const slice = createSlice({
  name: sliceType,
  initialState,
  reducers: {
    signUp: (state, _action: PayloadAction<UserSignUpModel>) => {
      state.loadStatusMap[AuthRemoteOperation.AUTH] = LoadStatus.Loading;
      state.error = null;
    },
    signUpFailed: {
      reducer(state, { error }: PayloadAction<UserSignUpModel, string, never, string>) {
        state.loadStatusMap[AuthRemoteOperation.AUTH] = LoadStatus.Failed;
        state.error = error;
      },
      prepare(payload: UserSignUpModel, { message }: ErrorLike) {
        return { payload, error: message };
      },
    },
    signUpSucceeded: (state, _action: PayloadAction<UserSignUpModel>) => {
      state.loadStatusMap[AuthRemoteOperation.AUTH] = LoadStatus.Succeeded;
      state.error = null;
    },
    signIn: (state, _action: PayloadAction<UserSignInModel>) => {
      state.loadStatusMap[AuthRemoteOperation.AUTH] = LoadStatus.Loading;
      state.error = null;
    },
    signInFailed: {
      reducer(state, { error }: PayloadAction<undefined, string, never, string>) {
        state.loadStatusMap[AuthRemoteOperation.AUTH] = LoadStatus.Failed;
        state.error = error;

        state.cognitoUser = null;
      },
      prepare({ message }: ErrorLike) {
        return { payload: undefined, error: message };
      },
    },
    signInSucceeded: (state, _action: PayloadAction<UserSignInModel>) => {
      state.loadStatusMap[AuthRemoteOperation.AUTH] = LoadStatus.Succeeded;
      state.error = null;
    },
    fetchInvitationInfo: (state, _action: PayloadAction<string>) => {
      state.loadStatusMap[AuthRemoteOperation.INVITE] = LoadStatus.Loading;
      state.error = null;
    },
    fetchInvitationInfoFailed: {
      reducer(state, { error }: PayloadAction<string, string, never, string>) {
        state.loadStatusMap[AuthRemoteOperation.INVITE] = LoadStatus.Failed;
        state.error = error;
      },
      prepare(payload: string, { message }: ErrorLike) {
        return { payload, error: message };
      },
    },
    fetchInvitedInfoSucceeded: (state, action: PayloadAction<InvitedUser>) => {
      state.loadStatusMap[AuthRemoteOperation.INVITE] = LoadStatus.Succeeded;
      state.error = null;

      state.invitedUser = action.payload;
    },
    forgotPassword: (state, _action: PayloadAction<UserForgotPasswordModel>) => {
      state.loadStatusMap[AuthRemoteOperation.AUTH] = LoadStatus.Loading;
      state.error = null;
    },
    forgotPasswordFailed: {
      reducer(state, { error }: PayloadAction<UserForgotPasswordModel, string, never, string>) {
        state.loadStatusMap[AuthRemoteOperation.AUTH] = LoadStatus.Failed;
        state.error = error;
      },
      prepare(payload: UserForgotPasswordModel, { message }: ErrorLike) {
        return { payload, error: message };
      },
    },
    forgotPasswordSucceeded: (state, _action: PayloadAction<UserForgotPasswordModel>) => {
      state.loadStatusMap[AuthRemoteOperation.AUTH] = LoadStatus.Succeeded;
      state.error = null;
    },
    restorePassword: (state, _action: PayloadAction<UserRestorePasswordModel>) => {
      state.loadStatusMap[AuthRemoteOperation.AUTH] = LoadStatus.Loading;
      state.error = null;
    },
    restorePasswordFailed: {
      reducer(state, { error }: PayloadAction<UserRestorePasswordModel, string, never, string>) {
        state.loadStatusMap[AuthRemoteOperation.AUTH] = LoadStatus.Failed;
        state.error = error;
      },
      prepare(payload: UserRestorePasswordModel, { message }: ErrorLike) {
        return { payload, error: message };
      },
    },
    restorePasswordSucceeded: (state, _action: PayloadAction<UserRestorePasswordModel>) => {
      state.loadStatusMap[AuthRemoteOperation.AUTH] = LoadStatus.Succeeded;
      state.error = null;
    },
    changePassword: (state, _action: PayloadAction<UserChangePasswordModel>) => {
      state.loadStatusMap[AuthRemoteOperation.AUTH] = LoadStatus.Loading;
      state.error = null;
    },
    changePasswordFailed: {
      reducer(state, { error }: PayloadAction<UserChangePasswordModel, string, never, string>) {
        state.loadStatusMap[AuthRemoteOperation.AUTH] = LoadStatus.Failed;
        state.error = error;
      },
      prepare(payload: UserChangePasswordModel, { message }: ErrorLike) {
        return { payload, error: message };
      },
    },
    changePasswordSucceeded: (state, _action: PayloadAction<UserChangePasswordModel>) => {
      state.loadStatusMap[AuthRemoteOperation.AUTH] = LoadStatus.Succeeded;
      state.error = null;
    },
    signOut: state => {
      state.loadStatusMap[AuthRemoteOperation.AUTH] = LoadStatus.Loading;
      state.error = null;
    },
    signOutFailed: {
      reducer(state, { error }: PayloadAction<undefined, string, never, string>) {
        state.loadStatusMap[AuthRemoteOperation.AUTH] = LoadStatus.Failed;
        state.error = error;
      },
      prepare({ message }: ErrorLike) {
        return { payload: undefined, error: message };
      },
    },
    signOutSucceeded: state => {
      state.loadStatusMap[AuthRemoteOperation.AUTH] = LoadStatus.Succeeded;
      state.error = null;
    },
    fetchAuthenticatedCognitoUser: {
      reducer(
        state,
        {
          meta,
        }: PayloadAction<
          | {
              bypassCache?: boolean;
            }
          | undefined,
          string,
          AuthMeta
        >,
      ) {
        state.loadStatusMap[meta.remoteOperation] = LoadStatus.Loading;
        state.error = null;
      },
      prepare(bypassCache = false, remoteOperation = AuthRemoteOperation.AUTH) {
        return { payload: { bypassCache }, meta: { remoteOperation } };
      },
    },
    fetchAuthenticatedCognitoUserFailed: {
      reducer(
        state,
        {
          meta,
          error,
        }: PayloadAction<
          | {
              bypassCache?: boolean;
            }
          | undefined,
          string,
          AuthMeta,
          string
        >,
      ) {
        state.loadStatusMap[meta.remoteOperation] = LoadStatus.Failed;
        state.error = error;

        state.cognitoUser = null;
      },
      prepare(
        payload:
          | {
              bypassCache?: boolean;
            }
          | undefined,
        meta: AuthMeta,
        { message }: ErrorLike,
      ) {
        return { payload, meta, error: message };
      },
    },
    fetchAuthenticatedCognitoUserSucceeded: {
      reducer(state, { payload, meta }: PayloadAction<AuthUser, string, AuthMeta>) {
        state.loadStatusMap[meta.remoteOperation] = LoadStatus.Succeeded;
        state.error = null;

        state.cognitoUser = payload;
      },
      prepare(payload: AuthUser, meta: AuthMeta) {
        return { payload, meta };
      },
    },
  },
});

export const { name: authType, actions: authActions, reducer: authReducer } = slice;

export function* rootAuthSaga() {
  yield all([watchAuth()]);
}
