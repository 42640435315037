import { type FC, type ReactNode } from "react";
import { Navigate, useLocation } from "react-router-dom";

import { useRootSelector } from "@ds/hooks/redux-hooks";

import { selectCognitoUserIsSignedIn } from "@ds/modules/auth/redux/selectors";

import { SIGN_IN_ROUTE } from "@ds/constants/router";

export const RequireAuth: FC<{ redirectTo?: string; children: ReactNode }> = ({
  children,
  redirectTo = SIGN_IN_ROUTE,
}) => {
  const { pathname } = useLocation();
  const isCognitoUserSignedIn = useRootSelector(selectCognitoUserIsSignedIn);

  return isCognitoUserSignedIn ? (
    <>{children}</>
  ) : (
    <Navigate
      to={{
        pathname: redirectTo,
        search: pathname ? `?returnUrl=${pathname}` : "",
      }}
      state={{ returnUrl: pathname }}
    />
  );
};
