import { type FC } from "react";
import { Portal } from "react-portal";
import styled from "styled-components";

import { useGetElemenetById } from "@ds/hooks/use-get-element-by-id";

const TotalSelectedText = styled.p`
  color: var(--textColor060);
  font-weight: 400;

  span {
    font-weight: 700;
  }
`;

export const TotalSelectedLabel: FC<{ selectedCount: number }> = ({ selectedCount }) => {
  const [element] = useGetElemenetById("total-selected");
  return (
    <Portal node={element}>
      <TotalSelectedText>
        <span>{selectedCount}</span>&nbsp;selected
      </TotalSelectedText>
    </Portal>
  );
};
