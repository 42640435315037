import { type FC } from "react";
import { Controller, type Control } from "react-hook-form";
import styled from "styled-components";

import { InputText } from "@ds/components/forms/inputs";
import { renderErrorMessage } from "@ds/components/forms/labels";

import {
  type UserForgotPasswordModel,
  type UserRestorePasswordModel,
  type UserSignInModel,
  type UserSignUpModel,
} from "../../../redux/types";

const FieldWrapper = styled.div`
  margin: 40px 0;
`;

const AuthInputText = styled(InputText)`
  width: 100%;
`;

const ErrorWrapper = styled.div`
  display: inline-block;
  div {
    margin-top: 5px;
  }
`;

export const UsernameControl: FC<{
  control:
    | Control<UserSignInModel>
    | Control<UserSignUpModel>
    | Control<UserForgotPasswordModel>
    | Control<UserRestorePasswordModel>;
  name?: "username" | "email";
  autoComplete?: string;
  isReadOnly?: boolean;
}> = ({ control, name = "username", autoComplete = "username", isReadOnly = false }) => (
  <Controller
    name={name}
    control={control as Control<UserSignInModel | UserSignUpModel | UserForgotPasswordModel | UserRestorePasswordModel>}
    render={({ field: { ref, ...restFields }, fieldState }) => (
      <FieldWrapper>
        <span className="p-float-label">
          <AuthInputText
            id={name}
            inputRef={ref}
            {...restFields}
            autoComplete={autoComplete}
            isReadonly={isReadOnly}
            isRequired
          />

          <label htmlFor={name}>Email</label>
        </span>

        <ErrorWrapper>{renderErrorMessage(fieldState.error)}</ErrorWrapper>
      </FieldWrapper>
    )}
  />
);
