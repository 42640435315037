import { type FC } from "react";
import { Controller, type Control } from "react-hook-form";

import { InputText } from "@ds/components/forms/inputs";
import { LabelWrapper } from "@ds/components/forms/labels";

export const InvitationEmailControl: FC<{
  name?: "email" | "invitation.email";
  control: Control<Invitation> | Control<InvitationFormData>;
  autoFocus?: boolean;
  excludeLabel?: boolean;
}> = ({ control, name = "email", autoFocus, excludeLabel }) => (
  <Controller
    name={name}
    control={control as Control<Invitation | InvitationFormData>}
    render={({ field: { ref, ...restFields }, fieldState }) => {
      const element = (
        <InputText inputRef={ref} placeholder="Enter invitation email" autoFocus={autoFocus} {...restFields} />
      );

      if (excludeLabel) {
        return element;
      }

      return (
        <LabelWrapper label="Email" className="col-8" error={fieldState.error} isRequired>
          {element}
        </LabelWrapper>
      );
    }}
  />
);
