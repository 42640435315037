export const EXPERIENCES_SEGMENT = "experiences";

export const DEVICES_SEGMENT = "devices";
export const DEVICES_PLAYERS_SEGMENT = "players";
export const DEVICES_PERIPHERALS_SEGMENT = "peripherals";

export const LOCATIONS_SEGMENT = "locations";

export const CONTENT_SEGMENT = "content";
export const CONTENT_PLAYLISTS_SEGMENT = "playlists";
export const CONTENT_VIDEOS_SEGMENT = "videos";
export const CONTENT_IMAGES_SEGMENT = "images";
export const CONTENT_AUDIO_SEGMENT = "audio";

export const DEPLOYMENTS_SEGMENT = "deployments";

export const SETTINGS_SEGMENT = "settings";
export const SETTINGS_PROJECTS_SEGMENT = "projects";
export const SETTINGS_USERS_SEGMENT = "users";
export const SETTINGS_INVITATIONS_SEGMENT = "invitations";
