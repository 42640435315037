import { yupResolver } from "@hookform/resolvers/yup";
import { uniq } from "lodash";
import { useCallback, useEffect, type FC } from "react";
import { type DeepPartial } from "react-hook-form";

import { useGetAggregatedEntityIds } from "@ds/hooks/use-get-aggregated-entity-ids";

import { Wrapper } from "@ds/modules/experiences/components/details/experience-details-players-card";
import {
  selectProjectsByIds,
  selectProjectsIsLoading,
} from "@ds/modules/settings/projects/redux/selectors/common-selectors";
import { projectsActions } from "@ds/modules/settings/projects/redux/slice";

import {
  DetailsCard,
  DetailsCardEditActionsMenu,
  DetailsCardRow,
  EditableDetailsCardDecorator,
  EditableDetailsCardRowDecorator,
  type EditableDetailsCardChildProps,
} from "@ds/components/details-view";
import { MultipleValuesLabelWithLink, UnassignedLabel } from "@ds/components/forms/labels/common";
import { Loading } from "@ds/components/loaders/loading";
import { TextLink } from "@ds/components/text-link";
import { useDeepRootSelector, useRootDispatch, useRootSelector } from "@ds/hooks";
import { isMultipleFieldValuesInAggregatedEntity } from "@ds/utils/entities";
import { composeDetailsRouteByEntityType } from "@ds/utils/router";

import { selectCurrentUserIsTenantAdmin, selectUsersByIds } from "../../redux/selectors";
import { updateValidationSchema } from "../../utils/validation-schema";
import { UserProjectsControl } from "../forms/controls/user-projects-control";

export const UserDetailsProjectsCard: FC<{
  user: User;
  onSubmit: (changedFields: DeepPartial<User>) => void;
}> = ({ user, onSubmit }) => {
  const aggregatedUserIds = useGetAggregatedEntityIds(user);
  const [aggregatedUserProjectsIds, aggregatedUserProjects] = useDeepRootSelector(state => {
    const aggregatedUsers = selectUsersByIds(state, aggregatedUserIds);
    const aggregatedProjectsIds = uniq(aggregatedUsers.flatMap(({ project_ids }) => project_ids).filter(_ => _));
    return [aggregatedProjectsIds, selectProjectsByIds(state, aggregatedProjectsIds)] as const;
  });

  const isEditAllowed = useRootSelector(selectCurrentUserIsTenantAdmin);
  const isLoading = useRootSelector(selectProjectsIsLoading);

  const dispatch = useRootDispatch();
  useEffect(() => {
    if (aggregatedUserProjectsIds.length) {
      dispatch(projectsActions.fetchProjects({ id: aggregatedUserProjectsIds }));
    }
  }, [dispatch, aggregatedUserProjectsIds]);

  const onSubmitHandler = ({ projects }: DeepPartial<{ projects: Project[] }>) =>
    onSubmit({ project_ids: projects?.map(project => project?.id).filter(_ => _) });

  const detailsBody = useCallback(
    (cardProps: EditableDetailsCardChildProps<UserFormData>) => {
      if (isMultipleFieldValuesInAggregatedEntity(cardProps.entity.user, "project_ids") && !cardProps.isDirty) {
        return <MultipleValuesLabelWithLink to={composeDetailsRouteByEntityType(aggregatedUserProjects)} />;
      }

      if (
        !isMultipleFieldValuesInAggregatedEntity(cardProps.entity.user, "project_ids") &&
        !cardProps.entity.projects?.length &&
        !cardProps.isEditing
      ) {
        return <UnassignedLabel text="User(s) has no projects" />;
      }

      return (
        <>
          {cardProps.entity.projects?.map(entry => (
            <Wrapper key={`user-details-project-${entry?.id}`}>
              <TextLink to={composeDetailsRouteByEntityType(entry)}>{entry.name}</TextLink>
            </Wrapper>
          ))}
        </>
      );
    },
    [aggregatedUserProjects],
  );

  return (
    <EditableDetailsCardDecorator
      initialValue={{ user, projects: aggregatedUserProjects }}
      validationResolver={yupResolver(updateValidationSchema.projects)}
      onSubmit={onSubmitHandler}
    >
      {cardProps => (
        <DetailsCard
          title="Projects"
          icon="pi pi-list"
          actions={
            <DetailsCardEditActionsMenu
              isEditing={cardProps.isEditing}
              isDirty={cardProps.isDirty}
              isEditHidden={!isEditAllowed}
              onEdit={cardProps.onEditRow("projects")}
              onReset={cardProps.onResetForm}
              onSubmit={cardProps.handleSubmit(cardProps.onSubmitForm)}
            />
          }
        >
          <Loading isLoading={isLoading}>
            <EditableDetailsCardRowDecorator
              field="projects"
              input={<UserProjectsControl control={cardProps.control} autoFocus excludeLabel />}
              shouldEditWholeCard
              shouldHideEdit={!isEditAllowed}
              {...cardProps}
            >
              {rowProps => (
                <DetailsCardRow label="Choose projects" editableSettings={rowProps}>
                  {detailsBody(cardProps)}
                </DetailsCardRow>
              )}
            </EditableDetailsCardRowDecorator>
          </Loading>
        </DetailsCard>
      )}
    </EditableDetailsCardDecorator>
  );
};
