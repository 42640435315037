import { useEffect, type FC } from "react";
import styled from "styled-components";

import { isAggregatedEntity } from "@ds/model/entity-model";
import { StatusEnum } from "@ds/model/status-model";

import { selectDeviceSyncsIsLoading, selectTableDeviceSyncs } from "@ds/modules/device-syncs/redux/selectors";
import { deviceSyncsActions } from "@ds/modules/device-syncs/redux/slice";
import { getPlayerStatusIndicator } from "@ds/modules/devices/players/components/common/status-indicator";
import { selectPlayerById } from "@ds/modules/devices/players/redux/selectors";
import { DEFAULT_TABLE_TAB } from "@ds/modules/table-data/redux/slice";
import { DetailsTableDataTypeEnum } from "@ds/modules/table-data/utils/model";

import { DetailsCard } from "@ds/components/details-view";
import { MultipleValuesLabel, UnassignedLabel } from "@ds/components/forms/labels/common";
import { Loading } from "@ds/components/loaders/loading";
import { ProgressIndicator } from "@ds/components/progress-indicator";
import { DetailsTable } from "@ds/components/table/details-table";
import { Column, getLastUpdatedColumn, getStatusColumn } from "@ds/components/table/table-columns";
import { TextLink } from "@ds/components/text-link";
import { usePagination, useRootDispatch, useRootSelector, useTableDataSorting } from "@ds/hooks";
import { composeDetailsRouteByEntityType } from "@ds/utils/router";
import { getPrintableStatus } from "@ds/utils/status-helpers";

import { getDeviceSyncStatusIndicator } from "../common/status-indicator";

const StatusBodyWrapper = styled.div`
  display: flex;
  align-items: center;
`;

const useGetDeviceNameBodyTemplate = (deviceSync: DeviceSync) => {
  {
    const deviceSyncPlayer = useRootSelector(state => selectPlayerById(state, deviceSync.device_id));
    return deviceSyncPlayer ? (
      <TextLink to={composeDetailsRouteByEntityType(deviceSyncPlayer)}>{deviceSyncPlayer.name}</TextLink>
    ) : (
      <UnassignedLabel />
    );
  }
};

const useGetDeviceStatusBodyTemplate = (deviceSync: DeviceSync) => {
  const deviceSyncPlayer = useRootSelector(state => selectPlayerById(state, deviceSync.device_id));
  return deviceSyncPlayer ? getPlayerStatusIndicator(deviceSyncPlayer) : <UnassignedLabel />;
};

const getStatusBodyTemplate = (deviceSync: DeviceSync) => {
  if (deviceSync.status === StatusEnum.Executing || deviceSync.status === StatusEnum.Initializing) {
    return (
      <StatusBodyWrapper>
        <ProgressIndicator status={deviceSync.status as StatusEnum} />
        <span>{getPrintableStatus(deviceSync.status)}</span>
      </StatusBodyWrapper>
    );
  }

  return getDeviceSyncStatusIndicator(deviceSync);
};

export const DeploymentDetailsSyncsCard: FC<{ deployment: Deployment }> = ({ deployment }) => {
  const deviceSyncs = useRootSelector(selectTableDeviceSyncs);
  const isLoading = useRootSelector(selectDeviceSyncsIsLoading);

  const [pagination, setPagination] = usePagination(
    DetailsTableDataTypeEnum.DeploymentDeviceSyncs,
    isAggregatedEntity(deployment) ? DEFAULT_TABLE_TAB : deployment.id.toString(),
    {
      deploymentId: deployment.id,
    },
  );

  const [{ sortOrder, sortField }, setSorting] = useTableDataSorting(
    DetailsTableDataTypeEnum.DeploymentDeviceSyncs,
    isAggregatedEntity(deployment) ? DEFAULT_TABLE_TAB : deployment.id.toString(),
    {
      deploymentId: deployment.id,
    },
  );

  const dispatch = useRootDispatch();
  useEffect(() => {
    if (deployment.id) {
      dispatch(
        deviceSyncsActions.fetchDeviceSyncs(
          { deploymentId: deployment.id },
          { tableType: DetailsTableDataTypeEnum.DeploymentDeviceSyncs },
        ),
      );
    }
  }, [dispatch, deployment.id]);

  return (
    <DetailsCard title="Syncs" icon="download">
      <Loading isLoading={isLoading}>
        {!isAggregatedEntity(deployment) && (
          <DetailsTable
            value={deviceSyncs}
            loading={isLoading}
            sortField={sortField}
            sortOrder={sortOrder}
            onSort={setSorting}
            pagination={pagination}
            onPageChange={setPagination}
          >
            <Column
              field="device.name"
              header="Player"
              body={useGetDeviceNameBodyTemplate}
              style={{ flexGrow: 3 }}
              resizeable={false}
            />

            <Column
              field="device.status"
              header="Player Status"
              body={useGetDeviceStatusBodyTemplate}
              style={{ flexGrow: 3 }}
              resizeable={false}
            />

            <Column field="message" header="Message" style={{ flexGrow: 3 }} resizeable={false} sortable />

            {getStatusColumn({ header: "Sync Status", body: getStatusBodyTemplate })}
            {getLastUpdatedColumn()}
          </DetailsTable>
        )}

        {isAggregatedEntity(deployment) && <MultipleValuesLabel />}
      </Loading>
    </DetailsCard>
  );
};
