export enum IoTTopicPathEnum {
  Experiences = "experiences",
  DevicePlayers = "devices",
  DevicePeripherals = "peripherals",
  Commands = "commands",
  Locations = "locations",
  Playlists = "playlists",
  PlaylistAssets = "playlist-items",
  ContentAssets = "assets",
  ContentThumbnails = "thumbnails",
  Deployments = "deployments",
  DeviceSyncs = "syncs",
  Projects = "projects",
  Users = "users",
  Invitations = "invitations",
}

export const createIoTError = (msg?: string): IoTError => {
  const err = new Error(msg) as IoTError;
  err.name = "DSIoTError";
  return err;
};
