import { Link, NavLink } from "react-router-dom";
import styled, { css } from "styled-components";

export type { NavLinkProps } from "react-router-dom";

const LinkMixin = css`
  width: fit-content;

  :hover,
  i:hover:before {
    font-weight: 700;
  }
`;

export const TextLink = styled(Link)`
  ${LinkMixin}
`;

export const NavTextLink = styled(NavLink)`
  ${LinkMixin}
`;
