import { yupResolver } from "@hookform/resolvers/yup";
import { useCallback, useEffect, type FC } from "react";
import { useForm } from "react-hook-form";

import { createValidationSchema } from "@ds/modules/locations/utils/validation-schema";

import { WizardPage, type WizardChildStepProps } from "@ds/components/wizard";

import { LocationOpenHoursControl } from "../controls/location-open-hours-control";
import { useCreateLocation } from "./create-location-context";

export const Step2OpenHours: FC<WizardChildStepProps> = ({ nextStep, ...rest }) => {
  const [currentLocation, submitLocation] = useCreateLocation();
  const { control, setValue, getValues, handleSubmit, reset } = useForm<Locality>({
    mode: "all",
    reValidateMode: "onChange",
    defaultValues: currentLocation,
    resolver: yupResolver(createValidationSchema.step2),
  });

  useEffect(() => reset(currentLocation), [currentLocation, reset]);

  const onNextClickHandler = useCallback(() => {
    submitLocation(getValues());
    if (nextStep) {
      nextStep();
    }
  }, [nextStep, submitLocation, getValues]);

  return (
    <WizardPage {...rest} title="Business hours" nextStep={handleSubmit(onNextClickHandler)}>
      <LocationOpenHoursControl control={control} setValue={setValue} />
    </WizardPage>
  );
};
