import { InputTextarea as PrimeInputTextarea } from "primereact/inputtextarea";
import styled from "styled-components";

import { TextInputMixin } from "./styles";

export const InputFieldSet = styled.fieldset`
  all: inherit;
`;

export const InputTextarea = styled(PrimeInputTextarea)<{ err?: Error }>`
  ${TextInputMixin}
  height: 100px;
`;
