import { isArray } from "lodash";

import { EntityTypeName, isEntity } from "@ds/model/entity-model";
import { StatusEnum } from "@ds/model/status-model";

import { isPlainObject } from "@ds/utils/type-guards/common-guards";

export enum ExperienceTableTypeEnum {
  Experiences = "experiences",
}

export const createExperienceModel = (options?: Partial<Experience>): Experience => ({
  __typename: EntityTypeName.EXPERIENCE,
  id: options?.id || 0,
  project_id: options?.project_id || 0,
  location_id: options?.location_id || 0,
  device_ids: options?.device_ids || [],
  name: options?.name || "",
  status: StatusEnum.Ok,
  formation: options?.formation || {
    version: 1,
    properties: {},
  },
});

export const isExperience = (obj: unknown): obj is Experience =>
  isEntity(obj) && obj.__typename === EntityTypeName.EXPERIENCE;

export const isApiExperience = (obj: unknown): obj is ApiExperience =>
  isExperience(obj) && "location" in obj && "devices" in obj;

export const isExperienceArray = (obj: unknown): obj is Experience[] => isArray(obj) && obj.every(isExperience);

export const isApiExperienceArray = (obj: unknown): obj is ApiExperience[] =>
  isArray(obj) && obj.every(isApiExperience);

export const isExperienceQueryOutput = (obj: unknown): obj is QueryOutput<Experience> =>
  isPlainObject(obj) && "items" in obj && isExperienceArray(obj.items);

export const isApiExperienceQueryOutput = (obj: unknown): obj is QueryOutput<ApiExperience> =>
  isPlainObject(obj) && "items" in obj && isApiExperienceArray(obj.items);
