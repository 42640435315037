import { type FC } from "react";
import { Route, Routes } from "react-router-dom";
import styled from "styled-components";

import { NotFoundPage } from "@ds/modules/app/components/not-found-page";
import { selectShowInitialLoader } from "@ds/modules/app/redux/selectors";
import { UserForgotPassword } from "@ds/modules/auth/components/forms/user-forgot-password";
import { UserRestorePassword } from "@ds/modules/auth/components/forms/user-restore-password";
import { UserSignIn } from "@ds/modules/auth/components/forms/user-sign-in";

import { ClimbingBoxLoader } from "@ds/components/loaders/loaders";
import { ConfirmDialog } from "@ds/components/popups/confirmation-dialog";
import { RequireAuth } from "@ds/components/protected-route";
import { NotificationToast } from "@ds/components/utils/notification-toast";
import { FORGOT_PASSWORD_ROUTE, RESTORE_PASSWORD_ROUTE, SIGN_IN_ROUTE, SIGN_UP_ROUTE } from "@ds/constants/router";
import { useRootSelector } from "@ds/hooks";

import { UserSignUp } from "../auth/components/forms/user-sign-up";
import { GlobalContextProvider } from "./components/global-context";
import { INITIAL_LOADER_MESSAGE } from "./constants";
import { MainRouter } from "./main-router";

const AppWrapper = styled.div`
  overflow: hidden;
`;

export const App: FC = () => {
  const showInitialLoader = useRootSelector(selectShowInitialLoader);
  if (showInitialLoader) {
    return <ClimbingBoxLoader title={INITIAL_LOADER_MESSAGE} />;
  }

  return (
    <AppWrapper>
      <GlobalContextProvider>
        <Routes>
          <Route path={`${SIGN_UP_ROUTE}/*`} element={<UserSignUp />} />
          <Route path={`${SIGN_IN_ROUTE}/*`} element={<UserSignIn />} />
          <Route path={`${FORGOT_PASSWORD_ROUTE}/*`} element={<UserForgotPassword />} />
          <Route path={`${RESTORE_PASSWORD_ROUTE}/*`} element={<UserRestorePassword />} />

          <Route
            path="*"
            element={
              <RequireAuth>
                <MainRouter />
              </RequireAuth>
            }
          />

          <Route path="*" element={<NotFoundPage />} />
        </Routes>
        <NotificationToast />
        <ConfirmDialog />
        <div id="app-modals" />
      </GlobalContextProvider>
    </AppWrapper>
  );
};
