import { type FC } from "react";
import styled from "styled-components";

import { Button, ButtonType } from "../forms/buttons";
import { IconType } from "../icons";
import { InfoContentSeparator } from "./info-content-separator";
import { InfoView, type InfoViewProps } from "./info-view";

const CallToActionTextWrapper = styled.div`
  margin: 0 auto;
  width: 95%;
  padding: 25px 0;
  color: var(--textColor080);
`;

export const InfoViewCallToAction: FC<
  InfoViewProps & {
    callToActionText: string;
    buttonLabel?: string;
    buttonIcon?: IconType;
    onActionButtonClick?: () => void;
  }
> = ({ infoTitle, callToActionText, buttonLabel = "", buttonIcon, onActionButtonClick }) => (
  <InfoView infoTitle={infoTitle}>
    <InfoContentSeparator />
    <CallToActionTextWrapper>{callToActionText}</CallToActionTextWrapper>
    {buttonIcon && buttonLabel && onActionButtonClick && (
      <Button
        label={buttonLabel}
        ariaLabel={`${buttonLabel.toLowerCase()} button`}
        buttonType={ButtonType.Outlined}
        icon={buttonIcon}
        className="w-8 align-self-center"
        onClick={onActionButtonClick}
      />
    )}
  </InfoView>
);
