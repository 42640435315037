import { useCallback, type FC } from "react";
import { useNavigate } from "react-router-dom";

import { useReduxEntitiesFetch } from "@ds/hooks/use-redux-entities-fetch";

import { EntityTypeName } from "@ds/model/entity-model";

import { selectCurrentUserIsTenantAdmin } from "@ds/modules/settings/users/redux/selectors";
import { MainTableDataTypeEnum } from "@ds/modules/table-data/utils/model";

import { ActionsBar } from "@ds/components/actions-bar";
import { Button, ButtonType } from "@ds/components/forms/buttons";
import { IconType } from "@ds/components/icons";
import { InfoViewToggleRibbonButton } from "@ds/components/info-view/info-view-toggle-ribbon-button";
import { MenuWithButton } from "@ds/components/menu-dropdown/menu-with-button";
import { confirmSuccess } from "@ds/components/popups/confirmation-dialog";
import { useDeepRootSelector, useRootDispatch, useRootSelector } from "@ds/hooks";
import { isMultipleFieldValuesInAggregatedEntity } from "@ds/utils/entities";
import { pluralizeActionMessage } from "@ds/utils/localization/common";
import { composeDetailsRouteByEntityType } from "@ds/utils/router";

import {
  selectAggregatedProjectForSelectedEntities,
  selectSelectedProjectIds,
} from "../../redux/selectors/common-selectors";
import { projectsActions } from "../../redux/slice";

export const ProjectsTableActionsMenu: FC = () => {
  const navigate = useNavigate();

  const aggregatedProject = useDeepRootSelector(selectAggregatedProjectForSelectedEntities);
  const selectedProjectIds = useRootSelector(selectSelectedProjectIds);
  const isEditAllowed = useRootSelector(selectCurrentUserIsTenantAdmin);

  const [toggleRefetch] = useReduxEntitiesFetch(
    { entityType: EntityTypeName.SETTINGS_PROJECT },
    { tableType: MainTableDataTypeEnum.Projects },
  );

  const dispatch = useRootDispatch();
  const onToggleActiveClickHandler = useCallback(() => {
    confirmSuccess({
      message: pluralizeActionMessage(
        "project",
        selectedProjectIds.length,
        aggregatedProject?.is_active ? "deactivate" : "activate",
      ),
      accept: () =>
        dispatch(projectsActions.updateProjects(selectedProjectIds, { is_active: !aggregatedProject?.is_active })),
    });
  }, [dispatch, aggregatedProject?.is_active, selectedProjectIds]);

  const onUpdateClickHandler = useCallback(
    () => navigate(composeDetailsRouteByEntityType(aggregatedProject)),
    [navigate, aggregatedProject],
  );

  return (
    <ActionsBar>
      <MenuWithButton
        menuItems={[
          {
            label: aggregatedProject?.is_active ? "Deactivate" : "Activate",
            icon: `pi pi-fw ${aggregatedProject?.is_active ? "pi-lock" : "pi-lock-open"}`,
            command: onToggleActiveClickHandler,
            disabled:
              !isEditAllowed ||
              !aggregatedProject ||
              isMultipleFieldValuesInAggregatedEntity(aggregatedProject, "is_active"),
          },
        ]}
        isDisabled={!selectedProjectIds.length}
      />

      <Button
        ariaLabel="edit button"
        buttonType={ButtonType.Round}
        icon={IconType.Update}
        isDisabled={!selectedProjectIds.length}
        onClick={onUpdateClickHandler}
      />

      <Button
        ariaLabel="refresh button"
        buttonType={ButtonType.Round}
        icon={IconType.Refresh}
        onClick={toggleRefetch}
      />

      <InfoViewToggleRibbonButton />
    </ActionsBar>
  );
};
