import { deepKeys } from "dot-prop";
import { useCallback } from "react";
import {
  type DeepMap,
  type DeepPartial,
  type FieldErrors,
  type FieldValues,
  type Path,
  type SubmitErrorHandler,
  type SubmitHandler,
  type UseFormGetValues,
  type UseFormHandleSubmit,
  type UseFormTrigger,
} from "react-hook-form";

import { getValuesByBooleanMap, normalizeChangedFieldsBeforeUpdate } from "@ds/utils/forms";

export type OnDeepPartialSubmitType = <T extends FieldValues>(entity: DeepPartial<T>) => void;

export const useGetHandleSubmit = <T extends FieldValues>({
  getValues,
  dirtyFields,
  trigger,
  errors,
}: {
  getValues: UseFormGetValues<T>;
  dirtyFields: Partial<DeepMap<DeepPartial<T>, boolean>> | boolean;
  trigger: UseFormTrigger<T>;
  errors: FieldErrors<T>;
}) => {
  const handleSubmit: UseFormHandleSubmit<T> = useCallback(
    (onValid: SubmitHandler<T>, onInvalid?: SubmitErrorHandler<T>) => {
      return async (e?: React.BaseSyntheticEvent) => {
        const data = getValues();
        const dirtyValues = getValuesByBooleanMap(dirtyFields, data);
        const isValid = await trigger(deepKeys(dirtyValues) as Path<T>[]);

        if (isValid) {
          onValid(data, e);
        } else if (onInvalid) {
          onInvalid(errors, e);
        }
      };
    },
    [dirtyFields, errors, getValues, trigger],
  );

  return handleSubmit;
};

export const useGetChangedFieldsOnSubmitHandlerDecorator = <T extends FieldValues>({
  dirtyFields,
  onSubmit,
}: {
  dirtyFields: Partial<DeepMap<DeepPartial<T>, boolean>> | boolean;
  onSubmit: OnDeepPartialSubmitType;
}) => {
  const onSubmitFormClickHandler = useCallback(
    (data: T) => onSubmit(normalizeChangedFieldsBeforeUpdate(getValuesByBooleanMap(dirtyFields, data))),
    [dirtyFields, onSubmit],
  );

  return onSubmitFormClickHandler;
};
