import { isPlayer } from "@ds/modules/devices/players/utils/model";

export const normalizeBase = <T extends Entity>(entity: T) =>
  ({
    tenant_id: undefined,
    tenant: undefined,
    ...entity,
  }) as T;

export const getEntityTypeName = (entity: Entity) => {
  if (isPlayer(entity)) {
    return "player";
  }

  return entity.__typename;
};
