import { useCallback, type FC } from "react";
import styled from "styled-components";

import { WizardNavDot } from "./wizard-nav-dot";
import { type WizardChildStepProps } from "./wizard-step";

const WizardNavWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 15px;
`;

export const WizardNav: FC<
  WizardChildStepProps & {
    isValid: boolean;
  }
> = ({ isValid, currentStep = 0, totalSteps, goToStep }) => {
  const isFinalValidStep = currentStep === totalSteps && isValid;
  const goToStepHandler = useCallback(
    (i: number) => () => {
      if (!isFinalValidStep && goToStep) {
        goToStep(i);
      }
    },
    [isFinalValidStep, goToStep],
  );

  return (
    <WizardNavWrapper>
      {[...Array(totalSteps).keys()].map((_, i) => (
        <WizardNavDot
          key={`wizard-step-${i + 1}`}
          isEnabled={i < currentStep && !isFinalValidStep}
          isActive={currentStep === i + 1}
          onClick={goToStepHandler(i + 1)}
        />
      ))}
    </WizardNavWrapper>
  );
};
