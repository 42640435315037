import { type FC } from "react";

import { Wizard } from "@ds/components/wizard";

import { CreatePlayerProvider } from "./create-player-context";
import { StepFinal } from "./step-final";
import { Step1Main } from "./step1-main";
import { Step2Experience } from "./step2-experience";
import { Step3Pairing } from "./step3-pairing";

export const CreatePlayerModal: FC<{ onClose: () => void }> = ({ onClose }) => (
  <CreatePlayerProvider>
    <Wizard width="40vw" height="80vh" onClose={onClose}>
      <Step1Main />
      <Step2Experience />
      <Step3Pairing />
      <StepFinal successMessage="Player added successfully!" />
    </Wizard>
  </CreatePlayerProvider>
);
