import { type DeepPartial } from "react-hook-form";

import { DSApi } from "@ds/services/ds-api";
import { type ApiService, type ApiServiceConfig } from "@ds/services/types";
import { parseQueries } from "@ds/utils/query";

interface PlayerServiceConfig extends ApiServiceConfig {
  api: DSApi;
}

export class PlayersService implements ApiService {
  private config: PlayerServiceConfig = {
    api: {} as DSApi,
  };

  configure(cfg: PlayerServiceConfig) {
    this.config = { ...cfg };
  }

  async getPlayers(queryInfo: QueryInfo, pagination: Pagination) {
    const queries = parseQueries(queryInfo, pagination);
    return this.config.api.get<QueryOutput<Player>>("/devices/", queries);
  }

  async getPlayer(playerId: number) {
    return this.config.api.get<Player>(`/devices/${playerId}`);
  }

  async createPlayer(data: PlayerAndPairingCode) {
    return this.config.api.post<Player>("/devices/", { device: data.player, pairing_code: data.pairingCode });
  }

  async updatePlayers(ids: number[], data: DeepPartial<Player>) {
    return this.config.api.patch<Player>("/devices/", { ids, data });
  }

  async deletePlayers(ids: number[]) {
    return this.config.api.delete("/devices/", { ids });
  }

  async pairPlayer(playerId: number, data: PairPlayer) {
    return this.config.api.put<Player>(`/devices/${playerId}/pair`, data);
  }

  async sendCommand(command: SendCommand) {
    return this.config.api.post<Command>("/commands/", command);
  }

  async sendCommands(commands: SendCommand[]) {
    return this.config.api.post<Command[]>("/commands/batch", commands);
  }
}

export const playersService = new PlayersService();
