import * as yup from "yup";
import { Schema, type AnyObject, type AnySchema } from "yup";

import {
  isMaxFormationPropertyValidationRule,
  isMinFormationPropertyValidationRule,
  isRequiredFormationPropertyValidationRule,
  isTypeFormationPropertyValidationRule,
} from "@ds/modules/settings/projects/utils/formation-rule-guards";

export type Shape<T> = Partial<Record<keyof T, AnySchema>>;

export type FormationPropertyValidationData = {
  field: string;
  validationRules: FormationPropertyValidationRule[];
};

enum SchemaTypes {
  Boolean = "boolean",
  Number = "number",
  String = "string",
}

type ValidatorSchemaType =
  | yup.BooleanSchema<boolean | null | undefined, AnyObject, boolean | null | undefined>
  | yup.NumberSchema<number | null | undefined, AnyObject, number | null | undefined>
  | yup.StringSchema<string | null | undefined, AnyObject, string | null | undefined>;

const isMinMaxSupportedSchema = (
  validator: ValidatorSchemaType,
): validator is
  | yup.NumberSchema<number | null | undefined, AnyObject, number | null | undefined>
  | yup.StringSchema<string | null | undefined, AnyObject, string | null | undefined> =>
  validator._type === SchemaTypes.Number || validator._type === SchemaTypes.String;

const createYupSchema = (
  acc: Record<string, Schema>,
  curr: FormationPropertyValidationData,
  skipRequiredValidation = false,
) => {
  const typeRule = curr.validationRules.filter(isTypeFormationPropertyValidationRule)[0];
  if (!typeRule) {
    return acc;
  }

  let validator: ValidatorSchemaType = yup.string().typeError(`Must be a ${SchemaTypes.String}`).nullable();
  if (typeRule.value === SchemaTypes.Boolean) {
    validator = yup.boolean().typeError(`Must be a ${SchemaTypes.Boolean}`).nullable();
  }

  if (typeRule.value === SchemaTypes.Number) {
    validator = yup.number().typeError(`Must be a ${SchemaTypes.Number}`).nullable();
  }

  curr.validationRules
    .flatMap(rule => (rule.type === "type" ? [] : [rule]))
    .forEach(rule => {
      if (isRequiredFormationPropertyValidationRule(rule) && rule.value === true && !skipRequiredValidation) {
        validator = validator.required(rule?.error);
      }

      if (isMinMaxSupportedSchema(validator)) {
        if (isMinFormationPropertyValidationRule(rule)) {
          validator = validator.min(rule.value, rule?.error);
        }

        if (isMaxFormationPropertyValidationRule(rule)) {
          validator = validator.max(rule.value, rule?.error);
        }
      }
    });

  acc[curr.field] = yup.object().shape({ value: validator });
  return acc;
};

export const getFormationPropertiesValidationSchema = (
  validationData: FormationPropertyValidationData[],
  skipRequiredValidation = false,
) =>
  yup.object().shape({
    properties: yup
      .object()
      .shape(
        validationData.reduce(
          (acc: Record<string, Schema>, curr: FormationPropertyValidationData) =>
            createYupSchema(acc, curr, skipRequiredValidation),
          {},
        ),
      ),
  });

export const isFormationPropertyRequired = (validationRules: FormationPropertyValidationRule[] | undefined) =>
  (validationRules || []).filter(isRequiredFormationPropertyValidationRule)[0]?.value || false;
