import { useCallback, type FC } from "react";
import { useNavigate } from "react-router-dom";

import { useReduxEntitiesFetch } from "@ds/hooks/use-redux-entities-fetch";

import { EntityTypeName } from "@ds/model/entity-model";

import { MainTableDataTypeEnum } from "@ds/modules/table-data/utils/model";

import { ActionsBar } from "@ds/components/actions-bar";
import { Button, ButtonType } from "@ds/components/forms/buttons";
import { IconType } from "@ds/components/icons";
import { InfoViewToggleRibbonButton } from "@ds/components/info-view/info-view-toggle-ribbon-button";
import { useDeepRootSelector, useRootSelector } from "@ds/hooks";
import { composeDetailsRouteByEntityType } from "@ds/utils/router";

import { selectAggregatedPeripheralForSelectedEntities, selectSelectedPeripheralIds } from "../../redux/selectors";

export const PeripheralsTableActionsMenu: FC = () => {
  const navigate = useNavigate();

  const aggregatedPeripheral = useDeepRootSelector(selectAggregatedPeripheralForSelectedEntities);
  const selectedPeripheralIds = useRootSelector(selectSelectedPeripheralIds);

  const [toggleRefetch] = useReduxEntitiesFetch(
    { entityType: EntityTypeName.DEVICE_PERIPHERAL },
    { tableType: MainTableDataTypeEnum.DevicePeripherals },
  );

  const onUpdateClickHandler = useCallback(
    () => navigate(composeDetailsRouteByEntityType(aggregatedPeripheral)),
    [navigate, aggregatedPeripheral],
  );

  return (
    <ActionsBar>
      <Button
        ariaLabel="edit button"
        buttonType={ButtonType.Round}
        icon={IconType.Update}
        isDisabled={!selectedPeripheralIds.length}
        onClick={onUpdateClickHandler}
      />

      <Button
        ariaLabel="refresh button"
        buttonType={ButtonType.Round}
        icon={IconType.Refresh}
        onClick={toggleRefetch}
      />

      <InfoViewToggleRibbonButton />
    </ActionsBar>
  );
};
