import { createEntityAdapter, createSlice, type PayloadAction } from "@reduxjs/toolkit";
import { DeepPartial } from "react-hook-form";
import { all } from "redux-saga/effects";

import { deploymentsActions } from "@ds/modules/deployments/redux/slice";
import { DetailsTableDataTypeEnum, MainTableDataTypeEnum } from "@ds/modules/table-data/utils/model";

import { type ProgressInfo } from "@ds/services/storage";
import { getIds } from "@ds/utils/entities";
import { LoadStatus, PatchedModel, type BaseMeta } from "@ds/utils/reducer";
import { type SagaOptions } from "@ds/utils/saga-helpers";

import { getContentAssetFileNameFromProgressInfo } from "../../assets/utils/helpers";
import { isPlaylistAsset } from "../utils/model";
import { apiToModelNormalize, normalize } from "../utils/normalizer";
import { watchPlaylistAssets } from "./sagas";

export enum PlaylistAssetsRemoteOperation {
  CRUD = "crud",
  CHECKBOXES = "checkboxes",
  IOT_FETCH = "iot_fetch",
}

export type PlaylistAssetsMeta = BaseMeta & {
  remoteOperation: PlaylistAssetsRemoteOperation;
  filters?: KeysToCamelCase<ContentPlaylistAssetFilters>;
};

export type PlaylistAssetUploadsMap = {
  [fileName: string]: {
    loadStatus: LoadStatus;
    error: string | null;

    progressInfo: ProgressInfo | null;
  };
};

interface PlaylistAssetsState {
  loadStatusMap: {
    [PlaylistAssetsRemoteOperation.CRUD]: LoadStatus;
    [PlaylistAssetsRemoteOperation.CHECKBOXES]: LoadStatus;
    [PlaylistAssetsRemoteOperation.IOT_FETCH]: LoadStatus;
  };

  error: string | null;

  entities: {
    [playlistAssetId: number]: PlaylistAsset;
  };

  ids: number[];
  tableIds: Record<string, number[]>;
  selectedIds: Record<string, number[]>;

  isUploadModalMinimized: boolean;
  uploadsByFilename: PlaylistAssetUploadsMap;
}

const entityAdapter = createEntityAdapter<PlaylistAsset>();

export const initialState: PlaylistAssetsState = entityAdapter.getInitialState({
  loadStatusMap: {
    [PlaylistAssetsRemoteOperation.CRUD]: LoadStatus.Idle,
    [PlaylistAssetsRemoteOperation.CHECKBOXES]: LoadStatus.Idle,
    [PlaylistAssetsRemoteOperation.IOT_FETCH]: LoadStatus.Idle,
  },

  error: null,

  tableIds: { [DetailsTableDataTypeEnum.ContentPlaylistAssets]: [] },
  selectedIds: { [DetailsTableDataTypeEnum.ContentPlaylistAssets]: [] },

  isUploadModalMinimized: false,
  uploadsByFilename: {},
}) as PlaylistAssetsState;

const getTableTypeOrDefault = (
  tableType: MainTableDataTypeEnum | DetailsTableDataTypeEnum = DetailsTableDataTypeEnum.ContentPlaylistAssets,
) => tableType;

const removeEntities = (state: PlaylistAssetsState, payload: number[]) => {
  entityAdapter.removeMany(state, payload);

  state.tableIds[DetailsTableDataTypeEnum.ContentPlaylistAssets] = state.tableIds[
    DetailsTableDataTypeEnum.ContentPlaylistAssets
  ].filter(id => !payload.includes(id));

  state.selectedIds[DetailsTableDataTypeEnum.ContentPlaylistAssets] = state.selectedIds[
    DetailsTableDataTypeEnum.ContentPlaylistAssets
  ].filter(id => !payload.includes(id));
};

const sliceType = "CONTENT_PLAYLIST_ASSETS";
const slice = createSlice({
  name: sliceType,
  initialState,
  reducers: {
    fetchPlaylistAssets: {
      reducer(state, { meta }: PayloadAction<undefined, string, PlaylistAssetsMeta>) {
        state.loadStatusMap[meta.remoteOperation] = LoadStatus.Loading;
        state.error = null;

        if (meta.remoteOperation === PlaylistAssetsRemoteOperation.CHECKBOXES) {
          state.selectedIds[getTableTypeOrDefault(meta.options?.tableType)] =
            state.tableIds[getTableTypeOrDefault(meta.options?.tableType)];
        }
      },
      prepare(
        filters: KeysToCamelCase<ContentPlaylistAssetFilters>,
        options: SagaOptions = {},
        remoteOperation: PlaylistAssetsRemoteOperation = PlaylistAssetsRemoteOperation.CRUD,
      ) {
        return { payload: undefined, meta: { remoteOperation, filters, options } };
      },
    },
    fetchPlaylistAssetsFailed: {
      reducer(state, { meta, error }: PayloadAction<undefined, string, PlaylistAssetsMeta, string>) {
        state.loadStatusMap[meta.remoteOperation] = LoadStatus.Failed;
        state.error = error;

        if (meta.remoteOperation === PlaylistAssetsRemoteOperation.CHECKBOXES) {
          state.selectedIds[getTableTypeOrDefault(meta.options?.tableType)] = [];
        }
      },
      prepare(meta: PlaylistAssetsMeta, { message }: ErrorLike) {
        return { payload: undefined, meta, error: message };
      },
    },
    fetchPlaylistAssetsSucceeded: {
      reducer(
        state,
        {
          payload,
          meta,
        }: PayloadAction<QueryOutput<PlaylistAsset> | QueryOutput<ApiPlaylistAsset>, string, PlaylistAssetsMeta>,
      ) {
        state.loadStatusMap[meta.remoteOperation] = LoadStatus.Succeeded;
        state.error = null;

        entityAdapter.setMany(state, apiToModelNormalize(payload.items));

        if (meta.remoteOperation === PlaylistAssetsRemoteOperation.CHECKBOXES) {
          state.selectedIds[getTableTypeOrDefault(meta.options?.tableType)] = payload.items.map(({ id }) => id);
        } else if (meta.options?.tableType) {
          state.tableIds[getTableTypeOrDefault(meta.options?.tableType)] = payload.items.map(({ id }) => id);
          state.selectedIds[getTableTypeOrDefault(meta.options?.tableType)] = state.selectedIds[
            getTableTypeOrDefault(meta.options?.tableType)
          ].filter(id => state.ids.includes(id));
        }
      },
      prepare(payload: QueryOutput<PlaylistAsset> | QueryOutput<ApiPlaylistAsset>, meta: PlaylistAssetsMeta) {
        return { payload, meta };
      },
    },
    createPlaylistAsset: {
      reducer(state, { meta }: PayloadAction<PlaylistAsset, string, PlaylistAssetsMeta>) {
        state.loadStatusMap[meta.remoteOperation] = LoadStatus.Loading;
        state.error = null;
      },
      prepare(
        payload: PlaylistAsset,
        filters: KeysToCamelCase<ContentPlaylistAssetFilters>,
        shouldDisableSagaThrottling = false,
      ) {
        return {
          payload,
          meta: {
            remoteOperation: PlaylistAssetsRemoteOperation.CRUD,
            filters,
            options: { shouldDisableSagaThrottling },
          },
        };
      },
    },
    createPlaylistAssetFailed: {
      reducer(state, { meta, error }: PayloadAction<PlaylistAsset, string, PlaylistAssetsMeta, string>) {
        state.loadStatusMap[meta.remoteOperation] = LoadStatus.Failed;
        state.error = error;
      },
      prepare(payload: PlaylistAsset, meta: PlaylistAssetsMeta, { message }: ErrorLike) {
        return { payload, meta, error: message };
      },
    },
    createPlaylistAssetSucceeded: {
      reducer(state, { payload, meta }: PayloadAction<ApiPlaylistAsset, string, PlaylistAssetsMeta>) {
        state.loadStatusMap[meta.remoteOperation] = LoadStatus.Succeeded;
        state.error = null;

        entityAdapter.setOne(state, apiToModelNormalize(payload));
      },
      prepare(payload: ApiPlaylistAsset, meta: PlaylistAssetsMeta) {
        return { payload, meta };
      },
    },
    updatePlaylistAssets: {
      reducer(state, _action: PayloadAction<PatchedModel<PlaylistAsset>>) {
        state.loadStatusMap[PlaylistAssetsRemoteOperation.CRUD] = LoadStatus.Loading;
        state.error = null;
      },
      prepare(changedIds: number[], changedFields: DeepPartial<PlaylistAsset>) {
        return { payload: { ids: changedIds, data: changedFields } };
      },
    },
    updatePlaylistAssetsFailed: {
      reducer(state, { error }: PayloadAction<PatchedModel<PlaylistAsset>, string, never, string>) {
        state.loadStatusMap[PlaylistAssetsRemoteOperation.CRUD] = LoadStatus.Failed;
        state.error = error;
      },
      prepare(payload: PatchedModel<PlaylistAsset>, { message }: ErrorLike) {
        return { payload, error: message };
      },
    },
    updatePlaylistAssetsSucceeded: (state, { payload }: PayloadAction<ApiPlaylistAsset[]>) => {
      state.loadStatusMap[PlaylistAssetsRemoteOperation.CRUD] = LoadStatus.Succeeded;
      state.error = null;

      entityAdapter.setMany(state, apiToModelNormalize(payload));
    },
    deletePlaylistAssets: {
      reducer(state, { meta }: PayloadAction<number[], string, PlaylistAssetsMeta>) {
        state.loadStatusMap[meta.remoteOperation] = LoadStatus.Loading;
        state.error = null;
      },
      prepare(idsToDelete: number[], filters: KeysToCamelCase<ContentPlaylistAssetFilters>, redirectTo?: string) {
        return {
          payload: idsToDelete,
          meta: { remoteOperation: PlaylistAssetsRemoteOperation.CRUD, filters, options: { redirectTo } },
        };
      },
    },
    deletePlaylistAssetsFailed: {
      reducer(state, { meta, error }: PayloadAction<number[], string, PlaylistAssetsMeta, string>) {
        state.loadStatusMap[meta.remoteOperation] = LoadStatus.Failed;
        state.error = error;
      },
      prepare(idsToDelete: number[], meta: PlaylistAssetsMeta, { message }: ErrorLike) {
        return { payload: idsToDelete, meta, error: message };
      },
    },
    deletePlaylistAssetsSucceeded: {
      reducer(state, { payload, meta }: PayloadAction<number[], string, PlaylistAssetsMeta>) {
        state.loadStatusMap[meta.remoteOperation] = LoadStatus.Succeeded;
        state.error = null;

        removeEntities(state, payload);
      },
      prepare(idsToDelete: number[], meta: PlaylistAssetsMeta) {
        return { payload: idsToDelete, meta };
      },
    },
    selectPlaylistAssets: {
      reducer(state, { payload, meta }: PayloadAction<number[], string, PlaylistAssetsMeta>) {
        state.loadStatusMap[meta.remoteOperation] = LoadStatus.Succeeded;

        state.selectedIds[getTableTypeOrDefault(meta.options?.tableType)] = payload;
      },
      prepare(playlistAssetsOrIds: PlaylistAsset[] | ApiPlaylistAsset[] | number[], options: SagaOptions = {}) {
        return {
          payload: getIds(playlistAssetsOrIds),
          meta: { remoteOperation: PlaylistAssetsRemoteOperation.CHECKBOXES, options },
        };
      },
    },
    toggleSelected: {
      reducer(state, { payload, meta }: PayloadAction<number, string, PlaylistAssetsMeta>) {
        state.loadStatusMap[meta.remoteOperation] = LoadStatus.Succeeded;

        state.selectedIds[getTableTypeOrDefault(meta.options?.tableType)] = state.selectedIds[
          getTableTypeOrDefault(meta.options?.tableType)
        ].includes(payload)
          ? state.selectedIds[getTableTypeOrDefault(meta.options?.tableType)].filter(id => id !== payload)
          : [...state.selectedIds[getTableTypeOrDefault(meta.options?.tableType)], payload];
      },
      prepare(playlistAssetId: number, options: SagaOptions = {}) {
        return {
          payload: playlistAssetId,
          meta: { remoteOperation: PlaylistAssetsRemoteOperation.CHECKBOXES, options },
        };
      },
    },
    reorderPlaylistAssets: {
      reducer(state, { meta }: PayloadAction<{ currIndex: number; newIndex: number }, string, PlaylistAssetsMeta>) {
        state.loadStatusMap[meta.remoteOperation] = LoadStatus.Loading;
        state.error = null;
      },
      prepare(payload: { currIndex: number; newIndex: number }, filters: KeysToCamelCase<ContentPlaylistAssetFilters>) {
        return { payload, meta: { remoteOperation: PlaylistAssetsRemoteOperation.CRUD, filters } };
      },
    },
    reorderPlaylistAssetsFailed: {
      reducer(
        state,
        { meta, error }: PayloadAction<{ currIndex: number; newIndex: number }, string, PlaylistAssetsMeta, string>,
      ) {
        state.loadStatusMap[meta.remoteOperation] = LoadStatus.Failed;
        state.error = error;
      },
      prepare(payload: { currIndex: number; newIndex: number }, meta: PlaylistAssetsMeta, { message }: ErrorLike) {
        return { payload, meta, error: message };
      },
    },
    reorderPlaylistAssetsSucceeded: {
      reducer(state, { meta }: PayloadAction<{ currIndex: number; newIndex: number }, string, PlaylistAssetsMeta>) {
        state.loadStatusMap[meta.remoteOperation] = LoadStatus.Succeeded;
        state.error = null;
      },
      prepare(payload: { currIndex: number; newIndex: number }, meta: PlaylistAssetsMeta) {
        return { payload, meta };
      },
    },
    uploadPlaylistAssets: {
      reducer(state, { payload, meta }: PayloadAction<File[], string, PlaylistAssetsMeta>) {
        state.loadStatusMap[meta.remoteOperation] = LoadStatus.Loading;
        const allFinished = Object.keys(state.uploadsByFilename).every(
          fileName => state.uploadsByFilename[fileName].loadStatus !== LoadStatus.Loading,
        );

        if (allFinished) {
          state.uploadsByFilename = {};
        }

        payload.forEach(({ name }) => {
          state.uploadsByFilename[name] = { loadStatus: LoadStatus.Loading, error: null, progressInfo: null };
        });
      },
      prepare(payload: File[], filters: KeysToCamelCase<ContentPlaylistAssetFilters>) {
        return { payload, meta: { remoteOperation: PlaylistAssetsRemoteOperation.CRUD, filters } };
      },
    },
    uploadPlaylistAsset: {
      reducer(state, { payload, meta }: PayloadAction<File, string, PlaylistAssetsMeta>) {
        state.loadStatusMap[meta.remoteOperation] = LoadStatus.Loading;
        const allFinished = Object.keys(state.uploadsByFilename).every(
          fileName => state.uploadsByFilename[fileName].loadStatus !== LoadStatus.Loading,
        );

        if (allFinished) {
          state.uploadsByFilename = {};
        }

        state.uploadsByFilename[payload.name] = {
          loadStatus: LoadStatus.Loading,
          error: null,

          progressInfo: null,
        };
      },
      prepare(payload: File, filters: KeysToCamelCase<ContentPlaylistAssetFilters>) {
        return { payload, meta: { remoteOperation: PlaylistAssetsRemoteOperation.CRUD, filters } };
      },
    },
    uploadPlaylistAssetFailed: {
      reducer(state, { payload, meta, error }: PayloadAction<string, string, PlaylistAssetsMeta, string>) {
        state.loadStatusMap[meta.remoteOperation] = LoadStatus.Failed;
        state.uploadsByFilename[payload].loadStatus = LoadStatus.Failed;
        state.uploadsByFilename[payload].error = error;
      },
      prepare({ name }: File, meta: PlaylistAssetsMeta, { message }: ErrorLike) {
        return { payload: name, meta, error: message };
      },
    },
    uploadPlaylistAssetSucceeded: {
      reducer(state, { payload, meta }: PayloadAction<ApiPlaylistAsset, string, PlaylistAssetsMeta>) {
        state.loadStatusMap[meta.remoteOperation] = LoadStatus.Succeeded;
        state.uploadsByFilename[payload.asset.name].loadStatus = LoadStatus.Succeeded;
        state.uploadsByFilename[payload.asset.name].error = null;
      },
      prepare(payload: ApiPlaylistAsset, meta: PlaylistAssetsMeta) {
        return { payload, meta };
      },
    },
    progressUploadingPlaylistAsset: (state, { payload }: PayloadAction<ProgressInfo>) => {
      const fileName = getContentAssetFileNameFromProgressInfo(payload);
      if (fileName) {
        state.uploadsByFilename[fileName].progressInfo = payload;
      }
    },
    setUploadModalMinimized: (state, { payload }: PayloadAction<boolean>) => {
      state.isUploadModalMinimized = payload;
    },
    iotUpdate: (state, { payload }: PayloadAction<ApiPlaylistAsset>) => {
      if (payload.audit?.deleted_at) {
        removeEntities(state, [payload.id]);
      } else {
        entityAdapter.setOne(state, apiToModelNormalize(normalize(payload)));
      }
    },
  },
  extraReducers: builder => {
    builder.addCase(deploymentsActions.fetchDeploymentsSucceeded, (state, { payload, meta }) => {
      if (!meta.options?.cache?.fetchedFromCache) {
        entityAdapter.setMany(
          state,
          payload.items.flatMap(({ details }) => details?.entities || []).filter(isPlaylistAsset),
        );
      }
    });

    builder.addCase(deploymentsActions.fetchDeploymentSucceeded, (state, { payload, meta }) => {
      if (!meta.options?.cache?.fetchedFromCache) {
        entityAdapter.setMany(state, (payload.details?.entities || []).filter(isPlaylistAsset));
      }
    });

    builder.addCase(deploymentsActions.createDeploymentSucceeded, (state, { payload }) => {
      entityAdapter.setMany(state, (payload.details?.entities || []).filter(isPlaylistAsset));
    });

    builder.addCase(deploymentsActions.updateDeploymentsSucceeded, (state, { payload }) => {
      entityAdapter.setMany(state, payload.flatMap(({ details }) => details?.entities || []).filter(isPlaylistAsset));
    });
  },
});

export const { name: playlistAssetsType, actions: playlistAssetsActions, reducer: playlistAssetsReducer } = slice;

export const entitySelectors = entityAdapter.getSelectors();

export function* rootPlaylistAssetsSaga() {
  yield all([watchPlaylistAssets()]);
}
