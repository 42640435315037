import { GoogleMap, Marker } from "@react-google-maps/api";
import { type FC } from "react";
import styled from "styled-components";

const GMapWrapper = styled.div<{ height: number }>`
  width: 100%;
  height: ${({ height }) => (height ? `${height}px` : "auto")};

  .google-address-map {
    width: 100%;
    height: 100%;
    box-shadow: 0px 0px 0px 0.7px var(--blackShadowColor025);
  }
`;

export const LocationMap: FC<{ coordinates?: string | null; height?: number }> = ({ coordinates, height = 180 }) => {
  // TODO: TESTS: rewrite with js api loader
  // const { isLoaded } = useJsApiLoader({
  //   id: "google-map-script",
  //   googleMapsApiKey: REACT_APP_GOOGLE_MAPS_API_KEY,
  // });

  if (!coordinates) {
    return <div className="google-address-map" />;
  }

  const [lat, lng] = coordinates.split(",").map(parseFloat);

  return (
    <GMapWrapper height={height}>
      <GoogleMap
        key={`google-address-map-${coordinates}`}
        mapContainerClassName="google-address-map"
        options={{
          center: { lat, lng },
          zoom: 14,
          clickableIcons: false,
          disableDefaultUI: true,
          draggable: true,
          zoomControl: true,
          fullscreenControl: true,
        }}
      >
        <Marker position={{ lat, lng }} />
      </GoogleMap>
    </GMapWrapper>
  );
};
