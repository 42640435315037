import { yupResolver } from "@hookform/resolvers/yup";
import { type FC } from "react";
import { type Control, type DeepPartial, type UseFormSetValue } from "react-hook-form";

import { FormationControl } from "@ds/modules/settings/projects/components/forms/controls/formation-control";
import { selectTemplateExperienceFormationValidationData } from "@ds/modules/settings/projects/redux/selectors/experience-formation-selectors";
import { updateValidationSchema } from "@ds/modules/settings/projects/utils/validation-schema";

import {
  DetailsCard,
  DetailsCardEditActionsMenu,
  EditableDetailsCardDecorator,
  EditableDetailsCardRowDecorator,
} from "@ds/components/details-view";
import { useDeepRootSelector } from "@ds/hooks";

import { NotRequiredFormationCardRow } from "../../formation/formation-card-row";

export const ProjectDetailsFormationCard: FC<{
  cardTitle: string;
  project: Project;
  isEditForbidden: boolean;
  templateProperties: [string, TemplateFormationProperty][];
  onSubmit: (changedFields: DeepPartial<Project>) => void;
}> = ({ cardTitle, project, isEditForbidden, templateProperties, onSubmit }) => {
  const validationData = useDeepRootSelector(state =>
    selectTemplateExperienceFormationValidationData(state, project.id, "details", templateProperties),
  );

  return (
    <EditableDetailsCardDecorator
      initialValue={project}
      validationResolver={yupResolver(updateValidationSchema.formation(validationData))}
      onSubmit={onSubmit}
    >
      {cardProps => (
        <DetailsCard
          title={cardTitle}
          icon="desktop"
          actions={
            <DetailsCardEditActionsMenu
              isEditing={cardProps.isEditing}
              isDirty={cardProps.isDirty}
              onReset={cardProps.onResetForm}
              onSubmit={cardProps.handleSubmit(cardProps.onSubmitForm)}
            />
          }
        >
          {templateProperties.map(([field, property]) => (
            <EditableDetailsCardRowDecorator
              key={`project-template-experience-formation-property-${field}`}
              field={`template.experience_formation.properties.${field}`}
              shouldHideEdit={property.readonly || isEditForbidden}
              input={
                <FormationControl
                  name={`template.experience_formation.properties.${field}`}
                  control={cardProps.control as Control<Experience | Project>}
                  setValue={cardProps.setValue as UseFormSetValue<Experience | Project>}
                  property={property}
                  autoFocus
                  excludeLabel
                />
              }
              {...cardProps}
            >
              {rowProps => (
                <NotRequiredFormationCardRow
                  entity={cardProps.entity}
                  field={field}
                  property={property}
                  editableSettings={rowProps}
                />
              )}
            </EditableDetailsCardRowDecorator>
          ))}
        </DetailsCard>
      )}
    </EditableDetailsCardDecorator>
  );
};
