import { useCallback, type FC } from "react";
import { uniq } from "lodash";

import { useGetAggregatedEntityIds } from "@ds/hooks/use-get-aggregated-entity-ids";

import { EntityTypeName } from "@ds/model/entity-model";

import { selectLocationById } from "@ds/modules/locations/redux/selectors";

import { InfoSection, InfoAttribute } from "@ds/components/info-view";
import { AggregatedValue } from "@ds/components/entities";
import { useDeepRootSelector, useRootSelector } from "@ds/hooks";
import { UnassignedLabel, MultipleValuesLabelWithLink } from "@ds/components/forms/labels/common";
import { TextLink } from "@ds/components/text-link";
import { isMultipleFieldValuesInAggregatedEntity } from "@ds/utils/entities";
import { composeDetailsRouteByEntityIds, composeDetailsRouteByEntityType } from "@ds/utils/router";

import { selectExperiencesByIds } from "../../redux/selectors";
import { getExperienceStatusIndicator } from "./status-indicator";
import { ExperienceFormationInfoSection } from "./experience-formation-info-section";

const ExperienceInfoLocationInfoAttribute: FC<{ experience: Experience }> = ({ experience }) => {
  const aggregatedExperienceIds = useGetAggregatedEntityIds(experience);
  const aggregatedExperienceLocationsIds = useDeepRootSelector(state => {
    const aggregatedExperiences = selectExperiencesByIds(state, aggregatedExperienceIds);
    return uniq(aggregatedExperiences.map(({ location_id }) => location_id).filter(_ => _));
  });

  const location = useRootSelector(state => selectLocationById(state, experience.location_id));

  const getInfoBody = useCallback(() => {
    const hasMultipleValues = isMultipleFieldValuesInAggregatedEntity(experience, "location_id");
    if (!aggregatedExperienceLocationsIds.length) {
      return <UnassignedLabel text="Experience(s) has no assigned location(s)" />;
    } else if (hasMultipleValues) {
      return (
        <MultipleValuesLabelWithLink
          to={composeDetailsRouteByEntityIds(aggregatedExperienceLocationsIds, EntityTypeName.LOCATION)}
        />
      );
    }

    return (
      <TextLink to={composeDetailsRouteByEntityType(location)}>
        <AggregatedValue entity={location} field="name" />
      </TextLink>
    );
  }, [experience, location, aggregatedExperienceLocationsIds]);

  return <InfoAttribute name="Location" title={location?.name} value={getInfoBody()} />;
};

export const ExperienceInfoSection: FC<{
  experience: Experience;
  templateProperties: [string, TemplateFormationProperty][];
}> = ({ experience, templateProperties }) => (
  <InfoSection title="Experience Info" icon="pi pi-info-circle">
    <InfoAttribute
      name="Status"
      value={
        <AggregatedValue
          entity={experience}
          field="status"
          formatter={entity => getExperienceStatusIndicator(entity)}
        />
      }
    />

    <InfoAttribute name="Message" value={<AggregatedValue entity={experience} field="status_message" />} />
    <ExperienceInfoLocationInfoAttribute experience={experience} />
    <ExperienceFormationInfoSection experience={experience} templateProperties={templateProperties} />
  </InfoSection>
);
