import { type FC } from "react";
import { Route, Routes, useLocation } from "react-router-dom";

import { MULTIPLE_IDS_PARAM, PERIPHERAL_ID_PARAM } from "@ds/constants/router";

import { PeripheralDetailsPage } from "./peripheral-details-page";
import { PeripheralsMainPage } from "./peripherals-main-page";

const PeripheralsRouteSwitch = () => {
  const { search } = useLocation();
  return search.startsWith(`?${MULTIPLE_IDS_PARAM}`) ? <PeripheralDetailsPage /> : <PeripheralsMainPage />;
};

export const PeripheralsRouter: FC = () => (
  <Routes>
    <Route path="*" element={<PeripheralsRouteSwitch />} />
    <Route path={`:${PERIPHERAL_ID_PARAM}`} element={<PeripheralDetailsPage />} />
  </Routes>
);
