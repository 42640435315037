import styled from "styled-components";

export const WizardTitle = styled.div`
  font-size: 1.2rem;
  font-weight: 600;
  display: flex;
  align-items: center;
  justify-content: center;
  text-transform: capitalize;
  margin-bottom: 10px;
`;
