import { nanoid } from "@reduxjs/toolkit";
import { type ComponentProps, type FC } from "react";
import { Controller, useWatch, type Control } from "react-hook-form";
import { PortalWithState } from "react-portal";
import styled from "styled-components";

import { ContentAssetPreview } from "@ds/modules/content/assets/components/common/content-asset-preview";
import { ContentAssetsAutocomplete } from "@ds/modules/content/assets/components/content-assets-autocomplete";
import { isContentTypeImage, isContentTypeVideo } from "@ds/modules/content/assets/utils/helpers";

import { AutoCompleteMultipleDecorator } from "@ds/components/forms/autocomplete";
import { LabelWrapper } from "@ds/components/forms/labels";
import { TrimmedContent } from "@ds/components/trimmed-text";

import { getContentTypesFilterByPlaylist } from "../../../utils/helpers";

const TrimmedContentWrapper = styled.span`
  max-width: 165px;
  overflow: hidden;
  text-overflow: ellipsis;
`;

const Icon = styled.i`
  height: auto;
  margin: auto 0;
  vertical-align: middle;
  margin-right: 10px;
  font-size: 0.95rem;
`;

const IconButton = styled.button`
  cursor: pointer;
  border: 0;
  background: none;
  margin: 0;
  padding: 0;
  color: inherit;
`;

export const PlaylistAssetsControl: FC<{ control: Control<PlaylistFormData> }> = ({ control }) => {
  const playlist = useWatch({ name: "playlist", control });
  return (
    <Controller
      name="assets"
      control={control}
      render={({ field: { ref, ...restFields }, fieldState }) => (
        <LabelWrapper label="Assets" className="col-12" error={fieldState.error}>
          <AutoCompleteMultipleDecorator
            inputRef={ref}
            chipsMapper={(asset: ContentAsset) => [
              `playlist-asset-chip-${nanoid()}`,
              asset.name,
              {
                template: (
                  <div className="flex justify-content-center align-items-center">
                    <PortalWithState closeOnOutsideClick closeOnEsc>
                      {({ openPortal, closePortal, portal }) => (
                        <>
                          {isContentTypeImage(asset.content_type) && (
                            <IconButton onClick={openPortal}>
                              <TrimmedContent content="preview image">
                                <Icon title="images" className="pi pi-image" />
                              </TrimmedContent>
                            </IconButton>
                          )}

                          {isContentTypeVideo(asset.content_type) && (
                            <IconButton onClick={openPortal}>
                              <TrimmedContent content="preview video">
                                <Icon title="videos" className="pi pi-video" />
                              </TrimmedContent>
                            </IconButton>
                          )}

                          {portal(<ContentAssetPreview asset={asset} onClose={closePortal} />)}
                        </>
                      )}
                    </PortalWithState>

                    <TrimmedContentWrapper>
                      <TrimmedContent content={asset.name} />
                    </TrimmedContentWrapper>
                  </div>
                ),
              },
            ]}
            filterSuggestionsPredicate={() => true}
            {...restFields}
          >
            {(props: ComponentProps<typeof ContentAssetsAutocomplete>) => (
              <ContentAssetsAutocomplete contentTypesFilter={getContentTypesFilterByPlaylist(playlist)} {...props} />
            )}
          </AutoCompleteMultipleDecorator>
        </LabelWrapper>
      )}
    />
  );
};
