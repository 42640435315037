import { produce } from "immer";
import { type SetOptional } from "type-fest";

import { normalizeBase } from "@ds/model/helpers";

import { normalize as projectNormalize } from "@ds/modules/settings/projects/utils/normalizer";

import { isApiUser, isUserArray, isUserQueryOutput } from "./model";

const normalizer = (entity: ApiUser) =>
  produce(normalizeBase(entity), (draft: ApiUser) => {
    draft.projects = projectNormalize(entity.projects);
  });

export const normalize = ((
  entityOrEntities: ApiUser | ApiUser[] | QueryOutput<ApiUser>,
): ApiUser | ApiUser[] | QueryOutput<ApiUser> => {
  if (isUserQueryOutput(entityOrEntities)) {
    return produce(entityOrEntities, draft => {
      draft.items = entityOrEntities.items.map(normalizer);
    });
  }

  return isUserArray(entityOrEntities) ? entityOrEntities.map(normalizer) : normalizer(entityOrEntities);
}) as {
  (user: ApiUser): ApiUser;
  (users: ApiUser[]): ApiUser[];
  (usersQueryOutput: QueryOutput<ApiUser>): QueryOutput<ApiUser>;
};

const apiToModelNormalizer = (entity: User | ApiUser): User => {
  if (!isApiUser(entity)) {
    return entity;
  }

  const result = produce(normalizeBase(entity), (draft: SetOptional<ApiUser, "projects">) => {
    delete draft.projects;
  }) as User;

  return result;
};

export const apiToModelNormalize = ((
  entityOrEntities: User | User[] | QueryOutput<User> | ApiUser | ApiUser[] | QueryOutput<ApiUser>,
) => {
  if (isUserQueryOutput(entityOrEntities)) {
    return produce(entityOrEntities, draft => {
      draft.items = entityOrEntities.items.map(item => apiToModelNormalizer(item) as ApiUser);
    }) as QueryOutput<User>;
  }

  return isUserArray(entityOrEntities)
    ? entityOrEntities.map(apiToModelNormalizer)
    : apiToModelNormalizer(entityOrEntities);
}) as {
  (user: User): User;
  (user: User[]): User[];
  (user: QueryOutput<ApiUser>): QueryOutput<User>;
  (user: ApiUser): User;
  (users: ApiUser[]): User[];
  (usersQueryOutput: QueryOutput<ApiUser>): QueryOutput<User>;
};
