import { type FC } from "react";
import {
  ClimbingBoxLoader as ReactClimbingBoxLoader,
  PropagateLoader as ReactPropagateLoader,
  ScaleLoader as ReactScaleLoader,
} from "react-spinners";
import { type LoaderSizeProps } from "react-spinners/helpers/props";
import styled from "styled-components";

import { trimAll } from "@ds/utils/strings";

import { SmallIcon } from "../icons";

type FlexContainerProps = {
  disableFlex?: boolean;
  justifyContent?: string;
  alignItems?: string;
};

type PositionContainerProps = {
  margin?: string;
  padding?: string;
  className?: string;
};

const FlexContainer = styled.div<FlexContainerProps>`
  display: flex;
  justify-content: ${props => (props.justifyContent ? props.justifyContent : "center")};
  align-items: ${props => (props.alignItems ? props.alignItems : "center")};
`;

const PositionContainer = styled.div<PositionContainerProps>`
  margin: ${props => (props.margin ? props.margin : "0")};
  padding: ${props => (props.padding ? props.padding : "0")};
`;

// TODO: CSS: move custom class props to primeflex classes
export const FieldLoader: FC<LoaderSizeProps & FlexContainerProps & PositionContainerProps> = ({
  size = 15,
  color = "var(--primaryColor100)",
  disableFlex = false,
  justifyContent = "center",
  alignItems = "center",
  margin,
  padding,
  className,
}) => {
  if (disableFlex) {
    return (
      <PositionContainer margin={margin} padding={padding} className={className + " fadein animation-duration-300"}>
        <ReactPropagateLoader size={`${size}px`} color={color} />
      </PositionContainer>
    );
  }

  return (
    <PositionContainer margin={margin} padding={padding} className={className + " fadein animation-duration-300"}>
      <FlexContainer justifyContent={justifyContent} alignItems={alignItems}>
        <ReactPropagateLoader size={`${size}px`} color={color} />
      </FlexContainer>
    </PositionContainer>
  );
};

export const SpinnerLoader2Wrapper = styled.div`
  display: flex;
  align-items: center;
`;

export const SpinnerIcon = ({ className }: { className?: string }) => (
  <i className={trimAll(`pi pi-spin pi-spinner ${className || ""}`)} />
);

export const SpinnerLoader2 = () => (
  <SpinnerLoader2Wrapper>
    <SmallIcon className="pi pi-spin pi-spinner" title="Loading..." />
  </SpinnerLoader2Wrapper>
);

export const ClimbingBoxLoaderWrapper = styled.div`
  display: flex;
  flex-flow: column;
  justify-content: center;
  align-items: center;
  height: 80vh;
`;

const FlexLoading = styled.div`
  display: flex;
  flex-flow: column;
  justify-content: center;
  align-items: center;
  align-self: center;
  flex-grow: 0.66;
`;

const FlexIndicator = styled.div`
  display: flex;
  flex-flow: column;
  align-items: center;
  opacity: 0.66;
`;

export const ClimbingBoxLoader: FC<{ title: string }> = ({ title }) => (
  <ClimbingBoxLoaderWrapper>
    <FlexLoading>
      <FlexIndicator>
        <ReactClimbingBoxLoader />
        <div>{title}</div>
      </FlexIndicator>
    </FlexLoading>
  </ClimbingBoxLoaderWrapper>
);

const LoadingWrapper = styled.div`
  display: grid;
  place-items: center;
  height: 100%;
`;

export const ScaleLoader = () => (
  <LoadingWrapper>
    <ReactScaleLoader />
  </LoadingWrapper>
);
