import { useMemo } from "react";
import { useParams } from "react-router-dom";

import { QueryParamType } from "./types";

export const useUrlRouterParams = ((param: string, queryParamType: QueryParamType = QueryParamType.String) => {
  const params = useParams();
  return useMemo(() => {
    if (queryParamType === QueryParamType.Number) {
      return Number(params[param]) || 0;
    }

    const stringParam = params[param] || "";
    if (queryParamType === QueryParamType.Email) {
      return stringParam;
    }

    return stringParam;
  }, [param, queryParamType, params]);
}) as {
  (param: string): string;
  (param: string, queryParamType: QueryParamType.String): string;
  (param: string, queryParamType: QueryParamType.Email): string;
  (param: string, queryParamType: QueryParamType.Number): number;
  (param: string, queryParamType: QueryParamType): number[] | string[];
};
