import { type FC } from "react";
import { Route, Routes, useLocation } from "react-router-dom";

import { MULTIPLE_IDS_PARAM, PLAYLIST_ID_PARAM } from "@ds/constants/router";

import { PlaylistDetailsPage } from "./playlist-details-page";
import { PlaylistsMainPage } from "./playlists-main-page";

const PlaylistsRouteSwitch = () => {
  const { search } = useLocation();
  return search.startsWith(`?${MULTIPLE_IDS_PARAM}`) ? <PlaylistDetailsPage /> : <PlaylistsMainPage />;
};

export const PlaylistsRouter: FC = () => (
  <Routes>
    <Route path="*" element={<PlaylistsRouteSwitch />} />
    <Route path={`:${PLAYLIST_ID_PARAM}`} element={<PlaylistDetailsPage />} />
  </Routes>
);
