import { produce } from "immer";

import { normalizeBase } from "@ds/model/helpers";

import { isPlaylistQueryOutput, isPlaylistArray } from "./model";

const normalizer = (entity: Playlist): Playlist => normalizeBase({ description: undefined, ...entity });

export const normalize = ((
  entityOrEntities: Playlist | Playlist[] | QueryOutput<Playlist>,
): Playlist | Playlist[] | QueryOutput<Playlist> => {
  if (isPlaylistQueryOutput(entityOrEntities)) {
    return produce(entityOrEntities, draft => {
      draft.items = entityOrEntities.items.map(normalizer);
    });
  }

  return isPlaylistArray(entityOrEntities) ? entityOrEntities.map(normalizer) : normalizer(entityOrEntities);
}) as {
  (playlist: Playlist): Playlist;
  (playlists: Playlist[]): Playlist[];
  (playlistsQueryOutput: QueryOutput<Playlist>): QueryOutput<Playlist>;
};
