import { useCallback, type FC } from "react";
import styled, { css } from "styled-components";

import { DetailsTableDataTypeEnum, MainTableDataTypeEnum } from "@ds/modules/table-data/utils/model";

import { Button, ButtonType } from "@ds/components/forms/buttons";
import { IconType } from "@ds/components/icons";
import { SpinnerLoader2 } from "@ds/components/loaders/loaders";
import { Dialog } from "@ds/components/modals";
import { useRootSelector } from "@ds/hooks";

import { UploadContentAssetView } from "../../assets/components/forms/upload-content-asset-view";
import {
  selectContentAssetIsUploading,
  selectContentAssetIsUploadingFailed,
  selectContentAssetIsUploadingSucceeded,
  selectContentAssetUploadData,
  selectIsContentAssetUploadModalMinimized,
} from "../../assets/redux/selectors/upload-selectors";
import {
  selectIsPlaylistAssetUploadModalMinimized,
  selectPlaylistAssetIsUploading,
  selectPlaylistAssetIsUploadingFailed,
  selectPlaylistAssetIsUploadingSucceeded,
  selectPlaylistAssetUploadData,
} from "../../playlist-assets/redux/selectors/upload-selectors";

const StyledDialog = styled(Dialog)<{ $isMinimized: boolean }>`
  width: ${({ $isMinimized }) => ($isMinimized ? "auto" : "550px")};
  .p-dialog-header-icon:focus {
    box-shadow: var(--button-light-box-shadow);
  }

  ${({ $isMinimized }) =>
    $isMinimized &&
    css`
      .p-dialog-content {
        padding: 0;
      }
    `};
`;

const MinimizedWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 12px 20px;

  > p {
    margin: 0 32px 0 12px;
  }
`;

const UploadCheckIcon = styled.i`
  color: var(--positiveColor);
`;

const UploadExclamationIcon = styled.i`
  color: var(--red-color);
`;

export const UploadFilesModal: FC<{
  tableType: MainTableDataTypeEnum | DetailsTableDataTypeEnum;
  setIsMinimized: (value: boolean) => () => void;
}> = ({ tableType, setIsMinimized }) => {
  const uploadData = useRootSelector(state =>
    tableType === MainTableDataTypeEnum.ContentPlaylists
      ? selectPlaylistAssetUploadData(state)
      : selectContentAssetUploadData(state),
  );

  const isUploading = useRootSelector(state =>
    tableType === MainTableDataTypeEnum.ContentPlaylists
      ? selectPlaylistAssetIsUploading(state)
      : selectContentAssetIsUploading(state),
  );
  const isFailed = useRootSelector(state =>
    tableType === MainTableDataTypeEnum.ContentPlaylists
      ? selectPlaylistAssetIsUploadingFailed(state)
      : selectContentAssetIsUploadingFailed(state),
  );

  const isSucceeded = useRootSelector(state =>
    tableType === MainTableDataTypeEnum.ContentPlaylists
      ? selectPlaylistAssetIsUploadingSucceeded(state)
      : selectContentAssetIsUploadingSucceeded(state),
  );

  const isMinimized = useRootSelector(state =>
    tableType === MainTableDataTypeEnum.ContentPlaylists
      ? selectIsPlaylistAssetUploadModalMinimized(state)
      : selectIsContentAssetUploadModalMinimized(state),
  );

  const renderMinimizedContent = useCallback(() => {
    if (isUploading) {
      return (
        <>
          <SpinnerLoader2 />
          <p>Uploading</p>
          <Button
            ariaLabel="maximize button"
            buttonType={ButtonType.Round}
            icon={IconType.Maximize}
            onClick={setIsMinimized(false)}
          />
        </>
      );
    }

    if (isFailed) {
      return (
        <>
          <UploadExclamationIcon className="pi pi-exclamation-circle" />
          <p>Upload failed</p>
          <Button
            ariaLabel="maximize button"
            buttonType={ButtonType.Round}
            icon={IconType.Maximize}
            onClick={setIsMinimized(false)}
          />
        </>
      );
    }

    if (isSucceeded) {
      return (
        <>
          <UploadCheckIcon className="pi pi-check" />
          <p>Uploaded successfully</p>

          <Button
            ariaLabel="maximize button"
            buttonType={ButtonType.Round}
            icon={IconType.Maximize}
            onClick={setIsMinimized(false)}
          />
        </>
      );
    }
  }, [isUploading, isFailed, isSucceeded, setIsMinimized]);

  return (
    <StyledDialog
      visible={!!Object.keys(uploadData).length}
      draggable={false}
      modal={false}
      showHeader={!isMinimized}
      $isMinimized={isMinimized}
      header="Upload assets"
      position="bottom-right"
      closable={!isMinimized}
      onHide={setIsMinimized(true)}
    >
      {isMinimized ? (
        <MinimizedWrapper>{renderMinimizedContent()}</MinimizedWrapper>
      ) : (
        <UploadContentAssetView uploadData={uploadData} />
      )}
    </StyledDialog>
  );
};
