import { useCallback, type FC } from "react";
import styled from "styled-components";

import { selectDeploymentsIsLoading } from "@ds/modules/deployments/redux/selectors";
import { DeploymentsRemoteOperation } from "@ds/modules/deployments/redux/slice";
import { MainTableDataTypeEnum } from "@ds/modules/table-data/utils/model";

import { Loading } from "@ds/components/loaders/loading";
import { ProgressIndicator } from "@ds/components/progress-indicator";
import { TableWithPagination } from "@ds/components/table/main-table";
import {
  Column,
  getEntityIdColumn,
  getEntityNameColumn,
  getGoToDetailsColumn,
  getLastUpdatedColumn,
  getSelectionColumn,
  getStatusColumn,
} from "@ds/components/table/table-columns";
import { TotalSelectedLabel } from "@ds/components/table/total-selected-label";
import { usePagination, useRootDispatch, useRootSelector, useTableDataSorting } from "@ds/hooks";

import {
  selectCommandByPlayerId,
  selectPlayersIsLoading,
  selectSelectedPlayers,
  selectTablePlayers,
} from "../redux/selectors";
import { DevicePlayersRemoteOperation, playersActions } from "../redux/slice";
import { getPlayerStatusIndicator } from "./common/status-indicator";

const CommandBodyWrapper = styled.div`
  display: flex;
  align-items: center;
`;

const useGetCommandBodyTemplate = ({ id }: Player) => {
  const command = useRootSelector(state => selectCommandByPlayerId(state, id));
  if (command) {
    const { name, friendly_name, status, message } = command;
    const commandName = friendly_name || name;
    return commandName ? (
      <CommandBodyWrapper>
        <ProgressIndicator status={status} title={message} />
        <span>{commandName}</span>
      </CommandBodyWrapper>
    ) : null;
  }

  return null;
};

export const PlayersTable: FC = () => {
  const selectedEntities = useRootSelector(selectSelectedPlayers);
  const entities = useRootSelector(selectTablePlayers);

  const isLoading = useRootSelector(selectPlayersIsLoading);
  const checkboxIsLoading = useRootSelector(state =>
    selectPlayersIsLoading(state, DevicePlayersRemoteOperation.CHECKBOXES),
  );

  const isDeploying = useRootSelector(state => selectDeploymentsIsLoading(state, DeploymentsRemoteOperation.DEPLOYING));

  const [pagination, setPagination] = usePagination(MainTableDataTypeEnum.DevicePlayers);
  const [{ sortOrder, sortField }, setSorting] = useTableDataSorting(MainTableDataTypeEnum.DevicePlayers);

  const dispatch = useRootDispatch();
  const onToggleRowSelected = useCallback(
    (entry: Player) => dispatch(playersActions.toggleSelected(entry.id)),
    [dispatch],
  );

  const onSingleRowSelectHandler = useCallback(
    (entry: Player) => dispatch(playersActions.selectPlayers([entry])),
    [dispatch],
  );

  const onToggleAllHandler = useCallback(
    (isSelectedAll: boolean) => {
      if (isSelectedAll) {
        dispatch(playersActions.fetchPlayers(undefined, undefined, DevicePlayersRemoteOperation.CHECKBOXES));
      } else {
        dispatch(playersActions.selectPlayers([]));
      }
    },
    [dispatch],
  );

  return (
    <Loading isLoading={isLoading || isDeploying}>
      <TableWithPagination
        value={entities}
        selection={selectedEntities}
        onSingleRowSelect={onSingleRowSelectHandler}
        toggleRowSelected={onToggleRowSelected}
        loading={isLoading || isDeploying}
        sortField={sortField}
        sortOrder={sortOrder}
        onSort={setSorting}
        pagination={pagination}
        onPageChange={setPagination}
      >
        {getSelectionColumn({
          total: pagination.total,
          selected: selectedEntities.length,
          isLoading: checkboxIsLoading,
          onToggleAll: onToggleAllHandler,
        })}

        {getEntityIdColumn()}
        {getEntityNameColumn({ style: { flexGrow: 2 } })}
        <Column field="serial_number" header="Serial #" sortable />
        <Column field="ip_address" header="IP Address" sortable />
        <Column field="code_version" header="Сode Version" className="center" sortable />
        <Column header="Last Action" body={useGetCommandBodyTemplate} resizeable={false} />

        {getStatusColumn({ field: "state", body: getPlayerStatusIndicator })}
        {getLastUpdatedColumn()}
        {getGoToDetailsColumn()}
      </TableWithPagination>

      <TotalSelectedLabel selectedCount={checkboxIsLoading ? Number(pagination.total) : selectedEntities.length} />
    </Loading>
  );
};
