import prettyBytes from "pretty-bytes";
import { useCallback, type FC, type MouseEvent } from "react";
import styled from "styled-components";

import { ContentAssetThumbnail } from "./content-asset-thumbnail";

const GridItemWrapper = styled.div<{ isSelected?: boolean }>`
  position: relative;
  padding: 8px;
  display: flex;
  flex-flow: column;
  justify-content: space-between;
  border: 1px solid var(--primaryColor015);
  box-shadow: 0 4px 8px var(--primaryColor015);

  :hover {
    outline: ${props => (props.isSelected ? "2px solid var(--primaryColor060)" : "2px solid var(--primaryColor015)")};
  }

  outline: ${props => (props.isSelected ? "2px solid var(--primaryColor060)" : "inherit")};
`;

const Check = styled.i`
  position: absolute;
  top: -8px;
  right: -8px;
  color: var(--lightSurfaceColor);
  background: var(--primaryColor100);
  border-radius: 50%;
  padding: 3px;
`;

export const ContentAssetThumbnailGridItem: FC<{
  asset: ContentAsset;
  isSelected: boolean;
  onSelect: (event: MouseEvent<HTMLDivElement>, asset: ContentAsset) => void;
}> = ({ asset, isSelected, onSelect }) => {
  const onClickHandler = useCallback((event: MouseEvent<HTMLDivElement>) => onSelect(event, asset), [asset, onSelect]);

  return (
    <GridItemWrapper isSelected={isSelected} onClick={onClickHandler}>
      {isSelected && <Check className="pi pi-check" />}
      <ContentAssetThumbnail asset={asset} width="100%" height="150px" />
      <div className="flex flex-column align-items-center text-sm mx-2">
        <div className="w-full font-medium white-space-nowrap text-overflow-ellipsis overflow-hidden">{asset.name}</div>
        <div className="w-full flex justify-content-between mt-1 text-xs select-none">
          <span className="font-italic">{asset.content_type}</span>
          <span>{prettyBytes(asset.file_size)}</span>
        </div>
      </div>
    </GridItemWrapper>
  );
};
