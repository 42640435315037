import { type FC } from "react";

import { AggregatedValue } from "@ds/components/entities";
import { InfoAttribute, InfoSection } from "@ds/components/info-view";

export const LocationInfoSection: FC<{ location: Locality }> = ({ location }) => (
  <InfoSection title="Location Info" icon="pi pi-info-circle">
    <InfoAttribute name="Store ID" value={<AggregatedValue entity={location} field="store_id" />} />
    <InfoAttribute name="Time zone" value={<AggregatedValue entity={location} field="time_zone.time_zone_id" />} />
  </InfoSection>
);
