import { Storage as AmplifyStorage } from "aws-amplify";

import { REACT_APP_REGION } from "@ds/constants/environment";

export interface StorageServiceConfig {
  region: string;
  bucket?: string;
}

export interface StorageMethodConfig {
  level?: "public" | "protected" | "private";
  contentType?: string;
  download?: boolean;
  maxKeys?: number;
  progressCallback?: (progressInfo: ProgressInfo) => void;
}

export interface ProgressInfo {
  key?: string;
  name?: string;
  loaded?: number;
  part?: number;
  total?: number;
  isTrusted?: boolean;
}

class StorageService {
  private config: StorageServiceConfig = {
    region: REACT_APP_REGION,
  };

  configure(cfg: StorageServiceConfig) {
    this.config = { ...this.config, ...cfg };
    AmplifyStorage.configure({
      bucket: this.config.bucket,
      region: this.config.region,
    });
  }

  /* eslint-disable class-methods-use-this */
  async get(key: string, config?: StorageMethodConfig) {
    return AmplifyStorage.get(key, config) as Promise<string | { Body: Uint8Array; ContentType: string }>;
  }

  async put(key: string, data: unknown, config?: StorageMethodConfig) {
    return AmplifyStorage.put(key, data, config) as unknown as { key: string };
  }

  async remove(key: string, config?: StorageMethodConfig): Promise<unknown> {
    return AmplifyStorage.remove(key, config);
  }

  async list(key: string, config?: StorageMethodConfig): Promise<unknown> {
    return AmplifyStorage.list(key, config);
  }
  /* eslint-enable class-methods-use-this */
}

export const storageService = new StorageService();
