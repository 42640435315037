import { yupResolver } from "@hookform/resolvers/yup";
import { noop } from "lodash";
import { type FC } from "react";

import { getPlayerStatusIndicatorWithNameAndLink } from "@ds/modules/devices/players/components/common/status-indicator";
import { selectAggregatedPlayerByIds } from "@ds/modules/devices/players/redux/selectors";

import { DetailsCard, DetailsCardRow, EditableDetailsCardDecorator } from "@ds/components/details-view";
import { AggregatedValue } from "@ds/components/entities";
import { MultipleValuesLabelWithLink } from "@ds/components/forms/labels/common";
import { useDeepRootSelector } from "@ds/hooks";
import { formatAbsoluteDate, formatRelativeDate } from "@ds/utils/date";
import { getAggregatedEntityIds } from "@ds/utils/entities";
import { getProperty } from "@ds/utils/properties";
import { composeDetailsRouteByEntityType } from "@ds/utils/router";

import { selectPeripheralsByIds } from "../../redux/selectors";
import { updateValidationSchema } from "../../utils/validation-schema";

export const PeripheralDetailsPlayerInfoCard: FC<{ peripheral: Peripheral }> = ({ peripheral }) => {
  const aggregatedPlayer = useDeepRootSelector(state => {
    const aggregatedPlayerIds = selectPeripheralsByIds(state, getAggregatedEntityIds(peripheral)).map(
      ({ device_id }) => device_id,
    );

    return selectAggregatedPlayerByIds(state, aggregatedPlayerIds) || ({} as Player);
  });

  return (
    <EditableDetailsCardDecorator
      initialValue={aggregatedPlayer}
      validationResolver={yupResolver(updateValidationSchema.info)}
      onSubmit={noop}
    >
      {cardProps => (
        <DetailsCard title="Player Info" icon="info-circle">
          <DetailsCardRow label="Name">
            <AggregatedValue
              entity={cardProps.entity}
              field="name"
              formatter={entity => getPlayerStatusIndicatorWithNameAndLink(entity)}
              multipleFormatter={entity => <MultipleValuesLabelWithLink to={composeDetailsRouteByEntityType(entity)} />}
            />
          </DetailsCardRow>

          <DetailsCardRow label="Applet">
            <AggregatedValue entity={cardProps.entity} field="applet_name" />
          </DetailsCardRow>

          <DetailsCardRow label="Serial #">
            <AggregatedValue entity={cardProps.entity} field="serial_number" />
          </DetailsCardRow>

          <DetailsCardRow label="IP Address">
            <AggregatedValue entity={cardProps.entity} field="ip_address" />
          </DetailsCardRow>

          <DetailsCardRow label="MAC Address">
            <AggregatedValue entity={cardProps.entity} field="mac_address" />
          </DetailsCardRow>

          <DetailsCardRow label="OS Version">
            <AggregatedValue entity={cardProps.entity} field="os_version" />
          </DetailsCardRow>

          <DetailsCardRow label="Code Version">
            <AggregatedValue entity={cardProps.entity} field="code_version" />
          </DetailsCardRow>

          <DetailsCardRow label="Model">
            <AggregatedValue entity={cardProps.entity} field="model" />
          </DetailsCardRow>

          <DetailsCardRow label="Manufacturer">
            <AggregatedValue entity={cardProps.entity} field="make" />
          </DetailsCardRow>

          <DetailsCardRow label="Video Mode">
            <AggregatedValue entity={cardProps.entity} field="video_mode" />
          </DetailsCardRow>

          <DetailsCardRow label="HDMI Status">
            <AggregatedValue entity={cardProps.entity} field="hdmi_status" />
          </DetailsCardRow>

          <DetailsCardRow label="Booted At">
            <AggregatedValue
              entity={cardProps.entity}
              field="boot_time"
              formatter={(entity, field) => formatAbsoluteDate(getProperty(entity, field))}
            />
          </DetailsCardRow>

          <DetailsCardRow label="Last Updated">
            <AggregatedValue
              entity={cardProps.entity}
              field="audit.updated_at"
              formatter={(entity, field) => formatRelativeDate(getProperty(entity, field))}
            />
          </DetailsCardRow>
        </DetailsCard>
      )}
    </EditableDetailsCardDecorator>
  );
};
