import { useMemo, type FC } from "react";

import { AggregatedValue } from "@ds/components/entities";
import { MultipleValuesLabel, UnassignedLabel } from "@ds/components/forms/labels/common";
import { InfoAttribute, InfoSection } from "@ds/components/info-view";
import { isMultipleFieldValuesInAggregatedEntity } from "@ds/utils/entities";

export const LocationAddressInfoSection: FC<{ location: Locality }> = ({ location }) => {
  const infoBody = useMemo(() => {
    if (isMultipleFieldValuesInAggregatedEntity(location, "address")) {
      return <MultipleValuesLabel />;
    } else if (!location?.address?.street_address) {
      return <UnassignedLabel text="No address provided..." />;
    }

    return (
      <>
        <InfoAttribute name="Street 1" value={<AggregatedValue entity={location} field="address.street_address.0" />} />
        <InfoAttribute name="Street 2" value={<AggregatedValue entity={location} field="address.street_address.1" />} />
        <InfoAttribute name="City" value={<AggregatedValue entity={location} field="address.locality" />} />
        <InfoAttribute name="State" value={<AggregatedValue entity={location} field="address.administrative_area" />} />
        <InfoAttribute name="ZIP Code" value={<AggregatedValue entity={location} field="address.post_code" />} />
        <InfoAttribute name="Country" value={<AggregatedValue entity={location} field="address.country" />} />
      </>
    );
  }, [location]);

  return (
    <InfoSection title="Address" icon="pi pi-map-marker">
      {infoBody}
    </InfoSection>
  );
};
