import { TreeTable as PrimeTreeTable, type TreeTableProps as PrimeTreeTableProps } from "primereact/treetable";
import { type FC } from "react";
import styled, { css } from "styled-components";

export type { TreeNode } from "primereact/treenode";

const CellStyles = css`
  &.center {
    display: flex;
    align-items: center;
    text-align: center;
    justify-content: center;
  }

  &.w200 {
    flex: none;
    width: 200px;
  }
`;

const DataTableWrapper = styled.div`
  .p-treetable {
    user-select: none;

    > .p-treetable-wrapper > table {
      width: 100%;

      thead.p-treetable-thead {
        > tr > th {
          ${CellStyles}
        }
      }

      tbody.p-treetable-tbody {
        > tr > td {
          ${CellStyles}
        }
      }
    }
  }
`;

export const TreeTable: FC<PrimeTreeTableProps> = ({ loading, ...rest }) => (
  <DataTableWrapper>
    <PrimeTreeTable emptyMessage={loading ? "Loading entities..." : "No entities found"} {...rest} />
  </DataTableWrapper>
);
