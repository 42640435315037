import { yupResolver } from "@hookform/resolvers/yup";
import { type FC } from "react";
import { type DeepPartial } from "react-hook-form";

import { useGetAggregatedEntityIds } from "@ds/hooks/use-get-aggregated-entity-ids";

import { isAggregatedEntity } from "@ds/model/entity-model";

import {
  DetailsCard,
  DetailsCardEditActionsMenu,
  DetailsCardRow,
  EditableDetailsCardDecorator,
  EditableDetailsCardRow,
  EditableDetailsCardRowDecorator,
} from "@ds/components/details-view";
import { AggregatedValue } from "@ds/components/entities";
import { BooleanControl } from "@ds/components/forms/control/boolean-control";
import { useRootSelector } from "@ds/hooks";
import { formatRelativeDate } from "@ds/utils/date";
import { getProperty } from "@ds/utils/properties";

import { selectCurrentUser, selectCurrentUserIsTenantAdmin, selectUserIsTenantOwnerById } from "../../redux/selectors";
import { updateValidationSchema } from "../../utils/validation-schema";
import { UserFirstNameControl } from "../forms/controls/user-first-name-control";
import { UserLastNameControl } from "../forms/controls/user-last-name-control";
import { UserRoleControl } from "../forms/controls/user-role-control";
import { UserDetailsInfoCardActionsMenu } from "./user-details-info-card-actions-menu";

export const UserDetailsInfoCard: FC<{ user: User; onSubmit: (changedFields: DeepPartial<User>) => void }> = ({
  user,
  onSubmit,
}) => {
  const aggregatedUserIds = useGetAggregatedEntityIds(user);
  const isAdmin = useRootSelector(selectCurrentUserIsTenantAdmin);
  const isUserModifyHimself = useRootSelector(state => aggregatedUserIds.includes(selectCurrentUser(state)?.id || 0));
  const isUserModifyTenantOwner = useRootSelector(state =>
    aggregatedUserIds.some(userId => selectUserIsTenantOwnerById(state, userId)),
  );

  return (
    <EditableDetailsCardDecorator
      initialValue={user}
      validationResolver={yupResolver(updateValidationSchema.getInfo({ isUserModifyHimself, isUserModifyTenantOwner }))}
      onSubmit={onSubmit}
    >
      {cardProps => (
        <DetailsCard
          title="Info"
          icon="info-circle"
          actions={
            !cardProps.isDirty && !cardProps.isEditing ? (
              <UserDetailsInfoCardActionsMenu user={user} />
            ) : (
              <DetailsCardEditActionsMenu
                isEditing={cardProps.isEditing}
                isDirty={cardProps.isDirty}
                onReset={cardProps.onResetForm}
                onSubmit={cardProps.handleSubmit(cardProps.onSubmitForm)}
              />
            )
          }
        >
          <DetailsCardRow label="Email">
            <AggregatedValue entity={cardProps.entity} field="email" />
          </DetailsCardRow>

          <EditableDetailsCardRowDecorator
            field="given_name"
            input={<UserFirstNameControl control={cardProps.control} autoFocus excludeLabel />}
            shouldHideEdit={isAggregatedEntity(cardProps.entity)}
            {...cardProps}
          >
            {rowProps => (
              <DetailsCardRow label="First Name" editableSettings={rowProps} isRequired>
                <AggregatedValue
                  entity={cardProps.entity}
                  field="given_name"
                  shouldShowEditedValue={rowProps.isDirty}
                />
              </DetailsCardRow>
            )}
          </EditableDetailsCardRowDecorator>

          <EditableDetailsCardRowDecorator
            field="family_name"
            input={<UserLastNameControl control={cardProps.control} autoFocus excludeLabel />}
            shouldHideEdit={isAggregatedEntity(cardProps.entity)}
            {...cardProps}
          >
            {rowProps => (
              <DetailsCardRow label="Last Name" editableSettings={rowProps} isRequired>
                <AggregatedValue
                  entity={cardProps.entity}
                  field="family_name"
                  shouldShowEditedValue={rowProps.isDirty}
                />
              </DetailsCardRow>
            )}
          </EditableDetailsCardRowDecorator>

          <EditableDetailsCardRowDecorator
            field="role"
            input={<UserRoleControl control={cardProps.control} autoFocus excludeLabel />}
            shouldHideEdit={!isAdmin}
            {...cardProps}
          >
            {rowProps => (
              <DetailsCardRow label="Role" editableSettings={rowProps} isRequired>
                <AggregatedValue entity={cardProps.entity} field="role" shouldShowEditedValue={rowProps.isDirty} />
              </DetailsCardRow>
            )}
          </EditableDetailsCardRowDecorator>

          <EditableDetailsCardRowDecorator
            field="is_active"
            input={
              <BooleanControl
                name="is_active"
                control={cardProps.control}
                setValue={cardProps.setValue}
                labelTitle="Active"
                autoFocus
                excludeLabel
              />
            }
            shouldHideEdit={!isAdmin || isUserModifyHimself || isUserModifyTenantOwner}
            {...cardProps}
          >
            {rowProps => <EditableDetailsCardRow label="Active" editableSettings={rowProps} />}
          </EditableDetailsCardRowDecorator>

          <EditableDetailsCardRowDecorator
            field="use_dark_theme"
            input={
              <BooleanControl
                name="use_dark_theme"
                control={cardProps.control}
                setValue={cardProps.setValue}
                labelTitle="Dark Theme"
                autoFocus
                excludeLabel
              />
            }
            shouldHideEdit={!isUserModifyHimself}
            {...cardProps}
          >
            {rowProps => <EditableDetailsCardRow label="Dark Theme" editableSettings={rowProps} />}
          </EditableDetailsCardRowDecorator>

          <DetailsCardRow label="Last Updated">
            <AggregatedValue
              entity={cardProps.entity}
              field="audit.updated_at"
              formatter={(entity, field) => formatRelativeDate(getProperty(entity, field))}
            />
          </DetailsCardRow>
        </DetailsCard>
      )}
    </EditableDetailsCardDecorator>
  );
};
