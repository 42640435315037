import {
  RadioButton as PrimeRadioButton,
  type RadioButtonProps as PrimeRadioButtonProps,
} from "primereact/radiobutton";
import { forwardRef } from "react";
import styled from "styled-components";

import { BaseInputMixin } from "./styles";

export type { RadioButtonProps } from "primereact/radiobutton";

const RadioButtonWrapper = styled.div`
  .p-radiobutton-box {
    ${BaseInputMixin}
  }
`;

export const RadioButton = forwardRef<PrimeRadioButton, PrimeRadioButtonProps>((props, ref) => (
  <RadioButtonWrapper>
    <PrimeRadioButton ref={ref} {...props} />
  </RadioButtonWrapper>
));
