import { useCallback, type FC } from "react";
import { Controller, useWatch, type Control, type UseFormSetValue } from "react-hook-form";

import { createAddress } from "@ds/modules/locations/utils/model";

import { useDetailsInlineSaving } from "@ds/components/details-view/hooks";
import { LabelWrapper } from "@ds/components/forms/labels";
import { AddressCountry, GoogleSearchTypesEnum } from "@ds/services/google-api";

import { LocationAddressesAutoComplete, type AddressData } from "../../location-addresses-autocomplete";

export const AddressAutoCompleteControl: FC<{
  control: Control<Locality>;
  setValue: UseFormSetValue<Locality>;
  name: "address.organization" | "address.street_address.0";
  label: string;
  placeholder?: string;
  searchType: GoogleSearchTypesEnum;
  shouldUpdateOpenHours?: boolean;
  excludeLabel?: boolean;
  autoFocus?: boolean;
  onBlur?: () => void;
}> = ({
  control,
  setValue,
  name,
  label,
  placeholder,
  searchType,
  shouldUpdateOpenHours = true,
  excludeLabel,
  autoFocus,
  onBlur,
}) => {
  const onBlurHandler = useDetailsInlineSaving(onBlur);

  const currentCountry = useWatch({ name: "address.country", control });
  const onChangeHandler = useCallback(
    (data: AddressData | null) => {
      if (data) {
        setValue("address", data.address, {
          shouldDirty: true,
          shouldTouch: true,
          shouldValidate: true,
        });

        if (shouldUpdateOpenHours) {
          setValue("open_hours", data.open_hours, {
            shouldDirty: true,
            shouldTouch: true,
            shouldValidate: true,
          });
        }

        setValue("time_zone", data.time_zone, {
          shouldDirty: true,
          shouldTouch: true,
          shouldValidate: true,
        });
      } else {
        const newAddress = createAddress({ country: currentCountry as AddressCountry });
        setValue("address", newAddress);
      }
    },
    [shouldUpdateOpenHours, currentCountry, setValue],
  );

  const countriesFilter: string[] = [currentCountry];
  if (currentCountry === AddressCountry.USA) {
    countriesFilter.push(
      AddressCountry.PUERTO_RICO,
      AddressCountry.VIRGIN_ISLANDS,
      AddressCountry.GUAM,
      AddressCountry.NORTHERN_MARIANA_ISLANDS,
    );
  }

  return (
    <Controller
      name={name}
      control={control}
      render={({ field: { ref, onBlur: onBlurFn, onChange, ...restFields }, fieldState }) => {
        const element = (
          <LocationAddressesAutoComplete
            field="description"
            placeholder={placeholder}
            inputRef={ref}
            searchType={searchType}
            autoFocus={autoFocus}
            countriesFilter={countriesFilter}
            onComplete={onChange}
            onChange={onChangeHandler}
            onBlur={onBlurHandler(onBlurFn)}
            {...restFields}
          />
        );

        if (excludeLabel) {
          return element;
        }

        return (
          <LabelWrapper label={label} error={fieldState.error}>
            {element}
          </LabelWrapper>
        );
      }}
    />
  );
};
