import { useMemo, type FC } from "react";

import { useCurrentView } from "@ds/hooks/use-current-view";
import { useVisibility } from "@ds/hooks/use-visibility";

import { EntityTypeName } from "@ds/model/entity-model";

import { CreateDeploymentModal } from "@ds/modules/deployments/components/forms/create-deployment-form/create-deployment-modal";

import { MainView } from "@ds/components/main-view";
import { TableTabMenu } from "@ds/components/table/table-tab-menu";
import { useRootSelector } from "@ds/hooks";

import { getPageTitleByTableDataType } from "../table-data/utils/helpers";
import { MainTableDataTypeEnum } from "../table-data/utils/model";
import { DeploymentsTableActionsMenu } from "./components/common/deployments-table-actions-menu";
import { DeploymentInfo } from "./components/deployment-info";
import { DeploymentsTable } from "./components/deployments-table";
import { ScheduleDeploymentsModal } from "./components/forms/schedule-deployments-form/schedule-deployments-modal";
import { selectSelectedDeployments } from "./redux/selectors";

export const DeploymentsMainPage: FC = () => {
  const [currentView] = useCurrentView();
  const [isCreateModalVisible, setIsCreateModalVisible] = useVisibility();
  const [isScheduleModalVisible, setIsScheduleModalVisible] = useVisibility();
  const selectedDeployments = useRootSelector(selectSelectedDeployments);

  const filterOptions = useMemo(
    () => ({
      entityType: EntityTypeName.DEPLOYMENT,
      tableType: MainTableDataTypeEnum.Deployments,
    }),
    [],
  );

  if (!currentView) {
    return null;
  }

  return (
    <>
      <MainView
        title={getPageTitleByTableDataType(filterOptions.tableType)}
        filterOptions={filterOptions}
        options={<TableTabMenu tableType={filterOptions.tableType} currentView={currentView} />}
        view={<DeploymentsTable />}
        info={<DeploymentInfo onCreateDeployment={setIsCreateModalVisible(true)} />}
        actions={
          <DeploymentsTableActionsMenu
            onCreate={setIsCreateModalVisible(true)}
            onSchedule={setIsScheduleModalVisible(true)}
          />
        }
      />

      {isCreateModalVisible && <CreateDeploymentModal onClose={setIsCreateModalVisible(false)} />}
      {isScheduleModalVisible && (
        <ScheduleDeploymentsModal deployments={selectedDeployments} onClose={setIsScheduleModalVisible(false)} />
      )}
    </>
  );
};
