import { useMemo, type FC } from "react";

import { isAggregatedEntity } from "@ds/model/entity-model";

import { MultipleValuesLabel, UnassignedLabel } from "@ds/components/forms/labels/common";
import { InfoAttribute, InfoSection } from "@ds/components/info-view";
import { isMultipleFieldValuesInAggregatedEntity } from "@ds/utils/entities";

import { DOW, formatHours } from "../../utils/datetime-helpers";

export const LocationOpenHoursInfoSection: FC<{ location: Locality }> = ({ location }) => {
  const infoBody = useMemo(() => {
    if (!isAggregatedEntity(location)) {
      return (
        <>
          {DOW.map((day, index) => (
            <InfoAttribute key={`open-hours-day-${day}`} name={day} value={formatHours(location.open_hours[index])} />
          ))}
        </>
      );
    }

    if (isMultipleFieldValuesInAggregatedEntity(location, "open_hours")) {
      return (
        <>
          {DOW.map((day, index) => (
            <InfoAttribute
              key={`open-hours-day-${day}`}
              name={day}
              value={
                (location.metadata?.aggregatedFields?.open_hours as boolean[])?.[index] === true ? (
                  <MultipleValuesLabel />
                ) : (
                  formatHours(location.open_hours[index])
                )
              }
            />
          ))}
        </>
      );
    }

    return <UnassignedLabel text="Location(s) has no open hours" />;
  }, [location]);

  return (
    <InfoSection title="Open hours" icon="pi pi-list">
      {infoBody}
    </InfoSection>
  );
};
