import { uniq } from "lodash";
import { useEffect, useMemo, type FC } from "react";

import { useGetAggregatedEntityIds } from "@ds/hooks/use-get-aggregated-entity-ids";

import { EntityTypeName, isAggregatedEntity } from "@ds/model/entity-model";

import {
  getDeploymentStatusIndicatorWithNameAndLink,
  getDeviceSyncStatusIndicator,
} from "@ds/modules/deployments/components/common/status-indicator";
import { selectDeploymentsByIds, selectDeploymentsIsLoading } from "@ds/modules/deployments/redux/selectors";
import { deploymentsActions } from "@ds/modules/deployments/redux/slice";
import { selectDeviceSyncsByDeviceIds, selectDeviceSyncsIsLoading } from "@ds/modules/device-syncs/redux/selectors";
import { deviceSyncsActions } from "@ds/modules/device-syncs/redux/slice";

import { MultipleValuesLabelWithLink, UnassignedLabel } from "@ds/components/forms/labels/common";
import { InfoAttribute, InfoSection } from "@ds/components/info-view";
import { SpinnerLoader2 } from "@ds/components/loaders/loaders";
import { useDeepRootSelector, useRootDispatch, useRootSelector } from "@ds/hooks";
import { formatAbsoluteDate } from "@ds/utils/date";
import { composeDetailsRouteByEntityIds } from "@ds/utils/router";

export const PlayerLastSyncInfoSection: FC<{ player: Player }> = ({ player: player }) => {
  const aggregatedPlayerIds = useGetAggregatedEntityIds(player);
  const aggregatedPlayersDeviceSyncs = useDeepRootSelector(state =>
    selectDeviceSyncsByDeviceIds(state, aggregatedPlayerIds, "id", "desc"),
  );

  const aggregatedPlayersDeviceSyncsDeploymentIds = useDeepRootSelector(state =>
    uniq(selectDeviceSyncsByDeviceIds(state, aggregatedPlayerIds).map(({ deployment_id }) => deployment_id)),
  );

  const aggregatedPlayersDeviceSyncsDeployments = useDeepRootSelector(state =>
    selectDeploymentsByIds(state, aggregatedPlayersDeviceSyncsDeploymentIds).filter(_ => _),
  );

  const isLoading = useRootSelector(
    state =>
      selectDeviceSyncsIsLoading(state) ||
      selectDeploymentsIsLoading(state) ||
      (!!aggregatedPlayersDeviceSyncs?.length && !aggregatedPlayersDeviceSyncsDeployments?.length),
  );

  const dispatch = useRootDispatch();
  useEffect(() => {
    if (aggregatedPlayerIds.length) {
      dispatch(deviceSyncsActions.fetchDeviceSyncs({ deviceId: aggregatedPlayerIds }));
    }
  }, [dispatch, aggregatedPlayerIds]);

  useEffect(() => {
    if (aggregatedPlayersDeviceSyncsDeploymentIds.length) {
      dispatch(deploymentsActions.fetchDeployments({ id: aggregatedPlayersDeviceSyncsDeploymentIds }));
    }
  }, [dispatch, aggregatedPlayersDeviceSyncsDeploymentIds]);

  const infoBody = useMemo(() => {
    if (isLoading) {
      return <SpinnerLoader2 />;
    }

    if (isAggregatedEntity(player)) {
      return (
        <MultipleValuesLabelWithLink
          to={composeDetailsRouteByEntityIds(aggregatedPlayersDeviceSyncsDeploymentIds, EntityTypeName.DEPLOYMENT)}
        />
      );
    } else if (!aggregatedPlayersDeviceSyncs[0]) {
      return <UnassignedLabel text="No deployment has been done yet" />;
    }

    return (
      <>
        <InfoAttribute
          name="Deployment"
          value={getDeploymentStatusIndicatorWithNameAndLink(aggregatedPlayersDeviceSyncsDeployments[0])}
        />

        <InfoAttribute name="When" value={formatAbsoluteDate(aggregatedPlayersDeviceSyncs[0].audit?.updated_at)} />
        <InfoAttribute name="Sync Status" value={getDeviceSyncStatusIndicator(aggregatedPlayersDeviceSyncs[0])} />
        <InfoAttribute
          name="Info"
          value={aggregatedPlayersDeviceSyncs[0].message}
          title={aggregatedPlayersDeviceSyncs[0].message}
        />
      </>
    );
  }, [
    isLoading,
    aggregatedPlayersDeviceSyncs,
    aggregatedPlayersDeviceSyncsDeploymentIds,
    aggregatedPlayersDeviceSyncsDeployments,
    player,
  ]);

  return (
    <InfoSection title="Last Sync" icon="pi pi-download">
      {infoBody}
    </InfoSection>
  );
};
