/// <reference types="zen-observable" />

import { AWSIoTProvider } from "@aws-amplify/pubsub";
import { type PubSubProvider } from "@aws-amplify/pubsub/lib-esm/types";
import { Amplify, PubSub as AmplifyPubSub } from "aws-amplify";

import { REACT_APP_IOT_ENDPOINT, REACT_APP_REGION } from "@ds/constants/environment";

interface PubSubServiceConfig {
  region: string;
  endpoint: string;
}

type PubSubObservable = {
  provider: PubSubProvider;
  value: string | Record<string, unknown> | IoTEntity;
};

class PubSubService {
  private subscription: ZenObservable.Subscription | null = null;

  private provider?: AWSIoTProvider;

  private config: PubSubServiceConfig = {
    region: REACT_APP_REGION,
    endpoint: REACT_APP_IOT_ENDPOINT,
  };

  configure(cfg?: PubSubServiceConfig) {
    if (!this.provider) {
      this.config = { ...this.config, ...cfg };
      this.provider = new AWSIoTProvider({
        aws_pubsub_region: this.config.region,
        aws_pubsub_endpoint: this.config.endpoint,
      });

      Amplify.addPluggable(this.provider);
    }
  }

  subscribe(
    subscriptionId: string,
    topics: string[],
    onData: (data: PubSubObservable) => void,
    onError: (data: Error) => void,
  ) {
    this.subscription = AmplifyPubSub.subscribe(topics, {
      clientId: subscriptionId,
      provider: "AWSIoTProvider",
    }).subscribe({
      next: onData,
      error: onError,
    });
  }

  unsubscribe() {
    if (this.subscription) {
      this.subscription.unsubscribe();
      this.subscription = null;
    }
  }
}

export const pubSubService = new PubSubService();
