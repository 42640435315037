export const convertImageToDataUrl = (
  url: string,
): Promise<{
  imgDataUrl: string;
  imgHeight: number;
  imgWidth: number;
}> =>
  new Promise(resolve => {
    const img = new Image();
    img.setAttribute("crossOrigin", "anonymous");
    img.onload = () => {
      const canvas = document.createElement("canvas") as HTMLCanvasElement;
      const context = canvas.getContext("2d");

      canvas.height = img.naturalHeight;
      canvas.width = img.naturalWidth;

      context?.drawImage(img, 0, 0);

      resolve({
        imgDataUrl: canvas.toDataURL("image/jpeg"),
        imgHeight: canvas.height,
        imgWidth: canvas.width,
      });
    };

    img.src = url;
  });
