import { useEffect, useMemo, type FC } from "react";

import { useGetAggregatedEntityIds } from "@ds/hooks/use-get-aggregated-entity-ids";

import { isAggregatedEntity } from "@ds/model/entity-model";

import { getExperienceStatusIndicator } from "@ds/modules/experiences/components/common/status-indicator";
import { selectExperiencesIsLoading, selectTableExperiences } from "@ds/modules/experiences/redux/selectors";
import { experiencesActions } from "@ds/modules/experiences/redux/slice";
import { DEFAULT_TABLE_TAB } from "@ds/modules/table-data/redux/slice";
import { DetailsTableDataTypeEnum } from "@ds/modules/table-data/utils/model";

import { DetailsCard } from "@ds/components/details-view";
import { MultipleValuesLabelWithLink, UnassignedLabel } from "@ds/components/forms/labels/common";
import { Loading } from "@ds/components/loaders/loading";
import { DetailsTable } from "@ds/components/table/details-table";
import {
  getEntityIdColumn,
  getEntityNameColumn,
  getGoToDetailsColumn,
  getLastUpdatedColumn,
  getStatusColumn,
} from "@ds/components/table/table-columns";
import { usePagination, useRootDispatch, useRootSelector, useTableDataSorting } from "@ds/hooks";
import { composeDetailsRouteByEntityType } from "@ds/utils/router";

export const LocationDetailsExperiencesCard: FC<{ location: Locality }> = ({ location }) => {
  const aggregatedLocationIds = useGetAggregatedEntityIds(location);
  const entities = useRootSelector(state =>
    selectTableExperiences(state, DetailsTableDataTypeEnum.LocationExperiences),
  );

  const isLoading = useRootSelector(selectExperiencesIsLoading);

  const [pagination, setPagination] = usePagination(
    DetailsTableDataTypeEnum.LocationExperiences,
    isAggregatedEntity(location) ? DEFAULT_TABLE_TAB : location.id.toString(),
    {
      locationId: aggregatedLocationIds,
    },
  );

  const [{ sortOrder, sortField }, setSorting] = useTableDataSorting(
    DetailsTableDataTypeEnum.LocationExperiences,
    isAggregatedEntity(location) ? DEFAULT_TABLE_TAB : location.id.toString(),
    {
      locationId: aggregatedLocationIds,
    },
  );

  const dispatch = useRootDispatch();
  useEffect(() => {
    if (aggregatedLocationIds.length) {
      dispatch(
        experiencesActions.fetchExperiences(
          { locationId: aggregatedLocationIds },
          { tableType: DetailsTableDataTypeEnum.LocationExperiences, shouldDisableSagaThrottling: true },
        ),
      );
    }
  }, [dispatch, aggregatedLocationIds]);

  const emptyLabel = useMemo(
    () =>
      entities.length ? (
        <MultipleValuesLabelWithLink to={composeDetailsRouteByEntityType(entities)} />
      ) : (
        <UnassignedLabel text="Location(s) has no experiences" />
      ),
    [entities],
  );

  return (
    <DetailsCard title="Experiences" icon="play">
      <Loading isLoading={isLoading}>
        {!isAggregatedEntity(location) && (
          <DetailsTable
            value={entities}
            pagination={pagination}
            onPageChange={setPagination}
            loading={isLoading}
            sortField={sortField}
            sortOrder={sortOrder}
            onSort={setSorting}
          >
            {getEntityIdColumn()}
            {getEntityNameColumn({ style: { flexGrow: 3 }, resizeable: false })}
            {getStatusColumn({ body: getExperienceStatusIndicator })}
            {getLastUpdatedColumn()}
            {getGoToDetailsColumn()}
          </DetailsTable>
        )}

        {isAggregatedEntity(location) && emptyLabel}
      </Loading>
    </DetailsCard>
  );
};
