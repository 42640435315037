export const PLAYER_WAITING_ONLINE_TIMEOUT = 15000;
export const SHOW_SCREENSHOT_ON_DETAILS_PAGE = false;

export enum PlayerCommands {
  REBOOT = "reboot",
  LOGS = "logs",
  SCREENSHOT = "screenshot",
  RESET_CONTENT = "reset_content",
}

export enum PlayerCommandsFriendlyNames {
  REBOOT = "Reboot",
  LOGS = "Logs",
  RESET_CONTENT = "Reset Content",
}
