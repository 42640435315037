import { useCallback, type FC } from "react";
import styled from "styled-components";

const Dot = styled.div<{ $isEnabled: boolean; $isActive: boolean }>`
  color: ${({ $isActive }) => ($isActive ? "var(--primaryColor100)" : "var(--darkSurfaceColor)")};
  cursor: ${({ $isActive, $isEnabled }) => ($isActive || !$isEnabled ? "default" : "pointer")};
  font-size: 2rem;
  line-height: 1;
  margin: 0 15px;
  opacity: ${({ $isActive }) => ($isActive ? 1 : 0.4)};
  text-shadow: ${({ $isActive }) => ($isActive ? "0 0 8px" : "none")};
  transition:
    opacity 1s ease,
    text-shadow 1s ease;
  will-change: opacity, text-shadow;
  user-select: none;
`;

export const WizardNavDot: FC<{
  isEnabled: boolean;
  isActive: boolean;
  onClick: () => void;
}> = ({ isEnabled, isActive, onClick }) => {
  const onClickHandler = useCallback(() => {
    if (isEnabled) {
      onClick();
    }
  }, [isEnabled, onClick]);

  return (
    <Dot $isEnabled={isEnabled} $isActive={isActive} onClick={onClickHandler}>
      &bull;
    </Dot>
  );
};
