import { useMemo, type FC } from "react";
import styled from "styled-components";

import { REACT_APP_PAGINATOR_ROWS_PER_PAGE } from "@ds/constants/environment";

import { Paginator, type PaginatorProps } from "./paginator";

const TotalSelected = styled.div<{ $isHidden?: boolean }>`
  flex-basis: 115px;
  opacity: ${({ $isHidden }) => ($isHidden ? 0 : 100)};
`;

const PaginatorWrapper = styled.div`
  border: none;
  margin: 0;
  padding: 0 15px;
  width: 100%;
  margin-top: auto;

  height: 60px;
  display: flex;
  justify-content: space-between;
  align-items: center;

  .p-paginator {
    margin: 0;
    padding: 0;

    button {
      color: var(--purple-light-color);
    }

    .p-paginator-current {
      cursor: auto;
    }

    .p-paginator-current,
    .p-paginator-pages {
      color: var(--textColor060);
      font-weight: 500;
    }

    .p-paginator-pages .p-paginator-page {
      background: var(--white-color-z);
      border-color: var(--white-color-z);
    }
  }

  div {
    height: 100%;
    display: flex;
    align-items: center;
  }
`;

export const MainTablePaginator: FC<Partial<PaginatorProps> & { isAlwaysVisible?: boolean }> = ({
  pagination,
  onPageChange,
  isAlwaysVisible = true,
}) => {
  const paginatorElement = useMemo(() => {
    if (!pagination || !onPageChange) {
      return null;
    }

    const totalEntries = pagination.total || 0;
    if (!totalEntries) {
      return null;
    }

    if (isAlwaysVisible || totalEntries > REACT_APP_PAGINATOR_ROWS_PER_PAGE[0]) {
      return (
        <Paginator
          pagination={pagination}
          onPageChange={onPageChange}
          rowsPerPageOptions={REACT_APP_PAGINATOR_ROWS_PER_PAGE}
        />
      );
    }

    return null;
  }, [isAlwaysVisible, pagination, onPageChange]);

  return (
    <PaginatorWrapper>
      <TotalSelected id="total-selected" />
      {paginatorElement}
      <TotalSelected />
    </PaginatorWrapper>
  );
};
