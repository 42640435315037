import { yupResolver } from "@hookform/resolvers/yup";
import { uniq } from "lodash";
import { useCallback, type ComponentProps, type FC } from "react";
import { type DeepPartial } from "react-hook-form";

import { useGetAggregatedEntityIds } from "@ds/hooks/use-get-aggregated-entity-ids";

import { EntityTypeName, isAggregatedEntity } from "@ds/model/entity-model";
import { StatusEnum } from "@ds/model/status-model";

import { updateValidationSchema } from "@ds/modules/experiences/utils/validation-schema";
import { selectLocationById } from "@ds/modules/locations/redux/selectors";
import { FormationCardRow } from "@ds/modules/settings/projects/components/formation/formation-card-row";
import { FormationControl } from "@ds/modules/settings/projects/components/forms/controls/formation-control";
import { selectTemplateExperienceFormationValidationData } from "@ds/modules/settings/projects/redux/selectors/experience-formation-selectors";
import { selectCurrentUserProjectId } from "@ds/modules/settings/users/redux/selectors";

import {
  DetailsCard,
  DetailsCardEditActionsMenu,
  DetailsCardRow,
  EditableDetailsCardDecorator,
  EditableDetailsCardRowDecorator,
  type EditableDetailsCardChildProps,
  type EditableDetailsCardRowChildProps,
} from "@ds/components/details-view";
import { AggregatedValue } from "@ds/components/entities";
import { MultipleValuesLabelWithLink, UnassignedLabel } from "@ds/components/forms/labels/common";
import { TextLink } from "@ds/components/text-link";
import { useDeepRootSelector, useRootSelector } from "@ds/hooks";
import { formatRelativeDate } from "@ds/utils/date";
import { isMultipleFieldValuesInAggregatedEntity } from "@ds/utils/entities";
import { getProperty } from "@ds/utils/properties";
import { composeDetailsRouteByEntityIds } from "@ds/utils/router";

import { selectExperiencesByIds } from "../../redux/selectors";
import { getExperienceStatusIndicator } from "../common/status-indicator";
import { ExperienceFormationControlDecorator } from "../forms/controls/experience-formation-control-decorator";
import { ExperienceLocationControl } from "../forms/controls/experience-location-control";
import { ExperienceNameControl } from "../forms/controls/experience-name-control";
import { ExperienceDetailsInfoCardActionsMenu } from "./experience-details-info-card-actions-menu";

const ExperienceDetailsLocationCardRow: FC<{ cardProps: EditableDetailsCardChildProps<Experience> }> = ({
  cardProps,
}) => {
  const aggregatedExperienceIds = useGetAggregatedEntityIds(cardProps.entity);
  const aggregatedExperienceLocationsIds = useDeepRootSelector(state => {
    const aggregatedExperiences = selectExperiencesByIds(state, aggregatedExperienceIds);
    return uniq(aggregatedExperiences.map(({ location_id }) => location_id).filter(_ => _));
  });

  const location = useRootSelector(state => selectLocationById(state, cardProps.entity.location_id));

  const getDetailsBody = useCallback(
    (rowProps: EditableDetailsCardRowChildProps) => {
      const hasMultipleValues = isMultipleFieldValuesInAggregatedEntity(cardProps.entity, "location_id");
      if (!aggregatedExperienceLocationsIds.length) {
        return <UnassignedLabel text="Experience(s) has no assigned location(s)" />;
      } else if (hasMultipleValues && !rowProps.isDirty) {
        return (
          <MultipleValuesLabelWithLink
            to={composeDetailsRouteByEntityIds(aggregatedExperienceLocationsIds, EntityTypeName.LOCATION)}
          />
        );
      }

      return (
        <TextLink
          to={composeDetailsRouteByEntityIds(
            rowProps.isDirty ? cardProps.entity.location_id : aggregatedExperienceLocationsIds,
            EntityTypeName.LOCATION,
          )}
        >
          <AggregatedValue entity={location} field="name" shouldShowEditedValue={rowProps.isDirty} />
        </TextLink>
      );
    },
    [cardProps.entity, location, aggregatedExperienceLocationsIds],
  );

  return (
    <EditableDetailsCardRowDecorator
      field="location_id"
      input={<ExperienceLocationControl control={cardProps.control} autoFocus excludeLabel />}
      {...cardProps}
    >
      {rowProps => (
        <DetailsCardRow label="Location" editableSettings={rowProps} isRequired>
          {getDetailsBody(rowProps)}
        </DetailsCardRow>
      )}
    </EditableDetailsCardRowDecorator>
  );
};

export const ExperienceDetailsInfoCard: FC<{
  experience: Experience;
  templateProperties: [string, TemplateFormationProperty][];
  onSubmit: (changedFields: DeepPartial<Experience>) => void;
}> = ({ experience, templateProperties, onSubmit }) => {
  const projectId = useRootSelector(selectCurrentUserProjectId);
  const validationData = useDeepRootSelector(state =>
    selectTemplateExperienceFormationValidationData(state, projectId, "details", templateProperties),
  );

  return (
    <EditableDetailsCardDecorator
      initialValue={experience}
      validationResolver={yupResolver(updateValidationSchema.info(validationData))}
      onSubmit={onSubmit}
    >
      {cardProps => (
        <DetailsCard
          title="Info"
          icon="info-circle"
          actions={
            !cardProps.isDirty ? (
              <ExperienceDetailsInfoCardActionsMenu experience={cardProps.entity} />
            ) : (
              <DetailsCardEditActionsMenu
                isEditing={cardProps.isEditing}
                isDirty={cardProps.isDirty}
                onReset={cardProps.onResetForm}
                onSubmit={cardProps.handleSubmit(cardProps.onSubmitForm)}
              />
            )
          }
        >
          <EditableDetailsCardRowDecorator
            field="name"
            input={<ExperienceNameControl control={cardProps.control} autoFocus excludeLabel />}
            shouldHideEdit={isAggregatedEntity(cardProps.entity)}
            {...cardProps}
          >
            {rowProps => (
              <DetailsCardRow label="Name" editableSettings={rowProps} isRequired>
                <AggregatedValue entity={cardProps.entity} field="name" shouldShowEditedValue={rowProps.isDirty} />
              </DetailsCardRow>
            )}
          </EditableDetailsCardRowDecorator>

          <DetailsCardRow label="Status">
            <AggregatedValue
              entity={cardProps.entity}
              field="status"
              formatter={entity => getExperienceStatusIndicator(entity)}
            />
          </DetailsCardRow>

          {cardProps.entity.status !== StatusEnum.Ok && (
            <DetailsCardRow label="Message">
              <AggregatedValue entity={cardProps.entity} field="status_message" />
            </DetailsCardRow>
          )}

          <ExperienceDetailsLocationCardRow cardProps={cardProps} />

          {templateProperties.map(([field, property]) => (
            <EditableDetailsCardRowDecorator
              key={`experience-formation-property-${field}`}
              field={`formation.properties.${field}`}
              input={
                <ExperienceFormationControlDecorator
                  field={field}
                  control={cardProps.control}
                  setValue={cardProps.setValue}
                  property={property}
                >
                  {(props: ComponentProps<typeof FormationControl>) => (
                    <FormationControl autoFocus excludeLabel {...props} />
                  )}
                </ExperienceFormationControlDecorator>
              }
              {...cardProps}
            >
              {rowProps => (
                <FormationCardRow
                  entity={cardProps.entity}
                  field={field}
                  property={property}
                  editableSettings={rowProps}
                />
              )}
            </EditableDetailsCardRowDecorator>
          ))}

          <DetailsCardRow label="Last Updated">
            <AggregatedValue
              entity={cardProps.entity}
              field="audit.updated_at"
              formatter={(entity, field) => formatRelativeDate(getProperty(entity, field))}
            />
          </DetailsCardRow>
        </DetailsCard>
      )}
    </EditableDetailsCardDecorator>
  );
};
