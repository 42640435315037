import { useCallback, useState, type FC } from "react";
import styled from "styled-components";

import { useDropzoneRef } from "@ds/modules/content/assets/hooks/use-dropzone-ref";
import { DeploymentTypeEnum } from "@ds/modules/deployments/utils/model";

import { DndDropzone } from "@ds/components/dnd-dropzone";
import { Button, ButtonType } from "@ds/components/forms/buttons";
import { IconType } from "@ds/components/icons";
import { ProgressBar } from "@ds/components/progress-bar";
import { WizardPage, type WizardChildStepProps } from "@ds/components/wizard";
import { storageService, type ProgressInfo } from "@ds/services/storage";

import { useCreateDeployment } from "./create-deployment-context";

const Wrapper = styled.div`
  width: 400px;
  display: flex;
  flex-flow: column;
  align-items: center;

  margin: 50px auto;

  > p {
    margin-top: 15px;
    font-size: 0.95rem;
    color: var(--textColor030);
  }
`;

const FileInfo = styled.div`
  margin-top: 30px;
  min-width: 250px;

  > p {
    margin: 4px 0 20px;
  }
`;

const FileWrapper = styled.div`
  width: 100%;
  display: flex;
  flex-flow: row;
  justify-content: space-between;
  align-items: center;
`;

const ProgressBarWrapper = styled(ProgressBar)`
  margin-top: 10px;
  height: 6px;
`;

const UploadCheckIcon = styled.i`
  color: var(--positiveColor);
`;

export const Step3Upload: FC<WizardChildStepProps> = ({ nextStep, previousStep, ...rest }) => {
  const [currentDeployment, submitDeployment] = useCreateDeployment();
  const [dropzoneRef, openDropzone] = useDropzoneRef();
  const [progressInfo, setProgressInfo] = useState<ProgressInfo>();
  const [fileName, setFileName] = useState<string>();
  const [fileKey, setFileKey] = useState<string>();

  const saveDeployment = useCallback(() => {
    if (fileKey && fileName) {
      submitDeployment({
        ...currentDeployment,
        params: {
          file_key: fileKey,
          file_name: fileName,
        },
      });
    }
  }, [submitDeployment, currentDeployment, fileKey, fileName]);

  const onNextClickHandler = useCallback(() => {
    saveDeployment();
    if (nextStep) {
      nextStep();
    }
  }, [nextStep, saveDeployment]);

  const onBackClickHandler = useCallback(() => {
    saveDeployment();
    if (previousStep) {
      previousStep();
    }
  }, [previousStep, saveDeployment]);

  const uploadFile = useCallback(
    async (file: File) => {
      const prefix = currentDeployment.type === DeploymentTypeEnum.Code ? "applets" : "firmware";
      const uniqueKey = `${prefix}/${Date.now()}_${file.name}`;
      const { key } = await storageService.put(uniqueKey, file, {
        level: "public",
        contentType: file.type,
        progressCallback: setProgressInfo,
      });

      setFileKey(key);
    },
    [currentDeployment],
  );

  const onUploadHandler = useCallback(
    (files: File[]) => {
      if (files.length) {
        const file = files[0];
        setFileName(file.name);
        uploadFile(file);
      }
    },
    [uploadFile],
  );

  const progressPercent =
    progressInfo?.loaded && progressInfo?.total ? Math.floor((progressInfo.loaded / progressInfo.total) * 100) : 0;

  return (
    <WizardPage
      {...rest}
      title={`${currentDeployment.type} Deployment Info`}
      nextStep={onNextClickHandler}
      previousStep={onBackClickHandler}
      isValid={progressPercent === 100}
    >
      <DndDropzone
        dropzoneRef={dropzoneRef}
        acceptMediaTypes={{
          "application/zip": [".zip"],
        }}
        onUpload={onUploadHandler}
      >
        <Wrapper>
          <Button
            label="Upload"
            buttonType={ButtonType.Outlined}
            icon={IconType.Upload}
            iconPosition="right"
            className="w-8 align-self-center"
            onClick={openDropzone}
          />
          <p>or drop files here</p>

          {!!fileName && (
            <FileInfo>
              <FileWrapper>
                <p>{fileName}</p>
                {progressPercent === 100 && <UploadCheckIcon className="pi pi-check" />}
              </FileWrapper>

              {progressPercent < 100 && <ProgressBarWrapper value={progressPercent} />}
            </FileInfo>
          )}
        </Wrapper>
      </DndDropzone>
    </WizardPage>
  );
};
