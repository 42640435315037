import { useIsMounted } from "@react-hookz/web";
import { useCallback, useState, type FC, type FocusEvent } from "react";

import { AutoComplete, type SingleAutoCompleteProps } from "@ds/components/forms/autocomplete";
import {
  GoogleSearchTypesEnum,
  useFetchGoogleAddressDetails,
  useFetchGoogleAddressTimeZone,
  useFetchGoogleAddressesDebouncedAutocomplete,
} from "@ds/services/google-api";

import { parseGooglePeriods, parseGooglePlaceData, parseGoogleTimeZoneData } from "../utils/locality-helpers";

export type AddressData = {
  address: Address;
  open_hours: LocalityOpeningHours[];
  time_zone: LocalityTimeZone;
};

export const LocationAddressesAutoComplete: FC<
  Omit<SingleAutoCompleteProps<string>, "onChange" | "isLoading"> & {
    searchType: GoogleSearchTypesEnum;
    countriesFilter: string[];
    onChange(data: AddressData | null): void;
  }
> = ({ field, placeholder, inputRef, value, searchType, autoFocus, countriesFilter, onChange, onComplete, onBlur }) => {
  const [currentValue, setCurrentValue] = useState(value);
  const isMounted = useIsMounted();
  const [, detailsActions] = useFetchGoogleAddressDetails();
  const [, timezoneActions] = useFetchGoogleAddressTimeZone();
  const [
    suggestions,
    onCompleteHandler,
    {
      state: { status: remoteStatus },
    },
  ] = useFetchGoogleAddressesDebouncedAutocomplete({
    queryFilter: countriesFilter,
    types: [searchType],
  });

  const onCompleteWrapperHandler = useCallback(
    (searchQuery: string) => {
      onCompleteHandler(searchQuery);
      if (onComplete) {
        onComplete(searchQuery);
      }
    },
    [onComplete, onCompleteHandler],
  );

  const onChangeHandler = useCallback(
    async (data: google.maps.places.AutocompletePrediction | null) => {
      const [mapElement] = document.getElementsByClassName("google-address-map");
      if (data && mapElement) {
        const place = await detailsActions.execute(data.place_id, mapElement);
        const timezoneResult = await timezoneActions.execute(
          `${place.geometry?.location?.lat()},${place.geometry?.location?.lng()}`,
        );

        if (isMounted()) {
          const organization =
            searchType === GoogleSearchTypesEnum.ESTABLISHMENT ? data.structured_formatting.main_text : "";
          const address = parseGooglePlaceData(organization, place);
          const openHours = parseGooglePeriods(place.opening_hours?.periods);
          const timezone = parseGoogleTimeZoneData(timezoneResult);

          onChange({ address, open_hours: openHours, time_zone: timezone });
          setCurrentValue(
            searchType === GoogleSearchTypesEnum.ESTABLISHMENT ? address.organization : address.street_address?.[0],
          );
        }
      } else {
        onChange(null);
      }
    },
    [detailsActions, timezoneActions, searchType, isMounted, onChange],
  );

  const onBlurHandler = useCallback(
    (e: FocusEvent<HTMLInputElement>) => {
      if (!e.relatedTarget?.classList.contains("p-autocomplete-item") && currentValue !== value) {
        onChange(null);
      }

      onBlur?.(e);
    },
    [currentValue, value, onBlur, onChange],
  );

  return (
    <AutoComplete
      field={field}
      placeholder={placeholder}
      inputRef={inputRef}
      value={value}
      suggestions={suggestions}
      autoFocus={autoFocus}
      dropdown={false}
      shouldSelectedOptionBePassedFromParent
      isLoading={remoteStatus === "loading"}
      onBlur={onBlurHandler}
      onChange={onChangeHandler}
      onComplete={onCompleteWrapperHandler}
    />
  );
};
