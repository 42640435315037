import { type FC, type ReactNode } from "react";
import { createPortal } from "react-dom";
import styled from "styled-components";

import { SpinnerIcon } from "./loaders";

const Wrapper = styled.div`
  position: relative;
  width: 100%;
  height: 100%;
  display: flex;
  flex-flow: column;
`;

const LoadingWrapper = styled.div<{ $isVisible: boolean }>`
  position: absolute;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  background: var(--whiteShadowColor050);
  color: var(--primaryColor100);
  z-index: ${({ $isVisible }) => ($isVisible ? 1000 : -1)};
  opacity: ${({ $isVisible }) => ($isVisible ? 100 : 0)};
  transition: all 0.3s ease-out;

  i {
    z-index: ${({ $isVisible }) => ($isVisible ? 1001 : -1)};
    font-size: 2rem;
  }
`;

export const Loading: FC<{
  isLoading: boolean;
  className?: string;
  children?: ReactNode;
}> = ({ isLoading, children, className }) => {
  return (
    <Wrapper className={className}>
      <LoadingWrapper $isVisible={isLoading}>
        <SpinnerIcon />
      </LoadingWrapper>
      {children}
    </Wrapper>
  );
};

const AbsoluteWrapper = styled.div`
  position: absolute;
  z-index: 10000;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
`;

export const AbsoluteLoader: FC<{ isLoading: boolean }> = ({ isLoading }) => (
  <>
    {isLoading &&
      createPortal(
        <AbsoluteWrapper>
          <Loading isLoading />
        </AbsoluteWrapper>,
        document.body,
      )}
  </>
);
