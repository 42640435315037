import { type FC } from "react";
import { Controller, type Control } from "react-hook-form";

import { SpanWrapper } from "@ds/components/forms/labels/span-wrapper";

import { CalendarView } from "./schedule/calendar-view";

export const DeploymentScheduleDateControl: FC<{ control: Control<{ startAt: Date }> }> = ({ control }) => {
  return (
    <Controller
      name="startAt"
      control={control}
      render={({ field: { value, onChange }, fieldState }) => (
        <SpanWrapper className="mt-6" error={fieldState.error} isRequired>
          <CalendarView initialDate={value} onChange={onChange} />
        </SpanWrapper>
      )}
    />
  );
};
