import { useCallback, useMemo, type FC } from "react";
import { useForm, type DeepPartial } from "react-hook-form";
import styled from "styled-components";

import { useGetChangedFieldsOnSubmitHandlerDecorator, useGetHandleSubmit } from "@ds/hooks/redux-forms-hooks";
import { useGetAggregatedEntityIds } from "@ds/hooks/use-get-aggregated-entity-ids";

import { Button, ButtonActionType, ButtonType } from "@ds/components/forms/buttons";
import { IconType } from "@ds/components/icons";
import { Dialog } from "@ds/components/modals";
import { useRootDispatch } from "@ds/hooks";

import { playlistAssetsActions } from "../../redux/slice";
import { PlaylistAssetDurationControl } from "../controls/playlist-asset-duration-control";

const ContentWrapper = styled.div`
  min-height: 200px;
  width: 500px;
`;

const FooterWrapper = styled.div`
  display: flex;
  justify-content: right;
`;

export const UpdatePlaylistAssetModal: FC<{
  initialValue: PlaylistAsset;
  onClose: () => void;
}> = ({ initialValue, onClose }) => {
  const aggregatedPlaylistAssetIds = useGetAggregatedEntityIds(initialValue);

  const {
    control,
    trigger,
    getValues,
    formState: { errors, dirtyFields },
  } = useForm<PlaylistAsset>({
    mode: "all",
    reValidateMode: "onChange",
    defaultValues: initialValue,
  });

  const dispatch = useRootDispatch();
  const handleSubmit = useGetHandleSubmit({
    getValues,
    dirtyFields,
    trigger,
    errors,
  });

  const onSubmitHandler = useCallback(
    async (changedFields: DeepPartial<PlaylistAsset>) => {
      dispatch(playlistAssetsActions.updatePlaylistAssets(aggregatedPlaylistAssetIds, changedFields));
      onClose();
    },
    [dispatch, aggregatedPlaylistAssetIds, onClose],
  );

  const onSubmitHandlerDecorator = useGetChangedFieldsOnSubmitHandlerDecorator({
    dirtyFields,
    onSubmit: onSubmitHandler,
  });

  const renderFooter = useMemo(
    () => (
      <FooterWrapper>
        <Button
          label="Cancel"
          ariaLabel="cancel button"
          buttonType={ButtonType.Outlined}
          actionType={ButtonActionType.Secondary}
          icon={IconType.Close}
          iconPosition="right"
          onClick={onClose}
        />

        <Button
          label="Update"
          ariaLabel="update button"
          buttonType={ButtonType.Raised}
          icon={IconType.Save}
          iconPosition="right"
          onClick={handleSubmit(onSubmitHandlerDecorator)}
        />
      </FooterWrapper>
    ),
    [onClose, handleSubmit, onSubmitHandlerDecorator],
  );

  return (
    <Dialog modal visible header="Update playlist asset(s)" footer={renderFooter} onHide={onClose}>
      <ContentWrapper>
        <PlaylistAssetDurationControl control={control} />
      </ContentWrapper>
    </Dialog>
  );
};
