import { createSlice, type PayloadAction } from "@reduxjs/toolkit";
import { all } from "redux-saga/effects";

import { DeviceTableTypeEnum } from "../utils/model";
import { watchCommon } from "./sagas";

interface DevicesState {
  viewType: DeviceTableTypeEnum | undefined;
}

const initialState: DevicesState = {
  viewType: undefined,
};

const sliceType = "DEVICES";
const slice = createSlice({
  name: sliceType,
  initialState,
  reducers: {
    switchView: (state, { payload }: PayloadAction<DeviceTableTypeEnum>) => {
      state.viewType = payload;
    },
  },
});

export const { name: devicesType, actions: devicesActions, reducer: devicesReducer } = slice;

export function* rootDevicesSaga() {
  yield all([watchCommon()]);
}
