import { createStateContext } from "react-use";

import { createPlayerModel } from "../../../utils/model";

const [useCreatePlayer, CreatePlayerProvider] = createStateContext<PlayerFormData>({
  player: createPlayerModel(),
  pairingCode: "",
});

export { useCreatePlayer, CreatePlayerProvider };
