import { yupResolver } from "@hookform/resolvers/yup";
import { type FC } from "react";
import { type DeepPartial } from "react-hook-form";

import { isAggregatedEntity } from "@ds/model/entity-model";

import {
  DetailsCard,
  DetailsCardEditActionsMenu,
  DetailsCardRow,
  EditableDetailsCardDecorator,
  EditableDetailsCardRowDecorator,
} from "@ds/components/details-view";
import { AggregatedValue } from "@ds/components/entities";
import { DescriptionControl } from "@ds/components/forms/control/description-control";
import { formatRelativeDate } from "@ds/utils/date";
import { getProperty } from "@ds/utils/properties";

import { updateValidationSchema } from "../../utils/validation-schema";
import { LocationNameControl } from "../forms/controls/location-name-control";
import { LocationStoreIdControl } from "../forms/controls/location-store-id-control";
import { LocationDetailsInfoCardActionsMenu } from "./location-details-info-card-actions-menu";

export const LocationDetailsInfoCard: FC<{
  location: Locality;
  onSubmit: (changedFields: DeepPartial<Locality>) => void;
}> = ({ location, onSubmit }) => (
  <EditableDetailsCardDecorator
    initialValue={location}
    validationResolver={yupResolver(updateValidationSchema.info)}
    onSubmit={onSubmit}
  >
    {cardProps => (
      <DetailsCard
        title="Info"
        icon="info-circle"
        actions={
          !cardProps.isDirty && !cardProps.isEditing ? (
            <LocationDetailsInfoCardActionsMenu location={location} />
          ) : (
            <DetailsCardEditActionsMenu
              isEditing={cardProps.isEditing}
              isDirty={cardProps.isDirty}
              onReset={cardProps.onResetForm}
              onSubmit={cardProps.handleSubmit(cardProps.onSubmitForm)}
            />
          )
        }
      >
        <EditableDetailsCardRowDecorator
          field="name"
          input={<LocationNameControl control={cardProps.control} autoFocus excludeLabel />}
          shouldHideEdit={isAggregatedEntity(cardProps.entity)}
          {...cardProps}
        >
          {rowProps => (
            <DetailsCardRow label="Name" editableSettings={rowProps} isRequired>
              <AggregatedValue entity={cardProps.entity} field="name" shouldShowEditedValue={rowProps.isDirty} />
            </DetailsCardRow>
          )}
        </EditableDetailsCardRowDecorator>

        <EditableDetailsCardRowDecorator
          field="store_id"
          input={<LocationStoreIdControl control={cardProps.control} autoFocus excludeLabel />}
          {...cardProps}
        >
          {rowProps => (
            <DetailsCardRow label="Store ID" editableSettings={rowProps}>
              <AggregatedValue entity={cardProps.entity} field="store_id" shouldShowEditedValue={rowProps.isDirty} />
            </DetailsCardRow>
          )}
        </EditableDetailsCardRowDecorator>

        <EditableDetailsCardRowDecorator
          field="description"
          input={<DescriptionControl control={cardProps.control} autoFocus excludeLabel />}
          {...cardProps}
        >
          {rowProps => (
            <DetailsCardRow label="Description" editableSettings={rowProps}>
              <AggregatedValue entity={cardProps.entity} field="description" shouldShowEditedValue={rowProps.isDirty} />
            </DetailsCardRow>
          )}
        </EditableDetailsCardRowDecorator>

        <DetailsCardRow label="Time zone">
          <AggregatedValue entity={cardProps.entity} field="time_zone.time_zone_id" />
        </DetailsCardRow>

        <DetailsCardRow label="Last Updated">
          <AggregatedValue
            entity={cardProps.entity}
            field="audit.updated_at"
            formatter={(entity, field) => formatRelativeDate(getProperty(entity, field))}
          />
        </DetailsCardRow>
      </DetailsCard>
    )}
  </EditableDetailsCardDecorator>
);
