import { yupResolver } from "@hookform/resolvers/yup";
import { useCallback, useEffect, useMemo, type ComponentProps, type FC } from "react";
import { useForm } from "react-hook-form";

import { createValidationSchema } from "@ds/modules/experiences/utils/validation-schema";
import { FormationControl } from "@ds/modules/settings/projects/components/forms/controls/formation-control";
import { selectTemplateExperienceFormationValidationData } from "@ds/modules/settings/projects/redux/selectors/experience-formation-selectors";
import { selectCurrentUserProjectId } from "@ds/modules/settings/users/redux/selectors";

import { WizardPage, type WizardChildStepProps } from "@ds/components/wizard";
import { useDeepRootSelector, useRootSelector } from "@ds/hooks";

import { ExperienceFormationControlDecorator } from "../controls/experience-formation-control-decorator";
import { useCreateExperienceContext } from "./create-experience-context";

export const Step2Formation: FC<
  WizardChildStepProps & {
    group: number;
    templateProperties: [string, TemplateFormationProperty][];
  }
> = ({ isActive, group, templateProperties, nextStep, previousStep, ...rest }) => {
  const { experience, setFormData } = useCreateExperienceContext();

  const projectId = useRootSelector(selectCurrentUserProjectId);
  const validationData = useDeepRootSelector(state =>
    selectTemplateExperienceFormationValidationData(state, projectId, "wizard", [group]),
  );

  const validationSchema = useMemo(() => createValidationSchema.getStep2(validationData), [validationData]);
  const { control, setValue, getValues, handleSubmit, reset } = useForm<Experience>({
    mode: "all",
    reValidateMode: "onChange",
    defaultValues: experience,
    resolver: yupResolver(validationSchema),
  });

  useEffect(() => {
    if (isActive) {
      reset(experience);
    }
  }, [experience, isActive, reset]);
  const onBackClickHandler = useCallback(() => {
    setFormData({ experience: getValues() });
    if (previousStep) {
      previousStep();
    }
  }, [previousStep, getValues, setFormData]);

  const onNextClickHandler = useCallback(() => {
    setFormData({ experience: getValues() });
    if (nextStep) {
      nextStep();
    }
  }, [nextStep, getValues, setFormData]);

  return (
    <WizardPage
      {...rest}
      title={templateProperties[0][1].metadata?.wizard?.group_title}
      previousStep={onBackClickHandler}
      nextStep={handleSubmit(onNextClickHandler)}
    >
      {templateProperties.map(([field, property]) => (
        <ExperienceFormationControlDecorator
          key={`experience-formation-control-${field}`}
          field={field}
          control={control}
          setValue={setValue}
          property={property}
        >
          {(props: ComponentProps<typeof FormationControl>) => <FormationControl {...props} />}
        </ExperienceFormationControlDecorator>
      ))}
    </WizardPage>
  );
};
