import { yupResolver } from "@hookform/resolvers/yup";
import { isNumber, uniq } from "lodash";
import { useCallback, useEffect, useMemo, type FC } from "react";
import { type DeepPartial } from "react-hook-form";

import { useGetAggregatedEntityIds } from "@ds/hooks/use-get-aggregated-entity-ids";

import { EntityTypeName } from "@ds/model/entity-model";

import { getExperienceStatusIndicatorWithNameAndLink } from "@ds/modules/experiences/components/common/status-indicator";
import { selectExperienceById, selectExperiencesIsLoading } from "@ds/modules/experiences/redux/selectors";
import { experiencesActions } from "@ds/modules/experiences/redux/slice";

import {
  DetailsCard,
  DetailsCardEditActionsMenu,
  DetailsCardRow,
  EditableDetailsCardDecorator,
  EditableDetailsCardRowDecorator,
} from "@ds/components/details-view";
import { MultipleValuesLabelWithLink, UnassignedLabel } from "@ds/components/forms/labels/common";
import { Loading } from "@ds/components/loaders/loading";
import { useDeepRootSelector, useRootDispatch, useRootSelector } from "@ds/hooks";
import { isMultipleFieldValuesInAggregatedEntity } from "@ds/utils/entities";
import { composeDetailsRouteByEntityIds } from "@ds/utils/router";

import { selectPlayersByIds } from "../../redux/selectors";
import { updateValidationSchema } from "../../utils/validation-schema";
import { PlayerExperienceControl } from "../forms/controls/player-experience-control";

const PlayerDetailsExperienceCardRow: FC<{ player: Player; isEditing: boolean }> = ({ player, isEditing }) => {
  const aggregatedPlayerIds = useGetAggregatedEntityIds(player);
  const aggregatedPlayersExperienceIds = useDeepRootSelector(state => {
    const aggregatedPlayers = selectPlayersByIds(state, aggregatedPlayerIds);
    return uniq(aggregatedPlayers.map(({ experience_id }) => experience_id)).filter(isNumber);
  });

  const experience = useRootSelector(state => selectExperienceById(state, player.experience_id || 0));
  const isLoading = useRootSelector(selectExperiencesIsLoading);

  const dispatch = useRootDispatch();
  useEffect(() => {
    if (player.experience_id && !isEditing) {
      dispatch(experiencesActions.fetchExperience(player.experience_id));
    }
  }, [dispatch, player.experience_id, isEditing]);

  const emptyLabel = useMemo(
    () =>
      isMultipleFieldValuesInAggregatedEntity(player, "experience_id") ? (
        <MultipleValuesLabelWithLink
          to={composeDetailsRouteByEntityIds(aggregatedPlayersExperienceIds, EntityTypeName.EXPERIENCE)}
        />
      ) : (
        <UnassignedLabel text="Player(s) has no experience" />
      ),
    [player, aggregatedPlayersExperienceIds],
  );

  return (
    <Loading isLoading={isLoading}>
      {experience && (
        <>
          <DetailsCardRow label="Name">{getExperienceStatusIndicatorWithNameAndLink(experience)}</DetailsCardRow>
          <DetailsCardRow label="Status Message">{experience.status_message}</DetailsCardRow>
        </>
      )}

      {!experience && emptyLabel}
    </Loading>
  );
};

export const PlayerDetailsExperienceCard: FC<{
  player: Player;
  onSubmit: (changedFields: DeepPartial<Player>) => void;
}> = ({ player, onSubmit }) => {
  const initialExperience = useRootSelector(state => selectExperienceById(state, player.experience_id || 0));

  const onSubmitHandler = useCallback(
    (changedFields: DeepPartial<PlayerFormData>) => onSubmit(changedFields.player || {}),
    [onSubmit],
  );

  return (
    <EditableDetailsCardDecorator
      initialValue={{ experience: initialExperience, player: player } as PlayerFormData}
      validationResolver={yupResolver(updateValidationSchema.experience)}
      onSubmit={onSubmitHandler}
    >
      {cardProps => (
        <DetailsCard
          title="Experience"
          icon="play"
          actions={
            <DetailsCardEditActionsMenu
              isEditing={cardProps.isEditing}
              isDirty={cardProps.isDirty}
              onEdit={cardProps.onEditRow("player.experience_id")}
              onReset={cardProps.onResetForm}
              onSubmit={cardProps.handleSubmit(cardProps.onSubmitForm)}
            />
          }
        >
          <EditableDetailsCardRowDecorator
            field="player.experience_id"
            input={
              <PlayerExperienceControl
                control={cardProps.control}
                setValue={cardProps.setValue}
                autoFocus
                excludeLabel
              />
            }
            shouldEditWholeCard
            {...cardProps}
          >
            {rowProps => (
              <DetailsCardRow label="Choose experience" editableSettings={rowProps}>
                <PlayerDetailsExperienceCardRow player={cardProps.entity.player} isEditing={cardProps.isEditing} />
              </DetailsCardRow>
            )}
          </EditableDetailsCardRowDecorator>
        </DetailsCard>
      )}
    </EditableDetailsCardDecorator>
  );
};
