import { produce } from "immer";

import { normalizeBase } from "@ds/model/helpers";

import { isProjectQueryOutput, isProjectArray } from "./model";

const normalizer = (entity: Project) =>
  produce(normalizeBase(entity), (draft: Project) => {
    draft.description = entity.description || undefined;
    draft.template.experience_formation.properties = Object.fromEntries(
      Object.entries(draft.template.experience_formation.properties).filter(
        ([_, v]: [string, ApiBaseFormationProperty]) => !v.hidden && !v.deprecated,
      ),
    );
  });

export const normalize = ((
  entityOrEntities: Project | Project[] | QueryOutput<Project>,
): Project | Project[] | QueryOutput<Project> => {
  if (isProjectQueryOutput(entityOrEntities)) {
    return produce(entityOrEntities, draft => {
      draft.items = entityOrEntities.items.map(normalizer);
    });
  }

  return isProjectArray(entityOrEntities) ? entityOrEntities.map(normalizer) : normalizer(entityOrEntities);
}) as {
  (Project: Project): Project;
  (Projects: Project[]): Project[];
  (ProjectsQueryOutput: QueryOutput<Project>): QueryOutput<Project>;
};
