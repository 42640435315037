import { isArray } from "lodash";

import { EntityTypeName, isEntity } from "@ds/model/entity-model";

import { isPlainObject } from "@ds/utils/type-guards/common-guards";

export interface PlayersByExperienceGroup {
  readonly assigned_to_status: "unassigned" | "assigned";
  players: Player[];
}

export type PlayersByExperienceGroupList = [PlayersByExperienceGroup, PlayersByExperienceGroup];

export enum ThingTypeEnum {
  Electron = "Electron",
  BrightSign = "BrightSign",
  Bluefin = "Bluefin",
  AudioAuthority = "AudioAuthority",
  TV = "TV",
  ButtonsPad = "ButtonsPad",
  Audio = "Audio",
  Receiver = "Receiver",
  Unknown = "Device",
}

export enum PlayerStateEnum {
  Unpaired = "unpaired",
  Paired = "paired",
  Offline = "offline",
  Online = "online",
}

export type ThingType = keyof typeof ThingTypeEnum;
export type PlayerState = Lowercase<keyof typeof PlayerStateEnum>;

export const createPlayerModel = (options?: Partial<Player>): Player => ({
  __typename: EntityTypeName.DEVICE_PLAYER,
  id: options?.id || 0,
  project_id: options?.project_id || 0,
  experience_order: 0,
  name: options?.name ?? "",
  thing_name: "",
  thing_type: options?.thing_type || ThingTypeEnum.BrightSign,
  state: PlayerStateEnum.Unpaired,
  make: "",
  model: "",
  mac_address: "",
  ip_address: "",
  serial_number: "",
});

export const isPlayer = (obj: unknown): obj is Player =>
  isEntity(obj) && obj.__typename === EntityTypeName.DEVICE_PLAYER;

export const isPlayerArray = (obj: unknown): obj is Player[] => isArray(obj) && obj.every(isPlayer);

export const isPlayerQueryOutput = (obj: unknown): obj is QueryOutput<Player> =>
  isPlainObject(obj) && "items" in obj && isPlayerArray(obj.items);
