import { type FC } from "react";

import { StatusEnum } from "@ds/model/status-model";

import { PlayerStateEnum } from "@ds/modules/devices/players/utils/model";

import { StatusMessage } from "@ds/components/status-message";
import { WizardFinalStepWrapper, WizardPage, type WizardChildStepProps } from "@ds/components/wizard";
import { useRootSelector } from "@ds/hooks";

import { selectLastPairedPlayer, selectPlayersError, selectPlayersIsLoading } from "../../../redux/selectors";

export const StepFinal: FC<WizardChildStepProps & { successMessage: string }> = ({
  firstStep,
  successMessage,
  ...rest
}) => {
  const isSaving = useRootSelector(selectPlayersIsLoading);
  const errorMessage = useRootSelector(selectPlayersError);
  const lastPaired = useRootSelector(selectLastPairedPlayer);

  let stepBody = <StatusMessage status={StatusEnum.Success} message={successMessage} />;
  if (lastPaired && lastPaired.state !== PlayerStateEnum.Online) {
    stepBody = (
      <StatusMessage
        status={StatusEnum.Info}
        message="Player has been paired, but appears to be currently offline. Once it is brought back online, the information on player will be updated automatically. No other action is required now."
      />
    );
  }

  if (errorMessage) {
    stepBody = <StatusMessage status={StatusEnum.Failed} message={errorMessage} />;
  }

  return (
    <WizardPage {...rest} isValid={!errorMessage} isLoading={isSaving} previousStep={firstStep}>
      <WizardFinalStepWrapper>{stepBody}</WizardFinalStepWrapper>
    </WizardPage>
  );
};
