import { type FC, type ReactNode } from "react";
import styled from "styled-components";

import { SmallIcon } from "../icons";
import { SpinnerLoader2 } from "../loaders/loaders";

const Wrapper = styled.div`
  display: flex;
  flex-flow: column;
  align-items: flex-start;
  margin-top: 1em;
`;

const TitleWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
`;

const AttributesWrapper = styled.div`
  width: 100%;
  display: flex;
  flex-flow: column;
  align-items: flex-start;
  font-size: 0.8rem;
`;

const Title = styled.h5`
  color: var(--primaryColor100);
  text-transform: uppercase;
`;

const EmptySpace = styled.div`
  width: 16px;
`;

export const DetailsSection: FC<{
  title: string;
  icon?: string;
  isLoading?: boolean;
  children?: ReactNode;
}> = ({ children, title, icon, isLoading }) => {
  return (
    <Wrapper className="p-component">
      <TitleWrapper>
        {icon && (
          <>
            <SmallIcon className={icon} />
            <EmptySpace />
          </>
        )}

        <Title>{title}</Title>
      </TitleWrapper>
      <AttributesWrapper>{isLoading ? <SpinnerLoader2 /> : children}</AttributesWrapper>
    </Wrapper>
  );
};
