import { useCallback, useRef, type FC, type SyntheticEvent } from "react";
import Avatar from "react-avatar";
import { useNavigate } from "react-router-dom";
import styled from "styled-components";

import { authActions } from "@ds/modules/auth/redux/slice";
import { selectCurrentUser, selectUserFullNameById } from "@ds/modules/settings/users/redux/selectors";

import { renderErrorMessage } from "@ds/components/forms/labels";
import { DropdownMenu } from "@ds/components/menu-dropdown/controls";
import { type ActionMenu } from "@ds/components/menu-dropdown/types";
import { useRootDispatch, useRootSelector } from "@ds/hooks";
import { composeDetailsRouteByEntityType } from "@ds/utils/router";

const UserMenuTitleWrapper = styled.span`
  margin-right: 15px;
  font-size: 1.2rem;

  display: block;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`;

const Wrapper = styled.div`
  display: flex;
  justify-content: flex-end;
  align-items: center;
  margin-right: 10px;

  width: inherit;
  height: inherit;

  .sb-avatar {
    cursor: pointer;
    width: inherit;
    height: inherit;
  }

  .sb-avatar--text,
  .sb-avatar__text {
    width: 2em !important;
    height: 2em !important;
  }

  .sb-avatar--gravatar {
    width: 50px !important;
    height: 50px !important;
  }

  .sb-avatar__image {
    width: 100% !important;
    height: 100% !important;
    object-fit: scale-down;
    object-position: center center;
  }

  > .error-text {
    font-size: 1.2rem;
  }
`;

export const USER_INFO_ERROR = "Failed to load user info. Try refreshing the page..";

export const HeaderRight: FC = () => {
  const navigate = useNavigate();

  const menuRef = useRef<ActionMenu>(null);
  const currentUser = useRootSelector(selectCurrentUser);
  const userFullName = useRootSelector(state => selectUserFullNameById(state, currentUser?.id || 0));
  const dispatch = useRootDispatch();

  const onMenuClickHandler = useCallback((e: SyntheticEvent) => menuRef.current?.toggle(e), []);

  const onSignOutClickHandler = useCallback(() => dispatch(authActions.signOut()), [dispatch]);

  if (!currentUser) {
    return <Wrapper>{renderErrorMessage(USER_INFO_ERROR)}</Wrapper>;
  }

  return (
    <>
      <Wrapper className={`fadein animation-duration-300 ml-auto`}>
        <UserMenuTitleWrapper>
          {`Hello, ${currentUser?.given_name} [${currentUser?.tenant?.company_name}]`}
        </UserMenuTitleWrapper>
        <Avatar round email={currentUser.email} name={userFullName} onClick={onMenuClickHandler} />
      </Wrapper>

      <DropdownMenu
        ref={menuRef}
        popup
        model={[
          {
            label: "User Profile",
            icon: "pi pi-id-card",
            command: () => navigate(composeDetailsRouteByEntityType(currentUser)),
          },
          {
            label: "Sign out",
            icon: "pi pi-sign-out",
            command: () => onSignOutClickHandler(),
          },
        ]}
      />
    </>
  );
};
