import { InputText as PrimeInputText } from "primereact/inputtext";
import { type ChangeEventHandler, type FC, type FocusEventHandler, type KeyboardEventHandler, type Ref } from "react";
import styled from "styled-components";

import { TextInputMixin } from "./styles";

export * from "../autocomplete";

export const StyledText = styled(PrimeInputText)<{ err?: Error }>`
  ${TextInputMixin}
`;

export const InputText: FC<{
  inputRef?: Ref<HTMLInputElement>;
  id?: string;
  name?: string;
  placeholder?: string;
  autoComplete?: string;
  value?: string;
  className?: string;
  autoFocus?: boolean;
  isDisabled?: boolean;
  isReadonly?: boolean;
  isRequired?: boolean;
  dataLpignore?: boolean;
  onChange?: ChangeEventHandler<HTMLInputElement>;
  onBlur?: FocusEventHandler<HTMLInputElement>;
  onKeyPress?: KeyboardEventHandler<HTMLInputElement>;
}> = ({
  inputRef,
  id,
  name,
  placeholder = "",
  autoComplete,
  value = "",
  className = "",
  autoFocus,
  isDisabled = false,
  isReadonly = false,
  isRequired = false,
  dataLpignore,
  onChange,
  onBlur,
  onKeyPress,
}) => (
  <StyledText
    ref={inputRef}
    id={id}
    name={name}
    placeholder={placeholder}
    autoComplete={autoComplete}
    value={value}
    className={className}
    autoFocus={autoFocus}
    aria-disabled={isDisabled || isReadonly}
    disabled={isDisabled || isReadonly}
    required={isRequired}
    data-lpignore={dataLpignore}
    onChange={onChange}
    onBlur={onBlur}
    onKeyPress={onKeyPress}
  />
);
