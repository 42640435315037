import { createEntityAdapter, createSlice, type PayloadAction } from "@reduxjs/toolkit";
import { type DeepPartial } from "react-hook-form";
import { all } from "redux-saga/effects";

import { StatusEnum } from "@ds/model/status-model";

import { deviceSyncsActions } from "@ds/modules/device-syncs/redux/slice";
import { isApiDeviceSyncQueryOutput } from "@ds/modules/device-syncs/utils/model";
import { peripheralsActions } from "@ds/modules/devices/peripherals/redux/slice";
import { devicesActions } from "@ds/modules/devices/redux/slice";
import { experiencesActions } from "@ds/modules/experiences/redux/slice";
import { isApiExperience, isApiExperienceQueryOutput } from "@ds/modules/experiences/utils/model";
import { iotEventUpdate } from "@ds/modules/iot/redux/actions";
import { IoTTopicPathEnum } from "@ds/modules/iot/utils/model";
import { DetailsTableDataTypeEnum, MainTableDataTypeEnum } from "@ds/modules/table-data/utils/model";

import { getIds } from "@ds/utils/entities";
import { LoadStatus, type BaseMeta, type PatchedModel } from "@ds/utils/reducer";
import { type SagaOptions } from "@ds/utils/saga-helpers";

import { isApiPeripheral, isApiPeripheralQueryOutput } from "../../peripherals/utils/model";
import { PlayerStateEnum } from "../utils/model";
import { normalize } from "../utils/normalizer";
import { watchCommands } from "./sagas/commands/send-command";
import { watchPlayers } from "./sagas/devices";

export enum DevicePlayersRemoteOperation {
  CRUD = "crud",
  PAIR = "pair",
  CHECKBOXES = "checkboxes",
  IOT_FETCH = "iot_fetch",
}

export type DevicePlayersMeta = BaseMeta & {
  remoteOperation: DevicePlayersRemoteOperation;
  filters?: KeysToCamelCase<DevicePlayerFilters>;
};

interface DevicePlayersState {
  loadStatusMap: {
    [DevicePlayersRemoteOperation.CRUD]: LoadStatus;
    [DevicePlayersRemoteOperation.PAIR]: LoadStatus;
    [DevicePlayersRemoteOperation.CHECKBOXES]: LoadStatus;
    [DevicePlayersRemoteOperation.IOT_FETCH]: LoadStatus;
  };

  error: string | null;

  entities: {
    [playerId: number]: Player;
  };

  ids: number[];
  tableIds: Record<string, number[]>;

  selectedIds: Record<string, number[]>;

  lastPairedPlayerId?: number;

  screenshotStatus: LoadStatus;
  screenshot: string | null;

  lastCommandByPlayerId: {
    [playerId: number]: Command;
  };
}

const entityAdapter = createEntityAdapter<Player>();

export const initialState: DevicePlayersState = entityAdapter.getInitialState({
  loadStatusMap: {
    [DevicePlayersRemoteOperation.CRUD]: LoadStatus.Idle,
    [DevicePlayersRemoteOperation.PAIR]: LoadStatus.Idle,
    [DevicePlayersRemoteOperation.CHECKBOXES]: LoadStatus.Idle,
    [DevicePlayersRemoteOperation.IOT_FETCH]: LoadStatus.Idle,
  },

  error: null,

  tableIds: { [MainTableDataTypeEnum.DevicePlayers]: [], [DetailsTableDataTypeEnum.LocationPlayers]: [] },
  selectedIds: { [MainTableDataTypeEnum.DevicePlayers]: [], [DetailsTableDataTypeEnum.LocationPlayers]: [] },

  lastPairedPlayerId: undefined,

  screenshotStatus: LoadStatus.Idle,
  screenshot: null,

  lastCommandByPlayerId: {},
}) as DevicePlayersState;

const getTableTypeOrDefault = (
  tableType: MainTableDataTypeEnum | DetailsTableDataTypeEnum = MainTableDataTypeEnum.DevicePlayers,
) => tableType;

const removeEntities = (state: DevicePlayersState, payload: number[]) => {
  entityAdapter.removeMany(state, payload);

  state.tableIds[MainTableDataTypeEnum.DevicePlayers] = state.tableIds[MainTableDataTypeEnum.DevicePlayers].filter(
    id => !payload.includes(id),
  );

  state.tableIds[DetailsTableDataTypeEnum.LocationPlayers] = state.tableIds[
    DetailsTableDataTypeEnum.LocationPlayers
  ].filter(id => !payload.includes(id));

  state.selectedIds[MainTableDataTypeEnum.DevicePlayers] = state.selectedIds[
    MainTableDataTypeEnum.DevicePlayers
  ].filter(id => !payload.includes(id));

  state.selectedIds[DetailsTableDataTypeEnum.LocationPlayers] = state.selectedIds[
    DetailsTableDataTypeEnum.LocationPlayers
  ].filter(id => !payload.includes(id));
};

const sliceType = "DEVICE_PLAYERS";
const slice = createSlice({
  name: sliceType,
  initialState,
  reducers: {
    fetchPlayers: {
      reducer(state, { meta }: PayloadAction<undefined, string, DevicePlayersMeta>) {
        state.loadStatusMap[meta.remoteOperation] = LoadStatus.Loading;
        state.error = null;

        if (meta.remoteOperation === DevicePlayersRemoteOperation.CHECKBOXES) {
          state.selectedIds[getTableTypeOrDefault(meta.options?.tableType)] =
            state.tableIds[getTableTypeOrDefault(meta.options?.tableType)];
        }
      },
      prepare(
        filters: KeysToCamelCase<DevicePlayerFilters> = {},
        options: SagaOptions = {},
        remoteOperation: DevicePlayersRemoteOperation = DevicePlayersRemoteOperation.CRUD,
      ) {
        return { payload: undefined, meta: { remoteOperation, filters, options } };
      },
    },
    fetchPlayersFailed: {
      reducer(state, { meta, error }: PayloadAction<undefined, string, DevicePlayersMeta, string>) {
        state.loadStatusMap[meta.remoteOperation] = LoadStatus.Failed;
        state.error = error;

        if (meta.remoteOperation === DevicePlayersRemoteOperation.CHECKBOXES) {
          state.loadStatusMap[meta.remoteOperation] = LoadStatus.Failed;
          state.selectedIds[getTableTypeOrDefault(meta.options?.tableType)] = [];
        }
      },
      prepare(meta: DevicePlayersMeta, { message }: ErrorLike) {
        return { payload: undefined, meta, error: message };
      },
    },
    fetchPlayersSucceeded: {
      reducer(state, { payload, meta }: PayloadAction<QueryOutput<Player>, string, DevicePlayersMeta>) {
        state.loadStatusMap[meta.remoteOperation] = LoadStatus.Succeeded;
        state.error = null;

        entityAdapter.setMany(state, payload.items);

        if (meta.remoteOperation === DevicePlayersRemoteOperation.CHECKBOXES) {
          state.selectedIds[getTableTypeOrDefault(meta.options?.tableType)] = payload.items.map(({ id }) => id);
        } else if (meta.options?.tableType) {
          state.tableIds[getTableTypeOrDefault(meta.options?.tableType)] = payload.items.map(({ id }) => id);
          state.selectedIds[getTableTypeOrDefault(meta.options?.tableType)] = state.selectedIds[
            getTableTypeOrDefault(meta.options?.tableType)
          ].filter(id => state.ids.includes(id));
        }
      },
      prepare(payload: QueryOutput<Player>, meta: DevicePlayersMeta) {
        return { payload, meta };
      },
    },
    fetchPlayer: {
      reducer(state, { meta }: PayloadAction<number, string, DevicePlayersMeta>) {
        state.loadStatusMap[meta.remoteOperation] = LoadStatus.Loading;
        state.error = null;
      },
      prepare(payload: number, options: SagaOptions = {}) {
        return { payload, meta: { remoteOperation: DevicePlayersRemoteOperation.CRUD, options } };
      },
    },
    fetchPlayerFailed: {
      reducer(state, { meta, error }: PayloadAction<number, string, DevicePlayersMeta, string>) {
        state.loadStatusMap[meta.remoteOperation] = LoadStatus.Failed;
        state.error = error;
      },
      prepare(payload: number, meta: DevicePlayersMeta, { message }: ErrorLike) {
        return { payload, meta, error: message };
      },
    },
    fetchPlayerSucceeded: {
      reducer(state, { payload, meta }: PayloadAction<Player, string, DevicePlayersMeta>) {
        state.loadStatusMap[meta.remoteOperation] = LoadStatus.Succeeded;
        state.error = null;

        entityAdapter.setOne(state, payload);
      },
      prepare(payload: Player, meta: DevicePlayersMeta) {
        return { payload, meta };
      },
    },
    createPlayer: {
      reducer(state, { meta }: PayloadAction<PlayerAndPairingCode, string, DevicePlayersMeta>) {
        state.loadStatusMap[meta.remoteOperation] = LoadStatus.Loading;
        state.error = null;

        state.lastPairedPlayerId = undefined;
      },
      prepare(
        payload: PlayerAndPairingCode,
        meta: DevicePlayersMeta = { remoteOperation: DevicePlayersRemoteOperation.CRUD },
      ) {
        return { payload, meta };
      },
    },
    createPlayerFailed: {
      reducer(state, { meta, error }: PayloadAction<PlayerAndPairingCode, string, DevicePlayersMeta, string>) {
        state.loadStatusMap[meta.remoteOperation] = LoadStatus.Failed;
        state.error = error;
      },
      prepare(payload: PlayerAndPairingCode, meta: DevicePlayersMeta, { message }: ErrorLike) {
        return { payload, meta, error: message };
      },
    },
    createPlayerSucceeded: {
      reducer(state, { payload, meta }: PayloadAction<PlayerAndPairingCode, string, DevicePlayersMeta>) {
        state.loadStatusMap[meta.remoteOperation] = LoadStatus.Succeeded;
        state.error = null;

        entityAdapter.setOne(state, payload.player);
        state.lastPairedPlayerId = payload.player.state === PlayerStateEnum.Unpaired ? undefined : payload.player.id;
      },
      prepare(payload: PlayerAndPairingCode, meta: DevicePlayersMeta) {
        return { payload, meta };
      },
    },
    updatePlayers: {
      reducer(state, _action: PayloadAction<PatchedModel<Player>>) {
        state.loadStatusMap[DevicePlayersRemoteOperation.CRUD] = LoadStatus.Loading;
        state.error = null;
      },
      prepare(changedIds: number[], changedFields: DeepPartial<Player>) {
        return { payload: { ids: changedIds, data: changedFields } };
      },
    },
    updatePlayersFailed: {
      reducer(state, { error }: PayloadAction<PatchedModel<Player>, string, never, string>) {
        state.loadStatusMap[DevicePlayersRemoteOperation.CRUD] = LoadStatus.Failed;
        state.error = error;
      },
      prepare(payload: PatchedModel<Player>, { message }: ErrorLike) {
        return { payload, error: message };
      },
    },
    updatePlayersSucceeded: (state, { payload }: PayloadAction<Player[]>) => {
      state.loadStatusMap[DevicePlayersRemoteOperation.CRUD] = LoadStatus.Succeeded;
      state.error = null;

      entityAdapter.setMany(state, payload);
    },
    deletePlayers: {
      reducer(state, { meta }: PayloadAction<number[], string, DevicePlayersMeta>) {
        state.loadStatusMap[meta.remoteOperation] = LoadStatus.Loading;
        state.error = null;
      },
      prepare(playersOrIds: Player[] | number[], redirectTo?: string) {
        return {
          payload: getIds(playersOrIds),
          meta: { remoteOperation: DevicePlayersRemoteOperation.CRUD, options: { redirectTo } },
        };
      },
    },
    deletePlayersFailed: {
      reducer(state, { meta, error }: PayloadAction<number[], string, DevicePlayersMeta, string>) {
        state.loadStatusMap[meta.remoteOperation] = LoadStatus.Failed;
        state.error = error;
      },
      prepare(idsToDelete: number[], meta: DevicePlayersMeta, { message }: ErrorLike) {
        return { payload: idsToDelete, meta, error: message };
      },
    },
    deletePlayersSucceeded: {
      reducer(state, { payload, meta }: PayloadAction<number[], string, DevicePlayersMeta>) {
        state.loadStatusMap[meta.remoteOperation] = LoadStatus.Succeeded;
        state.error = null;

        removeEntities(state, payload);
      },
      prepare(payload: number[], meta: DevicePlayersMeta) {
        return { payload, meta };
      },
    },
    selectPlayers: {
      reducer(state, { payload, meta }: PayloadAction<number[], string, DevicePlayersMeta>) {
        state.loadStatusMap[meta.remoteOperation] = LoadStatus.Succeeded;

        state.selectedIds[getTableTypeOrDefault(meta.options?.tableType)] = payload;
      },
      prepare(playersOrIds: Player[] | number[], options: SagaOptions = {}) {
        return {
          payload: getIds(playersOrIds),
          meta: { remoteOperation: DevicePlayersRemoteOperation.CHECKBOXES, options },
        };
      },
    },
    toggleSelected: {
      reducer(state, { payload, meta }: PayloadAction<number, string, DevicePlayersMeta>) {
        state.loadStatusMap[meta.remoteOperation] = LoadStatus.Succeeded;

        state.selectedIds[getTableTypeOrDefault(meta.options?.tableType)] = state.selectedIds[
          getTableTypeOrDefault(meta.options?.tableType)
        ].includes(payload)
          ? state.selectedIds[getTableTypeOrDefault(meta.options?.tableType)].filter(id => id !== payload)
          : [...state.selectedIds[getTableTypeOrDefault(meta.options?.tableType)], payload];
      },
      prepare(playerId: number, options: SagaOptions = {}) {
        return {
          payload: playerId,
          meta: { remoteOperation: DevicePlayersRemoteOperation.CHECKBOXES, options },
        };
      },
    },
    pairPlayer: (state, _action: PayloadAction<{ playerId: number; pairingCode: string }>) => {
      state.loadStatusMap[DevicePlayersRemoteOperation.PAIR] = LoadStatus.Loading;
    },
    pairPlayerFailed: {
      reducer(state, { error }: PayloadAction<{ playerId: number; pairingCode: string }, string, never, string>) {
        state.loadStatusMap[DevicePlayersRemoteOperation.PAIR] = LoadStatus.Failed;
        state.error = error;
      },
      prepare(payload: { playerId: number; pairingCode: string }, { message }: ErrorLike) {
        return { payload, error: message };
      },
    },
    pairPlayerSucceeded: (state, { payload }: PayloadAction<Player>) => {
      state.loadStatusMap[DevicePlayersRemoteOperation.PAIR] = LoadStatus.Succeeded;

      entityAdapter.setOne(state, payload);
    },
    // TODO: REDUX: REFACTOR TO LoadStatus nad RemoteOperation
    startFetchingScreenshots: (state, _action: PayloadAction<number>) => {
      state.screenshotStatus = LoadStatus.Loading;
      state.screenshot = null;
    },
    stopFetchingScreenshots: state => {
      state.screenshotStatus = LoadStatus.Idle;
      state.screenshot = null;
    },
    // TODO: REDUX: REFACTOR TO LoadStatus nad RemoteOperation
    processScreenshots: (state, { payload }: PayloadAction<{ screenshotUrl: string }>) => {
      state.screenshotStatus = LoadStatus.Succeeded;
      state.screenshot = payload.screenshotUrl;
    },
    sendCommandsToPlayers: (state, { payload }: PayloadAction<Command[]>) => {
      state.loadStatusMap[DevicePlayersRemoteOperation.CRUD] = LoadStatus.Loading;
      state.error = null;

      payload.forEach(command => {
        state.lastCommandByPlayerId[command.device_id] = command;
      });
    },
    sendCommandsToPlayersFailed: {
      reducer(state, { payload, error }: PayloadAction<Command[], string, never, string>) {
        state.loadStatusMap[DevicePlayersRemoteOperation.CRUD] = LoadStatus.Succeeded;
        state.error = error;

        payload.forEach(command => {
          state.lastCommandByPlayerId[command.device_id].status = StatusEnum.Failed;
          state.lastCommandByPlayerId[command.device_id].message = error;
        });
      },
      prepare(payload: Command[], { message }: ErrorLike) {
        return { payload, error: message };
      },
    },
    sendCommandsToPlayersSucceeded: (state, { payload }: PayloadAction<Command[]>) => {
      state.loadStatusMap[DevicePlayersRemoteOperation.CRUD] = LoadStatus.Succeeded;
      state.error = null;

      payload.forEach(command => {
        state.lastCommandByPlayerId[command.device_id] = command;
      });
    },
    iotUpdate: (state, { payload }: PayloadAction<Player>) => {
      if (payload.audit?.deleted_at) {
        removeEntities(state, [payload.id]);
      } else {
        entityAdapter.setOne(state, normalize(payload));
      }
    },
  },
  extraReducers: builder => {
    builder.addCase(devicesActions.switchView, state => {
      state.loadStatusMap[DevicePlayersRemoteOperation.CRUD] = LoadStatus.Idle;
      state.error = null;
    });

    builder.addCase(experiencesActions.fetchExperiencesSucceeded, (state, { payload }) => {
      if (isApiExperienceQueryOutput(payload))
        entityAdapter.setMany(
          state,
          payload.items.flatMap(({ devices }) => devices),
        );
    });

    builder.addCase(experiencesActions.fetchExperienceSucceeded, (state, { payload }) => {
      if (isApiExperience(payload)) {
        entityAdapter.setMany(state, payload.devices);
      }
    });

    builder.addCase(experiencesActions.createExperienceSucceeded, (state, { payload }) => {
      entityAdapter.setMany(state, payload.devices);
    });

    builder.addCase(experiencesActions.updateExperiencesSucceeded, (state, { payload }) => {
      entityAdapter.setMany(
        state,
        payload.flatMap(({ devices }) => devices),
      );
    });

    builder.addCase(deviceSyncsActions.fetchDeviceSyncsSucceeded, (state, { payload }) => {
      if (isApiDeviceSyncQueryOutput(payload)) {
        entityAdapter.setMany(
          state,
          payload.items.map(({ device }) => device),
        );
      }
    });

    builder.addCase(peripheralsActions.fetchPeripheralsSucceeded, (state, { payload }) => {
      if (isApiPeripheralQueryOutput(payload)) {
        entityAdapter.setMany(
          state,
          payload.items.map(({ device }) => device),
        );
      }
    });

    builder.addCase(peripheralsActions.fetchPeripheralSucceeded, (state, { payload }) => {
      if (isApiPeripheral(payload)) {
        entityAdapter.setOne(state, payload.device);
      }
    });

    builder.addCase(iotEventUpdate(IoTTopicPathEnum.Experiences), (state, { payload }) => {
      entityAdapter.setMany(state, normalize(payload.devices));
    });

    builder.addCase(iotEventUpdate(IoTTopicPathEnum.DeviceSyncs), (state, { payload }) => {
      entityAdapter.setOne(state, normalize(payload.device));
    });

    builder.addCase(iotEventUpdate(IoTTopicPathEnum.DevicePeripherals), (state, { payload }) => {
      entityAdapter.setOne(state, normalize(payload.device));
    });

    builder.addCase(iotEventUpdate(IoTTopicPathEnum.Commands), (state, { payload }) => {
      if (state.lastCommandByPlayerId[payload.device_id]?.id === payload.id) {
        state.lastCommandByPlayerId[payload.device_id] = payload;
      }
    });
  },
});

export const { name: playersType, actions: playersActions, reducer: playersReducer } = slice;

export const entitySelectors = entityAdapter.getSelectors();

export function* rootPlayersSaga() {
  yield all([watchPlayers(), watchCommands()]);
}
