import { cloneDeep } from "lodash";
import { call, put, race, select, take, takeLatest, takeLeading, type SagaReturnType } from "redux-saga/effects";

import { iotEventProjectUpdate } from "@ds/modules/iot/redux/actions";
import { toastShowErrorAction, toastShowSuccessAction } from "@ds/modules/notifications/redux/actions";
import { isIotTableEntityAddedOrDeleted, selectTableData } from "@ds/modules/table-data/redux/selectors";
import { convertToApiQueryInfo } from "@ds/modules/table-data/utils/common";
import { MainTableDataTypeEnum } from "@ds/modules/table-data/utils/model";

import { UnexpectedError } from "@ds/utils/errors";
import { logger } from "@ds/utils/logger";
import { UNLIMITED_PAGINATION } from "@ds/utils/query";
import { takeLatestOrEvery } from "@ds/utils/saga-helpers";
import { isErrorLike } from "@ds/utils/type-guards/error-guards";

import { projectsService } from "../utils/api";
import { normalize as normalizeDashboard } from "../utils/dashboard-normalizer";
import { normalize } from "../utils/normalizer";
import { selectProjectById, selectProjectsByIds, selectTableProjects } from "./selectors/common-selectors";
import { ProjectsRemoteOperation, projectsActions } from "./slice";

function* fetchProjects({ meta }: ReturnType<typeof projectsActions.fetchProjects>) {
  try {
    const { sorting, pagination, queryInfo, isFetchedAlready } = selectTableData(
      yield select(),
      meta.options.tableType || MainTableDataTypeEnum.Projects,
      undefined,
      meta.filters,
    );

    const newMeta = cloneDeep(meta);
    const resultFromCache: QueryOutput<Project> = { items: [] };

    if (!newMeta.options.cache?.disableCache) {
      if (isFetchedAlready && newMeta.options.tableType) {
        resultFromCache.items = selectTableProjects(yield select(), newMeta.options.tableType);
        newMeta.options.cache = {
          ...newMeta.options.cache,
          fetchedFromCache: true,
        };
      } else if (newMeta.filters.id && Object.keys(newMeta.filters).length === 1) {
        resultFromCache.items = selectProjectsByIds(yield select(), newMeta.filters.id);
        if (resultFromCache.items.length === newMeta.filters.id.length) {
          newMeta.options.cache = {
            ...newMeta.options.cache,
            fetchedFromCache: true,
          };
        }
      }
    }

    let result: QueryOutput<Project> = newMeta.options.cache?.fetchedFromCache
      ? resultFromCache
      : yield call(
          [projectsService, projectsService.getProjects],
          convertToApiQueryInfo(sorting, queryInfo),
          newMeta.options.tableType ? pagination : UNLIMITED_PAGINATION,
        );

    result = newMeta.options.cache?.fetchedFromCache ? result : normalize(result);
    result = newMeta.options.tableType ? result : { items: result.items };

    yield put(projectsActions.fetchProjectsSucceeded(result, newMeta));
  } catch (err) {
    const errorTitle = "Fetch projects";
    if (isErrorLike(err)) {
      yield put(projectsActions.fetchProjectsFailed(meta, err));
      yield put(toastShowErrorAction(err, errorTitle));
    } else {
      logger.error(`${errorTitle}: ${UnexpectedError}`);
      yield put(toastShowErrorAction(UnexpectedError, errorTitle));
    }
  }
}

function* fetchProject({ payload, meta }: ReturnType<typeof projectsActions.fetchProject>) {
  try {
    const newMeta = cloneDeep(meta);
    let result = selectProjectById(yield select(), payload);
    if (result && !newMeta.options.cache?.disableCache) {
      newMeta.options.cache = {
        ...newMeta.options.cache,
        fetchedFromCache: true,
      };
    } else {
      result = yield call([projectsService, projectsService.getProject], payload);
      result = normalize(result);
    }

    yield put(projectsActions.fetchProjectSucceeded(result, newMeta));
  } catch (err) {
    const errorTitle = "Fetch project";
    if (isErrorLike(err)) {
      yield put(projectsActions.fetchProjectFailed(payload, meta, err));
      yield put(toastShowErrorAction(err, errorTitle));
    } else {
      logger.error(`${errorTitle}: ${UnexpectedError}`);
      yield put(toastShowErrorAction(UnexpectedError, errorTitle));
    }
  }
}

function* updateProjects({ payload }: ReturnType<typeof projectsActions.updateProjects>) {
  try {
    const result: SagaReturnType<typeof projectsService.updateProjects> = yield call(
      [projectsService, projectsService.updateProjects],
      payload.ids,
      payload.data,
    );

    yield put(projectsActions.updateProjectsSucceeded(normalize(result)));
    yield put(toastShowSuccessAction("Project(s) were updated successfully"));
  } catch (err) {
    const errorTitle = "Update project(s)";
    if (isErrorLike(err)) {
      yield put(projectsActions.updateProjectsFailed(payload, err));
      yield put(toastShowErrorAction(err, errorTitle));
    } else {
      logger.error(`${errorTitle}: ${UnexpectedError}`);
      yield put(toastShowErrorAction(UnexpectedError, errorTitle));
    }
  }
}

function* fetchProjectDashboard({ payload, meta }: ReturnType<typeof projectsActions.fetchProjectDashboard>) {
  try {
    const result: SagaReturnType<typeof projectsService.getProjectDashboard> = yield call(
      [projectsService, projectsService.getProjectDashboard],
      payload,
    );

    yield put(projectsActions.fetchProjectDashboardSucceeded(normalizeDashboard(result), meta));
  } catch (err) {
    const errorTitle = "Fetch dashboard info";
    if (isErrorLike(err)) {
      yield put(projectsActions.fetchProjectDashboardFailed(payload, meta, err));
      yield put(toastShowErrorAction(err, errorTitle));
    } else {
      logger.error(`${errorTitle}: ${UnexpectedError}`);
      yield put(toastShowErrorAction(UnexpectedError, errorTitle));
    }
  }
}

function* refetchProjects({ payload }: ReturnType<typeof iotEventProjectUpdate>) {
  const isAddedOrDeleted = isIotTableEntityAddedOrDeleted(yield select(), payload, MainTableDataTypeEnum.Projects);
  if (isAddedOrDeleted) {
    yield call(
      fetchProjects,
      projectsActions.fetchProjects(
        undefined,
        {
          tableType: MainTableDataTypeEnum.Projects,
          cache: { disableCache: true },
        },
        ProjectsRemoteOperation.IOT_FETCH,
      ),
    );
  }

  yield put(projectsActions.iotUpdate(payload));
}

export function* watchProjects() {
  yield takeLatestOrEvery(projectsActions.fetchProjects, function* (action) {
    yield race({
      task: call(fetchProjects, action),
      cancel: take([projectsActions.selectProjects, projectsActions.toggleSelected]),
    });
  });

  yield takeLatestOrEvery(projectsActions.fetchProject, fetchProject);
  yield takeLeading(projectsActions.updateProjects, updateProjects);
  yield takeLatest(projectsActions.fetchProjectDashboard, fetchProjectDashboard);
  yield takeLatest(iotEventProjectUpdate, refetchProjects);
}
