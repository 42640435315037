import {
  Paginator as PrimePaginator,
  type PaginatorPageChangeEvent as PrimePaginatorPageChangeEvent,
} from "primereact/paginator";
import { type FC } from "react";

import { REACT_APP_PAGINATOR_ROWS_PER_PAGE } from "@ds/constants/environment";

export type { PrimePaginatorPageChangeEvent as PaginatorPageChangeEvent };

export type PaginatorProps = {
  pagination: Pagination;
  onPageChange: ({ page, rows }: PrimePaginatorPageChangeEvent) => void;
};

type PaginatorOptions = {
  rowsPerPageOptions?: number[];
};

export const Paginator: FC<PaginatorProps & PaginatorOptions> = ({
  pagination,
  onPageChange,
  rowsPerPageOptions = REACT_APP_PAGINATOR_ROWS_PER_PAGE,
}) => (
  <PrimePaginator
    style={{ backgroundColor: "transparent" }}
    rows={pagination.pageSize}
    first={pagination.pageSize * (pagination.pageNumber - 1)}
    totalRecords={pagination.total}
    rowsPerPageOptions={rowsPerPageOptions}
    template="CurrentPageReport FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink RowsPerPageDropdown"
    currentPageReportTemplate="Showing {first} to {last} of {totalRecords}"
    onPageChange={onPageChange}
  />
);
