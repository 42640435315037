import { useCallback, type FC } from "react";
import { Controller, type Control, type UseFormSetValue } from "react-hook-form";

import { useDetailsInlineSaving } from "@ds/components/details-view/hooks";
import { Dropdown } from "@ds/components/forms/inputs";
import { LabelWrapper } from "@ds/components/forms/labels";
import { AddressCountry } from "@ds/services/google-api";
import { useOnMouseEventPreventDefaultHandler } from "@ds/utils/handlers";

import { createAddress } from "../../../utils/model";

const CountryOptions = [
  { label: "USA", value: AddressCountry.USA },
  { label: "Canada", value: AddressCountry.CANADA },
];

export const AddressCountryControl: FC<{
  control: Control<Locality>;
  setValue: UseFormSetValue<Locality>;
  excludeLabel?: boolean;
  autoFocus?: boolean;
  onBlur?: () => void;
}> = ({ control, setValue, excludeLabel, autoFocus, onBlur }) => {
  const onBlurHandler = useDetailsInlineSaving(onBlur);
  const onMouseDownHandler = useOnMouseEventPreventDefaultHandler();

  const onChangeHandler = useCallback(
    ({ value }: { value: AddressCountry }) => {
      const newAddress = createAddress({ country: value });
      setValue("address", newAddress, {
        shouldDirty: true,
        shouldTouch: true,
        shouldValidate: true,
      });

      setValue("address.country", value, {
        shouldDirty: true,
        shouldTouch: true,
        shouldValidate: true,
      });
    },
    [setValue],
  );

  return (
    <Controller
      name="address.country"
      control={control}
      render={({ field: { ref, onBlur: onBlurFn, onChange: _, ...restFields }, fieldState }) => {
        const element = (
          <Dropdown
            inputRef={ref}
            options={CountryOptions}
            onChange={onChangeHandler}
            autoFocus={autoFocus}
            onBlur={onBlurHandler(onBlurFn)}
            onMouseDown={onMouseDownHandler}
            {...restFields}
          />
        );

        if (excludeLabel) {
          return element;
        }

        return (
          <LabelWrapper label="Country" className="col-8" error={fieldState.error} isRequired>
            {element}
          </LabelWrapper>
        );
      }}
    />
  );
};
