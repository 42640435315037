import { createEntityAdapter, createSlice, type PayloadAction } from "@reduxjs/toolkit";
import { type DeepPartial } from "react-hook-form";
import { all } from "redux-saga/effects";

import { deploymentsActions } from "@ds/modules/deployments/redux/slice";
import { DetailsTableDataTypeEnum, MainTableDataTypeEnum } from "@ds/modules/table-data/utils/model";

import { getIds } from "@ds/utils/entities";
import { LoadStatus, type BaseMeta, type PatchedModel } from "@ds/utils/reducer";
import { type SagaOptions } from "@ds/utils/saga-helpers";

import { contentActions } from "../../redux/slice";
import { isPlaylist } from "../utils/model";
import { watchPlaylists } from "./sagas";

export enum PlaylistsRemoteOperation {
  CRUD = "crud",
  CHECKBOXES = "checkboxes",
  IOT_FETCH = "iot_fetch",
}

type PlaylistsMeta = BaseMeta & {
  remoteOperation: PlaylistsRemoteOperation;
  filters?: KeysToCamelCase<ContentPlaylistFilters>;
};

interface PlaylistsState {
  loadStatusMap: {
    [PlaylistsRemoteOperation.CRUD]: LoadStatus;
    [PlaylistsRemoteOperation.CHECKBOXES]: LoadStatus;
    [PlaylistsRemoteOperation.IOT_FETCH]: LoadStatus;
  };

  error: string | null;

  entities: {
    [playlistId: number]: Playlist;
  };

  ids: number[];
  tableIds: Record<string, number[]>;
  selectedIds: Record<string, number[]>;
}

const entityAdapter = createEntityAdapter<Playlist>();

export const initialState: PlaylistsState = entityAdapter.getInitialState({
  loadStatusMap: {
    [PlaylistsRemoteOperation.CRUD]: LoadStatus.Idle,
    [PlaylistsRemoteOperation.CHECKBOXES]: LoadStatus.Idle,
    [PlaylistsRemoteOperation.IOT_FETCH]: LoadStatus.Idle,
  },

  error: null,

  tableIds: { [MainTableDataTypeEnum.ContentPlaylists]: [] },
  selectedIds: { [MainTableDataTypeEnum.ContentPlaylists]: [] },
}) as PlaylistsState;

const getTableTypeOrDefault = (
  tableType: MainTableDataTypeEnum | DetailsTableDataTypeEnum = MainTableDataTypeEnum.ContentPlaylists,
) => tableType;

const removeEntities = (state: PlaylistsState, payload: number[]) => {
  entityAdapter.removeMany(state, payload);

  state.tableIds[MainTableDataTypeEnum.ContentPlaylists] = state.tableIds[
    MainTableDataTypeEnum.ContentPlaylists
  ].filter(id => !payload.includes(id));

  state.selectedIds[MainTableDataTypeEnum.ContentPlaylists] = state.selectedIds[
    MainTableDataTypeEnum.ContentPlaylists
  ].filter(id => !payload.includes(id));
};

const sliceType = "CONTENT_PLAYLISTS";
const slice = createSlice({
  name: sliceType,
  initialState,
  reducers: {
    fetchPlaylists: {
      reducer(state, { meta }: PayloadAction<undefined, string, PlaylistsMeta>) {
        state.loadStatusMap[meta.remoteOperation] = LoadStatus.Loading;
        state.error = null;

        if (meta.remoteOperation === PlaylistsRemoteOperation.CHECKBOXES) {
          state.selectedIds[getTableTypeOrDefault(meta.options?.tableType)] =
            state.tableIds[getTableTypeOrDefault(meta.options?.tableType)];
        }
      },
      prepare(
        filters: KeysToCamelCase<ContentPlaylistFilters> = {},
        options: SagaOptions = {},
        remoteOperation: PlaylistsRemoteOperation = PlaylistsRemoteOperation.CRUD,
      ) {
        return { payload: undefined, meta: { remoteOperation, filters, options } };
      },
    },
    fetchPlaylistsFailed: {
      reducer(state, { meta, error }: PayloadAction<undefined, string, PlaylistsMeta, string>) {
        state.loadStatusMap[meta.remoteOperation] = LoadStatus.Failed;
        state.error = error;

        if (meta.remoteOperation === PlaylistsRemoteOperation.CHECKBOXES) {
          state.selectedIds[getTableTypeOrDefault(meta.options?.tableType)] = [];
        }
      },
      prepare(meta: PlaylistsMeta, { message }: ErrorLike) {
        return { payload: undefined, meta, error: message };
      },
    },
    fetchPlaylistsSucceeded: {
      reducer(state, { payload, meta }: PayloadAction<QueryOutput<Playlist>, string, PlaylistsMeta>) {
        state.loadStatusMap[meta.remoteOperation] = LoadStatus.Succeeded;
        state.error = null;

        entityAdapter.setMany(state, payload.items);

        if (meta.remoteOperation === PlaylistsRemoteOperation.CHECKBOXES) {
          state.selectedIds[getTableTypeOrDefault(meta.options?.tableType)] = payload.items.map(({ id }) => id);
        } else if (meta.options?.tableType) {
          state.tableIds[getTableTypeOrDefault(meta.options?.tableType)] = payload.items.map(({ id }) => id);
          state.selectedIds[getTableTypeOrDefault(meta.options?.tableType)] = state.selectedIds[
            getTableTypeOrDefault(meta.options?.tableType)
          ].filter(id => state.ids.includes(id));
        }
      },
      prepare(payload: QueryOutput<Playlist>, meta: PlaylistsMeta) {
        return { payload, meta };
      },
    },
    fetchPlaylist: {
      reducer(state, { meta }: PayloadAction<number, string, PlaylistsMeta>) {
        state.loadStatusMap[meta.remoteOperation] = LoadStatus.Loading;
        state.error = null;
      },
      prepare(payload: number, options: SagaOptions = {}) {
        return { payload, meta: { remoteOperation: PlaylistsRemoteOperation.CRUD, options } };
      },
    },
    fetchPlaylistFailed: {
      reducer(state, { meta, error }: PayloadAction<number, string, PlaylistsMeta, string>) {
        state.loadStatusMap[meta.remoteOperation] = LoadStatus.Failed;
        state.error = error;
      },
      prepare(payload: number, meta: PlaylistsMeta, { message }: ErrorLike) {
        return { payload, meta, error: message };
      },
    },
    fetchPlaylistSucceeded: {
      reducer(state, { payload, meta }: PayloadAction<Playlist, string, PlaylistsMeta>) {
        state.loadStatusMap[meta.remoteOperation] = LoadStatus.Succeeded;
        state.error = null;

        entityAdapter.setOne(state, payload);
      },
      prepare(payload: Playlist, meta: PlaylistsMeta) {
        return { payload, meta };
      },
    },
    createPlaylist: {
      reducer(state, { meta }: PayloadAction<Playlist, string, PlaylistsMeta>) {
        state.loadStatusMap[meta.remoteOperation] = LoadStatus.Loading;
        state.error = null;
      },
      prepare(payload: Playlist, meta: PlaylistsMeta = { remoteOperation: PlaylistsRemoteOperation.CRUD }) {
        return { payload, meta };
      },
    },
    createPlaylistFailed: {
      reducer(state, { meta, error }: PayloadAction<Playlist, string, PlaylistsMeta, string>) {
        state.loadStatusMap[meta.remoteOperation] = LoadStatus.Failed;
        state.error = error;
      },
      prepare(payload: Playlist, meta: PlaylistsMeta, { message }: ErrorLike) {
        return { payload, meta, error: message };
      },
    },
    createPlaylistSucceeded: {
      reducer(state, { payload, meta }: PayloadAction<Playlist, string, PlaylistsMeta>) {
        state.loadStatusMap[meta.remoteOperation] = LoadStatus.Succeeded;
        state.error = null;

        entityAdapter.setOne(state, payload);
      },
      prepare(payload: Playlist, meta: PlaylistsMeta) {
        return { payload, meta };
      },
    },
    updatePlaylists: {
      reducer(state, _action: PayloadAction<PatchedModel<Playlist>>) {
        state.loadStatusMap[PlaylistsRemoteOperation.CRUD] = LoadStatus.Loading;
        state.error = null;
      },
      prepare(changedIds: number[], changedFields: DeepPartial<Playlist>) {
        return { payload: { ids: changedIds, data: changedFields } };
      },
    },
    updatePlaylistsFailed: {
      reducer(state, { error }: PayloadAction<PatchedModel<Playlist>, string, never, string>) {
        state.loadStatusMap[PlaylistsRemoteOperation.CRUD] = LoadStatus.Failed;
        state.error = error;
      },
      prepare(payload: PatchedModel<Playlist>, { message }: ErrorLike) {
        return { payload, error: message };
      },
    },
    updatePlaylistsSucceeded: (state, { payload }: PayloadAction<Playlist[]>) => {
      state.loadStatusMap[PlaylistsRemoteOperation.CRUD] = LoadStatus.Succeeded;
      state.error = null;

      entityAdapter.setMany(state, payload);
    },
    deletePlaylists: {
      reducer(state, { meta }: PayloadAction<number[], string, PlaylistsMeta>) {
        state.loadStatusMap[meta.remoteOperation] = LoadStatus.Loading;
        state.error = null;
      },
      prepare(idsToDelete: number[], redirectTo?: string) {
        return {
          payload: idsToDelete,
          meta: { remoteOperation: PlaylistsRemoteOperation.CRUD, options: { redirectTo } },
        };
      },
    },
    deletePlaylistsFailed: {
      reducer(state, { meta, error }: PayloadAction<number[], string, PlaylistsMeta, string>) {
        state.loadStatusMap[meta.remoteOperation] = LoadStatus.Failed;
        state.error = error;
      },
      prepare(idsToDelete: number[], meta: PlaylistsMeta, { message }: ErrorLike) {
        return { payload: idsToDelete, meta, error: message };
      },
    },
    deletePlaylistsSucceeded: {
      reducer(state, { payload, meta }: PayloadAction<number[], string, PlaylistsMeta>) {
        state.loadStatusMap[meta.remoteOperation] = LoadStatus.Succeeded;
        state.error = null;

        removeEntities(state, payload);
      },
      prepare(payload: number[], meta: PlaylistsMeta) {
        return { payload, meta };
      },
    },
    selectPlaylists: {
      reducer(state, { payload, meta }: PayloadAction<number[], string, PlaylistsMeta>) {
        state.loadStatusMap[meta.remoteOperation] = LoadStatus.Succeeded;

        state.selectedIds[getTableTypeOrDefault(meta.options?.tableType)] = payload;
      },
      prepare(playlistsOrIds: Playlist[] | number[], options: SagaOptions = {}) {
        return {
          payload: getIds(playlistsOrIds),
          meta: { remoteOperation: PlaylistsRemoteOperation.CHECKBOXES, options },
        };
      },
    },
    toggleSelected: {
      reducer(state, { payload, meta }: PayloadAction<number, string, PlaylistsMeta>) {
        state.loadStatusMap[meta.remoteOperation] = LoadStatus.Succeeded;

        state.selectedIds[getTableTypeOrDefault(meta.options?.tableType)] = state.selectedIds[
          getTableTypeOrDefault(meta.options?.tableType)
        ].includes(payload)
          ? state.selectedIds[getTableTypeOrDefault(meta.options?.tableType)].filter(id => id !== payload)
          : [...state.selectedIds[getTableTypeOrDefault(meta.options?.tableType)], payload];
      },
      prepare(playlistId: number, options: SagaOptions = {}) {
        return {
          payload: playlistId,
          meta: { remoteOperation: PlaylistsRemoteOperation.CHECKBOXES, options },
        };
      },
    },
    iotUpdate: (state, { payload }: PayloadAction<Playlist>) => {
      if (payload.audit?.deleted_at) {
        removeEntities(state, [payload.id]);
      } else {
        entityAdapter.setOne(state, payload);
      }
    },
  },
  extraReducers: builder => {
    builder.addCase(contentActions.switchView, state => {
      state.loadStatusMap[PlaylistsRemoteOperation.CRUD] = LoadStatus.Idle;
      state.error = null;
    });

    builder.addCase(deploymentsActions.fetchDeploymentsSucceeded, (state, { payload, meta }) => {
      if (!meta.options?.cache?.fetchedFromCache) {
        entityAdapter.setMany(
          state,
          payload.items.flatMap(({ details }) => details?.entities || []).filter(isPlaylist),
        );
      }
    });

    builder.addCase(deploymentsActions.fetchDeploymentSucceeded, (state, { payload, meta }) => {
      if (!meta.options?.cache?.fetchedFromCache) {
        entityAdapter.setMany(state, (payload.details?.entities || []).filter(isPlaylist));
      }
    });

    builder.addCase(deploymentsActions.createDeploymentSucceeded, (state, { payload }) => {
      entityAdapter.setMany(state, (payload.details?.entities || []).filter(isPlaylist));
    });

    builder.addCase(deploymentsActions.updateDeploymentsSucceeded, (state, { payload }) => {
      entityAdapter.setMany(state, payload.flatMap(({ details }) => details?.entities || []).filter(isPlaylist));
    });
  },
});

export const { name: playlistsType, actions: playlistsActions, reducer: playlistsReducer } = slice;

export const entitySelectors = entityAdapter.getSelectors();

export function* rootPlaylistsSaga() {
  yield all([watchPlaylists()]);
}
