import { useCallback, type FC } from "react";
import { type DeepPartial } from "react-hook-form";

import { useGetAggregatedEntityIds } from "@ds/hooks/use-get-aggregated-entity-ids";
import { useParseQueryIds } from "@ds/hooks/use-parse-query-ids";
import { useReduxEntitiesFetch } from "@ds/hooks/use-redux-entities-fetch";

import { EntityTypeName } from "@ds/model/entity-model";

import { DetailsView } from "@ds/components/details-view";
import { AbsoluteLoader } from "@ds/components/loaders/loading";
import { EXPERIENCE_ID_PARAM } from "@ds/constants/router";
import { QueryParamType, useDeepRootSelector, useRootDispatch, useRootSelector } from "@ds/hooks";
import { pluralizeTotalSelectedTitle } from "@ds/utils/localization/common";
import { isMultiple } from "@ds/utils/numbers";

import { selectDeploymentsIsLoading } from "../deployments/redux/selectors";
import { DeploymentsRemoteOperation } from "../deployments/redux/slice";
import { selectTemplateExperienceFormationEntriesFilteredPerGroup } from "../settings/projects/redux/selectors/experience-formation-selectors";
import { selectCurrentUserProjectId } from "../settings/users/redux/selectors";
import { MainTableDataTypeEnum } from "../table-data/utils/model";
import { ExperienceDetailsFormationCard } from "./components/details/experience-details-formation-card";
import { ExperienceDetailsInfoCard } from "./components/details/experience-details-info-card";
import { ExperienceDetailsPlayersCard } from "./components/details/experience-details-players-card";
import { selectAggregatedExperienceByIds, selectExperiencesIsLoading } from "./redux/selectors";
import { experiencesActions } from "./redux/slice";

export const ExperienceDetailsPage: FC = () => {
  const queryIds = useParseQueryIds(EXPERIENCE_ID_PARAM, QueryParamType.Number);
  const experience = useDeepRootSelector(state => selectAggregatedExperienceByIds(state, queryIds));
  const aggregatedExperienceIds = useGetAggregatedEntityIds(experience);

  const isLoading = useRootSelector(selectExperiencesIsLoading);
  const isDeploying = useRootSelector(state => selectDeploymentsIsLoading(state, DeploymentsRemoteOperation.DEPLOYING));

  const projectId = useRootSelector(selectCurrentUserProjectId);
  const [infoPropertiesEntries, otherPropertiesPerGroup] = useDeepRootSelector(state => {
    const [infoData, otherData] = selectTemplateExperienceFormationEntriesFilteredPerGroup(
      state,
      projectId,
      "details",
      ["Info", "Players"],
    );

    return [Object.entries(infoData).flatMap(([, properties]) => properties), otherData] as const;
  });

  const [toggleRefetch] = useReduxEntitiesFetch(
    { entityType: EntityTypeName.EXPERIENCE, filters: { id: queryIds } },
    { initialLoad: true },
  );

  const dispatch = useRootDispatch();
  const onSubmitHandler = useCallback(
    (changedFields: DeepPartial<Experience>) =>
      dispatch(experiencesActions.updateExperiences(aggregatedExperienceIds, changedFields)),
    [dispatch, aggregatedExperienceIds],
  );

  if (!experience) {
    return <AbsoluteLoader isLoading />;
  }

  return (
    <DetailsView
      title={
        isMultiple(experience?.metadata?.totalAggregated)
          ? pluralizeTotalSelectedTitle("experience", experience?.metadata?.totalAggregated)
          : experience.name
      }
      tableType={MainTableDataTypeEnum.Experiences}
      isLoading={isLoading || isDeploying}
      onRefresh={toggleRefetch}
    >
      <ExperienceDetailsInfoCard
        experience={experience}
        templateProperties={infoPropertiesEntries}
        onSubmit={onSubmitHandler}
      />

      <ExperienceDetailsPlayersCard experience={experience} onSubmit={onSubmitHandler} />

      {Object.entries(otherPropertiesPerGroup).map(([group, properties]) => (
        <ExperienceDetailsFormationCard
          key={`experience-formation-card-${group}`}
          cardTitle={properties[0][1].metadata?.details?.group_title || ""}
          experience={experience}
          templateProperties={properties}
          onSubmit={onSubmitHandler}
        />
      ))}
    </DetailsView>
  );
};
