import { type FC } from "react";

import { InfoAttribute } from "@ds/components/info-view";

import { useGetFormationDisplayValue } from "../../../settings/projects/hooks/use-get-formation-display-value";

export const ExperienceFormationInfoSectionAttribute: FC<{
  entity: Experience;
  field: string;
  property: EntityFormationProperty;
}> = ({ entity, field, property }) => {
  const [formationDisplayValue] = useGetFormationDisplayValue(entity, field, property);
  return <InfoAttribute name={property.title} value={formationDisplayValue} />;
};
