import { type FC } from "react";

import { InfoSection } from "@ds/components/info-view";

import { LocationMap } from "./location-map";

export const LocationMapInfoSection: FC<{ location: Locality }> = ({ location }) =>
  location.address?.coordinates ? (
    <InfoSection title="Location Map" icon="pi pi-map">
      <LocationMap coordinates={location.address?.coordinates} />
    </InfoSection>
  ) : null;
