import { useCallback, type FC } from "react";

import { useReduxEntitiesFetch } from "@ds/hooks/use-redux-entities-fetch";

import { EntitySubtypeName, EntityTypeName } from "@ds/model/entity-model";

import { TableFilterOptions } from "@ds/modules/table-data/redux/types";

import { ActionsBar } from "@ds/components/actions-bar";
import { Button, ButtonActionType, ButtonType } from "@ds/components/forms/buttons";
import { IconType } from "@ds/components/icons";
import { InfoViewToggleRibbonButton } from "@ds/components/info-view/info-view-toggle-ribbon-button";
import { confirmWarning } from "@ds/components/popups/confirmation-dialog";
import { useRootDispatch, useRootSelector } from "@ds/hooks";
import { pluralizeDeleteMessage } from "@ds/utils/localization/common";

import { useDropzoneRef } from "../../hooks/use-dropzone-ref";
import { selectSelectedContentAssets } from "../../redux/selectors/common-selectors";
import { contentAssetsActions } from "../../redux/slice";
import { getSupportedFormatsByEntityType } from "../../utils/helpers";
import { ContentAssetUploadInput } from "../forms/common/content-assets-upload-input";
import { ContentAssetsLayout } from "../types";

export const ContentAssetsTableActionsMenu: FC<{
  filterOptions: TableFilterOptions;
  layout: ContentAssetsLayout;
  totalSelected: number;
  onUpload: (files: File[]) => void;
  onUpdate: () => void;
  onSwitchLayout: (newLayout: ContentAssetsLayout) => () => void;
}> = ({ filterOptions: { entitySubtype, tableType }, layout, totalSelected, onUpload, onUpdate, onSwitchLayout }) => {
  const selectedAssets = useRootSelector(state => selectSelectedContentAssets(state, tableType));

  const [dropzoneRef, openDropzone] = useDropzoneRef();

  const [toggleRefetch] = useReduxEntitiesFetch(
    { entityType: EntityTypeName.CONTENT_ASSET, entitySubtype },
    { tableType },
  );

  const dispatch = useRootDispatch();
  const onDeleteClickHandler = useCallback(
    () =>
      confirmWarning({
        message: pluralizeDeleteMessage("asset", selectedAssets.length),
        accept: () => dispatch(contentAssetsActions.deleteContentAssets(selectedAssets.map(({ id }) => id))),
      }),
    [dispatch, selectedAssets],
  );

  const shouldShowUpdateButton = totalSelected === 1 && entitySubtype === EntitySubtypeName.CONTENT_ASSET_VIDEO;

  return (
    <ActionsBar>
      <Button ariaLabel="upload button" buttonType={ButtonType.Round} icon={IconType.Upload} onClick={openDropzone} />

      {shouldShowUpdateButton && (
        <Button
          ariaLabel="update button"
          icon={IconType.Update}
          buttonType={ButtonType.Round}
          isDisabled={!selectedAssets.length}
          onClick={onUpdate}
        />
      )}

      <Button
        ariaLabel="delete button"
        icon={IconType.Delete}
        buttonType={ButtonType.Round}
        actionType={ButtonActionType.Error}
        isDisabled={!selectedAssets.length}
        onClick={onDeleteClickHandler}
      />

      <Button
        ariaLabel="refresh button"
        buttonType={ButtonType.Round}
        icon={IconType.Refresh}
        onClick={toggleRefetch}
      />

      {layout === ContentAssetsLayout.TILES && (
        <Button
          ariaLabel="list view button"
          buttonType={ButtonType.Round}
          icon={IconType.ListView}
          onClick={onSwitchLayout(ContentAssetsLayout.LIST)}
        />
      )}

      {layout === ContentAssetsLayout.LIST && (
        <Button
          ariaLabel="tiles view button"
          buttonType={ButtonType.Round}
          icon={IconType.TilesView}
          onClick={onSwitchLayout(ContentAssetsLayout.TILES)}
        />
      )}

      <InfoViewToggleRibbonButton />
      <ContentAssetUploadInput
        acceptMediaTypes={getSupportedFormatsByEntityType(entitySubtype)}
        dropzoneRef={dropzoneRef}
        onUpload={onUpload}
      />
    </ActionsBar>
  );
};
