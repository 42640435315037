import { yupResolver } from "@hookform/resolvers/yup";
import { noop } from "lodash";
import { useCallback, type FC } from "react";
import { useForm } from "react-hook-form";
import styled from "styled-components";

import { useRootDispatch, useRootSelector } from "@ds/hooks/redux-hooks";

import { Button, ButtonType } from "@ds/components/forms/buttons";
import { IconType } from "@ds/components/icons";
import { Dialog } from "@ds/components/modals";
import { NavTextLink } from "@ds/components/text-link";
import { SIGN_IN_ROUTE } from "@ds/constants/router";

import { selectAuthIsLoading, selectInvitedUser } from "../../redux/selectors";
import { authActions } from "../../redux/slice";
import { type UserSignUpModel } from "../../redux/types";
import { signUpValidationSchema } from "../../utils/validation-schema";
import { NameControl } from "./controls/name-control";
import { PasswordControl } from "./controls/password-control";
import { UsernameControl } from "./controls/username-signin-control";

const Form = styled.form`
  width: 400px;
  padding: 0 30px;
`;

export const UserSignUpForm: FC = () => {
  const isLoading = useRootSelector(selectAuthIsLoading);
  const invitedUser = useRootSelector(selectInvitedUser);

  const { control, getValues, handleSubmit } = useForm<UserSignUpModel>({
    mode: "all",
    reValidateMode: "onChange",
    defaultValues: {
      ...invitedUser,
      password: "",
      confirmPassword: "",
    },
    resolver: yupResolver(signUpValidationSchema),
  });

  const dispatch = useRootDispatch();
  const onSubmitHandler = useCallback(
    () =>
      dispatch(
        authActions.signUp({
          ...invitedUser,
          ...getValues(),
        }),
      ),
    [dispatch, invitedUser, getValues],
  );

  if (!invitedUser) {
    return null;
  }

  return (
    <Dialog modal visible showHeader={false} onHide={noop}>
      <Form onSubmit={handleSubmit(onSubmitHandler)} noValidate>
        <h2 className="text-center">Sign-Up</h2>
        <p className="text-center">Welcome to {invitedUser?.company_name}</p>
        <UsernameControl name="email" autoComplete="email" control={control} isReadOnly />
        <NameControl control={control} name="given_name" autoComplete="given-name" label="First Name" />
        <NameControl control={control} name="family_name" autoComplete="family-name" label="Last Name" />
        <PasswordControl control={control} autoComplete="new-password" />
        <PasswordControl
          control={control}
          name="confirmPassword"
          label="Confirm Password"
          autoComplete="confirm-password"
        />

        <div className="grid m-0">
          <div className="col">
            <NavTextLink
              to={{
                pathname: SIGN_IN_ROUTE,
              }}
              state={{
                username: invitedUser.email || "",
              }}
            >
              Login instead
            </NavTextLink>
          </div>

          <Button
            label="Sign-up"
            type="submit"
            buttonType={ButtonType.Raised}
            icon={IconType.Right}
            iconPosition="right"
            isLoading={isLoading}
          />
        </div>
      </Form>
    </Dialog>
  );
};
