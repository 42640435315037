import { nanoid } from "@reduxjs/toolkit";
import { isObject, isString } from "lodash";
import { type FC, type ReactNode } from "react";
import styled from "styled-components";

import { isError } from "@ds/utils/type-guards/error-guards";
import { isControllerFieldState, isFieldError } from "@ds/utils/type-guards/form-guards";

import {
  ValidationInputDescription,
  ValidationInputWrapper,
  ValidationLabelContentWrapper,
  ValidationTitle,
  ValidationWrapper,
} from "./styles";
import { type LabelSpanWrapperProps } from "./types";

const ErrorSubText = styled.div`
  color: var(--red-color);
  max-width: 30em;
  font-size: 11px;
  font-weight: 600;
  overflow: hidden;
  white-space: pre-wrap;
  text-overflow: ellipsis;
  margin-top: 2px;
`;

export const renderErrorMessage = (error: LabelError | string): ReactNode => {
  if (isString(error)) {
    return (
      <ErrorSubText key={`error-message-${nanoid()}`} className="error-text" role="alert">
        {error}
      </ErrorSubText>
    );
  }

  if (isError(error) || isFieldError(error)) {
    return (
      <ErrorSubText key={`error-message-${nanoid()}`} className="error-text" role="alert">
        {error.message}
      </ErrorSubText>
    );
  }

  if (isControllerFieldState(error)) {
    return renderErrorMessage(error.error);
  }

  return isObject(error) ? Object.entries(error).map(([, innerError]) => renderErrorMessage(innerError)) : null;
};

export const LabelWrapper: FC<LabelSpanWrapperProps> = ({
  label,
  infoText,
  isRequired = false,
  error,
  className,
  labelClassName,
  children,
  onClick,
}) => (
  <ValidationWrapper className={className}>
    <ValidationLabelContentWrapper className={labelClassName} onClick={onClick}>
      <ValidationTitle $isRequired={isRequired}>{label}</ValidationTitle>
      <ValidationInputWrapper aria-required={isRequired} $isValid={!error}>
        {children}
      </ValidationInputWrapper>
      {!!infoText && <ValidationInputDescription>{infoText}</ValidationInputDescription>}
    </ValidationLabelContentWrapper>
    {renderErrorMessage(error)}
  </ValidationWrapper>
);
