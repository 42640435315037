import { isPlainObject } from "lodash";

export const isError = (obj: unknown): obj is Error => obj instanceof Error;

export const isApiError = (obj: unknown): obj is ApiError =>
  isError(obj) && "response" in <ApiError>obj && "status" in <ApiError>obj;

export const isAmplifyError = (obj: unknown): obj is AmplifyError =>
  isPlainObject(obj) && "code" in <AmplifyError>obj && "name" in <AmplifyError>obj && "message" in <AmplifyError>obj;

export const isErrorLike = (obj: unknown): obj is ErrorLike => isError(obj) || isApiError(obj) || isAmplifyError(obj);
