export const assetMediaTypes = {
  video: {
    "video/mp4": [".mp4"],
    "video/MP2T": [".ts", ".tsv", ".tsa", ".m2t"],
    "video/quicktime": [".mov"],
    "video/x-matroska": [".mkv"],
  },
  image: {
    "image/png": [".png"],
    "image/jpeg": [".jpg", ".jpeg", ".jpe", ".jif", ".jfif"],
  },
  audio: {
    "audio/mpeg": [".mp3", ".mp2"],
    "audio/aac": [".aac"],
    "audio/wav": [".waw"],
  },
};
