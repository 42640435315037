import { type FC } from "react";
import { Controller, type Control, type UseFormSetValue } from "react-hook-form";

import { DeploymentScopeEnum } from "@ds/modules/deployments/utils/model";

import { Dropdown } from "@ds/components/forms/inputs";
import { LabelWrapper } from "@ds/components/forms/labels";

import { useCreateDeployment } from "../create-deployment-form/create-deployment-context";

const DeploymentScopeOptions = [
  { label: "All", value: DeploymentScopeEnum.All },
  { label: "Player", value: DeploymentScopeEnum.Device },
  { label: "Location", value: DeploymentScopeEnum.Location },
  { label: "Experience", value: DeploymentScopeEnum.Experience },
];

export const DeploymentScopeControl: FC<{
  control: Control<Deployment>;
  setValue: UseFormSetValue<Deployment>;
}> = ({ control, setValue }) => {
  const [currentDeployment] = useCreateDeployment();

  return (
    <Controller
      name="scope"
      control={control}
      render={({ field: { ref, value, onChange, ...restFields }, fieldState }) => {
        const handleChange = ({ value: scope }: { value: DeploymentScopeEnum }) => {
          setValue("targets", [], {
            shouldDirty: true,
            shouldTouch: true,
            shouldValidate: true,
          });

          onChange(scope);
        };

        let infoText = `Deploy ${currentDeployment.type} to all players in the project`;
        if (value === DeploymentScopeEnum.Device) {
          infoText = `Deploy ${currentDeployment.type} to selected players only`;
        }

        if (value === DeploymentScopeEnum.Experience) {
          infoText = `Deploy ${currentDeployment.type} to all players assigned to selected experiences`;
        }

        if (value === DeploymentScopeEnum.Location) {
          infoText = `Deploy ${currentDeployment.type} to all players in selected locations`;
        }

        return (
          <>
            <LabelWrapper label="Scope" infoText={infoText} className="col-8" error={fieldState.error} isRequired>
              <Dropdown
                inputRef={ref}
                value={value}
                options={DeploymentScopeOptions}
                onChange={handleChange}
                {...restFields}
              />
            </LabelWrapper>
          </>
        );
      }}
    />
  );
};
