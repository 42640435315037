import { useRerender } from "@react-hookz/web";
import { useEffect, type FC } from "react";
import { Portal } from "react-portal";
import { Link } from "react-router-dom";
import { useTitle } from "react-use";
import styled from "styled-components";

import { useGetElemenetById } from "@ds/hooks/use-get-element-by-id";

import { selectCurrentUserProject } from "@ds/modules/settings/projects/redux/selectors/common-selectors";
import { getPageTitleByTableDataType } from "@ds/modules/table-data/utils/helpers";
import { MainTableDataTypeEnum } from "@ds/modules/table-data/utils/model";

import { ROOT_ROUTE } from "@ds/constants/router";
import { useRootSelector } from "@ds/hooks";

import { TrimmedContent } from "./trimmed-text";

const NavLink = styled(Link)`
  color: inherit;

  &:hover {
    color: var(--textColor100);
  }
`;

export const HeaderTitle: FC<{ title: string; tableType?: MainTableDataTypeEnum }> = ({ title, tableType }) => {
  const [element] = useGetElemenetById("page-breadcrumbs");
  const currentUserProject = useRootSelector(selectCurrentUserProject);

  const rerender = useRerender();
  useEffect(() => rerender(), [currentUserProject, rerender]);

  useTitle(`DS Bits - ${title}`);

  return (
    <Portal node={element}>
      <NavLink to={ROOT_ROUTE}>
        <TrimmedContent>{currentUserProject?.name}</TrimmedContent>
      </NavLink>

      {!!tableType && (
        <>
          <i className="pi pi-angle-right" />
          <NavLink to="..">
            <TrimmedContent>{getPageTitleByTableDataType(tableType)}</TrimmedContent>
          </NavLink>
        </>
      )}

      <i className="pi pi-angle-right" />
      <NavLink to="">{title}</NavLink>
    </Portal>
  );
};
