import { isString } from "lodash";
import { push, replace } from "redux-first-history";
import { call, put, takeLatest, takeLeading, type SagaReturnType } from "redux-saga/effects";

import { toastShowErrorAction, toastShowSuccessAction } from "@ds/modules/notifications/redux/actions";
import { notificationsActions } from "@ds/modules/notifications/redux/slice";

import { FORGOT_PASSWORD_ROUTE, SIGN_IN_ROUTE } from "@ds/constants/router";
import { UnexpectedError } from "@ds/utils/errors";
import { logger } from "@ds/utils/logger";
import { isErrorLike } from "@ds/utils/type-guards/error-guards";

import { type UserSignInRouteParams } from "../components/forms/user-sign-in";
import { PASSWORD_CHANGED, RESTORE_CODE_SENDED } from "../constants/messages";
import { authService } from "../utils/api";
import { isAuthNotConfiguredAmplifyError } from "../utils/not-configured-amplify-error";
import { authActions } from "./slice";

function* fetchAuthenticatedCognitoUser({
  payload,
  meta,
}: ReturnType<typeof authActions.fetchAuthenticatedCognitoUser>) {
  try {
    const cognitoUser: SagaReturnType<typeof authService.getAuthenticatedUser> = yield call(
      [authService, authService.getAuthenticatedUser],
      payload?.bypassCache,
    );

    yield put(authActions.fetchAuthenticatedCognitoUserSucceeded(cognitoUser, meta));
  } catch (err) {
    const errorTitle = "Fetch authenticated user";
    if (isAuthNotConfiguredAmplifyError(err)) {
      yield put(authActions.fetchAuthenticatedCognitoUserFailed(payload, meta, err));
    } else if (isErrorLike(err)) {
      yield put(authActions.fetchAuthenticatedCognitoUserFailed(payload, meta, err));
      yield put(toastShowErrorAction(err, errorTitle));
    } else if (isString(err)) {
      yield put(authActions.fetchAuthenticatedCognitoUserFailed(payload, meta, new Error(err)));
    } else {
      logger.error(`${errorTitle}: ${UnexpectedError}`);
      yield put(toastShowErrorAction(UnexpectedError, errorTitle));
    }
  }
}

function* signUp({ payload }: ReturnType<typeof authActions.signUp>) {
  try {
    const user: SagaReturnType<typeof authService.signUp> = yield call([authService, authService.signUp], payload);
    yield put(authActions.signUpSucceeded(payload));
    yield put(
      authActions.signIn({
        username: user.email,
        password: payload.password,
      }),
    );
  } catch (err) {
    const errorTitle = "Sign up user";
    if (isErrorLike(err)) {
      yield put(authActions.signUpFailed(payload, err));
      yield put(toastShowErrorAction(err, errorTitle));
    } else {
      logger.error(`${errorTitle}: ${UnexpectedError}`);
      yield put(toastShowErrorAction(UnexpectedError, errorTitle));
    }
  }
}

function* signIn({ payload }: ReturnType<typeof authActions.signIn>) {
  try {
    yield put(notificationsActions.resetNotifications());
    yield call([authService, authService.signIn], payload);
    yield put(authActions.signInSucceeded(payload));
    yield put(authActions.fetchAuthenticatedCognitoUser());
  } catch (err) {
    const errorTitle = "SignIn User";
    if (isErrorLike(err)) {
      yield put(authActions.signInFailed(err));
      yield put(toastShowErrorAction(err, errorTitle));
    } else {
      logger.error(`${errorTitle}: ${UnexpectedError}`);
      yield put(toastShowErrorAction(UnexpectedError, errorTitle));
    }
  }
}

function* signOut() {
  try {
    yield call([authService, authService.signOut]);
    yield put(authActions.signOutSucceeded());
  } catch (err) {
    const errorTitle = "SignOut user";
    if (isErrorLike(err)) {
      yield put(authActions.signOutFailed(err));
      yield put(toastShowErrorAction(err, errorTitle));
    } else {
      logger.error(`${errorTitle}: ${UnexpectedError}`);
      yield put(toastShowErrorAction(UnexpectedError, errorTitle));
    }
  }
}

function* forgotPassword({ payload }: ReturnType<typeof authActions.forgotPassword>) {
  try {
    yield call([authService, authService.forgotPassword], payload);
    yield put(toastShowSuccessAction(RESTORE_CODE_SENDED));
    yield put(authActions.forgotPasswordSucceeded(payload));
    yield put(replace(FORGOT_PASSWORD_ROUTE, { username: payload.username, isAuthorized: true }));
  } catch (err) {
    const errorTitle = "Forgot Password";
    if (isErrorLike(err)) {
      yield put(authActions.forgotPasswordFailed(payload, err));
      yield put(toastShowErrorAction(err, errorTitle));
    } else {
      logger.error(`${errorTitle}: ${UnexpectedError}`);
      yield put(toastShowErrorAction(UnexpectedError, errorTitle));
    }
  }
}

function* restorePassword({ payload }: ReturnType<typeof authActions.restorePassword>) {
  try {
    yield call([authService, authService.restorePassword], payload);
    yield put(toastShowSuccessAction(PASSWORD_CHANGED));
    yield call(signOut);
    yield put(authActions.restorePasswordSucceeded(payload));
    yield put(push(SIGN_IN_ROUTE, { username: payload.username }));
  } catch (err) {
    const errorTitle = "Restore Password";
    if (isErrorLike(err)) {
      yield put(authActions.restorePasswordFailed(payload, err));
      yield put(toastShowErrorAction(err, errorTitle));
    } else {
      logger.error(`${errorTitle}: ${UnexpectedError}`);
      yield put(toastShowErrorAction(UnexpectedError, errorTitle));
    }
  }
}

function* changePassword({ payload }: ReturnType<typeof authActions.changePassword>) {
  try {
    yield call([authService, authService.changePassword], payload);
    yield put(toastShowSuccessAction(PASSWORD_CHANGED));
    yield put(authActions.changePasswordSucceeded(payload));
  } catch (err) {
    const errorTitle = "Change Password";
    if (isErrorLike(err)) {
      yield put(authActions.changePasswordFailed(payload, err));
      yield put(toastShowErrorAction(err, errorTitle));
    } else {
      logger.error(`${errorTitle}: ${UnexpectedError}`);
      yield put(toastShowErrorAction(UnexpectedError, errorTitle));
    }
  }
}

function* fetchInvitationInfo({ payload }: ReturnType<typeof authActions.fetchInvitationInfo>) {
  try {
    const invitation: SagaReturnType<typeof authService.getInvitationInfo> = yield call(
      [authService, authService.getInvitationInfo],
      payload,
    );

    yield put(authActions.fetchInvitedInfoSucceeded(invitation));
  } catch (err) {
    const routeParams = {} as UserSignInRouteParams;
    const errorTitle = "Fetch invitation info";
    if (isErrorLike(err)) {
      yield put(authActions.fetchInvitationInfoFailed(payload, err));
      yield put(toastShowErrorAction(err, errorTitle));
      routeParams.username = err.message.match(/\[(.*?)]/)?.[1];
    } else {
      logger.error(`${errorTitle}: ${UnexpectedError}`);
      yield put(toastShowErrorAction(UnexpectedError, errorTitle));
    }

    yield put(replace(SIGN_IN_ROUTE, routeParams));
  }
}

export function* watchAuth() {
  yield takeLatest(authActions.fetchAuthenticatedCognitoUser, fetchAuthenticatedCognitoUser);
  yield takeLeading(authActions.signUp, signUp);
  yield takeLeading(authActions.signIn, signIn);
  yield takeLeading(authActions.signOut, signOut);
  yield takeLeading(authActions.forgotPassword, forgotPassword);
  yield takeLeading(authActions.restorePassword, restorePassword);
  yield takeLeading(authActions.changePassword, changePassword);
  yield takeLeading(authActions.fetchInvitationInfo, fetchInvitationInfo);
}
