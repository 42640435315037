import { type FC } from "react";

import { AutocompleteTypeEnum, useFetchEntitiesAutocomplete } from "@ds/hooks/use-fetch-entities";

import { AutoComplete, type SingleAutoCompleteProps } from "@ds/components/forms/autocomplete";

export const ProjectsAutoComplete: FC<Omit<SingleAutoCompleteProps<Project>, "isLoading">> = ({
  customRef,
  inputRef,
  value,
  filterSuggestionsPredicate,
  dropdownMode,
  autoFocus,
  onBlur,
  onChange,
}) => {
  const [
    suggestions,
    onCompleteHandler,
    {
      state: { status: remoteStatus },
    },
  ] = useFetchEntitiesAutocomplete<Project>(AutocompleteTypeEnum.Projects, {
    filterPredicate: filterSuggestionsPredicate,
  });

  return (
    <AutoComplete
      field="name"
      placeholder="Enter project name"
      customRef={customRef}
      inputRef={inputRef}
      value={value}
      suggestions={suggestions}
      dropdownMode={dropdownMode}
      autoFocus={autoFocus}
      isLoading={remoteStatus === "loading"}
      onBlur={onBlur}
      onChange={onChange}
      onComplete={onCompleteHandler}
    />
  );
};
