import { useMemo, type FC } from "react";

import { createExperienceModel } from "@ds/modules/experiences/utils/model";
import { selectCurrentUserProject } from "@ds/modules/settings/projects/redux/selectors/common-selectors";
import { selectTemplateExperienceFormationEntriesPerGroup } from "@ds/modules/settings/projects/redux/selectors/experience-formation-selectors";

import { AbsoluteLoader } from "@ds/components/loaders/loading";
import { Wizard } from "@ds/components/wizard";
import { useDeepRootSelector, useRootSelector } from "@ds/hooks";

import { CreateExperienceProvider } from "./create-experience-context";
import { StepFinal } from "./step-final";
import { Step1Main } from "./step1-main";
import { Step2Formation } from "./step2-formation";
import { Step3Name } from "./step3-name";

export const CreateExperienceModal: FC<{ onClose: () => void }> = ({ onClose }) => {
  const project = useRootSelector(selectCurrentUserProject);
  const formationEntriesPerGroup = useDeepRootSelector(state =>
    selectTemplateExperienceFormationEntriesPerGroup(state, project?.id, "wizard"),
  );

  const formationSteps = useMemo(
    () =>
      Object.entries(formationEntriesPerGroup).map(([group, templateProperties]) => (
        <Step2Formation
          key={`experience-formation-wizard-step-${group}`}
          group={+group}
          templateProperties={templateProperties}
        />
      )),
    [formationEntriesPerGroup],
  );

  if (!project) {
    return <AbsoluteLoader isLoading />;
  }

  return (
    <CreateExperienceProvider
      initialValue={createExperienceModel({ formation: project.template.experience_formation })}
    >
      <Wizard width="40vw" height="80vh" onClose={onClose}>
        <Step1Main />
        {formationSteps}
        <Step3Name />
        <StepFinal />
      </Wizard>
    </CreateExperienceProvider>
  );
};
