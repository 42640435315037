import pluralize from "pluralize";

export const pluralizeActionMessage = (entityName: string, count: number, action: string) =>
  `Are you sure you want to ${action} ${pluralize(entityName, count, true)}?`;

export const pluralizeDeleteMessage = (entityName: string, count: number) =>
  `Are you sure you want to delete ${pluralize(entityName, count, true)}?`;

export const pluralizeTotalSelectedTitle = (entityName: string, count?: number) =>
  `Selected ${pluralize(entityName, count || 0, true)}`;
