import { useCallback, type FC } from "react";

import { MainTableDataTypeEnum } from "@ds/modules/table-data/utils/model";

import { YesNoCheckbox } from "@ds/components/forms/inputs";
import { Loading } from "@ds/components/loaders/loading";
import { TableWithPagination } from "@ds/components/table/main-table";
import {
  Column,
  getEntityIdColumn,
  getGoToDetailsColumn,
  getLastUpdatedColumn,
  getSelectionColumn,
} from "@ds/components/table/table-columns";
import { TotalSelectedLabel } from "@ds/components/table/total-selected-label";
import { TextLink } from "@ds/components/text-link";
import { usePagination, useRootDispatch, useRootSelector, useTableDataSorting } from "@ds/hooks";
import { composeDetailsRouteByEntityType } from "@ds/utils/router";

import {
  selectSelectedUsers,
  selectTableUsers,
  selectUserFullNameById,
  selectUsersIsLoading,
} from "../redux/selectors";
import { usersActions, UsersRemoteOperation } from "../redux/slice";

const useGetUserFullNameById = (id: number) => useRootSelector(state => selectUserFullNameById(state, id));

const getUserNameBodyTemplate = (user: User) => (
  <TextLink to={composeDetailsRouteByEntityType(user)}>{user.email}</TextLink>
);

const getFullNameBodyTemplate = (getUserFullNameById: (id: number) => string) => (user: User) =>
  getUserFullNameById(user.id);

const getIsActiveBodyTemplate = (project: Project) => <YesNoCheckbox isChecked={project.is_active} />;

export const UsersTable: FC = () => {
  const selectedEntities = useRootSelector(selectSelectedUsers);
  const entities = useRootSelector(selectTableUsers);

  const isLoading = useRootSelector(selectUsersIsLoading);
  const checkboxIsLoading = useRootSelector(state => selectUsersIsLoading(state, UsersRemoteOperation.CHECKBOXES));

  const [pagination, setPagination] = usePagination(MainTableDataTypeEnum.Users);
  const [{ sortOrder, sortField }, setSorting] = useTableDataSorting(MainTableDataTypeEnum.Users);

  const dispatch = useRootDispatch();
  const onToggleRowSelected = useCallback((entry: User) => dispatch(usersActions.toggleSelected(entry.id)), [dispatch]);

  const onSingleRowSelectHandler = useCallback(
    (entry: User) => dispatch(usersActions.selectUsers([entry])),
    [dispatch],
  );

  const onToggleAllHandler = useCallback(
    (isSelectedAll: boolean) => {
      if (isSelectedAll) {
        dispatch(usersActions.fetchUsers(undefined, undefined, UsersRemoteOperation.CHECKBOXES));
      } else {
        dispatch(usersActions.selectUsers([]));
      }
    },
    [dispatch],
  );

  return (
    <Loading isLoading={isLoading}>
      <TableWithPagination
        value={entities}
        selection={selectedEntities}
        onSingleRowSelect={onSingleRowSelectHandler}
        toggleRowSelected={onToggleRowSelected}
        loading={isLoading}
        sortField={sortField}
        sortOrder={sortOrder}
        onSort={setSorting}
        pagination={pagination}
        onPageChange={setPagination}
      >
        {getSelectionColumn({
          total: pagination.total,
          selected: selectedEntities.length,
          isLoading: checkboxIsLoading,
          onToggleAll: onToggleAllHandler,
        })}

        {getEntityIdColumn()}
        <Column header="Email" body={getUserNameBodyTemplate} style={{ flexGrow: 4 }} />
        <Column header="Name" body={getFullNameBodyTemplate(useGetUserFullNameById)} style={{ flexGrow: 4 }} />
        <Column header="Role" field="role" className="w200" resizeable={false} sortable />

        <Column
          field="is_active"
          header="Active"
          body={getIsActiveBodyTemplate}
          className="center w150"
          resizeable={false}
          sortable
        />

        {getLastUpdatedColumn()}
        {getGoToDetailsColumn()}
      </TableWithPagination>

      <TotalSelectedLabel selectedCount={checkboxIsLoading ? Number(pagination.total) : selectedEntities.length} />
    </Loading>
  );
};
