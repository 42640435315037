import { EntityTypeName, isEntity } from "@ds/model/entity-model";

import { PlayerCommands } from "../constants/common";

export enum CommandTargetEnum {
  Core = "core",
  Applet = "applet",
}

export const createCommandModel = (options: Partial<Command>): Command => ({
  __typename: EntityTypeName.DEVICE_COMMAND,
  id: options?.id || 0,
  device_id: options.device_id || 0,
  name: options.name || "",
  friendly_name: options.friendly_name || options.name,
  data: options.data || "",
  target: options.target || CommandTargetEnum.Core,
});

export const isCommand = (obj: unknown): obj is Command =>
  isEntity(obj) && obj.__typename === EntityTypeName.DEVICE_COMMAND;

export const isLogsCommand = (obj: unknown): obj is Command => isCommand(obj) && obj.name === PlayerCommands.LOGS;

export const isScreenshotCommand = (obj: unknown): obj is Command =>
  isCommand(obj) && obj.name === PlayerCommands.SCREENSHOT;

export const isRebootCommand = (obj: unknown): obj is Command => isCommand(obj) && obj.name === PlayerCommands.REBOOT;
