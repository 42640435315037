import { type FC } from "react";
import { Controller, type Control } from "react-hook-form";

import { LabelWrapper } from "@ds/components/forms/labels";

import { ExperienceTargets } from "./targets/experience-targets";

export const DeploymentTargetsExperiencesControl: FC<{ control: Control<Deployment> }> = ({ control }) => (
  <Controller
    name="targets"
    control={control}
    render={({ field: { ref, ...restFields }, fieldState }) => (
      <LabelWrapper label="Experiences" className="col-8" error={fieldState.error} isRequired>
        <ExperienceTargets inputRef={ref} {...restFields} />
      </LabelWrapper>
    )}
  />
);
