import { yupResolver } from "@hookform/resolvers/yup";
import { noop } from "lodash";
import { useCallback, type FC } from "react";
import { useForm } from "react-hook-form";
import { Navigate } from "react-router-dom";
import styled from "styled-components";

import { useRootDispatch, useRootSelector } from "@ds/hooks/redux-hooks";
import { useTypedLocation } from "@ds/hooks/use-typed-location";

import { selectCurrentUser } from "@ds/modules/settings/users/redux/selectors";

import { Button, ButtonType } from "@ds/components/forms/buttons";
import { IconType } from "@ds/components/icons";
import { Dialog } from "@ds/components/modals";
import { NavTextLink } from "@ds/components/text-link";
import { FORGOT_PASSWORD_ROUTE, ROOT_ROUTE } from "@ds/constants/router";

import { selectAuthIsLoading } from "../../redux/selectors";
import { authActions } from "../../redux/slice";
import { type UserSignInModel } from "../../redux/types";
import { signInValidationSchema } from "../../utils/validation-schema";
import { PasswordControl } from "./controls/password-control";
import { UsernameControl } from "./controls/username-signin-control";

const Form = styled.form`
  width: 400px;
  padding: 0 30px;
`;

export type UserSignInRouteParams = { returnUrl?: string; username?: string };

export const UserSignIn: FC = () => {
  const { state: routeState } = useTypedLocation<UserSignInRouteParams>();

  const { control, watch, getValues, handleSubmit } = useForm<UserSignInModel>({
    mode: "all",
    reValidateMode: "onChange",
    defaultValues: {
      username: routeState?.username || "",
      password: "",
    },
    resolver: yupResolver(signInValidationSchema),
  });

  const isLoading = useRootSelector(selectAuthIsLoading);
  const currentUserId = useRootSelector(selectCurrentUser)?.id;

  const dispatch = useRootDispatch();
  const onSubmitHandler = useCallback(() => {
    if (!currentUserId) {
      dispatch(authActions.signIn(getValues()));
    }
  }, [dispatch, currentUserId, getValues]);

  if (currentUserId) {
    return <Navigate to={routeState?.returnUrl ? ROOT_ROUTE + routeState?.returnUrl.slice(1) : ROOT_ROUTE} />;
  }

  const currentUsername = watch("username");
  return (
    <Dialog modal visible showHeader={false} onHide={noop}>
      <Form onSubmit={handleSubmit(onSubmitHandler)} noValidate>
        <h2 className="text-center">Sign-In</h2>
        <UsernameControl control={control} />
        <PasswordControl control={control} />

        <div className="grid m-0">
          <div className="col">
            <NavTextLink to={{ pathname: FORGOT_PASSWORD_ROUTE }} state={{ username: currentUsername }}>
              Forgot password?
            </NavTextLink>
          </div>

          <Button
            label="Sign-in"
            type="submit"
            buttonType={ButtonType.Raised}
            icon={IconType.Right}
            iconPosition="right"
            isLoading={isLoading}
          />
        </div>
      </Form>
    </Dialog>
  );
};
