import { type FC } from "react";

import { isAggregatedEntity } from "@ds/model/entity-model";

import { IconType } from "@ds/components/icons";
import { InfoView, InfoViewCallToAction } from "@ds/components/info-view";
import { getInfoViewTitle } from "@ds/components/info-view/helpers";
import { useDeepRootSelector } from "@ds/hooks";

import { selectAggregatedPlayerForSelectedEntities } from "../redux/selectors";
import { PlayerStateEnum } from "../utils/model";
import { PlayerExperienceInfoSection } from "./common/player-experience-info-section";
import { PlayerInfoSection } from "./common/player-info-section";
import { PlayerLastSyncInfoSection } from "./common/player-last-sync-info-section";
import { PlayerPairingInfoSection } from "./common/player-pairing-info-section";
import { PlayerPeripheralsInfoSection } from "./common/player-peripherals-info-section";
import { PlayerTvInfoSection } from "./common/player-tv-info-section";

export const PlayerInfo: FC<{
  onCreatePlayer: () => void;
  onPairPlayer: () => void;
}> = ({ onCreatePlayer, onPairPlayer }) => {
  const aggregatedPlayer = useDeepRootSelector(selectAggregatedPlayerForSelectedEntities);
  const isAggregated = isAggregatedEntity(aggregatedPlayer);
  if (aggregatedPlayer) {
    return (
      <InfoView infoTitle={getInfoViewTitle(aggregatedPlayer)}>
        {!isAggregated && <PlayerTvInfoSection player={aggregatedPlayer} />}
        {!isAggregated && aggregatedPlayer.state === PlayerStateEnum.Unpaired && (
          <PlayerPairingInfoSection onPairPlayer={onPairPlayer} />
        )}

        <PlayerInfoSection player={aggregatedPlayer} />
        <PlayerExperienceInfoSection player={aggregatedPlayer} />
        {aggregatedPlayer.state !== PlayerStateEnum.Unpaired && (
          <>
            <PlayerPeripheralsInfoSection player={aggregatedPlayer} />
            <PlayerLastSyncInfoSection player={aggregatedPlayer} />
          </>
        )}
      </InfoView>
    );
  }

  return (
    <InfoViewCallToAction
      infoTitle="Select player"
      callToActionText="Select a player in the table for more info or proceed with creating and pairing new player."
      buttonLabel="Create player"
      buttonIcon={IconType.Create}
      onActionButtonClick={onCreatePlayer}
    />
  );
};
