import { type FC, type ReactNode } from "react";
import styled from "styled-components";

import { StatusColor, StatusIconClassName } from "@ds/model/status-model";

const Icon = styled.i<{ $status: Status }>`
  font-size: 4rem !important;
  color: ${({ $status }) => StatusColor[$status]} !important;
  margin-bottom: 12px;
`;

const Text = styled.span`
  text-align: center;
  padding: 0 8px;
`;

export const StatusMessage: FC<{
  status: Status;
  message?: string;
  children?: ReactNode;
}> = ({ status, message, children }) => (
  <div className="grid flex-column align-items-center">
    <Icon $status={status} className={StatusIconClassName[status]} />
    {message && <Text>{message}</Text>}
    {children}
  </div>
);
