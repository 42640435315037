import { type RawAxiosResponseHeaders } from "axios";

import { PaginationHeadersEnum } from "@ds/constants/api";
import { REACT_APP_PAGE_SIZE } from "@ds/constants/environment";

export const convertPaginationResponse = (headers: RawAxiosResponseHeaders): Pagination => ({
  pageNumber: +(headers[PaginationHeadersEnum.PAGINATION_PAGE_HEADER] || 1),
  pageSize: +(headers[PaginationHeadersEnum.PAGINATION_PAGESIZE_HEADER] || REACT_APP_PAGE_SIZE),
  total: +(headers[PaginationHeadersEnum.PAGINATION_TOTAL_HEADER] || 0),
});

export const paginateData = <T>(
  data: T[],
  { pageNumber = 1, pageSize = REACT_APP_PAGE_SIZE }: Pagination = {
    pageNumber: 1,
    pageSize: REACT_APP_PAGE_SIZE,
  },
) => data.slice((pageNumber - 1) * pageSize, pageSize * pageNumber);
