import { useCallback, useEffect, type FC } from "react";
import { useWatch, type Control, type UseFormSetValue } from "react-hook-form";

import { useFetchAmplifyStorageDataSignedUrl } from "@ds/services/amplify-storage-api";

import { ImageUploadInput } from "./image-upload-input";

export const ContentAssetsThumbnailControl: FC<{
  control: Control<ThumbnailFormData>;
  setValue: UseFormSetValue<ThumbnailFormData>;
}> = ({ control, setValue }) => {
  const [signedUrl, fetchSignedUrl] = useFetchAmplifyStorageDataSignedUrl();
  const contentAsset = useWatch({ name: "contentAsset", control });

  useEffect(() => void fetchSignedUrl(contentAsset.thumbnail?.key), [contentAsset, fetchSignedUrl]);

  const onLogoUploadHandler = useCallback(
    (_: string, file: File | null, hasChanged: boolean) => {
      setValue("isDirtyFile", hasChanged, {
        shouldDirty: true,
        shouldTouch: true,
        shouldValidate: true,
      });

      setValue("thumbnailFile", file, {
        shouldDirty: true,
        shouldTouch: true,
        shouldValidate: true,
      });
    },
    [setValue],
  );

  return <ImageUploadInput initialImage={signedUrl} onChange={onLogoUploadHandler} />;
};
