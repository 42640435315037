import { type FC, type ReactNode } from "react";

import { MainTablePaginator } from "../paginator/main-table-paginator";
import { type PaginatorProps } from "../paginator/paginator";
import { DataTable, type DataTableProps } from "./data-table";

const TableWithPaginatorWrapper: FC<{ children: ReactNode }> = ({ children }) => (
  <div className="h-full flex flex-column justify-content-between fadein animation-duration-300">{children}</div>
);

export const DetailsTable = <T extends Entity>({
  pagination,
  onPageChange,
  children,
  ...rest
}: DataTableProps<T> & Partial<PaginatorProps>) => (
  <TableWithPaginatorWrapper>
    <DataTable {...rest}>{children}</DataTable>
    <MainTablePaginator pagination={pagination} onPageChange={onPageChange} isAlwaysVisible={false} />
  </TableWithPaginatorWrapper>
);
