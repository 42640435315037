import { type FC } from "react";

import { PlayerInfoSection } from "@ds/modules/devices/players/components/common/player-info-section";

import { AggregatedValue } from "@ds/components/entities";
import { InfoView, InfoViewCallToAction } from "@ds/components/info-view";
import { getInfoViewTitle } from "@ds/components/info-view/helpers";
import { useDeepRootSelector } from "@ds/hooks";
import { getAggregatedEntityIds } from "@ds/utils/entities";

import { selectAggregatedPlayerByIds } from "../../players/redux/selectors";
import { selectAggregatedPeripheralForSelectedEntities, selectPeripheralsByIds } from "../redux/selectors";
import { PeripheralInfoSection } from "./common/peripheral-info-section";

export const PeripheralInfo: FC = () => {
  const aggregatedPeripheral = useDeepRootSelector(selectAggregatedPeripheralForSelectedEntities);
  const aggregatedPlayer = useDeepRootSelector(state => {
    // @ts-expect-error: TODO: FIX LATER
    const aggregatedPlayerIds = selectPeripheralsByIds(state, getAggregatedEntityIds(aggregatedPeripheral)).map(
      ({ device_id }) => device_id,
    );

    return selectAggregatedPlayerByIds(state, aggregatedPlayerIds);
  });

  if (aggregatedPeripheral && aggregatedPlayer) {
    return (
      <InfoView
        infoTitle={getInfoViewTitle(aggregatedPeripheral)}
        infoDescription={<AggregatedValue entity={aggregatedPeripheral} field="description" />}
      >
        <PeripheralInfoSection peripheral={aggregatedPeripheral} />
        <PlayerInfoSection player={aggregatedPlayer} showNameIsteadOfStatus />
      </InfoView>
    );
  }

  return (
    <InfoViewCallToAction
      infoTitle="Select peripheral"
      callToActionText="Select a peripheral in the table for more info."
    />
  );
};
