import { createEntityAdapter, createSlice, type PayloadAction } from "@reduxjs/toolkit";
import { all } from "redux-saga/effects";

import { DetailsTableDataTypeEnum, MainTableDataTypeEnum } from "@ds/modules/table-data/utils/model";

import { getIds } from "@ds/utils/entities";
import { LoadStatus, type BaseMeta } from "@ds/utils/reducer";
import { type SagaOptions } from "@ds/utils/saga-helpers";

import { apiToModelNormalize, normalize } from "../utils/normalizer";
import { watchDeviceSyncs } from "./sagas";

export enum DeviceSyncsRemoteOperation {
  CRUD = "crud",
  CHECKBOXES = "checkboxes",
  IOT_FETCH = "iot_fetch",
}

export type DeviceSyncsMeta = BaseMeta & {
  remoteOperation: DeviceSyncsRemoteOperation;
  filters?: KeysToCamelCase<DeviceSyncFilters>;
};

export interface DeviceSyncsState {
  loadStatusMap: {
    [DeviceSyncsRemoteOperation.CRUD]: LoadStatus;
    [DeviceSyncsRemoteOperation.CHECKBOXES]: LoadStatus;
    [DeviceSyncsRemoteOperation.IOT_FETCH]: LoadStatus;
  };

  error: string | null;

  entities: {
    [deviceSyncId: number]: DeviceSync;
  };

  ids: number[];
  tableIds: Record<string, number[]>;
  selectedIds: Record<string, number[]>;
}

const entityAdapter = createEntityAdapter<DeviceSync>();

export const initialState: DeviceSyncsState = entityAdapter.getInitialState({
  loadStatusMap: {
    [DeviceSyncsRemoteOperation.CRUD]: LoadStatus.Idle,
    [DeviceSyncsRemoteOperation.CHECKBOXES]: LoadStatus.Idle,
    [DeviceSyncsRemoteOperation.IOT_FETCH]: LoadStatus.Idle,
  },

  error: null,

  tableIds: { [DetailsTableDataTypeEnum.DeploymentDeviceSyncs]: [] },
  selectedIds: { [DetailsTableDataTypeEnum.DeploymentDeviceSyncs]: [] },
}) as DeviceSyncsState;

const getTableTypeOrDefault = (
  tableType: MainTableDataTypeEnum | DetailsTableDataTypeEnum = DetailsTableDataTypeEnum.DeploymentDeviceSyncs,
) => tableType;

const removeEntities = (state: DeviceSyncsState, payload: number[]) => {
  entityAdapter.removeMany(state, payload);

  state.tableIds[DetailsTableDataTypeEnum.DeploymentDeviceSyncs] = state.tableIds[
    DetailsTableDataTypeEnum.DeploymentDeviceSyncs
  ].filter(id => !payload.includes(id));

  state.selectedIds[DetailsTableDataTypeEnum.DeploymentDeviceSyncs] = state.selectedIds[
    DetailsTableDataTypeEnum.DeploymentDeviceSyncs
  ].filter(id => !payload.includes(id));
};

const sliceType = "DEPLOYMENT_DEVICE_SYNCS";
const slice = createSlice({
  name: sliceType,
  initialState,
  reducers: {
    fetchDeviceSyncs: {
      reducer(state, { meta }: PayloadAction<undefined, string, DeviceSyncsMeta>) {
        state.loadStatusMap[meta.remoteOperation] = LoadStatus.Loading;
        state.error = null;

        if (meta.remoteOperation === DeviceSyncsRemoteOperation.CHECKBOXES) {
          state.selectedIds[getTableTypeOrDefault(meta.options?.tableType)] =
            state.tableIds[getTableTypeOrDefault(meta.options?.tableType)];
        }
      },
      prepare(
        filters: KeysToCamelCase<DeviceSyncFilters> = {},
        options: SagaOptions = {},
        remoteOperation: DeviceSyncsRemoteOperation = DeviceSyncsRemoteOperation.CRUD,
      ) {
        return { payload: undefined, meta: { remoteOperation, filters, options } };
      },
    },
    fetchDeviceSyncsFailed: {
      reducer(state, { meta, error }: PayloadAction<undefined, string, DeviceSyncsMeta, string>) {
        state.loadStatusMap[meta.remoteOperation] = LoadStatus.Failed;
        state.error = error;

        if (meta.remoteOperation === DeviceSyncsRemoteOperation.CHECKBOXES) {
          state.selectedIds[getTableTypeOrDefault(meta.options?.tableType)] = [];
        }
      },
      prepare(meta: DeviceSyncsMeta, { message }: ErrorLike) {
        return { payload: undefined, meta, error: message };
      },
    },
    fetchDeviceSyncsSucceeded: {
      reducer(
        state,
        { payload, meta }: PayloadAction<QueryOutput<DeviceSync> | QueryOutput<ApiDeviceSync>, string, DeviceSyncsMeta>,
      ) {
        state.loadStatusMap[meta.remoteOperation] = LoadStatus.Succeeded;
        state.error = null;

        entityAdapter.setMany(state, apiToModelNormalize(payload.items));

        if (meta.remoteOperation === DeviceSyncsRemoteOperation.CHECKBOXES) {
          state.selectedIds[getTableTypeOrDefault(meta.options?.tableType)] = payload.items.map(({ id }) => id);
        } else if (meta.options?.tableType) {
          state.tableIds[getTableTypeOrDefault(meta.options?.tableType)] = payload.items.map(({ id }) => id);
          state.selectedIds[getTableTypeOrDefault(meta.options?.tableType)] = state.selectedIds[
            getTableTypeOrDefault(meta.options?.tableType)
          ].filter(id => state.ids.includes(id));
        }
      },
      prepare(payload: QueryOutput<DeviceSync> | QueryOutput<ApiDeviceSync>, meta: DeviceSyncsMeta) {
        return { payload, meta };
      },
    },
    selectDeviceSyncs: {
      reducer(state, { payload, meta }: PayloadAction<number[], string, DeviceSyncsMeta>) {
        state.loadStatusMap[meta.remoteOperation] = LoadStatus.Succeeded;

        state.selectedIds[getTableTypeOrDefault(meta.options?.tableType)] = payload;
      },
      prepare(deviceSyncsOrIds: DeviceSync[] | ApiDeviceSync[] | number[], options: SagaOptions = {}) {
        return {
          payload: getIds(deviceSyncsOrIds),
          meta: { remoteOperation: DeviceSyncsRemoteOperation.CHECKBOXES, options },
        };
      },
    },
    iotUpdate: (state, { payload }: PayloadAction<ApiDeviceSync>) => {
      if (payload.audit?.deleted_at) {
        removeEntities(state, [payload.id]);
      } else {
        entityAdapter.setOne(state, apiToModelNormalize(normalize(payload)));
      }
    },
  },
});

export const { name: deviceSyncsType, actions: deviceSyncsActions, reducer: deviceSyncsReducer } = slice;

export const entitySelectors = entityAdapter.getSelectors();

export function* rootDeviceSyncsSaga() {
  yield all([watchDeviceSyncs()]);
}
