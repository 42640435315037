import { put, takeLatest } from "redux-saga/effects";
import { push } from "redux-first-history";

import { SETTINGS_PROJECTS_ROUTE, SETTINGS_USERS_ROUTE } from "@ds/constants/router";

import { SETTINGS_INVITATIONS_ROUTE } from "./../../../constants/router/entity-routes";
import { settingsActions } from "./slice";
import { SettingsTableTypeEnum } from "../utils/model";

function* switchRoute({ payload }: ReturnType<typeof settingsActions.switchView>) {
  let routeToSwitch = SETTINGS_PROJECTS_ROUTE;
  switch (payload) {
    case SettingsTableTypeEnum.Projects:
      routeToSwitch = SETTINGS_PROJECTS_ROUTE;
      break;
    case SettingsTableTypeEnum.Users:
      routeToSwitch = SETTINGS_USERS_ROUTE;
      break;
    case SettingsTableTypeEnum.Invitations:
      routeToSwitch = SETTINGS_INVITATIONS_ROUTE;
      break;
  }

  yield put(push(routeToSwitch));
}

export function* watchCommon() {
  yield takeLatest(settingsActions.switchView, switchRoute);
}
