import { type ReactNode } from "react";
import { type DeepMap, type DeepPartial, type DeepRequired, type FieldErrorsImpl, type Path } from "react-hook-form";

import { getProxyFieldPath } from "@ds/utils/entities";
import { getProperty } from "@ds/utils/properties";

export type EditableDetailsCardRowProps<T> = {
  isEditing: boolean;
  dirtyFields: Partial<Readonly<DeepMap<DeepPartial<T>, boolean>>>;
  errors: FieldErrorsImpl<DeepRequired<T>>;
  onEditRow: (field: Path<T>) => () => void;
  onResetRow: (field: Path<T>) => () => void;
  onCancelRow: (field: Path<T>) => () => void;
  onSaveRow: (field: Path<T>) => () => void;
};

export type EditableDetailsCardRowChildProps = {
  input: ReactNode;
  isEditHidden?: boolean;
  width?: string;
  shouldEditWholeCard?: boolean;
  isInlineEditingEnabled?: boolean;
  isEditing: boolean;
  isDirty: boolean;
  error: string;
  onEdit: () => void;
  onReset: () => void;
  onCancel: () => void;
  onSave: () => void;
};

export const EditableDetailsCardRowDecorator = <T,>({
  children,
  field,
  input,
  width,
  shouldHideEdit,
  shouldEditWholeCard,
  shouldDisableInlineEditing,
  isEditing,
  dirtyFields,
  errors,
  onEditRow,
  onResetRow,
  onCancelRow,
  onSaveRow,
}: {
  children: (props: EditableDetailsCardRowChildProps) => JSX.Element;
  entity: T;
  field: Path<T>;
  input: ReactNode;
  width?: string;
  shouldHideEdit?: boolean;
  shouldEditWholeCard?: boolean;
  shouldDisableInlineEditing?: boolean;
} & EditableDetailsCardRowProps<T>) =>
  children({
    input,
    width,
    shouldEditWholeCard,
    isEditHidden: shouldHideEdit,
    isEditing,
    isInlineEditingEnabled: !shouldDisableInlineEditing,
    isDirty: !!getProperty(dirtyFields, field) || !!getProperty(dirtyFields, getProxyFieldPath(field)),
    error: getProperty(errors, field) as string,
    onEdit: onEditRow(field),
    onReset: onResetRow(field),
    onCancel: onCancelRow(field),
    onSave: onSaveRow(field),
  });
