export const REACT_APP_PAGE_SIZE = Number(process.env.REACT_APP_PAGE_SIZE || "10");
export const REACT_APP_PAGINATOR_ROWS_PER_PAGE = (process.env.REACT_APP_PAGINATOR_ROWS_PER_PAGE || "10,25,50")
  .split(",")
  .map(Number);

export const {
  REACT_APP_LOG_LEVEL = "warn",
  REACT_APP_CONCURRENT_API_CALLS = Infinity,
  REACT_APP_API_NAME = "ds",
} = process.env;

const { REACT_APP_BASE_URL } = process.env;
if (!REACT_APP_BASE_URL) {
  throw new Error("REACT_APP_BASE_URL not specified at config");
}

const { REACT_APP_REGION } = process.env;
if (!REACT_APP_REGION) {
  throw new Error("REACT_APP_REGION not specified at config");
}

const { REACT_APP_IOT_ENDPOINT } = process.env;
if (!REACT_APP_IOT_ENDPOINT) {
  throw new Error("REACT_APP_IOT_ENDPOINT not specified at config");
}

const { REACT_APP_GOOGLE_MAPS_API_KEY } = process.env;
if (!REACT_APP_GOOGLE_MAPS_API_KEY) {
  throw new Error("REACT_APP_GOOGLE_MAPS_API_KEY not specified at config");
}

const { REACT_APP_GOOGLE_TIMEZONE_API_KEY } = process.env;
if (!REACT_APP_GOOGLE_TIMEZONE_API_KEY) {
  throw new Error("REACT_APP_GOOGLE_TIMEZONE_API_KEY not specified at config");
}

const { NODE_ENV } = process.env;
export const IS_TESTING = NODE_ENV === "test";

export {
  REACT_APP_BASE_URL,
  REACT_APP_GOOGLE_MAPS_API_KEY,
  REACT_APP_GOOGLE_TIMEZONE_API_KEY,
  REACT_APP_IOT_ENDPOINT,
  REACT_APP_REGION,
};
