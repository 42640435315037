import styled from "styled-components";

import { Chip } from "@ds/components/chips";

export const ChipCount = styled(Chip)<{
  $isSucceeded?: boolean;
  $isFailed?: boolean;
  $isSmall?: boolean;
}>`
  width: max-content;
  color: var(--whiteShadowColor100);
  padding: 0 ${({ $isSmall }) => ($isSmall ? 10 : 12)}px;
  font-size: ${({ $isSmall }) => ($isSmall ? 0.8 : 1)}rem;
  margin-right: ${({ $isSmall }) => ($isSmall ? 5 : 10)}px;

  > .p-chip-text {
    margin: ${({ $isSmall }) => ($isSmall ? 2 : 4)}px 0;
  }

  background-color: ${({ $isSucceeded, $isFailed }) => {
    if ($isSucceeded) {
      return "var(--positiveColor)";
    }

    return $isFailed ? "var(--error-button-background-color)" : "var(--primaryColor100)";
  }};
`;
