import { useMemo, type FC } from "react";

import { useCurrentView } from "@ds/hooks/use-current-view";

import { EntityTypeName } from "@ds/model/entity-model";

import { MainView } from "@ds/components/main-view";
import { TableTabMenu } from "@ds/components/table/table-tab-menu";

import { MainTableDataTypeEnum } from "../../table-data/utils/model";
import { ProjectsTableActionsMenu } from "./components/common/projects-table-actions-menu";
import { ProjectInfo } from "./components/project-info";
import { ProjectsTable } from "./components/projects-table";

export const ProjectsMainPage: FC = () => {
  const [currentView] = useCurrentView();

  const filterOptions = useMemo(
    () => ({
      entityType: EntityTypeName.SETTINGS_PROJECT,
      tableType: MainTableDataTypeEnum.Projects,
    }),
    [],
  );

  if (!currentView) {
    return null;
  }

  return (
    <MainView
      title="Settings"
      filterOptions={filterOptions}
      options={<TableTabMenu tableType={filterOptions.tableType} currentView={currentView} />}
      view={<ProjectsTable />}
      info={<ProjectInfo />}
      actions={<ProjectsTableActionsMenu />}
    />
  );
};
