import { useCallback, type FC } from "react";
import styled from "styled-components";

import { useGetAggregatedEntityIds } from "@ds/hooks/use-get-aggregated-entity-ids";

import { deploymentsActions, DeploymentsRemoteOperation } from "@ds/modules/deployments/redux/slice";
import { createDeploymentModel, DeploymentScopeEnum } from "@ds/modules/deployments/utils/model";

import { ActionsBar } from "@ds/components/actions-bar";
import { Button, ButtonActionType, ButtonType } from "@ds/components/forms/buttons";
import { IconType } from "@ds/components/icons";
import { MenuWithButton } from "@ds/components/menu-dropdown/menu-with-button";
import { confirmWarning } from "@ds/components/popups/confirmation-dialog";
import { EXPERIENCES_ROUTE } from "@ds/constants/router";
import { useRootDispatch, useRootSelector } from "@ds/hooks";
import { pluralizeDeleteMessage } from "@ds/utils/localization/common";
import {
  pluralizeDeployDescription,
  pluralizeDeployMessage,
  pluralizeDeployName,
} from "@ds/utils/localization/deployment";

import { selectExperiencesByIds } from "../../redux/selectors";
import { experiencesActions } from "../../redux/slice";

const Wrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

export const ExperienceDetailsInfoCardActionsMenu: FC<{ experience: Experience }> = ({ experience }) => {
  const aggregatedExperienceIds = useGetAggregatedEntityIds(experience);
  const aggregatedExperiences = useRootSelector(state => selectExperiencesByIds(state, aggregatedExperienceIds));

  const dispatch = useRootDispatch();
  const onDeleteClickHandler = useCallback(
    () =>
      confirmWarning({
        message: pluralizeDeleteMessage("experience", aggregatedExperienceIds.length),
        accept: () => dispatch(experiencesActions.deleteExperiences(aggregatedExperienceIds, EXPERIENCES_ROUTE)),
      }),
    [dispatch, aggregatedExperienceIds],
  );

  const onDeployClickHandler = useCallback(
    () =>
      confirmWarning({
        message: pluralizeDeployMessage("experience", aggregatedExperienceIds.length),
        accept: () =>
          dispatch(
            deploymentsActions.createDeployment(
              {
                ...createDeploymentModel(),
                name: pluralizeDeployName(aggregatedExperiences),
                description: pluralizeDeployDescription(aggregatedExperiences),
                scope: DeploymentScopeEnum.Experience,
                targets: aggregatedExperienceIds,
              },
              { remoteOperation: DeploymentsRemoteOperation.DEPLOYING },
            ),
          ),
      }),
    [dispatch, aggregatedExperienceIds, aggregatedExperiences],
  );

  return (
    <Wrapper>
      <ActionsBar>
        <MenuWithButton
          menuItems={[
            {
              label: "Deploy Content",
              icon: "pi pi-fw pi-sitemap",
              command: onDeployClickHandler,
            },
          ]}
        />

        <Button
          label="Delete"
          ariaLabel="delete button"
          buttonType={ButtonType.Round}
          actionType={ButtonActionType.Error}
          icon={IconType.Delete}
          onClick={onDeleteClickHandler}
        />
      </ActionsBar>
    </Wrapper>
  );
};
