import { Toast as PrimeToast } from "primereact/toast";
import { useEffect, useRef, type FC } from "react";

import { useRootSelector } from "@ds/hooks/redux-hooks";

import { selectToastSettings } from "@ds/modules/notifications/redux/selectors";

export type { ToastMessage } from "primereact/toast";

const NOTIFICATION_TOAST_TIMEOUT = 5000;

export const NotificationToast: FC = () => {
  const toastSettings = useRootSelector(selectToastSettings);
  const toastRef = useRef<PrimeToast>(null);

  useEffect(() => {
    if (toastSettings?.detail) {
      toastRef.current?.show({ ...toastSettings, life: NOTIFICATION_TOAST_TIMEOUT });
    }
  }, [toastSettings]);

  return <PrimeToast ref={toastRef} position="top-center" />;
};
