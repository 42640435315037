import { useCallback, type FC } from "react";
import { Controller, type Control, type UseFormSetValue } from "react-hook-form";

import { ExperiencesAutoComplete } from "@ds/modules/experiences/components/experiences-autocomplete";

import { useDetailsInlineSaving } from "@ds/components/details-view/hooks";
import { LabelWrapper } from "@ds/components/forms/labels";

export const PlayerExperienceControl: FC<{
  control: Control<PlayerFormData>;
  setValue: UseFormSetValue<PlayerFormData>;
  autoFocus?: boolean;
  excludeLabel?: boolean;
  onBlur?: () => void;
}> = ({ control, setValue, autoFocus, excludeLabel, onBlur }) => {
  const onBlurHandler = useDetailsInlineSaving(onBlur);

  const onChangeHandler = useCallback(
    (value: Experience | null) => {
      setValue("player.experience_id", value ? value.id : null, {
        shouldDirty: true,
        shouldTouch: true,
        shouldValidate: true,
      });
    },
    [setValue],
  );

  return (
    <Controller
      name="experience"
      control={control}
      render={({ field: { ref, onChange: _, onBlur: onBlurFn, ...restFields }, fieldState }) => {
        const element = (
          <ExperiencesAutoComplete
            inputRef={ref}
            onChange={onChangeHandler}
            autoFocus={autoFocus}
            onBlur={onBlurHandler(onBlurFn)}
            {...restFields}
          />
        );

        if (excludeLabel) {
          return element;
        }

        return (
          <LabelWrapper label="Experience" className="col-8" error={fieldState.error}>
            {element}
          </LabelWrapper>
        );
      }}
    />
  );
};
