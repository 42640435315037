import { DSApi } from "@ds/services/ds-api";
import { type ApiService, type ApiServiceConfig } from "@ds/services/types";

interface ThumbnailsServiceConfig extends ApiServiceConfig {
  api: DSApi;
}

class ThumbnailsService implements ApiService {
  private config: ThumbnailsServiceConfig = {
    api: {} as DSApi,
  };

  configure(cfg: ThumbnailsServiceConfig) {
    this.config = { ...cfg };
  }

  async createThumbnail(thumbnail: Thumbnail) {
    return this.config.api.post<Thumbnail>("/thumbnails/", thumbnail);
  }

  async deleteThumbnail(thumbnail: Thumbnail) {
    return this.config.api.delete<Thumbnail>(`/thumbnails/${thumbnail.id}`);
  }
}

export const thumbnailsService = new ThumbnailsService();
