import { createSelector } from "@reduxjs/toolkit";

import { DetailsTableDataTypeEnum, MainTableDataTypeEnum } from "@ds/modules/table-data/utils/model";

import { type RootState } from "@ds/store/bootstrap";
import { selectorsExtractIds, selectorsOutputEntitiesByIds } from "@ds/store/global-selectors";

import { getAggregatedModel } from "@ds/utils/entities";
import { LoadStatus } from "@ds/utils/reducer";

import { PlaylistsRemoteOperation, entitySelectors } from "./slice";

const selectBaseState = (state: RootState) => state.content.playlists;
const selectLoadStatus = (state: RootState, operation = PlaylistsRemoteOperation.CRUD) =>
  selectBaseState(state).loadStatusMap[operation];

export const selectPlaylistsIsLoading = (state: RootState, operation = PlaylistsRemoteOperation.CRUD) =>
  selectLoadStatus(state, operation) === LoadStatus.Loading;

export const selectPlaylistsError = (state: RootState) => selectBaseState(state).error;

export const selectTablePlaylistIds = (
  state: RootState,
  tableType: MainTableDataTypeEnum | DetailsTableDataTypeEnum = MainTableDataTypeEnum.ContentPlaylists,
) => selectBaseState(state).tableIds[tableType];

export const selectSelectedPlaylistIds = (
  state: RootState,
  tableType: MainTableDataTypeEnum | DetailsTableDataTypeEnum = MainTableDataTypeEnum.ContentPlaylists,
) => selectBaseState(state).selectedIds[tableType];

export const selectPlaylistById = (state: RootState, id: number) =>
  entitySelectors.selectById(selectBaseState(state), id) as Playlist;

export const selectPlaylistIds = (state: RootState) => entitySelectors.selectIds(selectBaseState(state));
export const selectPlaylists = (state: RootState) => entitySelectors.selectAll(selectBaseState(state));

export const selectPlaylistsByIds = createSelector(
  selectBaseState,
  selectorsExtractIds,
  selectorsOutputEntitiesByIds(() => entitySelectors),
);

export const selectTablePlaylists = createSelector(
  selectBaseState,
  selectTablePlaylistIds,
  selectorsOutputEntitiesByIds(() => entitySelectors),
);

export const selectSelectedPlaylists = createSelector(
  selectBaseState,
  selectSelectedPlaylistIds,
  selectorsOutputEntitiesByIds(() => entitySelectors),
);

export const selectAggregatedPlaylistByIds = createSelector(selectBaseState, selectorsExtractIds, (state, ids) =>
  ids?.length ? getAggregatedModel(selectorsOutputEntitiesByIds(entitySelectors)(state, ids)) : null,
);

export const selectAggregatedPlaylistForSelectedEntities = createSelector(selectSelectedPlaylists, selectedPlaylists =>
  getAggregatedModel(selectedPlaylists),
);
