import { type FC } from "react";

import { selectDeploymentsError, selectDeploymentsIsLoading } from "@ds/modules/deployments/redux/selectors";

import { WizardFinalStepWrapper, WizardPage, type WizardChildStepProps } from "@ds/components/wizard";
import { getStatusMessage } from "@ds/components/wizard/helpers";
import { useRootSelector } from "@ds/hooks";

export const StepFinal: FC<WizardChildStepProps> = ({ firstStep, ...rest }) => {
  const isSaving = useRootSelector(selectDeploymentsIsLoading);
  const errorMessage = useRootSelector(selectDeploymentsError);

  return (
    <WizardPage {...rest} isValid={!errorMessage} isLoading={isSaving} previousStep={firstStep}>
      <WizardFinalStepWrapper>
        {getStatusMessage({
          errorMessage,
          isLoading: isSaving,
          loadingText: "Scheduling deployments",
          successText: "Deployments scheduled successfully",
          failedText: "Scheduling deployments failed",
        })}
      </WizardFinalStepWrapper>
    </WizardPage>
  );
};
