import { createSlice, type PayloadAction } from "@reduxjs/toolkit";
import { all } from "redux-saga/effects";

import { SettingsTableTypeEnum } from "../utils/model";
import { watchCommon } from "./sagas";

interface SettingsState {
  viewType: SettingsTableTypeEnum | undefined;
}

const initialState: SettingsState = {
  viewType: undefined,
};

const sliceType = "SETTINGS";
const slice = createSlice({
  name: sliceType,
  initialState,
  reducers: {
    switchView: (state, { payload }: PayloadAction<SettingsTableTypeEnum>) => {
      state.viewType = payload;
    },
  },
});

export const { name: settingsType, actions: settingsActions, reducer: settingsReducer } = slice;

export function* rootSettingsSaga() {
  yield all([watchCommon()]);
}
