import { sumBy } from "lodash";
import { useMemo, type FC } from "react";

import { useGetAggregatedEntityIds } from "@ds/hooks/use-get-aggregated-entity-ids";

import { AggregatedValue } from "@ds/components/entities";
import { InfoAttribute, InfoSection } from "@ds/components/info-view";
import { TrimmedContent } from "@ds/components/trimmed-text";
import { useRootSelector } from "@ds/hooks";
import { formatAbsoluteDate, formatRelativeDate } from "@ds/utils/date";
import { getProperty } from "@ds/utils/properties";

import { selectDeploymentsByIds } from "../../redux/selectors";
import { ChipCount } from "./chip-count";
import { getDeploymentStatusIndicator } from "./status-indicator";

export const DeploymentInfoSection: FC<{ deployment: Deployment }> = ({ deployment }) => {
  const aggregatedDeploymentIds = useGetAggregatedEntityIds(deployment);
  const aggregatedDeployments = useRootSelector(state => selectDeploymentsByIds(state, aggregatedDeploymentIds));

  const numberOfPlayers = useMemo(() => {
    const totalCount = sumBy(aggregatedDeployments, "total_count");
    const succeededCount = sumBy(aggregatedDeployments, "succeeded_count");
    const failedCount = sumBy(aggregatedDeployments, "failed_count");

    return (
      <>
        <TrimmedContent content="Total">
          <ChipCount label={`${totalCount}`} $isSmall />
        </TrimmedContent>

        {succeededCount > 0 && (
          <TrimmedContent content="Succeeded">
            <ChipCount label={`${succeededCount}`} $isSmall $isSucceeded />
          </TrimmedContent>
        )}

        {failedCount > 0 && (
          <TrimmedContent content="Failed">
            <ChipCount label={`${failedCount}`} $isSmall $isFailed />
          </TrimmedContent>
        )}
      </>
    );
  }, [aggregatedDeployments]);

  return (
    <InfoSection title="Deployment Info" icon="pi pi-info-circle">
      <InfoAttribute
        name="Status"
        value={
          <AggregatedValue
            entity={deployment}
            field="status"
            formatter={entity => getDeploymentStatusIndicator(entity)}
          />
        }
      />

      <InfoAttribute name="Message" value={<AggregatedValue entity={deployment} field="message" />} />
      <InfoAttribute
        name="Schedule"
        value={
          <AggregatedValue
            entity={deployment}
            field="params.scheduled_time"
            formatter={(entity, field) => formatAbsoluteDate(getProperty(entity, field))}
          />
        }
      />

      <InfoAttribute
        name="Started"
        value={
          <AggregatedValue
            entity={deployment}
            field="started_at"
            formatter={(entity, field) => formatRelativeDate(getProperty(entity, field))}
          />
        }
      />

      <InfoAttribute
        name="Ended"
        value={
          <AggregatedValue
            entity={deployment}
            field="ended_at"
            formatter={(entity, field) => formatRelativeDate(getProperty(entity, field))}
          />
        }
      />

      <InfoAttribute name="# Players" value={numberOfPlayers} />
    </InfoSection>
  );
};
