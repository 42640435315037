import * as yup from "yup";

import { type Shape } from "@ds/utils/validation";

const emailSchema = yup.string().email("Invalid email format").required("Please specify email");

const projectsSchema = yup
  .array()
  .of(yup.object().shape<Shape<Project>>({}).required())
  .min(1, "Please specify at least one project");

export const createValidationSchema = {
  step1: yup.object().shape<Shape<InvitationFormData>>({
    invitation: yup.object().shape<Shape<Invitation>>({
      email: emailSchema,
    }),
    projects: projectsSchema,
  }),
};

export const updateValidationSchema = {
  info: yup.object().shape<Shape<Invitation>>({}),
  projects: yup.object().shape<Shape<{ projects: Project[] }>>({
    projects: projectsSchema,
  }),
};
