import { yupResolver } from "@hookform/resolvers/yup";
import { type FC } from "react";
import { type DeepPartial } from "react-hook-form";

import { isAggregatedEntity } from "@ds/model/entity-model";

import {
  DetailsCard,
  DetailsCardEditActionsMenu,
  DetailsCardRow,
  EditableDetailsCardDecorator,
  EditableDetailsCardRowDecorator,
} from "@ds/components/details-view";
import { AggregatedValue } from "@ds/components/entities";
import { formatAbsoluteDate, formatRelativeDate } from "@ds/utils/date";
import { getProperty } from "@ds/utils/properties";

import { updateValidationSchema } from "../../utils/validation-schema";
import { getPlayerStatusIndicator } from "../common/status-indicator";
import { PlayerNameControl } from "../forms/controls/player-name-control";
import { PlayerDetailsInfoCardActionsMenu } from "./player-details-info-card-actions-menu";

export const PlayerDetailsInfoCard: FC<{
  player: Player;
  onSubmit: (changedFields: DeepPartial<Player>) => void;
}> = ({ player, onSubmit }) => (
  <EditableDetailsCardDecorator
    initialValue={player}
    validationResolver={yupResolver(updateValidationSchema.info)}
    onSubmit={onSubmit}
  >
    {cardProps => (
      <DetailsCard
        title="Info"
        icon="info-circle"
        actions={
          !cardProps.isDirty && !cardProps.isEditing ? (
            <PlayerDetailsInfoCardActionsMenu player={cardProps.entity} />
          ) : (
            <DetailsCardEditActionsMenu
              isEditing={cardProps.isEditing}
              isDirty={cardProps.isDirty}
              onReset={cardProps.onResetForm}
              onSubmit={cardProps.handleSubmit(cardProps.onSubmitForm)}
            />
          )
        }
      >
        <EditableDetailsCardRowDecorator
          field="name"
          input={<PlayerNameControl control={cardProps.control} autoFocus excludeLabel />}
          shouldHideEdit={isAggregatedEntity(cardProps.entity)}
          {...cardProps}
        >
          {rowProps => (
            <DetailsCardRow label="Name" editableSettings={rowProps} isRequired>
              <AggregatedValue entity={cardProps.entity} field="name" shouldShowEditedValue={rowProps.isDirty} />
            </DetailsCardRow>
          )}
        </EditableDetailsCardRowDecorator>

        <DetailsCardRow label="Status">
          <AggregatedValue entity={player} field="state" formatter={entity => getPlayerStatusIndicator(entity)} />
        </DetailsCardRow>

        <DetailsCardRow label="Applet">
          <AggregatedValue entity={cardProps.entity} field="applet_name" />
        </DetailsCardRow>

        <DetailsCardRow label="Serial #">
          <AggregatedValue entity={cardProps.entity} field="serial_number" />
        </DetailsCardRow>

        <DetailsCardRow label="IP Address">
          <AggregatedValue entity={cardProps.entity} field="ip_address" />
        </DetailsCardRow>

        <DetailsCardRow label="MAC Address">
          <AggregatedValue entity={cardProps.entity} field="mac_address" />
        </DetailsCardRow>

        <DetailsCardRow label="OS Version">
          <AggregatedValue entity={cardProps.entity} field="os_version" />
        </DetailsCardRow>

        <DetailsCardRow label="Code Version">
          <AggregatedValue entity={cardProps.entity} field="code_version" />
        </DetailsCardRow>

        <DetailsCardRow label="Model">
          <AggregatedValue entity={cardProps.entity} field="model" />
        </DetailsCardRow>

        <DetailsCardRow label="Manufacturer">
          <AggregatedValue entity={cardProps.entity} field="make" />
        </DetailsCardRow>

        <DetailsCardRow label="Video Mode">
          <AggregatedValue entity={cardProps.entity} field="video_mode" />
        </DetailsCardRow>

        <DetailsCardRow label="HDMI Status">
          <AggregatedValue entity={cardProps.entity} field="hdmi_status" />
        </DetailsCardRow>

        <DetailsCardRow label="Booted At">
          <AggregatedValue
            entity={cardProps.entity}
            field="boot_time"
            formatter={(entity, field) => formatAbsoluteDate(getProperty(entity, field))}
          />
        </DetailsCardRow>

        <DetailsCardRow label="Last Updated">
          <AggregatedValue
            entity={cardProps.entity}
            field="audit.updated_at"
            formatter={(entity, field) => formatRelativeDate(getProperty(entity, field))}
          />
        </DetailsCardRow>
      </DetailsCard>
    )}
  </EditableDetailsCardDecorator>
);
