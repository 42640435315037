import { useMemo } from "react";

import { getAggregatedEntityIds } from "@ds/utils/entities";

export const useGetAggregatedEntityIds = ((entity: AggregatedEntity<Entity> | null) =>
  useMemo(() => getAggregatedEntityIds(entity as AggregatedEntity<Entity>), [entity])) as {
  (entity: null): [];
  (entity: AggregatedEntity<Entity<number>, number> | null): number[];
  (entity: AggregatedEntity<Entity<string>, string> | null): string[];
};
