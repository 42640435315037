import { type FC, type ReactNode } from "react";
import styled from "styled-components";

const StyledInputGroup = styled.div`
  .p-inputtext,
  .p-inputwrapper,
  .p-input {
    width: 100%;
  }
`;

export const InputGroup: FC<{ children: ReactNode }> = ({ children }) => (
  <StyledInputGroup className="p-inputgroup">{children}</StyledInputGroup>
);
