import { type FC } from "react";
import styled from "styled-components";

import { StatusEnum } from "@ds/model/status-model";

import { MultipleValuesLabel } from "@ds/components/forms/labels/common";
import { ProgressIndicator } from "@ds/components/progress-indicator";
import { isMultipleFieldValuesInAggregatedEntity } from "@ds/utils/entities";
import { getPrintableStatus } from "@ds/utils/status-helpers";

import { getDeploymentStatusIndicator } from "./status-indicator";

const DeploymentStatusWrapper = styled.div`
  display: flex;
  align-items: center;
`;

export const DeploymentStatus: FC<{ deployment: Deployment }> = ({ deployment }) => {
  if (isMultipleFieldValuesInAggregatedEntity(deployment, "status")) {
    return <MultipleValuesLabel />;
  }

  if (deployment.status === StatusEnum.Executing || deployment.status === StatusEnum.Initializing) {
    return (
      <DeploymentStatusWrapper>
        <ProgressIndicator status={deployment.status as StatusEnum} />
        <span>{getPrintableStatus(deployment.status)}</span>
      </DeploymentStatusWrapper>
    );
  }

  return getDeploymentStatusIndicator(deployment);
};
