import { type FC } from "react";

import { DetailsCard, DetailsCardRow } from "@ds/components/details-view";
import { AggregatedValue } from "@ds/components/entities";

import { DeploymentTypeEnum } from "../../utils/model";

export const DeploymentDetailsCodeOrFirmwareCard: FC<{
  deployment: Deployment;
  deploymentType: DeploymentType;
}> = ({ deployment, deploymentType }) => {
  let title = "";
  let icon = "";

  if (deploymentType === DeploymentTypeEnum.Code) {
    title = "Code";
    icon = "code";
  }

  if (deploymentType === DeploymentTypeEnum.Firmware) {
    title = "Firmware";
    icon = "cog";
  }

  if (!title || !icon) {
    return null;
  }

  return (
    <DetailsCard title={title} icon={icon}>
      <DetailsCardRow label="File name">
        <AggregatedValue entity={deployment} field="details.file_name" />
      </DetailsCardRow>
      <DetailsCardRow label="Version">
        <AggregatedValue entity={deployment} field="details.version" />
      </DetailsCardRow>
    </DetailsCard>
  );
};
