import { AxiosError } from "axios";

import { logger } from "../logger";

class ApiAxiosError extends Error implements ApiError {
  response?: ApiErrorData;

  status?: number;

  constructor(error: AxiosError<ApiErrorData>) {
    super();
    this.response = error.response?.data;
    this.status = error.response?.status;
    this.message = ApiAxiosError.getFormattedMessage(error);
    Object.setPrototypeOf(this, new.target.prototype);
  }

  private static getFormattedMessage(error: AxiosError<ApiErrorData>): string {
    logger.error(error);
    let msg = error.message || "";
    if (error.response?.data?.error) {
      msg = `${error.response.data.error.message} (${error.response.data.error.code})`;
    } else if (error.request && msg === "") {
      msg = `HTTP request failed. Reason: no response received from the server`;
    }

    return msg;
  }
}

export const throwAxiosErr = (err: AxiosError<ApiErrorData>): never => {
  logger.error(err);
  throw new ApiAxiosError(err);
};
