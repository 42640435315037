import { type FC } from "react";

import { AggregatedValue } from "@ds/components/entities";
import { InfoAttribute, InfoSection } from "@ds/components/info-view";

import { getInvitationStatusIndicator } from "./status-indicator";

export const InvitationInfoSection: FC<{ invitation: Invitation }> = ({ invitation }) => (
  <InfoSection title="Invitation Info" icon="pi pi-info-circle">
    <InfoAttribute
      name="Status"
      value={
        <AggregatedValue
          entity={invitation}
          field="status"
          formatter={entity => getInvitationStatusIndicator(entity)}
        />
      }
    />

    <InfoAttribute name="User Role" value={<AggregatedValue entity={invitation} field="user_role" />} />
  </InfoSection>
);
