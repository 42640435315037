import { useCallback, type FC } from "react";
import { type DeepPartial } from "react-hook-form";

import { useGetAggregatedEntityIds } from "@ds/hooks/use-get-aggregated-entity-ids";
import { useParseQueryIds } from "@ds/hooks/use-parse-query-ids";
import { useReduxEntitiesFetch } from "@ds/hooks/use-redux-entities-fetch";

import { EntityTypeName } from "@ds/model/entity-model";

import { DetailsView } from "@ds/components/details-view";
import { AbsoluteLoader } from "@ds/components/loaders/loading";
import { PROJECT_ID_PARAM } from "@ds/constants/router";
import { QueryParamType, useDeepRootSelector, useRootDispatch, useRootSelector } from "@ds/hooks";
import { pluralizeTotalSelectedTitle } from "@ds/utils/localization/common";
import { isMultiple } from "@ds/utils/numbers";

import { MainTableDataTypeEnum } from "../../table-data/utils/model";
import { selectCurrentUserIsTenantAdmin } from "../users/redux/selectors";
import { ProjectDetailsFormationCard } from "./components/forms/details/project-details-formation-card";
import { ProjectDetailsInfoCard } from "./components/forms/details/project-details-info-card";
import { ProjectDetailsSettingsCard } from "./components/forms/details/project-details-settings-card";
import { selectAggregatedProjectByIds, selectProjectsIsLoading } from "./redux/selectors/common-selectors";
import { selectTemplateExperienceFormationEntriesFilteredPerGroup } from "./redux/selectors/experience-formation-selectors";
import { projectsActions } from "./redux/slice";

export const ProjectDetailsPage: FC = () => {
  const queryIds = useParseQueryIds(PROJECT_ID_PARAM, QueryParamType.Number);
  const project = useDeepRootSelector(state => selectAggregatedProjectByIds(state, queryIds));
  const aggregatedProjectIds = useGetAggregatedEntityIds(project);

  const [infoPropertiesEntries, otherPropertiesPerGroup] = useDeepRootSelector(state => {
    const [infoData, otherData] = selectTemplateExperienceFormationEntriesFilteredPerGroup(
      state,
      project?.id,
      "details",
      ["Info", "Settings"],
    );

    return [Object.entries(infoData).flatMap(([, properties]) => properties), otherData] as const;
  });

  const isEditAllowed = useRootSelector(selectCurrentUserIsTenantAdmin);
  const isLoading = useRootSelector(selectProjectsIsLoading);

  const [toggleRefetch] = useReduxEntitiesFetch(
    { entityType: EntityTypeName.SETTINGS_PROJECT, filters: { id: queryIds } },
    { initialLoad: true },
  );

  const dispatch = useRootDispatch();
  const onSubmitHandler = useCallback(
    (changedFields: DeepPartial<Project>) =>
      dispatch(projectsActions.updateProjects(aggregatedProjectIds, changedFields)),
    [dispatch, aggregatedProjectIds],
  );

  if (!project) {
    return <AbsoluteLoader isLoading />;
  }

  return (
    <DetailsView
      title={
        isMultiple(project?.metadata?.totalAggregated)
          ? pluralizeTotalSelectedTitle("project", project?.metadata?.totalAggregated)
          : project.name
      }
      tableType={MainTableDataTypeEnum.Projects}
      isLoading={isLoading}
      onRefresh={toggleRefetch}
    >
      <ProjectDetailsInfoCard
        project={project}
        isEditForbidden={!isEditAllowed}
        templateProperties={infoPropertiesEntries}
        onSubmit={onSubmitHandler}
      />

      <ProjectDetailsSettingsCard project={project} isEditForbidden={!isEditAllowed} onSubmit={onSubmitHandler} />

      {Object.entries(otherPropertiesPerGroup).map(([group, properties]) => (
        <ProjectDetailsFormationCard
          key={`project-template-experience-formation-card-${group}`}
          cardTitle={properties[0][1].metadata?.details?.group_title || ""}
          project={project}
          isEditForbidden={!isEditAllowed}
          templateProperties={properties}
          onSubmit={onSubmitHandler}
        />
      ))}
    </DetailsView>
  );
};
