import { createSlice, type PayloadAction } from "@reduxjs/toolkit";

import { type ToastSettings } from "../utils/model";

export type NotificationsState = {
  toastSettings?: ToastSettings;
};

const initialState: NotificationsState = {};

const sliceType = "NOTIFICATIONS";
const slice = createSlice({
  name: sliceType,
  initialState,
  reducers: {
    resetNotifications: state => {
      state.toastSettings = undefined;
    },
    showToast: (state, { payload }: PayloadAction<ToastSettings>) => {
      state.toastSettings = payload;
    },
  },
});

export const { name: notificationsType, actions: notificationsActions, reducer: notificationsReducer } = slice;
