import { isString } from "lodash";
import { type FC, type ReactNode } from "react";
import styled from "styled-components";

import { WizardStep, type WizardStepProps } from "./wizard-step";
import { WizardTitle } from "./wizard-title";

const Wrapper = styled.div`
  display: flex;
  flex-flow: column;
  justify-content: space-between;
  height: 75vh;
`;

export const WizardPage: FC<
  {
    title?: string;
    isLoading?: boolean;
    children: ReactNode;
  } & Omit<WizardStepProps, "className">
> = ({ children, title, isLoading = false, ...rest }) => (
  <Wrapper>
    <div className="flex flex-column align-items-center col overflow-auto">
      {isString(title) && <WizardTitle>{title}</WizardTitle>}
      {children}
    </div>
    {!isLoading && <WizardStep className="col-10 col-align-center align-items-end" {...rest} />}
  </Wrapper>
);
