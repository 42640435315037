import { uniq } from "lodash";
import { useEffect, useMemo, type FC } from "react";

import { useGetAggregatedEntityIds } from "@ds/hooks/use-get-aggregated-entity-ids";

import {
  selectProjectsByIds,
  selectProjectsIsLoading,
} from "@ds/modules/settings/projects/redux/selectors/common-selectors";
import { projectsActions } from "@ds/modules/settings/projects/redux/slice";

import { MultipleValuesLabelWithLink, UnassignedLabel } from "@ds/components/forms/labels/common";
import { InfoSection } from "@ds/components/info-view";
import { SpinnerLoader2 } from "@ds/components/loaders/loaders";
import { TextLink } from "@ds/components/text-link";
import { useDeepRootSelector, useRootDispatch, useRootSelector } from "@ds/hooks";
import { isMultipleFieldValuesInAggregatedEntity } from "@ds/utils/entities";
import { composeDetailsRouteByEntityType } from "@ds/utils/router";

import { selectUsersByIds } from "../../redux/selectors";

export const UserProjectsInfoSection: FC<{ user: User }> = ({ user }) => {
  const aggregatedUserIds = useGetAggregatedEntityIds(user);
  const [aggregatedUserProjectsIds, aggregatedUserProjects] = useDeepRootSelector(state => {
    const aggregatedUsers = selectUsersByIds(state, aggregatedUserIds);
    const aggregatedProjectsIds = uniq(aggregatedUsers.flatMap(({ project_ids }) => project_ids));
    return [aggregatedProjectsIds, selectProjectsByIds(state, aggregatedProjectsIds)] as const;
  });

  const isLoading = useRootSelector(selectProjectsIsLoading);

  const dispatch = useRootDispatch();
  useEffect(() => {
    if (aggregatedUserProjectsIds.length) {
      dispatch(projectsActions.fetchProjects({ id: aggregatedUserProjectsIds }));
    }
  }, [dispatch, aggregatedUserProjectsIds]);

  const infoBody = useMemo(() => {
    if (isLoading) {
      return <SpinnerLoader2 />;
    }

    const hasMultipleValues = isMultipleFieldValuesInAggregatedEntity(user, "project_ids");
    if (!aggregatedUserProjects.length) {
      return <UnassignedLabel text="User(s) has no assigned projects" />;
    } else if (hasMultipleValues) {
      return <MultipleValuesLabelWithLink to={composeDetailsRouteByEntityType(aggregatedUserProjects)} />;
    }

    return aggregatedUserProjects?.map(entry => (
      <div key={`user-info-project-${entry.id}`} className="mb-1">
        <TextLink to={composeDetailsRouteByEntityType(entry)}>{entry.name}</TextLink>
      </div>
    ));
  }, [isLoading, user, aggregatedUserProjects]);

  return (
    <InfoSection title="Projects" icon="pi pi-list">
      {infoBody}
    </InfoSection>
  );
};
