import { useEffect, useMemo, type FC } from "react";

import { useGetAggregatedEntityIds } from "@ds/hooks/use-get-aggregated-entity-ids";

import { isAggregatedEntity } from "@ds/model/entity-model";

import { getPeripheralStatusIndicatorWithNameAndLink } from "@ds/modules/devices/peripherals/components/common/status-indicator";
import {
  selectPeripheralsByPlayerIds,
  selectPeripheralsIsLoading,
} from "@ds/modules/devices/peripherals/redux/selectors";
import { peripheralsActions } from "@ds/modules/devices/peripherals/redux/slice";

import { MultipleValuesLabelWithLink, UnassignedLabel } from "@ds/components/forms/labels/common";
import { InfoSection } from "@ds/components/info-view";
import { SpinnerLoader2 } from "@ds/components/loaders/loaders";
import { useDeepRootSelector, useRootDispatch, useRootSelector } from "@ds/hooks";
import { composeDetailsRouteByEntityType } from "@ds/utils/router";

export const PlayerPeripheralsInfoSection: FC<{ player: Player }> = ({ player }) => {
  const aggregatedPlayerIds = useGetAggregatedEntityIds(player);
  const aggregatedPlayersPeripherals = useDeepRootSelector(state =>
    selectPeripheralsByPlayerIds(state, aggregatedPlayerIds),
  );

  const isLoading = useRootSelector(selectPeripheralsIsLoading);

  const dispatch = useRootDispatch();
  useEffect(() => {
    if (aggregatedPlayerIds.length) {
      dispatch(peripheralsActions.fetchPeripherals({ deviceId: aggregatedPlayerIds }));
    }
  }, [dispatch, aggregatedPlayerIds]);

  const infoBody = useMemo(() => {
    if (isLoading) {
      return <SpinnerLoader2 />;
    }

    if (isAggregatedEntity(player)) {
      return <MultipleValuesLabelWithLink to={composeDetailsRouteByEntityType(aggregatedPlayersPeripherals)} />;
    } else if (!aggregatedPlayersPeripherals.length) {
      return <UnassignedLabel text="Player(s) has no peripherals" />;
    }

    return aggregatedPlayersPeripherals?.map(entry => (
      <div key={`player-info-peripheral-${entry?.id}`} className="mb-1">
        {getPeripheralStatusIndicatorWithNameAndLink(entry)}
      </div>
    ));
  }, [isLoading, player, aggregatedPlayersPeripherals]);

  return (
    <InfoSection title="Peripherals" icon="pi pi-sitemap">
      {infoBody}
    </InfoSection>
  );
};
