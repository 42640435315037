import { yupResolver } from "@hookform/resolvers/yup";
import { useCallback, useEffect, type FC } from "react";
import { useForm } from "react-hook-form";

import { useCreateDeployment } from "@ds/modules/deployments/components/forms/create-deployment-form/create-deployment-context";
import { createValidationSchema } from "@ds/modules/deployments/utils/validation-schema";

import { WizardPage, type WizardChildStepProps } from "@ds/components/wizard";

import { DeploymentTypeControl } from "../controls/deployment-type-control";

export const Step1Type: FC<WizardChildStepProps> = ({ nextStep, ...rest }) => {
  const [currentDeployment, submitDeployment] = useCreateDeployment();
  const { control, reset, getValues } = useForm<Deployment>({
    mode: "onSubmit",
    reValidateMode: "onSubmit",
    defaultValues: currentDeployment,
    resolver: yupResolver(createValidationSchema.step1),
  });

  useEffect(() => void reset(currentDeployment), [currentDeployment, reset]);

  const onNextClickHandler = useCallback(() => {
    submitDeployment(getValues());
    if (nextStep) {
      nextStep();
    }
  }, [submitDeployment, getValues, nextStep]);

  return (
    <WizardPage {...rest} title="Create New Deployment" nextStep={onNextClickHandler}>
      <DeploymentTypeControl control={control} />
    </WizardPage>
  );
};
