import { nanoid } from "@reduxjs/toolkit";
import { useCallback, type ComponentProps, type FC } from "react";

import { LocationsAutoComplete } from "@ds/modules/locations/components/locations-autocomplete";
import { selectLocationsByIds } from "@ds/modules/locations/redux/selectors";

import { AutoCompleteMultipleDecorator } from "@ds/components/forms/autocomplete";
import { useRootSelector } from "@ds/hooks";

import { Wrapper } from "./styles";
import { type DeploymentTargetsProps } from "./types";

export const LocationTargets: FC<DeploymentTargetsProps> = ({ inputRef, value, onChange }) => {
  const locations = useRootSelector(state => selectLocationsByIds(state, value));

  const handleChange = useCallback(
    (entities: Locality[]) => {
      const ids = entities.map(({ id }) => id);
      onChange(ids);
    },
    [onChange],
  );

  return (
    <Wrapper>
      <AutoCompleteMultipleDecorator
        inputRef={inputRef}
        value={locations}
        onChange={handleChange}
        chipsMapper={({ name }: Locality) => [`deployment-location-chip-${nanoid()}`, name]}
      >
        {(props: ComponentProps<typeof LocationsAutoComplete>) => <LocationsAutoComplete {...props} />}
      </AutoCompleteMultipleDecorator>
    </Wrapper>
  );
};
