import { yupResolver } from "@hookform/resolvers/yup";
import { type FC } from "react";
import { type DeepPartial } from "react-hook-form";

import { UserRoleControl } from "@ds/modules/settings/users/components/forms/controls/user-role-control";

import {
  DetailsCard,
  DetailsCardEditActionsMenu,
  DetailsCardRow,
  EditableDetailsCardDecorator,
  EditableDetailsCardRowDecorator,
} from "@ds/components/details-view";
import { AggregatedValue } from "@ds/components/entities";
import { formatRelativeDate } from "@ds/utils/date";
import { getProperty } from "@ds/utils/properties";

import { updateValidationSchema } from "../../utils/validation-schema";
import { getInvitationStatusIndicator } from "../common/status-indicator";
import { InvitationDetailsInfoCardActionsMenu } from "./invitation-details-info-card-actions-menu";

export const InvitationDetailsInfoCard: FC<{
  invitation: Invitation;
  onSubmit: (changedFields: DeepPartial<Invitation>) => void;
}> = ({ invitation, onSubmit }) => (
  <EditableDetailsCardDecorator
    initialValue={invitation}
    validationResolver={yupResolver(updateValidationSchema.info)}
    onSubmit={onSubmit}
  >
    {cardProps => (
      <DetailsCard
        title="Info"
        icon="info-circle"
        actions={
          !cardProps.isDirty && !cardProps.isEditing ? (
            <InvitationDetailsInfoCardActionsMenu invitation={invitation} />
          ) : (
            <DetailsCardEditActionsMenu
              isEditing={cardProps.isEditing}
              isDirty={cardProps.isDirty}
              onReset={cardProps.onResetForm}
              onSubmit={cardProps.handleSubmit(cardProps.onSubmitForm)}
            />
          )
        }
      >
        <DetailsCardRow label="Email">
          <AggregatedValue entity={cardProps.entity} field="email" />
        </DetailsCardRow>

        <DetailsCardRow label="Status">
          <AggregatedValue
            entity={invitation}
            field="status"
            formatter={entity => getInvitationStatusIndicator(entity)}
          />
        </DetailsCardRow>

        <EditableDetailsCardRowDecorator
          field="user_role"
          input={
            <UserRoleControl name="user_role" label="User Role" control={cardProps.control} autoFocus excludeLabel />
          }
          {...cardProps}
        >
          {rowProps => (
            <DetailsCardRow label="User Role" editableSettings={rowProps} isRequired>
              <AggregatedValue entity={cardProps.entity} field="user_role" shouldShowEditedValue={rowProps.isDirty} />
            </DetailsCardRow>
          )}
        </EditableDetailsCardRowDecorator>

        <DetailsCardRow label="Last Updated">
          <AggregatedValue
            entity={cardProps.entity}
            field="audit.updated_at"
            formatter={(entity, field) => formatRelativeDate(getProperty(entity, field))}
          />
        </DetailsCardRow>
      </DetailsCard>
    )}
  </EditableDetailsCardDecorator>
);
