import { isArray } from "lodash";

import { EntityTypeName, isEntity } from "@ds/model/entity-model";

import { isPlainObject } from "@ds/utils/type-guards/common-guards";

export enum ApiContentTypeEnum {
  Video = "^video",
  Image = "^image",
  Audio = "^audio",
}

export enum ContentTypeNameEnum {
  Video = "video",
  Image = "image",
  Audio = "audio",
}

export const createContentAssetModel = (options?: Partial<ContentAsset>): ContentAsset => ({
  __typename: EntityTypeName.CONTENT_ASSET,
  id: options?.id || 0,
  project_id: options?.project_id || 0,
  name: options?.name || "",
  key: options?.key || "",
  file_name: options?.file_name || "",
  file_size: options?.file_size || 0,
  content_type: options?.content_type || "",
});

export const isContentAsset = (obj: unknown): obj is ContentAsset =>
  isEntity(obj) && obj.__typename === EntityTypeName.CONTENT_ASSET;

export const isContentAssetArray = (obj: unknown): obj is ContentAsset[] => isArray(obj) && obj.every(isContentAsset);

export const isContentAssetQueryOutput = (obj: unknown): obj is QueryOutput<ContentAsset> =>
  isPlainObject(obj) && "items" in obj && isContentAssetArray(obj.items);
