import * as yup from "yup";

import {
  getFormationPropertiesValidationSchema,
  type FormationPropertyValidationData,
  type Shape,
} from "@ds/utils/validation";

const getFormationSchema = (validationData: FormationPropertyValidationData[]) =>
  yup.object().shape<Shape<Project>>({
    template: yup
      .object()
      .shape({ experience_formation: getFormationPropertiesValidationSchema(validationData, true) }),
  });

export const updateValidationSchema = {
  info: (validationData: FormationPropertyValidationData[]) =>
    getFormationSchema(validationData).concat(
      yup.object().shape<Shape<Project>>({
        name: yup.string().required("Specify project name"),
      }),
    ),
  settings: yup.object().shape<Shape<Project>>({}),
  formation: getFormationSchema,
};
