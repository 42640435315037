import { nanoid } from "@reduxjs/toolkit";
import { orderBy } from "lodash";
import prettyBytes from "pretty-bytes";
import { useMemo, type FC } from "react";
import styled from "styled-components";

import { EntityTypeName, isAggregatedEntity } from "@ds/model/entity-model";

import { isContentTypeImage, isContentTypeVideo } from "@ds/modules/content/assets/utils/helpers";
import { isContentAsset } from "@ds/modules/content/assets/utils/model";
import { isPlaylistAsset } from "@ds/modules/content/playlist-assets/utils/model";
import { isPlaylist } from "@ds/modules/content/playlists/utils/model";

import { DetailsCard } from "@ds/components/details-view";
import { MultipleValuesLabel } from "@ds/components/forms/labels/common";
import { Column } from "@ds/components/table/table-columns";
import { TreeTable, type TreeNode } from "@ds/components/table/table-tree";
import { TextLink } from "@ds/components/text-link";
import { TrimmedContent } from "@ds/components/trimmed-text";
import { formatRelativeDateEntity } from "@ds/utils/date";
import { composeDetailsRouteByEntityIds } from "@ds/utils/router";

const AssetWrapper = styled.div`
  display: flex;
  align-items: center;
  width: 100%;
  overflow: hidden;
`;

const AssetIcon = styled.i`
  margin-right: 10px;
`;

const getNameBodyTemplate = (node: TreeNode) => (
  <AssetWrapper>
    <AssetIcon className={`pi ${node.data.icon}`} />
    {node.data.type === "playlist" ? (
      <TextLink to={composeDetailsRouteByEntityIds(node.data.id, EntityTypeName.CONTENT_PLAYLIST)}>
        <TrimmedContent content={node.data.name} />
      </TextLink>
    ) : (
      <TrimmedContent content={node.data.name} />
    )}
  </AssetWrapper>
);

export const DeploymentDetailsContentCard: FC<{ deployment: Deployment }> = ({ deployment }) => {
  const playlistsTree: TreeNode[] = useMemo(() => {
    if (deployment.details?.entities) {
      const playlists = deployment.details.entities.filter(isPlaylist);
      const playlistAssets = orderBy(deployment.details.entities.filter(isPlaylistAsset), "playlist_order");
      const assets = deployment.details.entities.filter(isContentAsset);

      return playlists.map(playlist => ({
        key: `playlist-${playlist.id}`,
        data: {
          id: playlist.id,
          name: playlist.name,
          type: "playlist",
          size: "",
          icon: "pi-play",
          date: formatRelativeDateEntity(playlist),
        },
        children: playlistAssets.reduce((acc: TreeNode[], curr) => {
          if (curr.playlist_id !== playlist.id) {
            return acc;
          }

          const asset = assets.find(({ id }) => id === curr.asset_id);
          if (!asset) {
            return acc;
          }

          let assetIcon = "pi-volume-up";
          if (isContentTypeVideo(asset.content_type)) {
            assetIcon = "pi-video";
          } else if (isContentTypeImage(asset.content_type)) {
            assetIcon = "pi-image";
          }

          return [
            ...acc,
            {
              key: `asset-${nanoid()}`,
              data: {
                id: asset.id,
                name: asset.name,
                type: asset.content_type,
                icon: assetIcon,
                size: prettyBytes(asset.file_size),
                date: formatRelativeDateEntity(asset),
              },
            },
          ];
        }, []),
      }));
    }

    return [];
  }, [deployment]);

  return (
    <DetailsCard title="Content" icon="video">
      {!isAggregatedEntity(deployment) && (
        <TreeTable value={playlistsTree}>
          <Column field="name" header="Name" body={getNameBodyTemplate} className="center" expander />
          <Column field="type" header="Type" className="w200" />
          <Column field="size" header="Size" className="w200" />
          <Column field="date" header="Last Updated" className="w200" />
        </TreeTable>
      )}

      {isAggregatedEntity(deployment) && <MultipleValuesLabel />}
    </DetailsCard>
  );
};
