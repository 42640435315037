import { useCallback, type FC } from "react";
import { type DeepPartial } from "react-hook-form";

import { useGetAggregatedEntityIds } from "@ds/hooks/use-get-aggregated-entity-ids";
import { useParseQueryIds } from "@ds/hooks/use-parse-query-ids";
import { useReduxEntitiesFetch } from "@ds/hooks/use-redux-entities-fetch";

import { EntityTypeName, isAggregatedEntity } from "@ds/model/entity-model";

import { MainTableDataTypeEnum } from "@ds/modules/table-data/utils/model";

import { DetailsView } from "@ds/components/details-view";
import { AbsoluteLoader } from "@ds/components/loaders/loading";
import { USER_ID_PARAM } from "@ds/constants/router";
import { QueryParamType, useDeepRootSelector, useRootDispatch, useRootSelector } from "@ds/hooks";
import { pluralizeTotalSelectedTitle } from "@ds/utils/localization/common";
import { isMultiple } from "@ds/utils/numbers";

import { UserDetailsInfoCard } from "./components/details/user-details-info-card";
import { UserDetailsNotificationsCard } from "./components/details/user-details-notifications-card";
import { UserDetailsOrganizationCard } from "./components/details/user-details-organization-card";
import { UserDetailsProjectsCard } from "./components/details/user-details-projects-card";
import { selectAggregatedUserByIds, selectCurrentUser, selectUsersIsLoading } from "./redux/selectors";
import { usersActions } from "./redux/slice";

export const UserDetailsPage: FC = () => {
  const queryIds = useParseQueryIds(USER_ID_PARAM, QueryParamType.Number);
  const user = useDeepRootSelector(state => selectAggregatedUserByIds(state, queryIds));
  const aggregatedUserIds = useGetAggregatedEntityIds(user);

  const isLoading = useRootSelector(selectUsersIsLoading);
  const isUserOpenedProfile = useRootSelector(
    state => user && !isAggregatedEntity(user) && selectCurrentUser(state)?.id === user.id,
  );

  const [toggleRefetch] = useReduxEntitiesFetch(
    { entityType: EntityTypeName.SETTINGS_USER, filters: { id: queryIds } },
    { initialLoad: true },
  );

  const dispatch = useRootDispatch();
  const onSubmitHandler = useCallback(
    (changedFields: DeepPartial<User>) => dispatch(usersActions.updateUsers(aggregatedUserIds, changedFields)),
    [dispatch, aggregatedUserIds],
  );

  if (!user) {
    return <AbsoluteLoader isLoading />;
  }

  return (
    <DetailsView
      title={
        isMultiple(user?.metadata?.totalAggregated)
          ? pluralizeTotalSelectedTitle("user", user?.metadata?.totalAggregated)
          : user.email
      }
      tableType={MainTableDataTypeEnum.Users}
      isLoading={isLoading}
      onRefresh={toggleRefetch}
    >
      <UserDetailsInfoCard user={user} onSubmit={onSubmitHandler} />
      {isUserOpenedProfile && <UserDetailsOrganizationCard user={user} />}
      <UserDetailsNotificationsCard user={user} onSubmit={onSubmitHandler} />
      <UserDetailsProjectsCard user={user} onSubmit={onSubmitHandler} />
    </DetailsView>
  );
};
