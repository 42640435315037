import { type Task } from "@redux-saga/types";
import { type Action } from "@reduxjs/toolkit";
import {
  call,
  cancel,
  fork,
  take,
  type ActionPattern,
  type ForkEffect,
  type HelperWorkerParameters,
} from "redux-saga/effects";

import { DetailsTableDataTypeEnum, MainTableDataTypeEnum } from "@ds/modules/table-data/utils/model";

export type SagaOptions = {
  redirectTo?: string;
  tableType?: MainTableDataTypeEnum | DetailsTableDataTypeEnum;
  shouldDisableSagaThrottling?: boolean;

  cache?: {
    disableCache?: boolean;
    fetchedFromCache?: boolean;
  };
};

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const takeLeadingOrEvery = <A extends Action, Fn extends (...args: any[]) => any>(
  pattern: ActionPattern<A>,
  worker: Fn,
  ...args: HelperWorkerParameters<A, Fn>
): ForkEffect<never> =>
  fork(function* () {
    while (true) {
      const action: { meta: { options: SagaOptions } } = yield take(pattern);

      if (
        action.meta?.options?.shouldDisableSagaThrottling !== undefined &&
        !action.meta.options.shouldDisableSagaThrottling
      ) {
        // @ts-expect-error TS: SAGAS
        yield call(worker, ...(args.concat(action) as Parameters<Fn>));
      } else {
        // @ts-expect-error TS: SAGAS
        yield fork(worker, ...(args.concat(action) as Parameters<Fn>));
      }
    }
  });

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const takeLatestOrEvery = <A extends Action, Fn extends (...args: any[]) => any>(
  pattern: ActionPattern<A>,
  worker: Fn,
  ...args: HelperWorkerParameters<A, Fn>
): ForkEffect<never> =>
  fork(function* () {
    let lastTask: Task | undefined;
    while (true) {
      // TODO: TABLES: FIX updateTableData
      const action: { meta: { options: SagaOptions } } = yield take(pattern);
      if (
        lastTask &&
        action.meta?.options?.shouldDisableSagaThrottling !== undefined &&
        !action.meta.options.shouldDisableSagaThrottling
      ) {
        yield cancel(lastTask);
      }

      // @ts-expect-error TS: SAGAS
      lastTask = yield fork(worker, ...(args.concat(action) as Parameters<Fn>));
    }
  });
