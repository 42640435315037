import styled, { css } from "styled-components";

const ErrorAsteriskMixin = css<{ $isRequired: boolean }>`
  ${({ $isRequired }) =>
    $isRequired &&
    css`
      :after {
        content: " *";
        font-weight: 700;
        color: var(--error-text-color);
      }
    `}
`;

const ContentWrapperMixin = css`
  font-size: 0.9rem;
  color: var(--textColor060);
  font-weight: 500;
  margin-bottom: 2px;
`;

const InputValidationErrorMixin = css<{ err?: Error }>`
  border-width: 1px 1px 1px 7px !important;
  border-color: var(--error-input-border-color) !important;
  background: var(--error-text-background-color) !important;
`;

export const ValidationWrapper = styled.div`
  display: flex;
  flex-flow: column wrap;
  justify-content: center;
  margin-bottom: 15px;
`;

export const ValidationLabelContentWrapper = styled.label`
  ${ContentWrapperMixin}
`;

export const BaseValidationTitle = styled.span<{ $isRequired: boolean }>`
  ${ErrorAsteriskMixin}
`;

export const ValidationTitle = styled.span<{ $isRequired: boolean }>`
  display: inline-block;
  padding-bottom: 5px;

  ${ErrorAsteriskMixin}
`;

export const ValidationSpanContentWrapper = styled.div`
  ${ContentWrapperMixin}
`;

export const ValidationInputWrapper = styled.div<{ $isValid: boolean }>`
  display: flex;
  flex-flow: row wrap;
  align-items: center;

  .p-inputtext,
  .p-dropdown,
  .p-autocomplete > .p-autocomplete-input {
    width: 100%;
    transition: all 0.3s;

    ${({ $isValid }) =>
      !$isValid &&
      css`
        ${InputValidationErrorMixin}

        &.p-focus,
        &.p-inputwrapper-focus {
          ${InputValidationErrorMixin}
        }

        &:enabled:hover,
        &:enabled:focus {
          border-color: var(--red-color) !important;
        }
      `}
  }
`;

export const ValidationInputDescription = styled.div`
  padding-top: 10px;
  font-size: 0.8rem;
  color: var(--textColor060);
  white-space: pre-wrap;
  overflow: hidden;
  text-overflow: ellipsis;
`;

export const RadioButtonRow = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 10px;
`;

export const RadioLabel = styled.label`
  margin-left: 10px;
`;

export const RadioButtonInfoText = styled.p`
  margin: -3px 0 30px;
  padding-left: 30px;
  font-size: 0.95rem;
  color: var(--textColor060);
`;

export const ShadedLabel = styled.span<{ $shouldInheritColor?: boolean }>`
  color: ${({ $shouldInheritColor }) => ($shouldInheritColor ? "inherit" : "var(--textColor030)")};
`;
