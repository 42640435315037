import { cloneDeep } from "lodash";
import { push, replace } from "redux-first-history";
import { call, put, race, select, take, takeLatest, takeLeading, type SagaReturnType } from "redux-saga/effects";

import { iotEventExperienceUpdate } from "@ds/modules/iot/redux/actions";
import { toastShowErrorAction, toastShowSuccessAction } from "@ds/modules/notifications/redux/actions";
import { selectTemplateExperienceFormation } from "@ds/modules/settings/projects/redux/selectors/experience-formation-selectors";
import { selectCurrentUserProjectId } from "@ds/modules/settings/users/redux/selectors";
import { isIotTableEntityAddedOrDeleted, selectTableData } from "@ds/modules/table-data/redux/selectors";
import { convertToApiQueryInfo } from "@ds/modules/table-data/utils/common";
import { MainTableDataTypeEnum } from "@ds/modules/table-data/utils/model";

import { EXPERIENCES_ROUTE } from "@ds/constants/router";
import { UnexpectedError } from "@ds/utils/errors";
import { logger } from "@ds/utils/logger";
import { UNLIMITED_PAGINATION } from "@ds/utils/query";
import { takeLatestOrEvery } from "@ds/utils/saga-helpers";
import { isErrorLike } from "@ds/utils/type-guards/error-guards";

import { experiencesService } from "../utils/api";
import { isApiExperienceQueryOutput } from "../utils/model";
import { normalize } from "../utils/normalizer";
import { selectExperienceById, selectExperiencesByIds, selectTableExperiences } from "./selectors";
import { ExperiencesRemoteOperation, experiencesActions } from "./slice";

function* switchRoute(_: ReturnType<typeof experiencesActions.switchView>) {
  yield put(push(EXPERIENCES_ROUTE));
}

function* fetchExperiences({ meta }: ReturnType<typeof experiencesActions.fetchExperiences>) {
  try {
    const projectId = selectCurrentUserProjectId(yield select());
    const formation = selectTemplateExperienceFormation(yield select(), projectId);

    const { sorting, pagination, queryInfo, isFetchedAlready } = selectTableData(
      yield select(),
      meta.options.tableType || meta.options.tableType || MainTableDataTypeEnum.Experiences,
      meta.filters?.locationId?.toString(),
      meta.filters,
    );

    const newMeta = cloneDeep(meta);
    const resultFromCache: QueryOutput<Experience> = { items: [] };

    if (!newMeta.options.cache?.disableCache) {
      if (isFetchedAlready && newMeta.options.tableType) {
        resultFromCache.items = selectTableExperiences(yield select(), newMeta.options.tableType);
        newMeta.options.cache = {
          ...newMeta.options.cache,
          fetchedFromCache: true,
        };
      } else if (newMeta.filters.id && Object.keys(newMeta.filters).length === 1) {
        resultFromCache.items = selectExperiencesByIds(yield select(), newMeta.filters.id);
        if (resultFromCache.items.length === newMeta.filters.id.length) {
          newMeta.options.cache = {
            ...newMeta.options.cache,
            fetchedFromCache: true,
          };
        }
      }
    }

    let result: QueryOutput<Experience> | QueryOutput<ApiExperience> = newMeta.options.cache?.fetchedFromCache
      ? resultFromCache
      : yield call(
          [experiencesService, experiencesService.getExperiences],
          convertToApiQueryInfo(sorting, queryInfo),
          newMeta.options.tableType ? pagination : UNLIMITED_PAGINATION,
        );

    result = isApiExperienceQueryOutput(result) ? normalize(result, formation?.properties) : result;
    result = newMeta.options.tableType ? result : { items: result.items };

    yield put(experiencesActions.fetchExperiencesSucceeded(result, newMeta));
  } catch (err) {
    const errorTitle = "Fetch experiences";
    if (isErrorLike(err)) {
      yield put(experiencesActions.fetchExperiencesFailed(meta, err));
      yield put(toastShowErrorAction(err, errorTitle));
    } else {
      logger.error(`${errorTitle}: ${UnexpectedError}`);
      yield put(toastShowErrorAction(UnexpectedError, errorTitle));
    }
  }
}

function* fetchExperience({ payload, meta }: ReturnType<typeof experiencesActions.fetchExperience>) {
  try {
    const newMeta = cloneDeep(meta);
    let result: Experience | ApiExperience = selectExperienceById(yield select(), payload);
    if (result && !meta.options.cache?.disableCache) {
      newMeta.options.cache = {
        ...newMeta.options.cache,
        fetchedFromCache: true,
      };
    } else {
      const projectId = selectCurrentUserProjectId(yield select());
      const formation = selectTemplateExperienceFormation(yield select(), projectId);
      result = yield call([experiencesService, experiencesService.getExperience], payload);
      result = normalize(result as ApiExperience, formation?.properties);
    }

    yield put(experiencesActions.fetchExperienceSucceeded(result, newMeta));
  } catch (err) {
    const errorTitle = "Fetch experience";
    if (isErrorLike(err)) {
      yield put(experiencesActions.fetchExperienceFailed(payload, meta, err));
      yield put(toastShowErrorAction(err, errorTitle));
    } else {
      logger.error(`${errorTitle}: ${UnexpectedError}`);
      yield put(toastShowErrorAction(UnexpectedError, errorTitle));
    }
  }
}

function* createExperience({ payload, meta }: ReturnType<typeof experiencesActions.createExperience>) {
  try {
    const projectId = selectCurrentUserProjectId(yield select());
    const formation = selectTemplateExperienceFormation(yield select(), projectId);

    const result: SagaReturnType<typeof experiencesService.createExperience> = yield call(
      [experiencesService, experiencesService.createExperience],
      payload,
    );

    yield put(experiencesActions.createExperienceSucceeded(normalize(result, formation?.properties), meta));
    yield put(toastShowSuccessAction("Experience was created successfully"));
    yield put(experiencesActions.selectExperiences([result]));
  } catch (err) {
    const errorTitle = "Create experience";
    if (isErrorLike(err)) {
      yield put(experiencesActions.createExperienceFailed(payload, meta, err));
      yield put(toastShowErrorAction(err, errorTitle));
    } else {
      logger.error(`${errorTitle}: ${UnexpectedError}`);
      yield put(toastShowErrorAction(UnexpectedError, errorTitle));
    }
  }
}

function* updateExperiences({ payload }: ReturnType<typeof experiencesActions.updateExperiences>) {
  try {
    const projectId = selectCurrentUserProjectId(yield select());
    const formation = selectTemplateExperienceFormation(yield select(), projectId);

    const result: SagaReturnType<typeof experiencesService.updateExperiences> = yield call(
      [experiencesService, experiencesService.updateExperiences],
      payload.ids,
      payload.data,
    );

    yield put(experiencesActions.updateExperiencesSucceeded(normalize(result, formation?.properties)));
    yield put(toastShowSuccessAction("Experience(s) were updated successfully"));
  } catch (err) {
    const errorTitle = "Update experience(s)";
    if (isErrorLike(err)) {
      yield put(experiencesActions.updateExperiencesFailed(payload, err));
      yield put(toastShowErrorAction(err, errorTitle));
    } else {
      logger.error(`${errorTitle}: ${UnexpectedError}`);
      yield put(toastShowErrorAction(UnexpectedError, errorTitle));
    }
  }
}

function* deleteExperiences({ payload, meta }: ReturnType<typeof experiencesActions.deleteExperiences>) {
  try {
    yield call([experiencesService, experiencesService.deleteExperiences], payload);
    yield put(experiencesActions.deleteExperiencesSucceeded(payload, meta));
    yield put(toastShowSuccessAction("Experience(s) were deleted successfully"));

    if (meta.options?.redirectTo) {
      yield put(replace(meta.options.redirectTo));
    }
  } catch (err) {
    const errorTitle = "Delete experience(s)";
    if (isErrorLike(err)) {
      yield put(experiencesActions.deleteExperiencesFailed(payload, meta, err));
      yield put(toastShowErrorAction(err, errorTitle));
    } else {
      logger.error(`${errorTitle}: ${UnexpectedError}`);
      yield put(toastShowErrorAction(UnexpectedError, errorTitle));
    }
  }
}

function* refetchExperiences({ payload }: ReturnType<typeof iotEventExperienceUpdate>) {
  const isAddedOrDeleted = isIotTableEntityAddedOrDeleted(yield select(), payload, MainTableDataTypeEnum.Experiences);
  if (isAddedOrDeleted) {
    yield call(
      fetchExperiences,
      experiencesActions.fetchExperiences(
        undefined,
        {
          tableType: MainTableDataTypeEnum.Experiences,
          cache: { disableCache: true },
        },
        ExperiencesRemoteOperation.IOT_FETCH,
      ),
    );
  }

  yield put(experiencesActions.iotUpdate(payload));
}

export function* watchExperiences() {
  yield takeLatest(experiencesActions.switchView, switchRoute);
  yield takeLatestOrEvery(experiencesActions.fetchExperiences, function* (action) {
    yield race({
      task: call(fetchExperiences, action),
      cancel: take([experiencesActions.selectExperiences, experiencesActions.toggleSelected]),
    });
  });

  yield takeLatestOrEvery(experiencesActions.fetchExperience, fetchExperience);
  yield takeLeading(experiencesActions.createExperience, createExperience);
  yield takeLeading(experiencesActions.updateExperiences, updateExperiences);
  yield takeLeading(experiencesActions.deleteExperiences, deleteExperiences);
  yield takeLatest(iotEventExperienceUpdate, refetchExperiences);
}
