import { type FC } from "react";

import { AggregatedValue } from "@ds/components/entities";
import { MultipleValuesLabelWithLink } from "@ds/components/forms/labels/common";
import { InfoAttribute, InfoSection } from "@ds/components/info-view";
import { getProperty } from "@ds/utils/properties";
import { composeDetailsRouteByEntityType } from "@ds/utils/router";

import { getPlayerStatusIndicator, getPlayerStatusIndicatorWithNameAndLink } from "./status-indicator";

export const PlayerInfoSection: FC<{
  player: Player;
  showNameIsteadOfStatus?: boolean;
}> = ({ player, showNameIsteadOfStatus = false }) => (
  <InfoSection title="Player Info" icon="pi pi-info-circle">
    {showNameIsteadOfStatus ? (
      <InfoAttribute
        name="Name"
        value={
          <AggregatedValue
            entity={player}
            field="name"
            formatter={entity => getPlayerStatusIndicatorWithNameAndLink(entity)}
            multipleFormatter={entity => <MultipleValuesLabelWithLink to={composeDetailsRouteByEntityType(entity)} />}
          />
        }
      />
    ) : (
      <InfoAttribute
        name="Status"
        value={<AggregatedValue entity={player} field="state" formatter={entity => getPlayerStatusIndicator(entity)} />}
      />
    )}

    <InfoAttribute name="Applet" value={<AggregatedValue entity={player} field="applet_name" />} />
    <InfoAttribute name="Serial #" value={<AggregatedValue entity={player} field="serial_number" />} />
    <InfoAttribute name="IP Address" value={<AggregatedValue entity={player} field="ip_address" />} />
    <InfoAttribute name="MAC Address" value={<AggregatedValue entity={player} field="mac_address" />} />
    <InfoAttribute name="OS Version" value={<AggregatedValue entity={player} field="os_version" />} />
    <InfoAttribute name="Code Version" value={<AggregatedValue entity={player} field="code_version" />} />
    <InfoAttribute name="Model" value={<AggregatedValue entity={player} field="model" />} />
    <InfoAttribute name="Manufacturer" value={<AggregatedValue entity={player} field="make" />} />
    <InfoAttribute name="Video Mode" value={<AggregatedValue entity={player} field="video_mode" />} />
    <InfoAttribute name="HDMI Status" value={<AggregatedValue entity={player} field="hdmi_status" />} />

    <InfoAttribute
      name="Booted At"
      value={
        <AggregatedValue
          entity={player}
          field="boot_time"
          formatter={(entity, field) => {
            const bootTime = getProperty(entity, field);
            return bootTime ? new Date(bootTime).toLocaleString() : "";
          }}
        />
      }
    />
  </InfoSection>
);
