import { type FC } from "react";
import { Controller, type Control } from "react-hook-form";

import { useDetailsInlineSaving } from "@ds/components/details-view/hooks";
import { InputTextarea } from "@ds/components/forms/inputs";
import { LabelWrapper } from "@ds/components/forms/labels";

export const DescriptionControl: FC<{
  control: Control<Locality> | Control<Playlist> | Control<Deployment> | Control<Project>;
  autoFocus?: boolean;
  excludeLabel?: boolean;
  onBlur?: () => void;
}> = ({ control, autoFocus, excludeLabel, onBlur }) => {
  const onBlurHandler = useDetailsInlineSaving<HTMLTextAreaElement>(onBlur);

  return (
    <Controller
      name="description"
      control={control as Control<Locality | Playlist | Deployment | Project>}
      render={({ field: { ref, onBlur: onBlurFn, ...restFields }, fieldState }) => {
        const element = (
          <InputTextarea
            ref={ref}
            placeholder="Enter description"
            autoFocus={autoFocus}
            onBlur={onBlurHandler(onBlurFn)}
            {...restFields}
          />
        );

        if (excludeLabel) {
          return element;
        }

        return (
          <LabelWrapper label="Description" className="col-8" error={fieldState.error}>
            {element}
          </LabelWrapper>
        );
      }}
    />
  );
};
