import { every } from "lodash";
import { useCallback, type FC } from "react";
import { type DeepPartial } from "react-hook-form";

import { useGetAggregatedEntityIds } from "@ds/hooks/use-get-aggregated-entity-ids";
import { useParseQueryIds } from "@ds/hooks/use-parse-query-ids";
import { useReduxEntitiesFetch } from "@ds/hooks/use-redux-entities-fetch";

import { EntityTypeName } from "@ds/model/entity-model";

import { DetailsView } from "@ds/components/details-view";
import { AbsoluteLoader } from "@ds/components/loaders/loading";
import { PLAYER_ID_PARAM } from "@ds/constants/router";
import { QueryParamType, useDeepRootSelector, useRootDispatch, useRootSelector } from "@ds/hooks";
import { pluralizeTotalSelectedTitle } from "@ds/utils/localization/common";
import { isMultiple } from "@ds/utils/numbers";

import { selectDeploymentsIsLoading } from "../../deployments/redux/selectors";
import { DeploymentsRemoteOperation } from "../../deployments/redux/slice";
import { MainTableDataTypeEnum } from "../../table-data/utils/model";
import { PlayerDetailsExperienceCard } from "./components/details/player-details-experience-card";
import { PlayerDetailsInfoCard } from "./components/details/player-details-info-card";
import { PlayerDetailsLastSyncCard } from "./components/details/player-details-last-sync-card";
import { PlayerDetailsPeripheralsCard } from "./components/details/player-details-peripherals-card";
import { selectAggregatedPlayerByIds, selectPlayersByIds, selectPlayersIsLoading } from "./redux/selectors";
import { playersActions } from "./redux/slice";
import { PlayerStateEnum } from "./utils/model";

export const PlayerDetailsPage: FC = () => {
  const queryIds = useParseQueryIds(PLAYER_ID_PARAM, QueryParamType.Number);
  const player = useDeepRootSelector(state => selectAggregatedPlayerByIds(state, queryIds));
  const aggregatedPlayerIds = useGetAggregatedEntityIds(player);
  const aggregatedPlayers = useDeepRootSelector(state => selectPlayersByIds(state, queryIds));

  const isLoading = useRootSelector(selectPlayersIsLoading);
  const isDeploying = useRootSelector(state => selectDeploymentsIsLoading(state, DeploymentsRemoteOperation.DEPLOYING));

  const [toggleRefetch] = useReduxEntitiesFetch(
    { entityType: EntityTypeName.DEVICE_PLAYER, filters: { id: queryIds } },
    { initialLoad: true },
  );

  const dispatch = useRootDispatch();
  const onSubmitHandler = useCallback(
    (changedFields: DeepPartial<Player>) => dispatch(playersActions.updatePlayers(aggregatedPlayerIds, changedFields)),

    [dispatch, aggregatedPlayerIds],
  );

  if (!player) {
    return <AbsoluteLoader isLoading />;
  }

  return (
    <DetailsView
      title={
        isMultiple(player?.metadata?.totalAggregated)
          ? pluralizeTotalSelectedTitle("player", player?.metadata?.totalAggregated)
          : player.name
      }
      tableType={MainTableDataTypeEnum.DevicePlayers}
      isLoading={isLoading || isDeploying}
      onRefresh={toggleRefetch}
    >
      <PlayerDetailsInfoCard player={player} onSubmit={onSubmitHandler} />
      <PlayerDetailsExperienceCard player={player} onSubmit={onSubmitHandler} />
      {!every(aggregatedPlayers, ["state", PlayerStateEnum.Unpaired]) && (
        <>
          <PlayerDetailsPeripheralsCard player={player} />
          <PlayerDetailsLastSyncCard player={player} />
        </>
      )}
    </DetailsView>
  );
};
