import { TabMenu as PrimeTabMenu, type TabMenuProps as PrimeTabMenuProps } from "primereact/tabmenu";
import { type FC } from "react";
import styled from "styled-components";

export type { TabMenuTabChangeEvent } from "primereact/tabmenu";
export { TabPanel, TabView } from "primereact/tabview";

const StyledTabMenu = styled(PrimeTabMenu)`
  .p-tabmenu-nav {
    background: transparent;
    border: 0;

    .p-tabmenuitem {
      border-bottom: 3px solid transparent;

      .p-menuitem-link {
        color: var(--textColor060);
        background: transparent;
        text-decoration: none;
        border: 0;

        :not(.p-disabled):focus,
        :not(.p-disabled):hover {
          background: transparent;
          box-shadow: var(--button-light-box-shadow);
        }
      }

      &.p-highlight {
        border-bottom: 3px solid var(--primaryColor060);

        .p-menuitem-link {
          color: var(--primaryColor100);
          background: transparent;
        }
      }

      :not(.p-highlight):not(.p-disabled):hover {
        .p-menuitem-link {
          color: var(--textColor080);
          background: transparent;
        }
      }

      .p-menuitem-text {
        text-transform: capitalize;
      }
    }
  }
`;

type TabMenuProps = {
  selectedOption: string;
  options: {
    label: string;
    icon?: string;
  }[];
} & Omit<PrimeTabMenuProps, "model" | "activeIndex">;

export const TabMenu: FC<TabMenuProps> = ({ selectedOption, options, ...rest }) => (
  <StyledTabMenu model={options} activeIndex={options.findIndex(({ label }) => label === selectedOption)} {...rest} />
);
