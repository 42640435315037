import { yupResolver } from "@hookform/resolvers/yup";
import { type FC } from "react";
import { type Control, type DeepPartial, type UseFormSetValue } from "react-hook-form";

import { isAggregatedEntity } from "@ds/model/entity-model";

import { selectTemplateExperienceFormationValidationData } from "@ds/modules/settings/projects/redux/selectors/experience-formation-selectors";
import { updateValidationSchema } from "@ds/modules/settings/projects/utils/validation-schema";

import {
  DetailsCard,
  DetailsCardEditActionsMenu,
  DetailsCardRow,
  EditableDetailsCardDecorator,
  EditableDetailsCardRowDecorator,
} from "@ds/components/details-view";
import { AggregatedValue } from "@ds/components/entities";
import { DescriptionControl } from "@ds/components/forms/control/description-control";
import { useDeepRootSelector } from "@ds/hooks";
import { formatRelativeDate } from "@ds/utils/date";
import { getProperty } from "@ds/utils/properties";

import { NotRequiredFormationCardRow } from "../../formation/formation-card-row";
import { FormationControl } from "../controls/formation-control";
import { ProjectNameControl } from "../controls/project-name-control";

export const ProjectDetailsInfoCard: FC<{
  project: Project;
  isEditForbidden: boolean;
  templateProperties: [string, TemplateFormationProperty][];
  onSubmit: (changedFields: DeepPartial<Project>) => void;
}> = ({ project, isEditForbidden, templateProperties, onSubmit }) => {
  const validationData = useDeepRootSelector(state =>
    selectTemplateExperienceFormationValidationData(state, project.id, "details", templateProperties),
  );

  return (
    <EditableDetailsCardDecorator
      initialValue={project}
      validationResolver={yupResolver(updateValidationSchema.info(validationData))}
      onSubmit={onSubmit}
    >
      {cardProps => (
        <DetailsCard
          title="Info"
          icon="info-circle"
          actions={
            <DetailsCardEditActionsMenu
              isEditing={cardProps.isEditing}
              isDirty={cardProps.isDirty}
              onReset={cardProps.onResetForm}
              onSubmit={cardProps.handleSubmit(cardProps.onSubmitForm)}
            />
          }
        >
          <EditableDetailsCardRowDecorator
            field="name"
            input={<ProjectNameControl control={cardProps.control} autoFocus excludeLabel />}
            shouldHideEdit={isEditForbidden || isAggregatedEntity(cardProps.entity)}
            {...cardProps}
          >
            {rowProps => (
              <DetailsCardRow label="Name" editableSettings={rowProps} isRequired>
                <AggregatedValue entity={cardProps.entity} field="name" shouldShowEditedValue={rowProps.isDirty} />
              </DetailsCardRow>
            )}
          </EditableDetailsCardRowDecorator>

          <EditableDetailsCardRowDecorator
            field="description"
            input={<DescriptionControl control={cardProps.control} autoFocus excludeLabel />}
            shouldHideEdit={isEditForbidden}
            {...cardProps}
          >
            {rowProps => (
              <DetailsCardRow label="Description" editableSettings={rowProps}>
                <AggregatedValue
                  entity={cardProps.entity}
                  field="description"
                  shouldShowEditedValue={rowProps.isDirty}
                />
              </DetailsCardRow>
            )}
          </EditableDetailsCardRowDecorator>

          {templateProperties.map(([field, property]) => (
            <EditableDetailsCardRowDecorator
              key={`project-template-experience-formation-property-${field}`}
              field={`template.experience_formation.properties.${field}`}
              input={
                <FormationControl
                  name={`template.experience_formation.properties.${field}`}
                  control={cardProps.control as Control<Experience | Project>}
                  setValue={cardProps.setValue as UseFormSetValue<Experience | Project>}
                  property={property}
                  autoFocus
                  excludeLabel
                />
              }
              shouldHideEdit={isEditForbidden}
              {...cardProps}
            >
              {rowProps => (
                <NotRequiredFormationCardRow
                  entity={cardProps.entity}
                  field={field}
                  property={property}
                  editableSettings={rowProps}
                />
              )}
            </EditableDetailsCardRowDecorator>
          ))}

          <DetailsCardRow label="Last Updated">
            <AggregatedValue
              entity={cardProps.entity}
              field="audit.updated_at"
              formatter={(entity, field) => formatRelativeDate(getProperty(entity, field))}
            />
          </DetailsCardRow>
        </DetailsCard>
      )}
    </EditableDetailsCardDecorator>
  );
};
