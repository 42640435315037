import { yupResolver } from "@hookform/resolvers/yup";
import pluralize from "pluralize";
import { useCallback, useEffect, type FC } from "react";
import { useForm } from "react-hook-form";

import { DeploymentScopeEnum } from "@ds/modules/deployments/utils/model";
import { createValidationSchema } from "@ds/modules/deployments/utils/validation-schema";

import { DescriptionControl } from "@ds/components/forms/control/description-control";
import { WizardPage, type WizardChildStepProps } from "@ds/components/wizard";
import { convertToUpperCaseFirst } from "@ds/utils/strings";

import { DeploymentDeferredExecutionControl } from "../controls/deployment-deferred-execution-control";
import { DeploymentNameControl } from "../controls/deployment-name-control";
import { useCreateDeployment } from "./create-deployment-context";

export const Step4Info: FC<WizardChildStepProps> = ({ nextStep, previousStep, ...rest }) => {
  const [currentDeployment, submitDeployment] = useCreateDeployment();
  const { control, getValues, reset, handleSubmit } = useForm<Deployment>({
    mode: "onSubmit",
    reValidateMode: "onChange",
    defaultValues: currentDeployment,
    resolver: yupResolver(createValidationSchema.step3),
  });

  useEffect(() => {
    const targetsCount =
      currentDeployment.scope === DeploymentScopeEnum.All || !currentDeployment.targets.length
        ? "all"
        : currentDeployment.targets.length;

    const targetsScope =
      currentDeployment.scope === DeploymentScopeEnum.All || currentDeployment.scope === DeploymentScopeEnum.Device
        ? "player"
        : currentDeployment.scope;

    const scopeStatement = pluralize(targetsScope, targetsCount === "all" ? 0 : targetsCount);
    const deploymentType = convertToUpperCaseFirst(currentDeployment.type);

    reset({
      ...currentDeployment,
      name: `${deploymentType} deployment`,
      description: `${deploymentType} deployment to ${targetsCount} ${scopeStatement}`,
    });
  }, [currentDeployment, reset]);

  const onNextClickHandler = useCallback(() => {
    submitDeployment(getValues());
    if (nextStep) {
      nextStep();
    }
  }, [submitDeployment, getValues, nextStep]);

  const onBackClickHandler = useCallback(() => {
    submitDeployment(getValues());
    if (previousStep) {
      previousStep();
    }
  }, [submitDeployment, getValues, previousStep]);

  return (
    <WizardPage
      {...rest}
      title={`${currentDeployment.type} Deployment Info`}
      nextStep={handleSubmit(onNextClickHandler)}
      previousStep={onBackClickHandler}
    >
      <DeploymentNameControl control={control} autoFocus />
      <DescriptionControl control={control} />
      <DeploymentDeferredExecutionControl control={control} />
    </WizardPage>
  );
};
