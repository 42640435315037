import { useRerender } from "@react-hookz/web";
import {
  DataTable as PrimeDataTable,
  type DataTableProps as PrimeDataTableProps,
  type DataTableUnselectEvent as PrimeDataTableUnselectEvent,
} from "primereact/datatable";
import { useCallback, useEffect, useRef } from "react";
import styled, { css } from "styled-components";

import { TABLE_AUTOUPDATE_TIMEOUT } from "@ds/constants/table";

import { CheckBoxStylesMixin } from "../forms/inputs";

export type {
  DataTableRowReorderEvent,
  DataTableSelectEvent,
  DataTableSelectionChangeEvent,
  DataTableSortEvent,
  DataTableUnselectEvent,
} from "primereact/datatable";

export type DataTableProps<T extends Entity> = PrimeDataTableProps<T[]> & {
  onSingleRowSelect?: (value: T) => void;
  toggleRowSelected?: (value: T) => void;
};

const RowStyles = css`
  display: flex;
  flex-wrap: nowrap;
  width: 100%;
`;

const CellStyles = css`
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  display: inline-block;
  flex: 1 1;

  border: 0;
  margin: auto 0;
  align-items: center;

  .p-checkbox-box {
    box-shadow: 0 4px 6px var(--primaryColor015);

    &::after {
      content: "";
      background: transparent;

      position: absolute;
      padding: 120%;
    }
  }

  &.center {
    text-align: center;
    justify-content: center;
  }

  &.w150 {
    flex: none;
    width: 150px;
  }

  &.w175 {
    flex: none;
    width: 175px;
  }

  &.w200 {
    flex: none;
    width: 200px;
  }
`;

const ReorderColumnStyle = css`
  > tr > .reorder-column {
    flex-shrink: 0 !important;
    flex-grow: 0 !important;
    flex-basis: var(--icon-cell-width) !important;
    justify-content: center;
    text-align: center;

    > span,
    svg,
    i {
      text-align: center;
      vertical-align: middle;
    }
  }
`;

const CheckboxColumnStyles = css`
  > tr {
    > .select-checkbox-column .p-checkbox:not(.p-multistatecheckbox) {
      display: none;
    }

    > .p-selection-column {
      flex-shrink: 0 !important;
      flex-grow: 0 !important;
      flex-basis: var(--icon-cell-width) !important;
      justify-content: center;
      text-align: center;
    }

    .p-checkbox {
      ${CheckBoxStylesMixin}
    }
  }
`;

const StatusColumnStyle = css`
  > tr > .status-column {
    flex-shrink: 0 !important;
    flex-grow: 0 !important;
    flex-basis: var(--fixed-column-width) !important;
  }
`;

const LastUpdatedColumnStyles = css`
  > tr > .last_updated_column {
    flex-shrink: 0 !important;
    flex-grow: 0 !important;
    flex-basis: var(--fixed-column-width) !important;
  }
`;

const GoToDetailsColumnStyles = css`
  > tr {
    > .go_to_details_column {
      flex-shrink: 0 !important;
      flex-grow: 0 !important;
      flex-basis: var(--icon-cell-width) !important;
      justify-content: center;

      a {
        visibility: hidden;
        opacity: 0;
        transition:
          visibility 0s linear 300ms,
          opacity 300ms;
      }
    }

    &:hover {
      .go_to_details_column a {
        visibility: visible;
        opacity: 1;
        transition:
          visibility 0s linear 0s,
          opacity 300ms;
      }
    }
  }
`;

const DataTableWrapper = styled.div`
  --horizontal-padding: 3px;
  --icon-cell-width: 52px;
  --fixed-column-width: 175px;
  --header-height: 52px;

  display: contents;

  .p-datatable {
    user-select: none;

    &.p-datatable-resizable > .p-datatable-wrapper {
      scrollbar-gutter: stable;
    }

    > .p-datatable-wrapper > table.p-datatable-table {
      width: 100%;
      border-collapse: collapse;
      min-width: 100%;
      table-layout: fixed;

      thead.p-datatable-thead {
        z-index: 1;
        display: block;
        padding: 0 var(--horizontal-padding);

        ${ReorderColumnStyle}
        ${CheckboxColumnStyles}
        ${StatusColumnStyle}
        ${LastUpdatedColumnStyles}
        ${GoToDetailsColumnStyles}

        > tr {
          ${RowStyles}
          height: var(--header-height);
        }

        > tr > th {
          ${CellStyles}

          border: 0;
          box-shadow: var(--button-light-box-shadow);
          height: var(--header-height);
          color: var(--textColor060);

          .p-column-header-content {
            display: block;
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
          }

          &.p-sortable-column {
            &:not(.p-highlight):hover,
            &:not(.p-highlight):hover .p-sortable-column-icon {
              color: var(--textColor080);
            }

            .p-sortable-column-icon {
              color: var(--textColor060);
            }

            :not(.p-highlight):hover {
              background: inherit;
            }
          }

          .p-column-resizer {
            width: 5px;
            border-right: 1px dashed var(--blackShadowColor025);
            height: 25px;
            margin: 15px 0;
          }

          :not(.p-resizable-column) .p-column-resizer {
            display: none;
          }
        }
      }

      tbody.p-datatable-tbody {
        padding: 3px var(--horizontal-padding);
        display: block;

        > tr {
          ${RowStyles}
          color: var(--textColor070);
          font-weight: 400;
          border-radius: 4px;

          &.p-highlight {
            color: var(--textColor070);
            font-weight: 600;
            background: linear-gradient(0deg, var(--primaryColor005), var(--primaryColor005)),
              var(--whiteShadowColor100);
            outline: 2px solid var(--primaryColor080);
            box-shadow: 0 4px 16px var(--primaryColor015);

            a {
              :hover {
                font-weight: 800;
              }

              i {
                :hover:before {
                  font-weight: 800;
                }
              }
            }
          }

          :not(.p-datatable-emptymessage) {
            :not(.p-highlight):hover {
              color: var(--textColor080);
              background: var(--lightSurfaceColor);
            }

            :hover {
              box-shadow: 0 4px 16px var(--primaryColor015);
              outline: 2px solid var(--primaryColor030);
            }

            :active {
              color: var(--primaryColor100);
              background: linear-gradient(0deg, var(--primaryColor005), var(--primaryColor005)),
                var(--whiteShadowColor100);
              box-shadow: 0 4px 16px var(--primaryColor015);
              outline: 2px solid var(--primaryColor080);
            }
          }

          &.p-datatable-dragpoint-bottom {
            td {
              box-shadow: none;
            }

            :not(.p-highlight) {
              td {
                box-shadow: inset 0 -4px 0 0 var(--primaryColor100);
              }
            }
          }
        }

        > tr > td {
          ${CellStyles}
        }

        ${ReorderColumnStyle}
        ${CheckboxColumnStyles}
        ${StatusColumnStyle}
        ${LastUpdatedColumnStyles}
        ${GoToDetailsColumnStyles}
      }
    }

    &.playlist-assets-table td {
      line-height: 100px;
    }
  }
`;

export const DataTable = <T extends Entity>({
  dataKey = "id",
  loading,
  toggleRowSelected,
  onSingleRowSelect,
  selectionMode = "multiple",
  removableSort = true,
  columnResizeMode = "fit",
  resizableColumns = true,
  onColReorder,
  onRowReorder,
  ...rest
}: DataTableProps<T>) => {
  const rerender = useRerender();
  const timeout = useRef<NodeJS.Timeout>();
  useEffect(() => {
    timeout.current = setInterval(rerender, TABLE_AUTOUPDATE_TIMEOUT);

    return () => {
      if (timeout.current) {
        clearInterval(timeout.current);
      }
    };
  }, [rerender]);

  const onRowSelectHandler = useCallback(
    ({ originalEvent, type, data }: PrimeDataTableUnselectEvent) => {
      if (data) {
        if (!originalEvent.isPropagationStopped()) {
          if (type === "checkbox") {
            toggleRowSelected?.(data);
          } else {
            onSingleRowSelect?.(data);
          }
        }
      }

      originalEvent.stopPropagation();
    },
    [toggleRowSelected, onSingleRowSelect],
  );

  return (
    <DataTableWrapper>
      <PrimeDataTable
        dataKey={dataKey}
        emptyMessage={loading ? "Loading entities..." : "No entities found"}
        onRowSelect={onRowSelectHandler}
        onRowUnselect={onRowSelectHandler}
        selectionMode={selectionMode}
        removableSort={removableSort}
        onRowReorder={onRowReorder}
        reorderableRows={!!onRowReorder}
        onColReorder={onColReorder}
        reorderableColumns={!!onColReorder}
        columnResizeMode={columnResizeMode}
        resizableColumns={resizableColumns}
        scrollHeight="flex"
        scrollable
        {...rest}
      />
    </DataTableWrapper>
  );
};
