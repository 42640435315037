import { produce } from "immer";
import { isString } from "lodash";
import { call, put, select, takeEvery, type SagaReturnType } from "redux-saga/effects";

import { StatusEnum } from "@ds/model/status-model";

import { isLogsCommand } from "@ds/modules/devices/players/utils/command-model";
import { iotEventCommandUpdate } from "@ds/modules/iot/redux/actions";
import { toastShowErrorAction } from "@ds/modules/notifications/redux/actions";

import { storageService } from "@ds/services/storage";
import { UnexpectedError } from "@ds/utils/errors";
import { logger } from "@ds/utils/logger";
import { isErrorLike } from "@ds/utils/type-guards/error-guards";

import { selectPlayerById } from "../../selectors";

const downloadFile = (data: Uint8Array, type: string, fileName: string) => {
  const exportUrl = URL.createObjectURL(new File([data], fileName, { type }));
  const link = document.createElement("a");
  link.setAttribute("href", exportUrl);
  link.setAttribute("download", fileName);
  document.body.appendChild(link);
  link.click();
  document.body.removeChild(link);
};

function* saveLogs({ payload }: ReturnType<typeof iotEventCommandUpdate>) {
  if (!isLogsCommand(payload) || payload.status !== StatusEnum.Succeeded) {
    return;
  }

  try {
    const publicPrefixLength = 8;
    const logsFileURL = new URL(payload.data);
    const s3Key = logsFileURL.pathname.slice(publicPrefixLength);
    const logFileData: SagaReturnType<typeof storageService.get> = yield call(
      [storageService, storageService.get],
      s3Key,
      {
        level: "public",
        download: true,
      },
    );

    if (isString(logFileData)) {
      throw new Error("Failed to fetch logs from storage");
    }

    const player = selectPlayerById(yield select(), payload.device_id);
    downloadFile(logFileData.Body, logFileData.ContentType, player ? `${player.name}.zip` : "logs.zip");
  } catch (err) {
    const errorTitle = "Fetch logs for player";
    if (isErrorLike(err)) {
      const entity = produce(payload, draft => {
        draft.status = StatusEnum.Failed;
        draft.message = "Failed to download logs file";
      });

      yield put(iotEventCommandUpdate(entity));
      yield put(toastShowErrorAction(err, errorTitle));
    } else {
      logger.error(`${errorTitle}: ${UnexpectedError}`);
      yield put(toastShowErrorAction(UnexpectedError, errorTitle));
    }
  }
}

export function* watchPlayerLogsCommand() {
  yield takeEvery(iotEventCommandUpdate, saveLogs);
}
