import { yupResolver } from "@hookform/resolvers/yup";
import { useMemo, type FC } from "react";
import { type DeepPartial } from "react-hook-form";

import { isAggregatedEntity } from "@ds/model/entity-model";

import {
  DetailsCard,
  DetailsCardEditActionsMenu,
  DetailsCardRow,
  EditableDetailsCardDecorator,
  EditableDetailsCardRowDecorator,
} from "@ds/components/details-view";
import { MultipleValuesLabel, UnassignedLabel } from "@ds/components/forms/labels/common";
import { DOW } from "@ds/utils/date";
import { isMultipleFieldValuesInAggregatedEntity } from "@ds/utils/entities";

import { formatHours } from "../../utils/datetime-helpers";
import { updateValidationSchema } from "../../utils/validation-schema";
import { LocationOpenHoursControl } from "../forms/controls/location-open-hours-control";

const LocationDetailsOpenHoursCardRow: FC<{ location: Locality; isEditing: boolean }> = ({ location, isEditing }) => {
  const hasMultipleValues = useMemo(() => isMultipleFieldValuesInAggregatedEntity(location, "open_hours"), [location]);

  if (isEditing) {
    return null;
  }

  if (!isAggregatedEntity(location)) {
    return (
      <>
        {DOW.map((day, index) => (
          <DetailsCardRow key={`open-hours-day-${day}`} label={day}>
            {formatHours(location.open_hours[index])}
          </DetailsCardRow>
        ))}
      </>
    );
  }

  if (hasMultipleValues) {
    return (
      <>
        {DOW.map((day, index) => (
          <DetailsCardRow key={`open-hours-day-${day}`} label={day}>
            {(location.metadata?.aggregatedFields?.open_hours as boolean[])?.[index] === true ? (
              <MultipleValuesLabel />
            ) : (
              formatHours(location.open_hours[index])
            )}
          </DetailsCardRow>
        ))}
      </>
    );
  }

  return <UnassignedLabel text="Location(s) has no open hours" />;
};

export const LocationDetailsOpenHoursCard: FC<{
  location: Locality;
  onSubmit: (changedFields: DeepPartial<Locality>) => void;
}> = ({ location, onSubmit }) => (
  <EditableDetailsCardDecorator
    initialValue={location}
    validationResolver={yupResolver(updateValidationSchema.openHours)}
    onSubmit={onSubmit}
  >
    {cardProps => (
      <DetailsCard
        title="Open Hours"
        icon="list"
        actions={
          <DetailsCardEditActionsMenu
            onEdit={cardProps.onEditRow("open_hours")}
            isEditing={cardProps.isEditing}
            isDirty={cardProps.isDirty}
            onReset={cardProps.onResetForm}
            onSubmit={cardProps.handleSubmit(cardProps.onSubmitForm)}
          />
        }
      >
        <EditableDetailsCardRowDecorator
          field="open_hours"
          input={<LocationOpenHoursControl control={cardProps.control} setValue={cardProps.setValue} />}
          width="100%"
          shouldEditWholeCard
          {...cardProps}
        >
          {rowProps => (
            <DetailsCardRow label="Choose open hours" editableSettings={rowProps}>
              <LocationDetailsOpenHoursCardRow location={cardProps.entity} isEditing={cardProps.isEditing} />
            </DetailsCardRow>
          )}
        </EditableDetailsCardRowDecorator>
      </DetailsCard>
    )}
  </EditableDetailsCardDecorator>
);
