import { createSlice, type PayloadAction } from "@reduxjs/toolkit";
import { all } from "redux-saga/effects";

import { ContentTableTypeEnum } from "../utils/model";
import { watchCommon } from "./sagas";

interface ContentState {
  viewType: ContentTableTypeEnum | undefined;
}

const initialState: ContentState = {
  viewType: undefined,
};

const sliceType = "CONTENT";
const slice = createSlice({
  name: sliceType,
  initialState,
  reducers: {
    switchView: (state, { payload }: PayloadAction<ContentTableTypeEnum>) => {
      state.viewType = payload;
    },
  },
});

export const { name: contentType, actions: contentActions, reducer: contentReducer } = slice;

export function* rootContentSaga() {
  yield all([watchCommon()]);
}
