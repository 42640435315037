import { yupResolver } from "@hookform/resolvers/yup";
import { useCallback, useEffect, type FC } from "react";
import { useForm } from "react-hook-form";
import styled from "styled-components";

import { createValidationSchema } from "@ds/modules/devices/players/utils/validation-schema";

import { WizardPage, type WizardChildStepProps } from "@ds/components/wizard";

import { PlayerNameControl } from "../controls/player-name-control";
import { PlayerPairingCodeControl } from "../controls/player-pairing-code-control";
import { useCreatePlayer } from "./create-player-context";

const InfoText = styled.div`
  margin-top: 70px;
  font-size: 0.95rem;
  color: var(--textColor060);
  white-space: pre-wrap;
  overflow: hidden;
  text-overflow: ellipsis;
`;

export const Step1Main: FC<WizardChildStepProps> = ({ isActive, nextStep, ...rest }) => {
  const [currentPlayerData, submitPlayerData] = useCreatePlayer();
  const { control, getValues, handleSubmit, reset } = useForm<PlayerFormData>({
    mode: "all",
    reValidateMode: "onChange",
    defaultValues: currentPlayerData,
    resolver: yupResolver(createValidationSchema.step1),
  });

  useEffect(() => {
    if (isActive) {
      reset(currentPlayerData);
    }
  }, [currentPlayerData, isActive, reset]);

  const onNextClickHandler = useCallback(() => {
    submitPlayerData(getValues());
    if (nextStep) {
      nextStep();
    }
  }, [submitPlayerData, getValues, nextStep]);

  return (
    <WizardPage {...rest} title="Add player" nextStep={handleSubmit(onNextClickHandler)}>
      <PlayerNameControl name="player.name" control={control} autoFocus />
      <PlayerPairingCodeControl control={control} />
      <InfoText>You can always pair your player later.</InfoText>
    </WizardPage>
  );
};
