import { UnassignedLabel } from "@ds/components/forms/labels/common";
import { ShadedLabel } from "@ds/components/forms/labels/styles";
import { StatusIndicator } from "@ds/components/status-indicator";
import type { ColumnBodyOptions } from "@ds/components/table/table-columns";
import { TextLink } from "@ds/components/text-link";
import { composeDetailsRouteByEntityType } from "@ds/utils/router";

import { getInvitationPrintableStatus } from "../../utils/helpers";

export const getInvitationStatusIndicator = (
  invitation: Invitation,
  options?: ColumnBodyOptions & { key?: string },
) => (
  <StatusIndicator key={options?.key} status={getInvitationPrintableStatus(invitation.status)}>
    {invitation.status}
  </StatusIndicator>
);

export const getInvitationStatusIndicatorWithNameAndLink = (invitation: Invitation, options?: { key?: string }) => {
  const status = `[ ${invitation.status} ]`;
  return (
    <StatusIndicator
      key={options?.key}
      status={getInvitationPrintableStatus(invitation.status)}
      title={`${invitation.email} ${status}`}
    >
      <TextLink to={composeDetailsRouteByEntityType(invitation)}>{invitation.email || <UnassignedLabel />}</TextLink>
      <ShadedLabel className="ml-2">{status}</ShadedLabel>
    </StatusIndicator>
  );
};
