import { useCallback, type FC } from "react";

import { MainTableDataTypeEnum } from "@ds/modules/table-data/utils/model";

import { UnassignedLabel } from "@ds/components/forms/labels/common";
import { Loading } from "@ds/components/loaders/loading";
import { TableWithPagination } from "@ds/components/table/main-table";
import {
  Column,
  getEntityIdColumn,
  getEntityNameColumn,
  getLastUpdatedColumn,
  getSelectionColumn,
  getStatusColumn,
} from "@ds/components/table/table-columns";
import { TotalSelectedLabel } from "@ds/components/table/total-selected-label";
import { TextLink } from "@ds/components/text-link";
import { usePagination, useRootDispatch, useRootSelector, useTableDataSorting } from "@ds/hooks";
import { composeDetailsRouteByEntityType } from "@ds/utils/router";

import { selectPlayerById } from "../../players/redux/selectors";
import { PeripheralUpdateAtSortFieldName } from "../constants/common";
import { selectPeripheralsIsLoading, selectSelectedPeripherals, selectTablePeripherals } from "../redux/selectors";
import { DevicePeripheralsRemoteOperation, peripheralsActions } from "../redux/slice";
import { getPeripheralStatusIndicator } from "./common/status-indicator";

const usePeripheralNameTemplate = (peripheral: Peripheral) => {
  const player = useRootSelector(state => selectPlayerById(state, peripheral.device_id));
  return player?.name ? (
    <TextLink to={composeDetailsRouteByEntityType(player)}>{player.name}</TextLink>
  ) : (
    <UnassignedLabel />
  );
};

export const PeripheralsTable: FC = () => {
  const selectedEntities = useRootSelector(selectSelectedPeripherals);
  const entities = useRootSelector(selectTablePeripherals);

  const isLoading = useRootSelector(selectPeripheralsIsLoading);
  const checkboxIsLoading = useRootSelector(state =>
    selectPeripheralsIsLoading(state, DevicePeripheralsRemoteOperation.CHECKBOXES),
  );

  const [pagination, setPagination] = usePagination(MainTableDataTypeEnum.DevicePeripherals);
  const [{ sortOrder, sortField }, setSorting] = useTableDataSorting(MainTableDataTypeEnum.DevicePeripherals);

  const dispatch = useRootDispatch();
  const onToggleRowSelected = useCallback(
    (entry: Peripheral) => dispatch(peripheralsActions.toggleSelected(entry.id)),
    [dispatch],
  );

  const onSingleRowSelectHandler = useCallback(
    (entry: Peripheral) => dispatch(peripheralsActions.selectPeripherals([entry])),
    [dispatch],
  );

  const onToggleAllHandler = useCallback(
    (isSelectedAll: boolean) => {
      if (isSelectedAll) {
        dispatch(
          peripheralsActions.fetchPeripherals(undefined, undefined, DevicePeripheralsRemoteOperation.CHECKBOXES),
        );
      } else {
        dispatch(peripheralsActions.selectPeripherals([]));
      }
    },
    [dispatch],
  );

  return (
    <Loading isLoading={isLoading}>
      <TableWithPagination
        value={entities}
        selection={selectedEntities}
        onSingleRowSelect={onSingleRowSelectHandler}
        toggleRowSelected={onToggleRowSelected}
        loading={isLoading}
        sortField={sortField}
        sortOrder={sortOrder}
        onSort={setSorting}
        pagination={pagination}
        onPageChange={setPagination}
      >
        {getSelectionColumn({
          total: pagination.total,
          selected: selectedEntities.length,
          isLoading: checkboxIsLoading,
          onToggleAll: onToggleAllHandler,
        })}

        {getEntityIdColumn()}
        {getEntityNameColumn({ style: { flexGrow: 2 } })}

        <Column
          field="device"
          header="Player"
          body={usePeripheralNameTemplate}
          style={{ flexGrow: 3 }}
          resizeable={false}
        />

        {getStatusColumn({ field: "state", body: getPeripheralStatusIndicator })}
        {getLastUpdatedColumn(PeripheralUpdateAtSortFieldName)}
      </TableWithPagination>

      <TotalSelectedLabel selectedCount={checkboxIsLoading ? Number(pagination.total) : selectedEntities.length} />
    </Loading>
  );
};
