import * as yup from "yup";

import { type Shape } from "@ds/utils/validation";

const nameSchema = yup.string().required("Please specify player name");
const experienceSchema = yup.object().nullable().shape<Shape<Experience>>({});

export const createValidationSchema = {
  step1: yup.object().shape<Shape<PlayerFormData>>({
    player: yup.object().shape<Shape<Player>>({
      name: nameSchema,
    }),
  }),
  step2: yup.object().shape<Shape<PlayerFormData>>({
    experience: experienceSchema,
  }),
};

export const updateValidationSchema = {
  info: yup.object().shape<Shape<Player>>({
    name: nameSchema,
  }),
  experience: yup.object().shape<Shape<PlayerFormData>>({
    experience: experienceSchema,
  }),
};
