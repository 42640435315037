import { useCallback, useRef, useState } from "react";

import { Chip, type ChipProps } from "@ds/components/chips";

import { ChipsWrapper } from "./styles";
import { type MultipleAutoCompleteProps, type SingleAutoCompleteProps } from "./types";

export const AUTOCOMPLETE_REMOVE_CHIP_LABEL = "remove chip";

export const AutoCompleteMultipleDecorator = <
  TEntity extends Entity,
  TChildComponent extends JSX.Element,
  TChildComponentProps extends SingleAutoCompleteProps<TEntity>,
>({
  inputRef,
  value,
  dropdown,
  children,
  chipsMapper,
  filterSuggestionsPredicate,
  onChange,
  onBlur,
  onMouseDown,
}: MultipleAutoCompleteProps<TEntity, TChildComponent, TChildComponentProps>) => {
  const customRef = useRef<HTMLInputElement | null>(null);
  const [entities, setEntities] = useState<TEntity[]>(value || []);

  const onChangeHandler = useCallback(
    (entity: TEntity | null) => {
      if (entity) {
        const updatedEntities = [...entities, entity];
        setEntities(updatedEntities);
        onChange(updatedEntities);
      }
    },
    [entities, onChange],
  );

  const onRemoveHandler = useCallback(
    (entityIndex: number) => {
      const updatedEntities = [...entities];
      updatedEntities.splice(entityIndex, 1);
      setEntities(updatedEntities);
      onChange(updatedEntities);
      customRef.current?.focus();
    },
    [entities, customRef, onChange],
  );

  const filterSuggestionsPredicateHandler = useCallback(
    ({ id }: TEntity): boolean => (entities || []).every(entry => +entry.id !== +id),
    [entities],
  );

  const childProps: Pick<
    TChildComponentProps,
    | "customRef"
    | "inputRef"
    | "shouldBeClearedImmediately"
    | "dropdown"
    | "filterSuggestionsPredicate"
    | "onChange"
    | "onBlur"
    | "onMouseDown"
  > = {
    customRef,
    inputRef,
    shouldBeClearedImmediately: true,
    dropdown,
    filterSuggestionsPredicate: filterSuggestionsPredicate || filterSuggestionsPredicateHandler,
    onChange: onChangeHandler,
    onBlur,
    onMouseDown,
  };

  const renderChips = useCallback(
    () =>
      entities.map((entity, entityIndex) => {
        const [key, label, chipOptions] = chipsMapper(entity);
        const chipProps: ChipProps = {
          label,
          onRemove: () => onRemoveHandler(entityIndex),
          removable: chipOptions?.isRemovable ?? true,
        };

        if (chipOptions?.template) {
          chipProps.template = (
            <>
              {chipOptions.template}
              {chipProps.removable && (
                <span
                  tabIndex={0}
                  role="button"
                  aria-label={AUTOCOMPLETE_REMOVE_CHIP_LABEL}
                  className="p-chip-remove-icon pi pi-times-circle"
                  onClick={chipProps.onRemove}
                  onKeyDown={event => {
                    if (event.key === "Enter" && chipProps.onRemove) {
                      onRemoveHandler(entityIndex);
                    }
                  }}
                />
              )}
            </>
          );
        }

        return <Chip key={key} {...chipProps} />;
      }),
    [entities, chipsMapper, onRemoveHandler],
  );

  return (
    <>
      {children(childProps)}
      <ChipsWrapper>{renderChips()}</ChipsWrapper>
    </>
  );
};
