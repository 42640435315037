import { useCallback, type FocusEvent } from "react";

import { CONTENT_ASSET_MEDIA_TYPE_CHECKBOX_LABEL } from "@ds/modules/content/playlists/components/forms/controls/playlist-media-types-control";

import { AUTOCOMPLETE_REMOVE_CHIP_LABEL } from "../forms/autocomplete";

export const useDetailsInlineSaving = <T = HTMLInputElement>(onBlur?: () => void) =>
  useCallback(
    (onBlurFn: () => void) => (e: FocusEvent<T>) => {
      onBlurFn();
      if (
        onBlur &&
        !e.relatedTarget?.classList.contains("p-autocomplete-dropdown") &&
        !e.relatedTarget?.classList.contains("p-inputnumber-button") &&
        e.relatedTarget?.role !== "option" &&
        e.relatedTarget?.ariaLabel !== CONTENT_ASSET_MEDIA_TYPE_CHECKBOX_LABEL &&
        e.relatedTarget?.ariaLabel !== AUTOCOMPLETE_REMOVE_CHIP_LABEL &&
        e.relatedTarget?.nodeName?.toLowerCase() !== "a"
      ) {
        onBlur();
      }
    },
    [onBlur],
  );

export const useDetailsInlineDivSaving = <T = HTMLInputElement>(onBlur?: () => void) =>
  useCallback(
    (e: FocusEvent<T>) => {
      if (onBlur && e.relatedTarget?.nodeName?.toLowerCase() !== "input") {
        onBlur();
      }
    },
    [onBlur],
  );
