import { type RootState } from "@ds/store/bootstrap";

import { LoadStatus } from "@ds/utils/reducer";

import { selectBaseState } from "./common-selectors";

export const selectPlaylistAssetUploadData = (state: RootState) => selectBaseState(state).uploadsByFilename;

export const selectPlaylistAssetIsUploading = (state: RootState) =>
  Object.values(selectPlaylistAssetUploadData(state)).some(({ loadStatus }) => loadStatus === LoadStatus.Loading);

export const selectPlaylistAssetIsUploadingFailed = (state: RootState) =>
  Object.values(selectPlaylistAssetUploadData(state)).some(({ loadStatus }) => loadStatus === LoadStatus.Failed);

export const selectPlaylistAssetIsUploadingSucceeded = (state: RootState) =>
  Object.values(selectPlaylistAssetUploadData(state)).every(({ loadStatus }) => loadStatus === LoadStatus.Succeeded);

export const selectIsPlaylistAssetUploadModalMinimized = (state: RootState) =>
  selectBaseState(state).isUploadModalMinimized;
