import { useCallback } from "react";

import { selectTableData } from "@ds/modules/table-data/redux/selectors";
import { DEFAULT_SORT_FIELD, DEFAULT_TABLE_TAB, tableDataActions } from "@ds/modules/table-data/redux/slice";
import { DetailsTableDataTypeEnum, MainTableDataTypeEnum } from "@ds/modules/table-data/utils/model";

import { type DataTableSortEvent } from "@ds/components/table/data-table";

import { useDeepRootSelector, useRootDispatch } from "./redux-hooks";

export const useTableDataSorting = (
  tableType: MainTableDataTypeEnum | DetailsTableDataTypeEnum,
  tableTab = DEFAULT_TABLE_TAB,
  queryFilters?: KeysToCamelCase<QueryFilters>,
) => {
  const {
    sorting: { sortField, sortOrder },
    queryInfo,
  } = useDeepRootSelector(state => selectTableData(state, tableType, tableTab, queryFilters));

  const dispatch = useRootDispatch();
  return [
    { sortField, sortOrder: sortOrder === "asc" ? 1 : -1 },
    useCallback(
      (sortData: DataTableSortEvent) => {
        dispatch(
          tableDataActions.updateTableData({
            tableType,
            tableTab,
            sorting: {
              sortField: sortData.sortField || DEFAULT_SORT_FIELD,
              sortOrder: sortData.sortOrder === 1 ? "asc" : "desc",
            },
            queryInfo,
            options: { shouldDisableSagaThrottling: true },
          }),
        );
      },
      [dispatch, tableType, tableTab, queryInfo],
    ),
  ] as const;
};
