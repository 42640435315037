import { useCallback, useEffect, useRef } from "react";

import { callWithRetry } from "@ds/utils/call-with-retry";

export const useGetElemenetById = (elementId: string) => {
  const element = useRef(document.getElementById(elementId));
  const setElement = useCallback((value: HTMLElement | null) => {
    element.current = value;
  }, []);

  useEffect(() => {
    element.current = document.getElementById(elementId);
    return () => {
      setElement(null);
    };
  }, [elementId, setElement]);

  useEffect(() => {
    if (!element.current) {
      callWithRetry(
        () =>
          new Promise((resolve, reject) => {
            const elementToSet = document.getElementById(elementId);
            if (elementToSet) {
              setElement(elementToSet);
              return resolve(undefined);
            }

            return reject();
          }),
      );
    }
  }, [elementId, setElement]);

  return [element.current, setElement] as const;
};
