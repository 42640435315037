import { useCallback, useRef } from "react";
import { type DropzoneRef } from "react-dropzone";

export const useDropzoneRef = () => {
  const dropzoneRef = useRef<DropzoneRef>(null);
  return [
    dropzoneRef,
    useCallback(() => {
      if (dropzoneRef.current) {
        dropzoneRef.current.open();
      }
    }, [dropzoneRef]),
  ] as const;
};
