import { isArray } from "lodash";

import { EntityTypeName, isEntity } from "@ds/model/entity-model";

import { AddressCountry } from "@ds/services/google-api";
import { isPlainObject } from "@ds/utils/type-guards/common-guards";

export enum LocationTableTypeEnum {
  Locations = "locations",
}

type CreateAddressProps = (options?: { organization?: string; placeId?: string; country?: AddressCountry }) => Address;

export const createAddress: CreateAddressProps = (
  initialAddress = {
    organization: "",
    placeId: "",
    country: AddressCountry.USA,
  },
) => ({
  country: initialAddress.country || AddressCountry.USA,
  organization: initialAddress.organization || "",
  street_address: ["", ""],
  locality: "",
  administrative_area: "",
  post_code: "",
  place_id: initialAddress.placeId || "",
  coordinates: "",
});

export const getDefaultOpenHours = (): LocalityOpeningHours[] =>
  new Array(7).fill(null).map(() => ({
    closed: true,
    open: "0000",
    close: "0000",
  }));

export const createLocationModel = (): Locality => ({
  __typename: EntityTypeName.LOCATION,
  id: 0,
  project_id: 0,
  name: "",
  principal: false,
  open_hours: getDefaultOpenHours(),
  address: createAddress(),
});

export const isLocation = (obj: unknown): obj is Locality =>
  isEntity(obj) && obj.__typename === EntityTypeName.LOCATION;

export const isLocationArray = (obj: unknown): obj is Locality[] => isArray(obj) && obj.every(isLocation);

export const isLocationQueryOutput = (obj: unknown): obj is QueryOutput<Locality> =>
  isPlainObject(obj) && "items" in obj && isLocationArray(obj.items);
