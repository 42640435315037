import { useContext, type FC } from "react";
import { Navigate, Route, Routes } from "react-router-dom";
import styled, { ThemeProvider } from "styled-components";

import { ContentRouter } from "@ds/modules/content/content-router";
import { DeploymentsRouter } from "@ds/modules/deployments/deployments-router";
import { ExperiencesRouter } from "@ds/modules/experiences/experiences-router";
import { LocationsRouter } from "@ds/modules/locations/locations-router";

import { NavigationSidebar } from "@ds/components/navigation-sidebar/navigation-sidebar";
import {
  CONTENT_ROUTE,
  DASHBOARD_ROUTE,
  DEPLOYMENTS_ROUTE,
  DEVICES_ROUTE,
  EXPERIENCES_ROUTE,
  LOCATIONS_ROUTE,
  REPORTS_ROUTE,
  ROOT_ROUTE,
  SETTINGS_ROUTE,
} from "@ds/constants/router";

import { DashboardRouter } from "../dashboard/dashboard-router";
import { DevicesRouter } from "../devices/devices-router";
import { ReportsRouter } from "../reports/reports-router";
import { SettingsRouter } from "../settings/settings-router";
import { GlobalContext } from "./components/global-context";
import { HeaderLeft } from "./components/header-left";
import { HeaderRight } from "./components/header-right";
import { NotFoundPage } from "./components/not-found-page";

const Wrapper = styled.div`
  display: flex;
  flex-flow: column;
  margin-left: var(--navigation-width);
  height: 100vh;
`;

const MainContent = styled.main`
  display: flex;
  flex-flow: column;
  flex-grow: 1;
  background: var(--mainBackgroundColor);
`;

const HeaderContent = styled.header`
  height: var(--page-header-height);
  min-height: var(--page-header-height);
  padding: 0 16px;
  background: var(--lightSurfaceColor);
  display: flex;
  align-items: center;
  box-shadow: 0 0 10px 2px var(--blackShadowColor005);
  z-index: 3;

  @media only screen and (max-width: 992px) {
    flex-flow: column-reverse;
    justify-content: space-around;
    align-items: flex-start;

    > :last-child {
      align-self: flex-end;
    }
  }
`;

export const MainRouter: FC = () => {
  const { currentTheme } = useContext(GlobalContext);

  const theme: {
    navigationColor: string;
    navigationActiveColor: string;
    navigationBackgroundColor: string;
    navigationHoverBackgroundColor: string;
    navigationActiveBackgroundColor: string;
  } =
    currentTheme === "light"
      ? {
          navigationColor: "var(--navigationColor--light)",
          navigationActiveColor: "var(--navigationActiveColor--light)",
          navigationBackgroundColor: "var(--navigationBackgroundColor--light)",
          navigationHoverBackgroundColor: "var(--navigationHoverBackgroundColor--light)",
          navigationActiveBackgroundColor: "var(--navigationActiveBackgroundColor--light)",
        }
      : {
          navigationColor: "var(--navigationColor--dark)",
          navigationActiveColor: "var(--navigationActiveColor--dark)",
          navigationBackgroundColor: "var(--navigationBackgroundColor--dark)",
          navigationHoverBackgroundColor: "var(--navigationHoverBackgroundColor--dark)",
          navigationActiveBackgroundColor: "var(--navigationActiveBackgroundColor--dark)",
        };

  return (
    <Wrapper>
      <ThemeProvider theme={theme}>
        <NavigationSidebar />
        <MainContent>
          <HeaderContent>
            <HeaderLeft />
            <HeaderRight />
          </HeaderContent>
          <Routes>
            <Route path="*" element={<NotFoundPage />} />
            <Route path={ROOT_ROUTE} element={<Navigate to={EXPERIENCES_ROUTE} replace />} />
            <Route path={`${EXPERIENCES_ROUTE}/*`} element={<ExperiencesRouter />} />
            <Route path={`${DEVICES_ROUTE}/*`} element={<DevicesRouter />} />
            <Route path={`${LOCATIONS_ROUTE}/*`} element={<LocationsRouter />} />
            <Route path={`${CONTENT_ROUTE}/*`} element={<ContentRouter />} />
            <Route path={`${DEPLOYMENTS_ROUTE}/*`} element={<DeploymentsRouter />} />
            <Route path={`${DASHBOARD_ROUTE}/*`} element={<DashboardRouter />} />
            <Route path={`${REPORTS_ROUTE}/*`} element={<ReportsRouter />} />
            <Route path={`${SETTINGS_ROUTE}/*`} element={<SettingsRouter />} />
          </Routes>
        </MainContent>
      </ThemeProvider>
    </Wrapper>
  );
};
