import { produce } from "immer";
import { omit } from "lodash";
import { type SetOptional } from "type-fest";

import { normalizeBase } from "@ds/model/helpers";

import { normalize as playerNormalize } from "@ds/modules/devices/players/utils/normalizer";
import { normalize as locationNormalize } from "@ds/modules/locations/utils/normalizer";

import { isApiExperience, isExperienceArray, isExperienceQueryOutput } from "./model";
import { normalizeFormationProperties } from "./template-formation-properties-to-entity-normalizer";

const normalizer = (entity: ApiExperience) =>
  produce(normalizeBase(entity), (draft: ApiExperience) => {
    draft.status_message = entity.status_message || undefined;
    draft.app_type = entity.app_type || undefined;
    draft.app_id = entity.app_id || undefined;
    draft.devices = playerNormalize(entity.devices);
    draft.location = locationNormalize(entity.location);
    draft.formation.properties = Object.fromEntries(
      Object.entries(draft.formation.properties as TemplateFormationFieldPropertyMap)
        .filter(([_, v]: [string, ApiBaseFormationProperty]) => !v.hidden && !v.deprecated)
        .map(([k, v]) => [k, omit(v, ["metadata"])]),
    ) as EntityFormationFieldPropertyMap;

    delete (draft.formation as TemplateFormation).name_constructor;
  });

export const normalize = ((
  entityOrEntities: ApiExperience | ApiExperience[] | QueryOutput<ApiExperience>,
  templateFormationProperties?: TemplateFormationFieldPropertyMap,
): ApiExperience | ApiExperience[] | QueryOutput<ApiExperience> => {
  if (isExperienceQueryOutput(entityOrEntities)) {
    const result = produce(entityOrEntities, draft => {
      draft.items = entityOrEntities.items.map(normalizer);
    });

    return normalizeFormationProperties(result, templateFormationProperties);
  }

  if (isExperienceArray(entityOrEntities)) {
    const result = entityOrEntities.map(normalizer);
    return normalizeFormationProperties(result, templateFormationProperties);
  }

  return normalizeFormationProperties(normalizer(entityOrEntities), templateFormationProperties);
}) as {
  (experience: ApiExperience, templateFormationProperties?: TemplateFormationFieldPropertyMap): ApiExperience;
  (experiences: ApiExperience[], templateFormationProperties?: TemplateFormationFieldPropertyMap): ApiExperience[];
  (
    experiencesQueryOutput: QueryOutput<ApiExperience>,
    templateFormationProperties?: TemplateFormationFieldPropertyMap,
  ): QueryOutput<ApiExperience>;
};

const apiToModelNormalizer = (entity: Experience | ApiExperience): Experience => {
  if (!isApiExperience(entity)) {
    return entity;
  }

  return produce(normalizeBase(entity), (draft: SetOptional<ApiExperience, "devices" | "location">) => {
    delete draft.devices;
    delete draft.location;
  });
};

export const apiToModelNormalize = ((
  entityOrEntities:
    | Experience
    | Experience[]
    | QueryOutput<Experience>
    | ApiExperience
    | ApiExperience[]
    | QueryOutput<ApiExperience>,
): Experience | Experience[] | QueryOutput<Experience> => {
  if (isExperienceQueryOutput(entityOrEntities)) {
    return produce(entityOrEntities, draft => {
      draft.items = entityOrEntities.items.map(item => apiToModelNormalizer(item) as ApiExperience);
    });
  }

  return isExperienceArray(entityOrEntities)
    ? entityOrEntities.map(apiToModelNormalizer)
    : apiToModelNormalizer(entityOrEntities);
}) as {
  (experience: Experience): Experience;
  (experience: Experience[]): Experience[];
  (experience: QueryOutput<ApiExperience>): QueryOutput<Experience>;
  (Experience: ApiExperience): Experience;
  (Experiences: ApiExperience[]): Experience[];
  (ExperiencesQueryOutput: QueryOutput<ApiExperience>): QueryOutput<Experience>;
};
