import { format, differenceInMinutes, isSameYear, subDays, isSameDay } from "date-fns";

export const DOW = ["Sunday", "Monday", "Tuesday", "Wednesday", "Thursday", "Friday", "Saturday"];

const formatDate = (date: Date) => {
  const now = new Date();
  return isSameYear(now, date) ? format(date, "MMM d, p") : format(date, "MMM d, yyyy");
};

export const formatRelativeDate = (givenDate?: string | null): string => {
  if (!givenDate) {
    return "";
  }

  const now = new Date();
  const date = new Date(givenDate);

  const diffMinutes = differenceInMinutes(now, date);
  if (diffMinutes === 0) {
    return "few seconds ago";
  }

  if (diffMinutes < 2) {
    return "a minute ago";
  }

  if (diffMinutes < 60) {
    return `${diffMinutes} minutes ago`;
  }

  if (isSameDay(now, date)) {
    return format(date, "'Today,' p");
  }

  const yesterday = subDays(now, 1);
  if (isSameDay(yesterday, date)) {
    return format(date, "'Yesterday,' p");
  }

  return formatDate(date);
};

export const formatRelativeDateEntity = (entity: Entity): string => formatRelativeDate(entity.audit?.updated_at);

export const formatAbsoluteDate = (givenDate?: string | number | null) =>
  givenDate ? formatDate(new Date(givenDate)) : "";
