import { useCallback, type FC } from "react";
import { Controller, useWatch, type Control } from "react-hook-form";

import { useDetailsInlineSaving } from "@ds/components/details-view/hooks";
import { InputNumber } from "@ds/components/forms/inputs";
import { LabelWrapper } from "@ds/components/forms/labels";
import { isHtmlInputChangeEvent } from "@ds/utils/type-guards/form-guards";
import { isFormationPropertyRequired } from "@ds/utils/validation";

import { type FormationChangeHandlerDecorator, type FormationChangeHandlerInputData } from "./types";

export const NumberFormationControl: FC<{
  control: Control<Experience | Project>;
  name: `formation.properties.${string}` | `template.experience_formation.properties.${string}`;
  property: TemplateNumberFormationProperty;
  autoFocus?: boolean;
  excludeLabel?: boolean;
  onBeforeChangeHandlerDecorator: FormationChangeHandlerDecorator;
  onBlur?: () => void;
}> = ({ control, name, property, autoFocus, excludeLabel, onBeforeChangeHandlerDecorator, onBlur }) => {
  const onBlurHandler = useDetailsInlineSaving(onBlur);
  const propertyValue = (useWatch({ name, control }) as EntityNumberFormationProperty)?.value;
  const onChangeHandler = useCallback(
    (onChange: (inputData: FormationChangeHandlerInputData) => void) =>
      onBeforeChangeHandlerDecorator(onChange, propertyValue, inputData =>
        isHtmlInputChangeEvent(inputData) ? +inputData.target.value : null,
      ),
    [propertyValue, onBeforeChangeHandlerDecorator],
  );

  return (
    <Controller
      name={`${name}.value`}
      control={control}
      render={({ field: { ref, value, onChange, onBlur: onBlurFn, ...restFields }, fieldState }) => {
        const element = (
          <InputNumber
            inputRef={ref}
            value={Number(value)}
            autoFocus={autoFocus}
            onChange={inputData => onChangeHandler(onChange)(inputData.value || 0)}
            onBlur={onBlurHandler(onBlurFn)}
            {...restFields}
          />
        );

        if (excludeLabel) {
          return element;
        }

        return (
          <LabelWrapper
            label={property.title}
            className="col-8"
            isRequired={isFormationPropertyRequired(property.metadata?.validation_rules)}
            error={fieldState.error}
          >
            {element}
          </LabelWrapper>
        );
      }}
    />
  );
};
