import { useCallback, type FC, type MouseEvent } from "react";

import { DEFAULT_TABLE_TAB } from "@ds/modules/table-data/redux/slice";
import { MainTableDataTypeEnum } from "@ds/modules/table-data/utils/model";

import { DndDropzone } from "@ds/components/dnd-dropzone";
import { Loading } from "@ds/components/loaders/loading";
import { useDeepRootSelector, usePagination, useRootDispatch, useRootSelector } from "@ds/hooks";

import { GridWithPagination } from "../../../../components/grid/grid-with-pagination";
import {
  selectContentAssetsIsLoading,
  selectSelectedContentAssets,
  selectTableContentAssets,
} from "../redux/selectors/common-selectors";
import { contentAssetsActions } from "../redux/slice";
import { createContentAssetFiltersByTableType, getSupportedFormatsByTableType } from "../utils/helpers";
import { ContentAssetThumbnailGridItem } from "./common/content-asset-thumbnail-grid-item";

export const ContentAssetsGrid: FC<{
  tableType: MainTableDataTypeEnum;
  onUpload: (files: File[]) => void;
}> = ({ tableType, onUpload }) => {
  const [selectedEntities, entities] = useDeepRootSelector(state => [
    selectSelectedContentAssets(state, tableType),
    selectTableContentAssets(state, tableType),
  ]);

  const isLoading = useRootSelector(selectContentAssetsIsLoading);

  const queryFilters = createContentAssetFiltersByTableType({ tableType });
  const [pagination, setPagination] = usePagination(tableType, DEFAULT_TABLE_TAB, queryFilters);

  const dispatch = useRootDispatch();
  const onSelectHandler = useCallback(
    (e: MouseEvent<Element>, asset: ContentAsset) => {
      const selectedAsset = selectedEntities.find(({ id }: ContentAsset) => asset.id === id);
      const selectedIndex = selectedAsset ? selectedEntities.indexOf(selectedAsset) : -1;
      let newSelected: ContentAsset[] = [];

      if (selectedIndex === -1) {
        newSelected = newSelected.concat(e.ctrlKey ? [...selectedEntities, asset] : asset);
      } else if (selectedIndex === 0) {
        newSelected = newSelected.concat(
          e.ctrlKey || selectedEntities.length === 1 ? selectedEntities.slice(1) : asset,
        );
      } else if (selectedIndex === selectedEntities.length - 1) {
        newSelected = newSelected.concat(
          e.ctrlKey || selectedEntities.length === 1 ? selectedEntities.slice(0, -1) : asset,
        );
      } else if (selectedIndex > 0) {
        newSelected = newSelected.concat(
          selectedEntities.slice(0, selectedIndex),
          selectedEntities.slice(selectedIndex + 1),
        );
      }

      dispatch(contentAssetsActions.selectContentAssets(newSelected, tableType));
    },
    [dispatch, tableType, selectedEntities],
  );

  const renderMainDataGrid = useCallback(
    () =>
      entities.map((asset: ContentAsset) => {
        const isSelected = !!selectedEntities.find(({ id }: ContentAsset) => asset.id === id);
        return (
          <ContentAssetThumbnailGridItem
            asset={asset}
            key={`grid-content-asset-${asset.key}`}
            isSelected={isSelected}
            onSelect={onSelectHandler}
          />
        );
      }),
    [entities, selectedEntities, onSelectHandler],
  );

  return (
    <Loading isLoading={isLoading}>
      <DndDropzone acceptMediaTypes={getSupportedFormatsByTableType(tableType)} onUpload={onUpload}>
        <GridWithPagination pagination={pagination} onPageChange={setPagination}>
          {renderMainDataGrid()}
        </GridWithPagination>
      </DndDropzone>
    </Loading>
  );
};
