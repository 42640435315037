import { uniq } from "lodash";
import * as yup from "yup";

import { type Shape } from "@ds/utils/validation";

import { DeploymentScopeEnum } from "./model";

const nameSchema = yup.string().required("Please specify deployment name");

export const createValidationSchema = {
  step1: yup.object().shape<Shape<Deployment>>({}),
  step2: yup.object().shape<Shape<Deployment>>({
    scope: yup.string().required("Please specify deployment scope"),
    targets: yup
      .array()
      .of(yup.number())
      .when("scope", {
        is: (scope: DeploymentScopeEnum) => scope === DeploymentScopeEnum.All,
        then: schema => schema.min(0),
        otherwise: schema => schema.min(1, "Please specify at least one target"),
      })
      .test("isUniqueTargets", (targets, ctx) => {
        if (targets?.length && uniq(targets).length !== targets?.length) {
          return ctx.createError({
            message: "Remove duplicates from the list.",
          });
        }

        return true;
      }),
  }),
  step3: yup.object().shape<Shape<Deployment>>({
    name: nameSchema,
  }),
};

export const updateValidationSchema = {
  info: yup.object().shape<Shape<Deployment>>({
    name: nameSchema,
  }),
};
