import { useCallback, useEffect, useState, type FC } from "react";
import styled from "styled-components";

import { HeaderTitle } from "@ds/components/header-title";
import { Loading } from "@ds/components/loaders/loading";
import { useRootDispatch, useRootSelector } from "@ds/hooks";

import {
  selectCurrentUserProject,
  selectProjectDashboardData,
  selectProjectsIsLoading,
} from "../settings/projects/redux/selectors/common-selectors";
import { ProjectsRemoteOperation, projectsActions } from "../settings/projects/redux/slice";

const IframeContainer = styled.iframe`
  height: 100%;
`;

export const DashboardMainPage: FC = () => {
  const project = useRootSelector(selectCurrentUserProject);
  const dashboard = useRootSelector(selectProjectDashboardData);
  const isLoading = useRootSelector(state => selectProjectsIsLoading(state, ProjectsRemoteOperation.DASHBOARD));
  const [url, setUrl] = useState<string | undefined>(dashboard?.embedUrl);

  const dispatch = useRootDispatch();
  useEffect(() => {
    const isLinkExpired = !dashboard.expiredAt || dashboard.expiredAt < Date.now();
    if (project && isLinkExpired) {
      setUrl(undefined);
      dispatch(projectsActions.fetchProjectDashboard(project.id));
    }
  }, [dispatch, project, dashboard.expiredAt]);

  const onLoadHandler = useCallback(() => {
    if (dashboard.embedUrl) {
      const { hostname, pathname } = new URL(dashboard.embedUrl);
      const link = `https://${hostname}${pathname}?undoRedoDisabled=true&resetDisabled=true`;
      if (dashboard.embedUrl !== link) {
        dispatch(
          projectsActions.setDashboardUrl(`https://${hostname}${pathname}?undoRedoDisabled=true&resetDisabled=true`),
        );
      }
    }
  }, [dispatch, dashboard.embedUrl]);

  useEffect(() => {
    if (!url) {
      setUrl(dashboard.embedUrl);
    }
  }, [dashboard.embedUrl, url]);

  return (
    <>
      <HeaderTitle title="Dashboard" />
      <Loading isLoading={isLoading}>
        <IframeContainer src={url} title="dashboard" onLoad={onLoadHandler} />
      </Loading>
    </>
  );
};
