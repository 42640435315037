import { UnassignedLabel } from "@ds/components/forms/labels/common";
import { ShadedLabel } from "@ds/components/forms/labels/styles";
import { StatusIndicator } from "@ds/components/status-indicator";
import { TextLink } from "@ds/components/text-link";
import { composeDetailsRouteByEntityType } from "@ds/utils/router";
import { getPrintableStatus } from "@ds/utils/status-helpers";

export const getDeploymentStatusIndicator = (deployment: Deployment, options?: { key?: string }) => (
  <StatusIndicator key={options?.key} status={deployment.status} title={deployment.message}>
    {getPrintableStatus(deployment.status)}
  </StatusIndicator>
);

export const getDeploymentStatusIndicatorWithNameAndLink = (deployment: Deployment, options?: { key?: string }) => {
  const status = `[ ${getPrintableStatus(deployment.status)} ]`;
  return (
    <StatusIndicator key={options?.key} status={deployment.status} title={`${deployment.name} ${status}`}>
      <TextLink to={composeDetailsRouteByEntityType(deployment)}>{deployment.name || <UnassignedLabel />}</TextLink>
      <ShadedLabel className="ml-2">{status}</ShadedLabel>
    </StatusIndicator>
  );
};

export const getDeviceSyncStatusIndicator = (deviceSync: DeviceSync, options?: { key?: string }) => (
  <StatusIndicator key={options?.key} status={deviceSync.status} title={deviceSync.message}>
    {getPrintableStatus(deviceSync.status)}
  </StatusIndicator>
);
