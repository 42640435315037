import { uniqBy } from "lodash";
import pluralize from "pluralize";
import * as yup from "yup";

import {
  getFormationPropertiesValidationSchema,
  type FormationPropertyValidationData,
  type Shape,
} from "@ds/utils/validation";

const nameSchema = yup.string().required("Please specify experience name");

const locationIdErrorMessage = "Please choose location from list";
const locationIdSchema = yup
  .number()
  .positive(locationIdErrorMessage)
  .typeError(locationIdErrorMessage)
  .required(locationIdErrorMessage);

const playersSchema = yup
  .array()
  .of(yup.object().shape<Shape<Player>>({}))
  .test("isUniquePlayers", (players, ctx) => {
    const assignedPlayersNames = (players as Player[])
      ?.filter(
        ({ experience_id }) => experience_id && experience_id !== (ctx.parent as ExperienceFormData).experience.id,
      )
      .map(({ name }) => name);

    if (uniqBy(players, ({ id }) => id).length !== (players?.length || 0)) {
      return ctx.createError({
        message: "Remove duplicates from the list.",
      });
    }

    if (assignedPlayersNames?.length) {
      const playersStatement = pluralize("Player", assignedPlayersNames.length);
      return ctx.createError({
        message: `${playersStatement}: "${assignedPlayersNames.join(
          ", ",
        )}", already assigned to another experience, remove from the list first.`,
      });
    }

    return true;
  });

const getFormationSchema = (validationData: FormationPropertyValidationData[]) =>
  yup.object().shape<Shape<Experience>>({
    formation: getFormationPropertiesValidationSchema(validationData),
  });

export const createValidationSchema = {
  step1: yup.object().shape<Shape<ExperienceFormData>>({
    experience: yup.object().shape<Shape<Experience>>({
      location_id: locationIdSchema,
    }),
    players: playersSchema,
  }),
  getStep2: getFormationSchema,
  step3: yup.object().shape<Shape<Experience>>({
    name: nameSchema,
  }),
};

export const updateValidationSchema = {
  info: (validationData: FormationPropertyValidationData[]) =>
    getFormationSchema(validationData).concat(
      yup.object().shape<Shape<Experience>>({
        name: nameSchema,
        location_id: locationIdSchema,
      }),
    ),
  players: yup.object().shape<Shape<ExperienceFormData>>({
    players: playersSchema,
  }),
  formation: getFormationSchema,
};
