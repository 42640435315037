import {
  CONTENT_AUDIO_ROUTE,
  CONTENT_IMAGES_ROUTE,
  CONTENT_PLAYLISTS_ROUTE,
  CONTENT_VIDEOS_ROUTE,
  DEPLOYMENTS_ROUTE,
  DEVICE_PERIPHERALS_ROUTE,
  DEVICE_PLAYERS_ROUTE,
  EXPERIENCES_ROUTE,
  LOCATIONS_ROUTE,
  SETTINGS_PROJECTS_ROUTE,
  SETTINGS_USERS_ROUTE,
} from "@ds/constants/router";

import { SETTINGS_INVITATIONS_ROUTE } from "./../../../constants/router/entity-routes";
import { DetailsTableDataTypeEnum, MainTableDataTypeEnum } from "./model";

export const getMainTableDataTypeByRoute = (pathname: string): MainTableDataTypeEnum => {
  const normalizedPathname = pathname.replace(/\/$/, "");
  switch (normalizedPathname) {
    case EXPERIENCES_ROUTE:
      return MainTableDataTypeEnum.Experiences;
    case DEVICE_PLAYERS_ROUTE:
      return MainTableDataTypeEnum.DevicePlayers;
    case DEVICE_PERIPHERALS_ROUTE:
      return MainTableDataTypeEnum.DevicePeripherals;
    case LOCATIONS_ROUTE:
      return MainTableDataTypeEnum.Locations;
    case CONTENT_PLAYLISTS_ROUTE:
      return MainTableDataTypeEnum.ContentPlaylists;
    case CONTENT_VIDEOS_ROUTE:
      return MainTableDataTypeEnum.ContentVideos;
    case CONTENT_IMAGES_ROUTE:
      return MainTableDataTypeEnum.ContentImages;
    case CONTENT_AUDIO_ROUTE:
      return MainTableDataTypeEnum.ContentAudio;
    case DEPLOYMENTS_ROUTE:
      return MainTableDataTypeEnum.Deployments;
    case SETTINGS_PROJECTS_ROUTE:
      return MainTableDataTypeEnum.Projects;
    case SETTINGS_USERS_ROUTE:
      return MainTableDataTypeEnum.Users;
    case SETTINGS_INVITATIONS_ROUTE:
      return MainTableDataTypeEnum.Invitations;
  }

  throw new Error(`Specify table data type for current route path: ${pathname}`);
};

export const getPageTitleByTableDataType = (tableType: MainTableDataTypeEnum | DetailsTableDataTypeEnum): string => {
  switch (tableType) {
    case MainTableDataTypeEnum.Experiences:
      return "Experiences";
    case MainTableDataTypeEnum.DevicePlayers:
      return "Players";
    case MainTableDataTypeEnum.DevicePeripherals:
      return "Peripherals";
    case MainTableDataTypeEnum.Locations:
      return "Locations";
    case MainTableDataTypeEnum.ContentPlaylists:
      return "Playlists";
    case MainTableDataTypeEnum.ContentVideos:
      return "Videos";
    case MainTableDataTypeEnum.ContentImages:
      return "Images";
    case MainTableDataTypeEnum.ContentAudio:
      return "Audio";
    case MainTableDataTypeEnum.Deployments:
      return "Deployments";
    case MainTableDataTypeEnum.Projects:
      return "Projects";
    case MainTableDataTypeEnum.Users:
      return "Users";
    case MainTableDataTypeEnum.Invitations:
      return "Invitations";
  }

  return "";
};

export const isMainTableDataTypeEnum = (
  tableType: MainTableDataTypeEnum | DetailsTableDataTypeEnum,
): tableType is MainTableDataTypeEnum => {
  switch (tableType) {
    case MainTableDataTypeEnum.Experiences:
    case MainTableDataTypeEnum.DevicePlayers:
    case MainTableDataTypeEnum.DevicePeripherals:
    case MainTableDataTypeEnum.Locations:
    case MainTableDataTypeEnum.ContentPlaylists:
    case MainTableDataTypeEnum.ContentVideos:
    case MainTableDataTypeEnum.ContentImages:
    case MainTableDataTypeEnum.ContentAudio:
    case MainTableDataTypeEnum.Deployments:
    case MainTableDataTypeEnum.Projects:
    case MainTableDataTypeEnum.Users:
    case MainTableDataTypeEnum.Invitations:
      return true;
  }

  return false;
};
