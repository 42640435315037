import { useEffect, useState, type FC } from "react";

import { AutocompleteTypeEnum, useFetchEntitiesAutocomplete } from "@ds/hooks/use-fetch-entities";

import {
  AutoComplete,
  AutoCompleteGroupItem,
  AutoCompleteGroupLabel,
  type SingleAutoCompleteProps,
} from "@ds/components/forms/autocomplete";

import { getPlayerStatusIndicatorWithName } from "../../devices/players/components/common/status-indicator";
import { type PlayersByExperienceGroup, type PlayersByExperienceGroupList } from "../../devices/players/utils/model";

const renderItemTemplate = (player: Player) => {
  if (!player.experience_id) {
    return (
      <AutoCompleteGroupItem $isInvalid className="autocomplete-option--disabled">
        {getPlayerStatusIndicatorWithName(player)}
      </AutoCompleteGroupItem>
    );
  }

  return <AutoCompleteGroupItem>{getPlayerStatusIndicatorWithName(player)}</AutoCompleteGroupItem>;
};

const renderOptionGroupTemplate = ({ assigned_to_status }: PlayersByExperienceGroup) => (
  <AutoCompleteGroupLabel>
    {assigned_to_status === "unassigned" ? "Unassigned players" : "Assigned players"}
  </AutoCompleteGroupLabel>
);

const getInitialGroupedValues = (): PlayersByExperienceGroupList => [
  { assigned_to_status: "unassigned", players: [] },
  { assigned_to_status: "assigned", players: [] },
];

export const DeploymentPlayersAutoComplete: FC<Omit<SingleAutoCompleteProps<Player>, "isLoading">> = ({
  customRef,
  inputRef,
  value,
  filterSuggestionsPredicate,
  dropdownMode,
  autoFocus,
  onBlur,
  onChange,
}) => {
  const [grouppedSuggestions, setGrouppedSuggestions] =
    useState<PlayersByExperienceGroupList>(getInitialGroupedValues());

  const [
    suggestions,
    onCompleteHandler,
    {
      state: { status: remoteStatus },
    },
  ] = useFetchEntitiesAutocomplete<Player>(AutocompleteTypeEnum.Players, {
    sortOrder: "asc",
    sortField: "experience_id",
    filterPredicate: filterSuggestionsPredicate,
  });

  useEffect(() => {
    if (suggestions.length) {
      const [unassigned, assigned]: PlayersByExperienceGroupList = getInitialGroupedValues();
      suggestions.forEach(entry => {
        (entry?.experience_id ? assigned : unassigned).players.push(entry);
      });

      setGrouppedSuggestions([assigned, unassigned]);
    } else {
      setGrouppedSuggestions([] as unknown as PlayersByExperienceGroupList);
    }
  }, [suggestions]);

  return (
    <AutoComplete
      field="name"
      placeholder="Enter player name"
      itemTemplate={renderItemTemplate}
      optionGroupLabel="assigned_to_status"
      optionGroupChildren="players"
      optionGroupTemplate={renderOptionGroupTemplate}
      customRef={customRef}
      inputRef={inputRef}
      value={value}
      suggestions={grouppedSuggestions}
      dropdownMode={dropdownMode}
      autoFocus={autoFocus}
      isLoading={remoteStatus === "loading"}
      onBlur={onBlur}
      onChange={onChange}
      onComplete={onCompleteHandler}
    />
  );
};
