import { createAddress, getDefaultOpenHours } from "@ds/modules/locations/utils/model";

import { AddressCountry, type GoogleTimeZoneResult } from "@ds/services/google-api";

import { roundTime } from "./datetime-helpers";

enum GeocoderAddressComponentTypes {
  COUNTRY = "country",
  LOCALITY = "locality",
  NEIGHBORHOOD = "neighborhood",
  POSTAL_CODE = "postal_code",
  STREET_POINT_OF_INTEREST = "point_of_interest",
  STREET_CODE = "plus_code",
  STREET_NUMBER = "street_number",
  STREET_ROUTE = "route",
  STREET_SUBPREMISE = "subpremise",
  ADMINISTRATIVE_AREA = "administrative_area_level_1",
}

const USTerritories = [
  AddressCountry.PUERTO_RICO,
  AddressCountry.VIRGIN_ISLANDS,
  AddressCountry.GUAM,
  AddressCountry.NORTHERN_MARIANA_ISLANDS,
];

export const parseGooglePeriods = (
  periods: google.maps.places.PlaceOpeningHoursPeriod[] = [],
): LocalityOpeningHours[] => {
  let openHours = getDefaultOpenHours();
  if (periods.length === 1 && periods[0].open.day === 0 && periods[0].open.time === "0000" && !periods[0].close) {
    openHours = openHours.map(day => ({ ...day, closed: false }));
  }

  periods.forEach(period => {
    const { day } = period.open;
    openHours[day] = {
      closed: false,
      open: roundTime(period.open.time),
      close: roundTime(period.close?.time),
    };
  });

  return openHours;
};

const parseCountryCodeComponent = (addressComponents?: google.maps.GeocoderAddressComponent[]) => {
  const country =
    (addressComponents?.filter(type => type.types.includes(GeocoderAddressComponentTypes.COUNTRY))[0]
      ?.short_name as AddressCountry) || AddressCountry.USA;

  return USTerritories.includes(country) ? [AddressCountry.USA, country] : [country, country];
};

const parseLocalityComponent = (
  addressComponents?: google.maps.GeocoderAddressComponent[],
  formattedAddress?: string,
) => {
  const locality =
    addressComponents?.filter(type => type.types.includes(GeocoderAddressComponentTypes.LOCALITY))[0]?.short_name || "";

  const neighborhood =
    addressComponents?.filter(type => type.types.includes(GeocoderAddressComponentTypes.NEIGHBORHOOD))[0]?.short_name ||
    "";

  if (!locality && !neighborhood) {
    const sliced = formattedAddress?.split(",");
    return sliced && sliced.length > 1 ? sliced[1].trim() : "";
  }

  return locality || neighborhood;
};

const parsePostalCodeComponent = (addressComponents?: google.maps.GeocoderAddressComponent[]) =>
  addressComponents?.filter(type => type.types.includes(GeocoderAddressComponentTypes.POSTAL_CODE))[0]?.short_name ||
  "";

const parseStreetAddressComponent = (
  addressComponents?: google.maps.GeocoderAddressComponent[],
  formattedAddress?: string,
) => {
  let streetPoi =
    addressComponents?.filter(type => type.types.includes(GeocoderAddressComponentTypes.STREET_POINT_OF_INTEREST))[0]
      ?.short_name || "";

  streetPoi = streetPoi ? `${streetPoi},` : streetPoi;

  const streetCode =
    addressComponents?.filter(type => type.types.includes(GeocoderAddressComponentTypes.STREET_CODE))[0]?.short_name ||
    "";

  const streetNumber =
    addressComponents?.filter(type => type.types.includes(GeocoderAddressComponentTypes.STREET_NUMBER))[0]
      ?.short_name || "";

  const streetRoute =
    addressComponents?.filter(type => type.types.includes(GeocoderAddressComponentTypes.STREET_ROUTE))[0]?.long_name ||
    "";

  const streetSubpremise =
    addressComponents?.filter(type => type.types.includes(GeocoderAddressComponentTypes.STREET_SUBPREMISE))[0]
      ?.short_name || "";

  if (!streetPoi && !streetCode && !streetNumber && !streetRoute && !streetSubpremise) {
    const sliced = formattedAddress?.split(",");
    return sliced?.length ? sliced[0].trim() : "";
  }

  return [streetPoi, streetCode, streetNumber, streetRoute, streetSubpremise]
    .filter(_ => _)
    .join(" ")
    .trim();
};

const parseAdministrativeAreaComponent = (addressComponents?: google.maps.GeocoderAddressComponent[]) => {
  const country = parseCountryCodeComponent(addressComponents)[1];
  if (USTerritories.includes(country)) {
    return country;
  }

  return (
    addressComponents?.filter(type => type.types.includes(GeocoderAddressComponentTypes.ADMINISTRATIVE_AREA))[0]
      ?.short_name || ""
  );
};

export const parseGooglePlaceData = (organization: string, place: google.maps.places.PlaceResult): Address => {
  const address = createAddress({ organization, placeId: place.place_id });
  address.country = parseCountryCodeComponent(place.address_components)[0];
  address.locality = parseLocalityComponent(place.address_components, place.formatted_address);
  address.post_code = parsePostalCodeComponent(place.address_components);
  address.street_address = [parseStreetAddressComponent(place.address_components, place.formatted_address)];
  address.administrative_area = parseAdministrativeAreaComponent(place.address_components);
  address.coordinates = `${place.geometry?.location?.lat()},${place.geometry?.location?.lng()}`;
  return address;
};

export const parseGoogleTimeZoneData = (data: GoogleTimeZoneResult): LocalityTimeZone => ({
  dst_offset: data.dstOffset,
  raw_offset: data.rawOffset,
  time_zone_id: data.timeZoneId,
  time_zone_name: data.timeZoneName,
});
