import { yupResolver } from "@hookform/resolvers/yup";
import { useCallback, type FC } from "react";
import { useForm } from "react-hook-form";

import { createPlaylistModel } from "@ds/modules/content/playlists/utils/model";

import { DescriptionControl } from "@ds/components/forms/control/description-control";
import { WizardPage, type WizardChildStepProps } from "@ds/components/wizard";
import { useRootDispatch } from "@ds/hooks";

import { playlistsActions } from "../../../redux/slice";
import { createValidationSchema } from "../../../utils/validation-schema";
import { PlaylistMediaTypesControl } from "../controls/playlist-media-types-control";
import { PlaylistNameControl } from "../controls/playlist-name-control";

export const Step1Main: FC<WizardChildStepProps> = ({ nextStep, ...rest }) => {
  const { control, getValues, handleSubmit } = useForm<Playlist>({
    mode: "all",
    reValidateMode: "onChange",
    defaultValues: createPlaylistModel(),
    resolver: yupResolver(createValidationSchema.step1),
  });

  const dispatch = useRootDispatch();
  const onNextClickHandler = useCallback(() => {
    dispatch(playlistsActions.createPlaylist(getValues()));
    if (nextStep) {
      nextStep();
    }
  }, [dispatch, getValues, nextStep]);

  return (
    <WizardPage {...rest} title="Add playlist" nextStep={handleSubmit(onNextClickHandler)}>
      <PlaylistNameControl control={control} autoFocus />
      <DescriptionControl control={control} />
      <PlaylistMediaTypesControl control={control} />
    </WizardPage>
  );
};
