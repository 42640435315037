import { type FC } from "react";
import { Controller, type Control } from "react-hook-form";
import styled from "styled-components";

import { InputText } from "@ds/components/forms/inputs";
import { renderErrorMessage } from "@ds/components/forms/labels";

import { type UserSignUpModel } from "../../../redux/types";

const FieldWrapper = styled.div`
  margin: 40px 0;
`;

const AuthInputText = styled(InputText)`
  width: 100%;
`;

const ErrorWrapper = styled.div`
  display: inline-block;
  div {
    margin-top: 5px;
  }
`;

export const NameControl: FC<{
  control: Control<UserSignUpModel>;
  name: "given_name" | "family_name";
  autoComplete?: string;
  label: string;
}> = ({ control, name, autoComplete, label }) => (
  <Controller
    name={name}
    control={control as Control<UserSignUpModel>}
    render={({ field: { ref, ...restFields }, fieldState }) => (
      <FieldWrapper>
        <span className="p-float-label">
          <AuthInputText id={name} inputRef={ref} {...restFields} autoComplete={autoComplete} isRequired />
          <label htmlFor={name}>{label}</label>
        </span>
        <ErrorWrapper>{renderErrorMessage(fieldState.error)}</ErrorWrapper>
      </FieldWrapper>
    )}
  />
);
