import { cloneDeep } from "lodash";
import { call, put, race, select, take, takeEvery, takeLatest } from "redux-saga/effects";

import { playersActions } from "@ds/modules/devices/players/redux/slice";
import { iotEventPeripheralUpdate } from "@ds/modules/iot/redux/actions";
import { toastShowErrorAction } from "@ds/modules/notifications/redux/actions";
import { isIotTableEntityAddedOrDeleted, selectTableData } from "@ds/modules/table-data/redux/selectors";
import { convertToApiQueryInfo } from "@ds/modules/table-data/utils/common";
import { MainTableDataTypeEnum } from "@ds/modules/table-data/utils/model";

import { UnexpectedError } from "@ds/utils/errors";
import { logger } from "@ds/utils/logger";
import { UNLIMITED_PAGINATION } from "@ds/utils/query";
import { takeLatestOrEvery } from "@ds/utils/saga-helpers";
import { isErrorLike } from "@ds/utils/type-guards/error-guards";

import { peripheralsService } from "../utils/api";
import { isApiPeripheralQueryOutput } from "../utils/model";
import { normalize } from "../utils/normalizer";
import { selectPeripheralById, selectPeripheralsByIds, selectTablePeripherals } from "./selectors";
import { DevicePeripheralsRemoteOperation, peripheralsActions } from "./slice";

function* fetchPeripherals({ meta }: ReturnType<typeof peripheralsActions.fetchPeripherals>) {
  try {
    const { sorting, pagination, queryInfo, isFetchedAlready } = selectTableData(
      yield select(),
      meta.options.tableType || MainTableDataTypeEnum.DevicePeripherals,
      undefined,
      meta.filters,
    );

    const newMeta = cloneDeep(meta);
    const resultFromCache: QueryOutput<Peripheral> = { items: [] };

    if (!newMeta.options.cache?.disableCache) {
      if (isFetchedAlready && newMeta.options.tableType) {
        resultFromCache.items = selectTablePeripherals(yield select(), newMeta.options.tableType);
        newMeta.options.cache = {
          ...newMeta.options.cache,
          fetchedFromCache: true,
        };
      } else if (newMeta.filters.id && Object.keys(newMeta.filters).length === 1) {
        resultFromCache.items = selectPeripheralsByIds(yield select(), newMeta.filters.id);
        if (resultFromCache.items.length === newMeta.filters.id.length) {
          newMeta.options.cache = {
            ...newMeta.options.cache,
            fetchedFromCache: true,
          };
        }
      }
    }

    let result: QueryOutput<Peripheral> | QueryOutput<ApiPeripheral> = newMeta.options.cache?.fetchedFromCache
      ? resultFromCache
      : yield call(
          [peripheralsService, peripheralsService.getPeripherals],
          convertToApiQueryInfo(sorting, queryInfo),
          newMeta.options.tableType ? pagination : UNLIMITED_PAGINATION,
        );

    result = isApiPeripheralQueryOutput(result) ? normalize(result) : result;
    result = newMeta.options.tableType ? result : { items: result.items };

    yield put(peripheralsActions.fetchPeripheralsSucceeded(result, newMeta));
  } catch (err) {
    const errorTitle = "Fetch peripherals";
    if (isErrorLike(err)) {
      yield put(peripheralsActions.fetchPeripheralsFailed(meta, err));
      yield put(toastShowErrorAction(err, errorTitle));
    } else {
      logger.error(`${errorTitle}: ${UnexpectedError}`);
      yield put(toastShowErrorAction(UnexpectedError, errorTitle));
    }
  }
}

function* fetchPeripheral({ payload, meta }: ReturnType<typeof peripheralsActions.fetchPeripheral>) {
  try {
    const newMeta = cloneDeep(meta);
    let result: Peripheral | ApiPeripheral = selectPeripheralById(yield select(), payload) as ApiPeripheral;
    if (result && !newMeta.options.cache?.disableCache) {
      newMeta.options.cache = {
        ...newMeta.options.cache,
        fetchedFromCache: true,
      };
    } else {
      result = yield call([peripheralsService, peripheralsService.getPeripheral], payload);
      result = normalize(result as ApiPeripheral);
    }

    yield put(peripheralsActions.fetchPeripheralSucceeded(result, newMeta));
  } catch (err) {
    const errorTitle = "Fetch peripheral";
    if (isErrorLike(err)) {
      yield put(peripheralsActions.fetchPeripheralFailed(payload, meta, err));
      yield put(toastShowErrorAction(err, errorTitle));
    } else {
      logger.error(`${errorTitle}: ${UnexpectedError}`);
      yield put(toastShowErrorAction(UnexpectedError, errorTitle));
    }
  }
}

function* fetchPeripheralsOnFetchPlayer({ payload }: ReturnType<typeof playersActions.fetchPlayerSucceeded>) {
  if (payload.applet_type) {
    yield put(peripheralsActions.fetchPeripherals({ deviceId: [payload.id] }));
  }
}

function* refetchPeripherals({ payload }: ReturnType<typeof iotEventPeripheralUpdate>) {
  const isAddedOrDeleted = isIotTableEntityAddedOrDeleted(
    yield select(),
    payload,
    MainTableDataTypeEnum.DevicePeripherals,
  );
  if (isAddedOrDeleted) {
    yield call(
      fetchPeripherals,
      peripheralsActions.fetchPeripherals(
        undefined,
        {
          tableType: MainTableDataTypeEnum.DevicePeripherals,
          cache: { disableCache: true },
        },
        DevicePeripheralsRemoteOperation.IOT_FETCH,
      ),
    );
  }

  yield put(peripheralsActions.iotUpdate(payload));
}

export function* watchPeripherals() {
  yield takeLatestOrEvery(peripheralsActions.fetchPeripherals, function* (action) {
    yield race({
      task: call(fetchPeripherals, action),
      cancel: take([peripheralsActions.selectPeripherals, peripheralsActions.toggleSelected]),
    });
  });

  yield takeLatestOrEvery(peripheralsActions.fetchPeripheral, fetchPeripheral);
  yield takeEvery(playersActions.fetchPlayerSucceeded, fetchPeripheralsOnFetchPlayer);
  yield takeLatest(iotEventPeripheralUpdate, refetchPeripherals);
}
