import { useEffect, useRef, type FC } from "react";

import { StatusEnum } from "@ds/model/status-model";

import { StatusMessage } from "@ds/components/status-message";
import { WizardFinalStepWrapper, WizardPage, type WizardChildStepProps } from "@ds/components/wizard";
import { useRootSelector } from "@ds/hooks";

import { PLAYER_WAITING_ONLINE_TIMEOUT } from "../../../constants/common";
import { selectLastPairedPlayer, selectPlayersIsLoading } from "../../../redux/selectors";
import { PlayerStateEnum } from "../../../utils/model";

export const Step3Pairing: FC<WizardChildStepProps> = ({ currentStep, nextStep, ...props }) => {
  const isSaving = useRootSelector(selectPlayersIsLoading);
  const lastPaired = useRootSelector(selectLastPairedPlayer);

  const timeout = useRef<NodeJS.Timeout>();
  useEffect(() => {
    if (!nextStep || currentStep !== 3) {
      return;
    }

    if (lastPaired) {
      if (lastPaired.state === PlayerStateEnum.Online) {
        nextStep();
      }

      if (!timeout.current) {
        timeout.current = setTimeout(nextStep, PLAYER_WAITING_ONLINE_TIMEOUT);
      }
    } else if (!isSaving) {
      nextStep();
    }
  }, [isSaving, currentStep, lastPaired, nextStep]);

  useEffect(
    () => () => {
      if (timeout.current) {
        clearTimeout(timeout.current);
      }
    },
    [],
  );

  return (
    <WizardPage {...props} title="Pairing player" isLoading>
      <WizardFinalStepWrapper>
        <StatusMessage status={StatusEnum.Executing} message={lastPaired ? "Pairing player..." : "Adding player..."} />
      </WizardFinalStepWrapper>
    </WizardPage>
  );
};
