import { useCallback } from "react";

import { useCurrentTableDataType } from "@ds/hooks/use-current-table-data-type";

import { selectIsInfoViewVisible } from "@ds/modules/table-data/redux/selectors";
import { tableDataActions } from "@ds/modules/table-data/redux/slice";

import { useRootDispatch, useRootSelector } from "./redux-hooks";

export const useToggleInfoView = () => {
  const dispatch = useRootDispatch();
  const [tableType, tableTab] = useCurrentTableDataType();
  const isInfoViewVisible = useRootSelector(state => selectIsInfoViewVisible(state, tableType, tableTab));

  return [
    !!isInfoViewVisible,
    useCallback(
      (showInfoView?: boolean) => () => {
        dispatch(tableDataActions.toggleInfoView({ tableType, tableTab, showInfoView }));
      },
      [dispatch, tableType, tableTab],
    ),
  ] as const;
};
