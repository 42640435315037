import { type DeepPartial } from "react-hook-form";

import { DSApi } from "@ds/services/ds-api";
import { type ApiService, type ApiServiceConfig } from "@ds/services/types";
import { parseQueries } from "@ds/utils/query";

interface ContentServiceConfig extends ApiServiceConfig {
  api: DSApi;
}

class PlaylistAssetsService implements ApiService {
  private config: ContentServiceConfig = {
    api: {} as DSApi,
  };

  configure(cfg: ContentServiceConfig) {
    this.config = { ...cfg };
  }

  async getPlaylistAssets(queryInfo: QueryInfo, pagination: Pagination) {
    const queries = parseQueries(queryInfo, pagination);
    return this.config.api.get<QueryOutput<ApiPlaylistAsset>>("/playlist-items/", queries);
  }

  async createPlaylistAsset(item: PlaylistAsset) {
    return this.config.api.post<ApiPlaylistAsset>("/playlist-items/", item);
  }

  async updatePlaylistAssets(ids: number[], data: DeepPartial<ApiPlaylistAsset>) {
    return this.config.api.patch<ApiPlaylistAsset>("/playlist-items/", { ids, data });
  }

  async updatePlaylistAssetOrder(ids: number[], offset: number) {
    return this.config.api.patch("/playlist-items/", { ids, data: { offset } });
  }

  async deletePlaylistAssets(ids: number[]) {
    return this.config.api.delete<ApiPlaylistAsset>("/playlist-items/", { ids });
  }
}

export const playlistAssetsService = new PlaylistAssetsService();
