import { useEffect, useMemo, type DependencyList, type EffectCallback } from "react";

import { selectCurrentUserProjectId } from "@ds/modules/settings/users/redux/selectors";

import { useRootSelector } from ".";

/**
 * Usual useEffect with added currentProjectId to dependencies, for refetching data on project switch
 */
export const useProjectEffect = (effect: EffectCallback, deps?: DependencyList) => {
  const currentUserProjectId = useRootSelector(selectCurrentUserProjectId);
  const depsWithProjectId = useMemo(() => deps?.concat([currentUserProjectId]), [deps, currentUserProjectId]);

  // eslint-disable-next-line react-hooks/exhaustive-deps
  return useEffect(effect, depsWithProjectId);
};
