export enum EntityTypeName {
  AUTH_USER = "auth_user",
  EXPERIENCE = "experience",
  DEVICE_PLAYER = "device",
  DEVICE_PERIPHERAL = "peripheral",
  DEVICE_COMMAND = "command",
  LOCATION = "location",
  CONTENT_PLAYLIST = "playlist",
  CONTENT_PLAYLIST_ASSET = "playlist_item",
  CONTENT_ASSET = "asset",
  THUMBNAIL = "thumbnail",
  DEPLOYMENT = "deployment",
  DEVICE_SYNC = "sync",
  SETTINGS_PROJECT = "project",
  SETTINGS_PROJECT_TEMPLATE = "template",
  SETTINGS_USER = "user",
  SETTINGS_INVITATION = "invitation",
}

export enum EntitySubtypeName {
  CONTENT_ASSET_VIDEO = "video",
  CONTENT_ASSET_IMAGE = "image",
  CONTENT_ASSET_AUDIO = "audio",
}

export const isEntity = (obj: unknown): obj is Entity =>
  !!obj && typeof obj === "object" && "id" in <Entity>obj && "tenant_id" in <Entity>obj && "__typename" in <Entity>obj;

export const isAggregatedEntity = <T extends Entity>(obj: unknown) =>
  isEntity(obj) && "metadata" in <Entity>obj && !!(<AggregatedEntity<T>>obj).metadata?.isAggregated;

// TODO: TYPINGS: FIX
export const isAggregatedEntityType = <T extends Entity>(obj: unknown): obj is AggregatedEntity<T> =>
  isAggregatedEntity(obj);
