import { createAction, type PayloadActionCreator } from "@reduxjs/toolkit";

import { IoTTopicPathEnum } from "../utils/model";

const sliceType = "IOT";

export const iotSubscribe = createAction(`${sliceType}/subscribe`);
export const iotSubscribeFailed = createAction(`${sliceType}/subscribeFailed`, ({ message }: ErrorLike) => ({
  payload: undefined,
  error: message,
}));

export const iotUnsubscribe = createAction(`${sliceType}/unsubscribe`);
export const iotEventUpdate = ((topic: IoTTopicPath) =>
  createAction<IoTEntity>(`${sliceType}/eventUpdate/${topic}`)) as {
  (topic: IoTTopicPathEnum.Experiences): PayloadActionCreator<ApiExperience, string>;
  (topic: IoTTopicPathEnum.DevicePlayers): PayloadActionCreator<Player, string>;
  (topic: IoTTopicPathEnum.DevicePeripherals): PayloadActionCreator<ApiPeripheral, string>;
  (topic: IoTTopicPathEnum.Commands): PayloadActionCreator<Command, string>;
  (topic: IoTTopicPathEnum.Locations): PayloadActionCreator<Locality, string>;
  (topic: IoTTopicPathEnum.Playlists): PayloadActionCreator<Playlist, string>;
  (topic: IoTTopicPathEnum.PlaylistAssets): PayloadActionCreator<ApiPlaylistAsset, string>;
  (topic: IoTTopicPathEnum.ContentAssets): PayloadActionCreator<ContentAsset, string>;
  (topic: IoTTopicPathEnum.ContentThumbnails): PayloadActionCreator<Thumbnail, string>;
  (topic: IoTTopicPathEnum.Deployments): PayloadActionCreator<Deployment, string>;
  (topic: IoTTopicPathEnum.DeviceSyncs): PayloadActionCreator<ApiDeviceSync, string>;
  (topic: IoTTopicPathEnum.Projects): PayloadActionCreator<Project, string>;
  (topic: IoTTopicPathEnum.Users): PayloadActionCreator<ApiUser, string>;
  (topic: IoTTopicPath): PayloadActionCreator<IoTEntity, string>;
};

export const iotEventExperienceUpdate = iotEventUpdate(IoTTopicPathEnum.Experiences);
export const iotEventDeviceUpdate = iotEventUpdate(IoTTopicPathEnum.DevicePlayers);
export const iotEventPeripheralUpdate = iotEventUpdate(IoTTopicPathEnum.DevicePeripherals);
export const iotEventCommandUpdate = iotEventUpdate(IoTTopicPathEnum.Commands);
export const iotEventLocationUpdate = iotEventUpdate(IoTTopicPathEnum.Locations);
export const iotEventPlaylistUpdate = iotEventUpdate(IoTTopicPathEnum.Playlists);
export const iotEventPlaylistAssetUpdate = iotEventUpdate(IoTTopicPathEnum.PlaylistAssets);
export const iotEventAssetUpdate = iotEventUpdate(IoTTopicPathEnum.ContentAssets);
export const iotEventThumbnailUpdate = iotEventUpdate(IoTTopicPathEnum.ContentThumbnails);
export const iotEventDeploymentUpdate = iotEventUpdate(IoTTopicPathEnum.Deployments);
export const iotEventDeviceSyncUpdate = iotEventUpdate(IoTTopicPathEnum.DeviceSyncs);
export const iotEventProjectUpdate = iotEventUpdate(IoTTopicPathEnum.Projects);
export const iotEventUserUpdate = iotEventUpdate(IoTTopicPathEnum.Users);
