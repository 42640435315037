import { useAsyncAbortable } from "@react-hookz/web";

import { GooglePlaceResultEnum } from "./types";

const fetchGoogleAddressDetails = async (
  placeId: string,
  element: HTMLDivElement | Element,
): Promise<google.maps.places.PlaceResult> =>
  new Promise((resolve, reject) => {
    new google.maps.places.PlacesService(element as HTMLDivElement).getDetails(
      {
        placeId,
        fields: [
          GooglePlaceResultEnum.ADDRESS_COMPONENTS,
          GooglePlaceResultEnum.FORMATTED_ADDRESS,
          GooglePlaceResultEnum.PLACE_ID,
          GooglePlaceResultEnum.GEOMETRY,
          GooglePlaceResultEnum.OPENING_HOURS,
        ],
      },
      (place, status) => {
        if (place) {
          resolve(place);
        } else {
          reject(status);
        }
      },
    );
  });

export const useFetchGoogleAddressDetails = () => {
  const [{ result: data, status, error }, actions, meta] = useAsyncAbortable(
    async (_, placeId: string, element: HTMLDivElement | Element) => fetchGoogleAddressDetails(placeId, element),
  );

  return [
    {
      data,
      status,
      error,
    },
    actions,
    meta,
  ] as const;
};
