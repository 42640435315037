import { type Path } from "history";
import { type FC } from "react";

import { TextLink } from "@ds/components/text-link";

import { ShadedLabel } from "./styles";

export const UnassignedLabel: FC<{ text?: string; shouldInheritColor?: boolean }> = ({
  text = "Unassigned",
  shouldInheritColor = false,
}) => <ShadedLabel $shouldInheritColor={shouldInheritColor}>{text}</ShadedLabel>;

export const MultipleValuesLabel: FC<{ shouldInheritColor?: boolean }> = ({ shouldInheritColor = false }) => (
  <ShadedLabel $shouldInheritColor={shouldInheritColor}>Multiple values</ShadedLabel>
);

export const MultipleValuesLabelWithLink: FC<{ to: string | Partial<Path> }> = ({ to }) => (
  <TextLink to={to}>
    <MultipleValuesLabel shouldInheritColor />
  </TextLink>
);
