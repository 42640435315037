import { EntityTypeName } from "@ds/model/entity-model";

import { DEFAULT_SORT_FIELD, DEFAULT_SORT_ORDER } from "@ds/modules/table-data/redux/slice";
import { DetailsTableDataTypeEnum, MainTableDataTypeEnum } from "@ds/modules/table-data/utils/model";

import { REACT_APP_PAGE_SIZE } from "@ds/constants/environment";

export const UNLIMITED_PAGINATION = { pageNumber: 1, pageSize: 99999 };

export const parseQueries = (query: QueryInfo, pagination: Partial<Pagination> = {}) => {
  const { filters = {}, pattern = "", sortField = DEFAULT_SORT_FIELD, sortOrder = DEFAULT_SORT_ORDER } = query;
  const { pageNumber = 1, pageSize = REACT_APP_PAGE_SIZE } = pagination;

  const result = Object.entries(filters).reduce<Record<string, string>>((acc, [key, value]) => {
    if (value !== undefined) {
      acc[`filters[${key}]`] = value.toString();
    }

    return acc;
  }, {});

  if (pattern) {
    result.pattern = pattern;
  }

  if (sortField) {
    result.sort_field = sortField.split(".").pop() || "";
  }

  if (sortOrder) {
    result.sort_order = sortOrder;
  }

  if (pageSize !== UNLIMITED_PAGINATION.pageSize) {
    result.page = pageNumber.toString();
    result.page_size = pageSize.toString();
  }

  return result;
};

export const getQueryFilters = ((
  filters: QueryFilters | undefined,
  _: MainTableDataTypeEnum | DetailsTableDataTypeEnum,
) => filters) as {
  (filters: QueryFilters | undefined, _: MainTableDataTypeEnum.Experiences): ExperienceFilters;
  (filters: QueryFilters | undefined, _: MainTableDataTypeEnum.DevicePlayers): DevicePlayerFilters;
  (filters: QueryFilters | undefined, _: MainTableDataTypeEnum.DevicePeripherals): DevicePeripheralFilters;
  (filters: QueryFilters | undefined, _: MainTableDataTypeEnum.Locations): LocalityFilters;
  (filters: QueryFilters | undefined, _: DetailsTableDataTypeEnum.LocationExperiences): ExperienceFilters;
  (filters: QueryFilters | undefined, _: DetailsTableDataTypeEnum.LocationPlayers): DevicePlayerFilters;
  (filters: QueryFilters | undefined, _: MainTableDataTypeEnum.ContentPlaylists): ContentPlaylistFilters;
  (filters: QueryFilters | undefined, _: DetailsTableDataTypeEnum.ContentPlaylistAssets): ContentPlaylistAssetFilters;
  (filters: QueryFilters | undefined, _: MainTableDataTypeEnum.ContentVideos): ContentAssetFilters;
  (filters: QueryFilters | undefined, _: MainTableDataTypeEnum.ContentImages): ContentAssetFilters;
  (filters: QueryFilters | undefined, _: MainTableDataTypeEnum.ContentAudio): ContentAssetFilters;
  (
    filters: QueryFilters | undefined,
    _: MainTableDataTypeEnum.ContentVideos | MainTableDataTypeEnum.ContentImages | MainTableDataTypeEnum.ContentAudio,
  ): ContentAssetFilters;
  (filters: QueryFilters | undefined, _: MainTableDataTypeEnum.Deployments): DeploymentFilters;
  (filters: QueryFilters | undefined, _: DetailsTableDataTypeEnum.DeploymentDeviceSyncs): DeviceSyncFilters;
  (filters: QueryFilters | undefined, _: MainTableDataTypeEnum.Projects): SettingsProjectFilters;
  (filters: QueryFilters | undefined, _: MainTableDataTypeEnum.Users): SettingsUserFilters;
  (filters: QueryFilters | undefined, _: MainTableDataTypeEnum.Invitations): SettingsInvitationFilters;
};

export const getQueryCamelCaseFilters = ((filters: KeysToCamelCase<QueryFilters> | undefined, _: EntityTypeName) =>
  filters) as {
  (
    filters: KeysToCamelCase<QueryFilters> | undefined,
    _: EntityTypeName.EXPERIENCE,
  ): KeysToCamelCase<ExperienceFilters>;
  (
    filters: KeysToCamelCase<QueryFilters> | undefined,
    _: EntityTypeName.DEVICE_PLAYER,
  ): KeysToCamelCase<DevicePlayerFilters>;
  (
    filters: KeysToCamelCase<QueryFilters> | undefined,
    _: EntityTypeName.DEVICE_PERIPHERAL,
  ): KeysToCamelCase<DevicePeripheralFilters>;
  (filters: KeysToCamelCase<QueryFilters> | undefined, _: EntityTypeName.LOCATION): KeysToCamelCase<LocalityFilters>;
  (
    filters: KeysToCamelCase<QueryFilters> | undefined,
    _: EntityTypeName.CONTENT_PLAYLIST,
  ): KeysToCamelCase<ContentPlaylistFilters>;
  (
    filters: KeysToCamelCase<QueryFilters> | undefined,
    _: EntityTypeName.CONTENT_PLAYLIST_ASSET,
  ): KeysToCamelCase<ContentPlaylistAssetFilters>;
  (
    filters: KeysToCamelCase<QueryFilters> | undefined,
    _: EntityTypeName.CONTENT_ASSET,
  ): KeysToCamelCase<ContentAssetFilters>;
  (
    filters: KeysToCamelCase<QueryFilters> | undefined,
    _: EntityTypeName.DEPLOYMENT,
  ): KeysToCamelCase<DeploymentFilters>;
  (
    filters: KeysToCamelCase<QueryFilters> | undefined,
    _: DetailsTableDataTypeEnum.DeploymentDeviceSyncs,
  ): KeysToCamelCase<DeviceSyncFilters>;
  (
    filters: KeysToCamelCase<QueryFilters> | undefined,
    _: EntityTypeName.SETTINGS_PROJECT,
  ): KeysToCamelCase<SettingsProjectFilters>;
  (
    filters: KeysToCamelCase<QueryFilters> | undefined,
    _: EntityTypeName.SETTINGS_USER,
  ): KeysToCamelCase<SettingsUserFilters>;
  (
    filters: KeysToCamelCase<QueryFilters> | undefined,
    _: EntityTypeName.SETTINGS_INVITATION,
  ): KeysToCamelCase<SettingsInvitationFilters>;
};
