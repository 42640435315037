import { createSelector } from "@reduxjs/toolkit";

import { DetailsTableDataTypeEnum, MainTableDataTypeEnum } from "@ds/modules/table-data/utils/model";

import { type RootState } from "@ds/store/bootstrap";
import { selectorsExtractIds, selectorsOutputEntitiesByIds } from "@ds/store/global-selectors";

import { getAggregatedModel } from "@ds/utils/entities";
import { LoadStatus } from "@ds/utils/reducer";

import { entitySelectors, InvitationsRemoteOperation } from "./slice";

const selectBaseState = (state: RootState) => state.settings.invitations;
const selectLoadStatus = (state: RootState, operation = InvitationsRemoteOperation.CRUD) =>
  selectBaseState(state).loadStatusMap[operation];

export const selectInvitationsIsLoading = (state: RootState, operation = InvitationsRemoteOperation.CRUD) =>
  selectLoadStatus(state, operation) === LoadStatus.Loading;

export const selectInvitationsError = (state: RootState) => selectBaseState(state).error;

const selectTableInvitationIds = (
  state: RootState,
  tableType: MainTableDataTypeEnum | DetailsTableDataTypeEnum = MainTableDataTypeEnum.Invitations,
) => selectBaseState(state).tableIds[tableType];

export const selectSelectedInvitationIds = (
  state: RootState,
  tableType: MainTableDataTypeEnum | DetailsTableDataTypeEnum = MainTableDataTypeEnum.Invitations,
) => selectBaseState(state).selectedIds[tableType];

export const selectInvitationById = (state: RootState, id: string) =>
  entitySelectors.selectById(selectBaseState(state), id) as Invitation;

export const selectInvitationIds = (state: RootState) => entitySelectors.selectIds(selectBaseState(state));
export const selectInvitations = (state: RootState) => entitySelectors.selectAll(selectBaseState(state));

export const selectInvitationsByIds = createSelector(
  selectBaseState,
  selectorsExtractIds,
  selectorsOutputEntitiesByIds(() => entitySelectors),
);

export const selectTableInvitations = createSelector(
  selectBaseState,
  selectTableInvitationIds,
  selectorsOutputEntitiesByIds(() => entitySelectors),
);

export const selectSelectedInvitations = createSelector(
  selectBaseState,
  selectSelectedInvitationIds,
  selectorsOutputEntitiesByIds(() => entitySelectors),
);

export const selectAggregatedInvitationByIds = createSelector(selectBaseState, selectorsExtractIds, (state, ids) =>
  ids?.length ? getAggregatedModel(selectorsOutputEntitiesByIds(entitySelectors)(state, ids)) : null,
);

export const selectAggregatedInvitationForSelectedEntities = createSelector(
  selectSelectedInvitations,
  selectedInvitations => getAggregatedModel(selectedInvitations),
);
