import { UnassignedLabel } from "@ds/components/forms/labels/common";
import { ShadedLabel } from "@ds/components/forms/labels/styles";
import { StatusIndicator } from "@ds/components/status-indicator";
import type { ColumnBodyOptions } from "@ds/components/table/table-columns";
import { TextLink } from "@ds/components/text-link";
import { composeDetailsRouteByEntityType } from "@ds/utils/router";

import { getPeripheralPrintableStatus } from "../../utils/model";

export const getPeripheralStatusIndicator = (
  peripheral: Peripheral,
  options?: ColumnBodyOptions & { key?: string },
) => (
  <StatusIndicator
    key={options?.key}
    status={getPeripheralPrintableStatus(peripheral.state)}
    title={peripheral.state_message}
  >
    {peripheral.state}
  </StatusIndicator>
);

export const getPeripheralStatusIndicatorWithNameAndLink = (peripheral: Peripheral, options?: { key?: string }) => (
  <StatusIndicator
    key={options?.key}
    status={getPeripheralPrintableStatus(peripheral.state)}
    title={peripheral.state_message}
  >
    <TextLink to={composeDetailsRouteByEntityType(peripheral)}>{peripheral.name || <UnassignedLabel />}</TextLink>
    <ShadedLabel className="ml-2">{`[ ${peripheral.state} ]`}</ShadedLabel>
  </StatusIndicator>
);
