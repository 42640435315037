import { type FC } from "react";

import { isAggregatedEntity } from "@ds/model/entity-model";

import { InfoSection } from "@ds/components/info-view";

import { ProjectFormationInfoSection } from "./project-formation-info-section";

export const ProjectInfoSection: FC<{
  project: Project;
  templateProperties: [string, TemplateFormationProperty][];
}> = ({ project, templateProperties }) => {
  if (isAggregatedEntity(project)) {
    return null;
  }

  return (
    <InfoSection title="Project Info" icon="pi pi-info-circle">
      <ProjectFormationInfoSection project={project} templateProperties={templateProperties} />
    </InfoSection>
  );
};
