import { type History, type Transition } from "history";
import { useContext, useEffect, useRef, useState } from "react";
import { UNSAFE_NavigationContext as NavigationContext, type Navigator } from "react-router-dom";

import { confirmSuccess } from "@ds/components/popups/confirmation-dialog";

type ExtendNavigator = Navigator & Pick<History, "block">;

const useBlocker = (blocker: (tx: Transition) => void, when = true) => {
  const { navigator } = useContext(NavigationContext);
  useEffect(() => {
    if (!when) {
      return;
    }

    const unblock = (navigator as ExtendNavigator).block(tx => {
      const autoUnblockingTx = {
        ...tx,
        retry() {
          unblock();
          tx.retry();
        },
      };

      blocker(autoUnblockingTx);
    });

    return unblock;
  }, [navigator, blocker, when]);
};

export const useNavigationPrompt = (
  modalOptions: { header?: string; message?: string } | undefined,
  shouldPrompt: boolean | (() => boolean),
) => {
  const [confirmedNavigation, setConfirmedNavigation] = useState(false);
  const retryFn = useRef(() => {});

  useEffect(() => {
    if (confirmedNavigation) {
      retryFn.current();
    }
  }, [confirmedNavigation]);

  const handleBlockNavigation = ({ retry }: Transition) => {
    const shouldDisplayPrompt = typeof shouldPrompt === "boolean" ? shouldPrompt : shouldPrompt();
    if (shouldDisplayPrompt) {
      confirmSuccess({
        ...modalOptions,
        accept: () => {
          setConfirmedNavigation(true);
          retryFn.current = retry;
        },
      });
    } else {
      retry();
    }
  };

  useBlocker(handleBlockNavigation, !confirmedNavigation);
};
