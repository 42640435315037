import { Menu as PrimeMenu } from "primereact/menu";
import styled from "styled-components";

import { Button } from "../forms/buttons/buttons";

export const DropdownMenu = styled(PrimeMenu)`
  width: 14em;

  .p-menuitem-link:focus {
    box-shadow: var(--button-light-box-shadow);
  }

  .p-menuitem-link:not(.p-disabled):hover {
    background: var(--button-light-background);
  }

  .p-submenu-list {
    width: max-content;
  }

  .p-menuitem-text {
    text-transform: capitalize;
  }
`;

export const DropdownButton = styled(Button)`
  border-radius: 10px;
`;
