import { type RootState } from "@ds/store/bootstrap";

import { LoadStatus } from "@ds/utils/reducer";

import { AuthRemoteOperation } from "./slice";
const selectBaseState = (state: RootState) => state.auth;

const selectLoadStatus = (state: RootState, operation = AuthRemoteOperation.AUTH) =>
  selectBaseState(state).loadStatusMap[operation];

export const selectAuthIsIdle = (state: RootState, operation = AuthRemoteOperation.AUTH) =>
  selectLoadStatus(state, operation) === LoadStatus.Idle;

export const selectAuthIsLoading = (state: RootState, operation = AuthRemoteOperation.AUTH) =>
  selectLoadStatus(state, operation) === LoadStatus.Loading;

export const selectAuthIsSucceeded = (state: RootState, operation = AuthRemoteOperation.AUTH) =>
  selectLoadStatus(state, operation) === LoadStatus.Succeeded;

export const selectAuthError = (state: RootState) => selectBaseState(state).error;

export const selectInvitedUser = (state: RootState) => selectBaseState(state).invitedUser;
export const selectCognitoUser = (state: RootState) => selectBaseState(state).cognitoUser;

export const selectCognitoUserIsSignedIn = (state: RootState) => {
  const cognitoUser = selectCognitoUser(state);
  return !!(cognitoUser?.id && cognitoUser?.tenant_id);
};
