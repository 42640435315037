import {
  EXPERIENCE_ID_PARAM,
  PLAYER_ID_PARAM,
  PERIPHERAL_ID_PARAM,
  LOCATION_ID_PARAM,
  PLAYLIST_ID_PARAM,
  DEPLOYMENT_ID_PARAM,
  PROJECT_ID_PARAM,
  USER_ID_PARAM,
  INVITATION_ID_PARAM,
} from "./entity-params";
import {
  EXPERIENCES_SEGMENT,
  DEVICES_SEGMENT,
  DEVICES_PLAYERS_SEGMENT,
  DEVICES_PERIPHERALS_SEGMENT,
  LOCATIONS_SEGMENT,
  CONTENT_SEGMENT,
  CONTENT_PLAYLISTS_SEGMENT,
  CONTENT_VIDEOS_SEGMENT,
  CONTENT_IMAGES_SEGMENT,
  CONTENT_AUDIO_SEGMENT,
  DEPLOYMENTS_SEGMENT,
  SETTINGS_SEGMENT,
  SETTINGS_PROJECTS_SEGMENT,
  SETTINGS_USERS_SEGMENT,
  SETTINGS_INVITATIONS_SEGMENT,
} from "./entity-segments";

export const EXPERIENCES_ROUTE = `/${EXPERIENCES_SEGMENT}`;
export const EXPERIENCE_DETAILS_ROUTE = `/${EXPERIENCES_SEGMENT}/:${EXPERIENCE_ID_PARAM}`;

export const DEVICES_ROUTE = `/${DEVICES_SEGMENT}`;
export const DEVICE_PLAYERS_ROUTE = `/${DEVICES_SEGMENT}/${DEVICES_PLAYERS_SEGMENT}`;
export const DEVICE_PLAYER_DETAILS_ROUTE = `/${DEVICES_SEGMENT}/${DEVICES_PLAYERS_SEGMENT}/:${PLAYER_ID_PARAM}`;
export const DEVICE_PERIPHERALS_ROUTE = `/${DEVICES_SEGMENT}/${DEVICES_PERIPHERALS_SEGMENT}`;
export const DEVICE_PERIPHERAL_DETAILS_ROUTE = `/${DEVICES_SEGMENT}/${DEVICES_PERIPHERALS_SEGMENT}/:${PERIPHERAL_ID_PARAM}`;

export const LOCATIONS_ROUTE = `/${LOCATIONS_SEGMENT}`;
export const LOCATION_DETAILS_ROUTE = `/${LOCATIONS_SEGMENT}/:${LOCATION_ID_PARAM}`;

export const CONTENT_ROUTE = `/${CONTENT_SEGMENT}`;
export const CONTENT_PLAYLISTS_ROUTE = `/${CONTENT_SEGMENT}/${CONTENT_PLAYLISTS_SEGMENT}`;
export const CONTENT_PLAYLIST_DETAILS_ROUTE = `/${CONTENT_SEGMENT}/${CONTENT_PLAYLISTS_SEGMENT}/:${PLAYLIST_ID_PARAM}`;
export const CONTENT_VIDEOS_ROUTE = `/${CONTENT_SEGMENT}/${CONTENT_VIDEOS_SEGMENT}`;
export const CONTENT_IMAGES_ROUTE = `/${CONTENT_SEGMENT}/${CONTENT_IMAGES_SEGMENT}`;
export const CONTENT_AUDIO_ROUTE = `/${CONTENT_SEGMENT}/${CONTENT_AUDIO_SEGMENT}`;

export const DEPLOYMENTS_ROUTE = `/${DEPLOYMENTS_SEGMENT}`;
export const DEPLOYMENT_DETAILS_ROUTE = `/${DEPLOYMENTS_SEGMENT}/:${DEPLOYMENT_ID_PARAM}`;

export const SETTINGS_ROUTE = `/${SETTINGS_SEGMENT}`;
export const SETTINGS_PROJECTS_ROUTE = `/${SETTINGS_SEGMENT}/${SETTINGS_PROJECTS_SEGMENT}`;
export const SETTINGS_PROJECT_DETAILS_ROUTE = `/${SETTINGS_SEGMENT}/${SETTINGS_PROJECTS_SEGMENT}/:${PROJECT_ID_PARAM}`;
export const SETTINGS_USERS_ROUTE = `/${SETTINGS_SEGMENT}/${SETTINGS_USERS_SEGMENT}`;
export const SETTINGS_USER_DETAILS_ROUTE = `/${SETTINGS_SEGMENT}/${SETTINGS_USERS_SEGMENT}/:${USER_ID_PARAM}`;
export const SETTINGS_INVITATIONS_ROUTE = `/${SETTINGS_SEGMENT}/${SETTINGS_INVITATIONS_SEGMENT}`;
export const SETTINGS_INVITATION_DETAILS_ROUTE = `/${SETTINGS_SEGMENT}/${SETTINGS_INVITATIONS_SEGMENT}/:${INVITATION_ID_PARAM}`;
