import { type FC } from "react";

import { Wizard } from "@ds/components/wizard";

import { StepFinal } from "./step-final";
import { Step1Calendar } from "./step1-calendar-view";

export const ScheduleDeploymentsModal: FC<{
  deployments: Deployment[];
  onClose: () => void;
}> = ({ deployments, onClose }) => (
  <Wizard width="40vw" height="80vh" onClose={onClose}>
    <Step1Calendar deployments={deployments} />
    <StepFinal />
  </Wizard>
);
