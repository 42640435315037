import { createEntityAdapter, createSlice, type PayloadAction } from "@reduxjs/toolkit";
import { type DeepPartial } from "react-hook-form";
import { all } from "redux-saga/effects";

import { DetailsTableDataTypeEnum, MainTableDataTypeEnum } from "@ds/modules/table-data/utils/model";

import { getIds } from "@ds/utils/entities";
import { LoadStatus, type BaseMeta, type PatchedModel } from "@ds/utils/reducer";
import { type SagaOptions } from "@ds/utils/saga-helpers";

import { settingsActions } from "../../redux/slice";
import { apiToModelNormalize, normalize } from "../utils/normalizer";
import { watchUsers } from "./sagas";

export enum UsersRemoteOperation {
  AUTH = "auth",
  CRUD = "crud",
  CHECKBOXES = "checkboxes",
  IOT_FETCH = "iot_fetch",
}

export type UsersMeta = BaseMeta & {
  remoteOperation: UsersRemoteOperation;
  filters?: KeysToCamelCase<SettingsUserFilters>;
};

export interface UsersState {
  loadStatusMap: {
    [UsersRemoteOperation.AUTH]: LoadStatus;
    [UsersRemoteOperation.CRUD]: LoadStatus;
    [UsersRemoteOperation.CHECKBOXES]: LoadStatus;
    [UsersRemoteOperation.IOT_FETCH]: LoadStatus;
  };

  error: string | null;

  entities: {
    [userId: number]: User;
  };

  ids: number[];
  tableIds: Record<string, number[]>;
  selectedIds: Record<string, number[]>;

  currentUserId: number | null;
}

const entityAdapter = createEntityAdapter<User>();

export const initialState: UsersState = entityAdapter.getInitialState({
  loadStatusMap: {
    [UsersRemoteOperation.AUTH]: LoadStatus.Idle,
    [UsersRemoteOperation.CRUD]: LoadStatus.Idle,
    [UsersRemoteOperation.CHECKBOXES]: LoadStatus.Idle,
    [UsersRemoteOperation.IOT_FETCH]: LoadStatus.Idle,
  },

  error: null,

  tableIds: { [MainTableDataTypeEnum.Users]: [] },
  selectedIds: { [MainTableDataTypeEnum.Users]: [] },

  currentUserId: null,
}) as UsersState;

const getTableTypeOrDefault = (
  tableType: MainTableDataTypeEnum | DetailsTableDataTypeEnum = MainTableDataTypeEnum.Users,
) => tableType;

const removeEntities = (state: UsersState, payload: number[]) => {
  entityAdapter.removeMany(state, payload);

  state.tableIds[MainTableDataTypeEnum.Users] = state.tableIds[MainTableDataTypeEnum.Users].filter(
    id => !payload.includes(id),
  );

  state.selectedIds[MainTableDataTypeEnum.Users] = state.selectedIds[MainTableDataTypeEnum.Users].filter(
    id => !payload.includes(id),
  );
};

const sliceType = "USERS";
const slice = createSlice({
  name: sliceType,
  initialState,
  reducers: {
    fetchUsers: {
      reducer(state, { meta }: PayloadAction<undefined, string, UsersMeta>) {
        state.loadStatusMap[meta.remoteOperation] = LoadStatus.Loading;
        state.error = null;

        if (meta.remoteOperation === UsersRemoteOperation.CHECKBOXES) {
          state.selectedIds[getTableTypeOrDefault(meta.options?.tableType)] =
            state.tableIds[getTableTypeOrDefault(meta.options?.tableType)];
        }
      },
      prepare(
        filters: KeysToCamelCase<SettingsUserFilters> = {},
        options: SagaOptions = {},
        remoteOperation: UsersRemoteOperation = UsersRemoteOperation.CRUD,
      ) {
        return { payload: undefined, meta: { remoteOperation, filters, options } };
      },
    },
    fetchUsersFailed: {
      reducer(state, { meta, error }: PayloadAction<undefined, string, UsersMeta, string>) {
        state.loadStatusMap[meta.remoteOperation] = LoadStatus.Failed;
        state.error = error;

        if (meta.remoteOperation === UsersRemoteOperation.CHECKBOXES) {
          state.selectedIds[getTableTypeOrDefault(meta.options?.tableType)] = [];
        }
      },
      prepare(meta: UsersMeta, { message }: ErrorLike) {
        return { payload: undefined, meta, error: message };
      },
    },
    fetchUsersSucceeded: {
      reducer(state, { payload, meta }: PayloadAction<QueryOutput<User> | QueryOutput<ApiUser>, string, UsersMeta>) {
        state.loadStatusMap[meta.remoteOperation] = LoadStatus.Succeeded;
        state.error = null;

        entityAdapter.setMany(state, apiToModelNormalize(payload.items));

        if (meta.remoteOperation === UsersRemoteOperation.CHECKBOXES) {
          state.selectedIds[getTableTypeOrDefault(meta.options?.tableType)] = payload.items.map(({ id }) => id);
        } else if (meta.options?.tableType) {
          state.tableIds[getTableTypeOrDefault(meta.options?.tableType)] = payload.items.map(({ id }) => id);
          state.selectedIds[getTableTypeOrDefault(meta.options?.tableType)] = state.selectedIds[
            getTableTypeOrDefault(meta.options?.tableType)
          ].filter(id => state.ids.includes(id));
        }
      },
      prepare(payload: QueryOutput<User> | QueryOutput<ApiUser>, meta: UsersMeta) {
        return { payload, meta };
      },
    },
    fetchUser: {
      reducer(state, { meta }: PayloadAction<number, string, UsersMeta>) {
        state.loadStatusMap[meta.remoteOperation] = LoadStatus.Loading;
        state.error = null;
      },
      prepare(
        payload: number,
        options: SagaOptions = {},
        remoteOperation: UsersRemoteOperation = UsersRemoteOperation.CRUD,
      ) {
        return { payload, meta: { remoteOperation, options } };
      },
    },
    fetchUserFailed: {
      reducer(state, { meta, error }: PayloadAction<number, string, UsersMeta, string>) {
        state.loadStatusMap[meta.remoteOperation] = LoadStatus.Failed;
        state.error = error;
      },
      prepare(payload: number, meta: UsersMeta, { message }: ErrorLike) {
        return { payload, meta, error: message };
      },
    },
    fetchUserSucceeded: {
      reducer(state, { payload, meta }: PayloadAction<User | ApiUser, string, UsersMeta>) {
        state.loadStatusMap[meta.remoteOperation] = LoadStatus.Succeeded;
        state.error = null;

        entityAdapter.setOne(state, apiToModelNormalize(payload));

        if (meta.remoteOperation === UsersRemoteOperation.AUTH) {
          state.currentUserId = payload.id;
        }
      },
      prepare(payload: User | ApiUser, meta: UsersMeta) {
        return { payload, meta };
      },
    },
    updateUsers: {
      reducer(state, _action: PayloadAction<PatchedModel<User>>) {
        state.loadStatusMap[UsersRemoteOperation.CRUD] = LoadStatus.Loading;
        state.error = null;
      },
      prepare(changedIds: number[], changedFields: DeepPartial<User>) {
        return { payload: { ids: changedIds, data: changedFields } };
      },
    },
    updateUsersFailed: {
      reducer(state, { error }: PayloadAction<PatchedModel<User>, string, never, string>) {
        state.loadStatusMap[UsersRemoteOperation.CRUD] = LoadStatus.Failed;
        state.error = error;
      },
      prepare(payload: PatchedModel<User>, { message }: ErrorLike) {
        return { payload, error: message };
      },
    },
    updateUsersSucceeded: (state, { payload }: PayloadAction<ApiUser[]>) => {
      state.loadStatusMap[UsersRemoteOperation.CRUD] = LoadStatus.Succeeded;
      state.error = null;

      entityAdapter.setMany(state, apiToModelNormalize(payload));
    },
    deleteUsers: {
      reducer(state, { meta }: PayloadAction<number[], string, UsersMeta>) {
        state.loadStatusMap[meta.remoteOperation] = LoadStatus.Loading;
        state.error = null;
      },
      prepare(usersOrIds: User[] | number[], redirectTo?: string) {
        return {
          payload: getIds(usersOrIds),
          meta: { remoteOperation: UsersRemoteOperation.CRUD, options: { redirectTo } },
        };
      },
    },
    deleteUsersFailed: {
      reducer(state, { meta, error }: PayloadAction<number[], string, UsersMeta, string>) {
        state.loadStatusMap[meta.remoteOperation] = LoadStatus.Failed;
        state.error = error;
      },
      prepare(idsToDelete: number[], meta: UsersMeta, { message }: ErrorLike) {
        return { payload: idsToDelete, meta, error: message };
      },
    },
    deleteUsersSucceeded: {
      reducer(state, { payload, meta }: PayloadAction<number[], string, UsersMeta>) {
        state.loadStatusMap[meta.remoteOperation] = LoadStatus.Succeeded;
        state.error = null;

        removeEntities(state, payload);
      },
      prepare(payload: number[], meta: UsersMeta) {
        return { payload, meta };
      },
    },
    selectUsers: {
      reducer(state, { payload, meta }: PayloadAction<number[], string, UsersMeta>) {
        state.loadStatusMap[meta.remoteOperation] = LoadStatus.Succeeded;

        state.selectedIds[getTableTypeOrDefault(meta.options?.tableType)] = payload;
      },
      prepare(usersOrIds: User[] | ApiUser[] | number[], options: SagaOptions = {}) {
        return {
          payload: getIds(usersOrIds),
          meta: { remoteOperation: UsersRemoteOperation.CHECKBOXES, options },
        };
      },
    },
    toggleSelected: {
      reducer(state, { payload, meta }: PayloadAction<number, string, UsersMeta>) {
        state.loadStatusMap[meta.remoteOperation] = LoadStatus.Succeeded;

        state.selectedIds[getTableTypeOrDefault(meta.options?.tableType)] = state.selectedIds[
          getTableTypeOrDefault(meta.options?.tableType)
        ].includes(payload)
          ? state.selectedIds[getTableTypeOrDefault(meta.options?.tableType)].filter(id => id !== payload)
          : [...state.selectedIds[getTableTypeOrDefault(meta.options?.tableType)], payload];
      },
      prepare(userId: number, options: SagaOptions = {}) {
        return {
          payload: userId,
          meta: { remoteOperation: UsersRemoteOperation.CHECKBOXES, options },
        };
      },
    },
    updateUserCurrentProjectId: (state, _action: PayloadAction<number>) => {
      state.loadStatusMap[UsersRemoteOperation.CRUD] = LoadStatus.Loading;
      state.error = null;
    },
    updateUserCurrentProjectIdFailed: {
      reducer(state, { error }: PayloadAction<number, string, never, string>) {
        state.loadStatusMap[UsersRemoteOperation.CRUD] = LoadStatus.Failed;
        state.error = error;
      },
      prepare(payload: number, { message }: ErrorLike) {
        return { payload, error: message };
      },
    },
    updateUserCurrentProjectIdSucceeded: (state, { payload }: PayloadAction<ApiUser>) => {
      state.loadStatusMap[UsersRemoteOperation.CRUD] = LoadStatus.Succeeded;
      state.error = null;

      entityAdapter.setOne(state, apiToModelNormalize(payload));
    },
    iotUpdate: (state, { payload }: PayloadAction<ApiUser>) => {
      if (payload.audit?.deleted_at) {
        removeEntities(state, [payload.id]);
      } else {
        entityAdapter.setOne(state, apiToModelNormalize(normalize(payload)));
      }
    },
  },
  extraReducers: builder => {
    builder.addCase(settingsActions.switchView, state => {
      state.loadStatusMap[UsersRemoteOperation.CRUD] = LoadStatus.Idle;
      state.error = null;
    });
  },
});

export const { name: usersType, actions: usersActions, reducer: usersReducer } = slice;

export const entitySelectors = entityAdapter.getSelectors();

export function* rootUsersSaga() {
  yield all([watchUsers()]);
}
