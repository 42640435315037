import { createSelector } from "@reduxjs/toolkit";
import { chain, groupBy, isNumber, orderBy } from "lodash";

import { type RootState } from "@ds/store/bootstrap";

import { isNumberArray } from "@ds/utils/type-guards/common-guards";

import { selectProjectById } from "./common-selectors";

const extractDataSource = (_: RootState, _projectId: number | undefined, dataSource: "wizard" | "details") =>
  dataSource;

const extractPropertiesTitles = (
  _: RootState,
  _projectId: number | undefined,
  _dataSource: "wizard" | "details",
  propertiesTitles: string[],
) => propertiesTitles;

const extractGroupsOrPropertiesEntries = (
  _: RootState,
  _projectId: number | undefined,
  _dataSource: "wizard" | "details",
  groupsOrPropertiesEntries?: number[] | [string, TemplateFormationProperty][],
) => groupsOrPropertiesEntries;

export const selectTemplateExperienceFormation = (state: RootState, projectId: number | undefined) =>
  selectProjectById(state, projectId || 0)?.template?.experience_formation;

export const selectTemplateExperienceTableData = createSelector(selectTemplateExperienceFormation, formation => {
  if (!formation) {
    return [];
  }

  const filteredPropertiesEntries = Object.entries(formation.properties).filter(
    ([, v]) => !!v.metadata?.table?.group_order,
  );

  return orderBy(filteredPropertiesEntries, [([, property]) => property.metadata?.table?.group_order]).map(
    ([k, v]) => ({
      field: k,
      title: v.title,
      columnWidth: v.metadata?.table?.column_width,
    }),
  );
});

export const selectTemplateExperienceFormationEntriesPerGroup = createSelector(
  selectTemplateExperienceFormation,
  extractDataSource,
  (formation, dataSource): FormationEntriesPerGroup => {
    const formationEntriesPerGroup = formation
      ? groupBy(
          Object.entries(formation.properties).filter(([, v]) => !!v.metadata?.[dataSource]?.group_order),
          ([, v]) => v.metadata?.[dataSource]?.group_order,
        )
      : [];

    return Object.fromEntries(
      Object.entries(formationEntriesPerGroup).map(([k, v]) => [
        k,
        orderBy(v, [([, property]) => property.metadata?.[dataSource]?.field_order]),
      ]),
    );
  },
);

export const selectTemplateExperienceFormationEntriesFilteredPerGroup = createSelector(
  selectTemplateExperienceFormationEntriesPerGroup,
  extractDataSource,
  extractPropertiesTitles,
  (
    formationEntriesPerGroup,
    dataSource,
    propertiesTitles,
  ): readonly [FormationEntriesPerGroup, FormationEntriesPerGroup] =>
    [
      Object.fromEntries(
        Object.entries(formationEntriesPerGroup).filter(([, properties]) => {
          const groupTitle = properties[0][1].metadata?.[dataSource]?.group_title;
          return !groupTitle || propertiesTitles.includes(groupTitle);
        }),
      ),
      Object.fromEntries(
        Object.entries(formationEntriesPerGroup).filter(([, properties]) => {
          const groupTitle = properties[0][1].metadata?.[dataSource]?.group_title;
          return groupTitle && !propertiesTitles.includes(groupTitle);
        }),
      ),
    ] as const,
);

export const selectTemplateExperienceFormationValidationData = createSelector(
  selectTemplateExperienceFormation,
  extractDataSource,
  extractGroupsOrPropertiesEntries,
  (
    formation,
    dataSource: "wizard" | "details",
    groupsOrPropertiesEntries?: number[] | [string, TemplateFormationProperty][],
  ): FormationProperyValidationRulesPerProperty => {
    const groups = isNumberArray(groupsOrPropertiesEntries)
      ? groupsOrPropertiesEntries
      : groupsOrPropertiesEntries?.map(([_, v]) => v.metadata?.[dataSource]?.group_order);

    const normalizedGroups = chain(groups).filter(isNumber).uniq().value();
    const validationData = formation
      ? Object.entries(formation.properties)
          .filter(
            ([, v]) =>
              !normalizedGroups?.length ||
              (v.metadata?.[dataSource]?.group_order &&
                normalizedGroups.includes(v.metadata?.[dataSource]?.group_order || 0)),
          )
          .map(([k, v]) => ({
            field: k,
            validationRules: v.metadata?.validation_rules || [],
          }))
      : [];

    return validationData;
  },
);
