import { type FC } from "react";

import { selectAuthError, selectAuthIsLoading } from "@ds/modules/auth/redux/selectors";

import { WizardFinalStepWrapper, WizardPage, type WizardChildStepProps } from "@ds/components/wizard";
import { getStatusMessage } from "@ds/components/wizard/helpers";
import { useRootSelector } from "@ds/hooks";

export const StepFinal: FC<WizardChildStepProps> = ({ firstStep, ...rest }) => {
  const isSaving = useRootSelector(selectAuthIsLoading);
  const errorMessage = useRootSelector(selectAuthError);

  return (
    <WizardPage {...rest} isValid={!errorMessage} isLoading={isSaving} previousStep={firstStep}>
      <WizardFinalStepWrapper>{getStatusMessage({ isLoading: isSaving, errorMessage })}</WizardFinalStepWrapper>
    </WizardPage>
  );
};
