import { type FC } from "react";
import { Controller, type Control } from "react-hook-form";

import { LocationsAutocompleteRaw } from "@ds/modules/locations/components/locations-autocomplete";

import { useDetailsInlineSaving } from "@ds/components/details-view/hooks";
import { LabelWrapper } from "@ds/components/forms/labels";

export const ExperienceLocationControl: FC<{
  name?: "experience.location_id" | "location_id";
  control: Control<ExperienceFormData> | Control<Experience>;
  autoFocus?: boolean;
  excludeLabel?: boolean;
  onBlur?: () => void;
}> = ({ name = "location_id", control, autoFocus, excludeLabel, onBlur }) => {
  const onBlurHandler = useDetailsInlineSaving(onBlur);

  return (
    <Controller
      name={name}
      control={control as Control<ExperienceFormData | Experience>}
      render={({ field: { ref, onBlur: onBlurFn, ...restFields }, fieldState }) => {
        const element = (
          <LocationsAutocompleteRaw
            inputRef={ref}
            autoFocus={autoFocus}
            onBlur={onBlurHandler(onBlurFn)}
            {...restFields}
          />
        );

        if (excludeLabel) {
          return element;
        }

        return (
          <LabelWrapper label="Location" className="col-8" error={fieldState.error} isRequired>
            {element}
          </LabelWrapper>
        );
      }}
    />
  );
};
