import { createSelector } from "@reduxjs/toolkit";
import { uniq } from "lodash";

import { DetailsTableDataTypeEnum, MainTableDataTypeEnum } from "@ds/modules/table-data/utils/model";

import { type RootState } from "@ds/store/bootstrap";
import { selectorsExtractIds, selectorsOutputEntitiesByIds } from "@ds/store/global-selectors";

import { getAggregatedModel } from "@ds/utils/entities";
import { LoadStatus } from "@ds/utils/reducer";

import { DevicePeripheralsRemoteOperation, entitySelectors } from "./slice";

const selectBaseState = (state: RootState) => state.devices.peripherals;
const selectLoadStatus = (state: RootState, operation = DevicePeripheralsRemoteOperation.CRUD) =>
  selectBaseState(state).loadStatusMap[operation];

export const selectPeripheralsIsLoading = (state: RootState, operation = DevicePeripheralsRemoteOperation.CRUD) =>
  selectLoadStatus(state, operation) === LoadStatus.Loading;

export const selectTablePeripheralIds = (
  state: RootState,
  tableType: MainTableDataTypeEnum | DetailsTableDataTypeEnum = MainTableDataTypeEnum.DevicePeripherals,
) => selectBaseState(state).tableIds[tableType];

export const selectSelectedPeripheralIds = (
  state: RootState,
  tableType: MainTableDataTypeEnum | DetailsTableDataTypeEnum = MainTableDataTypeEnum.DevicePeripherals,
) => selectBaseState(state).selectedIds[tableType];

export const selectPeripheralById = (state: RootState, id: number) =>
  entitySelectors.selectById(selectBaseState(state), id) as Peripheral;

export const selectPeripheralIds = (state: RootState) => entitySelectors.selectIds(selectBaseState(state));
export const selectPeripherals = (state: RootState) => entitySelectors.selectAll(selectBaseState(state));

export const selectPeripheralsByIds = createSelector(
  selectBaseState,
  selectorsExtractIds,
  selectorsOutputEntitiesByIds(() => entitySelectors),
);

export const selectTablePeripherals = createSelector(
  selectBaseState,
  selectTablePeripheralIds,
  selectorsOutputEntitiesByIds(() => entitySelectors),
);

export const selectSelectedPeripherals = createSelector(
  selectBaseState,
  selectSelectedPeripheralIds,
  selectorsOutputEntitiesByIds(() => entitySelectors),
);

export const selectAggregatedPeripheralByIds = createSelector(selectBaseState, selectorsExtractIds, (state, ids) =>
  ids?.length ? getAggregatedModel(selectorsOutputEntitiesByIds(entitySelectors)(state, ids)) : null,
);

export const selectAggregatedPeripheralForSelectedEntities = createSelector(
  selectSelectedPeripherals,
  selectedPeripherals => getAggregatedModel(selectedPeripherals),
);

export const selectPeripheralsByPlayerIds = createSelector(selectBaseState, selectorsExtractIds, (state, playerIds) => {
  const normalizedPlayerIds = uniq(playerIds as number[]);
  return entitySelectors.selectAll(state).filter(({ device_id }) => normalizedPlayerIds.includes(device_id));
});
