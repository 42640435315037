import { useCallback, type FC } from "react";
import { type DeepPartial } from "react-hook-form";

import { useGetAggregatedEntityIds } from "@ds/hooks/use-get-aggregated-entity-ids";
import { useParseQueryIds } from "@ds/hooks/use-parse-query-ids";
import { useReduxEntitiesFetch } from "@ds/hooks/use-redux-entities-fetch";

import { EntityTypeName } from "@ds/model/entity-model";

import { MainTableDataTypeEnum } from "@ds/modules/table-data/utils/model";

import { DetailsView } from "@ds/components/details-view";
import { AbsoluteLoader } from "@ds/components/loaders/loading";
import { PLAYLIST_ID_PARAM } from "@ds/constants/router";
import { QueryParamType, useDeepRootSelector, useRootDispatch, useRootSelector } from "@ds/hooks";
import { pluralizeTotalSelectedTitle } from "@ds/utils/localization/common";
import { isMultiple } from "@ds/utils/numbers";

import { PlaylistDetailsAssetsCard } from "./components/details/playlist-details-assets-card";
import { PlaylistDetailsInfoCard } from "./components/details/playlist-details-info-card";
import { selectAggregatedPlaylistByIds, selectPlaylistsIsLoading } from "./redux/selectors";
import { playlistsActions } from "./redux/slice";

export const PlaylistDetailsPage: FC = () => {
  const queryIds = useParseQueryIds(PLAYLIST_ID_PARAM, QueryParamType.Number);

  const playlist = useDeepRootSelector(state => selectAggregatedPlaylistByIds(state, queryIds));
  const aggregatedPlaylistIds = useGetAggregatedEntityIds(playlist);

  const isLoading = useRootSelector(selectPlaylistsIsLoading);

  const [toggleRefetch] = useReduxEntitiesFetch(
    { entityType: EntityTypeName.CONTENT_PLAYLIST, filters: { id: queryIds } },
    { initialLoad: true },
  );

  const dispatch = useRootDispatch();
  const onSubmitHandler = useCallback(
    (changedFields: DeepPartial<Playlist>) =>
      dispatch(playlistsActions.updatePlaylists(aggregatedPlaylistIds, changedFields)),
    [dispatch, aggregatedPlaylistIds],
  );

  if (!playlist) {
    return <AbsoluteLoader isLoading />;
  }

  return (
    <DetailsView
      title={
        isMultiple(playlist?.metadata?.totalAggregated)
          ? pluralizeTotalSelectedTitle("playlist", playlist?.metadata?.totalAggregated)
          : playlist.name
      }
      tableType={MainTableDataTypeEnum.ContentPlaylists}
      isLoading={isLoading}
      onRefresh={toggleRefetch}
    >
      <PlaylistDetailsInfoCard playlist={playlist} onSubmit={onSubmitHandler} />
      <PlaylistDetailsAssetsCard playlist={playlist} />
    </DetailsView>
  );
};
