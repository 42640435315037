import { StatusEnum } from "@ds/model/status-model";

import { PlayerStateEnum } from "./model";

export const getPlayerPrintableStatus = (state: PlayerState) =>
  ({
    [PlayerStateEnum.Unpaired]: StatusEnum.Unknown,
    [PlayerStateEnum.Paired]: StatusEnum.Info,
    [PlayerStateEnum.Offline]: StatusEnum.Failure,
    [PlayerStateEnum.Online]: StatusEnum.Success,
  })[state];
