import { type FC } from "react";

import { Wizard } from "@ds/components/wizard";

import { CreateLocationProvider } from "./create-location-context";
import { StepFinal } from "./step-final";
import { Step1Address } from "./step1-address";
import { Step2OpenHours } from "./step2-open-hours";
import { Step3Info } from "./step3-info";

export const CreateLocationModal: FC<{ onClose: () => void }> = ({ onClose }) => (
  <CreateLocationProvider>
    <Wizard width="800px" height="80vh" onClose={onClose}>
      <Step1Address />
      <Step2OpenHours />
      <Step3Info />
      <StepFinal />
    </Wizard>
  </CreateLocationProvider>
);
