import { type FC } from "react";
import { Route, Routes, useLocation } from "react-router-dom";

import { EXPERIENCE_ID_PARAM, MULTIPLE_IDS_PARAM } from "@ds/constants/router";

import { ExperienceDetailsPage } from "./experience-details-page";
import { ExperiencesMainPage } from "./experiences-main-page";

const ExperiencesRouteSwitch = () => {
  const { search } = useLocation();
  return search.startsWith(`?${MULTIPLE_IDS_PARAM}`) ? <ExperienceDetailsPage /> : <ExperiencesMainPage />;
};

export const ExperiencesRouter: FC = () => (
  <Routes>
    <Route path="*" element={<ExperiencesRouteSwitch />} />
    <Route path={`:${EXPERIENCE_ID_PARAM}`} element={<ExperienceDetailsPage />} />
  </Routes>
);
