import { put, select, takeLatest, takeLeading } from "redux-saga/effects";

import { selectCognitoUser } from "@ds/modules/auth/redux/selectors";
import { AuthRemoteOperation, authActions } from "@ds/modules/auth/redux/slice";
import { contentAssetsService } from "@ds/modules/content/assets/utils/content-assets-api";
import { thumbnailsService } from "@ds/modules/content/assets/utils/thumbnails-api";
import { playlistAssetsService } from "@ds/modules/content/playlist-assets/utils/api";
import { playlistsService } from "@ds/modules/content/playlists/utils/api";
import { deploymentsService } from "@ds/modules/deployments/utils/api";
import { deviceSyncsService } from "@ds/modules/device-syncs/utils/api";
import { peripheralsService } from "@ds/modules/devices/peripherals/utils/api";
import { playersService } from "@ds/modules/devices/players/utils/api";
import { experiencesService } from "@ds/modules/experiences/utils/api";
import { locationsService } from "@ds/modules/locations/utils/api";
import { invitationsService } from "@ds/modules/settings/invitations/utils/api";
import { projectsService } from "@ds/modules/settings/projects/utils/api";
import { UsersRemoteOperation, usersActions } from "@ds/modules/settings/users/redux/slice";
import { usersService } from "@ds/modules/settings/users/utils/api";

import { REACT_APP_REGION } from "@ds/constants/environment";
import { api } from "@ds/services/ds-api";
import { pubSubService } from "@ds/services/pubsub";
import { storageService } from "@ds/services/storage";

import { appActions } from "./slice";

export const configureServices = () =>
  [
    experiencesService,
    playersService,
    deviceSyncsService,
    peripheralsService,
    locationsService,
    playlistsService,
    playlistAssetsService,
    contentAssetsService,
    thumbnailsService,
    deploymentsService,
    projectsService,
    usersService,
    invitationsService,
  ].forEach(service => service.configure({ api }));

function* onResetApp() {
  yield put(authActions.fetchAuthenticatedCognitoUser());
}

function* onSignOut() {
  yield put(appActions.reset());
}

function* postAuth({ meta }: ReturnType<typeof authActions.fetchAuthenticatedCognitoUserSucceeded>) {
  if (meta.remoteOperation === AuthRemoteOperation.AUTH) {
    configureServices();
    pubSubService.configure();

    const cognitoUser = selectCognitoUser(yield select());
    if (cognitoUser?.tenant_id) {
      storageService.configure({
        bucket: cognitoUser.tenant_id,
        region: REACT_APP_REGION,
      });
    }

    if (cognitoUser?.id) {
      yield put(usersActions.fetchUser(cognitoUser.id, undefined, UsersRemoteOperation.AUTH));
    }
  }
}

export function* watchApp() {
  yield takeLeading(appActions.reset, onResetApp);
  yield takeLeading(authActions.signOutSucceeded, onSignOut);
  yield takeLatest(authActions.fetchAuthenticatedCognitoUserSucceeded, postAuth);
}
