import { useMemo } from "react";
import { useSearchParams } from "react-router-dom";

import { QueryParamType } from "./types";

export const useUrlQueryParams = ((param: string, queryParamType: QueryParamType = QueryParamType.String) => {
  const [params] = useSearchParams(param);
  return useMemo(() => {
    const value = params.get(param);
    return value
      ? value
          .split(",")
          .filter(_ => _)
          .map(el => {
            if (queryParamType === QueryParamType.Number) {
              return Number(el) || 0;
            }

            const stringParam = el?.toString() || "";
            if (queryParamType === QueryParamType.Email) {
              return stringParam.replace(" ", "+");
            }

            return stringParam;
          })
      : [];
  }, [param, queryParamType, params]);
}) as {
  (param: string): string[];
  (param: string, queryParamType: QueryParamType.String): string[];
  (param: string, queryParamType: QueryParamType.Email): string[];
  (param: string, queryParamType: QueryParamType.Number): number[];
  (param: string, queryParamType: QueryParamType): number[] | string[];
};
