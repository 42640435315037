import { type FC } from "react";
import { Route, Routes } from "react-router-dom";

import { useRootSelector } from "@ds/hooks";

import { selectCurrentUserProjectId } from "../settings/users/redux/selectors";
import { ReportsMainPage } from "./reports-main-page";

export const ReportsRouter: FC = () => {
  const shouldShowReportsPage = useRootSelector(selectCurrentUserProjectId) === 29;
  return <Routes>{shouldShowReportsPage && <Route path="*" element={<ReportsMainPage />} />}</Routes>;
};
