import { useContext, type FC } from "react";
import styled from "styled-components";

import { GlobalContext } from "@ds/modules/app/components/global-context";

import { Image } from "../image";
import { NavTextLink } from "../text-link";
import LogoDark from "./logo_dark.png";
import LogoLight from "./logo_light.png";

const ACTIVE_CLASS_NAME = "selected";
const StyledNavLink = styled(NavTextLink)`
  &[aria-current] {
    color: ${props => props.theme.navigationColor};
  }

  color: ${props => props.theme.navigationColor};

  &.${ACTIVE_CLASS_NAME} {
    color: ${props => props.theme.navigationActiveColor};
    background: ${props => props.theme.navigationActiveBackgroundColor};
  }

  :not(.${ACTIVE_CLASS_NAME}):hover {
    background-color: ${props => props.theme.navigationHoverBackgroundColor};
  }

  :hover,
  i:hover:before {
    font-weight: inherit;
  }
`;

const Wrapper = styled.div`
  display: flex;
  flex-flow: column;
  align-items: center;
  margin-bottom: 30px;

  > span {
    display: flex;
    justify-content: center;
    > img {
      width: 50%;
    }
  }
`;

export const NavigationLogo: FC<{ to: string }> = ({ to }) => {
  const { currentTheme } = useContext(GlobalContext);
  const currentLogoSrc = currentTheme === "light" ? LogoDark : LogoLight;
  return (
    <StyledNavLink to={to}>
      <Wrapper>
        <Image src={currentLogoSrc} alt="company logo" />
      </Wrapper>
    </StyledNavLink>
  );
};
