import { type FC } from "react";
import { Controller, type Control } from "react-hook-form";
import styled from "styled-components";

import { FloatPassword } from "@ds/components/forms/inputs";
import { renderErrorMessage } from "@ds/components/forms/labels";

import { type UserRestorePasswordModel, type UserSignInModel, type UserSignUpModel } from "../../../redux/types";

const FieldWrapper = styled.div`
  margin-bottom: 40px;
`;

const ErrorWrapper = styled.div`
  display: inline-block;
  div {
    margin-top: 5px;
  }
`;

export const PasswordControl: FC<{
  control: Control<UserSignInModel> | Control<UserSignUpModel> | Control<UserRestorePasswordModel>;
  name?: "password" | "confirmPassword";
  label?: string;
  autoComplete?: string;
}> = ({ control, name = "password", label = "Password", autoComplete = "current-password" }) => (
  <Controller
    name={name}
    control={control as Control<UserSignInModel | UserRestorePasswordModel | UserSignUpModel>}
    render={({ field: { ref, ...restFields }, fieldState }) => (
      <FieldWrapper>
        <FloatPassword inputId={name} label={label} ref={ref} {...restFields} autoComplete={autoComplete} required />
        <ErrorWrapper>{renderErrorMessage(fieldState.error)}</ErrorWrapper>
      </FieldWrapper>
    )}
  />
);
