import { type FC } from "react";
import styled from "styled-components";

import { StatusEnum } from "@ds/model/status-model";

import { StatusMessage } from "@ds/components/status-message";

const Wrapper = styled.div`
  display: grid;
  place-items: center;
  height: 100vh;
  width: 100vw;
`;

export const NotFoundPage: FC = () => (
  <Wrapper>
    <StatusMessage status={StatusEnum.Warning} message="Page not found..." />
  </Wrapper>
);
