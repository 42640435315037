import { type FC } from "react";
import { Route, Routes, useLocation } from "react-router-dom";

import { MULTIPLE_IDS_PARAM, PROJECT_ID_PARAM } from "@ds/constants/router";

import { ProjectDetailsPage } from "./project-details-page";
import { ProjectsMainPage } from "./projects-main-page";

const ProjectsRouteSwitch = () => {
  const { search } = useLocation();
  return search.startsWith(`?${MULTIPLE_IDS_PARAM}`) ? <ProjectDetailsPage /> : <ProjectsMainPage />;
};

export const ProjectsRouter: FC = () => (
  <Routes>
    <Route path="*" element={<ProjectsRouteSwitch />} />
    <Route path={`:${PROJECT_ID_PARAM}`} element={<ProjectDetailsPage />} />
  </Routes>
);
