import { IS_TESTING } from "@ds/constants/environment";
import { isErrorLike } from "@ds/utils/type-guards/error-guards";

import { notificationsActions } from "./slice";

export const toastShowSuccessAction = (detail: string, summary = "Success") =>
  notificationsActions.showToast({
    summary,
    detail,
    severity: "success",
  });

export const toastShowInfoAction = (detail: string, summary = "Info") =>
  notificationsActions.showToast({
    summary,
    detail,
    severity: "info",
  });

export const toastShowErrorAction = (err: Error | ApiError | AmplifyError | string, summary = "Error") =>
  notificationsActions.showToast({
    summary,
    detail: isErrorLike(err) && !IS_TESTING ? err.message : JSON.stringify(err, Object.getOwnPropertyNames(err)),
    severity: "error",
  });
