import { useCallback, useState, type FC } from "react";

import { useRootDispatch } from "@ds/hooks/redux-hooks";

import { InputText } from "@ds/components/forms/inputs";
import { LabelWrapper } from "@ds/components/forms/labels";
import { WizardPage, type WizardChildStepProps } from "@ds/components/wizard";

import { playersActions } from "../../../redux/slice";

export const Step1Main: FC<WizardChildStepProps & { initialValue: Player }> = ({ initialValue, nextStep, ...rest }) => {
  const [pairingCode, setPairingCode] = useState("");
  const [error, setError] = useState<Error | undefined>();

  // TODO: FORMS: FIX TO REACT_HOOKS_FORM
  const validate = useCallback((): boolean => {
    if (!pairingCode.trim().length) {
      setError(Error("Please specify pairing code"));
      return false;
    }

    setError(undefined);
    return true;
  }, [pairingCode]);

  const dispatch = useRootDispatch();
  const onNextClickHandler = useCallback(() => {
    if (validate()) {
      dispatch(playersActions.pairPlayer({ playerId: initialValue.id, pairingCode }));
      if (nextStep) {
        nextStep();
      }
    }
  }, [dispatch, initialValue.id, pairingCode, validate, nextStep]);

  const onChangeHandler = useCallback((e: { target: { value: string } }) => setPairingCode(e.target.value), []);

  return (
    <WizardPage {...rest} title="Pair player" nextStep={onNextClickHandler}>
      <LabelWrapper label="Pairing Code" className="col-8" error={error} isRequired>
        <InputText placeholder="Enter pairing code" autoFocus value={pairingCode} onChange={onChangeHandler} />
      </LabelWrapper>
    </WizardPage>
  );
};
