import { useCallback, useEffect, useState, type FC } from "react";

import { TriStateCheckbox, TriStateCheckboxValue } from "../forms/inputs";

const getHeaderCheckboxValue = (total: number, selected: number): string | null => {
  if (total && selected === total) {
    return TriStateCheckboxValue.ALL;
  }

  return selected ? TriStateCheckboxValue.PARTIAL : null;
};

export const SelectionColumn: FC<{
  total: number;
  selected: number;
  isLoading: boolean;
  onToggleAll: (isSelectedAll: boolean) => void;
}> = ({ selected, total, isLoading, onToggleAll }) => {
  const [checkboxValue, setCheckboxValue] = useState<string | null>(getHeaderCheckboxValue(total, selected));

  const onChangeHandler = useCallback(() => {
    const isSelectedAll = checkboxValue === TriStateCheckboxValue.ALL;
    setCheckboxValue(isSelectedAll ? null : TriStateCheckboxValue.ALL);
    onToggleAll(!isSelectedAll);
  }, [onToggleAll, checkboxValue]);

  useEffect(() => {
    if (!isLoading) {
      setCheckboxValue(getHeaderCheckboxValue(total, selected));
    }
  }, [selected, total, isLoading]);

  return <TriStateCheckbox value={checkboxValue} onChange={onChangeHandler} />;
};
