import { useEffect, useMemo, useState, type FC } from "react";

import { AutocompleteTypeEnum, useFetchEntitiesAutocomplete } from "@ds/hooks/use-fetch-entities";

import {
  AutoComplete,
  AutoCompleteGroupItem,
  AutoCompleteGroupLabel,
  type SingleAutoCompleteProps,
} from "@ds/components/forms/autocomplete";

import { getPlayerStatusIndicatorWithName } from "../../devices/players/components/common/status-indicator";
import { type PlayersByExperienceGroup, type PlayersByExperienceGroupList } from "../../devices/players/utils/model";

const renderItemTemplate =
  (currentExperienceId: number | undefined, assignedEntitiesIds: number[]) => (player: Player) => {
    const hasAssignedToAnotherExperience =
      (player.experience_id && !currentExperienceId) ||
      (player.experience_id && player.experience_id !== currentExperienceId);

    if (hasAssignedToAnotherExperience || assignedEntitiesIds.includes(player.id)) {
      return (
        <AutoCompleteGroupItem $isInvalid className="autocomplete-option--disabled">
          {getPlayerStatusIndicatorWithName(player)}
        </AutoCompleteGroupItem>
      );
    }

    return <AutoCompleteGroupItem>{getPlayerStatusIndicatorWithName(player)}</AutoCompleteGroupItem>;
  };

const renderOptionGroupTemplate = ({ assigned_to_status }: PlayersByExperienceGroup) => (
  <AutoCompleteGroupLabel>
    {assigned_to_status === "unassigned" ? "Select player to assign" : "Already assigned players"}
  </AutoCompleteGroupLabel>
);

const getInitialGroupedValues = (): PlayersByExperienceGroupList => [
  { assigned_to_status: "unassigned", players: [] },
  { assigned_to_status: "assigned", players: [] },
];

export const ExperiencePlayersAutoComplete: FC<Omit<SingleAutoCompleteProps<Player>, "isLoading">> = ({
  customRef,
  inputRef,
  value,
  currentExperienceId,
  assignedEntities,
  filterSuggestionsPredicate,
  dropdownMode,
  autoFocus,
  onBlur,
  onChange,
}) => {
  const assignedEntitiesIds = useMemo(() => assignedEntities?.map(({ id }) => id) || [], [assignedEntities]);
  const [grouppedSuggestions, setGrouppedSuggestions] =
    useState<PlayersByExperienceGroupList>(getInitialGroupedValues());

  const [
    suggestions,
    onCompleteHandler,
    {
      state: { status: remoteStatus },
    },
  ] = useFetchEntitiesAutocomplete<Player>(AutocompleteTypeEnum.Players, {
    sortField: "experience_id",
    filterPredicate: filterSuggestionsPredicate,
  });

  useEffect(() => {
    if (suggestions.length) {
      const [unassigned, assigned] = getInitialGroupedValues();

      suggestions.forEach(entry => {
        const hasAssignedToAnotherExperience =
          (entry?.experience_id && !currentExperienceId) ||
          (entry?.experience_id && entry?.experience_id !== currentExperienceId);

        (hasAssignedToAnotherExperience || assignedEntitiesIds.includes(entry.id) ? assigned : unassigned).players.push(
          entry,
        );
      });

      setGrouppedSuggestions([unassigned, assigned]);
    } else {
      setGrouppedSuggestions([] as unknown as PlayersByExperienceGroupList);
    }
  }, [currentExperienceId, assignedEntitiesIds, suggestions]);

  return (
    <AutoComplete
      field="name"
      placeholder="Enter player name"
      itemTemplate={renderItemTemplate(currentExperienceId, assignedEntitiesIds)}
      optionGroupLabel="assigned_to_status"
      optionGroupChildren="players"
      optionGroupTemplate={renderOptionGroupTemplate}
      customRef={customRef}
      inputRef={inputRef}
      value={value}
      suggestions={grouppedSuggestions}
      dropdownMode={dropdownMode}
      autoFocus={autoFocus}
      isLoading={remoteStatus === "loading"}
      onBlur={onBlur}
      onChange={onChange}
      onComplete={onCompleteHandler}
    />
  );
};
