import { useCallback, useEffect, useState, type FC } from "react";
import styled from "styled-components";

import { useRootDispatch, useRootSelector } from "@ds/hooks/redux-hooks";

import { InfoSection } from "@ds/components/info-view";
import { TabMenu } from "@ds/components/tab-menu";

import { selectScreenshot, selectScreenshotStatus } from "../../redux/selectors";
import { playersActions } from "../../redux/slice";
import { PlayerStateEnum } from "../../utils/model";
import { PlayerScreenshotOverlay, renderLoader } from "./player-screenshot-overlay";

enum TvTabs {
  TVScreen,
  Playback,
}

const StyledTabMenu = styled(TabMenu)`
  .p-tabmenu-nav {
    margin-bottom: 1px;

    .p-tabmenuitem {
      width: 50%;
    }
  }
`;

export const PlayerTvInfoSection: FC<{ player: Player }> = ({ player }) => {
  const screenshot = useRootSelector(selectScreenshot);
  const screenshotStatus = useRootSelector(selectScreenshotStatus);
  const [tabIndex, setTabIndex] = useState<TvTabs>(TvTabs.TVScreen);

  const tvTabItems = [
    { label: "TV Screen", icon: "pi pi-fw pi-desktop" },
    { label: "Playback", icon: "pi pi-fw pi-play" },
  ];

  const dispatch = useRootDispatch();
  const onScreenshotClickHandler = useCallback(() => {
    dispatch(playersActions.startFetchingScreenshots(player.id));
  }, [dispatch, player.id]);

  const onTabChangeHandler = useCallback((e: { index: number }) => {
    setTabIndex(e.index);
  }, []);

  useEffect(() => {
    if (player.state === PlayerStateEnum.Online) {
      onScreenshotClickHandler();
    }

    return () => void dispatch(playersActions.stopFetchingScreenshots());
  }, [dispatch, player.state, onScreenshotClickHandler]);

  if (player.state !== PlayerStateEnum.Online) {
    return null;
  }

  return (
    <InfoSection title="Screenshot" icon="pi pi-images">
      <StyledTabMenu
        selectedOption={tvTabItems[tabIndex].label}
        options={tvTabItems}
        onTabChange={onTabChangeHandler}
        className="w-full"
      />

      {tabIndex === TvTabs.TVScreen && (
        <>
          {player.state === PlayerStateEnum.Online ? (
            <PlayerScreenshotOverlay
              screenshot={screenshot}
              status={screenshotStatus}
              onShowMoreClick={onScreenshotClickHandler}
            />
          ) : (
            renderLoader("TV screen is off")
          )}
        </>
      )}

      {tabIndex === TvTabs.Playback && (
        <PlayerScreenshotOverlay
          screenshot={screenshot}
          status={screenshotStatus}
          onShowMoreClick={onScreenshotClickHandler}
        />
      )}
    </InfoSection>
  );
};
