import { type FC } from "react";

import { Wizard } from "@ds/components/wizard";

import { StepFinal } from "../create-player-form/step-final";
import { Step1Main } from "./step1-main";
import { Step2Pairing } from "./step2-pairing";

export const PairPlayerModal: FC<{
  initialValue: Player;
  onClose: () => void;
}> = ({ initialValue, onClose }) => (
  <Wizard width="40vw" height="80vh" onClose={onClose}>
    <Step1Main initialValue={initialValue} />
    <Step2Pairing />
    <StepFinal successMessage="Player paired successfully!" />
  </Wizard>
);
