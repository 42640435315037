import { uniq } from "lodash";
import { useMemo, type FC } from "react";

import { useGetAggregatedEntityIds } from "@ds/hooks/use-get-aggregated-entity-ids";

import { getPlayerStatusIndicatorWithNameAndLink } from "@ds/modules/devices/players/components/common/status-indicator";
import { selectPlayersByIds } from "@ds/modules/devices/players/redux/selectors";

import { MultipleValuesLabelWithLink, UnassignedLabel } from "@ds/components/forms/labels/common";
import { InfoSection } from "@ds/components/info-view";
import { useDeepRootSelector } from "@ds/hooks";
import { isMultipleFieldValuesInAggregatedEntity } from "@ds/utils/entities";
import { composeDetailsRouteByEntityType } from "@ds/utils/router";

import { selectExperiencesByIds } from "../../redux/selectors";

export const ExperiencePlayersInfoSection: FC<{ experience: Experience }> = ({ experience }) => {
  const aggregatedExperienceIds = useGetAggregatedEntityIds(experience);
  const aggregatedExperiencePlayers = useDeepRootSelector(state => {
    const aggregatedExperiences = selectExperiencesByIds(state, aggregatedExperienceIds);
    const aggregatedPlayersIds = uniq(aggregatedExperiences.flatMap(({ device_ids }) => device_ids).filter(_ => _));
    return selectPlayersByIds(state, aggregatedPlayersIds, "experience_order");
  });

  const infoBody = useMemo(() => {
    const hasMultipleValues = isMultipleFieldValuesInAggregatedEntity(experience, "device_ids");
    if (!aggregatedExperiencePlayers.length) {
      return <UnassignedLabel text="Experience(s) has no assigned players" />;
    } else if (hasMultipleValues) {
      return <MultipleValuesLabelWithLink to={composeDetailsRouteByEntityType(aggregatedExperiencePlayers)} />;
    }

    return aggregatedExperiencePlayers.map(entry => (
      <div key={`experience-info-player-${entry?.id}`} className="mb-1">
        {getPlayerStatusIndicatorWithNameAndLink(entry)}
      </div>
    ));
  }, [experience, aggregatedExperiencePlayers]);

  return (
    <InfoSection title="Players" icon="pi pi-desktop">
      {infoBody}
    </InfoSection>
  );
};
