import { type DeepPartial } from "react-hook-form";

import { DSApi } from "@ds/services/ds-api";
import { type ApiService, type ApiServiceConfig } from "@ds/services/types";
import { parseQueries } from "@ds/utils/query";

interface ExperiencesServiceConfig extends ApiServiceConfig {
  api: DSApi;
}

export class ExperiencesService implements ApiService {
  private config: ExperiencesServiceConfig = {
    api: {} as DSApi,
  };

  configure(cfg: ExperiencesServiceConfig) {
    this.config = { ...cfg };
  }

  async getExperiences(queryInfo: QueryInfo, pagination: Pagination) {
    const queries = parseQueries(queryInfo, pagination);
    return this.config.api.get<QueryOutput<ApiExperience>>("/experiences/", queries);
  }

  async getExperience(experienceId: number) {
    return this.config.api.get<ApiExperience>(`/experiences/${experienceId}`);
  }

  async createExperience(experience: Experience) {
    return this.config.api.post<ApiExperience>("/experiences/", experience);
  }

  async updateExperiences(ids: number[], data: DeepPartial<ApiExperience>) {
    return this.config.api.patch<ApiExperience>("/experiences/", { ids, data });
  }

  async deleteExperiences(ids: number[]) {
    return this.config.api.delete<ApiExperience>("/experiences/", { ids });
  }
}

export const experiencesService = new ExperiencesService();
