import { nanoid } from "@reduxjs/toolkit";
import { type FC, type ReactNode } from "react";
import styled from "styled-components";

import { StatusColor } from "@ds/model/status-model";

import { Tooltip } from "./tooltips";

const StatusIcon = styled.span<{
  $status: Status;
}>`
  display: inline-block;
  min-width: 8px;
  height: 8px;
  margin-right: 10px;
  border: none;
  border-radius: 50% !important;
  background: ${({ $status }) => StatusColor[$status]} !important;
`;

const Wrapper = styled.div`
  display: inline-block;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
`;

export const StatusIndicator: FC<{
  status: Status;
  title?: string;
  children: ReactNode;
}> = ({ status, children, title }) => {
  const tooltipId = `.tooltip-${nanoid()}`;
  return (
    <div className="flex">
      <Tooltip target={tooltipId} content={title} position="top" />
      <div className={`flex align-items-center ${tooltipId.slice(1)}`}>
        <StatusIcon $status={status} />
        <Wrapper>{children}</Wrapper>
      </div>
    </div>
  );
};
