import { type FC } from "react";

import { renderErrorMessage } from "./label-wrapper";
import {
  ValidationInputDescription,
  ValidationInputWrapper,
  ValidationSpanContentWrapper,
  ValidationTitle,
  ValidationWrapper,
} from "./styles";
import { type LabelSpanWrapperProps } from "./types";

export const SpanWrapper: FC<WithPartial<LabelSpanWrapperProps, "label">> = ({
  label,
  infoText,
  isRequired = false,
  error,
  className,
  children,
}) => (
  <ValidationWrapper className={className}>
    <ValidationSpanContentWrapper>
      {!!label && <ValidationTitle $isRequired={isRequired}>{label}</ValidationTitle>}
      <ValidationInputWrapper aria-required={isRequired} $isValid={!error}>
        {children}
      </ValidationInputWrapper>
      {!!infoText && <ValidationInputDescription>{infoText}</ValidationInputDescription>}
    </ValidationSpanContentWrapper>
    {renderErrorMessage(error)}
  </ValidationWrapper>
);
