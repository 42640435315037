import { createEntityAdapter, createSlice, type PayloadAction } from "@reduxjs/toolkit";
import { type DeepPartial } from "react-hook-form";
import { all } from "redux-saga/effects";

import { DetailsTableDataTypeEnum, MainTableDataTypeEnum } from "@ds/modules/table-data/utils/model";

import { getIds } from "@ds/utils/entities";
import { LoadStatus, type BaseMeta, type PatchedModel } from "@ds/utils/reducer";
import { type SagaOptions } from "@ds/utils/saga-helpers";

import { settingsActions } from "../../redux/slice";
import { apiToModelNormalize } from "../utils/normalizer";
import { watchInvitations } from "./sagas";

export enum InvitationsRemoteOperation {
  CRUD = "crud",
  CHECKBOXES = "checkboxes",
  IOT_FETCH = "iot_fetch",
}

export type InvitationsMeta = BaseMeta & {
  remoteOperation: InvitationsRemoteOperation;
  filters?: KeysToCamelCase<SettingsInvitationFilters>;
};

export interface InvitationsState {
  loadStatusMap: {
    [InvitationsRemoteOperation.CRUD]: LoadStatus;
    [InvitationsRemoteOperation.CHECKBOXES]: LoadStatus;
    [InvitationsRemoteOperation.IOT_FETCH]: LoadStatus;
  };

  error: string | null;

  entities: {
    [invitationId: string]: Invitation;
  };

  ids: string[];
  tableIds: Record<string, string[]>;
  selectedIds: Record<string, string[]>;
}

const entityAdapter = createEntityAdapter<Invitation>();

export const initialState: InvitationsState = entityAdapter.getInitialState({
  loadStatusMap: {
    [InvitationsRemoteOperation.CRUD]: LoadStatus.Idle,
    [InvitationsRemoteOperation.CHECKBOXES]: LoadStatus.Idle,
    [InvitationsRemoteOperation.IOT_FETCH]: LoadStatus.Idle,
  },

  error: null,

  tableIds: { [MainTableDataTypeEnum.Invitations]: [] },
  selectedIds: { [MainTableDataTypeEnum.Invitations]: [] },
}) as InvitationsState;

const getTableTypeOrDefault = (
  tableType: MainTableDataTypeEnum | DetailsTableDataTypeEnum = MainTableDataTypeEnum.Invitations,
) => tableType;

const removeEntities = (state: InvitationsState, payload: string[]) => {
  entityAdapter.removeMany(state, payload);

  state.tableIds[MainTableDataTypeEnum.Invitations] = state.tableIds[MainTableDataTypeEnum.Invitations].filter(
    id => !payload.includes(id),
  );

  state.selectedIds[MainTableDataTypeEnum.Invitations] = state.selectedIds[MainTableDataTypeEnum.Invitations].filter(
    id => !payload.includes(id),
  );
};

const sliceType = "INVITATIONS";
const slice = createSlice({
  name: sliceType,
  initialState,
  reducers: {
    fetchInvitations: {
      reducer(state, { meta }: PayloadAction<undefined, string, InvitationsMeta>) {
        state.loadStatusMap[meta.remoteOperation] = LoadStatus.Loading;
        state.error = null;

        if (meta.remoteOperation === InvitationsRemoteOperation.CHECKBOXES) {
          state.selectedIds[getTableTypeOrDefault(meta.options?.tableType)] =
            state.tableIds[getTableTypeOrDefault(meta.options?.tableType)];
        }
      },
      prepare(
        filters: KeysToCamelCase<SettingsInvitationFilters> = {},
        options: SagaOptions = {},
        remoteOperation: InvitationsRemoteOperation = InvitationsRemoteOperation.CRUD,
      ) {
        return { payload: undefined, meta: { remoteOperation, filters, options } };
      },
    },
    fetchInvitationsFailed: {
      reducer(state, { meta, error }: PayloadAction<undefined, string, InvitationsMeta, string>) {
        state.loadStatusMap[meta.remoteOperation] = LoadStatus.Failed;
        state.error = error;

        if (meta.remoteOperation === InvitationsRemoteOperation.CHECKBOXES) {
          state.loadStatusMap[meta.remoteOperation] = LoadStatus.Failed;
          state.selectedIds[getTableTypeOrDefault(meta.options?.tableType)] = [];
        }
      },
      prepare(meta: InvitationsMeta, { message }: ErrorLike) {
        return { payload: undefined, meta, error: message };
      },
    },
    fetchInvitationsSucceeded: {
      reducer(
        state,
        { payload, meta }: PayloadAction<QueryOutput<Invitation> | QueryOutput<ApiInvitation>, string, InvitationsMeta>,
      ) {
        state.loadStatusMap[meta.remoteOperation] = LoadStatus.Succeeded;
        state.error = null;

        entityAdapter.setMany(state, apiToModelNormalize(payload.items));

        if (meta.remoteOperation === InvitationsRemoteOperation.CHECKBOXES) {
          state.selectedIds[getTableTypeOrDefault(meta.options?.tableType)] = payload.items.map(({ id }) => id);
        } else if (meta.options?.tableType) {
          state.tableIds[getTableTypeOrDefault(meta.options?.tableType)] = payload.items.map(({ id }) => id);
          state.selectedIds[getTableTypeOrDefault(meta.options?.tableType)] = state.selectedIds[
            getTableTypeOrDefault(meta.options?.tableType)
          ].filter(id => state.ids.includes(id));
        }
      },
      prepare(payload: QueryOutput<Invitation> | QueryOutput<ApiInvitation>, meta: InvitationsMeta) {
        return { payload, meta };
      },
    },
    fetchInvitation: {
      reducer(state, { meta }: PayloadAction<string, string, InvitationsMeta>) {
        state.loadStatusMap[meta.remoteOperation || meta.remoteOperation] = LoadStatus.Loading;
        state.error = null;
      },
      prepare(payload: string, options: SagaOptions = {}) {
        return { payload, meta: { remoteOperation: InvitationsRemoteOperation.CRUD, options } };
      },
    },
    fetchInvitationFailed: {
      reducer(state, { meta, error }: PayloadAction<string, string, InvitationsMeta, string>) {
        state.loadStatusMap[meta.remoteOperation || meta.remoteOperation] = LoadStatus.Failed;
        state.error = error;
      },
      prepare(payload: string, meta: InvitationsMeta, { message }: ErrorLike) {
        return { payload, meta, error: message };
      },
    },
    fetchInvitationSucceeded: {
      reducer(state, { payload, meta }: PayloadAction<Invitation | ApiInvitation, string, InvitationsMeta>) {
        state.loadStatusMap[meta.remoteOperation || meta.remoteOperation] = LoadStatus.Succeeded;
        state.error = null;

        entityAdapter.setOne(state, apiToModelNormalize(payload));
      },
      prepare(payload: Invitation | ApiInvitation, meta: InvitationsMeta) {
        return { payload, meta };
      },
    },
    createInvitation: {
      reducer(state, { meta }: PayloadAction<Invitation, string, InvitationsMeta>) {
        state.loadStatusMap[meta.remoteOperation] = LoadStatus.Loading;
        state.error = null;
      },
      prepare(payload: Invitation, meta: InvitationsMeta = { remoteOperation: InvitationsRemoteOperation.CRUD }) {
        return { payload, meta };
      },
    },
    createInvitationFailed: {
      reducer(state, { meta, error }: PayloadAction<Invitation, string, InvitationsMeta, string>) {
        state.loadStatusMap[meta.remoteOperation] = LoadStatus.Failed;
        state.error = error;
      },
      prepare(payload: Invitation, meta: InvitationsMeta, { message }: ErrorLike) {
        return { payload, meta, error: message };
      },
    },
    createInvitationSucceeded: {
      reducer(state, { payload, meta }: PayloadAction<ApiInvitation, string, InvitationsMeta>) {
        state.loadStatusMap[meta.remoteOperation] = LoadStatus.Succeeded;
        state.error = null;
        entityAdapter.setOne(state, apiToModelNormalize(payload));
      },
      prepare(payload: ApiInvitation, meta: InvitationsMeta) {
        return { payload, meta };
      },
    },
    updateInvitations: {
      reducer(state, _action: PayloadAction<PatchedModel<Invitation>>) {
        state.loadStatusMap[InvitationsRemoteOperation.CRUD] = LoadStatus.Loading;
        state.error = null;
      },
      prepare(changedIds: string[], changedFields: DeepPartial<Invitation>) {
        return { payload: { ids: changedIds, data: changedFields } };
      },
    },
    updateInvitationsFailed: {
      reducer(state, { error }: PayloadAction<PatchedModel<Invitation>, string, never, string>) {
        state.loadStatusMap[InvitationsRemoteOperation.CRUD] = LoadStatus.Failed;
        state.error = error;
      },
      prepare(payload: PatchedModel<Invitation>, { message }: ErrorLike) {
        return { payload, error: message };
      },
    },
    updateInvitationsSucceeded: (state, { payload }: PayloadAction<ApiInvitation[]>) => {
      state.loadStatusMap[InvitationsRemoteOperation.CRUD] = LoadStatus.Succeeded;
      state.error = null;

      entityAdapter.setMany(state, apiToModelNormalize(payload));
    },
    deleteInvitations: {
      reducer(state, { meta }: PayloadAction<string[], string, InvitationsMeta>) {
        state.loadStatusMap[meta.remoteOperation] = LoadStatus.Loading;
        state.error = null;
      },
      prepare(invitationsOrIds: Invitation[] | string[], redirectTo?: string) {
        return {
          payload: getIds(invitationsOrIds),
          meta: { remoteOperation: InvitationsRemoteOperation.CRUD, options: { redirectTo } },
        };
      },
    },
    deleteInvitationsFailed: {
      reducer(state, { meta, error }: PayloadAction<string[], string, InvitationsMeta, string>) {
        state.loadStatusMap[meta.remoteOperation] = LoadStatus.Failed;
        state.error = error;
      },
      prepare(idsToDelete: string[], meta: InvitationsMeta, { message }: ErrorLike) {
        return { payload: idsToDelete, meta, error: message };
      },
    },
    deleteInvitationsSucceeded: {
      reducer(state, { payload, meta }: PayloadAction<string[], string, InvitationsMeta>) {
        state.loadStatusMap[meta.remoteOperation] = LoadStatus.Succeeded;
        state.error = null;

        removeEntities(state, payload);
      },
      prepare(payload: string[], meta: InvitationsMeta) {
        return { payload, meta };
      },
    },
    selectInvitations: {
      reducer(state, { payload, meta }: PayloadAction<string[], string, InvitationsMeta>) {
        state.loadStatusMap[meta.remoteOperation] = LoadStatus.Succeeded;

        state.selectedIds[getTableTypeOrDefault(meta.options?.tableType)] = payload;
      },
      prepare(invitationsOrIds: Invitation[] | ApiInvitation[] | string[], options: SagaOptions = {}) {
        return {
          payload: getIds(invitationsOrIds),
          meta: { remoteOperation: InvitationsRemoteOperation.CHECKBOXES, options },
        };
      },
    },
    toggleSelected: {
      reducer(state, { payload, meta }: PayloadAction<string, string, InvitationsMeta>) {
        state.loadStatusMap[meta.remoteOperation] = LoadStatus.Succeeded;

        state.selectedIds[getTableTypeOrDefault(meta.options?.tableType)] = state.selectedIds[
          getTableTypeOrDefault(meta.options?.tableType)
        ].includes(payload)
          ? state.selectedIds[getTableTypeOrDefault(meta.options?.tableType)].filter(id => id !== payload)
          : [...state.selectedIds[getTableTypeOrDefault(meta.options?.tableType)], payload];
      },
      prepare(invitationId: string, options: SagaOptions = {}) {
        return {
          payload: invitationId,
          meta: { remoteOperation: InvitationsRemoteOperation.CHECKBOXES, options },
        };
      },
    },
    resendInvitations: {
      reducer(state, { meta }: PayloadAction<string[], string, InvitationsMeta>) {
        state.loadStatusMap[meta.remoteOperation] = LoadStatus.Loading;
        state.error = null;
      },
      prepare(invitationsOrIds: Invitation[] | string[], redirectTo?: string) {
        return {
          payload: getIds(invitationsOrIds),
          meta: { remoteOperation: InvitationsRemoteOperation.CRUD, options: { redirectTo } },
        };
      },
    },
    resendInvitationsFailed: {
      reducer(state, { meta, error }: PayloadAction<string[], string, InvitationsMeta, string>) {
        state.loadStatusMap[meta.remoteOperation] = LoadStatus.Failed;
        state.error = error;
      },
      prepare(idsToDelete: string[], meta: InvitationsMeta, { message }: ErrorLike) {
        return { payload: idsToDelete, meta, error: message };
      },
    },
    resendInvitationsSucceeded: {
      reducer(state, { payload, meta }: PayloadAction<ApiInvitation[], string, InvitationsMeta>) {
        state.loadStatusMap[meta.remoteOperation] = LoadStatus.Succeeded;
        state.error = null;

        entityAdapter.setMany(state, apiToModelNormalize(payload));
      },
      prepare(payload: ApiInvitation[], meta: InvitationsMeta) {
        return { payload, meta };
      },
    },
  },
  extraReducers: builder => {
    builder.addCase(settingsActions.switchView, state => {
      state.loadStatusMap[InvitationsRemoteOperation.CRUD] = LoadStatus.Idle;
      state.error = null;
    });
  },
});

export const { name: invitationsType, actions: invitationsActions, reducer: invitationsReducer } = slice;

export const entitySelectors = entityAdapter.getSelectors();

export function* rootInvitationsSaga() {
  yield all([watchInvitations()]);
}
