import { isEqual, isFunction, isString } from "lodash";
import { type AutoCompleteSelectEvent } from "primereact/autocomplete";
import { useCallback, useEffect, useState, type FC } from "react";

import { useOnMouseEventPreventDefaultHandler } from "@ds/utils/handlers";

import { AutocompleteWrapper, ClearButton, ClearIcon, StyledAutoComplete } from "./styles";
import {
  type AutoCompleteChangeEvent,
  type AutoCompleteCompleteEvent,
  type AutoCompleteProps,
  type NullableEntityData,
} from "./types";

const getNullableData = (isMultiple: boolean) => (isMultiple ? [] : null);

export const AutoComplete: FC<AutoCompleteProps> = ({
  customRef,
  inputRef,
  value,
  suggestions,
  dropdown = true,
  dropdownMode = "blank",
  multiple = false,
  shouldBeClearedImmediately = false,
  shouldSelectedOptionBePassedFromParent = false,
  isLoading,
  onComplete,
  onChange,
  onTextChange,
  onShow,
  ...rest
}) => {
  const [currentValue, setCurrentValues] = useState<NullableEntityData | string>(value || null);
  const onMouseDownHandler = useOnMouseEventPreventDefaultHandler();

  useEffect(() => setCurrentValues(value || null), [value]);
  useEffect(() => {
    if (shouldSelectedOptionBePassedFromParent) {
      setCurrentValues(value || null);
    }
  }, [value, shouldSelectedOptionBePassedFromParent]);

  const [selected, setSelected] = useState(!!value);
  useEffect(() => void setSelected(!!value), [value, setSelected]);

  const onChangeHandler = useCallback(
    ({ value: inputValue }: AutoCompleteChangeEvent) => {
      if (shouldSelectedOptionBePassedFromParent) {
        if (isString(inputValue)) {
          setCurrentValues(inputValue);
        }
      } else {
        setCurrentValues(inputValue);
      }

      if (isString(inputValue)) {
        setSelected(false);
        if (onTextChange) {
          onTextChange(inputValue);
        }
      } else {
        if (shouldBeClearedImmediately) {
          setCurrentValues(getNullableData(multiple));
        } else {
          setSelected(true);
        }

        if (onChange) {
          onChange(inputValue);
        }
      }
    },
    [shouldBeClearedImmediately, shouldSelectedOptionBePassedFromParent, multiple, onChange, onTextChange],
  );

  const onSelectHandler = useCallback(
    (e: AutoCompleteSelectEvent) => {
      if (onChange) {
        if (isEqual(currentValue, e.value)) {
          onChange(e.value);
        }
      }
    },
    [currentValue, onChange],
  );

  const onClearClickHandler = useCallback(() => {
    setCurrentValues(getNullableData(multiple));
    setSelected(false);
    if (onChange) {
      onChange(getNullableData(multiple));
    }
  }, [multiple, onChange]);

  const onCompleteHandler = useCallback(
    (e: AutoCompleteCompleteEvent) => {
      onComplete(e.query);
    },
    [onComplete],
  );

  // TODO: HOOKS: move to useEffect??? add unsubscribe???
  const onShowHandler = useCallback(() => {
    document.querySelectorAll(".p-autocomplete-item:has(.autocomplete-option--disabled)").forEach(element => {
      element.addEventListener("click", e => e.stopImmediatePropagation(), true);
    });

    document.querySelectorAll(".p-autocomplete-item").forEach(element => {
      element.setAttribute("tabIndex", "0");
    });

    if (onShow) {
      onShow();
    }
  }, [onShow]);

  return (
    <AutocompleteWrapper>
      <StyledAutoComplete
        {...rest}
        inputRef={el => {
          if (isFunction(inputRef)) {
            inputRef(el);
          }

          if (customRef) {
            customRef.current = el;
          }
        }}
        minLength={2}
        delay={500}
        inputClassName="autocomplete-input"
        value={currentValue}
        suggestions={suggestions}
        dropdown={dropdown}
        dropdownMode={dropdownMode}
        multiple={multiple}
        emptyMessage="No entities found"
        showEmptyMessage
        completeMethod={onCompleteHandler}
        onSelect={onSelectHandler}
        onChange={onChangeHandler}
        onShow={onShowHandler}
      />

      {selected && !multiple && !isLoading && (
        <ClearButton onClick={onClearClickHandler} onMouseDown={onMouseDownHandler}>
          <ClearIcon $hasDropdown={dropdown} className="pi pi-times" />
        </ClearButton>
      )}
    </AutocompleteWrapper>
  );
};
