import { createSelector } from "@reduxjs/toolkit";
import { uniq } from "lodash";

import { entitySelectors, ExperiencesRemoteOperation } from "@ds/modules/experiences/redux/slice";
import { DetailsTableDataTypeEnum, MainTableDataTypeEnum } from "@ds/modules/table-data/utils/model";

import { type RootState } from "@ds/store/bootstrap";
import { selectorsExtractIds, selectorsOutputEntitiesByIds } from "@ds/store/global-selectors";

import { getAggregatedModel } from "@ds/utils/entities";
import { LoadStatus } from "@ds/utils/reducer";

const selectBaseState = (state: RootState) => state.experiences;

export const selectCurrentView = (state: RootState) => selectBaseState(state).viewType;

const selectLoadStatus = (state: RootState, operation = ExperiencesRemoteOperation.CRUD) =>
  selectBaseState(state).loadStatusMap[operation];

export const selectExperiencesIsLoading = (state: RootState, operation = ExperiencesRemoteOperation.CRUD) =>
  selectLoadStatus(state, operation) === LoadStatus.Loading;

export const selectExperiencesError = (state: RootState) => selectBaseState(state).error;

export const selectTableExperienceIds = (
  state: RootState,
  tableType: MainTableDataTypeEnum | DetailsTableDataTypeEnum = MainTableDataTypeEnum.Experiences,
) => selectBaseState(state).tableIds[tableType];

export const selectSelectedExperienceIds = (
  state: RootState,
  tableType: MainTableDataTypeEnum | DetailsTableDataTypeEnum = MainTableDataTypeEnum.Experiences,
) => selectBaseState(state).selectedIds[tableType];

export const selectExperienceById = (state: RootState, id: number) =>
  entitySelectors.selectById(selectBaseState(state), id) as Experience;

export const selectExperienceIds = (state: RootState) => entitySelectors.selectIds(selectBaseState(state));
export const selectExperiences = (state: RootState) => entitySelectors.selectAll(selectBaseState(state));

export const selectExperiencesByIds = createSelector(
  selectBaseState,
  selectorsExtractIds,
  selectorsOutputEntitiesByIds(() => entitySelectors),
);

export const selectTableExperiences = createSelector(
  selectBaseState,
  selectTableExperienceIds,
  selectorsOutputEntitiesByIds(() => entitySelectors),
);

export const selectSelectedExperiences = createSelector(
  selectBaseState,
  selectSelectedExperienceIds,
  selectorsOutputEntitiesByIds(() => entitySelectors),
);

export const selectAggregatedExperienceByIds = createSelector(selectBaseState, selectorsExtractIds, (state, ids) =>
  ids?.length ? getAggregatedModel(selectorsOutputEntitiesByIds(entitySelectors)(state, ids)) : null,
);

export const selectAggregatedExperienceForSelectedEntities = createSelector(
  selectSelectedExperiences,
  selectedExperiences => getAggregatedModel(selectedExperiences),
);

export const selectExperiencesByLocationIds = createSelector(
  selectBaseState,
  selectorsExtractIds,
  (state, locationIds) => {
    const normalizedLocationIds = uniq(locationIds as number[]);
    return entitySelectors.selectAll(state).filter(({ location_id }) => normalizedLocationIds.includes(location_id));
  },
);
