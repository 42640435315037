import { cloneDeep } from "lodash";
import { replace } from "redux-first-history";
import { call, put, race, select, take, takeLatest, takeLeading, type SagaReturnType } from "redux-saga/effects";

import { authActions, AuthRemoteOperation } from "@ds/modules/auth/redux/slice";
import { iotEventUserUpdate, iotSubscribe } from "@ds/modules/iot/redux/actions";
import { toastShowErrorAction, toastShowSuccessAction } from "@ds/modules/notifications/redux/actions";
import { isIotTableEntityAddedOrDeleted, selectTableData } from "@ds/modules/table-data/redux/selectors";
import { convertToApiQueryInfo } from "@ds/modules/table-data/utils/common";
import { MainTableDataTypeEnum } from "@ds/modules/table-data/utils/model";

import { UnexpectedError } from "@ds/utils/errors";
import { logger } from "@ds/utils/logger";
import { UNLIMITED_PAGINATION } from "@ds/utils/query";
import { getCurrentRootPath } from "@ds/utils/router";
import { takeLatestOrEvery } from "@ds/utils/saga-helpers";
import { isErrorLike } from "@ds/utils/type-guards/error-guards";

import { usersService } from "../utils/api";
import { isApiUserQueryOutput } from "../utils/model";
import { normalize } from "../utils/normalizer";
import { selectCurrentUser, selectTableUsers, selectUserById, selectUsersByIds } from "./selectors";
import { usersActions, UsersRemoteOperation } from "./slice";

function* fetchUsers({ meta }: ReturnType<typeof usersActions.fetchUsers>) {
  try {
    const { sorting, pagination, queryInfo, isFetchedAlready } = selectTableData(
      yield select(),
      meta.options.tableType || MainTableDataTypeEnum.Users,
      undefined,
      meta.filters,
    );

    const newMeta = cloneDeep(meta);
    const resultFromCache: QueryOutput<User> = { items: [] };

    if (!newMeta.options.cache?.disableCache) {
      if (isFetchedAlready && newMeta.options.tableType) {
        resultFromCache.items = selectTableUsers(yield select(), newMeta.options.tableType);
        newMeta.options.cache = {
          ...newMeta.options.cache,
          fetchedFromCache: true,
        };
      } else if (newMeta.filters.id && Object.keys(newMeta.filters).length === 1) {
        resultFromCache.items = selectUsersByIds(yield select(), newMeta.filters.id);
        if (resultFromCache.items.length === newMeta.filters.id.length) {
          newMeta.options.cache = {
            ...newMeta.options.cache,
            fetchedFromCache: true,
          };
        }
      }
    }

    let result: QueryOutput<User> | QueryOutput<ApiUser> = newMeta.options.cache?.fetchedFromCache
      ? resultFromCache
      : yield call(
          [usersService, usersService.getUsers],
          convertToApiQueryInfo(sorting, queryInfo),
          newMeta.options.tableType ? pagination : UNLIMITED_PAGINATION,
        );

    result = isApiUserQueryOutput(result) ? normalize(result) : result;
    result = newMeta.options.tableType ? result : { items: result.items };

    yield put(usersActions.fetchUsersSucceeded(result, newMeta));
  } catch (err) {
    const errorTitle = "Fetch users";
    if (isErrorLike(err)) {
      yield put(usersActions.fetchUsersFailed(meta, err));
      yield put(toastShowErrorAction(err, errorTitle));
    } else {
      logger.error(`${errorTitle}: ${UnexpectedError}`);
      yield put(toastShowErrorAction(UnexpectedError, errorTitle));
    }
  }
}

function* fetchUser({ payload, meta }: ReturnType<typeof usersActions.fetchUser>) {
  try {
    yield call([usersService, usersService.putIoTPolicy]);
  } catch (err) {
    if ([403, 500].includes(Number((err as ApiError).status)) && meta.remoteOperation === UsersRemoteOperation.AUTH) {
      window.console.clear();
      yield put(authActions.signOut());
      return;
    }
  }

  try {
    const newMeta = cloneDeep(meta);
    let result: User | ApiUser = selectUserById(yield select(), payload) as ApiUser;
    if (result && !newMeta.options.cache?.disableCache) {
      newMeta.options.cache = {
        ...newMeta.options.cache,
        fetchedFromCache: true,
      };
    } else {
      result = yield call([usersService, usersService.getUser], payload);
      result = normalize(result as ApiUser);
    }

    yield put(usersActions.fetchUserSucceeded(result, newMeta));
    yield put(iotSubscribe());
  } catch (err) {
    const errorTitle = "Fetch user";
    if (isErrorLike(err)) {
      yield put(usersActions.fetchUserFailed(payload, meta, err));
      yield put(toastShowErrorAction(err, errorTitle));
    } else {
      logger.error(`${errorTitle}: ${UnexpectedError}`);
      yield put(toastShowErrorAction(UnexpectedError, errorTitle));
    }
  }
}

function* updateUsers({ payload }: ReturnType<typeof usersActions.updateUsers>) {
  try {
    const currentUser = selectCurrentUser(yield select());
    const result: SagaReturnType<typeof usersService.updateUsers> = yield call(
      [usersService, usersService.updateUsers],
      payload.ids,
      payload.data,
    );

    yield put(usersActions.updateUsersSucceeded(normalize(result)));

    if (result.map(({ id }) => id).includes(currentUser?.id || 0)) {
      yield put(authActions.fetchAuthenticatedCognitoUser(true, AuthRemoteOperation.REFRESH));
    }

    yield put(toastShowSuccessAction("User(s) were updated successfully"));
  } catch (err) {
    const errorTitle = "Update user(s)";
    if (isErrorLike(err)) {
      yield put(usersActions.updateUsersFailed(payload, err));
      yield put(toastShowErrorAction(err, errorTitle));
    } else {
      logger.error(`${errorTitle}: ${UnexpectedError}`);
      yield put(toastShowErrorAction(UnexpectedError, errorTitle));
    }
  }
}

function* updateUserCurrentProjectId({ payload }: ReturnType<typeof usersActions.updateUserCurrentProjectId>) {
  try {
    const currentUser = selectCurrentUser(yield select());
    if (!currentUser) {
      throw new Error("Current user is undefined");
    }

    const result: SagaReturnType<typeof usersService.updateUsers> = yield call(
      [usersService, usersService.updateUsers],
      [currentUser.id],
      { current_project_id: payload },
    );

    yield put(replace(getCurrentRootPath()));
    yield put(usersActions.updateUserCurrentProjectIdSucceeded(normalize(result[0])));
    yield put(toastShowSuccessAction("Project successfully changed"));
    yield put(iotSubscribe());
  } catch (err) {
    const errorTitle = "Switch project";
    if (isErrorLike(err)) {
      yield put(usersActions.updateUserCurrentProjectIdFailed(payload, err));
      yield put(toastShowErrorAction(err, errorTitle));
    } else {
      logger.error(`${errorTitle}: ${UnexpectedError}`);
      yield put(toastShowErrorAction(UnexpectedError, errorTitle));
    }
  }
}

function* deleteUsers({ payload, meta }: ReturnType<typeof usersActions.deleteUsers>) {
  try {
    yield call([usersService, usersService.deleteUsers], payload);
    yield put(usersActions.deleteUsersSucceeded(payload, meta));
    yield put(toastShowSuccessAction("User(s) were deleted successfully"));

    if (meta.options?.redirectTo) {
      yield put(replace(meta.options.redirectTo));
    }
  } catch (err) {
    const errorTitle = "Delete user(s)";
    if (isErrorLike(err)) {
      yield put(usersActions.deleteUsersFailed(payload, meta, err));
      yield put(toastShowErrorAction(err, errorTitle));
    } else {
      logger.error(`${errorTitle}: ${UnexpectedError}`);
      yield put(toastShowErrorAction(UnexpectedError, errorTitle));
    }
  }
}

function* refetchUsers({ payload }: ReturnType<typeof iotEventUserUpdate>) {
  const isAddedOrDeleted = isIotTableEntityAddedOrDeleted(yield select(), payload, MainTableDataTypeEnum.Users);
  if (isAddedOrDeleted) {
    yield call(
      fetchUsers,
      usersActions.fetchUsers(
        undefined,
        {
          tableType: MainTableDataTypeEnum.Users,
          cache: { disableCache: true },
        },
        UsersRemoteOperation.IOT_FETCH,
      ),
    );
  }

  yield put(usersActions.iotUpdate(payload));
}

export function* watchUsers() {
  yield takeLatestOrEvery(usersActions.fetchUsers, function* (action) {
    yield race({
      task: call(fetchUsers, action),
      cancel: take([usersActions.selectUsers, usersActions.toggleSelected]),
    });
  });

  yield takeLatestOrEvery(usersActions.fetchUser, fetchUser);
  yield takeLeading(usersActions.updateUsers, updateUsers);
  yield takeLeading(usersActions.deleteUsers, deleteUsers);
  yield takeLeading(usersActions.updateUserCurrentProjectId, updateUserCurrentProjectId);
  yield takeLatest(iotEventUserUpdate, refetchUsers);
}
