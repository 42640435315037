import { type DeepPartial } from "react-hook-form";

import { type SagaOptions } from "@ds/utils/saga-helpers";

export type BaseMeta = {
  options?: SagaOptions;
};

export type PatchedModel<T> = { ids: number[] | string[]; data: DeepPartial<T> };

export enum LoadStatus {
  Idle = "idle",
  Loading = "loading",
  Failed = "failed",
  Succeeded = "succeeded",
}
