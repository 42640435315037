import { Calendar as PrimeCalendar } from "primereact/calendar";
import styled from "styled-components";

export type { CalendarChangeEvent } from "primereact/calendar";

export const Calendar = styled(PrimeCalendar)`
  span:active,
  span:focus,
  button:active,
  button:focus {
    box-shadow: var(--button-light-box-shadow) !important;
  }

  td > span,
  button:not(.p-datepicker-month):not(.p-datepicker-year) {
    :hover {
      background: var(--button-light-background) !important;
    }
  }

  .p-yearpicker-year,
  .p-monthpicker-month {
    :hover {
      background: var(--button-light-background) !important;
    }
  }

  .p-datepicker-today > span {
    background: var(--button-dark-background) !important;
  }
`;
