import { useCallback, type FC } from "react";
import { useForm } from "react-hook-form";

import { deploymentsActions } from "@ds/modules/deployments/redux/slice";

import { WizardPage, type WizardChildStepProps } from "@ds/components/wizard";
import { useRootDispatch } from "@ds/hooks";

import { DeploymentScheduleDateControl } from "../controls/deployment-schedule-date-control";

export const Step1Calendar: FC<
  WizardChildStepProps & {
    deployments: Deployment[];
  }
> = ({ deployments, nextStep, ...rest }) => {
  const dispatch = useRootDispatch();
  const { control, getValues, handleSubmit } = useForm<{ startAt: Date }>({
    mode: "onSubmit",
    reValidateMode: "onChange",
    defaultValues: {
      startAt: new Date(),
    },
  });

  const onNextClickHandler = useCallback(() => {
    const { startAt } = getValues();
    dispatch(deploymentsActions.scheduleDeployments(deployments, startAt));
    if (nextStep) {
      nextStep();
    }
  }, [dispatch, nextStep, deployments, getValues]);

  return (
    <WizardPage
      {...rest}
      title="Schedule Deployments"
      nextStep={handleSubmit(onNextClickHandler)}
      nextButtonText="Schedule"
    >
      <DeploymentScheduleDateControl control={control} />
    </WizardPage>
  );
};
