import { type FC } from "react";
import { useWatch, type Control, type UseFormSetValue } from "react-hook-form";
import styled from "styled-components";

import { GoogleSearchTypesEnum } from "@ds/services/google-api";

import { LocationMap } from "../common/location-map";
import { AddressAutoCompleteControl } from "./controls/address-autocomplete-control";
import { AddressCityControl } from "./controls/address-city-control";
import { AddressStateControl } from "./controls/address-state-control";
import { AddressStreet2Control } from "./controls/address-street-2-control";
import { AddressZipControl } from "./controls/address-zip-control";

const Wrapper = styled.div`
  input {
    width: 100%;
  }
`;

export const LocationAddressForm: FC<{
  control: Control<Locality>;
  setValue: UseFormSetValue<Locality>;
}> = ({ control, setValue }) => {
  const address = useWatch({ name: "address", control });

  return (
    <Wrapper className="grid flex-column col-8">
      <AddressAutoCompleteControl
        control={control}
        setValue={setValue}
        name="address.organization"
        label="Search Business"
        placeholder="Enter business name"
        searchType={GoogleSearchTypesEnum.ESTABLISHMENT}
      />

      <AddressAutoCompleteControl
        control={control}
        setValue={setValue}
        name="address.street_address.0"
        label="Address Line 1"
        placeholder="Street address or P.O. Box"
        searchType={GoogleSearchTypesEnum.ADDRESS}
      />

      <AddressStreet2Control control={control} />

      <div className="grid p-justify-between">
        <AddressCityControl control={control} />
        <AddressStateControl control={control} />
        <AddressZipControl control={control} />
      </div>

      <LocationMap coordinates={address?.coordinates} />
    </Wrapper>
  );
};
