import { DSApi } from "@ds/services/ds-api";
import { type ApiService, type ApiServiceConfig } from "@ds/services/types";
import { parseQueries } from "@ds/utils/query";

interface PeripheralsServiceConfig extends ApiServiceConfig {
  api: DSApi;
}

class PeripheralsService implements ApiService {
  private config: PeripheralsServiceConfig = {
    api: {} as DSApi,
  };

  configure(cfg: PeripheralsServiceConfig) {
    this.config = { ...cfg };
  }

  async getPeripherals(queryInfo: QueryInfo, pagination: Pagination) {
    const queries = parseQueries(queryInfo, pagination);
    return this.config.api.get<QueryOutput<ApiPeripheral>>(`/peripherals/`, queries);
  }

  async getPeripheral(peripheralId: number) {
    return this.config.api.get<ApiPeripheral>(`/peripherals/${peripheralId}`);
  }
}

export const peripheralsService = new PeripheralsService();
