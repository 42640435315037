import { type EnhancedStore } from "@reduxjs/toolkit";
import { StrictMode, type FC, type ReactNode } from "react";
import { Provider } from "react-redux";
import { HistoryRouter as Router } from "redux-first-history/rr6";

import { rootHistory, rootStore, type RootState } from "@ds/store/bootstrap";

import { App } from "./app";

export const RootProviders: FC<{
  store: EnhancedStore<RootState>;
  history: typeof rootHistory;
  children: ReactNode;
}> = ({ children, store, history }) => (
  <Provider store={store}>
    <Router history={history}>
      <StrictMode>{children}</StrictMode>
    </Router>
  </Provider>
);

export const Root: FC = () => (
  <RootProviders store={rootStore} history={rootHistory}>
    <App />
  </RootProviders>
);
