import { type DeepPartial } from "react-hook-form";

import { DSApi } from "@ds/services/ds-api";
import { type ApiService, type ApiServiceConfig } from "@ds/services/types";
import { parseQueries } from "@ds/utils/query";

interface UsersServiceConfig extends ApiServiceConfig {
  api: DSApi;
}

class UsersService implements ApiService {
  config: UsersServiceConfig = {
    api: {} as DSApi,
  };

  configure(cfg: UsersServiceConfig) {
    this.config = { ...cfg };
  }

  async getUsers(queryInfo: QueryInfo, pagination: Pagination) {
    const queries = parseQueries(queryInfo, pagination);
    return this.config.api.get<QueryOutput<ApiUser>>("/users/", queries);
  }

  async getUser(userId: number) {
    return this.config.api.get<ApiUser>(`/users/${userId}`);
  }

  async updateUsers(ids: number[], data: DeepPartial<User>) {
    return this.config.api.patch<ApiUser>("/users/", { ids, data });
  }

  async deleteUsers(ids: number[]) {
    return this.config.api.delete<ApiUser>("/users/", { ids });
  }

  async putIoTPolicy(): Promise<void> {
    return this.config.api.put(`/policies/iot`);
  }
}

export const usersService = new UsersService();
