import { type FC, type ReactNode } from "react";
import styled from "styled-components";

const DataGridWrapper = styled.div`
  height: calc(100vh - 300px);
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(225px, 1fr));
  grid-auto-rows: 225px;
  grid-gap: 20px;
  color: var(--textColor080);
  overflow: overlay;
  padding-top: 8px;
`;

export const MainDataGrid: FC<{ children: ReactNode }> = ({ children }) => (
  <DataGridWrapper>{children}</DataGridWrapper>
);
