import { useCallback, useEffect, useRef, type FC, type SyntheticEvent } from "react";
import { useNavigate } from "react-router-dom";
import styled from "styled-components";

import { useVisibility } from "@ds/hooks/use-visibility";

import { selectCurrentUserProject } from "@ds/modules/settings/projects/redux/selectors/common-selectors";
import { SwitchProjectModal } from "@ds/modules/settings/users/components/forms/switch-project-modal";
import { selectUsersIsSucceeded } from "@ds/modules/settings/users/redux/selectors";

import { Button, ButtonType } from "@ds/components/forms/buttons";
import { IconType } from "@ds/components/icons";
import { AbsoluteLoader } from "@ds/components/loaders/loading";
import { DropdownMenu } from "@ds/components/menu-dropdown/controls";
import { type ActionMenu } from "@ds/components/menu-dropdown/types";
import { useRootSelector } from "@ds/hooks";
import { composeDetailsRouteByEntityType } from "@ds/utils/router";

const HeaderTitle = styled.h1`
  display: flex;
  align-items: center;
  padding: 0 8px;
  line-height: 1.2rem;
  font-size: 1.2rem;
  font-weight: 600;
  color: var(--textColor070);

  > i.pi {
    margin: 0 12px;
    font-weight: inherit;
    font-size: inherit;
    line-height: inherit;
  }
`;

export const HeaderLeft: FC = () => {
  const navigate = useNavigate();
  const [isSwitchProjectsModalVisible, setIsSwitchProjectsModalVisible] = useVisibility();

  const menuRef = useRef<ActionMenu>(null);
  const currentUserProject = useRootSelector(selectCurrentUserProject);
  const isSucceeded = useRootSelector(selectUsersIsSucceeded);

  const onMenuClickHandler = useCallback((e: SyntheticEvent) => menuRef.current?.toggle(e), []);

  useEffect(() => {
    if (isSucceeded) {
      setIsSwitchProjectsModalVisible(false)();
    }
  }, [isSucceeded, setIsSwitchProjectsModalVisible]);

  if (!currentUserProject) {
    return <AbsoluteLoader isLoading />;
  }

  return (
    <>
      <Button ariaLabel="menu button" buttonType={ButtonType.Round} icon={IconType.Bars} onClick={onMenuClickHandler} />
      <HeaderTitle id="page-breadcrumbs" />
      <DropdownMenu
        ref={menuRef}
        popup
        model={[
          {
            label: "Switch Project",
            icon: "pi pi-arrow-right-arrow-left",
            command: setIsSwitchProjectsModalVisible(true),
          },
          {
            label: "Project Settings",
            icon: "pi pi-server",
            command: () => navigate(composeDetailsRouteByEntityType(currentUserProject)),
          },
        ]}
      />

      {isSwitchProjectsModalVisible && (
        <SwitchProjectModal currentProjectId={currentUserProject.id} onClose={setIsSwitchProjectsModalVisible(false)} />
      )}
    </>
  );
};
