import { nanoid } from "@reduxjs/toolkit";
import { type DeepMap, type DeepPartial, type Path } from "react-hook-form";

import { deepKeys, getProperty, setProperty } from "./properties";

export const getValuesByBooleanMap = <T, K extends keyof T>(
  dirtyFields: Partial<DeepMap<DeepPartial<T>, boolean>> | boolean,
  allValues: T | T[K],
  innerId?: string,
): DeepPartial<T> => {
  if (dirtyFields === true) {
    return allValues as DeepPartial<T>;
  }

  if (Array.isArray(dirtyFields)) {
    if (dirtyFields.every(item => item === true)) {
      return allValues as DeepPartial<T>;
    }
  }

  const randomId = innerId || nanoid();
  const keyValues = Object.keys(dirtyFields)
    .map(key => {
      const booleanMapLeaf = (dirtyFields as Record<string, boolean>)[key];
      if (!booleanMapLeaf || !allValues) {
        return undefined;
      }

      const allValuesLeaf = (allValues as unknown as Record<string, T[keyof T]>)[key];
      const innerResult = getValuesByBooleanMap(booleanMapLeaf, allValuesLeaf, randomId);
      if (innerResult === (randomId as unknown as DeepPartial<T>)) {
        return undefined;
      }

      return [key, innerResult];
    })
    .flatMap(rule => (rule === undefined ? [] : [rule]));

  return keyValues.length ? (Object.fromEntries(keyValues) as DeepPartial<T>) : (innerId as unknown as DeepPartial<T>);
};

export const normalizeChangedFieldsBeforeUpdate = <T extends DeepPartial<AggregatedEntity<Entity>>>(obj: T) => {
  const result: T = obj;
  if (!obj.metadata?.proxyFields) {
    return obj;
  }

  const proxyFieldsPaths = deepKeys(obj.metadata.proxyFields);
  proxyFieldsPaths.forEach(proxyFieldPath => {
    const proxyFieldValue = getProperty(result, `metadata.proxyFields.${proxyFieldPath}`);
    setProperty(result, proxyFieldPath, proxyFieldValue);
  });

  delete result.metadata;
  return result;
};

export const normalizeFormationFieldNameBeforeEdit = <T>(fieldName: Path<T>): Path<T> =>
  (fieldName.includes("formation.properties") ? `${fieldName}.value` : fieldName) as Path<T>;
