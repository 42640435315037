import { some } from "lodash";
import { useCallback, type FC } from "react";
import { type DeepPartial } from "react-hook-form";

import { useGetAggregatedEntityIds } from "@ds/hooks/use-get-aggregated-entity-ids";
import { useParseQueryIds } from "@ds/hooks/use-parse-query-ids";
import { useReduxEntitiesFetch } from "@ds/hooks/use-redux-entities-fetch";

import { EntityTypeName } from "@ds/model/entity-model";

import { DetailsView } from "@ds/components/details-view";
import { AbsoluteLoader } from "@ds/components/loaders/loading";
import { DEPLOYMENT_ID_PARAM } from "@ds/constants/router";
import { QueryParamType, useDeepRootSelector, useRootDispatch, useRootSelector } from "@ds/hooks";
import { pluralizeTotalSelectedTitle } from "@ds/utils/localization/common";
import { isMultiple } from "@ds/utils/numbers";

import { MainTableDataTypeEnum } from "../table-data/utils/model";
import { DeploymentDetailsCodeOrFirmwareCard } from "./components/details/deployment-details-code-or-firmware-card";
import { DeploymentDetailsContentCard } from "./components/details/deployment-details-content-card";
import { DeploymentDetailsInfoCard } from "./components/details/deployment-details-info-card";
import { DeploymentDetailsSyncsCard } from "./components/details/deployment-details-syncs-card";
import { selectAggregatedDeploymentByIds, selectDeploymentsByIds, selectDeploymentsIsLoading } from "./redux/selectors";
import { deploymentsActions } from "./redux/slice";
import { DeploymentTypeEnum } from "./utils/model";

export const DeploymentDetailsPage: FC = () => {
  const queryIds = useParseQueryIds(DEPLOYMENT_ID_PARAM, QueryParamType.Number);

  const deployment = useDeepRootSelector(state => selectAggregatedDeploymentByIds(state, queryIds));
  const aggregatedDeploymentIds = useGetAggregatedEntityIds(deployment);
  const aggregatedDeployments = useDeepRootSelector(state => selectDeploymentsByIds(state, queryIds));

  const isLoading = useRootSelector(selectDeploymentsIsLoading);

  const [toggleRefetch] = useReduxEntitiesFetch(
    { entityType: EntityTypeName.DEPLOYMENT, filters: { id: queryIds } },
    { initialLoad: true },
  );

  const dispatch = useRootDispatch();
  const onSubmitHandler = useCallback(
    (changedFields: DeepPartial<Deployment>) =>
      dispatch(deploymentsActions.updateDeployments(aggregatedDeploymentIds, changedFields)),
    [dispatch, aggregatedDeploymentIds],
  );

  if (!deployment) {
    return <AbsoluteLoader isLoading />;
  }

  return (
    <DetailsView
      title={
        isMultiple(deployment?.metadata?.totalAggregated)
          ? pluralizeTotalSelectedTitle("deployment", deployment?.metadata?.totalAggregated)
          : deployment.name
      }
      tableType={MainTableDataTypeEnum.Deployments}
      isLoading={isLoading}
      onRefresh={toggleRefetch}
    >
      <DeploymentDetailsInfoCard deployment={deployment} onSubmit={onSubmitHandler} />
      <DeploymentDetailsSyncsCard deployment={deployment} />
      {some(aggregatedDeployments, ["type", DeploymentTypeEnum.Content]) && (
        <DeploymentDetailsContentCard deployment={deployment} />
      )}

      {some(aggregatedDeployments, ["type", DeploymentTypeEnum.Code]) && (
        <DeploymentDetailsCodeOrFirmwareCard deployment={deployment} deploymentType={DeploymentTypeEnum.Code} />
      )}

      {some(aggregatedDeployments, ["type", DeploymentTypeEnum.Firmware]) && (
        <DeploymentDetailsCodeOrFirmwareCard deployment={deployment} deploymentType={DeploymentTypeEnum.Firmware} />
      )}
    </DetailsView>
  );
};
