import { nanoid } from "@reduxjs/toolkit";
import { useCallback, type ComponentProps, type FC } from "react";

import { DeploymentPlayersAutoComplete } from "@ds/modules/deployments/components/deployment-players-autocomplete";
import { getPlayerStatusIndicatorWithName } from "@ds/modules/devices/players/components/common/status-indicator";
import { selectPlayersByIds } from "@ds/modules/devices/players/redux/selectors";

import { AutoCompleteMultipleDecorator } from "@ds/components/forms/autocomplete";
import { useRootSelector } from "@ds/hooks";

import { Wrapper } from "./styles";
import { type DeploymentTargetsProps } from "./types";

export const DeviceTargets: FC<DeploymentTargetsProps> = ({ inputRef, value, onChange }) => {
  const players = useRootSelector(state => selectPlayersByIds(state, value));

  const onChangeHandler = useCallback(
    (entities: Player[]) => {
      const ids = entities.map(({ id }) => id);
      onChange(ids);
    },
    [onChange],
  );

  return (
    <Wrapper>
      <AutoCompleteMultipleDecorator
        inputRef={inputRef}
        value={players}
        onChange={onChangeHandler}
        chipsMapper={(player: Player) => [
          `deployment-player-chip-${nanoid()}`,
          player.name,
          {
            template: getPlayerStatusIndicatorWithName(player),
          },
        ]}
      >
        {(props: ComponentProps<typeof DeploymentPlayersAutoComplete>) => <DeploymentPlayersAutoComplete {...props} />}
      </AutoCompleteMultipleDecorator>
    </Wrapper>
  );
};
