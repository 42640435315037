import { type FC, type ReactNode } from "react";

import { MainDataGrid } from "@ds/components/grid/main-data-grid";

import { MainTablePaginator } from "../paginator/main-table-paginator";
import { type PaginatorProps } from "../paginator/paginator";

const GridWrapper: FC<{ children: ReactNode }> = ({ children }) => (
  <div className="h-full flex flex-column justify-content-between fadein animation-duration-300">{children}</div>
);

export const GridWithPagination: FC<PaginatorProps & { children: ReactNode }> = ({
  pagination,
  onPageChange,
  children,
}) => (
  <GridWrapper>
    <MainDataGrid>{children}</MainDataGrid>
    <MainTablePaginator pagination={pagination} onPageChange={onPageChange}></MainTablePaginator>
  </GridWrapper>
);
