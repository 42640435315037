import { isEqual } from "lodash";
import { useDispatch, useSelector, type TypedUseSelectorHook } from "react-redux";

import { type RootDispatch, type RootState } from "@ds/store/bootstrap";

export const useRootDispatch: () => RootDispatch = useDispatch;

export const useRootSelector: TypedUseSelectorHook<RootState> = useSelector;
export const useParamsSelector = <TSelected, TParams extends unknown[]>(
  selector: (state: RootState, ...params: TParams) => TSelected,
  ...params: TParams
) => useRootSelector((state: RootState) => selector(state, ...params)) as TSelected;

export const useDeepRootSelector = <TSelected>(selector: (state: RootState) => TSelected): TSelected =>
  useRootSelector(selector, isEqual);
export const useDeepParamsSelector = <TSelected, TParams extends unknown[]>(
  selector: (state: RootState, ...params: TParams) => TSelected,
  ...params: TParams
) => useDeepRootSelector((state: RootState) => selector(state, ...params)) as TSelected;
