import { useEffect, useMemo, type FC } from "react";

import { useGetAggregatedEntityIds } from "@ds/hooks/use-get-aggregated-entity-ids";

import { isAggregatedEntity } from "@ds/model/entity-model";

import { getPlayerStatusIndicator } from "@ds/modules/devices/players/components/common/status-indicator";
import { selectPlayersIsLoading, selectTablePlayers } from "@ds/modules/devices/players/redux/selectors";
import { playersActions } from "@ds/modules/devices/players/redux/slice";
import { selectExperiencesIsLoading } from "@ds/modules/experiences/redux/selectors";
import { experiencesActions } from "@ds/modules/experiences/redux/slice";
import { DEFAULT_TABLE_TAB } from "@ds/modules/table-data/redux/slice";
import { DetailsTableDataTypeEnum } from "@ds/modules/table-data/utils/model";

import { DetailsCard } from "@ds/components/details-view";
import { MultipleValuesLabelWithLink, UnassignedLabel } from "@ds/components/forms/labels/common";
import { Loading } from "@ds/components/loaders/loading";
import { DetailsTable } from "@ds/components/table/details-table";
import {
  Column,
  getEntityNameColumn,
  getGoToDetailsColumn,
  getLastUpdatedColumn,
  getStatusColumn,
} from "@ds/components/table/table-columns";
import { usePagination, useRootDispatch, useRootSelector, useTableDataSorting } from "@ds/hooks";
import { composeDetailsRouteByEntityType } from "@ds/utils/router";

export const LocationDetailsPlayersCard: FC<{ location: Locality }> = ({ location }) => {
  const aggregatedLocationIds = useGetAggregatedEntityIds(location);
  const entities = useRootSelector(state => selectTablePlayers(state, DetailsTableDataTypeEnum.LocationPlayers));

  const isLoading = useRootSelector(state => selectExperiencesIsLoading(state) || selectPlayersIsLoading(state));

  const [pagination, setPagination] = usePagination(
    DetailsTableDataTypeEnum.LocationPlayers,
    isAggregatedEntity(location) ? DEFAULT_TABLE_TAB : location.id.toString(),
    {
      locationId: aggregatedLocationIds,
    },
  );

  const [{ sortOrder, sortField }, setSorting] = useTableDataSorting(
    DetailsTableDataTypeEnum.LocationPlayers,
    isAggregatedEntity(location) ? DEFAULT_TABLE_TAB : location.id.toString(),
    {
      locationId: aggregatedLocationIds,
    },
  );

  const dispatch = useRootDispatch();
  useEffect(() => {
    if (aggregatedLocationIds.length) {
      dispatch(experiencesActions.fetchExperiences({ locationId: aggregatedLocationIds }));
      dispatch(
        playersActions.fetchPlayers(
          { locationId: aggregatedLocationIds },
          { tableType: DetailsTableDataTypeEnum.LocationPlayers },
        ),
      );
    }
  }, [dispatch, aggregatedLocationIds]);

  const emptyLabel = useMemo(
    () =>
      entities.length ? (
        <MultipleValuesLabelWithLink to={composeDetailsRouteByEntityType(entities)} />
      ) : (
        <UnassignedLabel text="Location(s) has no players" />
      ),
    [entities],
  );

  return (
    <DetailsCard title="Players" icon="desktop">
      <Loading isLoading={isLoading}>
        {!isAggregatedEntity(location) && (
          <DetailsTable
            value={entities}
            pagination={pagination}
            onPageChange={setPagination}
            loading={isLoading}
            sortField={sortField}
            sortOrder={sortOrder}
            onSort={setSorting}
          >
            {getEntityNameColumn({ style: { flexGrow: 3 }, resizeable: false })}
            <Column field="applet_name" header="Applet" className="w250" resizeable={false} />

            {getStatusColumn({ field: "state", body: getPlayerStatusIndicator })}
            {getLastUpdatedColumn()}
            {getGoToDetailsColumn()}
          </DetailsTable>
        )}

        {isAggregatedEntity(location) && emptyLabel}
      </Loading>
    </DetailsCard>
  );
};
