import { useCallback, type ComponentProps, type FC } from "react";
import { Controller, type Control, type UseFormSetValue } from "react-hook-form";

import { ProjectsAutoComplete } from "@ds/modules/settings/projects/components/projects-autocomplete";

import { useDetailsInlineSaving } from "@ds/components/details-view/hooks";
import { AutoCompleteMultipleDecorator } from "@ds/components/forms/autocomplete";
import { LabelWrapper } from "@ds/components/forms/labels";
import { TextLink } from "@ds/components/text-link";
import { useOnMouseEventPreventDefaultHandler } from "@ds/utils/handlers";
import { composeDetailsRouteByEntityType } from "@ds/utils/router";

export const InvitationProjectsControl: FC<{
  control: Control<InvitationFormData>;
  setValue?: UseFormSetValue<InvitationFormData>;
  autoFocus?: boolean;
  excludeLabel?: boolean;
  onBlur?: () => void;
}> = ({ control, setValue, autoFocus, excludeLabel, onBlur }) => {
  const onBlurHandler = useDetailsInlineSaving(onBlur);
  const onMouseDownHandler = useOnMouseEventPreventDefaultHandler();

  const onChangeHandler = useCallback(
    (onChange: (inputData: Project[]) => void) => (inputData: Project[]) => {
      if (setValue) {
        setValue(
          "invitation.project_ids",
          inputData.map(({ id }) => id),
          {
            shouldDirty: true,
            shouldTouch: true,
            shouldValidate: true,
          },
        );
      }

      onChange(inputData);
    },
    [setValue],
  );

  return (
    <Controller
      name="projects"
      control={control}
      render={({ field: { ref, value, onChange, onBlur: onBlurFn, ...restFields }, fieldState }) => {
        const element = (
          <AutoCompleteMultipleDecorator
            inputRef={ref}
            value={value}
            chipsMapper={(project: Project) => [
              `invitation-project-chip-${project.id}`,
              project.name,
              {
                template: <TextLink to={composeDetailsRouteByEntityType(project)}>{project.name}</TextLink>,
              },
            ]}
            onChange={onChangeHandler(onChange)}
            onBlur={onBlurHandler(onBlurFn)}
            onMouseDown={onMouseDownHandler}
            {...restFields}
          >
            {(props: ComponentProps<typeof ProjectsAutoComplete>) => (
              <ProjectsAutoComplete autoFocus={autoFocus} {...props} />
            )}
          </AutoCompleteMultipleDecorator>
        );

        if (excludeLabel) {
          return element;
        }

        return (
          <LabelWrapper label="Projects" className="col-8" error={fieldState.error}>
            {element}
          </LabelWrapper>
        );
      }}
    />
  );
};
