import { type FC } from "react";
import { Controller, type Control } from "react-hook-form";

import { InputText } from "@ds/components/forms/inputs";
import { LabelWrapper } from "@ds/components/forms/labels";

export const AddressCityControl: FC<{
  control: Control<Locality>;
  excludeLabel?: boolean;
  autoFocus?: boolean;
}> = ({ control, excludeLabel, autoFocus }) => (
  <Controller
    name="address.locality"
    control={control}
    render={({ field: { ref, ...restFields }, fieldState }) => {
      const element = <InputText inputRef={ref} autoFocus={autoFocus} isReadonly {...restFields} />;
      if (excludeLabel) {
        return element;
      }

      return (
        <LabelWrapper label="City" className="col-5" error={fieldState.error}>
          {element}
        </LabelWrapper>
      );
    }}
  />
);
