export const snakify = (str: string) =>
  str
    .split(/(?=[A-Z])/)
    .join("_")
    .toLowerCase();

export const snakifyProps = (obj: Record<string, unknown>) =>
  Object.fromEntries(Object.entries(obj).map(([k, v]) => [snakify(k), v]));

export const convertToUpperCaseFirst = (str: string) =>
  str.length ? `${str[0].toUpperCase()}${str.substring(1)}` : "";

export const trimAll = (str: string) => str.trim().replace(/\s\s+/g, " ");
