import { type FC } from "react";
import { Navigate, Route, Routes } from "react-router-dom";

import { SETTINGS_INVITATIONS_SEGMENT, SETTINGS_PROJECTS_SEGMENT, SETTINGS_USERS_SEGMENT } from "@ds/constants/router";
import { useRootSelector } from "@ds/hooks";

import { InvitationsRouter } from "./invitations/invitations-router";
import { ProjectsRouter } from "./projects/projects-router";
import { selectCurrentView } from "./redux/selectors";
import { selectCurrentUserIsTenantAdmin } from "./users/redux/selectors";
import { UsersRouter } from "./users/users-router";

const SettingsRouteSwitch = () => {
  const currentView = useRootSelector(selectCurrentView);
  if (currentView) {
    return <Navigate to={currentView} replace />;
  }

  return <Navigate to={SETTINGS_PROJECTS_SEGMENT} replace />;
};

export const SettingsRouter: FC = () => {
  const isAdmin = useRootSelector(selectCurrentUserIsTenantAdmin);
  return (
    <Routes>
      <Route path="*" element={<SettingsRouteSwitch />} />
      <Route path={`${SETTINGS_PROJECTS_SEGMENT}/*`} element={<ProjectsRouter />} />
      <Route path={`${SETTINGS_USERS_SEGMENT}/*`} element={<UsersRouter />} />
      {isAdmin && <Route path={`${SETTINGS_INVITATIONS_SEGMENT}/*`} element={<InvitationsRouter />} />}
    </Routes>
  );
};
