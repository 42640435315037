import { type FC } from "react";
import { Controller, useWatch, type Control } from "react-hook-form";

import { InputText } from "@ds/components/forms/inputs";
import { LabelWrapper } from "@ds/components/forms/labels";
import { AddressCountry } from "@ds/services/google-api";

export const AddressZipControl: FC<{
  control: Control<Locality>;
  excludeLabel?: boolean;
  autoFocus?: boolean;
}> = ({ control, excludeLabel, autoFocus }) => {
  const currentCountry = useWatch({ name: "address.country", control });

  return (
    <Controller
      name="address.post_code"
      control={control}
      render={({ field: { ref, ...restFields }, fieldState }) => {
        const element = <InputText inputRef={ref} autoFocus={autoFocus} isReadonly {...restFields} />;
        if (excludeLabel) {
          return element;
        }

        return (
          <LabelWrapper
            label={currentCountry === AddressCountry.CANADA ? "Postal Code/ZIP" : "Zip Code"}
            className="col-3"
            error={fieldState.error}
          >
            {element}
          </LabelWrapper>
        );
      }}
    />
  );
};
