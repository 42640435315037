import { all, call, put, takeLeading, type SagaReturnType } from "redux-saga/effects";

import { toastShowErrorAction, toastShowSuccessAction } from "@ds/modules/notifications/redux/actions";

import { UnexpectedError } from "@ds/utils/errors";
import { logger } from "@ds/utils/logger";
import { isErrorLike } from "@ds/utils/type-guards/error-guards";

import { playersService } from "../../../utils/api";
import { playersActions } from "../../slice";
import { watchPlayerLogsCommand } from "./logs-command";
import { watchPlayerScreenshotCommand } from "./screenshot-command";

function* sendCommandsToPlayers({ payload }: ReturnType<typeof playersActions.sendCommandsToPlayers>) {
  try {
    const result: SagaReturnType<typeof playersService.sendCommands> = yield call(
      [playersService, playersService.sendCommands],
      payload,
    );

    yield put(playersActions.sendCommandsToPlayersSucceeded(result));
    yield put(toastShowSuccessAction("Command was sent successfully"));
  } catch (err) {
    const errorTitle = "Sending command";
    if (isErrorLike(err)) {
      yield put(playersActions.sendCommandsToPlayersFailed(payload, err));
      yield put(toastShowErrorAction(err, errorTitle));
    } else {
      logger.error(`${errorTitle}: ${UnexpectedError}`);
      yield put(toastShowErrorAction(UnexpectedError, errorTitle));
    }
  }
}

export function* watchCommands() {
  yield takeLeading(playersActions.sendCommandsToPlayers, sendCommandsToPlayers);
  yield all([watchPlayerLogsCommand(), watchPlayerScreenshotCommand()]);
}
