import { useCallback, type FC } from "react";
import { useNavigate } from "react-router-dom";

import { useReduxEntitiesFetch } from "@ds/hooks/use-redux-entities-fetch";

import { EntityTypeName, isAggregatedEntity } from "@ds/model/entity-model";
import { StatusEnum } from "@ds/model/status-model";

import { MainTableDataTypeEnum } from "@ds/modules/table-data/utils/model";

import { ActionsBar } from "@ds/components/actions-bar";
import { Button, ButtonActionType, ButtonType } from "@ds/components/forms/buttons";
import { IconType } from "@ds/components/icons";
import { InfoViewToggleRibbonButton } from "@ds/components/info-view/info-view-toggle-ribbon-button";
import { MenuWithButton } from "@ds/components/menu-dropdown/menu-with-button";
import { confirmSuccess, confirmWarning } from "@ds/components/popups/confirmation-dialog";
import { useDeepRootSelector, useRootDispatch, useRootSelector } from "@ds/hooks";
import { pluralizeDeleteMessage } from "@ds/utils/localization/common";
import { pluralizeExecuteMessage, pluralizeUnscheduleMessage } from "@ds/utils/localization/deployment";
import { composeDetailsRouteByEntityType } from "@ds/utils/router";

import {
  selectAggregatedDeploymentForSelectedEntities,
  selectSelectedDeploymentIds,
  selectSelectedDeployments,
} from "../../redux/selectors";
import { deploymentsActions } from "../../redux/slice";
import { isAllowedToDelete } from "../../utils/helpers";

export const DeploymentsTableActionsMenu: FC<{
  onCreate: () => void;
  onSchedule: () => void;
}> = ({ onCreate, onSchedule }) => {
  const navigate = useNavigate();

  const aggregatedDeployment = useDeepRootSelector(selectAggregatedDeploymentForSelectedEntities);
  const selectedDeployments = useRootSelector(selectSelectedDeployments);
  const selectedDeploymentIds = useRootSelector(selectSelectedDeploymentIds);

  const [toggleRefetch] = useReduxEntitiesFetch(
    { entityType: EntityTypeName.DEPLOYMENT },
    { tableType: MainTableDataTypeEnum.Deployments },
  );

  const dispatch = useRootDispatch();
  const onUpdateClickHandler = useCallback(
    () => navigate(composeDetailsRouteByEntityType(aggregatedDeployment)),
    [navigate, aggregatedDeployment],
  );

  const onDeleteClickHandler = useCallback(
    () =>
      confirmWarning({
        message: pluralizeDeleteMessage("deployment", selectedDeploymentIds.length),
        accept: () => dispatch(deploymentsActions.deleteDeployments(selectedDeploymentIds)),
      }),
    [dispatch, selectedDeploymentIds],
  );

  const onExecuteClickHandler = useCallback(
    () =>
      confirmSuccess({
        message: pluralizeExecuteMessage("deployment", selectedDeploymentIds.length),
        accept: () => dispatch(deploymentsActions.scheduleDeployments(selectedDeploymentIds)),
      }),
    [dispatch, selectedDeploymentIds],
  );

  const onUnscheduleClickHandler = useCallback(
    () =>
      confirmSuccess({
        message: pluralizeUnscheduleMessage("deployment", selectedDeploymentIds.length),
        accept: () => dispatch(deploymentsActions.unscheduleDeployments(selectedDeploymentIds)),
      }),
    [dispatch, selectedDeploymentIds],
  );

  const validStatuses = [StatusEnum.Pending, StatusEnum.Scheduled];

  return (
    <ActionsBar>
      <MenuWithButton
        menuItems={[
          {
            label: "Execute",
            icon: "pi pi-fw pi-play",
            disabled: selectedDeployments.some(({ status }) => !validStatuses.includes(status as StatusEnum)),
            command: onExecuteClickHandler,
          },
          {
            label: aggregatedDeployment?.status === StatusEnum.Scheduled ? "Reschedule" : "Schedule",
            icon: "pi pi-fw pi-calendar-plus",
            disabled:
              isAggregatedEntity(aggregatedDeployment) ||
              !validStatuses.includes(aggregatedDeployment?.status as StatusEnum),
            command: onSchedule,
          },
          {
            label: "Unschedule",
            icon: "pi pi-fw pi-calendar-times",
            disabled: isAggregatedEntity(aggregatedDeployment) || aggregatedDeployment?.status !== StatusEnum.Scheduled,
            command: onUnscheduleClickHandler,
          },
        ]}
        isDisabled={!selectedDeployments.length}
      />

      <Button ariaLabel="create button" buttonType={ButtonType.Round} icon={IconType.Create} onClick={onCreate} />
      <Button
        ariaLabel="edit button"
        buttonType={ButtonType.Round}
        icon={IconType.Update}
        isDisabled={!selectedDeployments.length}
        onClick={onUpdateClickHandler}
      />

      <Button
        ariaLabel="delete button"
        buttonType={ButtonType.Round}
        actionType={ButtonActionType.Error}
        icon={IconType.Delete}
        isDisabled={!isAllowedToDelete(selectedDeployments)}
        onClick={onDeleteClickHandler}
      />

      <Button
        ariaLabel="refresh button"
        buttonType={ButtonType.Round}
        icon={IconType.Refresh}
        onClick={toggleRefetch}
      />

      <InfoViewToggleRibbonButton />
    </ActionsBar>
  );
};
