import { useMemo, type FC } from "react";

import { InfoSection } from "@ds/components/info-view";

import { ExperienceFormationInfoSectionAttribute } from "./experience-formation-info-section-attribute";

export const ExperienceFormationInfoSection: FC<{
  cardTitle?: string;
  experience: Experience;
  templateProperties: [string, EntityFormationProperty][];
}> = ({ cardTitle, experience, templateProperties }) => {
  const infoBody = useMemo(
    () => (
      <>
        {templateProperties.map(([field, property]) => (
          <ExperienceFormationInfoSectionAttribute
            key={`experience-formation-property-${field}`}
            entity={experience}
            field={field}
            property={property}
          />
        ))}
      </>
    ),
    [experience, templateProperties],
  );

  if (cardTitle) {
    return (
      <InfoSection title={cardTitle} icon="pi pi-desktop">
        {infoBody}
      </InfoSection>
    );
  }

  return <>{infoBody}</>;
};
