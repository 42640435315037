import { useEffect, useMemo, type FC } from "react";
import { type Path } from "react-hook-form";
import styled from "styled-components";

import { useGetAggregatedEntityIds } from "@ds/hooks/use-get-aggregated-entity-ids";

import { EntityTypeName } from "@ds/model/entity-model";

import { selectPlaylistById, selectPlaylistsIsLoading } from "@ds/modules/content/playlists/redux/selectors";
import { playlistsActions } from "@ds/modules/content/playlists/redux/slice";
import { selectExperiencesByIds } from "@ds/modules/experiences/redux/selectors";
import { isExperience } from "@ds/modules/experiences/utils/model";

import { AggregatedValue } from "@ds/components/entities";
import { MultipleValuesLabelWithLink } from "@ds/components/forms/labels/common";
import { SpinnerIcon } from "@ds/components/loaders/loaders";
import { TextLink } from "@ds/components/text-link";
import { useRootDispatch, useRootSelector } from "@ds/hooks";
import { getProperty } from "@ds/utils/properties";
import { composeDetailsRouteByEntityIds, composeDetailsRouteByEntityType } from "@ds/utils/router";

import { selectProjectsByIds } from "../../redux/selectors/common-selectors";

const StyledSpinnerIcon = styled(SpinnerIcon)`
  width: 15px;
`;

const PlaylistValue: FC<{ value?: number }> = ({ value }) => {
  const playlist = useRootSelector(state => selectPlaylistById(state, value || 0));
  const isLoading = useRootSelector(selectPlaylistsIsLoading);

  const dispatch = useRootDispatch();
  useEffect(() => {
    if (value) {
      dispatch(playlistsActions.fetchPlaylist(value, { shouldDisableSagaThrottling: true }));
    }
  }, [dispatch, value]);

  if (isLoading || (value && !playlist)) {
    return <StyledSpinnerIcon />;
  }

  return <TextLink to={composeDetailsRouteByEntityType(playlist)}>{playlist?.name}</TextLink>;
};

export const FormationPlaylistDisplayValue: FC<{
  entity: Experience | Project;
  field: string;
  isDirty?: boolean;
}> = ({ entity, field, isDirty = false }) => {
  const fieldPath = isExperience(entity)
    ? `formation.properties.${field}.value`
    : `template.experience_formation.properties.${field}.value`;

  const aggregatedExperienceOrProjectIds = useGetAggregatedEntityIds(entity);
  const aggregatedExperiencesOrProjects = useRootSelector(state =>
    isExperience(entity)
      ? selectExperiencesByIds(state, aggregatedExperienceOrProjectIds)
      : selectProjectsByIds(state, aggregatedExperienceOrProjectIds),
  );

  const aggregatedFormationPlaylistIds = useMemo(
    () => aggregatedExperiencesOrProjects.map(entry => Number(getProperty(entry, fieldPath))),
    [aggregatedExperiencesOrProjects, fieldPath],
  );

  return (
    <AggregatedValue
      entity={entity}
      field={fieldPath as Path<Experience> | Path<Project>}
      shouldShowEditedValue={isDirty}
      formatter={(experienceOrProjectEntity, experienceOrProjectField) => {
        const playlistValue = Number(getProperty(experienceOrProjectEntity, experienceOrProjectField));
        if (!playlistValue) {
          return undefined;
        }

        return <PlaylistValue value={playlistValue} />;
      }}
      multipleFormatter={() => (
        <MultipleValuesLabelWithLink
          to={composeDetailsRouteByEntityIds(aggregatedFormationPlaylistIds, EntityTypeName.CONTENT_PLAYLIST)}
        />
      )}
    />
  );
};
