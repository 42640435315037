import { useState } from "react";
import { useAsyncAbortable, useDebouncedCallback, useIsMounted } from "@react-hookz/web";

import { GoogleSearchTypesEnum } from "./types";

const AUTOCOMPLETE_DEBOUNCE_TIMEOUT = 200;

type AutocompleteOptions = {
  queryFilter?: string[];
  types?: GoogleSearchTypesEnum[];
};

const fetchGoogleAddresses = (
  request: google.maps.places.AutocompletionRequest,
): Promise<google.maps.places.AutocompletePrediction[]> =>
  new Promise(resolve => {
    new google.maps.places.AutocompleteService().getPlacePredictions(request, result => {
      resolve(result || []);
    });
  });

const useFetchGoogleAddresses = (options?: AutocompleteOptions) => {
  const [{ result: data = [], status, error }, actions, meta] = useAsyncAbortable(
    async (_, searchQuery: string, queryFilter?: string[]) =>
      fetchGoogleAddresses({
        input: searchQuery,
        componentRestrictions: { country: queryFilter || options?.queryFilter || "" },
        types: options?.types || [GoogleSearchTypesEnum.ESTABLISHMENT],
      }),
  );

  return [
    {
      data,
      status,
      error,
    },
    actions,
    meta,
  ] as const;
};

export const useFetchGoogleAddressesDebouncedAutocomplete = (
  options?: AutocompleteOptions,
  debounceTimeout = AUTOCOMPLETE_DEBOUNCE_TIMEOUT,
) => {
  const isMounted = useIsMounted();
  const [suggestions, setSuggestions] = useState<google.maps.places.AutocompletePrediction[]>([]);
  const [state, actions, meta] = useFetchGoogleAddresses(options);
  return [
    suggestions,
    useDebouncedCallback(
      async (searchQuery: string, queryFilter?: string[]) => {
        const result = await actions.execute(searchQuery, queryFilter);
        if (isMounted()) {
          setSuggestions(result);
        }
      },
      [actions, isMounted],
      debounceTimeout,
    ),
    { state, actions, meta },
  ] as const;
};
