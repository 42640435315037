import { type FC } from "react";

import { AggregatedValue } from "@ds/components/entities";
import { BooleanDisplayValue } from "@ds/components/forms/control/boolean-display-value";
import { InfoAttribute, InfoSection } from "@ds/components/info-view";

export const UserInfoSection: FC<{ user: User }> = ({ user }) => (
  <InfoSection title="User Info" icon="pi pi-info-circle">
    <InfoAttribute name="First name" value={<AggregatedValue entity={user} field="given_name" />} />
    <InfoAttribute name="Last name" value={<AggregatedValue entity={user} field="family_name" />} />
    <InfoAttribute name="Role" value={<AggregatedValue entity={user} field="role" />} />
    <InfoAttribute name="Active" value={<BooleanDisplayValue entity={user} field="is_active" />} />
    <InfoAttribute name="Dark Theme" value={<BooleanDisplayValue entity={user} field="use_dark_theme" />} />
  </InfoSection>
);
