import { type FC, type ReactNode } from "react";
import styled from "styled-components";

const Wrapper = styled.section`
  width: 100%;
  background: var(--lightSurfaceColor);
  border-radius: 0 0 10px 10px;
  box-shadow:
    0 4px 10px var(--blackShadowColor005),
    0 0 2px var(--blackShadowColor010),
    0 2px 6px var(--blackShadowColor015);

  & + & {
    margin-top: 16px;
  }
`;

const Header = styled.div`
  height: 70px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 16px;
  border-bottom: 1px solid var(--textColor010);
  position: sticky;
  top: 0;
  background: rgba(243, 247, 251, 1);
  z-index: 2;
`;

const TitleWrapper = styled.h2`
  display: flex;
  align-items: center;

  color: var(--textColor070);
  font-size: 1.2rem;
  font-weight: 600;
  margin: 0;
`;

const TitleIcon = styled.i`
  margin-right: 15px;
  font-size: 1.4rem;
  color: var(--textColor070);
`;

const ChildrenWrapper = styled.div`
  padding: 32px;
  color: var(--textColor080);
`;

export const DetailsCard: FC<{
  title: string;
  icon?: string;
  actions?: ReactNode;
  children: ReactNode;
}> = ({ title, icon, actions, children }) => (
  <Wrapper>
    <Header>
      <TitleWrapper>
        {!!icon && <TitleIcon className={`pi pi-${icon}`} />}
        <span className="capitalize">{title}</span>
      </TitleWrapper>
      {actions}
    </Header>
    <ChildrenWrapper>{children}</ChildrenWrapper>
  </Wrapper>
);
