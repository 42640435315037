import { useEffect, useMemo, type FC } from "react";

import { useGetAggregatedEntityIds } from "@ds/hooks/use-get-aggregated-entity-ids";

import { isAggregatedEntity } from "@ds/model/entity-model";

import { getExperienceStatusIndicatorWithNameAndLink } from "@ds/modules/experiences/components/common/status-indicator";
import { selectExperiencesByLocationIds, selectExperiencesIsLoading } from "@ds/modules/experiences/redux/selectors";
import { experiencesActions } from "@ds/modules/experiences/redux/slice";

import { MultipleValuesLabelWithLink, UnassignedLabel } from "@ds/components/forms/labels/common";
import { InfoSection } from "@ds/components/info-view";
import { SpinnerLoader2 } from "@ds/components/loaders/loaders";
import { useDeepRootSelector, useRootDispatch, useRootSelector } from "@ds/hooks";
import { composeDetailsRouteByEntityType } from "@ds/utils/router";

export const LocationExperiencesInfoSection: FC<{ location: Locality }> = ({ location }) => {
  const aggregatedLocationIds = useGetAggregatedEntityIds(location);
  const aggregatedLocationsExperiences = useDeepRootSelector(state =>
    selectExperiencesByLocationIds(state, aggregatedLocationIds),
  );

  const isLoading = useRootSelector(selectExperiencesIsLoading);

  const dispatch = useRootDispatch();
  useEffect(() => {
    if (aggregatedLocationIds.length) {
      dispatch(experiencesActions.fetchExperiences({ locationId: aggregatedLocationIds }));
    }
  }, [dispatch, aggregatedLocationIds]);

  const infoBody = useMemo(() => {
    if (isLoading) {
      return <SpinnerLoader2 />;
    }

    const hasMultipleValues = isAggregatedEntity(location);
    if (!aggregatedLocationsExperiences.length) {
      return <UnassignedLabel />;
    } else if (hasMultipleValues) {
      return <MultipleValuesLabelWithLink to={composeDetailsRouteByEntityType(aggregatedLocationsExperiences)} />;
    }

    return aggregatedLocationsExperiences?.map(entry => (
      <div key={`location-info-experience-${entry.id}`} className="mb-1">
        {getExperienceStatusIndicatorWithNameAndLink(entry)}
      </div>
    ));
  }, [isLoading, location, aggregatedLocationsExperiences]);

  return (
    <InfoSection title="Experiences" icon="pi pi-play">
      {infoBody}
    </InfoSection>
  );
};
