import { yupResolver } from "@hookform/resolvers/yup";
import { useCallback, useEffect, type FC } from "react";
import { useForm } from "react-hook-form";

import { experiencesActions } from "@ds/modules/experiences/redux/slice";
import { createExperienceModel } from "@ds/modules/experiences/utils/model";
import { createValidationSchema } from "@ds/modules/experiences/utils/validation-schema";
import { selectLocationById } from "@ds/modules/locations/redux/selectors";
import { selectTemplateExperienceFormation } from "@ds/modules/settings/projects/redux/selectors/experience-formation-selectors";
import { selectCurrentUserProjectId } from "@ds/modules/settings/users/redux/selectors";

import { WizardPage, type WizardChildStepProps } from "@ds/components/wizard";
import { useRootDispatch, useRootSelector } from "@ds/hooks";

import { ExperienceNameControl } from "../controls/experience-name-control";
import { useCreateExperienceContext } from "./create-experience-context";

export const Step3Name: FC<WizardChildStepProps> = ({ isActive, nextStep, previousStep, ...rest }) => {
  const { experience, setFormData } = useCreateExperienceContext();

  const location = useRootSelector(state => selectLocationById(state, experience.location_id));
  const projectId = useRootSelector(selectCurrentUserProjectId);
  const nameConstructorRules = useRootSelector(state =>
    selectTemplateExperienceFormation(state, projectId),
  )?.name_constructor;

  const { control, getValues, handleSubmit, reset } = useForm<Experience>({
    mode: "all",
    reValidateMode: "onChange",
    defaultValues: experience,
    resolver: yupResolver(createValidationSchema.step3),
  });

  useEffect(() => {
    let { name } = experience;
    if (!name) {
      name = nameConstructorRules?.properties?.length
        ? nameConstructorRules.properties
            .reduce<string[]>((acc, curr) => [...acc, String(experience.formation?.properties[curr]?.value || "")], [])
            .filter(_ => _)
            .join(nameConstructorRules?.delimiter || " - ")
        : location?.name || "";
    }

    if (isActive) {
      reset({ ...experience, name });
    }
  }, [isActive, experience, location?.name, nameConstructorRules, reset]);

  const onBackClickHandler = useCallback(() => {
    setFormData({ experience: getValues() });
    if (previousStep) {
      previousStep();
    }
  }, [previousStep, getValues, setFormData]);

  const dispatch = useRootDispatch();
  const onNextClickHandler = useCallback(() => {
    const dataToSubmit = getValues();
    setFormData({ experience: dataToSubmit });
    dispatch(experiencesActions.createExperience(createExperienceModel(dataToSubmit)));
    if (nextStep) {
      nextStep();
    }
  }, [dispatch, nextStep, getValues, setFormData]);

  return (
    <WizardPage
      {...rest}
      title="Experience name"
      previousStep={onBackClickHandler}
      nextStep={handleSubmit(onNextClickHandler)}
    >
      <ExperienceNameControl control={control} autoFocus />
    </WizardPage>
  );
};
