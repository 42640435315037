import { nanoid } from "@reduxjs/toolkit";
import { type FC, type ReactNode } from "react";
import styled from "styled-components";

import { Tooltip } from "./tooltips";

const Wrapper = styled.span`
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`;

export const TrimmedContent: FC<{ content?: string; children?: ReactNode }> = ({ content = "", children }) => {
  const tooltipId = `.tooltip-${nanoid()}`;
  return (
    <>
      <Tooltip target={tooltipId} content={content} position="top" showDelay={300} />
      <Wrapper className={tooltipId.slice(1)}>{children || content}</Wrapper>
    </>
  );
};
