import { StatusEnum } from "@ds/model/status-model";

export const isAllowedToDelete = (deployments: Deployment[]) => {
  if (!deployments.length) {
    return false;
  }

  const availableStatuses: Status[] = [StatusEnum.Pending, StatusEnum.Scheduled, StatusEnum.Failed];
  return deployments.every(({ status }) => availableStatuses.includes(status));
};
