import { partial } from "lodash";
import { useCallback, type FC } from "react";
import { Controller, useWatch, type Control } from "react-hook-form";

import {
  type FormationChangeHandlerDecorator,
  type FormationChangeHandlerInputData,
} from "@ds/modules/settings/projects/components/forms/controls/types";

import { useDetailsInlineSaving } from "@ds/components/details-view/hooks";
import { LabelWrapper } from "@ds/components/forms/labels";
import { useRootSelector } from "@ds/hooks";
import { isFormationPropertyRequired } from "@ds/utils/validation";

import { selectContentAssetById } from "../../../redux/selectors/common-selectors";
import { ContentAssetsAutocompleteRaw } from "../../content-assets-autocomplete";

export const ContentAssetFormationControl: FC<{
  control: Control<Experience | Project>;
  name: `formation.properties.${string}` | `template.experience_formation.properties.${string}`;
  property: TemplateAssetFormationProperty;
  autoFocus?: boolean;
  excludeLabel?: boolean;
  onBeforeChangeHandlerDecorator: FormationChangeHandlerDecorator;
  onBlur?: () => void;
}> = ({ control, name, property, autoFocus, excludeLabel, onBeforeChangeHandlerDecorator, onBlur }) => {
  const onBlurHandler = useDetailsInlineSaving(onBlur);
  const propertyValue = (useWatch({ name, control }) as EntityAssetFormationProperty)?.value;

  // TODO: SELECTORS: FIX CONSOLE WARNING
  const getContentAssetById = useRootSelector(state => partial(selectContentAssetById, state));
  const onChangeHandler = useCallback(
    (onChange: (inputData: FormationChangeHandlerInputData) => void) =>
      onBeforeChangeHandlerDecorator(
        onChange,
        getContentAssetById(Number(propertyValue) || 0)?.name ?? "",
        inputData => getContentAssetById(Number(inputData) || 0)?.name,
      ),
    [propertyValue, onBeforeChangeHandlerDecorator, getContentAssetById],
  );

  return (
    <Controller
      name={`${name}.value`}
      control={control}
      render={({ field: { ref, value, onChange, onBlur: onBlurFn, ...restFields }, fieldState }) => {
        const element = (
          <ContentAssetsAutocompleteRaw
            inputRef={ref}
            value={Number(value)}
            autoFocus={autoFocus}
            contentTypesFilter={
              property.metadata?.content_types?.length ? `[${property.metadata?.content_types.join(",")}]` : undefined
            }
            onChange={onChangeHandler(onChange)}
            onBlur={onBlurHandler(onBlurFn)}
            {...restFields}
          />
        );

        if (excludeLabel) {
          return element;
        }

        return (
          <LabelWrapper
            label={property.title}
            className="col-8"
            error={fieldState.error?.message}
            isRequired={isFormationPropertyRequired(property.metadata?.validation_rules)}
          >
            {element}
          </LabelWrapper>
        );
      }}
    />
  );
};
