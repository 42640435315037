import { type FC, type ReactNode } from "react";
import styled from "styled-components";

import { useProjectEffect } from "@ds/hooks/use-project-effect";
import { useReduxEntitiesFetch } from "@ds/hooks/use-redux-entities-fetch";

import { EntitySubtypeName, EntityTypeName } from "@ds/model/entity-model";

import { TableFilterOptions } from "@ds/modules/table-data/redux/types";
import { DetailsTableDataTypeEnum, MainTableDataTypeEnum } from "@ds/modules/table-data/utils/model";

import { TableSearchBar } from "@ds/components/table-search-bar";

import { HeaderTitle } from "./header-title";

const Wrapper = styled.div`
  height: 100%;
  display: flex;
`;

const MainContentWrapper = styled.div`
  width: 100%;
  max-width: calc(100vw - var(--navigation-width));
  height: 100%;
  display: flex;
  flex-flow: column;
`;

const ContentBody = styled.div`
  display: flex;
  flex-flow: column;
  margin: 16px;
`;

const TableActionsWrapper = styled.div`
  height: 35px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin: 18px 16px;
`;

export type MainPage = {
  entityType: EntityTypeName & EntitySubtypeName;
  tableType: MainTableDataTypeEnum | DetailsTableDataTypeEnum;
};

export const MainView: FC<{
  options?: ReactNode;
  view: ReactNode;
  info?: ReactNode;
  title: string;
  actions?: ReactNode;
  filterOptions: TableFilterOptions;
}> = ({
  title,
  options,
  view,
  info,
  actions,
  filterOptions: { entityType, entitySubtype, tableType, tableTab, queryFilters },
}) => {
  const [, toggleRefetch] = useReduxEntitiesFetch(
    { entityType, entitySubtype, filters: queryFilters },
    { tableType, initialLoad: false },
  );

  useProjectEffect(() => toggleRefetch(), [tableType]);

  return (
    <>
      <HeaderTitle title={title} />
      <Wrapper>
        <MainContentWrapper>
          {options}
          <TableActionsWrapper>
            <TableSearchBar
              entityType={entityType}
              tableType={tableType}
              tableTab={tableTab}
              queryFilters={queryFilters}
            />

            {actions}
          </TableActionsWrapper>
          <ContentBody>{view}</ContentBody>
        </MainContentWrapper>

        {!!info && <>{info}</>}
      </Wrapper>
    </>
  );
};
