import { useEffect, useRef, type FC } from "react";
import styled from "styled-components";

import { StatusEnum } from "@ds/model/status-model";

import { PLAYER_WAITING_ONLINE_TIMEOUT } from "@ds/modules/devices/players/constants/common";
import {
  selectLastPairedPlayer,
  selectPlayersIsFailed,
  selectPlayersIsSucceeded,
} from "@ds/modules/devices/players/redux/selectors";
import { PlayerStateEnum } from "@ds/modules/devices/players/utils/model";

import { StatusMessage } from "@ds/components/status-message";
import { WizardPage, type WizardChildStepProps } from "@ds/components/wizard";
import { useRootSelector } from "@ds/hooks";

import { DevicePlayersRemoteOperation } from "../../../redux/slice";

const Wrapper = styled.div`
  display: grid;
  place-items: center;
  height: 100%;
  width: 100%;
`;

export const Step2Pairing: FC<WizardChildStepProps> = ({ nextStep, ...rest }) => {
  const timeout = useRef<NodeJS.Timeout>();
  const isFailed = useRootSelector(state => selectPlayersIsFailed(state, DevicePlayersRemoteOperation.PAIR));
  const isSucceeded = useRootSelector(state => selectPlayersIsSucceeded(state, DevicePlayersRemoteOperation.PAIR));
  const deviceState = useRootSelector(selectLastPairedPlayer)?.state;

  useEffect(() => {
    if (nextStep) {
      if (isFailed) {
        nextStep();
      }

      if (isSucceeded) {
        if (deviceState === PlayerStateEnum.Online) {
          nextStep();
        }

        if (!timeout.current) {
          timeout.current = setTimeout(nextStep, PLAYER_WAITING_ONLINE_TIMEOUT);
        }
      }
    }
  }, [isFailed, isSucceeded, deviceState, nextStep]);

  useEffect(
    () => () => {
      if (timeout.current) {
        clearTimeout(timeout.current);
      }
    },
    [],
  );

  return (
    <WizardPage {...rest} title="Pairing player" isLoading>
      <Wrapper>
        <StatusMessage status={StatusEnum.Executing} message="Pairing player..." />
      </Wrapper>
    </WizardPage>
  );
};
