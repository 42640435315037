import { useCallback, useEffect, useState, type FC } from "react";
import styled from "styled-components";

import { useFetchAmplifyStorageDataSignedUrl } from "@ds/services/amplify-storage-api";
import { useOnMouseEventPreventDefaultHandler } from "@ds/utils/handlers";

import { isContentTypeAudio, isContentTypeImage, isContentTypeVideo } from "../../utils/helpers";

export const DEFAULT_MAX_WIDTH = "90vw";
export const DEFAULT_MAX_HEIGHT = "80vh";

const AbsoluteWrapper = styled.div`
  position: absolute;
  z-index: 10000;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: var(--blackShadowColor090);
  display: flex;
  align-items: center;
  justify-content: center;
`;

const Toolbar = styled.div`
  position: absolute;
  top: 0;
  right: 0;
  padding: 1rem;
  display: flex;
`;

const ToolbarButton = styled.button`
  color: var(--lightSurfaceColor);
  width: 3rem;
  height: 3rem;
  border-radius: 50%;
  transition:
    background 0.2s,
    color 0.2s,
    box-shadow 0.2s;
  margin-right: 0.5rem;
  text-align: left;
  background: transparent;
  margin: 0;
  padding: 0;
  border: none;
  cursor: pointer;
  user-select: none;
  display: flex;
  justify-content: center;
  align-items: center;

  :hover {
    background: var(--whiteShadowColor010) !important;
  }

  :active {
    background: var(--whiteShadowColor025) !important;
  }
`;

const ToolbarIcon = styled.i`
  font-size: 1.4rem;
`;

const AssetWrapper = styled.div`
  transform: none;
`;

const AssetVideo = styled.video<{ rotateDegrees: number; scale: number }>`
  max-width: ${DEFAULT_MAX_WIDTH};
  max-height: ${DEFAULT_MAX_HEIGHT};
  transition: transform 0.15s;

  transform: ${({ rotateDegrees, scale }) => `rotate(${rotateDegrees}deg) scale(${scale})`};
`;

const AssetImage = styled.img<{ rotateDegrees: number; scale: number }>`
  max-width: ${DEFAULT_MAX_WIDTH};
  max-height: ${DEFAULT_MAX_HEIGHT};
  transition: transform 0.15s;

  transform: ${({ rotateDegrees, scale }) => `rotate(${rotateDegrees}deg) scale(${scale})`};
`;

const AssetAudio = styled.audio<{ rotateDegrees: number; scale: number }>`
  max-width: ${DEFAULT_MAX_WIDTH};
  max-height: ${DEFAULT_MAX_HEIGHT};
  transition: transform 0.15s;

  transform: ${({ rotateDegrees, scale }) => `rotate(${rotateDegrees}deg) scale(${scale})`};
`;

const Placeholder = styled.i`
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 3rem;
  color: var(--lightSurfaceColor);
  width: 600px;
  height: 400px;
`;

export const ContentAssetPreview: FC<{ asset: ContentAsset; onClose: () => void }> = ({ asset, onClose }) => {
  const onMouseDownHandler = useOnMouseEventPreventDefaultHandler();
  const [signedUrl, fetchSignedUrl] = useFetchAmplifyStorageDataSignedUrl();

  const [rotateDegrees, setRotateDegrees] = useState(0);
  const [scale, setScale] = useState(1);

  useEffect(() => void fetchSignedUrl(asset.key), [asset, fetchSignedUrl]);

  const onRotateClockwiseClickHandler = useCallback(
    () => setRotateDegrees(rotateDegrees + 90),
    [rotateDegrees, setRotateDegrees],
  );

  const onRotateCounterclockwiseClickHandler = useCallback(
    () => setRotateDegrees(rotateDegrees - 90),
    [rotateDegrees, setRotateDegrees],
  );

  const onIncreaseScaleClickHandler = useCallback(() => setScale(scale + 0.1), [scale, setScale]);
  const onDecreaseScaleClickHandler = useCallback(() => setScale(scale - 0.1), [scale, setScale]);

  return (
    <AbsoluteWrapper onMouseDown={onMouseDownHandler}>
      <Toolbar>
        <ToolbarButton type="button" onClick={onRotateClockwiseClickHandler}>
          <ToolbarIcon className="pi pi-refresh" />
        </ToolbarButton>

        <ToolbarButton type="button" onClick={onRotateCounterclockwiseClickHandler}>
          <ToolbarIcon className="pi pi-undo" />
        </ToolbarButton>

        <ToolbarButton type="button" onClick={onDecreaseScaleClickHandler}>
          <ToolbarIcon className="pi pi-search-minus" />
        </ToolbarButton>

        <ToolbarButton type="button" onClick={onIncreaseScaleClickHandler}>
          <ToolbarIcon className="pi pi-search-plus" />
        </ToolbarButton>

        <ToolbarButton type="button" onClick={onClose}>
          <ToolbarIcon className="pi pi-times" />
        </ToolbarButton>
      </Toolbar>

      <AssetWrapper>
        {signedUrl && isContentTypeVideo(asset.content_type) && (
          <AssetVideo rotateDegrees={rotateDegrees} scale={scale} controls autoPlay>
            <source src={signedUrl} />
          </AssetVideo>
        )}

        {signedUrl && isContentTypeImage(asset.content_type) && (
          <AssetImage rotateDegrees={rotateDegrees} scale={scale} src={signedUrl} alt={asset.file_name} />
        )}

        {signedUrl && isContentTypeAudio(asset.content_type) && (
          <AssetAudio rotateDegrees={rotateDegrees} scale={scale} controls autoPlay>
            <source src={signedUrl} />
          </AssetAudio>
        )}

        {!signedUrl && isContentTypeVideo(asset.content_type) && <Placeholder className="pi pi-video" />}
        {!signedUrl && isContentTypeImage(asset.content_type) && <Placeholder className="pi pi-image" />}
        {!signedUrl && isContentTypeAudio(asset.content_type) && <Placeholder className="pi pi-volume-up" />}
      </AssetWrapper>
    </AbsoluteWrapper>
  );
};
