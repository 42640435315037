import { type FC } from "react";

import { BooleanDisplayValue } from "@ds/components/forms/control/boolean-display-value";
import { InfoAttribute, InfoSection } from "@ds/components/info-view";

export const ProjectSettingsSection: FC<{ project: Project }> = ({ project }) => (
  <InfoSection title="Project Info" icon="pi pi-cog">
    <InfoAttribute name="Active" value={<BooleanDisplayValue entity={project} field="is_active" />} />

    <InfoAttribute
      name="Auto-pairing"
      value={<BooleanDisplayValue entity={project} field="is_auto_pairing_enabled" />}
    />

    <InfoAttribute
      name="Notifications"
      value={<BooleanDisplayValue entity={project} field="is_notifications_enabled" />}
    />
  </InfoSection>
);
