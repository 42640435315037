import { useEffect, useMemo, type FC } from "react";

import { useGetAggregatedEntityIds } from "@ds/hooks/use-get-aggregated-entity-ids";

import { isAggregatedEntity } from "@ds/model/entity-model";

import { getPlayerStatusIndicatorWithNameAndLink } from "@ds/modules/devices/players/components/common/status-indicator";
import { selectPlayersByIds, selectPlayersIsLoading } from "@ds/modules/devices/players/redux/selectors";
import { playersActions } from "@ds/modules/devices/players/redux/slice";
import { selectExperiencesByLocationIds, selectExperiencesIsLoading } from "@ds/modules/experiences/redux/selectors";
import { experiencesActions } from "@ds/modules/experiences/redux/slice";

import { MultipleValuesLabelWithLink, UnassignedLabel } from "@ds/components/forms/labels/common";
import { InfoSection } from "@ds/components/info-view";
import { SpinnerLoader2 } from "@ds/components/loaders/loaders";
import { useDeepRootSelector, useRootDispatch, useRootSelector } from "@ds/hooks";
import { composeDetailsRouteByEntityType } from "@ds/utils/router";

export const LocationPlayersInfoSection: FC<{ location: Locality }> = ({ location }) => {
  const aggregatedLocationIds = useGetAggregatedEntityIds(location);
  const aggregatedLocationsPlayers = useDeepRootSelector(state => {
    const aggregatedPlayersIds = selectExperiencesByLocationIds(state, aggregatedLocationIds).flatMap(
      ({ device_ids }) => device_ids,
    );

    return selectPlayersByIds(state, aggregatedPlayersIds);
  });

  const isLoading = useRootSelector(state => selectExperiencesIsLoading(state) || selectPlayersIsLoading(state));

  const dispatch = useRootDispatch();
  useEffect(() => {
    if (aggregatedLocationIds.length) {
      dispatch(experiencesActions.fetchExperiences({ locationId: aggregatedLocationIds }));
      dispatch(playersActions.fetchPlayers({ locationId: aggregatedLocationIds }));
    }
  }, [dispatch, aggregatedLocationIds]);

  const infoBody = useMemo(() => {
    if (isLoading) {
      return <SpinnerLoader2 />;
    }

    const hasMultipleValues = isAggregatedEntity(location);
    if (!aggregatedLocationsPlayers.length) {
      return <UnassignedLabel />;
    } else if (hasMultipleValues) {
      return <MultipleValuesLabelWithLink to={composeDetailsRouteByEntityType(aggregatedLocationsPlayers)} />;
    }

    return aggregatedLocationsPlayers?.map(entry => (
      <div key={`location-info-player-${entry.id}`} className="mb-1">
        {getPlayerStatusIndicatorWithNameAndLink(entry)}
      </div>
    ));
  }, [isLoading, location, aggregatedLocationsPlayers]);

  return (
    <InfoSection title="Players" icon="pi pi-desktop">
      {infoBody}
    </InfoSection>
  );
};
