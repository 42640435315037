import { type DeepPartial } from "react-hook-form";

import { DSApi } from "@ds/services/ds-api";
import { type ApiService, type ApiServiceConfig } from "@ds/services/types";
import { parseQueries } from "@ds/utils/query";

interface InvitationsServiceConfig extends ApiServiceConfig {
  api: DSApi;
}

export class InvitationsService implements ApiService {
  private config: InvitationsServiceConfig = {
    api: {} as DSApi,
  };

  configure(cfg: InvitationsServiceConfig) {
    this.config = { ...cfg };
  }

  async getInvitations(queryInfo: QueryInfo, pagination: Pagination) {
    const queries = parseQueries(queryInfo, pagination);
    return this.config.api.get<QueryOutput<ApiInvitation>>("/invites/", queries);
  }

  async getInvitation(invitationId: string) {
    return this.config.api.get<ApiInvitation>(`/invites/${invitationId}`);
  }

  async createInvitation(invitation: Invitation) {
    return this.config.api.post<ApiInvitation>("/invites/", invitation);
  }

  async updateInvitations(ids: string[], data: DeepPartial<Invitation>) {
    return this.config.api.patch<ApiInvitation>("/invites/", { ids, data });
  }

  async deleteInvitations(ids: string[]) {
    return this.config.api.delete<ApiInvitation>("/invites/", { ids });
  }

  async resendInvitations(ids: string[]) {
    return this.config.api.put<ApiInvitation[]>("/invites/resend", { ids });
  }
}

export const invitationsService = new InvitationsService();
