import { type FC } from "react";
import { Route, Routes, useLocation } from "react-router-dom";

import { MULTIPLE_IDS_PARAM, PLAYER_ID_PARAM } from "@ds/constants/router";

import { PlayerDetailsPage } from "./player-details-page";
import { PlayersMainPage } from "./players-main-page";

const PlayersRouteSwitch = () => {
  const { search } = useLocation();
  return search.startsWith(`?${MULTIPLE_IDS_PARAM}`) ? <PlayerDetailsPage /> : <PlayersMainPage />;
};

export const PlayersRouter: FC = () => (
  <Routes>
    <Route path="*" element={<PlayersRouteSwitch />} />
    <Route path={`:${PLAYER_ID_PARAM}`} element={<PlayerDetailsPage />} />
  </Routes>
);
