import { type FC } from "react";

import { AggregatedValue } from "@ds/components/entities";
import { IconType } from "@ds/components/icons";
import { InfoView, InfoViewCallToAction } from "@ds/components/info-view";
import { getInfoViewTitle } from "@ds/components/info-view/helpers";
import { useDeepRootSelector } from "@ds/hooks";

import { selectAggregatedLocationForSelectedEntities } from "../redux/selectors";
import { LocationAddressInfoSection } from "./common/location-address-info-section";
import { LocationExperiencesInfoSection } from "./common/location-experiences-info-section";
import { LocationInfoSection } from "./common/location-info-section";
import { LocationMapInfoSection } from "./common/location-map-info-section";
import { LocationOpenHoursInfoSection } from "./common/location-open-hours-info-section";
import { LocationPlayersInfoSection } from "./common/location-players-info-section";

export const LocationInfo: FC<{ onCreateLocation: () => void }> = ({ onCreateLocation }) => {
  const aggregatedLocation = useDeepRootSelector(selectAggregatedLocationForSelectedEntities);
  if (aggregatedLocation) {
    return (
      <InfoView
        infoTitle={getInfoViewTitle(aggregatedLocation)}
        infoDescription={<AggregatedValue entity={aggregatedLocation} field="description" />}
      >
        <LocationMapInfoSection location={aggregatedLocation} />
        <LocationInfoSection location={aggregatedLocation} />
        <LocationAddressInfoSection location={aggregatedLocation} />
        <LocationOpenHoursInfoSection location={aggregatedLocation} />
        <LocationExperiencesInfoSection location={aggregatedLocation} />
        <LocationPlayersInfoSection location={aggregatedLocation} />
      </InfoView>
    );
  }

  return (
    <InfoViewCallToAction
      infoTitle="Select location"
      callToActionText="Select a location in the table for more info or proceed with creating a new one."
      buttonLabel="Create location"
      buttonIcon={IconType.Create}
      onActionButtonClick={onCreateLocation}
    />
  );
};
