import { produce } from "immer";

import { normalizeBase } from "@ds/model/helpers";

import { isDeploymentQueryOutput, isDeploymentArray } from "./model";

const normalizer = (entity: Deployment): Deployment =>
  normalizeBase({
    description: undefined,
    started_at: undefined,
    ended_at: undefined,
    params: undefined,
    details: undefined,
    ...entity,
  });

export const normalize = ((
  entityOrEntities: Deployment | Deployment[] | QueryOutput<Deployment>,
): Deployment | Deployment[] | QueryOutput<Deployment> => {
  if (isDeploymentQueryOutput(entityOrEntities)) {
    return produce(entityOrEntities, draft => {
      draft.items = entityOrEntities.items.map(normalizer);
    });
  }

  return isDeploymentArray(entityOrEntities) ? entityOrEntities.map(normalizer) : normalizer(entityOrEntities);
}) as {
  (deployment: Deployment): Deployment;
  (deployments: Deployment[]): Deployment[];
  (deploymentsQueryOutput: QueryOutput<Deployment>): QueryOutput<Deployment>;
};
