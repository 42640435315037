import { yupResolver } from "@hookform/resolvers/yup";
import { useCallback, type FC } from "react";
import { useForm } from "react-hook-form";

import { authActions } from "@ds/modules/auth/redux/slice";
import { type UserChangePasswordModel } from "@ds/modules/auth/redux/types";

import { WizardPage, type WizardChildStepProps } from "@ds/components/wizard";
import { useRootDispatch } from "@ds/hooks";

import { changePasswordValidationSchema } from "../../../utils/validation-schema";
import { UserPasswordControl } from "../controls/user-password-control";

export const Step1Main: FC<WizardChildStepProps> = ({ nextStep, ...rest }) => {
  const { control, getValues, handleSubmit } = useForm<UserChangePasswordModel>({
    mode: "all",
    reValidateMode: "onChange",
    resolver: yupResolver(changePasswordValidationSchema.step1),
  });

  const dispatch = useRootDispatch();
  const onNextClickHandler = useCallback(() => {
    dispatch(authActions.changePassword(getValues()));
    if (nextStep) {
      nextStep();
    }
  }, [dispatch, getValues, nextStep]);

  return (
    <WizardPage {...rest} title="Change password" nextButtonText="Change" nextStep={handleSubmit(onNextClickHandler)}>
      <UserPasswordControl control={control} />
      <UserPasswordControl control={control} name="newPassword" label="New Password" autoComplete="new-password" />
      <UserPasswordControl
        control={control}
        name="newPasswordConfirmation"
        label="New Password Confirmation"
        autoComplete="new-password"
      />
    </WizardPage>
  );
};
