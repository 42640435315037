import { type FC } from "react";

import { AggregatedValue } from "@ds/components/entities";
import { InfoAttribute, InfoSection } from "@ds/components/info-view";

import { getPeripheralStatusIndicator } from "./status-indicator";

export const PeripheralInfoSection: FC<{ peripheral: Peripheral }> = ({ peripheral }) => (
  <InfoSection title="Peripheral Info" icon="pi pi-info-circle">
    <InfoAttribute
      name="Status"
      value={
        <AggregatedValue entity={peripheral} field="state" formatter={entity => getPeripheralStatusIndicator(entity)} />
      }
    />

    <InfoAttribute name="Message" value={<AggregatedValue entity={peripheral} field="state_message" />} />
  </InfoSection>
);
