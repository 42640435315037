import { cloneDeep } from "lodash";
import { push, replace } from "redux-first-history";
import { call, put, race, select, take, takeLatest, takeLeading, type SagaReturnType } from "redux-saga/effects";

import { iotEventLocationUpdate } from "@ds/modules/iot/redux/actions";
import { toastShowErrorAction, toastShowSuccessAction } from "@ds/modules/notifications/redux/actions";
import { isIotTableEntityAddedOrDeleted, selectTableData } from "@ds/modules/table-data/redux/selectors";
import { convertToApiQueryInfo } from "@ds/modules/table-data/utils/common";
import { MainTableDataTypeEnum } from "@ds/modules/table-data/utils/model";

import { LOCATIONS_ROUTE } from "@ds/constants/router";
import { UnexpectedError } from "@ds/utils/errors";
import { logger } from "@ds/utils/logger";
import { UNLIMITED_PAGINATION } from "@ds/utils/query";
import { takeLatestOrEvery } from "@ds/utils/saga-helpers";
import { isErrorLike } from "@ds/utils/type-guards/error-guards";

import { locationsService } from "../utils/api";
import { normalize } from "../utils/normalizer";
import { selectLocationById, selectLocationsByIds, selectTableLocations } from "./selectors";
import { LocalitiesRemoteOperation, locationsActions } from "./slice";

function* switchRoute(_: ReturnType<typeof locationsActions.switchView>) {
  yield put(push(LOCATIONS_ROUTE));
}

function* fetchLocations({ meta }: ReturnType<typeof locationsActions.fetchLocations>) {
  try {
    const { sorting, pagination, queryInfo, isFetchedAlready } = selectTableData(
      yield select(),
      meta.options.tableType || MainTableDataTypeEnum.Locations,
      undefined,
      meta.filters,
    );

    const newMeta = cloneDeep(meta);
    const resultFromCache: QueryOutput<Locality> = { items: [] };

    if (!newMeta.options.cache?.disableCache) {
      if (isFetchedAlready && newMeta.options.tableType) {
        resultFromCache.items = selectTableLocations(yield select(), newMeta.options.tableType);
        newMeta.options.cache = {
          ...newMeta.options.cache,
          fetchedFromCache: true,
        };
      } else if (newMeta.filters.id && Object.keys(newMeta.filters).length === 1) {
        resultFromCache.items = selectLocationsByIds(yield select(), newMeta.filters.id);
        if (resultFromCache.items.length === newMeta.filters.id.length) {
          newMeta.options.cache = {
            ...newMeta.options.cache,
            fetchedFromCache: true,
          };
        }
      }
    }

    let result: QueryOutput<Locality> = newMeta.options.cache?.fetchedFromCache
      ? resultFromCache
      : yield call(
          [locationsService, locationsService.getLocations],
          convertToApiQueryInfo(sorting, queryInfo),
          newMeta.options.tableType ? pagination : UNLIMITED_PAGINATION,
        );

    result = newMeta.options.cache?.fetchedFromCache ? result : normalize(result);
    result = newMeta.options.tableType ? result : { items: result.items };

    yield put(locationsActions.fetchLocationsSucceeded(result, newMeta));
  } catch (err) {
    const errorTitle = "Fetch locations";
    if (isErrorLike(err)) {
      yield put(locationsActions.fetchLocationsFailed(meta, err));
      yield put(toastShowErrorAction(err, errorTitle));
    } else {
      logger.error(`${errorTitle}: ${UnexpectedError}`);
      yield put(toastShowErrorAction(UnexpectedError, errorTitle));
    }
  }
}

function* fetchLocation({ payload, meta }: ReturnType<typeof locationsActions.fetchLocation>) {
  try {
    const newMeta = cloneDeep(meta);
    let result = selectLocationById(yield select(), payload);
    if (result && !newMeta.options.cache?.disableCache) {
      newMeta.options.cache = {
        ...newMeta.options.cache,
        fetchedFromCache: true,
      };
    } else {
      result = yield call([locationsService, locationsService.getLocation], payload);
      result = normalize(result);
    }

    yield put(locationsActions.fetchLocationSucceeded(result, newMeta));
  } catch (err) {
    const errorTitle = "Fetch location";
    if (isErrorLike(err)) {
      yield put(locationsActions.fetchLocationFailed(payload, meta, err));
      yield put(toastShowErrorAction(err, errorTitle));
    } else {
      logger.error(`${errorTitle}: ${UnexpectedError}`);
      yield put(toastShowErrorAction(UnexpectedError, errorTitle));
    }
  }
}

function* createLocation({ payload, meta }: ReturnType<typeof locationsActions.createLocation>) {
  try {
    const result: SagaReturnType<typeof locationsService.createLocation> = yield call(
      [locationsService, locationsService.createLocation],
      payload,
    );

    yield put(locationsActions.createLocationSucceeded(normalize(result), meta));
    yield put(toastShowSuccessAction("Location was created successfully"));
    yield put(locationsActions.selectLocations([result]));
  } catch (err) {
    const errorTitle = "Create location";
    if (isErrorLike(err)) {
      yield put(locationsActions.createLocationFailed(payload, meta, err));
      yield put(toastShowErrorAction(err, errorTitle));
    } else {
      logger.error(`${errorTitle}: ${UnexpectedError}`);
      yield put(toastShowErrorAction(UnexpectedError, errorTitle));
    }
  }
}

function* updateLocations({ payload }: ReturnType<typeof locationsActions.updateLocations>) {
  try {
    const result: SagaReturnType<typeof locationsService.updateLocations> = yield call(
      [locationsService, locationsService.updateLocations],
      payload.ids,
      payload.data,
    );

    yield put(locationsActions.updateLocationsSucceeded(normalize(result)));
    yield put(toastShowSuccessAction("Location(s) were updated successfully"));
  } catch (err) {
    const errorTitle = "Update location(s)";
    if (isErrorLike(err)) {
      yield put(locationsActions.updateLocationsFailed(payload, err));
      yield put(toastShowErrorAction(err, errorTitle));
    } else {
      logger.error(`${errorTitle}: ${UnexpectedError}`);
      yield put(toastShowErrorAction(UnexpectedError, errorTitle));
    }
  }
}

function* deleteLocations({ payload, meta }: ReturnType<typeof locationsActions.deleteLocations>) {
  try {
    yield call([locationsService, locationsService.deleteLocations], payload);
    yield put(locationsActions.deleteLocationsSucceeded(payload, meta));
    yield put(toastShowSuccessAction("Location(s) were deleted successfully"));

    if (meta.options?.redirectTo) {
      yield put(replace(meta.options.redirectTo));
    }
  } catch (err) {
    const errorTitle = "Delete location(s)";
    if (isErrorLike(err)) {
      yield put(locationsActions.deleteLocationsFailed(payload, meta, err));
      yield put(toastShowErrorAction(err, errorTitle));
    } else {
      logger.error(`${errorTitle}: ${UnexpectedError}`);
      yield put(toastShowErrorAction(UnexpectedError, errorTitle));
    }
  }
}

function* refetchLocations({ payload }: ReturnType<typeof iotEventLocationUpdate>) {
  const isAddedOrDeleted = isIotTableEntityAddedOrDeleted(yield select(), payload, MainTableDataTypeEnum.Locations);
  if (isAddedOrDeleted) {
    yield call(
      fetchLocations,
      locationsActions.fetchLocations(
        undefined,
        {
          tableType: MainTableDataTypeEnum.Locations,
          cache: { disableCache: true },
        },
        LocalitiesRemoteOperation.IOT_FETCH,
      ),
    );
  }

  yield put(locationsActions.iotUpdate(payload));
}

export function* watchLocations() {
  yield takeLatest(locationsActions.switchView, switchRoute);
  yield takeLatestOrEvery(locationsActions.fetchLocations, function* (action) {
    yield race({
      task: call(fetchLocations, action),
      cancel: take([locationsActions.selectLocations, locationsActions.toggleSelected]),
    });
  });

  yield takeLatestOrEvery(locationsActions.fetchLocation, fetchLocation);
  yield takeLeading(locationsActions.createLocation, createLocation);
  yield takeLeading(locationsActions.updateLocations, updateLocations);
  yield takeLeading(locationsActions.deleteLocations, deleteLocations);
  yield takeLatest(iotEventLocationUpdate, refetchLocations);
}
