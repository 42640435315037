import { Dropdown as PrimeDropdown } from "primereact/dropdown";
import styled from "styled-components";

import { TextInputMixin } from "./styles";

export type { DropdownChangeEvent } from "primereact/dropdown";
export { MultiSelect } from "primereact/multiselect";

export const Dropdown = styled(PrimeDropdown)<{ err?: Error }>`
  ${TextInputMixin}
`;
