import { useEffect, useMemo, type FC } from "react";
import { type StepWizardChildProps as PrimeStepWizardChildProps } from "react-step-wizard";

import { Button, ButtonActionType, ButtonType } from "../forms/buttons";
import { IconType } from "../icons";
import { Toolbar, ToolbarWrapper } from "../toolbar";

type CancelButton = {
  cancelButtonText?: string;
};

type NextButton = {
  nextButtonText?: string;
};

type PreviousButton = {
  previousButtonText?: string;
};

export type WizardChildStepProps = Partial<PrimeStepWizardChildProps>;
export type WizardStepProps = {
  className?: string;
  isValid?: boolean;
  setIsValid?: (isValid: boolean) => void;
  onClose?: () => void;
} & WizardChildStepProps &
  CancelButton &
  NextButton &
  PreviousButton;

export const WizardStep: FC<WizardStepProps> = ({
  className = "",
  isValid = true,
  setIsValid,
  onClose,
  cancelButtonText = "CANCEL",
  nextButtonText = "NEXT",
  previousButtonText = "BACK",
  totalSteps = 1,
  currentStep = 1,
  nextStep,
  previousStep,
}) => {
  const isFirstStep = currentStep === 1;
  const isFinalStep = currentStep === totalSteps;
  const isFinalValidStep = isFinalStep && isValid;

  useEffect(() => {
    if (setIsValid) {
      setIsValid(isValid);
    }
  }, [isValid, setIsValid]);

  const cancelButton = useMemo(
    () => (
      <>
        {cancelButtonText && onClose && (
          <Button
            label={cancelButtonText}
            ariaLabel="cancel button"
            className="uppercase"
            buttonType={ButtonType.Outlined}
            actionType={ButtonActionType.Secondary}
            icon={IconType.Close}
            iconPosition="right"
            onClick={onClose}
          />
        )}
      </>
    ),
    [cancelButtonText, onClose],
  );

  const navigationButton = useMemo(
    () => (
      <>
        {previousButtonText && !isFirstStep && previousStep && (
          <Button
            label={previousButtonText}
            ariaLabel="previous button"
            className="uppercase"
            buttonType={ButtonType.Raised}
            icon={IconType.Previous}
            onClick={previousStep}
          />
        )}
        &nbsp;&nbsp;
        {nextButtonText && isValid && !isFinalStep && nextStep && (
          <Button
            label={nextButtonText}
            ariaLabel="next button"
            className="uppercase"
            buttonType={ButtonType.Raised}
            icon={IconType.Next}
            iconPosition="right"
            onClick={nextStep}
          />
        )}
      </>
    ),
    [isFirstStep, isFinalStep, isValid, previousButtonText, previousStep, nextButtonText, nextStep],
  );

  if (isFinalValidStep && onClose) {
    return (
      <ToolbarWrapper>
        <Button
          label="CLOSE"
          ariaLabel="close button"
          buttonType={ButtonType.Raised}
          icon={IconType.Close}
          iconPosition="right"
          onClick={onClose}
        />
      </ToolbarWrapper>
    );
  }

  return (
    <ToolbarWrapper>
      <Toolbar className={className} left={cancelButton} right={navigationButton} />
    </ToolbarWrapper>
  );
};
