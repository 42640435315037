import type { FC } from "react";
import styled from "styled-components";

import { HeaderTitle } from "@ds/components/header-title";
import { Loading } from "@ds/components/loaders/loading";
import { useRootSelector } from "@ds/hooks";

import { selectProjectsIsLoading } from "../settings/projects/redux/selectors/common-selectors";

const IframeContainer = styled.iframe`
  height: 100%;
`;

const REPORTS_URL = "https://embed.domo.com/embed/pages/32kvA";

export const ReportsMainPage: FC = () => {
  const isLoading = useRootSelector(state => selectProjectsIsLoading(state));

  return (
    <>
      <HeaderTitle title="Reports" />
      <Loading isLoading={isLoading}>
        <IframeContainer src={REPORTS_URL} title="dashboard" />
      </Loading>
    </>
  );
};
