import { useCallback, useMemo, type FC } from "react";
import { useForm } from "react-hook-form";
import styled from "styled-components";

import { PlaylistAssetsControl } from "@ds/modules/content/playlists/components/forms/controls/playlist-assets-control";

import { Button, ButtonActionType, ButtonType } from "@ds/components/forms/buttons";
import { IconType } from "@ds/components/icons";
import { Dialog } from "@ds/components/modals";
import { useRootDispatch } from "@ds/hooks";

import { playlistAssetsActions } from "../../redux/slice";
import { createPlaylistAssetModel } from "../../utils/model";

const ContentWrapper = styled.div`
  min-height: 200px;
  width: 500px;
`;

const FooterWrapper = styled.div`
  display: flex;
  justify-content: right;
`;

export const CreatePlaylistAssetModal: FC<{
  playlist: Playlist;
  onClose: () => void;
}> = ({ playlist, onClose }) => {
  const { control, watch, getValues, handleSubmit } = useForm<PlaylistFormData>({
    mode: "all",
    reValidateMode: "onChange",
    defaultValues: { playlist, assets: [] },
  });

  const dispatch = useRootDispatch();
  const onSubmitHandler = useCallback(async () => {
    getValues().assets.forEach(asset =>
      dispatch(
        playlistAssetsActions.createPlaylistAsset(
          createPlaylistAssetModel({
            playlist_id: playlist.id,
            playlist_order: 0,
            asset_id: asset.id,
          }),
          { playlistId: playlist.id },
          true,
        ),
      ),
    );

    onClose();
  }, [dispatch, playlist.id, getValues, onClose]);

  const assets = watch("assets");
  const renderFooter = useMemo(
    () => (
      <FooterWrapper>
        <Button
          label="Cancel"
          ariaLabel="cancel button"
          buttonType={ButtonType.Outlined}
          actionType={ButtonActionType.Secondary}
          icon={IconType.Close}
          iconPosition="right"
          onClick={onClose}
        />

        <Button
          label="Add"
          ariaLabel="create button"
          buttonType={ButtonType.Raised}
          icon={IconType.Create}
          iconPosition="right"
          isDisabled={!assets.length}
          onClick={handleSubmit(onSubmitHandler)}
        />
      </FooterWrapper>
    ),
    [assets, onClose, onSubmitHandler, handleSubmit],
  );

  return (
    <Dialog modal visible header="Add assets to playlist" footer={renderFooter} onHide={onClose}>
      <ContentWrapper>
        <PlaylistAssetsControl control={control} />
      </ContentWrapper>
    </Dialog>
  );
};
