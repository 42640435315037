import { useEffect, type FC } from "react";
import styled from "styled-components";

import { ContentAssetThumbnail } from "@ds/modules/content/assets/components/common/content-asset-thumbnail";
import {
  selectContentAssetById,
  selectContentAssetsIsLoading,
} from "@ds/modules/content/assets/redux/selectors/common-selectors";
import { contentAssetsActions } from "@ds/modules/content/assets/redux/slice";
import { isExperience } from "@ds/modules/experiences/utils/model";

import { AggregatedValue } from "@ds/components/entities";
import { SpinnerIcon } from "@ds/components/loaders/loaders";
import { useRootDispatch, useRootSelector } from "@ds/hooks";
import { getProperty } from "@ds/utils/properties";

const StyledSpinnerIcon = styled(SpinnerIcon)`
  width: 15px;
`;

const AssetWrapper = styled.div`
  display: flex;
  align-items: center;
`;

const AssetValue: FC<{ value?: number }> = ({ value }) => {
  const asset = useRootSelector(state => selectContentAssetById(state, value || 0));
  const isLoading = useRootSelector(selectContentAssetsIsLoading);

  const dispatch = useRootDispatch();
  useEffect(() => {
    if (value) {
      dispatch(contentAssetsActions.fetchContentAsset(value, { shouldDisableSagaThrottling: true }));
    }
  }, [dispatch, value]);

  if (isLoading || (value && !asset)) {
    return <StyledSpinnerIcon />;
  }

  return (
    <AssetWrapper>
      <ContentAssetThumbnail asset={asset} />
    </AssetWrapper>
  );
};

export const FormationAssetDisplayValue: FC<{
  entity: Experience | Project;
  field: string;
  isDirty?: boolean;
}> = ({ entity, field, isDirty = false }) => (
  <AggregatedValue
    entity={entity}
    field={
      isExperience(entity)
        ? `formation.properties.${field}.value`
        : `template.experience_formation.properties.${field}.value`
    }
    shouldShowEditedValue={isDirty}
    formatter={(assetEntity, assetField) => {
      const assetValue = Number(getProperty(assetEntity, assetField));
      if (!assetValue) {
        return undefined;
      }

      return <AssetValue value={assetValue} />;
    }}
  />
);
