import { parseQueries } from "@ds/utils/query";
import { DSApi } from "@ds/services/ds-api";
import { type ApiService, type ApiServiceConfig } from "@ds/services/types";

interface ContentAssetsServiceConfig extends ApiServiceConfig {
  api: DSApi;
}

class ContentAssetsService implements ApiService {
  private config: ContentAssetsServiceConfig = {
    api: {} as DSApi,
  };

  configure(cfg: ContentAssetsServiceConfig) {
    this.config = { ...cfg };
  }

  async getAssets(queryInfo: QueryInfo, pagination: Pagination) {
    const queries = parseQueries(queryInfo, pagination);
    return this.config.api.get<QueryOutput<ContentAsset>>("/assets/", queries);
  }

  async getAsset(assetId: number) {
    return this.config.api.get<ContentAsset>(`/assets/${assetId}`);
  }

  async createAsset(asset: ContentAsset) {
    return this.config.api.post<ContentAsset>("/assets/", asset);
  }

  async deleteAssets(ids: number[]) {
    return this.config.api.delete<ContentAsset>("/assets/", { ids });
  }
}

export const contentAssetsService = new ContentAssetsService();
