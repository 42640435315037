import { selectCognitoUserIsSignedIn } from "@ds/modules/auth/redux/selectors";
import { selectUsersIsSucceeded } from "@ds/modules/settings/users/redux/selectors";
import { UsersRemoteOperation } from "@ds/modules/settings/users/redux/slice";

import { type RootState } from "@ds/store/bootstrap";

const selectBaseState = (state: RootState) => state.app;

export const selectAppIsLoaded = (state: RootState) => selectBaseState(state).appIsLoaded;

export const selectShowInitialLoader = (state: RootState) =>
  !selectAppIsLoaded(state) ||
  (selectCognitoUserIsSignedIn(state) && !selectUsersIsSucceeded(state, UsersRemoteOperation.AUTH));
