import { isBoolean } from "lodash";
import { useCallback, useEffect, type FC } from "react";
import { PortalWithState } from "react-portal";
import styled from "styled-components";

import { AutocompleteTypeEnum, useFetchEntitiesAutocomplete } from "@ds/hooks/use-fetch-entities";
import { useIsFirstTimeTrue } from "@ds/hooks/use-is-first-time-true";

import { AutoComplete, type SingleAutoCompleteProps } from "@ds/components/forms/autocomplete";
import { Button } from "@ds/components/forms/buttons";
import { InputGroup } from "@ds/components/forms/buttons/input-group";
import { IconType } from "@ds/components/icons";
import { SpinnerIcon } from "@ds/components/loaders/loaders";
import { TrimmedContent } from "@ds/components/trimmed-text";
import { useRootDispatch, useRootSelector } from "@ds/hooks";
import { useOnMouseEventPreventDefaultHandler } from "@ds/utils/handlers";

import { selectContentAssetById, selectContentAssetsIsLoading } from "../redux/selectors/common-selectors";
import { contentAssetsActions } from "../redux/slice";
import { isContentTypeAudio, isContentTypeImage, isContentTypeVideo } from "../utils/helpers";
import { isContentAsset } from "../utils/model";
import { ContentAssetPreview } from "./common/content-asset-preview";
import { ContentAssetThumbnail } from "./common/content-asset-thumbnail";

const StyledSpinnerIcon = styled(SpinnerIcon)`
  width: 15px;
`;

const TrimmedContentWrapper = styled.span`
  width: 300px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`;

const ThumbnailImageWrapper = styled.span`
  margin-right: 20px;
  width: 75px;
`;

const Icon = styled.i`
  height: auto;
  margin: auto 0;
  vertical-align: middle;
  margin-left: 10px;
  font-size: 0.95rem;
  padding-bottom: 1px;
`;

const PreviewButton = styled(Button)<{ $isVisible?: boolean }>`
  display: ${({ $isVisible }) => {
    if (!isBoolean($isVisible)) {
      return "flex";
    }

    return $isVisible ? "flex" : "none";
  }};
`;

export const ContentAssetsAutocomplete: FC<
  Omit<SingleAutoCompleteProps<ContentAsset>, "isLoading"> & {
    contentTypesFilter?: string;
  }
> = ({ customRef, inputRef, value, filterSuggestionsPredicate, autoFocus, onBlur, onChange, contentTypesFilter }) => {
  const onMouseDownHandler = useOnMouseEventPreventDefaultHandler();

  const renderItemTemplate = useCallback(
    (asset: ContentAsset) => (
      <div className="flex align-items-center">
        <ThumbnailImageWrapper>
          <ContentAssetThumbnail asset={asset} height="50px" width="75px" showPreview={false} />
        </ThumbnailImageWrapper>

        <TrimmedContentWrapper>
          <TrimmedContent content={asset.name} />
        </TrimmedContentWrapper>

        {isContentTypeVideo(asset.content_type) && (
          <TrimmedContent content="video">
            <Icon title="videos" className="pi pi-video" />
          </TrimmedContent>
        )}

        {isContentTypeImage(asset.content_type) && (
          <TrimmedContent content="image">
            <Icon className="pi pi-image" />
          </TrimmedContent>
        )}

        {isContentTypeAudio(asset.content_type) && (
          <TrimmedContent content="audio">
            <Icon title="audio" className="pi pi-volume-up" />
          </TrimmedContent>
        )}
      </div>
    ),
    [],
  );

  const [
    suggestions,
    onCompleteHandler,
    {
      state: { status: remoteStatus },
    },
  ] = useFetchEntitiesAutocomplete<ContentAsset>(AutocompleteTypeEnum.ContentAssets, {
    queryFilter: contentTypesFilter,
    queryLimit: 15,
    filterPredicate: filterSuggestionsPredicate,
  });

  return (
    <PortalWithState closeOnOutsideClick closeOnEsc>
      {({ openPortal, closePortal, portal }) => (
        <>
          <InputGroup>
            <PreviewButton
              ariaLabel="preview button"
              icon={IconType.Eye}
              $isVisible={!!value}
              onClick={openPortal}
              onMouseDown={onMouseDownHandler}
            />

            <AutoComplete
              field="name"
              placeholder="Enter asset name"
              itemTemplate={renderItemTemplate}
              customRef={customRef}
              inputRef={inputRef}
              value={value}
              suggestions={suggestions}
              autoFocus={autoFocus}
              isLoading={remoteStatus === "loading"}
              onBlur={onBlur}
              onChange={onChange}
              onComplete={onCompleteHandler}
            />
          </InputGroup>

          {value && portal(<ContentAssetPreview asset={value} onClose={closePortal} />)}
        </>
      )}
    </PortalWithState>
  );
};

export const ContentAssetsAutocompleteRaw: FC<
  Omit<SingleAutoCompleteProps<ContentAsset>, "value" | "onChange" | "isLoading"> & {
    value?: number | null;
    contentTypesFilter?: string;
    onChange: (id: number | null, asset: ContentAsset | null) => void;
  }
> = ({ value, onChange, ...restProps }) => {
  const isLoading = useRootSelector(selectContentAssetsIsLoading);
  const currentValue = useRootSelector(state =>
    isContentAsset(value) ? value : selectContentAssetById(state, Number(value) || 0),
  );

  const [isFirstLoading] = useIsFirstTimeTrue(isLoading);

  const dispatch = useRootDispatch();
  useEffect(() => {
    if (!isContentAsset(value) && Number(value)) {
      dispatch(contentAssetsActions.fetchContentAsset(Number(value), { shouldDisableSagaThrottling: true }));
    }
  }, [dispatch, value]);

  const onChangeHandler = useCallback(
    (asset: ContentAsset) => {
      onChange(asset?.id ?? null, asset ?? null);
    },
    [onChange],
  );

  return isFirstLoading ? (
    <StyledSpinnerIcon />
  ) : (
    <ContentAssetsAutocomplete value={currentValue} onChange={onChangeHandler} {...restProps} />
  );
};
