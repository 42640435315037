import { type FC } from "react";
import { Controller, type Control } from "react-hook-form";

import { InputText } from "@ds/components/forms/inputs";
import { LabelWrapper } from "@ds/components/forms/labels";

export const PlayerPairingCodeControl: FC<{
  control: Control<PlayerFormData>;
  autoFocus?: boolean;
  excludeLabel?: boolean;
}> = ({ control, autoFocus, excludeLabel }) => (
  <Controller
    name="pairingCode"
    control={control}
    render={({ field: { ref, ...restFields }, fieldState }) => {
      const element = (
        <InputText inputRef={ref} placeholder="Enter pairing code" autoFocus={autoFocus} {...restFields} />
      );

      if (excludeLabel) {
        return element;
      }

      return (
        <LabelWrapper label="Pairing Code" className="col-8" error={fieldState.error}>
          {element}
        </LabelWrapper>
      );
    }}
  />
);
