import { isString } from "lodash";
import { type FC, type ReactNode } from "react";
import styled, { css } from "styled-components";

import { useToggleInfoView } from "@ds/hooks/use-toggle-info-view";

import { Button, ButtonType } from "../forms/buttons";
import { IconType } from "../icons";

const titleCss = css`
  padding-right: 16px;
  font-weight: 600;
  font-size: 1.1rem;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
`;

const Wrapper = styled.div<{ $isVisible: boolean }>`
  width: var(--infoView-width);
  min-width: var(--infoView-width);
  height: calc(100vh - 60px);
  display: ${({ $isVisible }) => ($isVisible ? "flex" : "none")};
  flex-flow: column;
  overflow-x: hidden;
  overflow-y: overlay;

  background: var(--lightSurfaceColor);
  box-shadow: 0 10px 10px 2px var(--blackShadowColor005);
  border-top: 1px solid var(--blackShadowColor010);

  @media only screen and (max-width: 1200px) {
    display: none;
  }
`;

const InfoViewSectionsWrapper = styled.div`
  display: flex;
  flex-flow: column;
  flex-grow: 1;
  padding: 15px;
`;

const TitleWrapper = styled.h2`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 18px 0 0;
  padding: 0;
  height: 45px;
`;

const InfoHeader = styled.div`
  margin: 0 16px;
`;

const InfoHeaderTitle = styled.span`
  ${titleCss};
  color: var(--textColor070);
`;

const InfoHeaderTitleDescription = styled.div`
  font-size: 0.9rem;
  font-style: oblique;
  line-height: 0.9rem;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`;

export type InfoViewProps = {
  infoTitle: ReactNode;
  infoDescription?: ReactNode;
  thumbnail?: string;
  children?: ReactNode;
};

export const InfoView: FC<InfoViewProps> = ({ children, infoTitle, infoDescription }) => {
  const [isInfoViewVisible, onCloseClick] = useToggleInfoView();
  return (
    <Wrapper $isVisible={isInfoViewVisible}>
      <InfoHeader>
        <TitleWrapper>
          <InfoHeaderTitle title={isString(infoTitle) ? infoTitle : ""}>{infoTitle}</InfoHeaderTitle>

          <Button
            ariaLabel="close info view button"
            buttonType={ButtonType.Round}
            icon={IconType.Close}
            onClick={onCloseClick(false)}
          />
        </TitleWrapper>
        <InfoHeaderTitleDescription>{infoDescription}</InfoHeaderTitleDescription>
      </InfoHeader>
      <InfoViewSectionsWrapper>{children}</InfoViewSectionsWrapper>
    </Wrapper>
  );
};
