import { type FC } from "react";
import Dropzone, { type Accept, type DropzoneRef } from "react-dropzone";

export const ContentAssetUploadInput: FC<{
  acceptMediaTypes: Accept;
  dropzoneRef: React.Ref<DropzoneRef>;
  onUpload: (files: File[]) => void;
}> = ({ acceptMediaTypes, dropzoneRef, onUpload }) => (
  <Dropzone ref={dropzoneRef} accept={acceptMediaTypes} onDrop={onUpload}>
    {({ getRootProps, getInputProps }) => (
      <div {...getRootProps()}>
        <input {...getInputProps()} />
      </div>
    )}
  </Dropzone>
);
