import { css } from "styled-components";

export const BaseInputMixin = css`
  ::placeholder,
  .p-inputtext::placeholder {
    font-size: 0.95rem;
    font-style: oblique;
    color: var(--textColor030);
  }

  :not(.p-disabled):hover,
  .p-inputtext:not(.p-disabled):hover {
    border-color: var(--primaryColor015);
    box-shadow: 0 4px 6px var(--primaryColor015);
    opacity: 1;
  }

  :not(.p-disabled).p-focus,
  .p-inputtext:not(.p-disabled).p-focus {
    border-color: var(--primaryColor060);
    box-shadow: 0 4px 6px var(--primaryColor015);
    opacity: 1;
  }

  :enabled:hover,
  .p-inputtext:enabled:hover {
    border-color: var(--primaryColor015);
    box-shadow: 0 4px 6px var(--primaryColor015);
    opacity: 1;
  }

  :enabled:focus,
  .p-inputtext:enabled:focus {
    border-color: var(--primaryColor060);
    box-shadow: 0 4px 6px var(--primaryColor015);
    opacity: 1;
  }
`;

export const TextInputMixin = css<{ err?: Error }>`
  font-size: 0.95rem;
  border: 1px solid;
  border-color: ${prop => (prop.err ? "var(--red-color)" : "var(--textColor010)")};

  ${BaseInputMixin}
`;
