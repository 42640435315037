import { type FC } from "react";

import { selectTemplateExperienceFormationEntriesFilteredPerGroup } from "@ds/modules/settings/projects/redux/selectors/experience-formation-selectors";
import { selectCurrentUserProjectId } from "@ds/modules/settings/users/redux/selectors";

import { IconType } from "@ds/components/icons";
import { InfoView, InfoViewCallToAction } from "@ds/components/info-view";
import { getInfoViewTitle } from "@ds/components/info-view/helpers";
import { useDeepRootSelector, useRootSelector } from "@ds/hooks";

import { selectAggregatedExperienceForSelectedEntities } from "../redux/selectors";
import { ExperienceFormationInfoSection } from "./common/experience-formation-info-section";
import { ExperienceInfoSection } from "./common/experience-info-section";
import { ExperiencePlayersInfoSection } from "./common/experience-players-info-section";

export const ExperienceInfo: FC<{ onCreateExperience: () => void }> = ({ onCreateExperience }) => {
  const aggregatedExperience = useDeepRootSelector(selectAggregatedExperienceForSelectedEntities);
  const projectId = useRootSelector(selectCurrentUserProjectId);
  const [infoPropertiesEntries, otherPropertiesPerGroup] = useDeepRootSelector(state => {
    const [infoData, otherData] = selectTemplateExperienceFormationEntriesFilteredPerGroup(
      state,
      projectId,
      "details",
      ["Info", "Players"],
    );

    return [Object.entries(infoData).flatMap(([, properties]) => properties), otherData] as const;
  });

  if (aggregatedExperience) {
    return (
      <InfoView infoTitle={getInfoViewTitle(aggregatedExperience)}>
        <ExperienceInfoSection experience={aggregatedExperience} templateProperties={infoPropertiesEntries} />
        <ExperiencePlayersInfoSection experience={aggregatedExperience} />

        {Object.entries(otherPropertiesPerGroup).map(([group, properties]) => (
          <ExperienceFormationInfoSection
            key={`experience-formation-card-${group}`}
            cardTitle={properties[0][1].metadata?.details?.group_title || ""}
            experience={aggregatedExperience}
            templateProperties={properties}
          />
        ))}
      </InfoView>
    );
  }

  return (
    <InfoViewCallToAction
      infoTitle="Select experience"
      callToActionText="Select an experience in the table for more info or proceed with creating a new one."
      buttonLabel="Create experience"
      buttonIcon={IconType.Create}
      onActionButtonClick={onCreateExperience}
    />
  );
};
