import { isArray, isDate } from "lodash";
import { useCallback, useEffect, useState, type FC } from "react";
import styled from "styled-components";

import { Calendar, type CalendarChangeEvent } from "@ds/components/calendars";

const CalendarWrapper = styled.div`
  min-width: 340px;

  .p-calendar {
    width: 100%;
    margin-top: 10px;
  }

  .p-datepicker table td {
    padding: 3px;
  }

  .p-datepicker-other-month {
    opacity: 0.5;
  }
`;

export const CalendarView: FC<{
  onChange: (date: Date) => void;
  initialDate?: Date;
}> = ({ onChange, initialDate = new Date() }) => {
  const [date, setDate] = useState<Date>(initialDate);

  useEffect(() => void onChange(date), [date, onChange]);

  const onDateChangeHandler = useCallback(({ value }: CalendarChangeEvent) => {
    if (value && !isArray(value) && isDate(value)) {
      setDate(value);
    }
  }, []);

  return (
    <CalendarWrapper>
      <Calendar minDate={new Date()} value={date} onChange={onDateChangeHandler} hourFormat="12" inline showTime />
    </CalendarWrapper>
  );
};
