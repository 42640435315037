import {
  hasProperty as baseHasProperty,
  setProperty as baseSetProperty,
  getProperty as baseGetProperty,
  deepKeys as baseDeepKeys,
} from "dot-prop";

export const toDotNotation = (path: string) => path.replace(/(\.)(\d+)(\.|$)/, "[$2]$3");

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const hasProperty = (object: Record<string, any> | undefined, path: string) => baseHasProperty(object, path);

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const setProperty = <ObjectType extends Record<string, any>>(object: ObjectType, path: string, value: unknown) =>
  baseSetProperty(object, toDotNotation(path), value);

export const getProperty = <ObjectType, PathType extends string, DefaultValue = undefined>(
  object: ObjectType,
  path: PathType,
  defaultValue?: DefaultValue,
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
): any => baseGetProperty(object, toDotNotation(path), defaultValue);

export const deepKeys = (object: unknown) => baseDeepKeys(object);
