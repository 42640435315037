import { useEffect, type FC } from "react";
import { PortalWithState } from "react-portal";
import styled from "styled-components";

import { useFetchAmplifyStorageDataSignedUrl } from "@ds/services/amplify-storage-api";
import { useOnMouseEventPreventDefaultHandler } from "@ds/utils/handlers";

import { isContentTypeAudio, isContentTypeImage, isContentTypeVideo } from "../../utils/helpers";
import { ContentAssetPreview } from "./content-asset-preview";

export const DEFAULT_THUMBNAIL_WIDTH = "150";
export const DEFAULT_THUMBNAIL_HEIGHT = "100";

const ContentAssetThumbnailWrapper = styled.div<{
  width?: string;
  height?: string;
}>`
  position: relative;
  width: ${props => props.width || `${DEFAULT_THUMBNAIL_WIDTH}px`};
  height: ${props => props.height || `${DEFAULT_THUMBNAIL_HEIGHT}px`};
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: var(--textColor005);
`;

const Image = styled.img<{
  width?: string;
  height?: string;
}>`
  width: ${props => props.width || `${DEFAULT_THUMBNAIL_WIDTH}px`};
  height: ${props => props.height || `${DEFAULT_THUMBNAIL_HEIGHT}px`};
  box-shadow:
    0 3px 6px var(--blackShadowColor015),
    0 3px 6px var(--blackShadowColor025);
  object-fit: contain;
`;

const Video = styled.video<{
  width?: string;
  height?: string;
}>`
  width: ${props => props.width || `${DEFAULT_THUMBNAIL_WIDTH}px`};
  height: ${props => props.height || `${DEFAULT_THUMBNAIL_HEIGHT}px`};
  box-shadow:
    0 3px 6px var(--blackShadowColor015),
    0 3px 6px var(--blackShadowColor025);
  object-fit: contain;
`;

const Placeholder = styled.i<{
  width?: string;
  height?: string;
}>`
  width: ${props => props.width || `${DEFAULT_THUMBNAIL_WIDTH}px`};
  height: ${props => props.height || `${DEFAULT_THUMBNAIL_HEIGHT}px`};
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 3rem;
  color: var(--textColor060);
`;

const IndicatorIconWrapper = styled.div`
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  opacity: 0;
  transition: opacity 0.3s;
  cursor: pointer;

  &:hover {
    opacity: 1;
    background: var(--blackShadowColor050);
  }
`;

const IndicatorIcon = styled.i`
  color: var(--lightSurfaceColor);
  font-size: 1.4rem;
`;

export const ContentAssetThumbnail: FC<{
  asset: ContentAsset;
  width?: string;
  height?: string;
  wrapperWidth?: string;
  wrapperHeight?: string;
  showPreview?: boolean;
}> = ({ asset, width, height, wrapperWidth, wrapperHeight, showPreview = true }) => {
  const onMouseDownHandler = useOnMouseEventPreventDefaultHandler();
  const [signedUrl, fetchSignedUrl] = useFetchAmplifyStorageDataSignedUrl();

  useEffect(() => {
    if (isContentTypeVideo(asset.content_type)) {
      if (asset.thumbnail?.key) {
        fetchSignedUrl(asset.thumbnail?.key);
      } else {
        fetchSignedUrl(asset.key);
      }
    } else {
      fetchSignedUrl(asset.thumbnail?.key);
    }
  }, [asset, fetchSignedUrl]);

  return (
    <PortalWithState closeOnOutsideClick closeOnEsc>
      {({ openPortal, closePortal, portal }) => (
        <>
          <ContentAssetThumbnailWrapper
            tabIndex={0}
            role="button"
            width={wrapperWidth || width}
            height={wrapperHeight || height}
            onClick={openPortal}
            onKeyPress={openPortal}
            onMouseDown={onMouseDownHandler}
          >
            {signedUrl && isContentTypeVideo(asset.content_type) && asset.thumbnail?.key && (
              <Image key={`video-content-asset-${asset.key}`} width={width} height={height} src={signedUrl} />
            )}

            {signedUrl && isContentTypeVideo(asset.content_type) && !asset.thumbnail?.key && (
              <Video key={`video-content-asset-${asset.key}`} width={width} height={height} src={signedUrl} />
            )}

            {!signedUrl && isContentTypeVideo(asset.content_type) && (
              <Placeholder
                key={`video-placeholder-${asset.key}`}
                width={width}
                height={height}
                className="pi pi-video"
              />
            )}

            {signedUrl && isContentTypeImage(asset.content_type) && (
              <Image key={`image-content-asset-${asset.key}`} width={width} height={height} src={signedUrl} />
            )}

            {!signedUrl && isContentTypeImage(asset.content_type) && (
              <Placeholder
                key={`image-placeholder-${asset.key}`}
                width={width}
                height={height}
                className="pi pi-image"
              />
            )}

            {signedUrl && isContentTypeAudio(asset.content_type) && (
              <Image key={`audio-content-asset-${asset.key}`} width={width} height={height} src={signedUrl} />
            )}

            {!signedUrl && isContentTypeAudio(asset.content_type) && (
              <Placeholder
                key={`audio-placeholder-${asset.key}`}
                width={width}
                height={height}
                className="pi pi-volume-up"
              />
            )}

            {showPreview && (
              <IndicatorIconWrapper>
                <IndicatorIcon className="pi pi-eye" />
              </IndicatorIconWrapper>
            )}
          </ContentAssetThumbnailWrapper>

          {showPreview && portal(<ContentAssetPreview asset={asset} onClose={closePortal} />)}
        </>
      )}
    </PortalWithState>
  );
};
