import { produce } from "immer";
import { type SetOptional } from "type-fest";

import { normalizeBase } from "@ds/model/helpers";

import { normalize as projectNormalize } from "@ds/modules/settings/projects/utils/normalizer";

import { isApiInvitation, isInvitationArray, isInvitationQueryOutput } from "./model";

const normalizer = (entity: ApiInvitation) =>
  produce(normalizeBase(entity), (draft: ApiInvitation) => {
    draft.projects = projectNormalize(entity.projects);
  });

export const normalize = ((
  entityOrEntities: ApiInvitation | ApiInvitation[] | QueryOutput<ApiInvitation>,
): ApiInvitation | ApiInvitation[] | QueryOutput<ApiInvitation> => {
  if (isInvitationQueryOutput(entityOrEntities)) {
    return produce(entityOrEntities, draft => {
      draft.items = entityOrEntities.items.map(normalizer);
    });
  }

  return isInvitationArray(entityOrEntities) ? entityOrEntities.map(normalizer) : normalizer(entityOrEntities);
}) as {
  (invitation: ApiInvitation): ApiInvitation;
  (invitations: ApiInvitation[]): ApiInvitation[];
  (invitationsQueryOutput: QueryOutput<ApiInvitation>): QueryOutput<ApiInvitation>;
};

const apiToModelNormalizer = (entity: Invitation | ApiInvitation): Invitation => {
  if (!isApiInvitation(entity)) {
    return entity;
  }

  const result = produce(normalizeBase(entity), (draft: SetOptional<ApiInvitation, "projects">) => {
    delete draft.projects;
  }) as Invitation;

  return result;
};

export const apiToModelNormalize = ((
  entityOrEntities:
    | Invitation
    | Invitation[]
    | QueryOutput<Invitation>
    | ApiInvitation
    | ApiInvitation[]
    | QueryOutput<ApiInvitation>,
): Invitation | Invitation[] | QueryOutput<Invitation> => {
  if (isInvitationQueryOutput(entityOrEntities)) {
    return produce(entityOrEntities, draft => {
      draft.items = entityOrEntities.items.map(item => apiToModelNormalizer(item) as ApiInvitation);
    }) as QueryOutput<Invitation>;
  }

  return isInvitationArray(entityOrEntities)
    ? entityOrEntities.map(apiToModelNormalizer)
    : apiToModelNormalizer(entityOrEntities);
}) as {
  (invitation: Invitation): Invitation;
  (invitation: Invitation[]): Invitation[];
  (invitation: QueryOutput<ApiInvitation>): QueryOutput<Invitation>;
  (invitation: ApiInvitation): Invitation;
  (invitations: ApiInvitation[]): Invitation[];
  (invitationsQueryOutput: QueryOutput<ApiInvitation>): QueryOutput<Invitation>;
};
