import { type FC } from "react";
import { Controller, type Control } from "react-hook-form";
import styled from "styled-components";

import { InputText } from "@ds/components/forms/inputs";
import { renderErrorMessage } from "@ds/components/forms/labels";

import { type UserRestorePasswordModel } from "../../../redux/types";

const FieldWrapper = styled.div`
  margin-bottom: 40px;
`;

const AuthInputText = styled(InputText)`
  width: 100%;
`;

const ErrorWrapper = styled.div`
  display: inline-block;
  div {
    margin-top: 5px;
  }
`;

export const RestoreCodeControl: FC<{
  control: Control<UserRestorePasswordModel>;
  autoComplete?: string;
}> = ({ control, autoComplete = "one-time-code" }) => (
  <Controller
    name="code"
    control={control}
    render={({ field: { ref, ...restFields }, fieldState }) => (
      <FieldWrapper>
        <span className="p-float-label">
          <AuthInputText
            id="restore-code"
            inputRef={ref}
            {...restFields}
            autoComplete={autoComplete}
            data-lpignore="true"
            isRequired
          />

          <label htmlFor="restore-code">Restore Code</label>
        </span>
        <ErrorWrapper>{renderErrorMessage(fieldState.error)}</ErrorWrapper>
      </FieldWrapper>
    )}
  />
);
