import { type FC } from "react";
import styled from "styled-components";

import { StatusEnum } from "@ds/model/status-model";

import { StatusMessage } from "../status-message";

const TextError = styled.div`
  color: var(--error-text-color);
  font-size: 0.95rem;
  margin-top: 20px;
`;

export const getStatusMessage: FC<{
  isLoading: boolean;
  errorMessage: string | null;
  loadingText?: string;
  successText?: string;
  failedText?: string;
}> = ({
  isLoading,
  errorMessage,
  loadingText = "Adding entry...",
  successText = "Entry added successfully!",
  failedText = "Failed to add new entry!",
}) => {
  const content = isLoading ? (
    <StatusMessage status={StatusEnum.Executing} message={loadingText} />
  ) : (
    <StatusMessage status={StatusEnum.Success} message={successText} />
  );

  return errorMessage ? (
    <StatusMessage status={StatusEnum.Failed} message={failedText}>
      <TextError>{errorMessage}</TextError>
    </StatusMessage>
  ) : (
    content
  );
};
