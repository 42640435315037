import { isArray } from "lodash";

import { EntityTypeName, isEntity } from "@ds/model/entity-model";

import { isPlainObject } from "@ds/utils/type-guards/common-guards";

export const isProject = (obj: unknown): obj is Project =>
  isEntity(obj) && obj.__typename === EntityTypeName.SETTINGS_PROJECT;

export const isProjectArray = (obj: unknown): obj is Project[] => isArray(obj) && obj.every(isProject);

export const isProjectQueryOutput = (obj: unknown): obj is QueryOutput<Project> =>
  isPlainObject(obj) && "items" in obj && isProjectArray(obj.items);
