import { useCallback, type FC } from "react";
import { useNavigate } from "react-router-dom";

import { useReduxEntitiesFetch } from "@ds/hooks/use-redux-entities-fetch";

import { EntityTypeName } from "@ds/model/entity-model";

import { deploymentsActions, DeploymentsRemoteOperation } from "@ds/modules/deployments/redux/slice";
import { createDeploymentModel, DeploymentScopeEnum } from "@ds/modules/deployments/utils/model";
import { MainTableDataTypeEnum } from "@ds/modules/table-data/utils/model";

import { ActionsBar } from "@ds/components/actions-bar";
import { Button, ButtonActionType, ButtonType } from "@ds/components/forms/buttons";
import { IconType } from "@ds/components/icons";
import { InfoViewToggleRibbonButton } from "@ds/components/info-view/info-view-toggle-ribbon-button";
import { MenuWithButton } from "@ds/components/menu-dropdown/menu-with-button";
import { confirmSuccess, confirmWarning } from "@ds/components/popups/confirmation-dialog";
import { useDeepRootSelector, useRootDispatch, useRootSelector } from "@ds/hooks";
import { pluralizeDeleteMessage } from "@ds/utils/localization/common";
import {
  pluralizeDeployDescription,
  pluralizeDeployMessage,
  pluralizeDeployName,
} from "@ds/utils/localization/deployment";
import { composeDetailsRouteByEntityType } from "@ds/utils/router";

import {
  selectAggregatedExperienceForSelectedEntities,
  selectSelectedExperienceIds,
  selectSelectedExperiences,
} from "../../redux/selectors";
import { experiencesActions } from "../../redux/slice";

export const ExperiencesTableActionsMenu: FC<{ onCreate: () => void }> = ({ onCreate }) => {
  const navigate = useNavigate();

  const aggregatedExperience = useDeepRootSelector(selectAggregatedExperienceForSelectedEntities);
  const selectedExperienceIds = useRootSelector(selectSelectedExperienceIds);
  const selectedExperiences = useRootSelector(selectSelectedExperiences);

  const [toggleRefetch] = useReduxEntitiesFetch(
    { entityType: EntityTypeName.EXPERIENCE },
    { tableType: MainTableDataTypeEnum.Experiences },
  );

  const dispatch = useRootDispatch();
  const onUpdateClickHandler = useCallback(
    () => navigate(composeDetailsRouteByEntityType(aggregatedExperience)),
    [navigate, aggregatedExperience],
  );

  const onDeleteClickHandler = useCallback(
    () =>
      confirmWarning({
        message: pluralizeDeleteMessage("experience", selectedExperienceIds.length),
        accept: () => dispatch(experiencesActions.deleteExperiences(selectedExperienceIds)),
      }),
    [dispatch, selectedExperienceIds],
  );

  const onDeployClickHandler = useCallback(
    () =>
      confirmSuccess({
        message: pluralizeDeployMessage("experience", selectedExperienceIds.length),
        accept: () =>
          dispatch(
            deploymentsActions.createDeployment(
              {
                ...createDeploymentModel(),
                name: pluralizeDeployName(selectedExperiences),
                description: pluralizeDeployDescription(selectedExperiences),
                scope: DeploymentScopeEnum.Experience,
                targets: selectedExperienceIds,
              },
              { remoteOperation: DeploymentsRemoteOperation.DEPLOYING },
            ),
          ),
      }),
    [dispatch, selectedExperiences, selectedExperienceIds],
  );

  return (
    <ActionsBar>
      <MenuWithButton
        menuItems={[
          {
            label: "Deploy Content",
            icon: "pi pi-fw pi-sitemap",
            command: onDeployClickHandler,
          },
        ]}
        isDisabled={!selectedExperienceIds.length}
      />

      <Button ariaLabel="create button" buttonType={ButtonType.Round} icon={IconType.Create} onClick={onCreate} />
      <Button
        ariaLabel="edit button"
        buttonType={ButtonType.Round}
        icon={IconType.Update}
        isDisabled={!selectedExperienceIds.length}
        onClick={onUpdateClickHandler}
      />

      <Button
        ariaLabel="delete button"
        buttonType={ButtonType.Round}
        actionType={ButtonActionType.Error}
        icon={IconType.Delete}
        isDisabled={!selectedExperienceIds.length}
        onClick={onDeleteClickHandler}
      />

      <Button
        ariaLabel="refresh button"
        buttonType={ButtonType.Round}
        icon={IconType.Refresh}
        onClick={toggleRefetch}
      />

      <InfoViewToggleRibbonButton />
    </ActionsBar>
  );
};
