import { createSelector } from "@reduxjs/toolkit";
import { ListIteratee, Many, orderBy, uniq } from "lodash";

import { DetailsTableDataTypeEnum, MainTableDataTypeEnum } from "@ds/modules/table-data/utils/model";

import { type RootState } from "@ds/store/bootstrap";
import { selectorsExtractIds, selectorsOutputEntitiesByIds } from "@ds/store/global-selectors";

import { getAggregatedModel } from "@ds/utils/entities";
import { LoadStatus } from "@ds/utils/reducer";

import { entitySelectors, PlaylistAssetsRemoteOperation } from "../slice";

const extractSortIteratees = (
  _: RootState,
  _tableType: MainTableDataTypeEnum | DetailsTableDataTypeEnum,
  sortIteratees?: Many<ListIteratee<PlaylistAsset>>,
) => sortIteratees;

const extractSortOrder = (
  _: RootState,
  _tableType: MainTableDataTypeEnum | DetailsTableDataTypeEnum,
  _sortIteratees: Many<ListIteratee<PlaylistAsset>>,
  sortOrders?: Many<boolean | "asc" | "desc">,
) => sortOrders;

export const selectBaseState = (state: RootState) => state.content.playlistAssets;
const selectLoadStatus = (state: RootState, operation = PlaylistAssetsRemoteOperation.CRUD) =>
  selectBaseState(state).loadStatusMap[operation];

export const selectPlaylistAssetsIsLoading = (state: RootState, operation = PlaylistAssetsRemoteOperation.CRUD) =>
  selectLoadStatus(state, operation) === LoadStatus.Loading;

export const selectPlaylistAssetsError = (state: RootState) => selectBaseState(state).error;

export const selectTablePlaylistAssetIds = (
  state: RootState,
  tableType: MainTableDataTypeEnum | DetailsTableDataTypeEnum = DetailsTableDataTypeEnum.ContentPlaylistAssets,
) => selectBaseState(state).tableIds[tableType];

export const selectSelectedPlaylistAssetIds = (
  state: RootState,
  tableType: MainTableDataTypeEnum | DetailsTableDataTypeEnum = DetailsTableDataTypeEnum.ContentPlaylistAssets,
) => selectBaseState(state).selectedIds[tableType];

export const selectPlaylistAssetById = (state: RootState, id: number) =>
  entitySelectors.selectById(selectBaseState(state), id) as PlaylistAsset;

export const selectPlaylistAssetIds = (state: RootState) => entitySelectors.selectIds(selectBaseState(state));
export const selectPlaylistAssets = (state: RootState) => entitySelectors.selectAll(selectBaseState(state));

export const selectPlaylistAssetsByIds = createSelector(
  selectBaseState,
  selectorsExtractIds,
  selectorsOutputEntitiesByIds(() => entitySelectors),
);

export const selectTablePlaylistAssets = createSelector(
  selectBaseState,
  selectTablePlaylistAssetIds,
  selectorsOutputEntitiesByIds(() => entitySelectors),
);

export const selectSelectedPlaylistAssets = createSelector(
  selectBaseState,
  selectSelectedPlaylistAssetIds,
  selectorsOutputEntitiesByIds(() => entitySelectors),
);

export const selectAggregatedPlaylistAssetByIds = createSelector(selectBaseState, selectorsExtractIds, (state, ids) =>
  ids?.length ? getAggregatedModel(selectorsOutputEntitiesByIds(entitySelectors)(state, ids)) : null,
);

export const selectAggregatedPlaylistAssetForSelectedEntities = createSelector(
  selectSelectedPlaylistAssets,
  selectedPlaylistAssets => getAggregatedModel(selectedPlaylistAssets),
);

export const selectPlaylistAssetsByPlaylistId = createSelector(
  selectBaseState,
  selectorsExtractIds,
  (state, playlistIds) => {
    const normalizedPlaylistIds = uniq(playlistIds as number[]);
    return entitySelectors.selectAll(state).filter(({ playlist_id }) => normalizedPlaylistIds.includes(playlist_id));
  },
);

// TODO: CHECK FOR WHAT SORTING HERE
export const selectAggregatedPlaylistAssetIndexForSelectedEntities = createSelector(
  selectPlaylistAssets,
  selectAggregatedPlaylistAssetForSelectedEntities,
  extractSortIteratees,
  extractSortOrder,
  (playlistAssets, selectedPlaylistAsset, sortIteratees, sortOrders) => {
    const sortedPlaylistAssets = sortIteratees ? orderBy(playlistAssets, sortIteratees, sortOrders) : playlistAssets;
    return selectedPlaylistAsset ? sortedPlaylistAssets.findIndex(asset => asset.id === selectedPlaylistAsset.id) : -1;
  },
);
