import { useCallback, type FC } from "react";
import styled from "styled-components";

import { useGetAggregatedEntityIds } from "@ds/hooks/use-get-aggregated-entity-ids";
import { useVisibility } from "@ds/hooks/use-visibility";

import { isAggregatedEntity } from "@ds/model/entity-model";
import { StatusEnum } from "@ds/model/status-model";

import { ActionsBar } from "@ds/components/actions-bar";
import { Button, ButtonActionType, ButtonType } from "@ds/components/forms/buttons";
import { IconType } from "@ds/components/icons";
import { MenuWithButton } from "@ds/components/menu-dropdown/menu-with-button";
import { confirmSuccess, confirmWarning } from "@ds/components/popups/confirmation-dialog";
import { DEPLOYMENTS_ROUTE } from "@ds/constants/router";
import { useRootDispatch, useRootSelector } from "@ds/hooks";
import { pluralizeDeleteMessage } from "@ds/utils/localization/common";
import { pluralizeExecuteMessage, pluralizeUnscheduleMessage } from "@ds/utils/localization/deployment";

import { selectDeploymentsByIds } from "../../redux/selectors";
import { deploymentsActions } from "../../redux/slice";
import { isAllowedToDelete } from "../../utils/helpers";
import { ScheduleDeploymentsModal } from "../forms/schedule-deployments-form/schedule-deployments-modal";

const Wrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

export const DeploymentDetailsInfoCardActionsMenu: FC<{ deployment: Deployment }> = ({ deployment }) => {
  const aggregatedDeploymentIds = useGetAggregatedEntityIds(deployment);
  const aggregatedDeployments = useRootSelector(state => selectDeploymentsByIds(state, aggregatedDeploymentIds));

  const [isScheduleModalVisible, setIsScheduleModalVisible] = useVisibility();

  const dispatch = useRootDispatch();
  const onDeleteClickHandler = useCallback(
    () =>
      confirmWarning({
        message: pluralizeDeleteMessage("deployment", aggregatedDeploymentIds.length),
        accept: () => dispatch(deploymentsActions.deleteDeployments(aggregatedDeploymentIds, DEPLOYMENTS_ROUTE)),
      }),
    [dispatch, aggregatedDeploymentIds],
  );

  const onExecuteClickHandler = useCallback(
    () =>
      confirmSuccess({
        message: pluralizeExecuteMessage("deployment", aggregatedDeploymentIds.length),
        accept: () => dispatch(deploymentsActions.scheduleDeployments(aggregatedDeploymentIds)),
      }),
    [dispatch, aggregatedDeploymentIds],
  );

  const onUnscheduleClickHandler = useCallback(
    () =>
      confirmSuccess({
        message: pluralizeUnscheduleMessage("deployment", aggregatedDeploymentIds.length),
        accept: () => dispatch(deploymentsActions.unscheduleDeployments(aggregatedDeploymentIds)),
      }),
    [dispatch, aggregatedDeploymentIds],
  );

  const validStatuses = [StatusEnum.Pending, StatusEnum.Scheduled];

  return (
    <>
      <Wrapper>
        <ActionsBar>
          <MenuWithButton
            menuItems={[
              {
                label: "Execute",
                icon: "pi pi-fw pi-play",
                disabled: aggregatedDeployments.some(({ status }) => !validStatuses.includes(status as StatusEnum)),
                command: onExecuteClickHandler,
              },
              {
                label: deployment.status === StatusEnum.Scheduled ? "Reschedule" : "Schedule",
                icon: "pi pi-fw pi-calendar-plus",
                disabled: isAggregatedEntity(deployment) || !validStatuses.includes(deployment.status as StatusEnum),
                command: setIsScheduleModalVisible(true),
              },
              {
                label: "Unschedule",
                icon: "pi pi-fw pi-calendar-times",
                disabled: isAggregatedEntity(deployment) || deployment.status !== StatusEnum.Scheduled,
                command: onUnscheduleClickHandler,
              },
            ]}
          />

          <Button
            label="Delete"
            ariaLabel="delete button"
            buttonType={ButtonType.Round}
            actionType={ButtonActionType.Error}
            icon={IconType.Delete}
            onClick={onDeleteClickHandler}
            isDisabled={!isAllowedToDelete([deployment])}
          />
        </ActionsBar>
      </Wrapper>

      {isScheduleModalVisible && (
        <ScheduleDeploymentsModal deployments={[deployment]} onClose={setIsScheduleModalVisible(false)} />
      )}
    </>
  );
};
