import { type FC } from "react";
import { useLocation } from "react-router-dom";
import styled from "styled-components";

import { trimAll } from "@ds/utils/strings";

import { NavTextLink, type NavLinkProps } from "../text-link";

const ACTIVE_CLASS_NAME = "selected";
const StyledNavLink = styled(NavTextLink)`
  &[aria-current] {
    color: ${props => props.theme.navigationColor};
  }

  color: ${props => props.theme.navigationColor};

  &.${ACTIVE_CLASS_NAME} {
    color: ${props => props.theme.navigationActiveColor};
    background: ${props => props.theme.navigationActiveBackgroundColor};
  }

  :not(.${ACTIVE_CLASS_NAME}):hover {
    background-color: ${props => props.theme.navigationHoverBackgroundColor};
  }

  :hover,
  i:hover:before {
    font-weight: inherit;
  }
`;

const Wrapper = styled.div`
  display: flex;
  flex-flow: column;
  align-items: center;
  margin-bottom: 30px;
`;

const Icon = styled.i`
  font-size: 2.5rem;
  padding: 5px 0;
}
`;

export const NavigationLink: FC<
  {
    to: string;
    text: string;
    icon: string;
    matchedRoutes?: string[];
    className?: string;
  } & NavLinkProps
> = ({ to, icon, text, matchedRoutes, className, ...restProps }) => {
  const { pathname } = useLocation();
  const isActive = (matchedRoutes || [to])?.reduce((acc, curr) => acc || pathname.startsWith(curr), false);

  return (
    <StyledNavLink
      to={to}
      className={trimAll(`${isActive ? ACTIVE_CLASS_NAME : ""} ${className || ""}`)}
      {...restProps}
    >
      <Wrapper>
        <Icon className={icon} />
        <span>{text}</span>
      </Wrapper>
    </StyledNavLink>
  );
};
