import { yupResolver } from "@hookform/resolvers/yup";
import { uniq } from "lodash";
import { useCallback, type FC } from "react";
import { type DeepPartial } from "react-hook-form";
import styled from "styled-components";

import { useGetAggregatedEntityIds } from "@ds/hooks/use-get-aggregated-entity-ids";

import { isAggregatedEntity } from "@ds/model/entity-model";

import { getPlayerStatusIndicatorWithNameAndLink } from "@ds/modules/devices/players/components/common/status-indicator";
import { selectPlayersByIds } from "@ds/modules/devices/players/redux/selectors";

import {
  DetailsCard,
  DetailsCardEditActionsMenu,
  DetailsCardRow,
  DetailsCardRowWrapper,
  EditableDetailsCardDecorator,
  EditableDetailsCardRowDecorator,
  type EditableDetailsCardChildProps,
} from "@ds/components/details-view";
import { MultipleValuesLabelWithLink, UnassignedLabel } from "@ds/components/forms/labels/common";
import { useDeepRootSelector } from "@ds/hooks";
import { isMultipleFieldValuesInAggregatedEntity } from "@ds/utils/entities";
import { composeDetailsRouteByEntityType } from "@ds/utils/router";

import { selectExperiencesByIds } from "../../redux/selectors";
import { updateValidationSchema } from "../../utils/validation-schema";
import { ExperiencePlayersControl } from "../forms/controls/experience-players-control";

export const Wrapper = styled(DetailsCardRowWrapper)`
  :last-child {
    border-bottom: none;
    padding-bottom: 0;
  }
`;

export const ExperienceDetailsPlayersCard: FC<{
  experience: Experience;
  onSubmit: (changedFields: DeepPartial<Experience>) => void;
}> = ({ experience, onSubmit }) => {
  const aggregatedExperienceIds = useGetAggregatedEntityIds(experience);
  const aggregatedExperiencePlayers = useDeepRootSelector(state => {
    const aggregatedExperiences = selectExperiencesByIds(state, aggregatedExperienceIds);
    const aggregatedPlayersIds = uniq(aggregatedExperiences.flatMap(({ device_ids }) => device_ids).filter(_ => _));
    return selectPlayersByIds(state, aggregatedPlayersIds, "experience_order");
  });

  const onSubmitHandler = ({ players }: DeepPartial<ExperienceFormData>) =>
    onSubmit({ device_ids: players?.map(player => player?.id).filter(_ => _) });

  const detailsBody = useCallback(
    (cardProps: EditableDetailsCardChildProps<ExperienceFormData>) => {
      if (isMultipleFieldValuesInAggregatedEntity(cardProps.entity.experience, "device_ids") && !cardProps.isDirty) {
        return <MultipleValuesLabelWithLink to={composeDetailsRouteByEntityType(aggregatedExperiencePlayers)} />;
      }

      if (
        !isMultipleFieldValuesInAggregatedEntity(cardProps.entity.experience, "device_ids") &&
        !cardProps.entity.players?.length &&
        !cardProps.isEditing
      ) {
        return <UnassignedLabel text="Experience(s) has no players" />;
      }

      return (
        <>
          {cardProps.entity.players?.map(entry => (
            <Wrapper key={`experience-details-player-${entry?.id}`}>
              {getPlayerStatusIndicatorWithNameAndLink(entry)}
            </Wrapper>
          ))}
        </>
      );
    },
    [aggregatedExperiencePlayers],
  );

  return (
    <EditableDetailsCardDecorator<ExperienceFormData>
      initialValue={{ experience, players: aggregatedExperiencePlayers }}
      validationResolver={yupResolver(updateValidationSchema.players)}
      onSubmit={onSubmitHandler}
    >
      {cardProps => (
        <DetailsCard
          title="Players"
          icon="desktop"
          actions={
            <DetailsCardEditActionsMenu
              isEditing={cardProps.isEditing}
              isDirty={cardProps.isDirty}
              isEditHidden={isAggregatedEntity(cardProps.entity.experience)}
              onEdit={cardProps.onEditRow("players")}
              onReset={cardProps.onResetForm}
              onSubmit={cardProps.handleSubmit(cardProps.onSubmitForm)}
            />
          }
        >
          <EditableDetailsCardRowDecorator
            field="players"
            input={<ExperiencePlayersControl control={cardProps.control} autoFocus excludeLabel />}
            shouldEditWholeCard
            shouldHideEdit={isAggregatedEntity(cardProps.entity.experience)}
            {...cardProps}
          >
            {rowProps => (
              <DetailsCardRow label="Choose players" editableSettings={rowProps}>
                {detailsBody(cardProps)}
              </DetailsCardRow>
            )}
          </EditableDetailsCardRowDecorator>
        </DetailsCard>
      )}
    </EditableDetailsCardDecorator>
  );
};
