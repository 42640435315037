import { useRef, type FC, type MouseEvent } from "react";

import { ButtonType } from "../forms/buttons";
import { IconType } from "../icons";
import { ConfirmTypeEnum, confirmByType } from "../popups/confirmation-dialog";
import { DropdownButton, DropdownMenu } from "./controls";
import { type ActionMenu, type ActionMenuItemCommandEvent, type ActionMenuProps } from "./types";

export const MenuWithButton: FC<ActionMenuProps> = ({ menuItems, menuTitle = "ACTIONS", isDisabled = false }) => {
  const menuRef = useRef<ActionMenu>(null);
  const primeItems = menuItems.map(
    ({
      label,
      separator,
      icon,
      items,
      disabled,
      className,
      style,
      target,
      confirmTitleSuccess,
      confirmMessageSuccess,
      confirmTitleWarning,
      confirmMessageWarning,
      command,
    }) => ({
      label,
      separator,
      icon,
      items,
      disabled,
      className,
      style,
      target,
      command: (e: ActionMenuItemCommandEvent) => {
        if (!command) {
          return;
        }

        const confirmMessage = confirmMessageSuccess || confirmMessageWarning;
        const confirmTitle = confirmTitleSuccess || confirmTitleWarning;
        if (confirmMessage) {
          confirmByType({
            type: confirmMessageSuccess ? ConfirmTypeEnum.SUCCESS : ConfirmTypeEnum.WARNING,
            header: confirmTitle,
            message: confirmMessage,
            accept: () => {
              command(e);
            },
          });
        } else {
          command(e);
        }
      },
    }),
  );

  const onClickHandler = (e: MouseEvent<HTMLButtonElement>) => {
    if (primeItems.length) {
      menuRef.current?.toggle(e);
    }
  };

  return (
    <div className="mr-3">
      <DropdownMenu ref={menuRef} popup model={primeItems} id="actions-menu" />
      <DropdownButton
        label={menuTitle}
        ariaLabel="menu button"
        buttonType={ButtonType.Outlined}
        icon={IconType.Down}
        iconPosition="right"
        onClick={onClickHandler}
        isDisabled={isDisabled}
        aria-haspopup
        aria-controls="actions-menu"
      />
    </div>
  );
};
