import pluralize from "pluralize";

import { type ActionMenuItem } from "@ds/components/menu-dropdown/types";

import { CommandTargetEnum } from "../utils/command-model";
import { PlayerCommands, PlayerCommandsFriendlyNames } from "./common";

export const cecAllOnCommand = "all:on";
export const cecAllOffCommand = "all:off";

export const rebootCommandMenuItem = (
  onCommand: (options: SendCommand) => void,
  disabled = false,
  totalCount = 1,
): ActionMenuItem => ({
  label: PlayerCommandsFriendlyNames.REBOOT,
  icon: "pi pi-fw pi-power-off",
  disabled,
  confirmTitleWarning: "Reboot confirmation",
  confirmMessageWarning: `Are you sure you want to reboot selected ${pluralize("player", totalCount)}?`,
  command: () =>
    onCommand({
      target: CommandTargetEnum.Core,
      name: PlayerCommands.REBOOT,
      friendly_name: PlayerCommandsFriendlyNames.REBOOT,
      data: "",
    }),
});

export const logsCommandMenuItem = (
  onCommand: (options: SendCommand) => void,
  disabled = false,
  totalCount = 1,
): ActionMenuItem => ({
  label: PlayerCommandsFriendlyNames.LOGS,
  icon: "pi pi-fw pi-file-o",
  disabled,
  confirmMessageSuccess: `Are you sure you want to retrieve logs from selected ${pluralize("player", totalCount)}?`,
  command: () =>
    onCommand({
      target: CommandTargetEnum.Core,
      name: PlayerCommands.LOGS,
      friendly_name: PlayerCommandsFriendlyNames.LOGS,
      data: "",
    }),
});

export const resetContentMenuItem = (
  onCommand: (options: SendCommand) => void,
  disabled = false,
  totalCount = 1,
): ActionMenuItem => ({
  label: PlayerCommandsFriendlyNames.RESET_CONTENT,
  icon: "pi pi-fw pi-history",
  disabled,
  confirmTitleWarning: "Reset content confirmation",
  confirmMessageWarning: `This command clears all the deployed content and configuration on the selected ${pluralize(
    "player",
    totalCount,
  )}? Are you sure you want to proceed?`,
  command: () =>
    onCommand({
      target: CommandTargetEnum.Core,
      name: PlayerCommands.RESET_CONTENT,
      friendly_name: PlayerCommandsFriendlyNames.RESET_CONTENT,
      data: "",
    }),
});

export const createCommandMenuItem = (
  name: string,
  friendlyName: string,
  icon: string,
  data: string,
  onCommand: (options: SendCommand) => void,
  disabled = false,
  totalCount = 1,
): ActionMenuItem => ({
  label: friendlyName,
  icon,
  disabled,
  confirmTitleWarning: `Command confirmation`,
  confirmMessageWarning: `Are you sure you want to send the command to selected ${pluralize("player", totalCount)}?`,
  command: () =>
    onCommand({
      target: CommandTargetEnum.Core,
      name,
      friendly_name: friendlyName,
      data,
    }),
});
