import { type FC } from "react";
import { Navigate, Route, Routes, useLocation, useParams } from "react-router-dom";

import { MULTIPLE_IDS_PARAM, SETTINGS_PROJECTS_ROUTE, USER_ID_PARAM } from "@ds/constants/router";
import { useRootSelector } from "@ds/hooks";

import { selectCurrentUser, selectCurrentUserIsTenantAdmin } from "./redux/selectors";
import { UserDetailsPage } from "./user-details-page";
import { UsersMainPage } from "./users-main-page";

const UsersRouteSwitch: FC<{ isAdmin: boolean }> = ({ isAdmin }) => {
  const { search } = useLocation();

  if (!isAdmin) {
    return <Navigate to={SETTINGS_PROJECTS_ROUTE} replace />;
  }

  return search.startsWith(`?${MULTIPLE_IDS_PARAM}`) ? <UserDetailsPage /> : <UsersMainPage />;
};

const UsersDetailsPageSwitch: FC<{ isAdmin: boolean }> = ({ isAdmin }) => {
  const currentUser = useRootSelector(selectCurrentUser);
  const userId = Number(useParams()["*"]);
  const isEditingProfileOrAdmin = isAdmin || (currentUser && currentUser.id === userId);
  return isEditingProfileOrAdmin ? <UserDetailsPage /> : <Navigate to={SETTINGS_PROJECTS_ROUTE} replace />;
};

export const UsersRouter: FC = () => {
  const isAdmin = useRootSelector(selectCurrentUserIsTenantAdmin);
  return (
    <Routes>
      <Route path="*" element={<UsersRouteSwitch isAdmin={isAdmin} />} />
      <Route path={`:${USER_ID_PARAM}`} element={<UsersDetailsPageSwitch isAdmin={isAdmin} />} />
    </Routes>
  );
};
