import { produce } from "immer";
import { type SetOptional } from "type-fest";

import { normalizeBase } from "@ds/model/helpers";

import { normalize as playerNormalize } from "@ds/modules/devices/players/utils/normalizer";

import { isApiPeripheral, isPeripheralArray, isPeripheralQueryOutput } from "./model";

const normalizer = (entity: ApiPeripheral) =>
  produce(normalizeBase(entity), (draft: ApiPeripheral) => {
    draft.description = entity.description || undefined;
    draft.device = playerNormalize(entity.device);
  });

export const normalize = ((
  entityOrEntities: ApiPeripheral | ApiPeripheral[] | QueryOutput<ApiPeripheral>,
): ApiPeripheral | ApiPeripheral[] | QueryOutput<ApiPeripheral> => {
  if (isPeripheralQueryOutput(entityOrEntities)) {
    return produce(entityOrEntities, draft => {
      draft.items = entityOrEntities.items.map(normalizer);
    });
  }

  const isArray = isPeripheralArray(entityOrEntities);
  if (isArray) {
    return entityOrEntities.map(normalizer);
  }

  return normalizer(entityOrEntities);
}) as {
  (peripheral: ApiPeripheral): ApiPeripheral;
  (peripherals: ApiPeripheral[]): ApiPeripheral[];
  (peripheralsQueryOutput: QueryOutput<ApiPeripheral>): QueryOutput<ApiPeripheral>;
};

const apiToModelNormalizer = (entity: Peripheral | ApiPeripheral): Peripheral => {
  if (!isApiPeripheral(entity)) {
    return entity;
  }

  return produce(normalizeBase(entity), (draft: SetOptional<ApiPeripheral, "device">) => {
    delete draft.device;
  });
};

export const apiToModelNormalize = ((
  entityOrEntities:
    | Peripheral
    | Peripheral[]
    | QueryOutput<Peripheral>
    | ApiPeripheral
    | ApiPeripheral[]
    | QueryOutput<ApiPeripheral>,
): Peripheral | Peripheral[] | QueryOutput<Peripheral> => {
  if (isPeripheralQueryOutput(entityOrEntities)) {
    return produce(entityOrEntities, draft => {
      draft.items = entityOrEntities.items.map(item => apiToModelNormalizer(item) as ApiPeripheral);
    });
  }

  return isPeripheralArray(entityOrEntities)
    ? entityOrEntities.map(apiToModelNormalizer)
    : apiToModelNormalizer(entityOrEntities);
}) as {
  (peripheral: Peripheral): Peripheral;
  (peripheral: Peripheral[]): Peripheral[];
  (peripheral: QueryOutput<ApiPeripheral>): QueryOutput<Peripheral>;
  (peripheral: ApiPeripheral): Peripheral;
  (peripherals: ApiPeripheral[]): Peripheral[];
  (peripheralsQueryOutput: QueryOutput<ApiPeripheral>): QueryOutput<Peripheral>;
};
