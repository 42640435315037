import { produce } from "immer";
import { type SetOptional } from "type-fest";

import { normalizeBase } from "@ds/model/helpers";

import { normalize as playerNormalize } from "@ds/modules/devices/players/utils/normalizer";

import { isApiDeviceSync, isDeviceSyncArray, isDeviceSyncQueryOutput } from "./model";

const normalizer = (entity: ApiDeviceSync) =>
  produce(normalizeBase(entity), (draft: ApiDeviceSync) => {
    draft.device = playerNormalize(entity.device);
  });

export const normalize = ((
  entityOrEntities: ApiDeviceSync | ApiDeviceSync[] | QueryOutput<ApiDeviceSync>,
): ApiDeviceSync | ApiDeviceSync[] | QueryOutput<ApiDeviceSync> => {
  if (isDeviceSyncQueryOutput(entityOrEntities)) {
    return produce(entityOrEntities, draft => {
      draft.items = entityOrEntities.items.map(normalizer);
    });
  }

  return isDeviceSyncArray(entityOrEntities) ? entityOrEntities.map(normalizer) : normalizer(entityOrEntities);
}) as {
  (deviceSync: ApiDeviceSync): ApiDeviceSync;
  (deviceSyncs: ApiDeviceSync[]): ApiDeviceSync[];
  (deviceSyncsQueryOutput: QueryOutput<ApiDeviceSync>): QueryOutput<ApiDeviceSync>;
};
const apiToModelNormalizer = (entity: DeviceSync | ApiDeviceSync): DeviceSync => {
  if (!isApiDeviceSync(entity)) {
    return entity;
  }

  return produce(normalizeBase(entity), (draft: SetOptional<ApiDeviceSync, "device">) => {
    delete draft.device;
  });
};

export const apiToModelNormalize = ((
  entityOrEntities:
    | DeviceSync
    | DeviceSync[]
    | QueryOutput<DeviceSync>
    | ApiDeviceSync
    | ApiDeviceSync[]
    | QueryOutput<ApiDeviceSync>,
): DeviceSync | DeviceSync[] | QueryOutput<DeviceSync> => {
  if (isDeviceSyncQueryOutput(entityOrEntities)) {
    return produce(entityOrEntities, draft => {
      draft.items = entityOrEntities.items.map(item => apiToModelNormalizer(item) as ApiDeviceSync);
    });
  }

  return isDeviceSyncArray(entityOrEntities)
    ? entityOrEntities.map(apiToModelNormalizer)
    : apiToModelNormalizer(entityOrEntities);
}) as {
  (deviceSync: DeviceSync): DeviceSync;
  (deviceSync: DeviceSync[]): DeviceSync[];
  (deviceSync: QueryOutput<ApiDeviceSync>): QueryOutput<DeviceSync>;
  (deviceSync: ApiDeviceSync): DeviceSync;
  (deviceSyncs: ApiDeviceSync[]): DeviceSync[];
  (deviceSyncsQueryOutput: QueryOutput<ApiDeviceSync>): QueryOutput<DeviceSync>;
};
