import { logger } from "@ds/utils/logger";

export const DOW = ["Sunday", "Monday", "Tuesday", "Wednesday", "Thursday", "Friday", "Saturday"];

// TODO: TESTS: WRITE TESTS
export const roundTime = (rawTime?: string) => {
  if (!rawTime) {
    return "0000";
  }

  if (+rawTime[2] !== 0 || +rawTime[3] !== 0) {
    const result = (+rawTime.slice(0, 2) + 1).toString().padStart(2, "0").padEnd(4, "0");
    return result === "2400" ? "0000" : result;
  }

  return rawTime;
};

const formatTime = (rawTime: string): string => {
  const matchResult = roundTime(rawTime).match(/\d{2}/g);
  if (matchResult) {
    const [rawHours, minutes] = matchResult;
    let hours = +rawHours;
    const part = hours >= 12 ? "pm" : "am";
    if (hours > 12) {
      hours -= 12;
    }

    return `${hours}:${minutes}${part}`;
  }

  logger.error("Time has wrong format");
  return "00:00";
};

export const isOpen24Hours = (item: LocalityOpeningHours) => {
  if (!item || item.closed) {
    return false;
  }

  const [openRawTime, closeRawTime] = [roundTime(item.open), roundTime(item.close)];
  if (openRawTime === "0000" && openRawTime === closeRawTime) {
    return true;
  }

  return false;
};

export const formatHours = (item: LocalityOpeningHours): string => {
  if (!item || item.closed) {
    return "Closed";
  }

  const [openRawTime, closeRawTime] = [roundTime(item.open), roundTime(item.close)];
  return isOpen24Hours(item) ? "Open 24 hours" : `${formatTime(openRawTime)} - ${formatTime(closeRawTime)}`;
};

export const calculateHours = (item: LocalityOpeningHours): number => {
  const openTime = Math.floor(+roundTime(item.open) / 100);
  const closeTime = Math.floor(+roundTime(item.close) / 100);

  if (closeTime > openTime) {
    return closeTime - openTime;
  }

  return 24 - openTime + closeTime;
};
