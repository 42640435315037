import { type FC } from "react";

import { RadioButton, RadioButtonProps } from "../inputs";
import { RadioButtonInfoText, RadioButtonRow, RadioLabel } from "./styles";

export const LabelRadio: FC<
  RadioButtonProps & {
    label: string;
    className?: string;
    infoText?: string;
  }
> = ({ label, value, infoText, ...props }) => (
  <>
    <RadioButtonRow>
      <RadioButton inputId={`radio-${value}`} value={value} {...props} />
      <RadioLabel htmlFor={`radio-${value}`}>{label}</RadioLabel>
    </RadioButtonRow>
    {!!infoText && <RadioButtonInfoText>{infoText}</RadioButtonInfoText>}
  </>
);
