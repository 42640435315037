import { type FC, type ReactNode } from "react";
import styled from "styled-components";

import { UnassignedLabel } from "../forms/labels/common";
import { TrimmedContent } from "../trimmed-text";

const ValueWrapper = styled.div<{
  color?: string;
}>`
  color: ${({ color }) => color || "inherit"};
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
`;

export const InfoAttribute: FC<{
  name: string;
  title?: string;
  value: ReactNode;
  color?: string;
}> = ({ name, title = "", value, color }) => (
  <div className="grid w-full mb-1">
    <div className="col-4">{`${name}:`}</div>
    <ValueWrapper className="col my-auto" color={color}>
      <TrimmedContent content={title}>{value || <UnassignedLabel />}</TrimmedContent>
    </ValueWrapper>
  </div>
);
