import { produce } from "immer";

import { normalizeBase } from "@ds/model/helpers";

import { isContentAssetQueryOutput, isContentAssetArray } from "./model";

const normalizer = (entity: ContentAsset): ContentAsset =>
  normalizeBase({
    playlist_id: undefined,
    dimensions: undefined,
    duration: undefined,
    thumbnail: undefined,
    ...entity,
  });

export const normalize = ((
  entityOrEntities: ContentAsset | ContentAsset[] | QueryOutput<ContentAsset>,
): ContentAsset | ContentAsset[] | QueryOutput<ContentAsset> => {
  if (isContentAssetQueryOutput(entityOrEntities)) {
    return produce(entityOrEntities, draft => {
      draft.items = entityOrEntities.items.map(normalizer);
    });
  }

  return isContentAssetArray(entityOrEntities) ? entityOrEntities.map(normalizer) : normalizer(entityOrEntities);
}) as {
  (contentAsset: ContentAsset): ContentAsset;
  (contentAssets: ContentAsset[]): ContentAsset[];
  (contentAssetsQueryOutput: QueryOutput<ContentAsset>): QueryOutput<ContentAsset>;
};
