import { type RootState } from "@ds/store/bootstrap";

import { LoadStatus } from "@ds/utils/reducer";

import { selectBaseState } from "./common-selectors";

export const selectContentAssetUploadData = (state: RootState) => selectBaseState(state).uploadsByFilename;

export const selectContentAssetIsUploading = (state: RootState) =>
  Object.values(selectContentAssetUploadData(state)).some(({ loadStatus }) => loadStatus === LoadStatus.Loading);

export const selectContentAssetIsUploadingFailed = (state: RootState) =>
  Object.values(selectContentAssetUploadData(state)).some(({ loadStatus }) => loadStatus === LoadStatus.Failed);

export const selectContentAssetIsUploadingSucceeded = (state: RootState) =>
  Object.values(selectContentAssetUploadData(state)).every(({ loadStatus }) => loadStatus === LoadStatus.Succeeded);

export const selectIsContentAssetUploadModalMinimized = (state: RootState) =>
  selectBaseState(state).isUploadModalMinimized;

const selectThumbnailUploadData = (state: RootState) => selectBaseState(state).thumbnailUploadsByFilename;
export const selectThumbnailIsUploading = (state: RootState) =>
  Object.values(selectThumbnailUploadData(state)).some(({ loadStatus }) => loadStatus === LoadStatus.Loading);
