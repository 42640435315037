import { useEffect, type FC } from "react";

import { selectDeploymentsError, selectDeploymentsIsLoading } from "@ds/modules/deployments/redux/selectors";
import { deploymentsActions } from "@ds/modules/deployments/redux/slice";

import { WizardFinalStepWrapper, WizardPage, type WizardChildStepProps } from "@ds/components/wizard";
import { getStatusMessage } from "@ds/components/wizard/helpers";
import { useRootDispatch, useRootSelector } from "@ds/hooks";

import { useCreateDeployment } from "./create-deployment-context";

export const StepFinal: FC<WizardChildStepProps> = ({ firstStep, ...rest }) => {
  const isSaving = useRootSelector(selectDeploymentsIsLoading);
  const errorMessage = useRootSelector(selectDeploymentsError);
  const [deployment] = useCreateDeployment();

  const dispatch = useRootDispatch();
  useEffect(() => {
    if (rest.currentStep === rest.totalSteps) {
      dispatch(deploymentsActions.createDeployment(deployment));
    }
  }, [dispatch, deployment, rest.currentStep, rest.totalSteps]);

  return (
    <WizardPage {...rest} isValid={!errorMessage} isLoading={isSaving} previousStep={firstStep}>
      <WizardFinalStepWrapper>
        {getStatusMessage({
          errorMessage,
          isLoading: isSaving,
          loadingText: "Creating Deployment...",
          successText:
            "Deployment created successfully. To execute or schedule this deployment, wait until initialization process is completed and the status changed to ready for scheduling.",
          failedText: "Failed to create deployment",
        })}
      </WizardFinalStepWrapper>
    </WizardPage>
  );
};
