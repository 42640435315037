import { createEntityAdapter, createSlice, type PayloadAction } from "@reduxjs/toolkit";
import { type DeepPartial } from "react-hook-form";
import { all } from "redux-saga/effects";

import { iotEventUpdate } from "@ds/modules/iot/redux/actions";
import { IoTTopicPathEnum } from "@ds/modules/iot/utils/model";
import { DetailsTableDataTypeEnum, MainTableDataTypeEnum } from "@ds/modules/table-data/utils/model";

import { getIds } from "@ds/utils/entities";
import { LoadStatus, type BaseMeta, type PatchedModel } from "@ds/utils/reducer";
import { type SagaOptions } from "@ds/utils/saga-helpers";

import { invitationsActions } from "../../invitations/redux/slice";
import { isApiInvitation, isApiInvitationQueryOutput } from "../../invitations/utils/model";
import { settingsActions } from "../../redux/slice";
import { usersActions } from "../../users/redux/slice";
import { isApiUser, isApiUserQueryOutput } from "../../users/utils/model";
import { normalize } from "../utils/normalizer";
import { watchProjects } from "./sagas";

export enum ProjectsRemoteOperation {
  CRUD = "crud",
  CHECKBOXES = "checkboxes",
  DASHBOARD = "dashboard",
  IOT_FETCH = "iot_fetch",
}

export type ProjectsMeta = BaseMeta & {
  remoteOperation: ProjectsRemoteOperation;
  filters?: KeysToCamelCase<SettingsProjectFilters>;
};

export interface ProjectsState {
  loadStatusMap: {
    [ProjectsRemoteOperation.CRUD]: LoadStatus;
    [ProjectsRemoteOperation.CHECKBOXES]: LoadStatus;
    [ProjectsRemoteOperation.DASHBOARD]: LoadStatus;
    [ProjectsRemoteOperation.IOT_FETCH]: LoadStatus;
  };

  error: string | null;

  entities: {
    [projectId: number]: Project;
  };

  ids: number[];
  tableIds: Record<string, number[]>;
  selectedIds: Record<string, number[]>;

  dashboardInfo: {
    embedUrl?: string;
    expiredAt?: number;
  };
}

const entityAdapter = createEntityAdapter<Project>();

export const initialState: ProjectsState = entityAdapter.getInitialState({
  loadStatusMap: {
    [ProjectsRemoteOperation.CRUD]: LoadStatus.Idle,
    [ProjectsRemoteOperation.CHECKBOXES]: LoadStatus.Idle,
    [ProjectsRemoteOperation.DASHBOARD]: LoadStatus.Idle,
    [ProjectsRemoteOperation.IOT_FETCH]: LoadStatus.Idle,
  },

  error: null,

  tableIds: { [MainTableDataTypeEnum.Projects]: [] },
  selectedIds: { [MainTableDataTypeEnum.Projects]: [] },

  dashboardInfo: {},
}) as ProjectsState;

const getTableTypeOrDefault = (
  tableType: MainTableDataTypeEnum | DetailsTableDataTypeEnum = MainTableDataTypeEnum.Projects,
) => tableType;

const removeEntities = (state: ProjectsState, payload: number[]) => {
  entityAdapter.removeMany(state, payload);

  state.tableIds[MainTableDataTypeEnum.Projects] = state.tableIds[MainTableDataTypeEnum.Projects].filter(
    id => !payload.includes(id),
  );

  state.selectedIds[MainTableDataTypeEnum.Projects] = state.selectedIds[MainTableDataTypeEnum.Projects].filter(
    id => !payload.includes(id),
  );
};

const sliceType = "PROJECTS";
const slice = createSlice({
  name: sliceType,
  initialState,
  reducers: {
    fetchProjects: {
      reducer(state, { meta }: PayloadAction<undefined, string, ProjectsMeta>) {
        state.loadStatusMap[meta.remoteOperation] = LoadStatus.Loading;
        state.error = null;

        if (meta.remoteOperation === ProjectsRemoteOperation.CHECKBOXES) {
          state.selectedIds[getTableTypeOrDefault(meta.options?.tableType)] =
            state.tableIds[getTableTypeOrDefault(meta.options?.tableType)];
        }
      },
      prepare(
        filters: KeysToCamelCase<SettingsProjectFilters> = {},
        options: SagaOptions = {},
        remoteOperation: ProjectsRemoteOperation = ProjectsRemoteOperation.CRUD,
      ) {
        return { payload: undefined, meta: { remoteOperation, filters, options } };
      },
    },
    fetchProjectsFailed: {
      reducer(state, { meta, error }: PayloadAction<undefined, string, ProjectsMeta, string>) {
        state.loadStatusMap[meta.remoteOperation] = LoadStatus.Failed;
        state.error = error;

        if (meta.remoteOperation === ProjectsRemoteOperation.CHECKBOXES) {
          state.selectedIds[getTableTypeOrDefault(meta.options?.tableType)] = [];
        }
      },
      prepare(meta: ProjectsMeta, { message }: ErrorLike) {
        return { payload: undefined, meta, error: message };
      },
    },
    fetchProjectsSucceeded: {
      reducer(state, { payload, meta }: PayloadAction<QueryOutput<Project>, string, ProjectsMeta>) {
        state.loadStatusMap[meta.remoteOperation] = LoadStatus.Succeeded;
        state.error = null;

        entityAdapter.setMany(state, payload.items);

        if (meta.remoteOperation === ProjectsRemoteOperation.CHECKBOXES) {
          state.selectedIds[getTableTypeOrDefault(meta.options?.tableType)] = payload.items.map(({ id }) => id);
        } else if (meta.options?.tableType) {
          state.tableIds[getTableTypeOrDefault(meta.options?.tableType)] = payload.items.map(({ id }) => id);
          state.selectedIds[getTableTypeOrDefault(meta.options?.tableType)] = state.selectedIds[
            getTableTypeOrDefault(meta.options?.tableType)
          ].filter(id => state.ids.includes(id));
        }
      },
      prepare(payload: QueryOutput<Project>, meta: ProjectsMeta) {
        return { payload, meta };
      },
    },
    fetchProject: {
      reducer(state, { meta }: PayloadAction<number, string, ProjectsMeta>) {
        state.loadStatusMap[meta.remoteOperation] = LoadStatus.Loading;
        state.error = null;
      },
      prepare(payload: number, options: SagaOptions = {}) {
        return { payload, meta: { remoteOperation: ProjectsRemoteOperation.CRUD, options } };
      },
    },
    fetchProjectFailed: {
      reducer(state, { meta, error }: PayloadAction<number, string, ProjectsMeta, string>) {
        state.loadStatusMap[meta.remoteOperation] = LoadStatus.Failed;
        state.error = error;
      },
      prepare(payload: number, meta: ProjectsMeta, { message }: ErrorLike) {
        return { payload, meta, error: message };
      },
    },
    fetchProjectSucceeded: {
      reducer(state, { payload, meta }: PayloadAction<Project, string, ProjectsMeta>) {
        state.loadStatusMap[meta.remoteOperation] = LoadStatus.Succeeded;
        state.error = null;

        entityAdapter.setOne(state, payload);
      },
      prepare(payload: Project, meta: ProjectsMeta) {
        return { payload, meta };
      },
    },
    updateProjects: {
      reducer(state, _action: PayloadAction<PatchedModel<Project>>) {
        state.loadStatusMap[ProjectsRemoteOperation.CRUD] = LoadStatus.Loading;
        state.error = null;
      },
      prepare(changedIds: number[], changedFields: DeepPartial<Project>) {
        return { payload: { ids: changedIds, data: changedFields } };
      },
    },
    updateProjectsFailed: {
      reducer(state, { error }: PayloadAction<PatchedModel<Project>, string, never, string>) {
        state.loadStatusMap[ProjectsRemoteOperation.CRUD] = LoadStatus.Failed;
        state.error = error;
      },
      prepare(payload: PatchedModel<Project>, { message }: ErrorLike) {
        return { payload, error: message };
      },
    },
    updateProjectsSucceeded: (state, { payload }: PayloadAction<Project[]>) => {
      state.loadStatusMap[ProjectsRemoteOperation.CRUD] = LoadStatus.Succeeded;
      state.error = null;

      entityAdapter.setMany(state, payload);
    },
    selectProjects: {
      reducer(state, { payload, meta }: PayloadAction<number[], string, ProjectsMeta>) {
        state.loadStatusMap[meta.remoteOperation] = LoadStatus.Succeeded;

        state.selectedIds[getTableTypeOrDefault(meta.options?.tableType)] = payload;
      },
      prepare(projectsOrIds: Project[] | number[], options: SagaOptions = {}) {
        return {
          payload: getIds(projectsOrIds),
          meta: { remoteOperation: ProjectsRemoteOperation.CHECKBOXES, options },
        };
      },
    },
    toggleSelected: {
      reducer(state, { payload, meta }: PayloadAction<number, string, ProjectsMeta>) {
        state.loadStatusMap[meta.remoteOperation] = LoadStatus.Succeeded;

        state.selectedIds[getTableTypeOrDefault(meta.options?.tableType)] = state.selectedIds[
          getTableTypeOrDefault(meta.options?.tableType)
        ].includes(payload)
          ? state.selectedIds[getTableTypeOrDefault(meta.options?.tableType)].filter(id => id !== payload)
          : [...state.selectedIds[getTableTypeOrDefault(meta.options?.tableType)], payload];
      },
      prepare(projectId: number, options: SagaOptions = {}) {
        return {
          payload: projectId,
          meta: { remoteOperation: ProjectsRemoteOperation.CHECKBOXES, options },
        };
      },
    },
    fetchProjectDashboard: {
      reducer(state, { meta }: PayloadAction<number, string, ProjectsMeta>) {
        state.loadStatusMap[meta.remoteOperation] = LoadStatus.Loading;
        state.error = null;
      },
      prepare(payload: number, options: SagaOptions = {}) {
        return { payload, meta: { remoteOperation: ProjectsRemoteOperation.DASHBOARD, options } };
      },
    },
    fetchProjectDashboardFailed: {
      reducer(state, { meta, error }: PayloadAction<number, string, ProjectsMeta, string>) {
        state.loadStatusMap[meta.remoteOperation] = LoadStatus.Failed;
        state.error = error;
      },
      prepare(payload: number, meta: ProjectsMeta, { message }: ErrorLike) {
        return { payload, meta, error: message };
      },
    },
    fetchProjectDashboardSucceeded: {
      reducer(state, { payload, meta }: PayloadAction<ProjectDashboardInfo | undefined, string, ProjectsMeta>) {
        state.loadStatusMap[meta.remoteOperation] = LoadStatus.Succeeded;
        if (payload?.embed_url) {
          state.dashboardInfo.embedUrl = payload.embed_url;
          state.dashboardInfo.expiredAt = Date.now() + payload.lifetime_in_minutes * 60 * 1000;
        }
      },
      prepare(
        payload: ProjectDashboardInfo | undefined,
        meta: ProjectsMeta = { remoteOperation: ProjectsRemoteOperation.DASHBOARD },
      ) {
        return { payload, meta };
      },
    },
    setDashboardUrl: (state, action: PayloadAction<string>) => {
      state.dashboardInfo.embedUrl = action.payload;
    },
    iotUpdate: (state, { payload }: PayloadAction<Project>) => {
      if (payload.audit?.deleted_at) {
        removeEntities(state, [payload.id]);
      } else {
        entityAdapter.setOne(state, normalize(payload));
      }
    },
  },
  extraReducers: builder => {
    builder.addCase(settingsActions.switchView, state => {
      state.loadStatusMap[ProjectsRemoteOperation.CRUD] = LoadStatus.Idle;
      state.error = null;
    });

    builder.addCase(usersActions.fetchUsersSucceeded, (state, { payload }) => {
      if (isApiUserQueryOutput(payload)) {
        entityAdapter.setMany(
          state,
          payload.items.flatMap(({ projects }) => projects),
        );
      }
    });

    builder.addCase(usersActions.fetchUserSucceeded, (state, { payload }) => {
      if (isApiUser(payload)) {
        entityAdapter.setMany(state, payload.projects);
      }
    });

    builder.addCase(usersActions.updateUsersSucceeded, (state, { payload }) => {
      entityAdapter.setMany(
        state,
        payload.flatMap(({ projects }) => projects),
      );
    });

    builder.addCase(usersActions.updateUserCurrentProjectIdSucceeded, (state, { payload }) => {
      entityAdapter.setMany(state, payload.projects);
    });

    builder.addCase(invitationsActions.fetchInvitationsSucceeded, (state, { payload }) => {
      if (isApiInvitationQueryOutput(payload)) {
        entityAdapter.setMany(
          state,
          payload.items.flatMap(({ projects }) => projects),
        );
      }
    });

    builder.addCase(invitationsActions.fetchInvitationSucceeded, (state, { payload }) => {
      if (isApiInvitation(payload)) {
        entityAdapter.setMany(state, payload.projects);
      }
    });

    builder.addCase(invitationsActions.createInvitationSucceeded, (state, { payload }) => {
      entityAdapter.setMany(state, payload.projects);
    });

    builder.addCase(invitationsActions.updateInvitationsSucceeded, (state, { payload }) => {
      entityAdapter.setMany(
        state,
        payload.flatMap(({ projects }) => projects),
      );
    });

    builder.addCase(iotEventUpdate(IoTTopicPathEnum.Users), (state, { payload }) => {
      entityAdapter.setMany(state, normalize(payload.projects));
    });
  },
});

export const { name: projectsType, actions: projectsActions, reducer: projectsReducer } = slice;

export const entitySelectors = entityAdapter.getSelectors();

export function* rootProjectsSaga() {
  yield all([watchProjects()]);
}
