import { partial } from "lodash";
import { useCallback, type FC } from "react";
import { Controller, useWatch, type Control } from "react-hook-form";

import {
  type FormationChangeHandlerDecorator,
  type FormationChangeHandlerInputData,
} from "@ds/modules/settings/projects/components/forms/controls/types";

import { useDetailsInlineSaving } from "@ds/components/details-view/hooks";
import { LabelWrapper } from "@ds/components/forms/labels";
import { useRootSelector } from "@ds/hooks";
import { isFormationPropertyRequired } from "@ds/utils/validation";

import { selectPlaylistById } from "../../../redux/selectors";
import { PlaylistsAutocompleteRaw } from "../../playlists-autocomplete";

export const PlaylistFormationControl: FC<{
  control: Control<Experience | Project>;
  name: `formation.properties.${string}` | `template.experience_formation.properties.${string}`;
  property: TemplatePlaylistFormationProperty;
  autoFocus?: boolean;
  excludeLabel?: boolean;
  onBeforeChangeHandlerDecorator: FormationChangeHandlerDecorator;
  onBlur?: () => void;
}> = ({ control, name, property, autoFocus, excludeLabel, onBeforeChangeHandlerDecorator, onBlur }) => {
  const onBlurHandler = useDetailsInlineSaving(onBlur);
  const propertyValue = (useWatch({ name, control }) as EntityPlaylistFormationProperty)?.value;
  // TODO: SELECTORS: FIX CONSOLE WARNING
  const getPlaylistById = useRootSelector(state => partial(selectPlaylistById, state));
  const onChangeHandler = useCallback(
    (onChange: (inputData: FormationChangeHandlerInputData) => void) =>
      onBeforeChangeHandlerDecorator(
        onChange,
        getPlaylistById(Number(propertyValue) || 0)?.name ?? "",
        inputData => getPlaylistById(Number(inputData) || 0)?.name,
      ),
    [propertyValue, onBeforeChangeHandlerDecorator, getPlaylistById],
  );

  return (
    <Controller
      name={`${name}.value`}
      control={control}
      render={({ field: { ref, value, onChange, onBlur: onBlurFn, ...restFields }, fieldState }) => {
        const element = (
          <PlaylistsAutocompleteRaw
            inputRef={ref}
            value={Number(value)}
            autoFocus={autoFocus}
            onChange={onChangeHandler(onChange)}
            onBlur={onBlurHandler(onBlurFn)}
            {...restFields}
          />
        );

        if (excludeLabel) {
          return element;
        }

        return (
          <LabelWrapper
            label={property.title}
            className="col-8"
            isRequired={isFormationPropertyRequired(property.metadata?.validation_rules)}
            error={fieldState.error}
          >
            {element}
          </LabelWrapper>
        );
      }}
    />
  );
};
