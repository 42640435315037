import { isArray } from "lodash";
import { useCallback, useEffect, useState, type FC } from "react";
import styled from "styled-components";

import { assetMediaTypes } from "@ds/modules/content/utils/constants";

import { DndDropzone } from "@ds/components/dnd-dropzone";
import { Button, ButtonType } from "@ds/components/forms/buttons";
import { IconType } from "@ds/components/icons";
import { readFileContent } from "@ds/utils/file";
import { convertImageToDataUrl } from "@ds/utils/image";

import { useDropzoneRef } from "../../../hooks/use-dropzone-ref";
import { ContentAssetUploadInput } from "./content-assets-upload-input";

const ImageWrapper = styled.div<{
  height?: number;
  width?: number;
}>`
  height: ${({ height, width }) => {
    if (!height || !width) {
      return "100%";
    }

    return height >= width ? `${height}px` : "auto";
  }};

  width: ${({ height, width }) => {
    if (!height || !width) {
      return "auto";
    }

    return width > height ? `${width}px` : "auto";
  }};

  max-height: 90%;
  max-width: 100%;
  aspect-ratio: 1;
  position: relative;
  display: flex;
  border: 2px solid transparent;

  > img {
    height: 100%;
    width: 100%;
    object-fit: scale-down;
  }

  &:hover {
    border-color: var(--red-color);

    > i {
      display: block;
    }
  }
`;

const DeleteIcon = styled.i`
  position: absolute;
  top: -12px;
  right: -12px;
  color: var(--lightSurfaceColor);
  background: var(--red-color);
  border-radius: 50%;
  padding: 0.5em;
  cursor: pointer;
  display: none;
`;

const UploadWrapper = styled.div`
  width: 100%;
  height: 100%;
`;

const UploadIcon = styled.i`
  font-size: 5rem;
  border-radius: 50%;
  background-color: var(--surface-b);
  color: var(--surface-d);
`;

const UploadIconText = styled.span`
  font-size: 1.2rem;
  color: var(--text-color-secondary);
`;

export const ImageUploadInput: FC<{
  initialImage?: string;
  initialFile?: File;
  onChange: (imgDataUrl: string, file: File | null, hasChanged: boolean) => void;
}> = ({ initialImage, initialFile, onChange }) => {
  const [dropzoneRef, openDropzone] = useDropzoneRef();

  const [image, setImage] = useState(initialImage);
  const [imgWrapperHeight, setImgWrapperHeight] = useState<number>();
  const [imgWrapperWidth, setImgWrapperWidth] = useState<number>();

  const onDeleteClickHandler = useCallback(() => {
    setImage("");
    onChange("", null, true);
  }, [onChange]);

  const loadImage = useCallback(
    async (url: string, file: File | null, hasChanged = false) => {
      const { imgDataUrl, imgHeight, imgWidth } = await convertImageToDataUrl(url);
      setImage(imgDataUrl);
      setImgWrapperHeight(imgHeight);
      setImgWrapperWidth(imgWidth);
      onChange(imgDataUrl, file, hasChanged);
    },
    [onChange],
  );

  const onUploadClickHandler = useCallback(
    async (files: File[]) => {
      if (isArray(files) && files.length) {
        const content = await readFileContent(files[0]);
        loadImage(content, files[0], true);
      }
    },
    [loadImage],
  );

  useEffect(() => {
    if (initialImage) {
      loadImage(initialImage, initialFile || null);
    } else {
      onDeleteClickHandler();
    }
  }, [initialImage, onDeleteClickHandler, initialFile, loadImage]);

  if (image) {
    return (
      <ImageWrapper height={imgWrapperHeight} width={imgWrapperWidth}>
        <img src={image} alt="video preview" />
        <DeleteIcon className="pi pi-trash" onClick={onDeleteClickHandler} />
      </ImageWrapper>
    );
  }

  return (
    <>
      <DndDropzone acceptMediaTypes={assetMediaTypes.image} onUpload={onUploadClickHandler} multiple={false}>
        <UploadWrapper className="flex align-items-center justify-content-center flex-column">
          <UploadIcon className="pi pi-image mt-3 p-5" />
          <UploadIconText className="my-5">Drag and Drop Image or Press Button</UploadIconText>
          <Button
            label="Upload image"
            buttonType={ButtonType.Outlined}
            icon={IconType.Upload}
            iconPosition="right"
            onClick={openDropzone}
          />
        </UploadWrapper>
      </DndDropzone>

      <ContentAssetUploadInput
        acceptMediaTypes={assetMediaTypes.image}
        dropzoneRef={dropzoneRef}
        onUpload={onUploadClickHandler}
      />
    </>
  );
};
