import { type DeepPartial } from "react-hook-form";

import { DSApi } from "@ds/services/ds-api";
import { type ApiService, type ApiServiceConfig } from "@ds/services/types";
import { parseQueries } from "@ds/utils/query";

interface DeploymentsServiceConfig extends ApiServiceConfig {
  api: DSApi;
}

class DeploymentsService implements ApiService {
  private config: DeploymentsServiceConfig = {
    api: {} as DSApi,
  };

  configure(cfg: DeploymentsServiceConfig) {
    this.config = { ...cfg };
  }

  async getDeployments(queryInfo: QueryInfo, pagination: Pagination) {
    const queries = parseQueries(queryInfo, pagination);
    return this.config.api.get<QueryOutput<Deployment>>("/deployments/", queries);
  }

  async getDeployment(deploymentId: number) {
    return this.config.api.get<Deployment>(`/deployments/${deploymentId}`);
  }

  async getDeploymentDetails(deploymentId: number) {
    return this.config.api.get<DeploymentDetails>(`/deployments/${deploymentId}/details`);
  }

  async createDeployment(deployment: Deployment) {
    return this.config.api.post<Deployment>("/deployments/", deployment);
  }

  async updateDeployments(ids: number[], data: DeepPartial<Deployment>) {
    return this.config.api.patch<Deployment>("/deployments/", { ids, data });
  }

  async deleteDeployments(ids: number[]) {
    return this.config.api.delete<Deployment>("/deployments/", { ids });
  }

  async scheduleDeployment(deploymentId: number, startAt?: string) {
    const body = startAt ? { start_at: startAt } : {};
    return this.config.api.put<Deployment>(`/deployments/${deploymentId}/schedule`, body);
  }

  async unscheduleDeployment(deploymentId: number) {
    return this.config.api.delete<Deployment>(`/deployments/${deploymentId}/schedule`);
  }
}

export const deploymentsService = new DeploymentsService();
