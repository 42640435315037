import { yupResolver } from "@hookform/resolvers/yup";
import { useCallback, useEffect, type FC } from "react";
import { useForm } from "react-hook-form";

import { createValidationSchema } from "@ds/modules/experiences/utils/validation-schema";

import { WizardPage, type WizardChildStepProps } from "@ds/components/wizard";

import { ExperienceLocationControl } from "../controls/experience-location-control";
import { ExperiencePlayersControl } from "../controls/experience-players-control";
import { useCreateExperienceContext } from "./create-experience-context";

export const Step1Main: FC<WizardChildStepProps> = ({ isActive, nextStep, ...rest }) => {
  const { experience, players, setFormData } = useCreateExperienceContext();

  const { control, getValues, setValue, handleSubmit, resetField } = useForm<ExperienceFormData>({
    mode: "all",
    reValidateMode: "onChange",
    defaultValues: { experience, players },
    resolver: yupResolver(createValidationSchema.step1),
  });

  useEffect(() => {
    if (isActive) {
      resetField("experience", { defaultValue: experience });
    }
  }, [experience, isActive, resetField]);

  const onNextClickHandler = useCallback(() => {
    setFormData(getValues());
    if (nextStep) {
      nextStep();
    }
  }, [nextStep, setFormData, getValues]);

  return (
    <WizardPage {...rest} title="Location And Players" nextStep={handleSubmit(onNextClickHandler)}>
      <ExperienceLocationControl name="experience.location_id" control={control} />
      <ExperiencePlayersControl control={control} setValue={setValue} />
    </WizardPage>
  );
};
