import { useCallback, type FC, type ReactNode } from "react";
import { useNavigate } from "react-router-dom";
import styled from "styled-components";

import { useNavigationPrompt } from "@ds/hooks/use-navigation-prompt";

import { MainTableDataTypeEnum } from "@ds/modules/table-data/utils/model";

import { Button, ButtonType } from "../forms/buttons";
import { HeaderTitle } from "../header-title";
import { IconType } from "../icons";
import { Loading } from "../loaders/loading";

const ScrollWrapper = styled.div`
  height: calc(100vh - 60px);
  overflow: overlay;
  position: relative;
`;

const CloseButtonWrapper = styled.div`
  position: absolute;
  top: 16px;
  right: 20px;
  z-index: 1;
`;

const Wrapper = styled.div`
  margin: 24px auto 48px;
  width: 80%;
`;

export const DetailsView: FC<{
  isLoading: boolean;
  title: string;
  tableType?: MainTableDataTypeEnum;
  onRefresh: () => void;
  children: ReactNode;
}> = ({ title, tableType, isLoading, onRefresh, children }) => {
  const navigate = useNavigate();

  const getIsAnyUnsaved = useCallback(() => {
    return !!document.querySelectorAll('[aria-label="details save button"]').length;
  }, []);

  useNavigationPrompt(
    { header: "Leaving Confirmation", message: "You have unsaved changes, are you sure?" },
    getIsAnyUnsaved,
  );

  const onCloseClickHandler = useCallback(() => navigate(-1), [navigate]);

  return (
    <>
      <HeaderTitle title={title} tableType={tableType} />
      <Loading isLoading={isLoading}>
        <CloseButtonWrapper>
          <Button
            ariaLabel="details refresh button"
            buttonType={ButtonType.Round}
            icon={IconType.Refresh}
            onClick={onRefresh}
          />

          <Button
            ariaLabel="details close button"
            buttonType={ButtonType.Round}
            icon={IconType.Close}
            onClick={onCloseClickHandler}
          />
        </CloseButtonWrapper>

        <ScrollWrapper>
          <Wrapper>{!isLoading && children}</Wrapper>
        </ScrollWrapper>
      </Loading>
    </>
  );
};
