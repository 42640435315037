import { type FC } from "react";
import { type Control, type UseFormSetValue } from "react-hook-form";

import { ContentAssetFormationControl } from "@ds/modules/content/assets/components/forms/controls/content-asset-formation-control";
import { PlaylistFormationControl } from "@ds/modules/content/playlists/components/forms/controls/playlist-formation-control";
import {
  isAssetFormationProperty,
  isBooleanFormationProperty,
  isNumberFormationProperty,
  isPlaylistFormationProperty,
  isStringFormationProperty,
} from "@ds/modules/settings/projects/utils/formation-guards";

import { BooleanFormationControl } from "./boolean-formation-control";
import { NumberFormationControl } from "./number-formation-control";
import { StringFormationControl } from "./string-formation-control";
import { type FormationChangeHandlerDecorator } from "./types";

export const FormationControl: FC<{
  name: `formation.properties.${string}` | `template.experience_formation.properties.${string}`;
  control: Control<Experience | Project>;
  setValue: UseFormSetValue<Experience | Project>;
  property: TemplateFormationProperty;
  autoFocus?: boolean;
  excludeLabel?: boolean;
  onBeforeChangeHandlerDecorator?: FormationChangeHandlerDecorator;
  onBlur?: () => void;
}> = ({
  name,
  control,
  setValue,
  property,
  autoFocus,
  excludeLabel,
  onBeforeChangeHandlerDecorator = onChange => inputData => onChange(inputData),
  onBlur,
}) => {
  if (isPlaylistFormationProperty(property)) {
    return (
      <PlaylistFormationControl
        name={name}
        control={control}
        property={property}
        autoFocus={autoFocus}
        excludeLabel={excludeLabel}
        onBeforeChangeHandlerDecorator={onBeforeChangeHandlerDecorator}
        onBlur={onBlur}
      />
    );
  }

  if (isAssetFormationProperty(property)) {
    return (
      <ContentAssetFormationControl
        name={name}
        control={control}
        property={property}
        autoFocus={autoFocus}
        excludeLabel={excludeLabel}
        onBeforeChangeHandlerDecorator={onBeforeChangeHandlerDecorator}
        onBlur={onBlur}
      />
    );
  }

  if (isBooleanFormationProperty(property)) {
    return (
      <BooleanFormationControl
        name={name}
        control={control}
        setValue={setValue}
        property={property}
        autoFocus={autoFocus}
        excludeLabel={excludeLabel}
        onBlur={onBlur}
      />
    );
  }

  if (isNumberFormationProperty(property)) {
    return (
      <NumberFormationControl
        name={name}
        control={control}
        property={property}
        autoFocus={autoFocus}
        excludeLabel={excludeLabel}
        onBeforeChangeHandlerDecorator={onBeforeChangeHandlerDecorator}
        onBlur={onBlur}
      />
    );
  }

  if (isStringFormationProperty(property)) {
    return (
      <StringFormationControl
        name={name}
        control={control}
        property={property}
        autoFocus={autoFocus}
        excludeLabel={excludeLabel}
        onBeforeChangeHandlerDecorator={onBeforeChangeHandlerDecorator}
        onBlur={onBlur}
      />
    );
  }

  return null;
};
