import { type FC, type ReactNode } from "react";
import styled from "styled-components";

import { SpinnerLoader2 } from "../loaders/loaders";
import { InfoContentSeparator } from "./info-content-separator";

const Wrapper = styled.section`
  display: flex;
  flex-flow: column;

  :not(:first-child) {
    margin-top: 20px;
  }
`;

const InfoSectionIcon = styled.i`
  margin-right: 10px;
  color: var(--textColor070);
  font-weight: 400;
`;

const InfoSectionTitle = styled.h3`
  color: var(--textColor070);
  font-size: 1rem;
  font-weight: 600;
  text-transform: capitalize;
`;

const AttributesWrapper = styled.div`
  display: flex;
  flex-flow: column;
  align-self: center;
  width: 86%;
  color: var(--textColor080);
  font-weight: 400;
  font-size: 0.95rem;
`;

export const InfoSection: FC<{
  title: string;
  icon?: string;
  isLoading?: boolean;
  children: ReactNode;
}> = ({ children, title, icon, isLoading }) => (
  <Wrapper>
    <InfoContentSeparator />
    <div className="flex align-items-center">
      {icon && <InfoSectionIcon className={icon} />}
      <InfoSectionTitle>{title}</InfoSectionTitle>
    </div>

    <AttributesWrapper>{isLoading ? <SpinnerLoader2 /> : children}</AttributesWrapper>
  </Wrapper>
);
