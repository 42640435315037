import { createSelector } from "@reduxjs/toolkit";

import { DetailsTableDataTypeEnum, MainTableDataTypeEnum } from "@ds/modules/table-data/utils/model";

import { type RootState } from "@ds/store/bootstrap";
import { selectorsExtractIds, selectorsOutputEntitiesByIds } from "@ds/store/global-selectors";

import { getAggregatedModel } from "@ds/utils/entities";
import { LoadStatus } from "@ds/utils/reducer";

import { entitySelectors, LocalitiesRemoteOperation } from "./slice";

const selectBaseState = (state: RootState) => state.locations;

export const selectCurrentView = (state: RootState) => selectBaseState(state).viewType;

const selectLoadStatus = (state: RootState, operation = LocalitiesRemoteOperation.CRUD) =>
  selectBaseState(state).loadStatusMap[operation];

export const selectLocationsIsLoading = (state: RootState, operation = LocalitiesRemoteOperation.CRUD) =>
  selectLoadStatus(state, operation) === LoadStatus.Loading;

export const selectLocationsError = (state: RootState) => selectBaseState(state).error;

export const selectTableLocationIds = (
  state: RootState,
  tableType: MainTableDataTypeEnum | DetailsTableDataTypeEnum = MainTableDataTypeEnum.Locations,
) => selectBaseState(state).tableIds[tableType];

export const selectSelectedLocationIds = (
  state: RootState,
  tableType: MainTableDataTypeEnum | DetailsTableDataTypeEnum = MainTableDataTypeEnum.Locations,
) => selectBaseState(state).selectedIds[tableType];

export const selectLocationById = (state: RootState, id: number) =>
  entitySelectors.selectById(selectBaseState(state), id) as Locality;

export const selectLocationIds = (state: RootState) => entitySelectors.selectIds(selectBaseState(state));
export const selectLocations = (state: RootState) => entitySelectors.selectAll(selectBaseState(state));

export const selectLocationsByIds = createSelector(
  selectBaseState,
  selectorsExtractIds,
  selectorsOutputEntitiesByIds(() => entitySelectors),
);

export const selectTableLocations = createSelector(
  selectBaseState,
  selectTableLocationIds,
  selectorsOutputEntitiesByIds(() => entitySelectors),
);

export const selectSelectedLocations = createSelector(
  selectBaseState,
  selectSelectedLocationIds,
  selectorsOutputEntitiesByIds(() => entitySelectors),
);

export const selectAggregatedLocationByIds = createSelector(selectBaseState, selectorsExtractIds, (state, ids) =>
  ids?.length ? getAggregatedModel(selectorsOutputEntitiesByIds(entitySelectors)(state, ids)) : null,
);

export const selectAggregatedLocationForSelectedEntities = createSelector(selectSelectedLocations, selectedLocations =>
  getAggregatedModel(selectedLocations),
);
