import { createEntityAdapter, createSlice, type PayloadAction } from "@reduxjs/toolkit";
import { type DeepPartial } from "react-hook-form";
import { all } from "redux-saga/effects";

import { experiencesActions } from "@ds/modules/experiences/redux/slice";
import { isApiExperience, isApiExperienceQueryOutput } from "@ds/modules/experiences/utils/model";
import { iotEventUpdate } from "@ds/modules/iot/redux/actions";
import { IoTTopicPathEnum } from "@ds/modules/iot/utils/model";
import { DetailsTableDataTypeEnum, MainTableDataTypeEnum } from "@ds/modules/table-data/utils/model";

import { getIds } from "@ds/utils/entities";
import { LoadStatus, type BaseMeta, type PatchedModel } from "@ds/utils/reducer";
import { type SagaOptions } from "@ds/utils/saga-helpers";

import { LocationTableTypeEnum } from "../utils/model";
import { normalize } from "../utils/normalizer";
import { watchLocations } from "./sagas";

export enum LocalitiesRemoteOperation {
  CRUD = "crud",
  CHECKBOXES = "checkboxes",
  IOT_FETCH = "iot_fetch",
}

export type LocalitiesMeta = BaseMeta & {
  remoteOperation: LocalitiesRemoteOperation;
  filters?: KeysToCamelCase<LocalityFilters>;
};

export interface LocalitiesState {
  viewType: LocationTableTypeEnum | undefined;
  loadStatusMap: {
    [LocalitiesRemoteOperation.CRUD]: LoadStatus;
    [LocalitiesRemoteOperation.CHECKBOXES]: LoadStatus;
    [LocalitiesRemoteOperation.IOT_FETCH]: LoadStatus;
  };

  error: string | null;

  entities: {
    [locationId: number]: Locality;
  };

  ids: number[];
  tableIds: Record<string, number[]>;
  selectedIds: Record<string, number[]>;
}

const entityAdapter = createEntityAdapter<Locality>();

export const initialState: LocalitiesState = entityAdapter.getInitialState({
  viewType: undefined,
  loadStatusMap: {
    [LocalitiesRemoteOperation.CRUD]: LoadStatus.Idle,
    [LocalitiesRemoteOperation.CHECKBOXES]: LoadStatus.Idle,
    [LocalitiesRemoteOperation.IOT_FETCH]: LoadStatus.Idle,
  },

  error: null,

  tableIds: { [MainTableDataTypeEnum.Locations]: [] },
  selectedIds: { [MainTableDataTypeEnum.Locations]: [] },
}) as LocalitiesState;

const getTableTypeOrDefault = (
  tableType: MainTableDataTypeEnum | DetailsTableDataTypeEnum = MainTableDataTypeEnum.Locations,
) => tableType;

const removeEntities = (state: LocalitiesState, payload: number[]) => {
  entityAdapter.removeMany(state, payload);

  state.tableIds[MainTableDataTypeEnum.Locations] = state.tableIds[MainTableDataTypeEnum.Locations].filter(
    id => !payload.includes(id),
  );

  state.selectedIds[MainTableDataTypeEnum.Locations] = state.selectedIds[MainTableDataTypeEnum.Locations].filter(
    id => !payload.includes(id),
  );
};

const sliceType = "LOCATIONS";
const slice = createSlice({
  name: sliceType,
  initialState,
  reducers: {
    switchView: (state, { payload }: PayloadAction<LocationTableTypeEnum>) => {
      state.viewType = payload;
    },
    fetchLocations: {
      reducer(state, { meta }: PayloadAction<undefined, string, LocalitiesMeta>) {
        state.loadStatusMap[meta.remoteOperation] = LoadStatus.Loading;
        state.error = null;

        if (meta.remoteOperation === LocalitiesRemoteOperation.CHECKBOXES) {
          state.selectedIds[getTableTypeOrDefault(meta.options?.tableType)] =
            state.tableIds[getTableTypeOrDefault(meta.options?.tableType)];
        }
      },
      prepare(
        filters: KeysToCamelCase<LocalityFilters> = {},
        options: SagaOptions = {},
        remoteOperation: LocalitiesRemoteOperation = LocalitiesRemoteOperation.CRUD,
      ) {
        return {
          payload: undefined,
          meta: { remoteOperation, filters, options },
        };
      },
    },
    fetchLocationsFailed: {
      reducer(state, { meta, error }: PayloadAction<undefined, string, LocalitiesMeta, string>) {
        state.loadStatusMap[meta.remoteOperation] = LoadStatus.Failed;
        state.error = error;

        if (meta.remoteOperation === LocalitiesRemoteOperation.CHECKBOXES) {
          state.loadStatusMap[meta.remoteOperation] = LoadStatus.Failed;
          state.selectedIds[getTableTypeOrDefault(meta.options?.tableType)] = [];
        }
      },
      prepare(meta: LocalitiesMeta, { message }: ErrorLike) {
        return { payload: undefined, meta, error: message };
      },
    },
    fetchLocationsSucceeded: {
      reducer(state, { payload, meta }: PayloadAction<QueryOutput<Locality>, string, LocalitiesMeta>) {
        state.loadStatusMap[meta.remoteOperation] = LoadStatus.Succeeded;
        state.error = null;

        entityAdapter.setMany(state, payload.items);

        if (meta.remoteOperation === LocalitiesRemoteOperation.CHECKBOXES) {
          state.selectedIds[getTableTypeOrDefault(meta.options?.tableType)] = payload.items.map(({ id }) => id);
        } else if (meta.options?.tableType) {
          state.tableIds[getTableTypeOrDefault(meta.options?.tableType)] = payload.items.map(({ id }) => id);
          state.selectedIds[getTableTypeOrDefault(meta.options?.tableType)] = state.selectedIds[
            getTableTypeOrDefault(meta.options?.tableType)
          ].filter(id => state.ids.includes(id));
        }
      },
      prepare(payload: QueryOutput<Locality>, meta: LocalitiesMeta) {
        return { payload, meta };
      },
    },
    fetchLocation: {
      reducer(state, { meta }: PayloadAction<number, string, LocalitiesMeta>) {
        state.loadStatusMap[meta.remoteOperation] = LoadStatus.Loading;
        state.error = null;
      },
      prepare(payload: number, options: SagaOptions = {}) {
        return {
          payload,
          meta: { remoteOperation: LocalitiesRemoteOperation.CRUD, options },
        };
      },
    },
    fetchLocationFailed: {
      reducer(state, { meta, error }: PayloadAction<number, string, LocalitiesMeta, string>) {
        state.loadStatusMap[meta.remoteOperation] = LoadStatus.Failed;
        state.error = error;
      },
      prepare(payload: number, meta: LocalitiesMeta, { message }: ErrorLike) {
        return { payload, meta, error: message };
      },
    },
    fetchLocationSucceeded: {
      reducer(state, { payload, meta }: PayloadAction<Locality, string, LocalitiesMeta>) {
        state.loadStatusMap[meta.remoteOperation] = LoadStatus.Succeeded;
        state.error = null;

        entityAdapter.setOne(state, payload);
      },
      prepare(payload: Locality, meta: LocalitiesMeta) {
        return { payload, meta };
      },
    },
    createLocation: {
      reducer(state, { meta }: PayloadAction<Locality, string, LocalitiesMeta>) {
        state.loadStatusMap[meta.remoteOperation] = LoadStatus.Loading;
        state.error = null;
      },
      prepare(payload: Locality, meta: LocalitiesMeta = { remoteOperation: LocalitiesRemoteOperation.CRUD }) {
        return { payload, meta };
      },
    },
    createLocationFailed: {
      reducer(state, { meta, error }: PayloadAction<Locality, string, LocalitiesMeta, string>) {
        state.loadStatusMap[meta.remoteOperation] = LoadStatus.Failed;
        state.error = error;
      },
      prepare(payload: Locality, meta: LocalitiesMeta, { message }: ErrorLike) {
        return { payload, meta, error: message };
      },
    },
    createLocationSucceeded: {
      reducer(state, { payload, meta }: PayloadAction<Locality, string, LocalitiesMeta>) {
        state.loadStatusMap[meta.remoteOperation] = LoadStatus.Succeeded;
        state.error = null;

        entityAdapter.setOne(state, payload);
      },
      prepare(payload: Locality, meta: LocalitiesMeta) {
        return { payload, meta };
      },
    },
    updateLocations: {
      reducer(state, _action: PayloadAction<PatchedModel<Locality>>) {
        state.loadStatusMap[LocalitiesRemoteOperation.CRUD] = LoadStatus.Loading;
        state.error = null;
      },
      prepare(changedIds: number[], changedFields: DeepPartial<Locality>) {
        return { payload: { ids: changedIds, data: changedFields } };
      },
    },
    updateLocationsFailed: {
      reducer(state, { error }: PayloadAction<PatchedModel<Locality>, string, never, string>) {
        state.loadStatusMap[LocalitiesRemoteOperation.CRUD] = LoadStatus.Failed;
        state.error = error;
      },
      prepare(payload: PatchedModel<Locality>, { message }: ErrorLike) {
        return { payload, error: message };
      },
    },
    updateLocationsSucceeded: (state, { payload }: PayloadAction<Locality[]>) => {
      state.loadStatusMap[LocalitiesRemoteOperation.CRUD] = LoadStatus.Succeeded;
      state.error = null;

      entityAdapter.setMany(state, payload);
    },
    deleteLocations: {
      reducer(state, { meta }: PayloadAction<number[], string, LocalitiesMeta>) {
        state.loadStatusMap[meta.remoteOperation] = LoadStatus.Loading;
        state.error = null;
      },
      prepare(locationsOrIds: Locality[] | number[], redirectTo?: string) {
        return {
          payload: getIds(locationsOrIds),
          meta: { remoteOperation: LocalitiesRemoteOperation.CRUD, options: { redirectTo } },
        };
      },
    },
    deleteLocationsFailed: {
      reducer(state, { meta, error }: PayloadAction<number[], string, LocalitiesMeta, string>) {
        state.loadStatusMap[meta.remoteOperation] = LoadStatus.Failed;
        state.error = error;
      },
      prepare(idsToDelete: number[], meta: LocalitiesMeta, { message }: ErrorLike) {
        return { payload: idsToDelete, meta, error: message };
      },
    },
    deleteLocationsSucceeded: {
      reducer(state, { payload, meta }: PayloadAction<number[], string, LocalitiesMeta>) {
        state.loadStatusMap[meta.remoteOperation] = LoadStatus.Succeeded;
        state.error = null;

        removeEntities(state, payload);
      },
      prepare(payload: number[], meta: LocalitiesMeta) {
        return { payload, meta };
      },
    },
    selectLocations: {
      reducer(state, { payload, meta }: PayloadAction<number[], string, LocalitiesMeta>) {
        state.loadStatusMap[meta.remoteOperation] = LoadStatus.Succeeded;

        state.selectedIds[getTableTypeOrDefault(meta.options?.tableType)] = payload;
      },
      prepare(locationsOrIds: Locality[] | number[], options: SagaOptions = {}) {
        return {
          payload: getIds(locationsOrIds),
          meta: { remoteOperation: LocalitiesRemoteOperation.CHECKBOXES, options },
        };
      },
    },
    toggleSelected: {
      reducer(state, { payload, meta }: PayloadAction<number, string, LocalitiesMeta>) {
        state.loadStatusMap[meta.remoteOperation] = LoadStatus.Succeeded;

        state.selectedIds[getTableTypeOrDefault(meta.options?.tableType)] = state.selectedIds[
          getTableTypeOrDefault(meta.options?.tableType)
        ].includes(payload)
          ? state.selectedIds[getTableTypeOrDefault(meta.options?.tableType)].filter(id => id !== payload)
          : [...state.selectedIds[getTableTypeOrDefault(meta.options?.tableType)], payload];
      },
      prepare(locationId: number, options: SagaOptions = {}) {
        return {
          payload: locationId,
          meta: { remoteOperation: LocalitiesRemoteOperation.CHECKBOXES, options },
        };
      },
    },
    iotUpdate: (state, { payload }: PayloadAction<Locality>) => {
      if (payload.audit?.deleted_at) {
        removeEntities(state, [payload.id]);
      } else {
        entityAdapter.setOne(state, normalize(payload));
      }
    },
  },
  extraReducers: builder => {
    builder.addCase(experiencesActions.fetchExperiencesSucceeded, (state, { payload }) => {
      if (isApiExperienceQueryOutput(payload)) {
        entityAdapter.setMany(
          state,
          payload.items.map(({ location }) => location),
        );
      }
    });

    builder.addCase(experiencesActions.fetchExperienceSucceeded, (state, { payload }) => {
      if (isApiExperience(payload)) {
        entityAdapter.setOne(state, payload.location);
      }
    });

    builder.addCase(experiencesActions.createExperienceSucceeded, (state, { payload }) => {
      entityAdapter.setOne(state, payload.location);
    });

    builder.addCase(experiencesActions.updateExperiencesSucceeded, (state, { payload }) => {
      entityAdapter.setMany(
        state,
        payload.map(({ location }) => location),
      );
    });

    builder.addCase(iotEventUpdate(IoTTopicPathEnum.Experiences), (state, { payload }) => {
      entityAdapter.setOne(state, normalize(payload.location));
    });
  },
});

export const { name: locationsType, actions: locationsActions, reducer: locationsReducer } = slice;

export const entitySelectors = entityAdapter.getSelectors();

export function* rootLocationsSaga() {
  yield all([watchLocations()]);
}
