import { produce } from "immer";

import { normalizeBase } from "@ds/model/helpers";

import { isPlayerQueryOutput, isPlayerArray } from "./model";

const normalizer = (entity: Player): Player =>
  normalizeBase({
    certificate_id: undefined,
    boot_time: undefined,
    os_version: undefined,
    video_mode: undefined,
    hdmi_status: undefined,
    experience_id: undefined,
    applet_type: undefined,
    applet_name: undefined,
    ...entity,
  });

export const normalize = ((
  entityOrEntities: Player | Player[] | QueryOutput<Player>,
): Player | Player[] | QueryOutput<Player> => {
  if (isPlayerQueryOutput(entityOrEntities)) {
    return produce(entityOrEntities, draft => {
      draft.items = entityOrEntities.items.map(normalizer);
    });
  }

  return isPlayerArray(entityOrEntities) ? entityOrEntities.map(normalizer) : normalizer(entityOrEntities);
}) as {
  (player: Player): Player;
  (players: Player[]): Player[];
  (playersQueryOutput: QueryOutput<Player>): QueryOutput<Player>;
};
