import { type DeepPartial } from "react-hook-form";

import { DSApi } from "@ds/services/ds-api";
import { type ApiService, type ApiServiceConfig } from "@ds/services/types";
import { parseQueries } from "@ds/utils/query";

interface ProjectsServiceConfig extends ApiServiceConfig {
  api: DSApi;
}

export class ProjectsService implements ApiService {
  private config: ProjectsServiceConfig = {
    api: {} as DSApi,
  };

  configure(cfg: ProjectsServiceConfig) {
    this.config = { ...cfg };
  }

  async getProjects(queryInfo: QueryInfo, pagination: Pagination) {
    const queries = parseQueries(queryInfo, pagination);
    return this.config.api.get<QueryOutput<Project>>("/projects/", queries);
  }

  async getProject(projectId: number) {
    return this.config.api.get<Project>(`/projects/${projectId}`);
  }

  async getProjectDashboard(projectId: number) {
    return this.config.api.get<ProjectDashboardInfo>(`/projects/${projectId}/dashboard`);
  }

  async updateProjects(ids: number[], data: DeepPartial<Project>) {
    return this.config.api.patch<Project>("/projects/", { ids, data });
  }
}

export const projectsService = new ProjectsService();
