import { Password as PrimePassword, type PasswordProps as PrimePasswordProps } from "primereact/password";
import { forwardRef } from "react";
import styled from "styled-components";

import { TextInputMixin } from "./styles";

const PasswordWrapper = styled.div`
  width: 100%;

  .p-password {
    width: 100%;
  }

  input {
    width: inherit;
    ${TextInputMixin}
  }
`;

export const Password = forwardRef<PrimePassword, PrimePasswordProps>((props, ref) => (
  <PasswordWrapper>
    <PrimePassword ref={ref} feedback={false} {...props} />
  </PasswordWrapper>
));

export const FloatPassword = forwardRef<PrimePassword, PrimePasswordProps & { label: string }>(
  ({ inputId, label, ...props }, ref) => (
    <PasswordWrapper className="p-float-label">
      <PrimePassword ref={ref} inputId={inputId} feedback={false} {...props} />
      <label htmlFor={inputId}>{label}</label>
    </PasswordWrapper>
  ),
);
