import { isNumber } from "lodash";
import { type Path } from "react-hook-form";

import { isMultipleFieldValuesInAggregatedEntity } from "@ds/utils/entities";
import { getProperty } from "@ds/utils/properties";

import { MultipleValuesLabel, UnassignedLabel } from "./forms/labels/common";

export const AggregatedValue = <T extends AggregatedEntity<T>>({
  entity,
  field,
  multipleFormatter,
  formatter,
  shouldShowEditedValue,
  shouldInheritColor,
}: {
  entity: T;
  field: Path<T>;
  multipleFormatter?: (entity: T, field: Path<T>) => React.ReactNode;
  formatter?: (entity: T, field: Path<T>) => React.ReactNode;
  shouldShowEditedValue?: boolean;
  shouldInheritColor?: boolean;
}) => {
  if (!shouldShowEditedValue && isMultipleFieldValuesInAggregatedEntity(entity, field)) {
    const displayMultipleValue = multipleFormatter ? (
      multipleFormatter(entity, field)
    ) : (
      <MultipleValuesLabel shouldInheritColor={shouldInheritColor} />
    );

    return <>{displayMultipleValue}</>;
  }

  const displayValue = formatter ? formatter(entity, field) : getProperty(entity, field);
  if (isNumber(displayValue)) {
    return <>{displayValue ?? <UnassignedLabel />}</>;
  }

  return <>{displayValue || <UnassignedLabel />}</>;
};
