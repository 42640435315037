import { cloneDeep } from "lodash";
import { push, replace } from "redux-first-history";
import { all, call, put, race, select, take, takeLatest, takeLeading, type SagaReturnType } from "redux-saga/effects";

import { DeploymentsRemoteOperation, deploymentsActions } from "@ds/modules/deployments/redux/slice";
import { iotEventDeploymentUpdate } from "@ds/modules/iot/redux/actions";
import { toastShowErrorAction, toastShowSuccessAction } from "@ds/modules/notifications/redux/actions";
import { isIotTableEntityAddedOrDeleted, selectTableData } from "@ds/modules/table-data/redux/selectors";
import { convertToApiQueryInfo } from "@ds/modules/table-data/utils/common";
import { MainTableDataTypeEnum } from "@ds/modules/table-data/utils/model";

import { DEPLOYMENTS_ROUTE } from "@ds/constants/router";
import { UnexpectedError } from "@ds/utils/errors";
import { logger } from "@ds/utils/logger";
import { UNLIMITED_PAGINATION } from "@ds/utils/query";
import { takeLatestOrEvery } from "@ds/utils/saga-helpers";
import { isErrorLike } from "@ds/utils/type-guards/error-guards";

import { deploymentsService } from "../utils/api";
import { normalize } from "../utils/normalizer";
import { selectDeploymentById, selectDeploymentsByIds, selectTableDeployments } from "./selectors";

function* switchRoute(_: ReturnType<typeof deploymentsActions.switchView>) {
  yield put(push(DEPLOYMENTS_ROUTE));
}

function* fetchDeployments({ meta }: ReturnType<typeof deploymentsActions.fetchDeployments>) {
  try {
    const { sorting, pagination, queryInfo, isFetchedAlready } = selectTableData(
      yield select(),
      meta.options.tableType || MainTableDataTypeEnum.Deployments,
      undefined,
      meta.filters,
    );

    const newMeta = cloneDeep(meta);
    const resultFromCache: QueryOutput<Deployment> = { items: [] };

    if (!newMeta.options.cache?.disableCache) {
      if (isFetchedAlready && newMeta.options.tableType) {
        resultFromCache.items = selectTableDeployments(yield select(), newMeta.options.tableType);
        newMeta.options.cache = {
          ...newMeta.options.cache,
          fetchedFromCache: true,
        };
      } else if (newMeta.filters.id && Object.keys(newMeta.filters).length === 1) {
        resultFromCache.items = selectDeploymentsByIds(yield select(), newMeta.filters.id);
        if (resultFromCache.items.length === newMeta.filters.id.length) {
          newMeta.options.cache = {
            ...newMeta.options.cache,
            fetchedFromCache: true,
          };
        }
      }
    }

    let result: QueryOutput<Deployment> = newMeta.options.cache?.fetchedFromCache
      ? resultFromCache
      : yield call(
          [deploymentsService, deploymentsService.getDeployments],
          convertToApiQueryInfo(sorting, queryInfo),
          newMeta.options.tableType ? pagination : UNLIMITED_PAGINATION,
        );

    result = newMeta.options.cache?.fetchedFromCache ? result : normalize(result);
    result = newMeta.options.tableType ? result : { items: result.items };

    yield put(deploymentsActions.fetchDeploymentsSucceeded(result, newMeta));
  } catch (err) {
    const errorTitle = "Fetch deployments";
    if (isErrorLike(err)) {
      yield put(deploymentsActions.fetchDeploymentsFailed(meta, err));
      yield put(toastShowErrorAction(err, errorTitle));
    } else {
      logger.error(`${errorTitle}: ${UnexpectedError}`);
      yield put(toastShowErrorAction(UnexpectedError, errorTitle));
    }
  }
}

function* fetchDeployment({ payload, meta }: ReturnType<typeof deploymentsActions.fetchDeployment>) {
  try {
    const newMeta = cloneDeep(meta);
    let result = selectDeploymentById(yield select(), payload);
    if (result && !newMeta.options.cache?.disableCache) {
      newMeta.options.cache = {
        ...newMeta.options.cache,
        fetchedFromCache: true,
      };
    } else {
      result = yield call([deploymentsService, deploymentsService.getDeployment], payload);
      result = normalize(result);
    }

    yield put(deploymentsActions.fetchDeploymentSucceeded(result, newMeta));
  } catch (err) {
    const errorTitle = "Fetch deployment";
    if (isErrorLike(err)) {
      yield put(deploymentsActions.fetchDeploymentFailed(payload, meta, err));
      yield put(toastShowErrorAction(err, errorTitle));
    } else {
      logger.error(`${errorTitle}: ${UnexpectedError}`);
      yield put(toastShowErrorAction(UnexpectedError, errorTitle));
    }
  }
}

function* createDeployment({ payload, meta }: ReturnType<typeof deploymentsActions.createDeployment>) {
  try {
    const result: SagaReturnType<typeof deploymentsService.createDeployment> = yield call(
      [deploymentsService, deploymentsService.createDeployment],
      payload,
    );

    yield put(deploymentsActions.createDeploymentSucceeded(normalize(result), meta));
    yield put(toastShowSuccessAction("Deployment was created successfully"));
    yield put(deploymentsActions.selectDeployments([result]));
  } catch (err) {
    const errorTitle = "Create deployment";
    if (isErrorLike(err)) {
      yield put(deploymentsActions.createDeploymentFailed(payload, meta, err));
      yield put(toastShowErrorAction(err, errorTitle));
    } else {
      logger.error(`${errorTitle}: ${UnexpectedError}`);
      yield put(toastShowErrorAction(UnexpectedError, errorTitle));
    }
  }
}

function* updateDeployments({ payload }: ReturnType<typeof deploymentsActions.updateDeployments>) {
  try {
    const result: SagaReturnType<typeof deploymentsService.updateDeployments> = yield call(
      [deploymentsService, deploymentsService.updateDeployments],
      payload.ids,
      payload.data,
    );

    yield put(deploymentsActions.updateDeploymentsSucceeded(normalize(result)));
    yield put(toastShowSuccessAction("Deployment(s) were updated successfully"));
  } catch (err) {
    const errorTitle = "Update deployment(s)";
    if (isErrorLike(err)) {
      yield put(deploymentsActions.updateDeploymentsFailed(payload, err));
      yield put(toastShowErrorAction(err, errorTitle));
    } else {
      logger.error(`${errorTitle}: ${UnexpectedError}`);
      yield put(toastShowErrorAction(UnexpectedError, errorTitle));
    }
  }
}

function* deleteDeployments({ payload, meta }: ReturnType<typeof deploymentsActions.deleteDeployments>) {
  try {
    yield call([deploymentsService, deploymentsService.deleteDeployments], payload);
    yield put(deploymentsActions.deleteDeploymentsSucceeded(payload, meta));
    yield put(toastShowSuccessAction("Deployment(s) were deleted successfully"));

    if (meta.options?.redirectTo) {
      yield put(replace(meta.options.redirectTo));
    }
  } catch (err) {
    const errorTitle = "Delete deployment(s)";
    if (isErrorLike(err)) {
      yield put(deploymentsActions.deleteDeploymentsFailed(payload, meta, err));
      yield put(toastShowErrorAction(err, errorTitle));
    } else {
      logger.error(`${errorTitle}: ${UnexpectedError}`);
      yield put(toastShowErrorAction(UnexpectedError, errorTitle));
    }
  }
}

function* scheduleDeployment({ payload }: ReturnType<typeof deploymentsActions.scheduleDeployment>) {
  try {
    const result: SagaReturnType<typeof deploymentsService.scheduleDeployment> = yield call(
      [deploymentsService, deploymentsService.scheduleDeployment],
      payload.deploymentId,
      payload.startAt,
    );

    yield put(deploymentsActions.scheduleDeploymentSucceeded(normalize(result)));
    yield put(toastShowSuccessAction("Deployment was scheduled successfully"));
  } catch (err) {
    const errorTitle = "Schedule deployment";
    if (isErrorLike(err)) {
      yield put(deploymentsActions.scheduleDeploymentFailed(payload.deploymentId, err));
      yield put(toastShowErrorAction(err, errorTitle));
    } else {
      logger.error(`${errorTitle}: ${UnexpectedError}`);
      yield put(toastShowErrorAction(UnexpectedError, errorTitle));
    }
  }
}

function* scheduleDeployments({ payload }: ReturnType<typeof deploymentsActions.scheduleDeployments>) {
  yield all(
    payload.deploymentIds.map(deploymentId =>
      call(scheduleDeployment, deploymentsActions.scheduleDeployment(deploymentId, payload.startAt)),
    ),
  );
}

function* unscheduleDeployment({ payload }: ReturnType<typeof deploymentsActions.unscheduleDeployment>) {
  try {
    const result: SagaReturnType<typeof deploymentsService.unscheduleDeployment> = yield call(
      [deploymentsService, deploymentsService.unscheduleDeployment],
      payload.deploymentId,
    );

    yield put(deploymentsActions.unscheduleDeploymentSucceeded(normalize(result)));
    yield put(toastShowSuccessAction("Deployment was unscheduled successfully"));
  } catch (err) {
    const errorTitle = "Unschedule deployment";
    if (isErrorLike(err)) {
      yield put(deploymentsActions.unscheduleDeploymentFailed(payload.deploymentId, err));
      yield put(toastShowErrorAction(err, errorTitle));
    } else {
      logger.error(`${errorTitle}: ${UnexpectedError}`);
      yield put(toastShowErrorAction(UnexpectedError, errorTitle));
    }
  }
}

function* unscheduleDeployments({ payload }: ReturnType<typeof deploymentsActions.unscheduleDeployments>) {
  yield all(
    payload.deploymentIds.map(deploymentId =>
      call(unscheduleDeployment, deploymentsActions.unscheduleDeployment(deploymentId)),
    ),
  );
}

function* refetchDeployments({ payload }: ReturnType<typeof iotEventDeploymentUpdate>) {
  const isAddedOrDeleted = isIotTableEntityAddedOrDeleted(yield select(), payload, MainTableDataTypeEnum.Deployments);
  if (isAddedOrDeleted) {
    yield call(
      fetchDeployments,
      deploymentsActions.fetchDeployments(
        undefined,
        {
          tableType: MainTableDataTypeEnum.Deployments,
          cache: { disableCache: true },
        },
        DeploymentsRemoteOperation.IOT_FETCH,
      ),
    );
  }

  yield put(deploymentsActions.iotUpdate(payload));
}

export function* watchDeployments() {
  yield takeLatest(deploymentsActions.switchView, switchRoute);
  yield takeLatestOrEvery(deploymentsActions.fetchDeployments, function* (action) {
    yield race({
      task: call(fetchDeployments, action),
      cancel: take([deploymentsActions.selectDeployments, deploymentsActions.toggleSelected]),
    });
  });

  yield takeLatestOrEvery(deploymentsActions.fetchDeployment, fetchDeployment);
  yield takeLeading(deploymentsActions.createDeployment, createDeployment);
  yield takeLeading(deploymentsActions.updateDeployments, updateDeployments);
  yield takeLeading(deploymentsActions.deleteDeployments, deleteDeployments);
  yield takeLeading(deploymentsActions.scheduleDeployments, scheduleDeployments);
  yield takeLeading(deploymentsActions.scheduleDeployment, scheduleDeployment);
  yield takeLeading(deploymentsActions.unscheduleDeployments, unscheduleDeployments);
  yield takeLeading(deploymentsActions.unscheduleDeployment, unscheduleDeployment);
  yield takeLatest(iotEventDeploymentUpdate, refetchDeployments);
}
