import { yupResolver } from "@hookform/resolvers/yup";
import { type FC } from "react";
import { type DeepPartial } from "react-hook-form";

import { isAggregatedEntity } from "@ds/model/entity-model";

import {
  DetailsCard,
  DetailsCardEditActionsMenu,
  DetailsCardRow,
  EditableDetailsCardDecorator,
  EditableDetailsCardRowDecorator,
} from "@ds/components/details-view";
import { AggregatedValue } from "@ds/components/entities";
import { DescriptionControl } from "@ds/components/forms/control/description-control";
import { formatRelativeDate } from "@ds/utils/date";
import { getProperty } from "@ds/utils/properties";

import { updateValidationSchema } from "../../utils/validation-schema";
import { PlaylistMediaTypesControl } from "../forms/controls/playlist-media-types-control";
import { PlaylistNameControl } from "../forms/controls/playlist-name-control";
import { PlaylistDetailsInfoCardActionsMenu } from "./playlist-details-info-card-actions-menu";

export const PlaylistDetailsInfoCard: FC<{
  playlist: Playlist;
  onSubmit: (changedFields: DeepPartial<Playlist>) => void;
}> = ({ playlist, onSubmit }) => (
  <EditableDetailsCardDecorator
    initialValue={playlist}
    validationResolver={yupResolver(updateValidationSchema.info)}
    onSubmit={onSubmit}
  >
    {cardProps => (
      <DetailsCard
        title="Info"
        icon="info-circle"
        actions={
          !cardProps.isDirty && !cardProps.isEditing ? (
            <PlaylistDetailsInfoCardActionsMenu playlist={cardProps.entity} />
          ) : (
            <DetailsCardEditActionsMenu
              isEditing={cardProps.isEditing}
              isDirty={cardProps.isDirty}
              onReset={cardProps.onResetForm}
              onSubmit={cardProps.handleSubmit(cardProps.onSubmitForm)}
            />
          )
        }
      >
        <EditableDetailsCardRowDecorator
          field="name"
          input={<PlaylistNameControl control={cardProps.control} autoFocus excludeLabel />}
          shouldHideEdit={isAggregatedEntity(cardProps.entity)}
          {...cardProps}
        >
          {rowProps => (
            <DetailsCardRow label="Name" editableSettings={rowProps} isRequired>
              <AggregatedValue entity={cardProps.entity} field="name" shouldShowEditedValue={rowProps.isDirty} />
            </DetailsCardRow>
          )}
        </EditableDetailsCardRowDecorator>

        <EditableDetailsCardRowDecorator
          field="description"
          input={<DescriptionControl control={cardProps.control} autoFocus excludeLabel />}
          {...cardProps}
        >
          {rowProps => (
            <DetailsCardRow label="Description" editableSettings={rowProps}>
              <AggregatedValue entity={cardProps.entity} field="description" shouldShowEditedValue={rowProps.isDirty} />
            </DetailsCardRow>
          )}
        </EditableDetailsCardRowDecorator>

        <EditableDetailsCardRowDecorator
          field="media_types"
          input={<PlaylistMediaTypesControl control={cardProps.control} autoFocus excludeLabel />}
          width="600px"
          {...cardProps}
        >
          {rowProps => (
            <DetailsCardRow label="Media Types" editableSettings={rowProps} isRequired>
              <AggregatedValue
                entity={cardProps.entity}
                field="media_types"
                formatter={(entity, field) => (getProperty(entity, field) || []).join(", ")}
                shouldShowEditedValue={rowProps.isDirty}
              />
            </DetailsCardRow>
          )}
        </EditableDetailsCardRowDecorator>

        <DetailsCardRow label="# Assets">
          <AggregatedValue entity={cardProps.entity} field="playlist_items_count" />
        </DetailsCardRow>

        <DetailsCardRow label="Last Updated">
          <AggregatedValue
            entity={cardProps.entity}
            field="audit.updated_at"
            formatter={(entity, field) => formatRelativeDate(getProperty(entity, field))}
          />
        </DetailsCardRow>
      </DetailsCard>
    )}
  </EditableDetailsCardDecorator>
);
