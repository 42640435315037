import { useCallback, useEffect, type FC } from "react";

import { AutocompleteTypeEnum, useFetchEntitiesAutocomplete } from "@ds/hooks/use-fetch-entities";
import { useIsFirstTimeTrue } from "@ds/hooks/use-is-first-time-true";

import { AutoComplete, type SingleAutoCompleteProps } from "@ds/components/forms/autocomplete";
import { SpinnerIcon } from "@ds/components/loaders/loaders";
import { useRootDispatch, useRootSelector } from "@ds/hooks";

import { selectPlaylistById, selectPlaylistsIsLoading } from "../redux/selectors";
import { playlistsActions } from "../redux/slice";
import { isPlaylist } from "../utils/model";

export const PlaylistsAutoComplete: FC<Omit<SingleAutoCompleteProps<Playlist>, "isLoading">> = ({
  inputRef,
  value,
  filterSuggestionsPredicate,
  dropdownMode,
  autoFocus,
  onBlur,
  onChange,
}) => {
  const [
    suggestions,
    onCompleteHandler,
    {
      state: { status: remoteStatus },
    },
  ] = useFetchEntitiesAutocomplete<Playlist>(AutocompleteTypeEnum.Playlists, {
    filterPredicate: filterSuggestionsPredicate,
  });

  return (
    <AutoComplete
      field="name"
      placeholder="Enter playlist name"
      inputRef={inputRef}
      value={value}
      suggestions={suggestions}
      dropdownMode={dropdownMode}
      autoFocus={autoFocus}
      isLoading={remoteStatus === "loading"}
      onBlur={onBlur}
      onChange={onChange}
      onComplete={onCompleteHandler}
    />
  );
};

export const PlaylistsAutocompleteRaw: FC<
  Omit<SingleAutoCompleteProps<Playlist>, "value" | "onChange" | "isLoading"> & {
    value?: number | null;
    onChange: (id: number | null, playlist: Playlist | null) => void;
  }
> = ({ value, onChange, ...restProps }) => {
  const isLoading = useRootSelector(selectPlaylistsIsLoading);
  const currentValue = useRootSelector(state =>
    isPlaylist(value) ? value : selectPlaylistById(state, Number(value) || 0),
  );

  const [isFirstLoading] = useIsFirstTimeTrue(isLoading);

  const dispatch = useRootDispatch();
  useEffect(() => {
    if (!isPlaylist(value) && Number(value)) {
      dispatch(playlistsActions.fetchPlaylist(Number(value), { shouldDisableSagaThrottling: true }));
    }
  }, [dispatch, value]);

  const onChangeHandler = useCallback(
    (playlist: Playlist) => {
      onChange(playlist?.id ?? null, playlist ?? null);
    },
    [onChange],
  );

  return isFirstLoading ? (
    <SpinnerIcon />
  ) : (
    <PlaylistsAutoComplete value={currentValue} onChange={onChangeHandler} {...restProps} />
  );
};
