import { yupResolver } from "@hookform/resolvers/yup";
import { useCallback, useEffect, type FC } from "react";
import { useForm } from "react-hook-form";

import { locationsActions } from "@ds/modules/locations/redux/slice";

import { DescriptionControl } from "@ds/components/forms/control/description-control";
import { WizardPage, type WizardChildStepProps } from "@ds/components/wizard";
import { useRootDispatch } from "@ds/hooks";

import { createValidationSchema } from "../../../utils/validation-schema";
import { LocationNameControl } from "../controls/location-name-control";
import { LocationStoreIdControl } from "../controls/location-store-id-control";
import { useCreateLocation } from "./create-location-context";

export const Step3Info: FC<WizardChildStepProps> = ({ nextStep, previousStep, ...rest }) => {
  const [currentLocation, submitLocation] = useCreateLocation();
  const { control, getValues, handleSubmit, reset } = useForm<Locality>({
    mode: "all",
    reValidateMode: "onChange",
    defaultValues: currentLocation,
    resolver: yupResolver(createValidationSchema.step3),
  });

  useEffect(
    () =>
      void reset({
        ...currentLocation,
        name: currentLocation.address?.organization || currentLocation.address?.street_address?.[0] || "",
      }),
    [currentLocation, reset],
  );

  const dispatch = useRootDispatch();
  const onNextClickHandler = useCallback(() => {
    const locationData = getValues();
    submitLocation(locationData);
    dispatch(
      locationsActions.createLocation({
        ...locationData,
        address: locationData.address?.place_id ? locationData.address : undefined,
      }),
    );

    if (nextStep) {
      nextStep();
    }
  }, [dispatch, submitLocation, getValues, nextStep]);

  const onBackClickHandler = useCallback(() => {
    submitLocation(getValues());
    if (previousStep) {
      previousStep();
    }
  }, [submitLocation, getValues, previousStep]);

  return (
    <WizardPage
      {...rest}
      title="Add location"
      previousStep={onBackClickHandler}
      nextStep={handleSubmit(onNextClickHandler)}
    >
      <LocationNameControl control={control} autoFocus />
      <LocationStoreIdControl control={control} />
      <DescriptionControl control={control} />
    </WizardPage>
  );
};
