import { useMemo, type FC } from "react";

import { useCurrentView } from "@ds/hooks/use-current-view";
import { useVisibility } from "@ds/hooks/use-visibility";

import { EntityTypeName } from "@ds/model/entity-model";

import { MainView } from "@ds/components/main-view";
import { TableTabMenu } from "@ds/components/table/table-tab-menu";

import { getPageTitleByTableDataType } from "../table-data/utils/helpers";
import { MainTableDataTypeEnum } from "../table-data/utils/model";
import { LocationsTableActionsMenu } from "./components/common/locations-table-actions-menu";
import { CreateLocationModal } from "./components/forms/create-location-form/create-location-modal";
import { LocationInfo } from "./components/location-info";
import { LocationsTable } from "./components/locations-table";

export const LocationsMainPage: FC = () => {
  const [currentView] = useCurrentView();
  const [isCreateModalVisible, setIsCreateModalVisible] = useVisibility();

  const filterOptions = useMemo(
    () => ({
      entityType: EntityTypeName.LOCATION,
      tableType: MainTableDataTypeEnum.Locations,
    }),
    [],
  );

  if (!currentView) {
    return null;
  }

  return (
    <>
      <MainView
        title={getPageTitleByTableDataType(filterOptions.tableType)}
        filterOptions={filterOptions}
        options={<TableTabMenu tableType={filterOptions.tableType} currentView={currentView} />}
        view={<LocationsTable />}
        info={<LocationInfo onCreateLocation={setIsCreateModalVisible(true)} />}
        actions={<LocationsTableActionsMenu onCreate={setIsCreateModalVisible(true)} />}
      />

      {isCreateModalVisible && <CreateLocationModal onClose={setIsCreateModalVisible(false)} />}
    </>
  );
};
