import { Sidebar as PrimeSidebar } from "primereact/sidebar";
import { type FC } from "react";
import styled from "styled-components";

import { selectCurrentUserProject } from "@ds/modules/settings/projects/redux/selectors/common-selectors";
import { selectCurrentUserProjectId } from "@ds/modules/settings/users/redux/selectors";

import {
  CONTENT_ROUTE,
  DASHBOARD_ROUTE,
  DEPLOYMENTS_ROUTE,
  DEVICES_ROUTE,
  EXPERIENCES_ROUTE,
  LOCATIONS_ROUTE,
  REPORTS_ROUTE,
  ROOT_ROUTE,
  SETTINGS_ROUTE,
} from "@ds/constants/router";
import { useRootSelector } from "@ds/hooks";

import { NavigationLink } from "./navigation-link";
import { NavigationLogo } from "./navigation-logo";

const StyledPrimeSidebar = styled(PrimeSidebar)`
  overflow-x: auto;
  scrollbar-gutter: stable;
  width: var(--navigation-width) !important;
  background: ${props => props.theme.navigationBackgroundColor} !important;

  ::-webkit-scrollbar {
    display: none;
  }

  .p-sidebar-content {
    padding: 0;
    margin: 0;
    overflow: visible;
    display: flex;
    flex-flow: column;

    a {
      width: 100%;
      display: flex;
      flex: 0 1 0;
      align-items: center;
      justify-content: center;
      div {
        margin: 0;
        padding: 0;
        height: calc(var(--navigation-width) * 0.85);
        justify-content: center;
      }
    }
  }

  .p-sidebar-header {
    display: none;
  }
`;

export const NavigationSidebar: FC = () => {
  const shouldShowDashboardPage = !!useRootSelector(selectCurrentUserProject)?.dashboard_id;
  const shouldShowReportsPage = useRootSelector(selectCurrentUserProjectId) === 29;

  return (
    <StyledPrimeSidebar
      visible
      position="left"
      modal={false}
      dismissable={false}
      showCloseIcon={false}
      onHide={() => undefined}
    >
      <NavigationLogo to={ROOT_ROUTE} />
      <NavigationLink to={EXPERIENCES_ROUTE} text="Experiences" icon="pi pi-play" />
      <NavigationLink to={DEVICES_ROUTE} text="Devices" icon="pi pi-desktop" />
      <NavigationLink to={LOCATIONS_ROUTE} text="Locations" icon="pi pi-map-marker" />
      <NavigationLink to={CONTENT_ROUTE} text="Content" icon="pi pi-video" />
      <NavigationLink to={DEPLOYMENTS_ROUTE} text="Deployments" icon="pi pi-sitemap" />
      {shouldShowDashboardPage && <NavigationLink to={DASHBOARD_ROUTE} text="Dashboard" icon="pi pi-th-large" />}
      {shouldShowReportsPage && <NavigationLink to={REPORTS_ROUTE} text="Reports" icon="pi pi-chart-bar" />}

      <NavigationLink to={SETTINGS_ROUTE} text="Settings" icon="pi pi-cog" className="mt-auto" />
    </StyledPrimeSidebar>
  );
};
