import { useCallback, type FC } from "react";
import styled from "styled-components";

import { useGetAggregatedEntityIds } from "@ds/hooks/use-get-aggregated-entity-ids";

import { deploymentsActions, DeploymentsRemoteOperation } from "@ds/modules/deployments/redux/slice";
import { createDeploymentModel, DeploymentScopeEnum } from "@ds/modules/deployments/utils/model";

import { ActionsBar } from "@ds/components/actions-bar";
import { Button, ButtonActionType, ButtonType } from "@ds/components/forms/buttons";
import { IconType } from "@ds/components/icons";
import { MenuWithButton } from "@ds/components/menu-dropdown/menu-with-button";
import { confirmSuccess, confirmWarning } from "@ds/components/popups/confirmation-dialog";
import { LOCATIONS_ROUTE } from "@ds/constants/router";
import { useRootDispatch, useRootSelector } from "@ds/hooks";
import { pluralizeDeleteMessage } from "@ds/utils/localization/common";
import {
  pluralizeDeployDescription,
  pluralizeDeployMessage,
  pluralizeDeployName,
} from "@ds/utils/localization/deployment";

import { selectLocationsByIds } from "../../redux/selectors";
import { locationsActions } from "../../redux/slice";

const Wrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

export const LocationDetailsInfoCardActionsMenu: FC<{ location: Locality }> = ({ location }) => {
  const aggregatedLocationIds = useGetAggregatedEntityIds(location);
  const aggregatedLocations = useRootSelector(state => selectLocationsByIds(state, aggregatedLocationIds));

  const dispatch = useRootDispatch();
  const onDeleteClickHandler = useCallback(
    () =>
      confirmWarning({
        message: pluralizeDeleteMessage("location", aggregatedLocationIds.length || 1),
        accept: () => dispatch(locationsActions.deleteLocations(aggregatedLocationIds, LOCATIONS_ROUTE)),
      }),
    [dispatch, aggregatedLocationIds],
  );

  const onDeployClickHandler = useCallback(
    () =>
      confirmSuccess({
        message: pluralizeDeployMessage("location", aggregatedLocationIds.length || 1),
        accept: () =>
          dispatch(
            deploymentsActions.createDeployment(
              {
                ...createDeploymentModel(),
                name: pluralizeDeployName(aggregatedLocations),
                description: pluralizeDeployDescription(aggregatedLocations),
                scope: DeploymentScopeEnum.Location,
                targets: aggregatedLocationIds,
              },
              { remoteOperation: DeploymentsRemoteOperation.DEPLOYING },
            ),
          ),
      }),
    [dispatch, aggregatedLocations, aggregatedLocationIds],
  );

  return (
    <Wrapper>
      <ActionsBar>
        <MenuWithButton
          menuItems={[
            {
              label: "Deploy Content",
              icon: "pi pi-fw pi-sitemap",
              command: onDeployClickHandler,
            },
          ]}
        />

        <Button
          label="Delete"
          ariaLabel="delete button"
          buttonType={ButtonType.Text}
          actionType={ButtonActionType.Error}
          icon={IconType.Delete}
          onClick={onDeleteClickHandler}
        />
      </ActionsBar>
    </Wrapper>
  );
};
