import { useCallback } from "react";

import { selectTableData } from "@ds/modules/table-data/redux/selectors";
import { DEFAULT_TABLE_TAB, tableDataActions } from "@ds/modules/table-data/redux/slice";
import { DetailsTableDataTypeEnum, MainTableDataTypeEnum } from "@ds/modules/table-data/utils/model";

import { type PaginatorPageChangeEvent } from "@ds/components/paginator/paginator";

import { useDeepRootSelector, useRootDispatch } from "./redux-hooks";

const useTableDataPagination = (
  tableType: MainTableDataTypeEnum | DetailsTableDataTypeEnum,
  tableTab = DEFAULT_TABLE_TAB,
  queryFilters?: KeysToCamelCase<QueryFilters>,
) => {
  const { pagination, queryInfo } = useDeepRootSelector(state =>
    selectTableData(state, tableType, tableTab, queryFilters),
  );

  const dispatch = useRootDispatch();

  return [
    pagination,
    useCallback(
      (newPagination: Pagination) =>
        dispatch(
          tableDataActions.updateTableData({
            tableType,
            tableTab,
            pagination: newPagination,
            queryInfo,
            options: { shouldDisableSagaThrottling: true },
          }),
        ),
      [dispatch, tableType, tableTab, queryInfo],
    ),
  ] as const;
};

export const usePagination = (
  tableType: MainTableDataTypeEnum | DetailsTableDataTypeEnum,
  tableTab = DEFAULT_TABLE_TAB,
  queryFilters?: KeysToCamelCase<QueryFilters>,
) => {
  const [pagination, setPagination] = useTableDataPagination(tableType, tableTab, queryFilters);
  return [
    pagination,
    useCallback(
      ({ page, rows }: PaginatorPageChangeEvent) => setPagination({ pageNumber: page + 1, pageSize: rows }),
      [setPagination],
    ),
  ] as const;
};
