import { isPlainObject } from "@ds/utils/type-guards/common-guards";

export const isFormationProperty = (obj: unknown): obj is EntityFormationProperty =>
  isPlainObject(obj) && "title" in obj && "type" in obj && "value" in obj;

export const isPlaylistFormationProperty = (obj: unknown): obj is TemplatePlaylistFormationProperty =>
  isFormationProperty(obj) && obj.type === "playlist";

export const isAssetFormationProperty = (obj: unknown): obj is TemplateAssetFormationProperty =>
  isFormationProperty(obj) && obj.type === "asset";

export const isBooleanFormationProperty = (obj: unknown): obj is TemplateBooleanFormationProperty =>
  isFormationProperty(obj) && obj.type === "boolean";

export const isNumberFormationProperty = (obj: unknown): obj is TemplateNumberFormationProperty =>
  isFormationProperty(obj) && obj.type === "number";

export const isStringFormationProperty = (obj: unknown): obj is TemplateStringFormationProperty =>
  isFormationProperty(obj) && obj.type === "string";
