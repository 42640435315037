import { useCallback, type ReactNode } from "react";
import {
  Controller,
  useController,
  useWatch,
  type Control,
  type FieldError,
  type Path,
  type PathValue,
  type UseFormSetValue,
} from "react-hook-form";

import { useDetailsInlineSaving } from "@ds/components/details-view/hooks";
import { BooleanTriStateCheckbox, Checkbox } from "@ds/components/forms/inputs";
import { LabelWrapper } from "@ds/components/forms/labels";
import { isMultipleFieldValuesInAggregatedEntity } from "@ds/utils/entities";
import { useOnMouseEventPreventDefaultHandler } from "@ds/utils/handlers";

export const BooleanControl = <T extends Entity>({
  name,
  control,
  setValue,
  labelTitle,
  autoFocus,
  isDisabled,
  excludeLabel,
  onBlur,
}: {
  name: Path<T>;
  control: Control<T>;
  setValue: UseFormSetValue<T>;
  labelTitle: string;
  autoFocus?: boolean;
  isDisabled?: boolean;
  excludeLabel?: boolean;
  onBlur?: () => void;
}) => {
  const onBlurHandler = useDetailsInlineSaving(onBlur);
  const onMouseDownHandler = useOnMouseEventPreventDefaultHandler();

  const entity = useWatch({ control }) as T;
  const mainFieldPath = `${name}` as Path<T>;
  const proxyFieldPath = `metadata.proxyFields.${name}` as Path<T>;

  const {
    fieldState: { error: mainFieldError },
  } = useController({ name: mainFieldPath, control });

  const hasMultipleValues = isMultipleFieldValuesInAggregatedEntity(entity, mainFieldPath as Path<T>);

  const onChangeHandler = useCallback(
    (onChange: (...event: unknown[]) => void) => (newValue?: boolean) => {
      setValue(mainFieldPath, (newValue ?? false) as PathValue<T, Path<T>>, {
        shouldDirty: true,
        shouldTouch: true,
        shouldValidate: true,
      });

      onChange(newValue);
    },
    [mainFieldPath, setValue],
  );

  const getLabelElement = useCallback(
    (labelError: FieldError | undefined, inputElement: ReactNode) => (
      <LabelWrapper label={labelTitle} className="col-8" error={labelError}>
        {inputElement}
      </LabelWrapper>
    ),
    [labelTitle],
  );

  if (hasMultipleValues) {
    return (
      <Controller
        name={proxyFieldPath}
        control={control}
        render={({ field: { ref, value, onChange, onBlur: onBlurFn, ...restFields }, fieldState }) => {
          const element = (
            <BooleanTriStateCheckbox
              ref={ref}
              isChecked={value as boolean}
              autoFocus={autoFocus}
              disabled={isDisabled}
              aria-disabled={isDisabled}
              onChange={onChangeHandler(onChange)}
              onBlur={onBlurHandler(onBlurFn)}
              onMouseDown={onMouseDownHandler}
              {...restFields}
            />
          );

          return excludeLabel ? element : getLabelElement(mainFieldError || fieldState.error, element);
        }}
      />
    );
  }

  return (
    <Controller
      name={mainFieldPath}
      control={control}
      render={({ field: { ref, value, onBlur: onBlurFn, ...restFields }, fieldState }) => {
        const element = (
          <Checkbox
            ref={ref}
            checked={value as boolean}
            autoFocus={autoFocus}
            disabled={isDisabled}
            aria-disabled={isDisabled}
            onBlur={onBlurHandler(onBlurFn)}
            onMouseDown={onMouseDownHandler}
            {...restFields}
          />
        );

        return excludeLabel ? element : getLabelElement(mainFieldError || fieldState.error, element);
      }}
    />
  );
};
