import { Children, cloneElement, isValidElement, useState, type FC, type ReactNode } from "react";
import ReactStepWizard from "react-step-wizard";
import styled from "styled-components";

import { Dialog } from "../modals";
import { WizardNav } from "./wizard-nav";
import { type WizardStepProps } from "./wizard-step";

const StepWizardWrapper = styled(ReactStepWizard)<{
  width: string;
  height: string;
  children: ReactNode;
}>`
  width: ${({ width }) => width};
  height: ${({ height }) => height};
  overflow-x: clip;
`;

export const Wizard: FC<{
  width: string;
  height: string;
  onClose: () => void;
  children: ReactNode;
}> = ({ children, width, height, onClose }) => {
  const [isValid, setIsValid] = useState(true);
  const [isVisible, setIsVisible] = useState(true);

  const onCloseClickHandler = () => {
    setIsVisible(false);
    onClose();
  };

  return (
    <Dialog
      visible={isVisible}
      onHide={onCloseClickHandler}
      modal
      showHeader={false}
      closeOnEscape
      focusOnShow={false}
      blockScroll
    >
      <StepWizardWrapper nav={<WizardNav isValid={isValid} />} width={width} height={height}>
        {Children.map(children, child =>
          isValidElement<WizardStepProps>(child)
            ? cloneElement(child, { setIsValid, onClose: onCloseClickHandler })
            : null,
        )}
      </StepWizardWrapper>
    </Dialog>
  );
};
