import { createSelector } from "@reduxjs/toolkit";

import { DetailsTableDataTypeEnum, MainTableDataTypeEnum } from "@ds/modules/table-data/utils/model";

import { type RootState } from "@ds/store/bootstrap";
import { selectorsOutputEntitiesByIds } from "@ds/store/global-selectors";

import { getAggregatedModel } from "@ds/utils/entities";
import { LoadStatus } from "@ds/utils/reducer";

import { ContentAssetsRemoteOperation, entitySelectors } from "../slice";

export const selectBaseState = (state: RootState) => state.content.assets;
const selectLoadStatus = (state: RootState, operation = ContentAssetsRemoteOperation.CRUD) =>
  selectBaseState(state).loadStatusMap[operation];

export const selectContentAssetsIsLoading = (state: RootState, operation = ContentAssetsRemoteOperation.CRUD) =>
  selectLoadStatus(state, operation) === LoadStatus.Loading;

export const selectContentAssetsIsSucceeded = (state: RootState, operation = ContentAssetsRemoteOperation.CRUD) =>
  selectLoadStatus(state, operation) === LoadStatus.Succeeded;

export const selectTableContentAssetIds = (
  state: RootState,
  tableType: MainTableDataTypeEnum | DetailsTableDataTypeEnum = MainTableDataTypeEnum.ContentVideos,
) => selectBaseState(state).tableIds[tableType];

const selectSelectedContentAssetIds = (
  state: RootState,
  tableType: MainTableDataTypeEnum | DetailsTableDataTypeEnum = MainTableDataTypeEnum.ContentVideos,
) => selectBaseState(state).selectedIds[tableType];

export const selectContentAssetById = (state: RootState, id: number) =>
  entitySelectors.selectById(selectBaseState(state), id) as ContentAsset;

export const selectTableContentAssets = createSelector(
  selectBaseState,
  selectTableContentAssetIds,
  selectorsOutputEntitiesByIds(() => entitySelectors),
);

export const selectSelectedContentAssets = createSelector(
  selectBaseState,
  selectSelectedContentAssetIds,
  selectorsOutputEntitiesByIds(() => entitySelectors),
);

export const selectAggregatedContentAssetForSelectedEntities = createSelector(
  selectSelectedContentAssets,
  selectedContentAssets => getAggregatedModel(selectedContentAssets),
);
