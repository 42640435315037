import { createSelector } from "@reduxjs/toolkit";

import { DetailsTableDataTypeEnum, MainTableDataTypeEnum } from "@ds/modules/table-data/utils/model";

import { type RootState } from "@ds/store/bootstrap";
import { selectorsExtractIds, selectorsOutputEntitiesByIds } from "@ds/store/global-selectors";

import { getAggregatedModel } from "@ds/utils/entities";
import { LoadStatus } from "@ds/utils/reducer";

import { DeploymentsRemoteOperation, entitySelectors } from "./slice";

const selectBaseState = (state: RootState) => state.deployments;

export const selectCurrentView = (state: RootState) => selectBaseState(state).viewType;

const selectLoadStatus = (state: RootState, operation = DeploymentsRemoteOperation.CRUD) =>
  selectBaseState(state).loadStatusMap[operation];

export const selectDeploymentsIsLoading = (state: RootState, operation = DeploymentsRemoteOperation.CRUD) =>
  selectLoadStatus(state, operation) === LoadStatus.Loading;

export const selectDeploymentsError = (state: RootState) => selectBaseState(state).error;

export const selectTableDeploymentIds = (
  state: RootState,
  tableType: MainTableDataTypeEnum | DetailsTableDataTypeEnum = MainTableDataTypeEnum.Deployments,
) => selectBaseState(state).tableIds[tableType];

export const selectSelectedDeploymentIds = (
  state: RootState,
  tableType: MainTableDataTypeEnum | DetailsTableDataTypeEnum = MainTableDataTypeEnum.Deployments,
) => selectBaseState(state).selectedIds[tableType];

export const selectDeploymentById = (state: RootState, id: number) =>
  entitySelectors.selectById(selectBaseState(state), id) as Deployment;

export const selectDeploymentIds = (state: RootState) => entitySelectors.selectIds(selectBaseState(state));
export const selectDeployments = (state: RootState) => entitySelectors.selectAll(selectBaseState(state));

export const selectDeploymentsByIds = createSelector(
  selectBaseState,
  selectorsExtractIds,
  selectorsOutputEntitiesByIds(() => entitySelectors),
);

export const selectTableDeployments = createSelector(
  selectBaseState,
  selectTableDeploymentIds,
  selectorsOutputEntitiesByIds(() => entitySelectors),
);

export const selectSelectedDeployments = createSelector(
  selectBaseState,
  selectSelectedDeploymentIds,
  selectorsOutputEntitiesByIds(() => entitySelectors),
);

export const selectAggregatedDeploymentByIds = createSelector(selectBaseState, selectorsExtractIds, (state, ids) =>
  ids?.length ? getAggregatedModel(selectorsOutputEntitiesByIds(entitySelectors)(state, ids)) : null,
);

export const selectAggregatedDeploymentForSelectedEntities = createSelector(
  selectSelectedDeployments,
  selectedDeployments => getAggregatedModel(selectedDeployments),
);
