import { useMemo, type FC } from "react";

import { useCurrentView } from "@ds/hooks/use-current-view";
import { useVisibility } from "@ds/hooks/use-visibility";

import { EntityTypeName } from "@ds/model/entity-model";

import { MainView } from "@ds/components/main-view";
import { TableTabMenu } from "@ds/components/table/table-tab-menu";
import { useDeepRootSelector } from "@ds/hooks";

import { MainTableDataTypeEnum } from "../../table-data/utils/model";
import { PlayersTableActionsMenu } from "./components/common/player-table-actions-menu";
import { CreatePlayerModal } from "./components/forms/create-player-form/create-player-modal";
import { PairPlayerModal } from "./components/forms/pair-player-form/pair-player-modal";
import { PlayerInfo } from "./components/player-info";
import { PlayersTable } from "./components/players-table";
import { selectAggregatedPlayerForSelectedEntities } from "./redux/selectors";

export const PlayersMainPage: FC = () => {
  const [currentView] = useCurrentView();
  const [isCreateModalVisible, setIsCreateModalVisible] = useVisibility();
  const [isPairModalVisible, setIsPairModalVisible] = useVisibility();

  const aggregatedPlayer = useDeepRootSelector(selectAggregatedPlayerForSelectedEntities);

  const filterOptions = useMemo(
    () => ({
      entityType: EntityTypeName.DEVICE_PLAYER,
      tableType: MainTableDataTypeEnum.DevicePlayers,
    }),
    [],
  );

  if (!currentView) {
    return null;
  }

  return (
    <>
      <MainView
        title="Players"
        filterOptions={filterOptions}
        options={<TableTabMenu tableType={filterOptions.tableType} currentView={currentView} />}
        view={<PlayersTable />}
        info={<PlayerInfo onCreatePlayer={setIsCreateModalVisible(true)} onPairPlayer={setIsPairModalVisible(true)} />}
        actions={
          <PlayersTableActionsMenu onCreate={setIsCreateModalVisible(true)} onPair={setIsPairModalVisible(true)} />
        }
      />

      {isCreateModalVisible && <CreatePlayerModal onClose={setIsCreateModalVisible(false)} />}
      {isPairModalVisible && aggregatedPlayer && (
        <PairPlayerModal initialValue={aggregatedPlayer} onClose={setIsPairModalVisible(false)} />
      )}
    </>
  );
};
