import { call, put } from "redux-saga/effects";

import { contentAssetsActions } from "@ds/modules/content/assets/redux/slice";
import { playlistAssetsActions } from "@ds/modules/content/playlist-assets/redux/slice";
import { playlistsActions } from "@ds/modules/content/playlists/redux/slice";
import { deploymentsActions } from "@ds/modules/deployments/redux/slice";
import { deviceSyncsActions } from "@ds/modules/device-syncs/redux/slice";
import { peripheralsActions } from "@ds/modules/devices/peripherals/redux/slice";
import { playersActions } from "@ds/modules/devices/players/redux/slice";
import { experiencesActions } from "@ds/modules/experiences/redux/slice";
import { locationsActions } from "@ds/modules/locations/redux/slice";
import { invitationsActions } from "@ds/modules/settings/invitations/redux/slice";
import { projectsActions } from "@ds/modules/settings/projects/redux/slice";
import { usersActions } from "@ds/modules/settings/users/redux/slice";
import { tableDataActions } from "@ds/modules/table-data/redux/slice";

import { getQueryFilters } from "@ds/utils/query";
import { takeLatestOrEvery } from "@ds/utils/saga-helpers";

import { DetailsTableDataTypeEnum, MainTableDataTypeEnum } from "../utils/model";

function* fetchTableData(tableType: MainTableDataTypeEnum | DetailsTableDataTypeEnum, queryFilters?: QueryFilters) {
  if (tableType === MainTableDataTypeEnum.Experiences) {
    yield put(experiencesActions.fetchExperiences(undefined, { tableType, cache: { disableCache: true } }));
  } else if (tableType === MainTableDataTypeEnum.DevicePlayers) {
    yield put(playersActions.fetchPlayers(undefined, { tableType, cache: { disableCache: true } }));
  } else if (tableType === MainTableDataTypeEnum.DevicePeripherals) {
    yield put(peripheralsActions.fetchPeripherals(undefined, { tableType, cache: { disableCache: true } }));
  } else if (tableType === MainTableDataTypeEnum.Locations) {
    yield put(locationsActions.fetchLocations(undefined, { tableType, cache: { disableCache: true } }));
  } else if (tableType === DetailsTableDataTypeEnum.LocationExperiences) {
    yield put(
      experiencesActions.fetchExperiences(
        { locationId: getQueryFilters(queryFilters, tableType)?.location_id },
        { tableType, cache: { disableCache: true } },
      ),
    );
  } else if (tableType === DetailsTableDataTypeEnum.LocationPlayers) {
    yield put(
      experiencesActions.fetchExperiences(
        {
          locationId: getQueryFilters(queryFilters, tableType)?.location_id,
        },
        { tableType, cache: { disableCache: true } },
      ),
    );

    yield put(
      playersActions.fetchPlayers(
        {
          locationId: getQueryFilters(queryFilters, tableType)?.location_id,
        },
        { tableType, cache: { disableCache: true } },
      ),
    );
  } else if (tableType === MainTableDataTypeEnum.ContentPlaylists) {
    yield put(playlistsActions.fetchPlaylists(undefined, { tableType, cache: { disableCache: true } }));
  } else if (tableType === DetailsTableDataTypeEnum.ContentPlaylistAssets) {
    yield put(
      playlistAssetsActions.fetchPlaylistAssets(
        { playlistId: getQueryFilters(queryFilters, tableType)?.playlist_id },
        { tableType, cache: { disableCache: true } },
      ),
    );
  } else if (
    tableType === MainTableDataTypeEnum.ContentVideos ||
    tableType === MainTableDataTypeEnum.ContentImages ||
    tableType === MainTableDataTypeEnum.ContentAudio
  ) {
    yield put(
      contentAssetsActions.fetchContentAssets(
        { contentType: getQueryFilters(queryFilters, tableType)?.content_type },
        { tableType, cache: { disableCache: true } },
      ),
    );
  } else if (tableType === MainTableDataTypeEnum.Deployments) {
    yield put(deploymentsActions.fetchDeployments(undefined, { tableType, cache: { disableCache: true } }));
  } else if (tableType === DetailsTableDataTypeEnum.DeploymentDeviceSyncs) {
    yield put(
      deviceSyncsActions.fetchDeviceSyncs(
        {
          deploymentId: getQueryFilters(queryFilters, tableType)?.deployment_id,
        },
        { tableType, cache: { disableCache: true } },
      ),
    );
  } else if (tableType === MainTableDataTypeEnum.Projects) {
    yield put(projectsActions.fetchProjects(undefined, { tableType, cache: { disableCache: true } }));
  } else if (tableType === MainTableDataTypeEnum.Users) {
    yield put(usersActions.fetchUsers(undefined, { tableType, cache: { disableCache: true } }));
  } else if (tableType === MainTableDataTypeEnum.Invitations) {
    yield put(invitationsActions.fetchInvitations(undefined, { tableType, cache: { disableCache: true } }));
  }
}

export function* updateTableData({
  payload: { tableType, queryInfo },
}: ReturnType<typeof tableDataActions.updateTableData>) {
  yield call(fetchTableData, tableType, queryInfo?.filters);
}

export function* watchTableData() {
  yield takeLatestOrEvery(tableDataActions.updateTableData, updateTableData);
}
