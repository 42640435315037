export const readFileContent = (file: File): Promise<string> =>
  new Promise<string>(resolve => {
    const reader = new FileReader();
    reader.onload = e => {
      if (e.target?.result) {
        const content = e.target.result.toString();
        resolve(content);
      }
    };

    reader.readAsDataURL(file);
  });
