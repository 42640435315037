import { type FC } from "react";

import { AggregatedValue } from "@ds/components/entities";
import { IconType } from "@ds/components/icons";
import { InfoAttribute, InfoSection, InfoView, InfoViewCallToAction } from "@ds/components/info-view";
import { getInfoViewTitle } from "@ds/components/info-view/helpers";
import { useDeepRootSelector } from "@ds/hooks";
import { getProperty } from "@ds/utils/properties";

import { selectAggregatedPlaylistForSelectedEntities } from "../redux/selectors";

export const PlaylistInfo: FC<{ onCreatePlaylist: () => void }> = ({ onCreatePlaylist }) => {
  const aggregatedPlaylist = useDeepRootSelector(selectAggregatedPlaylistForSelectedEntities);
  if (aggregatedPlaylist) {
    return (
      <InfoView
        infoTitle={getInfoViewTitle(aggregatedPlaylist)}
        infoDescription={<AggregatedValue entity={aggregatedPlaylist} field="description" />}
      >
        <InfoSection title="Playlist Info" icon="pi pi-info-circle">
          <InfoAttribute
            name="Media Types"
            value={
              <AggregatedValue
                entity={aggregatedPlaylist}
                field="media_types"
                formatter={(entity, field) => (getProperty(entity, field) || []).join(", ")}
              />
            }
          />

          <InfoAttribute
            name="# Assets"
            value={<AggregatedValue entity={aggregatedPlaylist} field="playlist_items_count" />}
          />
        </InfoSection>
      </InfoView>
    );
  }

  return (
    <InfoViewCallToAction
      infoTitle="Select playlist"
      callToActionText="Select a playlist in the table for more info or proceed with creating a new playlist."
      buttonLabel="Create playlist"
      buttonIcon={IconType.Create}
      onActionButtonClick={onCreatePlaylist}
    />
  );
};
