import { yupResolver } from "@hookform/resolvers/yup";
import { compact } from "lodash";
import { useCallback, type FC } from "react";
import { useForm } from "react-hook-form";

import { selectCurrentUserProject } from "@ds/modules/settings/projects/redux/selectors/common-selectors";
import { UserRoleControl } from "@ds/modules/settings/users/components/forms/controls/user-role-control";

import { WizardPage, type WizardChildStepProps } from "@ds/components/wizard";
import { useRootDispatch, useRootSelector } from "@ds/hooks";

import { invitationsActions } from "../../../redux/slice";
import { createInvitationModel } from "../../../utils/model";
import { createValidationSchema } from "../../../utils/validation-schema";
import { InvitationEmailControl } from "../../controls/invitation-email-control";
import { InvitationProjectsControl } from "../../controls/invitation-projects-control";

export const Step1Main: FC<WizardChildStepProps> = ({ nextStep, ...rest }) => {
  const currentUserProject = useRootSelector(selectCurrentUserProject);
  const { control, getValues, setValue, handleSubmit } = useForm<InvitationFormData>({
    mode: "all",
    reValidateMode: "onChange",
    defaultValues: {
      invitation: createInvitationModel({ project_ids: compact<number>([currentUserProject?.id]) }),
      projects: [currentUserProject],
    },
    resolver: yupResolver(createValidationSchema.step1),
  });

  const dispatch = useRootDispatch();
  const onNextClickHandler = useCallback(() => {
    dispatch(invitationsActions.createInvitation(getValues().invitation));
    if (nextStep) {
      nextStep();
    }
  }, [dispatch, getValues, nextStep]);

  return (
    <WizardPage {...rest} title="Add invitation" nextStep={handleSubmit(onNextClickHandler)}>
      <InvitationEmailControl name="invitation.email" control={control} autoFocus />
      <UserRoleControl name="invitation.user_role" label="User Role" control={control} />
      <InvitationProjectsControl control={control} setValue={setValue} />
    </WizardPage>
  );
};
