import { useMemo, type FC } from "react";

import { useCurrentView } from "@ds/hooks/use-current-view";

import { EntityTypeName } from "@ds/model/entity-model";

import { MainView } from "@ds/components/main-view";
import { TableTabMenu } from "@ds/components/table/table-tab-menu";

import { getPageTitleByTableDataType } from "../../table-data/utils/helpers";
import { MainTableDataTypeEnum } from "../../table-data/utils/model";
import { PeripheralsTableActionsMenu } from "./components/common/peripherals-table-actions-menu";
import { PeripheralInfo } from "./components/peripheral-info";
import { PeripheralsTable } from "./components/peripherals-table";

export const PeripheralsMainPage: FC = () => {
  const [currentView] = useCurrentView();

  const filterOptions = useMemo(
    () => ({
      entityType: EntityTypeName.DEVICE_PERIPHERAL,
      tableType: MainTableDataTypeEnum.DevicePeripherals,
    }),
    [],
  );

  if (!currentView) {
    return null;
  }

  return (
    <MainView
      title={getPageTitleByTableDataType(filterOptions.tableType)}
      filterOptions={filterOptions}
      options={<TableTabMenu tableType={filterOptions.tableType} currentView={currentView} />}
      view={<PeripheralsTable />}
      info={<PeripheralInfo />}
      actions={<PeripheralsTableActionsMenu />}
    />
  );
};
