import { useCallback, type FC } from "react";
import styled from "styled-components";

import { type PlaylistAssetUploadsMap } from "@ds/modules/content/playlist-assets/redux/slice";

import { ProgressBar } from "@ds/components/progress-bar";
import { LoadStatus } from "@ds/utils/reducer";

import { type ContentAssetThumbnailUploadsMap, type ContentAssetUploadsMap } from "../../redux/slice";

const Wrapper = styled.div`
  width: 100%;
  display: flex;
  flex-flow: column;
`;

const UploadWrapper = styled.div`
  width: 100%;
  display: flex;
  flex-flow: row;
  justify-content: space-between;
  align-items: center;
  padding: 0.5em;
`;

const FileName = styled.div`
  flex-grow: 1;
  white-space: pre-wrap;
  overflow: hidden;
  text-overflow: ellipsis;
  margin-right: 2em;
`;

const ProgressBarWrapper = styled(ProgressBar)`
  min-width: 12em;
  height: 6px;
`;

const UploadCheckIcon = styled.i`
  color: var(--positiveColor);
`;

const UploadExclamationIcon = styled.i`
  color: var(--red-color);
`;

const renderFailedUpload = (fileName: string, errorMessage: string) => (
  <UploadWrapper key={`file-${fileName}`}>
    <FileName>{fileName}</FileName>
    <UploadExclamationIcon className="pi pi-exclamation-circle" title={errorMessage} />
  </UploadWrapper>
);

const renderLoaded = (fileName: string) => (
  <UploadWrapper key={`file-${fileName}`}>
    <FileName>{fileName}</FileName>
    <UploadCheckIcon className="pi pi-check" />
  </UploadWrapper>
);

export const UploadContentAssetView: FC<{
  uploadData: ContentAssetUploadsMap | PlaylistAssetUploadsMap | ContentAssetThumbnailUploadsMap;
}> = ({ uploadData }) => {
  const renderUploads = useCallback(
    () =>
      Object.entries(uploadData).map(([fileName, { loadStatus, error, progressInfo }]) => {
        if (error) {
          return renderFailedUpload(fileName, error);
        }

        if (loadStatus === LoadStatus.Succeeded) {
          return renderLoaded(fileName);
        }

        const progressPercent =
          progressInfo?.loaded && progressInfo?.total
            ? Math.floor((progressInfo.loaded / progressInfo.total) * 100)
            : 0;

        return (
          <UploadWrapper key={`file-${fileName}`}>
            <FileName>{fileName}</FileName>
            <ProgressBarWrapper value={progressPercent} showValue={false} />
          </UploadWrapper>
        );
      }),
    [uploadData],
  );

  return <Wrapper>{renderUploads()}</Wrapper>;
};
