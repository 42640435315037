import { API as AmplifyApi } from "aws-amplify";
import axios, { InternalAxiosRequestConfig, type AxiosResponse, type RawAxiosResponseHeaders } from "axios";
import Queue from "queue";
import { type DeepPartial } from "react-hook-form";

import { authService } from "@ds/modules/auth/utils/api";

import { REACT_APP_API_NAME, REACT_APP_BASE_URL, REACT_APP_CONCURRENT_API_CALLS } from "@ds/constants/environment";
import { throwAxiosErr } from "@ds/utils/errors/axios-error";
import { convertPaginationResponse } from "@ds/utils/pagination";

const AXIOS_DEFAULT_TIMEOUT = 30000;

export const configureAxiosDefaults = (timeout = AXIOS_DEFAULT_TIMEOUT) => {
  axios.interceptors.request.use((config: InternalAxiosRequestConfig) => ({ ...config, timeout }));
};

export class DSApi {
  private readonly config: {
    baseUrl: string;
    apiName: string;
  };

  private queue: Queue;

  constructor(baseUrl: string, apiName: string, concurrentApiCalls: number) {
    this.config = { baseUrl, apiName };
    this.queue = new Queue({ concurrency: concurrentApiCalls, autostart: true });
    this.initializeAmplifyApi();
  }

  get options() {
    return this.config;
  }

  get name() {
    return this.config.apiName;
  }

  get baseUrl() {
    return this.config.baseUrl;
  }

  async head(
    url: string,
    params?: string | string[][] | Record<string, string> | URLSearchParams | undefined,
  ): Promise<RawAxiosResponseHeaders> {
    return new Promise((resolve, reject) => {
      const domUrl = new URL(url, this.baseUrl);
      new URLSearchParams(params).forEach((value, key) => domUrl.searchParams.set(key, value));
      this.queue.push(() =>
        AmplifyApi.head(this.name, domUrl.pathname + domUrl.search, { response: true })
          .then((obj: AxiosResponse) => resolve(obj.headers))
          .catch(throwAxiosErr)
          .catch(reject),
      );
    });
  }

  async get<T = unknown>(
    url: string,
    params?: string | string[][] | Record<string, string> | URLSearchParams | undefined,
  ): Promise<T> {
    return new Promise<T>((resolve, reject) => {
      const domUrl = new URL(url, this.baseUrl);
      new URLSearchParams(params).forEach((value, key) => domUrl.searchParams.set(key, value));
      this.queue.push(() =>
        DSApi.handleResponse(
          AmplifyApi.get(this.name, domUrl.pathname + domUrl.search, { response: true }),
          resolve,
          reject,
        ),
      );
    });
  }

  async post<T = unknown>(url: string, body?: unknown): Promise<T> {
    return new Promise((resolve, reject) =>
      this.queue.push(() =>
        DSApi.handleResponse(AmplifyApi.post(this.name, url, { body, response: true }), resolve, reject),
      ),
    );
  }

  async put<T = unknown>(url: string, body?: unknown): Promise<T> {
    return new Promise((resolve, reject) =>
      this.queue.push(() =>
        DSApi.handleResponse(AmplifyApi.put(this.name, url, { body, response: true }), resolve, reject),
      ),
    );
  }

  async patch<T = unknown>(
    url: string,
    body: {
      ids: number[] | string[];
      data: DeepPartial<T>;
    },
  ): Promise<T[]> {
    return new Promise((resolve, reject) =>
      this.queue.push(() =>
        DSApi.handleResponse(AmplifyApi.patch(this.name, url, { body, response: true }), resolve, reject),
      ),
    );
  }

  async delete<T = unknown>(
    url: string,
    body?: {
      ids: number[] | string[];
    },
  ): Promise<T> {
    return new Promise((resolve, reject) =>
      this.queue.push(() =>
        DSApi.handleResponse(AmplifyApi.del(this.name, url, { body, response: true }), resolve, reject),
      ),
    );
  }

  initializeAmplifyApi() {
    AmplifyApi.configure({
      endpoints: [
        {
          name: this.config.apiName,
          endpoint: this.config.baseUrl,
          custom_header: async () => {
            try {
              return {
                Authorization: `Bearer ${await authService.getCurrentSessionJwtToken()}`,
              };
            } catch {
              // ignore error
            }
          },
        },
      ],
    });
  }

  private static async handleResponse<T>(
    promise: Promise<AxiosResponse>,
    resolve: (value: T | PromiseLike<T>) => void,
    reject: (reason?: unknown) => void,
  ) {
    return promise
      .then(obj =>
        obj.data?.items
          ? resolve({
              items: obj.data.items,
              pagination: convertPaginationResponse(obj.headers),
            } as T)
          : resolve(obj.data),
      )
      .catch(throwAxiosErr)
      .catch(reject);
  }
}

export const api = new DSApi(REACT_APP_BASE_URL, REACT_APP_API_NAME, REACT_APP_CONCURRENT_API_CALLS);
