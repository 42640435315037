import { type FC } from "react";
import { Navigate, Route, Routes } from "react-router-dom";

import {
  CONTENT_AUDIO_SEGMENT,
  CONTENT_IMAGES_SEGMENT,
  CONTENT_PLAYLISTS_SEGMENT,
  CONTENT_VIDEOS_SEGMENT,
} from "@ds/constants/router";
import { useRootSelector } from "@ds/hooks";

import { ContentAssetsMainPage } from "./assets/content-assets-main-page";
import { PlaylistsRouter } from "./playlists/playlists-router";
import { selectCurrentView } from "./redux/selectors";

const ContentRouteSwitch = () => {
  const currentView = useRootSelector(selectCurrentView);
  if (currentView) {
    return <Navigate to={currentView} replace />;
  }

  return <Navigate to={CONTENT_PLAYLISTS_SEGMENT} replace />;
};

export const ContentRouter: FC = () => (
  <Routes>
    <Route path="*" element={<ContentRouteSwitch />} />
    <Route path={`${CONTENT_PLAYLISTS_SEGMENT}/*`} element={<PlaylistsRouter />} />
    <Route path={CONTENT_VIDEOS_SEGMENT} element={<ContentAssetsMainPage />} />
    <Route path={CONTENT_IMAGES_SEGMENT} element={<ContentAssetsMainPage />} />
    <Route path={CONTENT_AUDIO_SEGMENT} element={<ContentAssetsMainPage />} />
  </Routes>
);
