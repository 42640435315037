import { type FC } from "react";

import { useParseQueryIds } from "@ds/hooks/use-parse-query-ids";
import { useReduxEntitiesFetch } from "@ds/hooks/use-redux-entities-fetch";

import { EntityTypeName } from "@ds/model/entity-model";

import { selectDeploymentsIsLoading } from "@ds/modules/deployments/redux/selectors";
import { DeploymentsRemoteOperation } from "@ds/modules/deployments/redux/slice";
import { MainTableDataTypeEnum } from "@ds/modules/table-data/utils/model";

import { DetailsView } from "@ds/components/details-view";
import { AbsoluteLoader } from "@ds/components/loaders/loading";
import { PERIPHERAL_ID_PARAM } from "@ds/constants/router";
import { QueryParamType, useDeepRootSelector, useRootSelector } from "@ds/hooks";
import { pluralizeTotalSelectedTitle } from "@ds/utils/localization/common";
import { isMultiple } from "@ds/utils/numbers";

import { PeripheralDetailsInfoCard } from "./components/details/peripheral-details-info-card";
import { PeripheralDetailsPlayerInfoCard } from "./components/details/peripheral-details-player-info-card";
import { selectAggregatedPeripheralByIds, selectPeripheralsIsLoading } from "./redux/selectors";

export const PeripheralDetailsPage: FC = () => {
  const queryIds = useParseQueryIds(PERIPHERAL_ID_PARAM, QueryParamType.Number);
  const peripheral = useDeepRootSelector(state => selectAggregatedPeripheralByIds(state, queryIds));

  const isLoading = useRootSelector(selectPeripheralsIsLoading);
  const isDeploying = useRootSelector(state => selectDeploymentsIsLoading(state, DeploymentsRemoteOperation.DEPLOYING));

  const [toggleRefetch] = useReduxEntitiesFetch(
    { entityType: EntityTypeName.DEVICE_PERIPHERAL, filters: { id: queryIds } },
    { initialLoad: true },
  );

  if (!peripheral) {
    return <AbsoluteLoader isLoading />;
  }

  return (
    <DetailsView
      title={
        isMultiple(peripheral?.metadata?.totalAggregated)
          ? pluralizeTotalSelectedTitle("peripheral", peripheral?.metadata?.totalAggregated)
          : peripheral.name
      }
      tableType={MainTableDataTypeEnum.DevicePeripherals}
      isLoading={isLoading || isDeploying}
      onRefresh={toggleRefetch}
    >
      <PeripheralDetailsInfoCard peripheral={peripheral} />
      <PeripheralDetailsPlayerInfoCard peripheral={peripheral} />
    </DetailsView>
  );
};
