import { type FC } from "react";
import { Controller, useWatch, type Control } from "react-hook-form";

import { InputText } from "@ds/components/forms/inputs";
import { LabelWrapper } from "@ds/components/forms/labels";
import { AddressCountry } from "@ds/services/google-api";

export const AddressStateControl: FC<{
  control: Control<Locality>;
  excludeLabel?: boolean;
  autoFocus?: boolean;
}> = ({ control, excludeLabel, autoFocus }) => {
  const currentCountry = useWatch({ name: "address.country", control });

  return (
    <Controller
      name="address.administrative_area"
      control={control}
      render={({ field: { ref, ...restFields }, fieldState }) => {
        const element = <InputText inputRef={ref} autoFocus={autoFocus} isReadonly {...restFields} />;
        if (excludeLabel) {
          return element;
        }

        return (
          <LabelWrapper
            label={currentCountry === AddressCountry.CANADA ? "Province / Region" : "State / Region"}
            className="col-4"
            error={fieldState.error}
          >
            {element}
          </LabelWrapper>
        );
      }}
    />
  );
};
