import { useCallback, useMemo, type FC } from "react";
import { useForm } from "react-hook-form";
import styled from "styled-components";

import { useRootDispatch, useRootSelector } from "@ds/hooks/redux-hooks";

import { MainTableDataTypeEnum } from "@ds/modules/table-data/utils/model";

import { Button, ButtonActionType, ButtonType } from "@ds/components/forms/buttons";
import { IconType } from "@ds/components/icons";
import { Dialog } from "@ds/components/modals";

import { selectThumbnailIsUploading } from "../../redux/selectors/upload-selectors";
import { contentAssetsActions } from "../../redux/slice";
import { ContentAssetsThumbnailControl } from "./common/content-assets-thumnail-control";

const ContentWrapper = styled.div`
  height: 400px;
  width: 800px;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const FooterWrapper = styled.div`
  display: flex;
  justify-content: right;
`;

export const UpdateContentAssetModal: FC<{
  tableType: MainTableDataTypeEnum;
  initialValue: ContentAsset;
  onClose: () => void;
}> = ({ tableType, initialValue, onClose }) => {
  const { control, setValue, getValues, handleSubmit } = useForm<ThumbnailFormData>({
    mode: "all",
    reValidateMode: "onChange",
    defaultValues: {
      isDirtyFile: false,
      thumbnailFile: null,
      contentAsset: initialValue,
    },
  });

  const isSaving = useRootSelector(selectThumbnailIsUploading);

  const dispatch = useRootDispatch();
  const onSubmitHandler = useCallback(() => {
    const { thumbnailFile, contentAsset, isDirtyFile } = getValues();
    if (thumbnailFile) {
      dispatch(contentAssetsActions.uploadContentAssetThumbnails([{ thumbnailFile, contentAsset }], tableType));
    } else if (initialValue.thumbnail && isDirtyFile) {
      dispatch(contentAssetsActions.deleteContentAssetThumbnail(initialValue.thumbnail, tableType));
    } else {
      onClose();
    }
  }, [dispatch, tableType, initialValue, getValues, onClose]);

  const renderFooter = useMemo(
    () => (
      <FooterWrapper>
        <Button
          label="Cancel"
          ariaLabel="cancel button"
          buttonType={ButtonType.Outlined}
          actionType={ButtonActionType.Secondary}
          icon={IconType.Close}
          iconPosition="right"
          onClick={onClose}
        />

        <Button
          label="Save"
          ariaLabel="save button"
          buttonType={ButtonType.Raised}
          icon={IconType.Save}
          iconPosition="right"
          isLoading={isSaving}
          onClick={handleSubmit(onSubmitHandler)}
        />
      </FooterWrapper>
    ),
    [isSaving, onClose, onSubmitHandler, handleSubmit],
  );

  return (
    <Dialog modal visible header="Add thumbnail" footer={renderFooter} onHide={onClose}>
      <ContentWrapper>
        <ContentAssetsThumbnailControl control={control} setValue={setValue} />
      </ContentWrapper>
    </Dialog>
  );
};
