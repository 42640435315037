import React, { FC, useEffect, useState, type ReactNode } from "react";

import { selectCurrentUser } from "@ds/modules/settings/users/redux/selectors";

import { useRootSelector } from "@ds/hooks";

type ThemeType = "light" | "dark";

const defaultTheme = "dark";

export const GlobalContext = React.createContext({
  currentTheme: defaultTheme as ThemeType,
  themeSwitchHandler: (_theme: ThemeType) => {},
});

export const GlobalContextProvider: FC<{ children: ReactNode }> = ({ children }) => {
  const currentUser = useRootSelector(selectCurrentUser);
  const [currentTheme, setCurrentTheme] = useState<ThemeType>(defaultTheme);

  useEffect(() => {
    setCurrentTheme(currentUser?.use_dark_theme ? "dark" : "light");
  }, [currentUser, setCurrentTheme]);

  const themeSwitchHandler = (theme: ThemeType) => {
    setCurrentTheme(theme);
  };

  return (
    <GlobalContext.Provider value={{ currentTheme: currentTheme, themeSwitchHandler }}>
      {children}
    </GlobalContext.Provider>
  );
};
