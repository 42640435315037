import { nanoid } from "@reduxjs/toolkit";
import { useCallback, type ComponentProps, type FC } from "react";
import { Controller, useWatch, type Control, type UseFormSetValue } from "react-hook-form";

import { getPlayerStatusIndicatorWithName } from "@ds/modules/devices/players/components/common/status-indicator";

import { useDetailsInlineSaving } from "@ds/components/details-view/hooks";
import { AutoCompleteMultipleDecorator } from "@ds/components/forms/autocomplete";
import { LabelWrapper } from "@ds/components/forms/labels";

import { ExperiencePlayersAutoComplete } from "../../experience-players-autocomplete";

export const ExperiencePlayersControl: FC<{
  control: Control<ExperienceFormData>;
  setValue?: UseFormSetValue<ExperienceFormData>;
  autoFocus?: boolean;
  excludeLabel?: boolean;
  onBlur?: () => void;
}> = ({ control, setValue, autoFocus, excludeLabel, onBlur }) => {
  const onBlurHandler = useDetailsInlineSaving(onBlur);
  const currentExperienceId = useWatch({ name: "experience.id", control });

  const onChangeHandler = useCallback(
    (onChange: (inputData: Player[]) => void) => (inputData: Player[]) => {
      if (setValue) {
        setValue(
          "experience.device_ids",
          inputData.map(({ id }) => id),
          {
            shouldDirty: true,
            shouldTouch: true,
            shouldValidate: true,
          },
        );
      }

      onChange(inputData);
    },
    [setValue],
  );

  return (
    <Controller
      name="players"
      control={control}
      render={({ field: { ref, value, onChange, onBlur: onBlurFn, ...restFields }, fieldState }) => {
        const element = (
          <AutoCompleteMultipleDecorator
            inputRef={ref}
            value={value}
            chipsMapper={(player: Player) => [
              `experience-player-chip-${nanoid()}`,
              player.name,
              {
                template: getPlayerStatusIndicatorWithName(player),
              },
            ]}
            filterSuggestionsPredicate={() => true}
            onChange={onChangeHandler(onChange)}
            onBlur={onBlurHandler(onBlurFn)}
            {...restFields}
          >
            {(props: ComponentProps<typeof ExperiencePlayersAutoComplete>) => (
              <ExperiencePlayersAutoComplete
                currentExperienceId={currentExperienceId}
                assignedEntities={value}
                autoFocus={autoFocus}
                {...props}
              />
            )}
          </AutoCompleteMultipleDecorator>
        );

        if (excludeLabel) {
          return element;
        }

        return (
          <LabelWrapper label="Players" className="col-8" error={fieldState.error}>
            {element}
          </LabelWrapper>
        );
      }}
    />
  );
};
