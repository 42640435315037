import { yupResolver } from "@hookform/resolvers/yup";
import { useCallback, useEffect, type FC } from "react";
import { useForm } from "react-hook-form";

import { useCreateDeployment } from "@ds/modules/deployments/components/forms/create-deployment-form/create-deployment-context";
import { DeploymentScopeEnum } from "@ds/modules/deployments/utils/model";
import { createValidationSchema } from "@ds/modules/deployments/utils/validation-schema";

import { WizardPage, type WizardChildStepProps } from "@ds/components/wizard";

import { DeploymentScopeControl } from "../controls/deployment-scope-control";
import { DeploymentTargetsDevicesControl } from "../controls/deployment-targets-devices-control";
import { DeploymentTargetsExperiencesControl } from "../controls/deployment-targets-experiences-control";
import { DeploymentTargetsLocationsControl } from "../controls/deployment-targets-locations-control";

export const Step2Scope: FC<WizardChildStepProps> = ({ nextStep, previousStep, ...rest }) => {
  const [currentDeployment, submitDeployment] = useCreateDeployment();
  const { control, reset, setValue, getValues, watch, handleSubmit } = useForm<Deployment>({
    mode: "onSubmit",
    reValidateMode: "onChange",
    defaultValues: currentDeployment,
    resolver: yupResolver(createValidationSchema.step2),
  });

  useEffect(() => reset(currentDeployment), [currentDeployment, reset]);

  const onNextClickHandler = useCallback(() => {
    submitDeployment(getValues());
    if (nextStep) {
      nextStep();
    }
  }, [submitDeployment, getValues, nextStep]);

  const onBackClickHandler = useCallback(() => {
    submitDeployment(getValues());
    if (previousStep) {
      previousStep();
    }
  }, [submitDeployment, getValues, previousStep]);

  const deploymentScope = watch("scope");

  return (
    <WizardPage
      {...rest}
      title={`${currentDeployment.type} Deployment Scope`}
      nextStep={handleSubmit(onNextClickHandler)}
      previousStep={onBackClickHandler}
    >
      <DeploymentScopeControl control={control} setValue={setValue} />
      {deploymentScope === DeploymentScopeEnum.Device && <DeploymentTargetsDevicesControl control={control} />}
      {deploymentScope === DeploymentScopeEnum.Location && <DeploymentTargetsLocationsControl control={control} />}
      {deploymentScope === DeploymentScopeEnum.Experience && <DeploymentTargetsExperiencesControl control={control} />}
    </WizardPage>
  );
};
