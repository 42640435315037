import { UnassignedLabel } from "@ds/components/forms/labels/common";
import { ShadedLabel } from "@ds/components/forms/labels/styles";
import { StatusIndicator } from "@ds/components/status-indicator";
import type { ColumnBodyOptions } from "@ds/components/table/table-columns";
import { TextLink } from "@ds/components/text-link";
import { composeDetailsRouteByEntityType } from "@ds/utils/router";

import { getPlayerPrintableStatus } from "../../utils/helpers";

export const getPlayerStatusIndicator = (player: Player, options?: ColumnBodyOptions & { key?: string }) => (
  <StatusIndicator key={options?.key} status={getPlayerPrintableStatus(player.state)}>
    {player.state}
  </StatusIndicator>
);

export const getPlayerStatusIndicatorWithName = (player: Player, options?: { key?: string }) => (
  <StatusIndicator key={options?.key} status={getPlayerPrintableStatus(player.state)}>
    {player.name || <UnassignedLabel />}
    <ShadedLabel className="ml-2">{`[ ${player.state} ]`}</ShadedLabel>
  </StatusIndicator>
);

export const getPlayerStatusIndicatorWithNameAndLink = (player: Player, options?: { key?: string }) => {
  const state = `[ ${player.state} ]`;
  return (
    <StatusIndicator
      key={options?.key}
      status={getPlayerPrintableStatus(player.state)}
      title={`${player.name} ${state}`}
    >
      <TextLink to={composeDetailsRouteByEntityType(player)}>{player.name || <UnassignedLabel />}</TextLink>
      <ShadedLabel className="ml-2">{state}</ShadedLabel>
    </StatusIndicator>
  );
};
