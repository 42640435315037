import { Button as PrimeButton, type ButtonProps as PrimeButtonProps } from "primereact/button";
import { type FC, type MouseEventHandler } from "react";
import styled, { css } from "styled-components";

import { IconType } from "@ds/components/icons";

export type { ButtonProps } from "primereact/button";

export enum ButtonType {
  Outlined = "p-button-outlined",
  Raised = "p-button-raised",
  Round = "p-button-rounded p-button-text",
  Text = "p-button-text",
}

export enum ButtonActionType {
  Primary = "p-button-primary",
  Secondary = "p-button-secondary",
  Error = "p-button-danger",
  Warning = "p-button-warning",
  Success = "p-button-success",
}

const ButtonMixin = css<{ err?: Error }>`
  font-size: 0.9rem;
  font-family: inherit;

  background: var(--primaryColor090);

  &:focus,
  &.p-button-secondary:enabled:focus,
  &.p-button-secondary:not(button):not(a):not(.p-disabled):focus,
  .p-buttonset.p-button-secondary > .p-button:enabled:focus,
  .p-buttonset.p-button-secondary > .p-button:not(button):not(a):not(.p-disabled):focus,
  .p-splitbutton.p-button-secondary > .p-button:enabled:focus,
  .p-splitbutton.p-button-secondary > .p-button:not(button):not(a):not(.p-disabled):focus,
  .p-fileupload-choose.p-button-secondary:enabled:focus,
  .p-fileupload-choose.p-button-secondary:not(button):not(a):not(.p-disabled):focus {
    box-shadow: var(--button-light-box-shadow);
  }

  &:enabled:hover,
  &:not(button):not(a):not(.p-disabled):hover {
    background: var(--primaryColor100);
  }

  &.p-button-text,
  &.p-button-text:enabled:hover,
  &.p-button-text:not(button):not(a):not(.p-disabled):hover,
  &.p-button-text:enabled:active,
  &.p-button-text:not(button):not(a):not(.p-disabled):active {
    color: var(--primaryColor090);
    border: none;
  }

  &.p-button-outlined,
  &.p-button-outlined:enabled:hover,
  &.p-button-outlined:not(button):not(a):not(.p-disabled):hover,
  &.p-button-outlined:enabled:active,
  &.p-button-outlined:not(button):not(a):not(.p-disabled):active {
    color: var(--primaryColor090);
    border-color: var(--primaryColor090);
  }

  &.p-button-danger:enabled:focus,
  &.p-button-danger:not(button):not(a):not(.p-disabled):focus,
  .p-buttonset.p-button-danger > .p-button:enabled:focus,
  .p-buttonset.p-button-danger > .p-button:not(button):not(a):not(.p-disabled):focus,
  .p-splitbutton.p-button-danger > .p-button:enabled:focus,
  .p-splitbutton.p-button-danger > .p-button:not(button):not(a):not(.p-disabled):focus,
  .p-fileupload-choose.p-button-danger:enabled:focus,
  .p-fileupload-choose.p-button-danger:not(button):not(a):not(.p-disabled):focus {
    box-shadow: var(--button-light-box-shadow);
  }

  &.p-button-secondary.p-button-outlined,
  &.p-buttonset.p-button-secondary > .p-button.p-button-outlined,
  &.p-splitbutton.p-button-secondary > .p-button.p-button-outlined,
  &.p-fileupload-choose.p-button-secondary.p-button-outlined {
    color: var(--textColor060);
    border-color: var(--textColor060);
  }

  &.p-button-danger.p-button-text,
  &.p-buttonset.p-button-danger > .p-button.p-button-text,
  &.p-splitbutton.p-button-danger > .p-button.p-button-text,
  &.p-fileupload-choose.p-button-danger.p-button-text {
    color: var(--red-color);
  }
`;

const StyledButton = styled(PrimeButton)<PrimeButtonProps>`
  ${ButtonMixin}
`;

export const Button: FC<{
  label?: string;
  ariaLabel?: string;
  type?: "button" | "submit";
  buttonType?: ButtonType;
  actionType?: ButtonActionType;
  icon?: IconType;
  iconPosition?: "left" | "right";
  className?: string;
  isDisabled?: boolean;
  isLoading?: boolean;
  onClick?: MouseEventHandler<HTMLButtonElement>;
  onMouseDown?: MouseEventHandler<HTMLButtonElement>;
}> = ({
  label,
  ariaLabel,
  type = "button",
  buttonType = ButtonType.Outlined,
  actionType = ButtonActionType.Primary,
  icon,
  iconPosition = "left",
  className = "",
  isDisabled = false,
  isLoading = false,
  onClick,
  onMouseDown,
}) => (
  <StyledButton
    label={label}
    aria-label={ariaLabel}
    type={type}
    icon={`pi ${icon}`}
    iconPos={iconPosition}
    className={`${className} ${buttonType} ${actionType}`}
    aria-disabled={isDisabled}
    disabled={isDisabled}
    loading={isLoading}
    onClick={onClick}
    onMouseDown={onMouseDown}
  />
);
