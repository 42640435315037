import { put, takeLatest } from "redux-saga/effects";
import { push } from "redux-first-history";

import { DEVICE_PERIPHERALS_ROUTE, DEVICE_PLAYERS_ROUTE } from "@ds/constants/router";

import { devicesActions } from "./slice";
import { DeviceTableTypeEnum } from "../utils/model";

function* switchRoute({ payload }: ReturnType<typeof devicesActions.switchView>) {
  let routeToSwitch = DEVICE_PLAYERS_ROUTE;
  switch (payload) {
    case DeviceTableTypeEnum.Players:
      routeToSwitch = DEVICE_PLAYERS_ROUTE;
      break;
    case DeviceTableTypeEnum.Peripherals:
      routeToSwitch = DEVICE_PERIPHERALS_ROUTE;
      break;
  }

  yield put(push(routeToSwitch));
}

export function* watchCommon() {
  yield takeLatest(devicesActions.switchView, switchRoute);
}
