import { isArray } from "lodash";

import { EntityTypeName, isEntity } from "@ds/model/entity-model";

import { isPlainObject } from "@ds/utils/type-guards/common-guards";

export const createPlaylistAssetModel = (options?: Partial<PlaylistAsset>): PlaylistAsset => ({
  __typename: EntityTypeName.CONTENT_PLAYLIST_ASSET,
  id: options?.id || 0,
  project_id: options?.project_id || 0,
  asset_id: options?.asset_id || 0,
  playlist_id: options?.playlist_id || 0,
  playlist_order: options?.playlist_order || 0,
});

export const isPlaylistAsset = (obj: unknown): obj is PlaylistAsset =>
  isEntity(obj) && obj.__typename === EntityTypeName.CONTENT_PLAYLIST_ASSET;

export const isApiPlaylistAsset = (obj: unknown): obj is ApiPlaylistAsset => isPlaylistAsset(obj) && "asset" in obj;

export const isPlaylistAssetArray = (obj: unknown): obj is PlaylistAsset[] =>
  isArray(obj) && obj.every(isPlaylistAsset);

export const isApiPlaylistAssetArray = (obj: unknown): obj is ApiPlaylistAsset[] =>
  isArray(obj) && obj.every(isApiPlaylistAsset);

export const isPlaylistAssetQueryOutput = (obj: unknown): obj is QueryOutput<PlaylistAsset> =>
  isPlainObject(obj) && "items" in obj && isPlaylistAssetArray(obj.items);

export const isApiPlaylistAssetQueryOutput = (obj: unknown): obj is QueryOutput<ApiPlaylistAsset> =>
  isPlainObject(obj) && "items" in obj && isApiPlaylistAssetArray(obj.items);
