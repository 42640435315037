import { isArray } from "lodash";

import { EntityTypeName, isEntity } from "@ds/model/entity-model";

import { isPlainObject } from "@ds/utils/type-guards/common-guards";

import { TenantRoleEnum } from "../../users/utils/model";

export enum InvitationStatusEnum {
  Pending = "pending",
  Expired = "expired",
}

export const createInvitationModel = ({ project_ids }: Partial<Invitation>): Invitation => ({
  __typename: EntityTypeName.SETTINGS_INVITATION,
  id: "",
  email: "",
  status: InvitationStatusEnum.Pending,
  user_role: TenantRoleEnum.TenantUser,
  project_ids: project_ids || [],
});

export const isInvitation = (obj: unknown): obj is Invitation =>
  isEntity(obj) && obj.__typename === EntityTypeName.SETTINGS_INVITATION;

export const isApiInvitation = (obj: unknown): obj is ApiInvitation => isInvitation(obj) && "projects" in obj;

export const isInvitationArray = (obj: unknown): obj is Invitation[] => isArray(obj) && obj.every(isInvitation);

export const isApiInvitationArray = (obj: unknown): obj is ApiInvitation[] =>
  isArray(obj) && obj.every(isApiInvitation);

export const isInvitationQueryOutput = (obj: unknown): obj is QueryOutput<Invitation> =>
  isPlainObject(obj) && "items" in obj && isInvitationArray(obj.items);

export const isApiInvitationQueryOutput = (obj: unknown): obj is QueryOutput<ApiInvitation> =>
  isPlainObject(obj) && "items" in obj && isApiInvitationArray(obj.items);
