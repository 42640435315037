import { autoBatchEnhancer, configureStore } from "@reduxjs/toolkit";
import { Amplify } from "aws-amplify";
import { createBrowserHistory, createMemoryHistory } from "history";
import PrimeReact from "primereact/api";
import { createReduxHistoryContext } from "redux-first-history";
import createSagaMiddleware from "redux-saga";

import { authActions } from "@ds/modules/auth/redux/slice";
import { authService } from "@ds/modules/auth/utils/api";
import { contentAssetsType } from "@ds/modules/content/assets/redux/slice";
import { playlistAssetsType } from "@ds/modules/content/playlist-assets/redux/slice";

import { createRootReducer } from "@ds/store/rootReducer";
import { rootSaga } from "@ds/store/rootSaga";

import { REACT_APP_LOG_LEVEL } from "@ds/constants/environment";
import { api, configureAxiosDefaults } from "@ds/services/ds-api";
import { logger } from "@ds/utils/logger";

export const initDefaultSettings = () => {
  PrimeReact.ripple = true;

  Amplify.Logger.LOG_LEVEL = REACT_APP_LOG_LEVEL.toUpperCase();
  logger.setLevel(REACT_APP_LOG_LEVEL);

  authService.configure({ api });
  configureAxiosDefaults();
};

export const createStoreWithMiddlewares = (preloadedState = {}, isForTestingPurpose = false) => {
  const sagaMiddleware = createSagaMiddleware();
  const { createReduxHistory, routerMiddleware, routerReducer } = createReduxHistoryContext({
    history: isForTestingPurpose ? createMemoryHistory() : createBrowserHistory(),
    reduxTravelling: true,
    savePreviousLocations: 1,
  });

  const rootStore = configureStore({
    reducer: createRootReducer(routerReducer),
    enhancers: existingEnhancers => existingEnhancers.concat(autoBatchEnhancer()),
    middleware: getDefaultMiddleware =>
      getDefaultMiddleware({
        thunk: false,
        serializableCheck: {
          ignoredActions: [
            `${contentAssetsType}/uploadContentAssets`,
            `${contentAssetsType}/uploadContentAsset`,
            `${contentAssetsType}/uploadContentAssetThumbnails`,
            `${contentAssetsType}/uploadContentAssetThumbnail`,
            `${contentAssetsType}/progressUploadingContentAssetThumbnail`,
            `${playlistAssetsType}/uploadPlaylistAssets`,
            `${playlistAssetsType}/uploadPlaylistAsset`,
          ],
        },
      })
        .prepend(routerMiddleware)
        .concat(sagaMiddleware),
    preloadedState,
  });

  sagaMiddleware.run(rootSaga);
  rootStore.dispatch(authActions.fetchAuthenticatedCognitoUser(true));

  return { rootStore, rootHistory: createReduxHistory(rootStore) };
};
