import { useCallback, type FC } from "react";
import { useNavigate } from "react-router-dom";

import { useReduxEntitiesFetch } from "@ds/hooks/use-redux-entities-fetch";

import { EntityTypeName } from "@ds/model/entity-model";

import { MainTableDataTypeEnum } from "@ds/modules/table-data/utils/model";

import { ActionsBar } from "@ds/components/actions-bar";
import { Button, ButtonActionType, ButtonType } from "@ds/components/forms/buttons";
import { IconType } from "@ds/components/icons";
import { InfoViewToggleRibbonButton } from "@ds/components/info-view/info-view-toggle-ribbon-button";
import { MenuWithButton } from "@ds/components/menu-dropdown/menu-with-button";
import { confirmSuccess, confirmWarning } from "@ds/components/popups/confirmation-dialog";
import { useDeepRootSelector, useRootDispatch, useRootSelector } from "@ds/hooks";
import { pluralizeActionMessage, pluralizeDeleteMessage } from "@ds/utils/localization/common";
import { composeDetailsRouteByEntityType } from "@ds/utils/router";

import { selectAggregatedInvitationForSelectedEntities, selectSelectedInvitationIds } from "../../redux/selectors";
import { invitationsActions } from "../../redux/slice";

export const InvitationsTableActionsMenu: FC<{ onCreate: () => void }> = ({ onCreate }) => {
  const navigate = useNavigate();

  const aggregatedInvitation = useDeepRootSelector(selectAggregatedInvitationForSelectedEntities);
  const selectedInvitationIds = useRootSelector(selectSelectedInvitationIds);

  const [toggleRefetch] = useReduxEntitiesFetch(
    { entityType: EntityTypeName.SETTINGS_INVITATION },
    { tableType: MainTableDataTypeEnum.Invitations },
  );

  const dispatch = useRootDispatch();
  const onResendActivationClickHandler = useCallback(
    () =>
      confirmSuccess({
        message: pluralizeActionMessage("invitation", selectedInvitationIds.length, "resend"),
        accept: () => dispatch(invitationsActions.resendInvitations(selectedInvitationIds)),
      }),
    [dispatch, selectedInvitationIds],
  );

  const onUpdateClickHandler = useCallback(
    () => navigate(composeDetailsRouteByEntityType(aggregatedInvitation)),
    [navigate, aggregatedInvitation],
  );

  const onDeleteClickHandler = useCallback(
    () =>
      confirmWarning({
        message: pluralizeDeleteMessage("invitation", selectedInvitationIds.length),
        accept: () => dispatch(invitationsActions.deleteInvitations(selectedInvitationIds)),
      }),
    [dispatch, selectedInvitationIds],
  );

  return (
    <ActionsBar>
      {
        <MenuWithButton
          menuItems={[
            {
              label: "Resend invitation",
              icon: "pi pi-fw pi-envelope",
              command: onResendActivationClickHandler,
              disabled: !selectedInvitationIds.length,
            },
          ]}
        />
      }

      <Button ariaLabel="create button" buttonType={ButtonType.Round} icon={IconType.Create} onClick={onCreate} />
      <Button
        ariaLabel="edit button"
        buttonType={ButtonType.Round}
        icon={IconType.Update}
        isDisabled={!selectedInvitationIds.length}
        onClick={onUpdateClickHandler}
      />

      <Button
        ariaLabel="delete button"
        buttonType={ButtonType.Round}
        actionType={ButtonActionType.Error}
        icon={IconType.Delete}
        isDisabled={!selectedInvitationIds.length}
        onClick={onDeleteClickHandler}
      />

      <Button
        ariaLabel="refresh button"
        buttonType={ButtonType.Round}
        icon={IconType.Refresh}
        onClick={toggleRefetch}
      />

      <InfoViewToggleRibbonButton />
    </ActionsBar>
  );
};
