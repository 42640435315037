import { useMemo, type FC } from "react";

import { useCurrentView } from "@ds/hooks/use-current-view";

import { EntityTypeName } from "@ds/model/entity-model";

import { MainView } from "@ds/components/main-view";
import { TableTabMenu } from "@ds/components/table/table-tab-menu";

import { MainTableDataTypeEnum } from "../../table-data/utils/model";
import { UsersTableActionsMenu } from "./components/common/users-table-actions-menu";
import { UserInfo } from "./components/user-info";
import { UsersTable } from "./components/users-table";

export const UsersMainPage: FC = () => {
  const [currentView] = useCurrentView();

  const filterOptions = useMemo(
    () => ({
      entityType: EntityTypeName.SETTINGS_USER,
      tableType: MainTableDataTypeEnum.Users,
    }),
    [],
  );

  if (!currentView) {
    return null;
  }

  return (
    <MainView
      title="Users"
      filterOptions={filterOptions}
      options={<TableTabMenu tableType={filterOptions.tableType} currentView={currentView} />}
      view={<UsersTable />}
      info={<UserInfo />}
      actions={<UsersTableActionsMenu />}
    />
  );
};
