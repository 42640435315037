import { type DeepPartial } from "react-hook-form";

import { DSApi } from "@ds/services/ds-api";
import { type ApiService, type ApiServiceConfig } from "@ds/services/types";
import { parseQueries } from "@ds/utils/query";

interface LocationsServiceConfig extends ApiServiceConfig {
  api: DSApi;
}

class LocationsService implements ApiService {
  private config: LocationsServiceConfig = {
    api: {} as DSApi,
  };

  configure(cfg: LocationsServiceConfig) {
    this.config = { ...cfg };
  }

  async getLocations(queryInfo: QueryInfo, pagination: Pagination) {
    const queries = parseQueries(queryInfo, pagination);
    return this.config.api.get<QueryOutput<Locality>>("/locations/", queries);
  }

  async getLocation(locationId: number) {
    return this.config.api.get<Locality>(`/locations/${locationId}`);
  }

  async createLocation(location: Locality) {
    return this.config.api.post<Locality>("/locations/", location);
  }

  async updateLocations(ids: number[], data: DeepPartial<Locality>) {
    return this.config.api.patch<Locality>("/locations/", { ids, data });
  }

  async deleteLocations(ids: number[]) {
    return this.config.api.delete<Locality>("/locations/", { ids });
  }
}

export const locationsService = new LocationsService();
