import { isArray } from "lodash";

import { EntityTypeName, isEntity } from "@ds/model/entity-model";

import { isPlainObject } from "@ds/utils/type-guards/common-guards";

import { assetMediaTypes } from "../../utils/constants";

export const createPlaylistModel = (options?: Partial<Playlist>): Playlist => ({
  __typename: EntityTypeName.CONTENT_PLAYLIST,
  id: options?.id || 0,
  project_id: options?.project_id || 0,
  name: options?.name || "",
  description: options?.description || "",
  media_types: Object.keys(assetMediaTypes.video)
    .concat(Object.keys(assetMediaTypes.image))
    .concat(Object.keys(assetMediaTypes.audio)),
  total_duration: 0,
  playlist_items_count: 0,
});

export const isPlaylist = (obj: unknown): obj is Playlist => {
  return isEntity(obj) && obj.__typename === EntityTypeName.CONTENT_PLAYLIST;
};

export const isPlaylistArray = (obj: unknown): obj is Playlist[] => isArray(obj) && obj.every(isPlaylist);

export const isPlaylistQueryOutput = (obj: unknown): obj is QueryOutput<Playlist> =>
  isPlainObject(obj) && "items" in obj && isPlaylistArray(obj.items);
