import { useCallback, type FC } from "react";
import { type Path } from "react-hook-form";

import { isExperience } from "@ds/modules/experiences/utils/model";

import { AggregatedValue } from "@ds/components/entities";
import { YesNoCheckbox } from "@ds/components/forms/inputs";
import { getNormalAndProxyFieldsMap, isMultipleFieldValuesInAggregatedEntity } from "@ds/utils/entities";
import { getProperty } from "@ds/utils/properties";

export const FormationBooleanDisplayValue: FC<{
  entity: Experience | Project;
  field: string;
  isDirty?: boolean;
}> = ({ entity, field, isDirty = false }) => {
  const fieldsMap = isExperience(entity)
    ? getNormalAndProxyFieldsMap(entity, `formation.properties.${field}.value`)
    : getNormalAndProxyFieldsMap(entity, `template.experience_formation.properties.${field}.value`);

  const fieldPath = isMultipleFieldValuesInAggregatedEntity(entity, fieldsMap.normal.path)
    ? `${fieldsMap.proxy.path}`
    : `${fieldsMap.normal.path}`;

  const getCheckboxElement = useCallback(
    (checkboxEntity: Experience | Project, checkboxField: Path<Experience | Project>) => (
      <YesNoCheckbox isChecked={getProperty(checkboxEntity, checkboxField)} />
    ),
    [],
  );

  return (
    <AggregatedValue
      entity={entity}
      field={fieldPath as Path<Experience | Project>}
      shouldShowEditedValue={isDirty}
      formatter={getCheckboxElement}
      multipleFormatter={getCheckboxElement}
    />
  );
};
