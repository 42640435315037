import { useMemo, type FC } from "react";
import { Controller, type Control } from "react-hook-form";

import { DeploymentTypeEnum } from "@ds/modules/deployments/utils/model";

import { SpanWrapper } from "@ds/components/forms/labels/span-wrapper";
import {
  SelectButtonsList,
  type SelectButtonElementOptions,
} from "@ds/components/select-buttons-list/select-buttons-list";

export const DeploymentTypeControl: FC<{ control: Control<Deployment> }> = ({ control }) => {
  const deploymentTypeOptions: SelectButtonElementOptions[] = useMemo(
    () => [
      {
        icon: "video",
        label: "Content",
        value: DeploymentTypeEnum.Content,
        infoText: `Deploy project configurations, playlists and assets to the players`,
      },
      {
        icon: "file",
        label: "Code",
        value: DeploymentTypeEnum.Code,
        infoText: `Update players' software to the latest available app version`,
      },
      {
        icon: "cog",
        label: "Firmware",
        value: DeploymentTypeEnum.Firmware,
        infoText: `Upgrade players' firmware provided by the manufacturer`,
      },
    ],
    [],
  );

  return (
    <Controller
      name="type"
      control={control}
      render={({ field: { value, onChange }, fieldState }) => (
        <SpanWrapper className="col-8 mt-6" error={fieldState.error}>
          <SelectButtonsList items={deploymentTypeOptions} selected={value} onChange={onChange} />
        </SpanWrapper>
      )}
    />
  );
};
