import { useCallback, type FC } from "react";
import { useNavigate } from "react-router-dom";

import { useReduxEntitiesFetch } from "@ds/hooks/use-redux-entities-fetch";

import { EntityTypeName, isAggregatedEntity } from "@ds/model/entity-model";

import { deploymentsActions, DeploymentsRemoteOperation } from "@ds/modules/deployments/redux/slice";
import { createDeploymentModel, DeploymentScopeEnum } from "@ds/modules/deployments/utils/model";
import { MainTableDataTypeEnum } from "@ds/modules/table-data/utils/model";

import { ActionsBar } from "@ds/components/actions-bar";
import { Button, ButtonActionType, ButtonType } from "@ds/components/forms/buttons";
import { IconType } from "@ds/components/icons";
import { InfoViewToggleRibbonButton } from "@ds/components/info-view/info-view-toggle-ribbon-button";
import { MenuWithButton } from "@ds/components/menu-dropdown/menu-with-button";
import { confirmSuccess, confirmWarning } from "@ds/components/popups/confirmation-dialog";
import { useDeepRootSelector, useRootDispatch, useRootSelector } from "@ds/hooks";
import { pluralizeDeleteMessage } from "@ds/utils/localization/common";
import {
  pluralizeDeployDescription,
  pluralizeDeployMessage,
  pluralizeDeployName,
} from "@ds/utils/localization/deployment";
import { composeDetailsRouteByEntityType } from "@ds/utils/router";

import {
  cecAllOffCommand,
  cecAllOnCommand,
  createCommandMenuItem,
  logsCommandMenuItem,
  rebootCommandMenuItem,
  resetContentMenuItem,
} from "../../constants/actions";
import {
  areSelectedPlayersHaveTheSameAppletType,
  selectAggregatedPlayerForSelectedEntities,
  selectSelectedPlayerIds,
  selectSelectedPlayers,
} from "../../redux/selectors";
import { playersActions } from "../../redux/slice";
import { createCommandModel } from "../../utils/command-model";
import { PlayerStateEnum } from "../../utils/model";

export const PlayersTableActionsMenu: FC<{
  onCreate: () => void;
  onPair: () => void;
}> = ({ onCreate, onPair }) => {
  const navigate = useNavigate();

  const aggregatedPlayer = useDeepRootSelector(selectAggregatedPlayerForSelectedEntities);
  const selectedPlayerIds = useRootSelector(selectSelectedPlayerIds);
  const selectedPlayers = useRootSelector(selectSelectedPlayers);
  const canShowAppletCommands = useRootSelector(areSelectedPlayersHaveTheSameAppletType);

  const [toggleRefetch] = useReduxEntitiesFetch(
    { entityType: EntityTypeName.DEVICE_PLAYER },
    { tableType: MainTableDataTypeEnum.DevicePlayers },
  );

  const dispatch = useRootDispatch();
  const onUpdateClickHandler = useCallback(
    () => navigate(composeDetailsRouteByEntityType(aggregatedPlayer)),
    [navigate, aggregatedPlayer],
  );

  const onDeleteClickHandler = useCallback(
    () =>
      confirmWarning({
        message: pluralizeDeleteMessage("player", selectedPlayerIds.length),
        accept: () => dispatch(playersActions.deletePlayers(selectedPlayerIds)),
      }),
    [dispatch, selectedPlayerIds],
  );

  const onDeployClickHandler = useCallback(
    () =>
      confirmSuccess({
        message: pluralizeDeployMessage("player", selectedPlayerIds.length),
        accept: () =>
          dispatch(
            deploymentsActions.createDeployment(
              {
                ...createDeploymentModel(),
                name: pluralizeDeployName(selectedPlayers),
                description: pluralizeDeployDescription(selectedPlayers),
                scope: DeploymentScopeEnum.Device,
                targets: selectedPlayerIds,
              },
              { remoteOperation: DeploymentsRemoteOperation.DEPLOYING },
            ),
          ),
      }),
    [dispatch, selectedPlayers, selectedPlayerIds],
  );

  const onCommandSelectHandler = useCallback(
    (options: SendCommand) => {
      dispatch(playersActions.stopFetchingScreenshots());
      dispatch(
        playersActions.sendCommandsToPlayers(
          selectedPlayerIds.map(id => createCommandModel({ ...options, device_id: id })),
        ),
      );
    },
    [dispatch, selectedPlayerIds],
  );

  const isNotOnline = selectedPlayers.some(({ state }) => state !== PlayerStateEnum.Online);
  return (
    <ActionsBar>
      <MenuWithButton
        menuItems={[
          {
            label: "Pair Device",
            icon: "pi pi-fw pi-sort-alt",
            disabled: isAggregatedEntity(aggregatedPlayer) || aggregatedPlayer?.state !== PlayerStateEnum.Unpaired,
            command: onPair,
          },
          {
            label: "Deploy Content",
            icon: "pi pi-fw pi-sitemap",
            disabled: selectedPlayers.some(({ state }) => state === PlayerStateEnum.Unpaired),
            command: onDeployClickHandler,
          },
          { separator: true },
          rebootCommandMenuItem(onCommandSelectHandler, isNotOnline, selectedPlayers.length),
          logsCommandMenuItem(onCommandSelectHandler, isNotOnline, selectedPlayers.length),
          resetContentMenuItem(onCommandSelectHandler, isNotOnline, selectedPlayers.length),
          { separator: true },
          createCommandMenuItem(
            "cec",
            "CEC On",
            "pi pi-fw pi-window-maximize",
            cecAllOnCommand,
            onCommandSelectHandler,
            !canShowAppletCommands || isNotOnline,
            selectedPlayers.length,
          ),
          createCommandMenuItem(
            "cec",
            "CEC Off",
            "pi pi-fw pi-window-minimize",
            cecAllOffCommand,
            onCommandSelectHandler,
            !canShowAppletCommands || isNotOnline,
            selectedPlayers.length,
          ),
        ]}
        isDisabled={!selectedPlayers.length}
      />

      <Button ariaLabel="create button" buttonType={ButtonType.Round} icon={IconType.Create} onClick={onCreate} />
      <Button
        ariaLabel="edit button"
        icon={IconType.Update}
        buttonType={ButtonType.Round}
        isDisabled={!selectedPlayers.length}
        onClick={onUpdateClickHandler}
      />

      <Button
        ariaLabel="delete button"
        icon={IconType.Delete}
        buttonType={ButtonType.Round}
        actionType={ButtonActionType.Error}
        isDisabled={!selectedPlayers.length}
        onClick={onDeleteClickHandler}
      />

      <Button
        ariaLabel="refresh button"
        buttonType={ButtonType.Round}
        icon={IconType.Refresh}
        onClick={toggleRefetch}
      />

      <InfoViewToggleRibbonButton />
    </ActionsBar>
  );
};
