import styled from "styled-components";

export const WizardFinalStepWrapper = styled.div`
  width: 500px;
  height: 100%;
  margin: auto;

  > div {
    justify-content: center;
    height: 100%;
  }
`;
