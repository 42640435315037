import { type FC } from "react";

import { isExperience } from "@ds/modules/experiences/utils/model";

import { AggregatedValue } from "@ds/components/entities";

export const FormationStringDisplayValue: FC<{
  entity: Experience | Project;
  field: string;
  isDirty?: boolean;
}> = ({ entity, field, isDirty = false }) => (
  <AggregatedValue
    entity={entity}
    field={
      isExperience(entity)
        ? `formation.properties.${field}.value`
        : `template.experience_formation.properties.${field}.value`
    }
    shouldShowEditedValue={isDirty}
  />
);
