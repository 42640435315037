import { all } from "redux-saga/effects";
import { createSlice } from "@reduxjs/toolkit";

import { authActions } from "@ds/modules/auth/redux/slice";

import { watchApp } from "./sagas";

interface AppState {
  appIsLoaded: boolean;
}

export const initialState: AppState = {
  appIsLoaded: false,
};

const sliceType = "APP";
const slice = createSlice({
  name: sliceType,
  initialState,
  reducers: {
    reset: () => initialState,
  },
  extraReducers: builder => {
    builder
      .addCase(authActions.fetchAuthenticatedCognitoUserFailed, state => {
        state.appIsLoaded = true;
      })
      .addCase(authActions.fetchAuthenticatedCognitoUserSucceeded, state => {
        state.appIsLoaded = true;
      });
  },
});

export const { name: applicationType, actions: appActions, reducer: appReducer } = slice;

export function* rootAppSaga() {
  yield all([watchApp()]);
}
