import { yupResolver } from "@hookform/resolvers/yup";
import { type FC } from "react";
import { type DeepPartial } from "react-hook-form";

import { updateValidationSchema } from "@ds/modules/settings/projects/utils/validation-schema";

import {
  DetailsCard,
  DetailsCardEditActionsMenu,
  EditableDetailsCardDecorator,
  EditableDetailsCardRow,
  EditableDetailsCardRowDecorator,
} from "@ds/components/details-view";
import { BooleanControl } from "@ds/components/forms/control/boolean-control";

export const ProjectDetailsSettingsCard: FC<{
  project: Project;
  isEditForbidden: boolean;
  onSubmit: (changedFields: DeepPartial<Project>) => void;
}> = ({ project, isEditForbidden, onSubmit }) => (
  <EditableDetailsCardDecorator
    initialValue={project}
    validationResolver={yupResolver(updateValidationSchema.settings)}
    onSubmit={onSubmit}
  >
    {cardProps => (
      <DetailsCard
        title="Settings"
        icon="cog"
        actions={
          <DetailsCardEditActionsMenu
            isEditing={cardProps.isEditing}
            isDirty={cardProps.isDirty}
            onReset={cardProps.onResetForm}
            onSubmit={cardProps.handleSubmit(cardProps.onSubmitForm)}
          />
        }
      >
        <EditableDetailsCardRowDecorator
          field="is_active"
          input={
            <BooleanControl
              name="is_active"
              control={cardProps.control}
              setValue={cardProps.setValue}
              labelTitle="Active"
              autoFocus
              excludeLabel
            />
          }
          shouldHideEdit={isEditForbidden}
          {...cardProps}
        >
          {rowProps => <EditableDetailsCardRow label="Active" editableSettings={rowProps} />}
        </EditableDetailsCardRowDecorator>

        <EditableDetailsCardRowDecorator
          field="is_auto_pairing_enabled"
          input={
            <BooleanControl
              name="is_auto_pairing_enabled"
              control={cardProps.control}
              setValue={cardProps.setValue}
              labelTitle="Auto-pairing"
              autoFocus
              excludeLabel
            />
          }
          shouldHideEdit={isEditForbidden}
          {...cardProps}
        >
          {rowProps => <EditableDetailsCardRow label="Auto-pairing" editableSettings={rowProps} />}
        </EditableDetailsCardRowDecorator>

        <EditableDetailsCardRowDecorator
          field="is_notifications_enabled"
          input={
            <BooleanControl
              name="is_notifications_enabled"
              control={cardProps.control}
              setValue={cardProps.setValue}
              labelTitle="Email notifications"
              autoFocus
              excludeLabel
            />
          }
          shouldHideEdit={isEditForbidden}
          {...cardProps}
        >
          {rowProps => <EditableDetailsCardRow label="Email notifications" editableSettings={rowProps} />}
        </EditableDetailsCardRowDecorator>
      </DetailsCard>
    )}
  </EditableDetailsCardDecorator>
);
