import { createEntityAdapter, createSlice, type PayloadAction } from "@reduxjs/toolkit";
import { all } from "redux-saga/effects";

import { DetailsTableDataTypeEnum, MainTableDataTypeEnum } from "@ds/modules/table-data/utils/model";

import { getIds } from "@ds/utils/entities";
import { LoadStatus, type BaseMeta } from "@ds/utils/reducer";
import { type SagaOptions } from "@ds/utils/saga-helpers";

import { devicesActions } from "../../redux/slice";
import { apiToModelNormalize, normalize } from "../utils/normalizer";
import { watchPeripherals } from "./sagas";

export enum DevicePeripheralsRemoteOperation {
  CRUD = "crud",
  CHECKBOXES = "checkboxes",
  IOT_FETCH = "iot_fetch",
}

export type DevicePeripheralsMeta = BaseMeta & {
  remoteOperation: DevicePeripheralsRemoteOperation;
  filters?: KeysToCamelCase<DevicePeripheralFilters>;
};

interface DevicePeripheralsState {
  loadStatusMap: {
    [DevicePeripheralsRemoteOperation.CRUD]: LoadStatus;
    [DevicePeripheralsRemoteOperation.CHECKBOXES]: LoadStatus;
    [DevicePeripheralsRemoteOperation.IOT_FETCH]: LoadStatus;
  };

  error: string | null;

  entities: {
    [peripheralId: number]: Peripheral;
  };

  ids: number[];
  tableIds: Record<string, number[]>;

  selectedIds: Record<string, number[]>;
}

const entityAdapter = createEntityAdapter<Peripheral>();

export const initialState: DevicePeripheralsState = entityAdapter.getInitialState({
  loadStatusMap: {
    [DevicePeripheralsRemoteOperation.CRUD]: LoadStatus.Idle,
    [DevicePeripheralsRemoteOperation.CHECKBOXES]: LoadStatus.Idle,
    [DevicePeripheralsRemoteOperation.IOT_FETCH]: LoadStatus.Idle,
  },

  error: null,

  tableIds: { [MainTableDataTypeEnum.DevicePeripherals]: [] },
  selectedIds: { [MainTableDataTypeEnum.DevicePeripherals]: [] },
}) as DevicePeripheralsState;

const getTableTypeOrDefault = (
  tableType: MainTableDataTypeEnum | DetailsTableDataTypeEnum = MainTableDataTypeEnum.DevicePeripherals,
) => tableType;

const removeEntities = (state: DevicePeripheralsState, payload: number[]) => {
  entityAdapter.removeMany(state, payload);

  state.tableIds[MainTableDataTypeEnum.DevicePeripherals] = state.tableIds[
    MainTableDataTypeEnum.DevicePeripherals
  ].filter(id => !payload.includes(id));

  state.selectedIds[MainTableDataTypeEnum.DevicePeripherals] = state.selectedIds[
    MainTableDataTypeEnum.DevicePeripherals
  ].filter(id => !payload.includes(id));
};

const sliceType = "DEVICE_PERIPHERALS";
const slice = createSlice({
  name: sliceType,
  initialState,
  reducers: {
    fetchPeripherals: {
      reducer(state, { meta }: PayloadAction<undefined, string, DevicePeripheralsMeta, never>) {
        state.loadStatusMap[meta.remoteOperation] = LoadStatus.Loading;
        state.error = null;

        if (meta.remoteOperation === DevicePeripheralsRemoteOperation.CHECKBOXES) {
          state.selectedIds[getTableTypeOrDefault(meta.options?.tableType)] =
            state.tableIds[getTableTypeOrDefault(meta.options?.tableType)];
        }
      },
      prepare(
        filters: KeysToCamelCase<DevicePeripheralFilters> = {},
        options: SagaOptions = {},
        remoteOperation: DevicePeripheralsRemoteOperation = DevicePeripheralsRemoteOperation.CRUD,
      ) {
        return {
          payload: undefined,
          meta: { remoteOperation, filters, options },
        };
      },
    },
    fetchPeripheralsFailed: {
      reducer(state, { meta, error }: PayloadAction<undefined, string, DevicePeripheralsMeta, string>) {
        state.loadStatusMap[meta.remoteOperation] = LoadStatus.Failed;
        state.error = error;

        if (meta.remoteOperation === DevicePeripheralsRemoteOperation.CHECKBOXES) {
          state.selectedIds[getTableTypeOrDefault(meta.options?.tableType)] = [];
        }
      },
      prepare(meta: DevicePeripheralsMeta, { message }: ErrorLike) {
        return { payload: undefined, meta, error: message };
      },
    },
    fetchPeripheralsSucceeded: {
      reducer(
        state,
        {
          payload,
          meta,
        }: PayloadAction<QueryOutput<Peripheral> | QueryOutput<ApiPeripheral>, string, DevicePeripheralsMeta>,
      ) {
        state.loadStatusMap[meta.remoteOperation] = LoadStatus.Succeeded;
        state.error = null;

        entityAdapter.setMany(state, apiToModelNormalize(payload.items));

        if (meta.remoteOperation === DevicePeripheralsRemoteOperation.CHECKBOXES) {
          state.selectedIds[getTableTypeOrDefault(meta.options?.tableType)] = payload.items.map(({ id }) => id);
        } else if (meta.options?.tableType) {
          state.tableIds[getTableTypeOrDefault(meta.options?.tableType)] = payload.items.map(({ id }) => id);
          state.selectedIds[getTableTypeOrDefault(meta.options?.tableType)] = state.selectedIds[
            getTableTypeOrDefault(meta.options?.tableType)
          ].filter(id => state.ids.includes(id));
        }
      },
      prepare(payload: QueryOutput<Peripheral> | QueryOutput<ApiPeripheral>, meta: DevicePeripheralsMeta) {
        return { payload, meta };
      },
    },
    fetchPeripheral: {
      reducer(state, { meta }: PayloadAction<number, string, DevicePeripheralsMeta>) {
        state.loadStatusMap[meta.remoteOperation] = LoadStatus.Loading;
        state.error = null;
      },
      prepare(payload: number, options: SagaOptions = {}) {
        return { payload, meta: { remoteOperation: DevicePeripheralsRemoteOperation.CRUD, options } };
      },
    },
    fetchPeripheralFailed: {
      reducer(state, { meta, error }: PayloadAction<number, string, DevicePeripheralsMeta, string>) {
        state.loadStatusMap[meta.remoteOperation] = LoadStatus.Failed;
        state.error = error;
      },
      prepare(payload: number, meta: DevicePeripheralsMeta, { message }: ErrorLike) {
        return { payload, meta, error: message };
      },
    },
    fetchPeripheralSucceeded: {
      reducer(state, { payload, meta }: PayloadAction<Peripheral | ApiPeripheral, string, DevicePeripheralsMeta>) {
        state.loadStatusMap[meta.remoteOperation] = LoadStatus.Succeeded;
        state.error = null;

        entityAdapter.setOne(state, apiToModelNormalize(payload));
      },
      prepare(payload: Peripheral | ApiPeripheral, meta: DevicePeripheralsMeta) {
        return { payload, meta };
      },
    },
    selectPeripherals: {
      reducer(state, { payload, meta }: PayloadAction<number[], string, DevicePeripheralsMeta>) {
        state.loadStatusMap[meta.remoteOperation] = LoadStatus.Succeeded;

        state.selectedIds[getTableTypeOrDefault(meta.options?.tableType)] = payload;
      },
      prepare(peripheralsOrIds: Peripheral[] | ApiPeripheral[] | number[], options: SagaOptions = {}) {
        return {
          payload: getIds(peripheralsOrIds),
          meta: { remoteOperation: DevicePeripheralsRemoteOperation.CHECKBOXES, options },
        };
      },
    },
    toggleSelected: {
      reducer(state, { payload, meta }: PayloadAction<number, string, DevicePeripheralsMeta>) {
        state.loadStatusMap[meta.remoteOperation] = LoadStatus.Succeeded;

        state.selectedIds[getTableTypeOrDefault(meta.options?.tableType)] = state.selectedIds[
          getTableTypeOrDefault(meta.options?.tableType)
        ].includes(payload)
          ? state.selectedIds[getTableTypeOrDefault(meta.options?.tableType)].filter(id => id !== payload)
          : [...state.selectedIds[getTableTypeOrDefault(meta.options?.tableType)], payload];
      },
      prepare(peripheralId: number, options: SagaOptions = {}) {
        return {
          payload: peripheralId,
          meta: { remoteOperation: DevicePeripheralsRemoteOperation.CHECKBOXES, options },
        };
      },
    },
    iotUpdate: (state, { payload }: PayloadAction<ApiPeripheral>) => {
      if (payload.audit?.deleted_at) {
        removeEntities(state, [payload.id]);
      } else {
        entityAdapter.setOne(state, apiToModelNormalize(normalize(payload)));
      }
    },
  },
  extraReducers: builder => {
    builder.addCase(devicesActions.switchView, state => {
      state.loadStatusMap[DevicePeripheralsRemoteOperation.CRUD] = LoadStatus.Idle;
      state.error = null;
    });
  },
});

export const { name: peripheralsType, actions: peripheralsActions, reducer: peripheralsReducer } = slice;

export const entitySelectors = entityAdapter.getSelectors();

export function* rootPeripheralsSaga() {
  yield all([watchPeripherals()]);
}
