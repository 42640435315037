import { yupResolver } from "@hookform/resolvers/yup";
import { noop } from "lodash";
import { useCallback, useEffect, useRef, useState, type FC } from "react";
import { useForm } from "react-hook-form";
import { useNavigate } from "react-router-dom";
import styled from "styled-components";

import { useRootDispatch, useRootSelector } from "@ds/hooks/redux-hooks";
import { useTypedLocation } from "@ds/hooks/use-typed-location";

import { Button, ButtonType } from "@ds/components/forms/buttons";
import { IconType } from "@ds/components/icons";
import { SpinnerIcon } from "@ds/components/loaders/loaders";
import { Dialog } from "@ds/components/modals";
import { NavTextLink } from "@ds/components/text-link";
import { RESTORE_PASSWORD_ROUTE, SIGN_IN_ROUTE } from "@ds/constants/router";

import { RESTORE_CODE_SENDED } from "../../constants/messages";
import { selectAuthIsLoading } from "../../redux/selectors";
import { authActions } from "../../redux/slice";
import { type UserForgotPasswordModel } from "../../redux/types";
import { forgotPasswordValidationSchema } from "../../utils/validation-schema";
import { UsernameControl } from "./controls/username-signin-control";

const Form = styled.form`
  width: 400px;
  padding: 0 30px;
`;

const MessageWrapper = styled.div`
  display: flex;
  flex-flow: column;
  align-items: center;
  text-align: center;
`;

enum RedirectState {
  INITIAL = "INITIAL",
  REDIRECTING = "REDIRECTING",
  REDIRECTED = "REDIRECTED",
}

const REDIRECT_TIMEOUT = 5000;

export const UserForgotPassword: FC = () => {
  const { state: routeState } = useTypedLocation<{
    username: string;
    isAuthorized: boolean;
  }>();

  const { control, watch, getValues, handleSubmit } = useForm<UserForgotPasswordModel>({
    mode: "all",
    reValidateMode: "onChange",
    defaultValues: {
      username: routeState?.username || "",
    },
    resolver: yupResolver(forgotPasswordValidationSchema),
  });

  const [redirect, setRedirect] = useState(RedirectState.INITIAL);
  const isLoading = useRootSelector(selectAuthIsLoading);

  const dispatch = useRootDispatch();
  const onSubmitHandler = useCallback(() => dispatch(authActions.forgotPassword(getValues())), [dispatch, getValues]);

  const navigate = useNavigate();
  const timeout = useRef<NodeJS.Timeout>();
  useEffect(() => {
    if (redirect === RedirectState.REDIRECTING) {
      setRedirect(RedirectState.REDIRECTED);
      timeout.current = setTimeout(() => {
        navigate(RESTORE_PASSWORD_ROUTE, { state: getValues(), replace: true });
      }, REDIRECT_TIMEOUT);
    }
  }, [dispatch, navigate, redirect, getValues]);

  useEffect(
    () => () => {
      if (timeout.current) {
        clearTimeout(timeout.current);
      }
    },
    [],
  );

  useEffect(() => {
    if (!isLoading && routeState?.isAuthorized) {
      setRedirect(RedirectState.REDIRECTING);
    }
  }, [routeState?.isAuthorized, isLoading]);

  const currentUsername = watch("username");
  const renderContent = useCallback(() => {
    if (redirect === RedirectState.REDIRECTED) {
      return (
        <MessageWrapper>
          <p>{RESTORE_CODE_SENDED}</p>
          <p>Your will be redirected to the next page in few seconds...</p>
          <p>
            <SpinnerIcon />
          </p>
        </MessageWrapper>
      );
    }

    return (
      <>
        <div className="grid m-0">
          <div className="col">
            <NavTextLink
              to={{
                pathname: SIGN_IN_ROUTE,
              }}
              state={{ username: currentUsername }}
            >
              Back to Sign-In
            </NavTextLink>
          </div>

          <Button
            label="Send code"
            type="submit"
            buttonType={ButtonType.Raised}
            icon={IconType.Right}
            iconPosition="right"
            isLoading={isLoading}
          />
        </div>
      </>
    );
  }, [currentUsername, isLoading, redirect]);

  return (
    <Dialog modal visible showHeader={false} onHide={noop}>
      <Form onSubmit={handleSubmit(onSubmitHandler)} noValidate>
        <h2 className="text-center">Forgot password</h2>
        <UsernameControl control={control} autoComplete="email" isReadOnly={isLoading || routeState?.isAuthorized} />
        {renderContent()}
      </Form>
    </Dialog>
  );
};
