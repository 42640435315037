import { cloneDeep } from "lodash";
import { replace } from "redux-first-history";
import { channel, type Channel } from "redux-saga";
import {
  all,
  call,
  put,
  race,
  select,
  take,
  takeEvery,
  takeLatest,
  takeLeading,
  type SagaReturnType,
} from "redux-saga/effects";

import { createContentAssetModel } from "@ds/modules/content/assets/utils/model";
import { iotEventAssetUpdate } from "@ds/modules/iot/redux/actions";
import { toastShowErrorAction, toastShowSuccessAction } from "@ds/modules/notifications/redux/actions";
import { isIotTableEntityAddedOrDeleted, selectTableData } from "@ds/modules/table-data/redux/selectors";
import { convertToApiQueryInfo } from "@ds/modules/table-data/utils/common";

import { storageService, type ProgressInfo } from "@ds/services/storage";
import { UnexpectedError } from "@ds/utils/errors";
import { logger } from "@ds/utils/logger";
import { UNLIMITED_PAGINATION } from "@ds/utils/query";
import { takeLatestOrEvery } from "@ds/utils/saga-helpers";
import { isErrorLike } from "@ds/utils/type-guards/error-guards";

import { contentAssetsService } from "../../utils/content-assets-api";
import {
  createContentAssetFiltersByTableType,
  getMainTableTypeByApiContentType,
  getMainTableTypeByContentType,
} from "../../utils/helpers";
import { normalize } from "../../utils/normalizer";
import { selectContentAssetById, selectTableContentAssets } from "../selectors/common-selectors";
import { contentAssetsActions, ContentAssetsRemoteOperation, type ContentAssetsProgressChannelEvent } from "../slice";

function* fetchContentAssets({ meta }: ReturnType<typeof contentAssetsActions.fetchContentAssets>) {
  try {
    const { sorting, pagination, queryInfo, isFetchedAlready } = selectTableData(
      yield select(),
      meta.options.tableType || getMainTableTypeByApiContentType(meta.filters?.contentType),
      undefined,
      meta.filters,
    );

    const newMeta = cloneDeep(meta);
    const resultFromCache: QueryOutput<ContentAsset> = { items: [] };

    if (isFetchedAlready && newMeta.options.tableType && !newMeta.options.cache?.disableCache) {
      resultFromCache.items = selectTableContentAssets(yield select(), newMeta.options.tableType);
      newMeta.options.cache = {
        ...newMeta.options.cache,
        fetchedFromCache: true,
      };
    }

    let result: QueryOutput<ContentAsset> = newMeta.options.cache?.fetchedFromCache
      ? resultFromCache
      : yield call(
          [contentAssetsService, contentAssetsService.getAssets],
          convertToApiQueryInfo(sorting, queryInfo),
          newMeta.options.tableType ? pagination : UNLIMITED_PAGINATION,
        );

    result = newMeta.options.cache?.fetchedFromCache ? result : normalize(result);
    result = newMeta.options.tableType ? result : { items: result.items };

    yield put(contentAssetsActions.fetchContentAssetsSucceeded(result, newMeta));
  } catch (err) {
    const errorTitle = "Fetch content assets";
    if (isErrorLike(err)) {
      yield put(contentAssetsActions.fetchContentAssetsFailed(meta, err));
      yield put(toastShowErrorAction(err, errorTitle));
    } else {
      logger.error(`${errorTitle}: ${UnexpectedError}`);
      yield put(toastShowErrorAction(UnexpectedError, errorTitle));
    }
  }
}

function* fetchContentAsset({ payload, meta }: ReturnType<typeof contentAssetsActions.fetchContentAsset>) {
  try {
    const newMeta = cloneDeep(meta);
    let result = selectContentAssetById(yield select(), payload);
    if (result && !newMeta.options.cache?.disableCache) {
      newMeta.options.cache = {
        ...newMeta.options.cache,
        fetchedFromCache: true,
      };
    } else {
      result = yield call([contentAssetsService, contentAssetsService.getAsset], payload);
      result = normalize(result);
    }

    yield put(contentAssetsActions.fetchContentAssetSucceeded(result, newMeta));
  } catch (err) {
    const errorTitle = "Fetch content asset";
    if (isErrorLike(err)) {
      yield put(contentAssetsActions.fetchContentAssetFailed(payload, meta, err));
      yield put(toastShowErrorAction(err, errorTitle));
    } else {
      logger.error(`${errorTitle}: ${UnexpectedError}`);
      yield put(toastShowErrorAction(UnexpectedError, errorTitle));
    }
  }
}

function* deleteContentAssets({ payload, meta }: ReturnType<typeof contentAssetsActions.deleteContentAssets>) {
  try {
    yield call([contentAssetsService, contentAssetsService.deleteAssets], payload);
    yield put(contentAssetsActions.deleteContentAssetsSucceeded(payload, meta));
    yield put(toastShowSuccessAction("Content asset(s) were deleted successfully"));

    if (meta.options?.redirectTo) {
      yield put(replace(meta.options.redirectTo));
    }
  } catch (err) {
    const errorTitle = "Delete content asset(s)";
    if (isErrorLike(err)) {
      yield put(contentAssetsActions.deleteContentAssetsFailed(payload, meta, err));
      yield put(toastShowErrorAction(err, errorTitle));
    } else {
      logger.error(`${errorTitle}: ${UnexpectedError}`);
      yield put(toastShowErrorAction(UnexpectedError, errorTitle));
    }
  }
}

export function* createContentAsset(progressChannel: Channel<ContentAssetsProgressChannelEvent>, file: File) {
  const { sorting, queryInfo } = selectTableData(yield select(), getMainTableTypeByContentType(file.type), undefined, {
    name: file.name,
  });

  const existingAssets: SagaReturnType<typeof contentAssetsService.getAssets> = yield call(
    [contentAssetsService, contentAssetsService.getAssets],
    convertToApiQueryInfo(sorting, queryInfo),
    UNLIMITED_PAGINATION,
  );

  if (existingAssets.items.length) {
    throw new Error(`Content asset with name ${file.name} already exists`);
  }

  const uniqueKey = `assets/${new Date().getTime()}_${file.name}`;
  const { key }: SagaReturnType<typeof storageService.put> = yield call(
    [storageService, storageService.put],
    uniqueKey,
    file,
    {
      level: "public",
      contentType: file.type,
      progressCallback: (progressInfo: ProgressInfo) => {
        progressChannel.put({ progressInfo: { ...progressInfo, name: file.name } });
      },
    },
  );

  const result: SagaReturnType<typeof contentAssetsService.createAsset> = yield call(
    [contentAssetsService, contentAssetsService.createAsset],
    createContentAssetModel({
      key,
      name: file.name,
      file_name: file.name,
      file_size: file.size,
      content_type: file.type,
    }),
  );

  return result;
}

function* uploadContentAsset(
  progressChannel: Channel<ContentAssetsProgressChannelEvent>,
  { payload, meta }: ReturnType<typeof contentAssetsActions.uploadContentAsset>,
) {
  try {
    const result: SagaReturnType<typeof createContentAsset> = yield call(createContentAsset, progressChannel, payload);

    yield put(contentAssetsActions.uploadContentAssetSucceeded(normalize(result), meta));
    yield put(toastShowSuccessAction("Content asset was successfully uploaded"));
  } catch (err) {
    const errorTitle = "Upload content asset";
    if (isErrorLike(err)) {
      yield put(contentAssetsActions.uploadContentAssetFailed(payload, meta, err));
      yield put(toastShowErrorAction(err, errorTitle));
    } else {
      logger.error(`${errorTitle}: ${UnexpectedError}`);
      yield put(toastShowErrorAction(UnexpectedError, errorTitle));
    }
  }
}

function* uploadContentAssets(
  progressChannel: Channel<ContentAssetsProgressChannelEvent>,
  { payload, meta }: ReturnType<typeof contentAssetsActions.uploadContentAssets>,
) {
  yield all(
    payload.map(entity =>
      call(
        uploadContentAsset,
        progressChannel,
        contentAssetsActions.uploadContentAsset(entity, getMainTableTypeByApiContentType(meta.filters?.contentType)),
      ),
    ),
  );
}

function* refetchContentAssets({ payload }: ReturnType<typeof iotEventAssetUpdate>) {
  const tableType = getMainTableTypeByContentType(payload.content_type);
  const isAddedOrDeleted = isIotTableEntityAddedOrDeleted(yield select(), payload, tableType);
  if (isAddedOrDeleted) {
    yield call(
      fetchContentAssets,
      contentAssetsActions.fetchContentAssets(
        createContentAssetFiltersByTableType({ tableType }),
        { tableType, cache: { disableCache: true } },
        ContentAssetsRemoteOperation.IOT_FETCH,
      ),
    );
  }

  yield put(contentAssetsActions.iotUpdate(payload));
}

function* handleUploading() {
  const progressChannel: Channel<ContentAssetsProgressChannelEvent> = yield call(channel);
  yield takeEvery(contentAssetsActions.uploadContentAssets, uploadContentAssets, progressChannel);

  while (true) {
    const { progressInfo }: ContentAssetsProgressChannelEvent = yield take(progressChannel);
    yield put(contentAssetsActions.progressUploadingContentAsset(progressInfo));
  }
}

export function* watchContentAssets() {
  yield takeLatestOrEvery(contentAssetsActions.fetchContentAssets, function* (action) {
    yield race({
      task: call(fetchContentAssets, action),
      cancel: take([contentAssetsActions.selectContentAssets, contentAssetsActions.toggleSelected]),
    });
  });

  yield takeLatestOrEvery(contentAssetsActions.fetchContentAsset, fetchContentAsset);
  yield takeLeading(contentAssetsActions.deleteContentAssets, deleteContentAssets);
  yield takeLatest(iotEventAssetUpdate, refetchContentAssets);

  yield call(handleUploading);
}
