import { type FC } from "react";

import { AggregatedValue } from "@ds/components/entities";
import { IconType } from "@ds/components/icons";
import { InfoView, InfoViewCallToAction } from "@ds/components/info-view";
import { getInfoViewTitle } from "@ds/components/info-view/helpers";
import { useDeepRootSelector } from "@ds/hooks";

import { selectAggregatedDeploymentForSelectedEntities } from "../redux/selectors";
import { DeploymentInfoSection } from "./common/deployment-info-section";

export const DeploymentInfo: FC<{ onCreateDeployment: () => void }> = ({ onCreateDeployment }) => {
  const aggregatedDeployment = useDeepRootSelector(selectAggregatedDeploymentForSelectedEntities);
  if (aggregatedDeployment) {
    return (
      <InfoView
        infoTitle={getInfoViewTitle(aggregatedDeployment)}
        infoDescription={<AggregatedValue entity={aggregatedDeployment} field="description" />}
      >
        <DeploymentInfoSection deployment={aggregatedDeployment} />
      </InfoView>
    );
  }

  return (
    <InfoViewCallToAction
      infoTitle="Select deployment"
      callToActionText="Select a deployment in the table for more info or proceed with creating a new one."
      buttonLabel="Create deployment"
      buttonIcon={IconType.Create}
      onActionButtonClick={onCreateDeployment}
    />
  );
};
