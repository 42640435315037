import { type FC, type ReactNode } from "react";
import Dropzone, { type Accept, type DropzoneRef } from "react-dropzone";
import styled from "styled-components";

const DropzoneWrapper = styled.div`
  position: relative;
  height: 100%;
  width: 100%;
`;

const DropzoneOverlay = styled.div<{
  isDragAccept: boolean;
  isDragReject: boolean;
}>`
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  z-index: 10;
  display: grid;
  place-items: center;
  background: var(--blackShadowColor025);
  box-shadow:
    inset 0 0 0 0 var(--blackShadowColor010),
    inset 0 0 14px 6px var(--whiteShadowColor100),
    -1px 2px 3px var(--whiteShadowColor025);
`;

const DropzoneOverlayText = styled.span`
  line-height: 1.2rem;
  font-size: 1.2rem;
  letter-spacing: 1px;
  color: var(--lightSurfaceColor);
  text-shadow: 2px -2px 4px var(--blackShadowColor100);
  padding: 0 30px;
  text-align: center;
`;

export const DndDropzone: FC<{
  acceptMediaTypes: Accept;
  onUpload: (files: File[]) => void;
  dropzoneRef?: React.Ref<DropzoneRef>;
  multiple?: boolean;
  children: ReactNode;
}> = ({ acceptMediaTypes, onUpload, children, dropzoneRef, multiple = true }) => (
  <Dropzone ref={dropzoneRef} accept={acceptMediaTypes} onDrop={onUpload} multiple={multiple} noClick>
    {({ getRootProps, getInputProps, isDragActive, isDragAccept, isDragReject }) => (
      <DropzoneWrapper {...getRootProps({ isDragAccept, isDragReject })}>
        <input {...getInputProps()} />
        {isDragActive && (
          <DropzoneOverlay isDragAccept={isDragAccept} isDragReject={isDragReject}>
            {isDragAccept && <DropzoneOverlayText>Drop files here...</DropzoneOverlayText>}
            {isDragReject && (
              <DropzoneOverlayText>
                Unsupported format, can accept only {Object.values(acceptMediaTypes).flat()}
              </DropzoneOverlayText>
            )}
          </DropzoneOverlay>
        )}
        {children}
      </DropzoneWrapper>
    )}
  </Dropzone>
);
