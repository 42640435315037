import { deepKeys } from "dot-prop";
import { cloneDeep, isArray, isBoolean, isEmpty, isNil, isNumber, isString, uniq } from "lodash";
import { type FieldValues, type Path, type PathValue } from "react-hook-form";

import { isAggregatedEntity, isEntity } from "@ds/model/entity-model";

import { isDeployment } from "@ds/modules/deployments/utils/model";
import { isExperience } from "@ds/modules/experiences/utils/model";
import { isLocation } from "@ds/modules/locations/utils/model";
import { isProject } from "@ds/modules/settings/projects/utils/model";

import { getProperty, setProperty } from "./properties";
import {
  isFiniteNumber,
  isInfiniteNumber,
  isNumberArray,
  isPlainObject,
  isStringArray,
} from "./type-guards/common-guards";

const getDefaultValueByFieldType = (obj: object) => {
  if (isNil(obj)) {
    return undefined;
  } else if (isString(obj)) {
    return "";
  } else if (isNumber(obj)) {
    return 0;
  } else if (isBoolean(obj)) {
    return false;
  } else if (isArray(obj)) {
    return [];
  } else if (isPlainObject(obj)) {
    return {};
  }
};

const getMaxArrayLengthForEntitiesField = <T extends Entity>(entities: T[], field: string) => {
  const [firstEntity, ...restEntities] = entities;
  const fieldValue = getProperty(firstEntity, field) || [];
  const restEntitiesArrLength = restEntities.reduce((acc: number, curr) => {
    const currArrLength = (getProperty(curr, field) || []).length;
    return acc > currArrLength ? acc : currArrLength;
  }, 0);

  return Math.max(fieldValue.length, restEntitiesArrLength);
};

const setArrayFields = <T extends Entity>(aggregatedFields: Record<keyof T, boolean>, entities: T[], field: string) => {
  const [firstEntity, ...restEntities] = entities;
  const fieldValue = getProperty(firstEntity, field) || [];
  const maxArrLength = getMaxArrayLengthForEntitiesField(entities, field);
  for (let i = 0; i < maxArrLength; i++) {
    if (
      restEntities.some(entity => {
        const entityField = getProperty(entity, field) || [];
        return JSON.stringify(fieldValue[i]) !== JSON.stringify(entityField[i]);
      })
    ) {
      setProperty(aggregatedFields, `${field}[${i}]`, true);
    }
  }

  return aggregatedFields;
};

export const setAggregatedFields = <T extends Entity>(
  aggregatedFields: Record<keyof T, boolean>,
  entities: T[],
  parentFieldPrefix?: string,
  parentFieldPostfix?: string,
) => {
  let newAggregatedFields = { ...aggregatedFields };
  const fieldPrefix = parentFieldPrefix ? `${parentFieldPrefix}.` : "";
  const fieldPostfix = parentFieldPostfix ? `.${parentFieldPostfix}` : "";
  const entriesProperties = parentFieldPrefix
    ? (entities.map(entity => getProperty(entity, parentFieldPrefix)) as Record<string, unknown>[]).filter(_ => _)
    : entities;

  const [first, ...rest] = entriesProperties;
  const entityKeys = uniq(entriesProperties.flatMap(Object.keys));

  entityKeys.forEach(field => {
    const fieldValue = getProperty(first, field);
    if (isNil(fieldValue) || isString(fieldValue) || isFiniteNumber(fieldValue) || isBoolean(fieldValue)) {
      if (rest.some(entity => getProperty(entity, field) !== fieldValue)) {
        setProperty(newAggregatedFields, `${fieldPrefix}${field}${fieldPostfix}`, true);
      }
    } else if (isArray(fieldValue)) {
      newAggregatedFields = setArrayFields(newAggregatedFields, entities, `${fieldPrefix}${field}${fieldPostfix}`);
    } else if (isPlainObject(fieldValue) || isInfiniteNumber(fieldValue)) {
      if (rest.some(entity => JSON.stringify(getProperty(entity, field)) !== JSON.stringify(fieldValue))) {
        setProperty(newAggregatedFields, `${fieldPrefix}${field}${fieldPostfix}`, true);
      }
    }
  });

  return newAggregatedFields;
};

export const getAggregatedModel = <T extends Entity>(entities: T[]) => {
  if (!entities?.length) {
    return null;
  }

  let aggregatedFields = {} as Record<keyof T, boolean>;
  const [firstEntity, ...restEntities] = entities;
  const resultEntity = cloneDeep(firstEntity);

  Object.keys(firstEntity).forEach(field => {
    const fieldValue = getProperty(firstEntity, field);
    const isExperienceOrProjectFormationField =
      field === "formation" && (isExperience(firstEntity) || isProject(firstEntity));

    const isLocationAddressOrTimezoneField = (field === "address" || field === "time_zone") && isLocation(firstEntity);
    const isDeploymentsDetailsField = (field === "details" || field === "params") && isDeployment(firstEntity);
    const isEntityAuditField = field === "audit" && isEntity(firstEntity);

    if (isExperienceOrProjectFormationField) {
      aggregatedFields = setAggregatedFields(aggregatedFields, entities, "formation.properties", "value");
    } else if (isLocationAddressOrTimezoneField || isDeploymentsDetailsField || isEntityAuditField) {
      aggregatedFields = setAggregatedFields(aggregatedFields, entities, field);
    } else if (isNil(fieldValue) || isString(fieldValue) || isFiniteNumber(fieldValue) || isBoolean(fieldValue)) {
      if (restEntities.some(entity => getProperty(entity, field) !== fieldValue)) {
        setProperty(aggregatedFields, field, true);
      }
    } else if (isArray(fieldValue)) {
      aggregatedFields = setArrayFields(aggregatedFields, entities, field);
    } else if (isPlainObject(fieldValue) || isInfiniteNumber(fieldValue)) {
      if (restEntities.some(entity => JSON.stringify(getProperty(entity, field)) !== JSON.stringify(fieldValue))) {
        setProperty(aggregatedFields, field, true);
      }
    }
  });

  const proxyFields = {};
  deepKeys(aggregatedFields).forEach(field => {
    const fieldValue = getProperty(firstEntity, field) as object;
    setProperty(resultEntity, field, getDefaultValueByFieldType(fieldValue));

    if (isBoolean(fieldValue)) {
      setProperty(proxyFields, field, null);
    }
  });
  const aggregatedIds = uniq(entities.map(({ id }) => id));
  return {
    ...resultEntity,
    metadata: {
      totalAggregated: aggregatedIds.length,
      isAggregated: aggregatedIds.length > 1,
      aggregatedIds,
      aggregatedFields,
      proxyFields,
    },
  } as T;
};

export const isMultipleFieldValuesInAggregatedEntity = <T extends AggregatedEntity<T>>(obj: T, field: Path<T>) =>
  isAggregatedEntity(obj) && !!getProperty(obj?.metadata?.aggregatedFields, field);

export const getAggregatedEntityIds = ((entity: AggregatedEntity<Entity> | null): number[] | string[] => {
  if (isEmpty(entity) || entity === null) {
    return [];
  }

  return (entity?.metadata?.aggregatedIds || [entity.id] || []) as string[] | number[];
}) as {
  (entity: null): [];
  (entity: AggregatedEntity<Entity>): number[] | string[];
  (entity: { id: number }): number[];
  (entity: { id: string }): string[];
};

export const getIds = (<T extends Entity>(entitiesOrIds: T[] | number[] | string[]) =>
  isNumberArray(entitiesOrIds) || isStringArray(entitiesOrIds) ? entitiesOrIds : entitiesOrIds.map(({ id }) => id)) as {
  (entitiesOrIds: Entity<number>[] | number[]): number[];
  (entitiesOrIds: Entity<string>[] | string[]): string[];
};

export const getProxyFieldPath = <T extends FieldValues>(fieldPath: Path<T>) =>
  `metadata.proxyFields.${fieldPath}` as Path<T>;

export const getNormalAndProxyFieldsMap = <T extends Entity>(entity: T, fieldPath: Path<T>) => {
  const proxyFieldPath = getProxyFieldPath(fieldPath);
  return {
    normal: {
      path: fieldPath,
      value: getProperty(entity, fieldPath) as PathValue<T, Path<T>>,
    },
    proxy: {
      path: proxyFieldPath,
      value: getProperty(entity, proxyFieldPath) as PathValue<T, Path<T>>,
    },
  } as const;
};
