import { CognitoUser } from "amazon-cognito-identity-js";
import { Auth as AmplifyAuth } from "aws-amplify";

import { EntityTypeName } from "@ds/model/entity-model";

import { REACT_APP_REGION } from "@ds/constants/environment";
import { DSApi } from "@ds/services/ds-api";
import { type ApiService, type ApiServiceConfig } from "@ds/services/types";

import {
  type InvitedUser,
  type UserChangePasswordModel,
  type UserForgotPasswordModel,
  type UserRestorePasswordModel,
  type UserSignInModel,
  type UserSignUpModel,
} from "../redux/types";
import { AuthError, AuthErrorCode } from "./not-configured-amplify-error";

const localStorageKey = "DsAuthIdentity";

interface AuthServiceConfig extends ApiServiceConfig {
  api: DSApi;
  region: string;
}

class AuthService implements ApiService {
  private config: AuthServiceConfig = { api: {} as DSApi, region: REACT_APP_REGION };

  provider = AmplifyAuth;

  configure(cfg: ApiServiceConfig) {
    this.config = { ...this.config, ...cfg };
    const authData = JSON.parse(localStorage.getItem(localStorageKey) || "{}");
    this.provider.configure({ region: this.config.region, ...authData });
  }

  async configureAmplifyAuth(username: string) {
    const url = `${this.config.api.baseUrl}/auth/identity/${username}`;
    const response = await fetch(url);
    if (!response.ok) {
      return Promise.reject(
        new Error(response.status === 404 ? "You entered wrong credentials" : `{res.status} ${response.statusText}`),
      );
    }

    const { user_pool, user_pool_client, identity_pool } = await response.json();
    const authData = {
      userPoolId: user_pool,
      userPoolWebClientId: user_pool_client,
      identityPoolId: identity_pool,
    };

    if (!authData.userPoolId || !authData.userPoolWebClientId || !authData.identityPoolId) {
      return Promise.reject(new Error("You entered wrong credentials"));
    }

    localStorage.setItem(localStorageKey, JSON.stringify(authData));
    return this.provider.configure({ region: this.config.region, ...authData });
  }

  async getCognitoUserAttributes(bypassCache = false): Promise<Record<string, string>> {
    const user = await this.getAuthenticatedCognitoUser(bypassCache);
    const userAttributes = await this.provider.userAttributes(user);

    return userAttributes.reduce((acc, curr) => ({ ...acc, [curr.getName()]: curr.getValue() }), {});
  }

  async getAuthenticatedCognitoUser(bypassCache = false): Promise<CognitoUser> {
    return this.provider.currentAuthenticatedUser({ bypassCache });
  }

  async getCurrentSessionJwtToken(): Promise<string> {
    const currentSession = await this.provider.currentSession();
    return currentSession.getIdToken().getJwtToken();
  }

  async getAuthenticatedUser(bypassCache = false): Promise<AuthUser> {
    if (!localStorage.getItem(localStorageKey)) {
      throw new AuthError(AuthErrorCode.NotConfiguredAmplify);
    }

    const cognitoAttributes = await this.getCognitoUserAttributes(bypassCache);
    return {
      __typename: EntityTypeName.AUTH_USER,
      id: +cognitoAttributes["custom:user_id"],
      tenant_id: cognitoAttributes["custom:tenant_id"],
      email: cognitoAttributes.email,
      given_name: cognitoAttributes.given_name,
      family_name: cognitoAttributes.family_name,
      role: cognitoAttributes["custom:role"] as TenantRole,
    };
  }

  async signIn(model: UserSignInModel) {
    await this.configureAmplifyAuth(model.username);
    return this.provider.signIn(model.username, model.password);
  }

  async signUp(data: UserSignUpModel) {
    return this.config.api.post<User>("/users/", data);
  }

  async signOut() {
    localStorage.removeItem(localStorageKey);
    return this.provider.signOut({ global: true });
  }

  async getInvitationInfo(token: string) {
    return this.config.api.get<InvitedUser>("/invites/token/content", { token });
  }

  async forgotPassword(model: UserForgotPasswordModel) {
    await this.configureAmplifyAuth(model.username);
    return this.provider.forgotPassword(model.username);
  }

  async restorePassword(model: UserRestorePasswordModel) {
    await this.configureAmplifyAuth(model.username);
    return this.provider.forgotPasswordSubmit(model.username, model.code, model.password);
  }

  async changePassword(model: UserChangePasswordModel, bypassCache = false) {
    const user = await this.getAuthenticatedCognitoUser(bypassCache);
    return this.provider.changePassword(user, model.password, model.newPassword);
  }
}

export const authService = new AuthService();
