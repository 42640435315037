import { useMemo, type FC } from "react";

import { InfoSection } from "@ds/components/info-view";

import { ProjectFormationInfoSectionAttribute } from "./project-formation-info-section-attribute";

export const ProjectFormationInfoSection: FC<{
  cardTitle?: string;
  project: Project;
  templateProperties: [string, EntityFormationProperty][];
}> = ({ cardTitle, project, templateProperties }) => {
  const infoBody = useMemo(
    () => (
      <>
        {templateProperties.map(([field, property]) => (
          <ProjectFormationInfoSectionAttribute
            key={`project-formation-property-${field}`}
            entity={project}
            field={field}
            property={property}
          />
        ))}
      </>
    ),
    [project, templateProperties],
  );

  if (cardTitle) {
    return (
      <InfoSection title={cardTitle} icon="pi pi-desktop">
        {infoBody}
      </InfoSection>
    );
  }

  return <>{infoBody}</>;
};
