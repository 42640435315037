import {
  confirmDialog as primeConfirmDialog,
  type ConfirmDialogProps as PrimeConfirmDialogProps,
} from "primereact/confirmdialog";

import { logger } from "@ds/utils/logger";

export { ConfirmDialog } from "primereact/confirmdialog";

export enum ConfirmTypeEnum {
  WARNING = "WARNING",
  SUCCESS = "SUCCESS",
}

export const confirmSuccess = ({
  message = "Are you sure you want to proceed?",
  header = "Proceed confirmation",
  icon = "pi pi-exclamation-triangle",
  ...rest
}: PrimeConfirmDialogProps) => primeConfirmDialog({ message, header, icon, ...rest });

export const confirmWarning = ({
  message,
  header = "Delete confirmation",
  icon = "pi pi-exclamation-triangle",
  acceptClassName = "p-button-danger",
  ...rest
}: PrimeConfirmDialogProps) => primeConfirmDialog({ message, header, icon, acceptClassName, ...rest });

export const confirmByType = ({ type, ...restProps }: PrimeConfirmDialogProps & { type: ConfirmTypeEnum }) => {
  if (type === ConfirmTypeEnum.SUCCESS) {
    return confirmSuccess(restProps);
  }

  if (type === ConfirmTypeEnum.WARNING) {
    return confirmWarning(restProps);
  }

  logger.error(`Unhandled confirm modal dialog type: ${type}`);
};
