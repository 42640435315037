import { useCallback, useEffect } from "react";
import { useLocation } from "react-router-dom";

import { selectCurrentView as selectContentCurrentView } from "@ds/modules/content/redux/selectors";
import { contentActions } from "@ds/modules/content/redux/slice";
import { ContentTableTypeEnum } from "@ds/modules/content/utils/model";
import { selectCurrentView as selectDeploymentsCurrentView } from "@ds/modules/deployments/redux/selectors";
import { deploymentsActions } from "@ds/modules/deployments/redux/slice";
import { DeploymentTableTypeEnum } from "@ds/modules/deployments/utils/model";
import { selectCurrentView as selectDevicesCurrentView } from "@ds/modules/devices/redux/selectors";
import { devicesActions } from "@ds/modules/devices/redux/slice";
import { DeviceTableTypeEnum } from "@ds/modules/devices/utils/model";
import { selectCurrentView as selectExperiencesCurrentView } from "@ds/modules/experiences/redux/selectors";
import { experiencesActions } from "@ds/modules/experiences/redux/slice";
import { ExperienceTableTypeEnum } from "@ds/modules/experiences/utils/model";
import { selectCurrentView as selectLocationsCurrentView } from "@ds/modules/locations/redux/selectors";
import { locationsActions } from "@ds/modules/locations/redux/slice";
import { LocationTableTypeEnum } from "@ds/modules/locations/utils/model";
import { selectCurrentView as selectSettingsCurrentView } from "@ds/modules/settings/redux/selectors";
import { settingsActions } from "@ds/modules/settings/redux/slice";
import { SettingsTableTypeEnum } from "@ds/modules/settings/utils/model";

import {
  CONTENT_AUDIO_ROUTE,
  CONTENT_IMAGES_ROUTE,
  CONTENT_PLAYLISTS_ROUTE,
  CONTENT_VIDEOS_ROUTE,
  DEPLOYMENTS_ROUTE,
  DEVICE_PERIPHERALS_ROUTE,
  DEVICE_PLAYERS_ROUTE,
  EXPERIENCES_ROUTE,
  LOCATIONS_ROUTE,
  SETTINGS_INVITATIONS_ROUTE,
  SETTINGS_PROJECTS_ROUTE,
  SETTINGS_USERS_ROUTE,
} from "../constants/router/entity-routes";
import { useRootDispatch, useRootSelector } from "./redux-hooks";

export const useCurrentView = () => {
  const { pathname } = useLocation();
  const currentView = useRootSelector(state => {
    if (pathname === EXPERIENCES_ROUTE) {
      return selectExperiencesCurrentView(state);
    } else if (pathname === DEVICE_PLAYERS_ROUTE) {
      return selectDevicesCurrentView(state);
    } else if (pathname === DEVICE_PERIPHERALS_ROUTE) {
      return selectDevicesCurrentView(state);
    } else if (pathname === LOCATIONS_ROUTE) {
      return selectLocationsCurrentView(state);
    } else if (pathname === CONTENT_PLAYLISTS_ROUTE) {
      return selectContentCurrentView(state);
    } else if (pathname === CONTENT_VIDEOS_ROUTE) {
      return selectContentCurrentView(state);
    } else if (pathname === CONTENT_IMAGES_ROUTE) {
      return selectContentCurrentView(state);
    } else if (pathname === CONTENT_AUDIO_ROUTE) {
      return selectContentCurrentView(state);
    } else if (pathname === DEPLOYMENTS_ROUTE) {
      return selectDeploymentsCurrentView(state);
    } else if (pathname === SETTINGS_PROJECTS_ROUTE) {
      return selectSettingsCurrentView(state);
    } else if (pathname === SETTINGS_USERS_ROUTE) {
      return selectSettingsCurrentView(state);
    } else if (pathname === SETTINGS_INVITATIONS_ROUTE) {
      return selectSettingsCurrentView(state);
    }

    return undefined;
  });

  const dispatch = useRootDispatch();
  const switchView = useCallback(() => {
    if (!currentView) {
      if (pathname === EXPERIENCES_ROUTE) {
        dispatch(experiencesActions.switchView(ExperienceTableTypeEnum.Experiences));
      } else if (pathname === DEVICE_PLAYERS_ROUTE) {
        dispatch(devicesActions.switchView(DeviceTableTypeEnum.Players));
      } else if (pathname === DEVICE_PERIPHERALS_ROUTE) {
        dispatch(devicesActions.switchView(DeviceTableTypeEnum.Peripherals));
      } else if (pathname === LOCATIONS_ROUTE) {
        dispatch(locationsActions.switchView(LocationTableTypeEnum.Locations));
      } else if (pathname === CONTENT_PLAYLISTS_ROUTE) {
        dispatch(contentActions.switchView(ContentTableTypeEnum.Playlists));
      } else if (pathname === CONTENT_VIDEOS_ROUTE) {
        dispatch(contentActions.switchView(ContentTableTypeEnum.Videos));
      } else if (pathname === CONTENT_IMAGES_ROUTE) {
        dispatch(contentActions.switchView(ContentTableTypeEnum.Images));
      } else if (pathname === CONTENT_AUDIO_ROUTE) {
        dispatch(contentActions.switchView(ContentTableTypeEnum.Audio));
      } else if (pathname === DEPLOYMENTS_ROUTE) {
        dispatch(deploymentsActions.switchView(DeploymentTableTypeEnum.Deployments));
      } else if (pathname === SETTINGS_PROJECTS_ROUTE) {
        dispatch(settingsActions.switchView(SettingsTableTypeEnum.Projects));
      } else if (pathname === SETTINGS_USERS_ROUTE) {
        dispatch(settingsActions.switchView(SettingsTableTypeEnum.Users));
      } else if (pathname === SETTINGS_INVITATIONS_ROUTE) {
        dispatch(settingsActions.switchView(SettingsTableTypeEnum.Invitations));
      }
    }
  }, [dispatch, currentView, pathname]);

  useEffect(switchView, [switchView]);

  return [currentView, switchView] as const;
};
