import prettyBytes from "pretty-bytes";
import { type FC } from "react";

import { MainTableDataTypeEnum } from "@ds/modules/table-data/utils/model";

import { AggregatedValue } from "@ds/components/entities";
import { IconType } from "@ds/components/icons";
import { InfoAttribute, InfoSection, InfoView, InfoViewCallToAction } from "@ds/components/info-view";
import { getInfoViewTitle } from "@ds/components/info-view/helpers";
import { useDeepRootSelector } from "@ds/hooks";

import { useDropzoneRef } from "../hooks/use-dropzone-ref";
import { selectAggregatedContentAssetForSelectedEntities } from "../redux/selectors/common-selectors";
import { getContentTypeNameByTableType, getSupportedFormatsByTableType } from "../utils/helpers";
import { ContentAssetUploadInput } from "./forms/common/content-assets-upload-input";

export const ContentAssetInfo: FC<{
  tableType: MainTableDataTypeEnum;
  onUpload: (files: File[]) => void;
}> = ({ tableType, onUpload }) => {
  const [aggregatedAsset, assetPrintName] = useDeepRootSelector(state => [
    selectAggregatedContentAssetForSelectedEntities(state, tableType),
    getContentTypeNameByTableType(tableType),
  ]);

  const [dropzoneRef, openDropzone] = useDropzoneRef();

  if (aggregatedAsset) {
    return (
      <InfoView infoTitle={getInfoViewTitle(aggregatedAsset)}>
        <InfoSection title="Asset Info" icon="pi pi-info-circle">
          <InfoAttribute
            name="File Size"
            value={
              <AggregatedValue
                entity={aggregatedAsset}
                field="file_size"
                formatter={entity => prettyBytes(entity.file_size)}
              />
            }
          />

          <InfoAttribute
            name="Content Type"
            value={<AggregatedValue entity={aggregatedAsset} field="content_type" />}
          />
        </InfoSection>
      </InfoView>
    );
  }

  return (
    <>
      <InfoViewCallToAction
        infoTitle={`Select ${assetPrintName}`}
        callToActionText={`Select a ${assetPrintName} asset in the table for more info or proceed with uploading new ${assetPrintName}.`}
        buttonLabel={`Upload ${assetPrintName}`}
        buttonIcon={IconType.Upload}
        onActionButtonClick={openDropzone}
      />

      <ContentAssetUploadInput
        acceptMediaTypes={getSupportedFormatsByTableType(tableType)}
        dropzoneRef={dropzoneRef}
        onUpload={onUpload}
      />
    </>
  );
};
