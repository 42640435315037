import { yupResolver } from "@hookform/resolvers/yup";
import { noop } from "lodash";
import { type FC } from "react";

import {
  DetailsCard,
  DetailsCardEditActionsMenu,
  DetailsCardRow,
  EditableDetailsCardDecorator,
} from "@ds/components/details-view";
import { AggregatedValue } from "@ds/components/entities";
import { formatRelativeDate } from "@ds/utils/date";
import { getProperty } from "@ds/utils/properties";

import { updateValidationSchema } from "../../utils/validation-schema";

export const PeripheralDetailsInfoCard: FC<{ peripheral: Peripheral }> = ({ peripheral }) => (
  <EditableDetailsCardDecorator
    initialValue={peripheral}
    validationResolver={yupResolver(updateValidationSchema.info)}
    onSubmit={noop}
  >
    {cardProps => (
      <DetailsCard
        title="Info"
        icon="info-circle"
        actions={
          <DetailsCardEditActionsMenu
            isEditing={cardProps.isEditing}
            isDirty={cardProps.isDirty}
            onReset={cardProps.onResetForm}
            onSubmit={cardProps.handleSubmit(cardProps.onSubmitForm)}
          />
        }
      >
        <DetailsCardRow label="Name">
          <AggregatedValue entity={cardProps.entity} field="name" />
        </DetailsCardRow>

        <DetailsCardRow label="Type">
          <AggregatedValue entity={cardProps.entity} field="peripheral_type" />
        </DetailsCardRow>

        <DetailsCardRow label="Description">
          <AggregatedValue entity={cardProps.entity} field="description" />
        </DetailsCardRow>

        <DetailsCardRow label="Message">
          <AggregatedValue entity={cardProps.entity} field="state_message" />
        </DetailsCardRow>

        <DetailsCardRow label="Last Updated">
          <AggregatedValue
            entity={cardProps.entity}
            field="audit.updated_at"
            formatter={(entity, field) => formatRelativeDate(getProperty(entity, field))}
          />
        </DetailsCardRow>
      </DetailsCard>
    )}
  </EditableDetailsCardDecorator>
);
