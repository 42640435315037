import { yupResolver } from "@hookform/resolvers/yup";
import { type FC } from "react";
import { type DeepPartial } from "react-hook-form";

import {
  DetailsCard,
  DetailsCardEditActionsMenu,
  EditableDetailsCardDecorator,
  EditableDetailsCardRow,
  EditableDetailsCardRowDecorator,
} from "@ds/components/details-view";
import { BooleanControl } from "@ds/components/forms/control/boolean-control";

import { updateValidationSchema } from "../../utils/validation-schema";

export const UserDetailsNotificationsCard: FC<{
  user: User;
  onSubmit: (changedFields: DeepPartial<User>) => void;
}> = ({ user, onSubmit }) => (
  <EditableDetailsCardDecorator
    initialValue={user}
    validationResolver={yupResolver(updateValidationSchema.notifications)}
    onSubmit={onSubmit}
  >
    {cardProps => (
      <DetailsCard
        title="Notifications"
        icon="pi pi-cog"
        actions={
          <DetailsCardEditActionsMenu
            isEditing={cardProps.isEditing}
            isDirty={cardProps.isDirty}
            onReset={cardProps.onResetForm}
            onSubmit={cardProps.handleSubmit(cardProps.onSubmitForm)}
          />
        }
      >
        <EditableDetailsCardRowDecorator
          field="is_deployment_notifications_enabled"
          input={
            <BooleanControl
              name="is_deployment_notifications_enabled"
              control={cardProps.control}
              setValue={cardProps.setValue}
              labelTitle="Deployment Notifications"
              autoFocus
              excludeLabel
            />
          }
          {...cardProps}
        >
          {rowProps => <EditableDetailsCardRow label="Deployment Notifications" editableSettings={rowProps} />}
        </EditableDetailsCardRowDecorator>

        <EditableDetailsCardRowDecorator
          field="is_device_offline_notifications_enabled"
          input={
            <BooleanControl
              name="is_device_offline_notifications_enabled"
              control={cardProps.control}
              setValue={cardProps.setValue}
              labelTitle="Device Offline Notifications"
              autoFocus
              excludeLabel
            />
          }
          {...cardProps}
        >
          {rowProps => <EditableDetailsCardRow label="Device Offline Notifications" editableSettings={rowProps} />}
        </EditableDetailsCardRowDecorator>
      </DetailsCard>
    )}
  </EditableDetailsCardDecorator>
);
