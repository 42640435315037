import { uniq } from "lodash";
import { useEffect, useMemo, type FC } from "react";
import styled from "styled-components";

import { useGetAggregatedEntityIds } from "@ds/hooks/use-get-aggregated-entity-ids";

import { EntityTypeName, isAggregatedEntity } from "@ds/model/entity-model";

import {
  getDeploymentStatusIndicatorWithNameAndLink,
  getDeviceSyncStatusIndicator,
} from "@ds/modules/deployments/components/common/status-indicator";
import { selectDeploymentsByIds, selectDeploymentsIsLoading } from "@ds/modules/deployments/redux/selectors";
import { deploymentsActions } from "@ds/modules/deployments/redux/slice";
import { selectDeviceSyncsByDeviceIds, selectDeviceSyncsIsLoading } from "@ds/modules/device-syncs/redux/selectors";
import { deviceSyncsActions } from "@ds/modules/device-syncs/redux/slice";

import { DetailsCard, DetailsCardRow } from "@ds/components/details-view";
import { MultipleValuesLabelWithLink, UnassignedLabel } from "@ds/components/forms/labels/common";
import { SpinnerIcon } from "@ds/components/loaders/loaders";
import { Loading } from "@ds/components/loaders/loading";
import { useDeepRootSelector, useRootDispatch, useRootSelector } from "@ds/hooks";
import { formatAbsoluteDate } from "@ds/utils/date";
import { composeDetailsRouteByEntityIds } from "@ds/utils/router";

const StyledSpinnerIcon = styled(SpinnerIcon)`
  width: 15px;
`;

export const PlayerDetailsLastSyncCard: FC<{ player: Player }> = ({ player }) => {
  const aggregatedPlayerIds = useGetAggregatedEntityIds(player);
  const aggregatedPlayersDeviceSyncs = useDeepRootSelector(state =>
    selectDeviceSyncsByDeviceIds(state, aggregatedPlayerIds, "id", "desc"),
  );

  const aggregatedPlayersDeviceSyncsDeploymentIds = useDeepRootSelector(state =>
    uniq(selectDeviceSyncsByDeviceIds(state, aggregatedPlayerIds).map(({ deployment_id }) => deployment_id)),
  );

  const aggregatedPlayersDeviceSyncsDeployments = useDeepRootSelector(state =>
    selectDeploymentsByIds(state, aggregatedPlayersDeviceSyncsDeploymentIds).filter(_ => _),
  );

  const isLoading = useRootSelector(
    state =>
      selectDeviceSyncsIsLoading(state) ||
      selectDeploymentsIsLoading(state) ||
      (!!aggregatedPlayersDeviceSyncs?.length && !aggregatedPlayersDeviceSyncsDeployments?.length),
  );

  const dispatch = useRootDispatch();
  useEffect(() => {
    if (aggregatedPlayerIds.length) {
      dispatch(deviceSyncsActions.fetchDeviceSyncs({ deviceId: aggregatedPlayerIds }));
    }
  }, [dispatch, aggregatedPlayerIds]);

  useEffect(() => {
    if (aggregatedPlayersDeviceSyncsDeploymentIds.length) {
      dispatch(deploymentsActions.fetchDeployments({ id: aggregatedPlayersDeviceSyncsDeploymentIds }));
    }
  }, [dispatch, aggregatedPlayersDeviceSyncsDeploymentIds]);

  const detailsBody = useMemo(() => {
    if (isLoading) {
      return null;
    }

    if (isAggregatedEntity(player)) {
      return (
        <MultipleValuesLabelWithLink
          to={composeDetailsRouteByEntityIds(aggregatedPlayersDeviceSyncsDeploymentIds, EntityTypeName.DEPLOYMENT)}
        />
      );
    } else if (!aggregatedPlayersDeviceSyncs[0]) {
      return <UnassignedLabel text="No deployment has been done yet" />;
    }

    return (
      <>
        <DetailsCardRow label="Deployment">
          {!aggregatedPlayersDeviceSyncsDeployments[0] && <StyledSpinnerIcon />}
          {aggregatedPlayersDeviceSyncsDeployments[0] &&
            getDeploymentStatusIndicatorWithNameAndLink(aggregatedPlayersDeviceSyncsDeployments[0])}
        </DetailsCardRow>
        <DetailsCardRow label="When">
          {formatAbsoluteDate(aggregatedPlayersDeviceSyncs[0].audit?.updated_at)}
        </DetailsCardRow>
        <DetailsCardRow label="Sync Status">
          {getDeviceSyncStatusIndicator(aggregatedPlayersDeviceSyncs[0])}
        </DetailsCardRow>
        <DetailsCardRow label="Info">{aggregatedPlayersDeviceSyncs[0].message}</DetailsCardRow>
      </>
    );
  }, [
    isLoading,
    player,
    aggregatedPlayersDeviceSyncs,
    aggregatedPlayersDeviceSyncsDeployments,
    aggregatedPlayersDeviceSyncsDeploymentIds,
  ]);

  return (
    <DetailsCard title="Last Sync" icon="download">
      <Loading isLoading={isLoading}>{detailsBody}</Loading>
    </DetailsCard>
  );
};
