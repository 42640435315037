import { DSApi } from "@ds/services/ds-api";
import { type ApiService, type ApiServiceConfig } from "@ds/services/types";
import { parseQueries } from "@ds/utils/query";

interface DeviceSyncServiceConfig extends ApiServiceConfig {
  api: DSApi;
}

export class DeviceSyncsService implements ApiService {
  private config: DeviceSyncServiceConfig = {
    api: {} as DSApi,
  };

  configure(cfg: DeviceSyncServiceConfig) {
    this.config = { ...cfg };
  }

  async getDeviceSyncs(queryInfo: QueryInfo, pagination: Pagination) {
    const queries = parseQueries(queryInfo, pagination);
    return this.config.api.get<QueryOutput<ApiDeviceSync>>(`/syncs/`, queries);
  }
}

export const deviceSyncsService = new DeviceSyncsService();
