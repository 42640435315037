import { useEffect, useMemo, type FC } from "react";

import { useGetAggregatedEntityIds } from "@ds/hooks/use-get-aggregated-entity-ids";

import { isAggregatedEntity } from "@ds/model/entity-model";

import { getPeripheralStatusIndicator } from "@ds/modules/devices/peripherals/components/common/status-indicator";
import {
  selectPeripheralsByPlayerIds,
  selectPeripheralsIsLoading,
} from "@ds/modules/devices/peripherals/redux/selectors";
import { peripheralsActions } from "@ds/modules/devices/peripherals/redux/slice";

import { DetailsCard, DetailsCardRow } from "@ds/components/details-view";
import { MultipleValuesLabelWithLink, UnassignedLabel } from "@ds/components/forms/labels/common";
import { Loading } from "@ds/components/loaders/loading";
import { useDeepRootSelector, useRootDispatch, useRootSelector } from "@ds/hooks";
import { composeDetailsRouteByEntityType } from "@ds/utils/router";

export const PlayerDetailsPeripheralsCard: FC<{ player: Player }> = ({ player }) => {
  const aggregatedPlayerIds = useGetAggregatedEntityIds(player);
  const aggregatedPlayersPeripherals = useDeepRootSelector(state =>
    selectPeripheralsByPlayerIds(state, aggregatedPlayerIds),
  );

  const isLoading = useRootSelector(selectPeripheralsIsLoading);

  const dispatch = useRootDispatch();
  useEffect(() => {
    if (aggregatedPlayerIds.length) {
      dispatch(peripheralsActions.fetchPeripherals({ deviceId: aggregatedPlayerIds }));
    }
  }, [dispatch, aggregatedPlayerIds]);

  const detailsBody = useMemo(() => {
    if (isLoading) {
      return null;
    }

    const hasMultipleValues = isAggregatedEntity(player);
    if (hasMultipleValues) {
      return <MultipleValuesLabelWithLink to={composeDetailsRouteByEntityType(aggregatedPlayersPeripherals)} />;
    } else if (!aggregatedPlayersPeripherals.length) {
      return <UnassignedLabel text="Player(s) has no peripherals" />;
    }

    return aggregatedPlayersPeripherals.map(entry => (
      <DetailsCardRow key={`player-details-peripheral-${entry.id}`} label={entry.name}>
        {getPeripheralStatusIndicator(entry)}
      </DetailsCardRow>
    ));
  }, [isLoading, player, aggregatedPlayersPeripherals]);

  return (
    <DetailsCard title="Peripherals" icon="sitemap">
      <Loading isLoading={isLoading}>{detailsBody}</Loading>
    </DetailsCard>
  );
};
