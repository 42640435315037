import { type FC } from "react";

import { IconType } from "@ds/components/icons";
import { InfoView, InfoViewCallToAction } from "@ds/components/info-view";
import { getInfoViewTitle } from "@ds/components/info-view/helpers";
import { useDeepRootSelector } from "@ds/hooks";

import { selectAggregatedInvitationForSelectedEntities } from "../redux/selectors";
import { InvitationInfoSection } from "./common/invitation-info-section";
import { InvitationProjectsInfoSection } from "./common/invitation-projects-info-section";

export const InvitationInfo: FC<{ onCreateInvitation: () => void }> = ({ onCreateInvitation }) => {
  const aggregatedInvitation = useDeepRootSelector(selectAggregatedInvitationForSelectedEntities);
  if (aggregatedInvitation) {
    return (
      <InfoView infoTitle={getInfoViewTitle(aggregatedInvitation)}>
        <InvitationInfoSection invitation={aggregatedInvitation} />
        <InvitationProjectsInfoSection invitation={aggregatedInvitation} />
      </InfoView>
    );
  }

  return (
    <InfoViewCallToAction
      infoTitle="Select invitation"
      callToActionText="Select an invitation in the table for more info or proceed with creating a new one."
      buttonLabel="Create invitation"
      buttonIcon={IconType.Create}
      onActionButtonClick={onCreateInvitation}
    />
  );
};
