import { type DeepPartial } from "react-hook-form";

import { DSApi } from "@ds/services/ds-api";
import { parseQueries } from "@ds/utils/query";
import { type ApiService, type ApiServiceConfig } from "@ds/services/types";

interface PlaylistsServiceConfig extends ApiServiceConfig {
  api: DSApi;
}

class PlaylistsService implements ApiService {
  private config: PlaylistsServiceConfig = {
    api: {} as DSApi,
  };

  configure(cfg: PlaylistsServiceConfig) {
    this.config = { ...cfg };
  }

  async getPlaylists(queryInfo: QueryInfo, pagination: Pagination) {
    const queries = parseQueries(queryInfo, pagination);
    return this.config.api.get<QueryOutput<Playlist>>("/playlists/", queries);
  }

  async getPlaylist(playlistId: number) {
    return this.config.api.get<Playlist>(`/playlists/${playlistId}`);
  }

  async createPlaylist(playlist: Playlist) {
    return this.config.api.post<Playlist>("/playlists/", playlist);
  }

  async updatePlaylists(ids: number[], data: DeepPartial<Playlist>) {
    return this.config.api.patch<Playlist>("/playlists/", { ids, data });
  }

  async deletePlaylists(ids: number[]) {
    return this.config.api.delete<Playlist>("/playlists/", { ids });
  }
}

export const playlistsService = new PlaylistsService();
