import { produce } from "immer";
import { has, omit } from "lodash";

import { isExperienceQueryOutput, isExperienceArray } from "./model";

const normalizer = (templateFormationProperties: TemplateFormationFieldPropertyMap) => (entity: ApiExperience) =>
  produce(entity, (draft: ApiExperience) => {
    Object.entries(templateFormationProperties).forEach(([k, v]) => {
      if (!has(draft.formation.properties, k)) {
        draft.formation.properties[k] = omit(v, ["metadata"]) as EntityFormationProperty;
      }
    });

    delete (draft.formation as TemplateFormation).name_constructor;
  });

export const normalizeFormationProperties = ((
  entityOrEntities: ApiExperience | ApiExperience[] | QueryOutput<ApiExperience>,
  templateFormationProperties?: TemplateFormationFieldPropertyMap,
): ApiExperience | ApiExperience[] | QueryOutput<ApiExperience> => {
  if (!templateFormationProperties) {
    return entityOrEntities;
  }

  if (isExperienceQueryOutput(entityOrEntities)) {
    return produce(entityOrEntities, draft => {
      draft.items = entityOrEntities.items.map(normalizer(templateFormationProperties));
    });
  }

  return isExperienceArray(entityOrEntities)
    ? entityOrEntities.map(normalizer(templateFormationProperties))
    : normalizer(templateFormationProperties)(entityOrEntities);
}) as {
  (experience: ApiExperience, templateFormationProperties?: TemplateFormationFieldPropertyMap): ApiExperience;
  (experiences: ApiExperience[], templateFormationProperties?: TemplateFormationFieldPropertyMap): ApiExperience[];
  (
    experiencesQueryOutput: QueryOutput<ApiExperience>,
    templateFormationProperties?: TemplateFormationFieldPropertyMap,
  ): QueryOutput<ApiExperience>;
};
