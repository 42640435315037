import { useDeepCompareMemo } from "@react-hookz/web";
import { AnyAction } from "@reduxjs/toolkit";
import { isBoolean, isNumber } from "lodash";
import { Dispatch, useCallback, useEffect, useState } from "react";

import { EntitySubtypeName, EntityTypeName } from "@ds/model/entity-model";

import { contentAssetsActions } from "@ds/modules/content/assets/redux/slice";
import { createContentAssetFiltersByEntityType } from "@ds/modules/content/assets/utils/helpers";
import { playlistsActions } from "@ds/modules/content/playlists/redux/slice";
import { deploymentsActions } from "@ds/modules/deployments/redux/slice";
import { peripheralsActions } from "@ds/modules/devices/peripherals/redux/slice";
import { playersActions } from "@ds/modules/devices/players/redux/slice";
import { experiencesActions } from "@ds/modules/experiences/redux/slice";
import { locationsActions } from "@ds/modules/locations/redux/slice";
import { invitationsActions } from "@ds/modules/settings/invitations/redux/slice";
import { projectsActions } from "@ds/modules/settings/projects/redux/slice";
import { usersActions } from "@ds/modules/settings/users/redux/slice";
import { DetailsTableDataTypeEnum, MainTableDataTypeEnum } from "@ds/modules/table-data/utils/model";

import { getQueryCamelCaseFilters } from "@ds/utils/query";
import { SagaOptions } from "@ds/utils/saga-helpers";

import { useRootDispatch } from "./redux-hooks";

type EntityOptions = {
  entityType: EntityTypeName;
  entitySubtype?: EntitySubtypeName;
  entityId?: number | string;
  filters?: KeysToCamelCase<QueryFilters>;
};

type HookOptions = {
  initialLoad?: boolean;
  disableCacheForInitialLoad?: boolean;
  tableType?: MainTableDataTypeEnum | DetailsTableDataTypeEnum;
};

const baseFetchEntity = (dispatch: Dispatch<AnyAction>, entityOptions: EntityOptions, sagaOptions: SagaOptions) => {
  if (!entityOptions.entityId) {
    throw new Error("Should provide entity id for single request");
  }

  if (isNumber(entityOptions.entityId)) {
    switch (entityOptions.entityType) {
      case EntityTypeName.EXPERIENCE:
        dispatch(experiencesActions.fetchExperience(entityOptions.entityId, sagaOptions));
        break;
      case EntityTypeName.DEVICE_PLAYER:
        dispatch(playersActions.fetchPlayer(entityOptions.entityId, sagaOptions));
        break;
      case EntityTypeName.DEVICE_PERIPHERAL:
        dispatch(peripheralsActions.fetchPeripheral(entityOptions.entityId, sagaOptions));
        break;
      case EntityTypeName.LOCATION:
        dispatch(locationsActions.fetchLocation(entityOptions.entityId, sagaOptions));
        break;
      case EntityTypeName.CONTENT_PLAYLIST:
        dispatch(playlistsActions.fetchPlaylist(entityOptions.entityId, sagaOptions));
        break;
      case EntityTypeName.CONTENT_ASSET:
        dispatch(contentAssetsActions.fetchContentAsset(entityOptions.entityId, sagaOptions));
        break;
      case EntityTypeName.DEPLOYMENT:
        dispatch(deploymentsActions.fetchDeployment(entityOptions.entityId, sagaOptions));
        break;
      case EntityTypeName.SETTINGS_PROJECT:
        dispatch(projectsActions.fetchProject(entityOptions.entityId, sagaOptions));
        break;
      case EntityTypeName.SETTINGS_USER:
        dispatch(usersActions.fetchUser(entityOptions.entityId, sagaOptions));
        break;
    }
  } else {
    switch (entityOptions.entityType) {
      case EntityTypeName.SETTINGS_INVITATION:
        dispatch(invitationsActions.fetchInvitation(entityOptions.entityId, sagaOptions));
        break;
    }
  }
};

const baseFetchEntities = (dispatch: Dispatch<AnyAction>, entityOptions: EntityOptions, sagaOptions: SagaOptions) => {
  switch (entityOptions.entityType) {
    case EntityTypeName.EXPERIENCE:
      dispatch(
        experiencesActions.fetchExperiences(
          getQueryCamelCaseFilters(entityOptions.filters, entityOptions.entityType),
          sagaOptions,
        ),
      );
      break;
    case EntityTypeName.DEVICE_PLAYER:
      dispatch(
        playersActions.fetchPlayers(
          getQueryCamelCaseFilters(entityOptions.filters, entityOptions.entityType),
          sagaOptions,
        ),
      );
      break;
    case EntityTypeName.DEVICE_PERIPHERAL:
      dispatch(
        peripheralsActions.fetchPeripherals(
          getQueryCamelCaseFilters(entityOptions.filters, entityOptions.entityType),
          sagaOptions,
        ),
      );
      break;
    case EntityTypeName.LOCATION:
      dispatch(
        locationsActions.fetchLocations(
          getQueryCamelCaseFilters(entityOptions.filters, entityOptions.entityType),
          sagaOptions,
        ),
      );
      break;
    case EntityTypeName.CONTENT_PLAYLIST:
      dispatch(
        playlistsActions.fetchPlaylists(
          getQueryCamelCaseFilters(entityOptions.filters, entityOptions.entityType),
          sagaOptions,
        ),
      );
      break;
    case EntityTypeName.CONTENT_ASSET:
      dispatch(
        contentAssetsActions.fetchContentAssets(
          {
            ...createContentAssetFiltersByEntityType({
              entityType: entityOptions.entitySubtype,
            }),
            ...getQueryCamelCaseFilters(entityOptions.filters, entityOptions.entityType),
          },
          sagaOptions,
        ),
      );

      break;
    case EntityTypeName.DEPLOYMENT:
      dispatch(
        deploymentsActions.fetchDeployments(
          getQueryCamelCaseFilters(entityOptions.filters, entityOptions.entityType),
          sagaOptions,
        ),
      );
      break;
    case EntityTypeName.SETTINGS_PROJECT:
      dispatch(
        projectsActions.fetchProjects(
          getQueryCamelCaseFilters(entityOptions.filters, entityOptions.entityType),
          sagaOptions,
        ),
      );
      break;
    case EntityTypeName.SETTINGS_USER:
      dispatch(
        usersActions.fetchUsers(getQueryCamelCaseFilters(entityOptions.filters, entityOptions.entityType), sagaOptions),
      );
      break;
    case EntityTypeName.SETTINGS_INVITATION:
      dispatch(
        invitationsActions.fetchInvitations(
          getQueryCamelCaseFilters(entityOptions.filters, entityOptions.entityType),
          sagaOptions,
        ),
      );
      break;
  }
};

export const useReduxEntitiesFetch = (entityOptions: EntityOptions, hookOptions: HookOptions = {}) => {
  const [isCacheDisabled, setIsCacheDisabled] = useState<boolean>(hookOptions?.disableCacheForInitialLoad || false);
  const [bitFlag, toggleBitFlag] = useState<boolean | undefined>(hookOptions?.initialLoad || undefined);

  const memoizedFilters = useDeepCompareMemo(() => entityOptions.filters, [entityOptions.filters]);
  const toggleRefetch = useCallback(
    (disableCache: boolean) => () => {
      setIsCacheDisabled(disableCache);
      toggleBitFlag(!bitFlag);
    },
    [bitFlag, toggleBitFlag],
  );

  const dispatch = useRootDispatch();
  const fetchEntities = useCallback(() => {
    if (entityOptions.entityId) {
      baseFetchEntity(
        dispatch,
        {
          filters: memoizedFilters,
          entityId: entityOptions.entityId,
          entityType: entityOptions.entityType,
          entitySubtype: entityOptions.entitySubtype,
        },
        {
          tableType: hookOptions.tableType,
          cache: { disableCache: isCacheDisabled },
        },
      );
    } else {
      baseFetchEntities(
        dispatch,
        {
          filters: memoizedFilters,
          entityType: entityOptions.entityType,
          entitySubtype: entityOptions.entitySubtype,
        },
        {
          tableType: hookOptions.tableType,
          cache: { disableCache: isCacheDisabled },
        },
      );
    }
  }, [
    dispatch,
    isCacheDisabled,
    hookOptions.tableType,
    memoizedFilters,
    entityOptions.entityId,
    entityOptions.entityType,
    entityOptions.entitySubtype,
  ]);

  useEffect(() => {
    if (isBoolean(bitFlag)) {
      fetchEntities();
    }
  }, [bitFlag, fetchEntities]);

  return [toggleRefetch(true), toggleRefetch(false)];
};
