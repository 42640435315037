import { all } from "redux-saga/effects";

import { rootAppSaga } from "@ds/modules/app/redux/slice";
import { rootAuthSaga } from "@ds/modules/auth/redux/slice";
import { rootContentAssetsSaga } from "@ds/modules/content/assets/redux/slice";
import { rootPlaylistAssetsSaga } from "@ds/modules/content/playlist-assets/redux/slice";
import { rootPlaylistsSaga } from "@ds/modules/content/playlists/redux/slice";
import { rootContentSaga } from "@ds/modules/content/redux/slice";
import { rootDeploymentsSaga } from "@ds/modules/deployments/redux/slice";
import { rootDeviceSyncsSaga } from "@ds/modules/device-syncs/redux/slice";
import { rootPeripheralsSaga } from "@ds/modules/devices/peripherals/redux/slice";
import { rootPlayersSaga } from "@ds/modules/devices/players/redux/slice";
import { rootDevicesSaga } from "@ds/modules/devices/redux/slice";
import { rootExperiencesSaga } from "@ds/modules/experiences/redux/slice";
import { rootIoTSaga } from "@ds/modules/iot/redux/sagas";
import { rootLocationsSaga } from "@ds/modules/locations/redux/slice";
import { rootInvitationsSaga } from "@ds/modules/settings/invitations/redux/slice";
import { rootProjectsSaga } from "@ds/modules/settings/projects/redux/slice";
import { rootSettingsSaga } from "@ds/modules/settings/redux/slice";
import { rootUsersSaga } from "@ds/modules/settings/users/redux/slice";
import { rootTableDataSaga } from "@ds/modules/table-data/redux/slice";

export function* rootSaga() {
  yield all([
    rootAppSaga(),
    rootAuthSaga(),

    rootExperiencesSaga(),
    rootDevicesSaga(),
    rootPlayersSaga(),
    rootPeripheralsSaga(),

    rootLocationsSaga(),

    rootContentSaga(),
    rootPlaylistsSaga(),
    rootPlaylistAssetsSaga(),
    rootContentAssetsSaga(),

    rootDeploymentsSaga(),
    rootDeviceSyncsSaga(),

    rootSettingsSaga(),
    rootProjectsSaga(),
    rootUsersSaga(),
    rootInvitationsSaga(),

    rootTableDataSaga(),
    rootIoTSaga(),
  ]);
}
