import { useMemo, type FC } from "react";

import { useCurrentView } from "@ds/hooks/use-current-view";
import { useVisibility } from "@ds/hooks/use-visibility";

import { EntityTypeName } from "@ds/model/entity-model";

import { getPageTitleByTableDataType } from "@ds/modules/table-data/utils/helpers";
import { MainTableDataTypeEnum } from "@ds/modules/table-data/utils/model";

import { MainView } from "@ds/components/main-view";
import { TableTabMenu } from "@ds/components/table/table-tab-menu";

import { PlaylistsTableActionsMenu } from "./components/common/playlists-table-actions-menu";
import { CreatePlaylistModal } from "./components/forms/create-playlist-form/create-playlist-modal";
import { PlaylistInfo } from "./components/playlist-info";
import { PlaylistsTable } from "./components/playlists-table";

export const PlaylistsMainPage: FC = () => {
  const [currentView] = useCurrentView();
  const [isCreateModalVisible, setIsCreateModalVisible] = useVisibility();

  const filterOptions = useMemo(
    () => ({
      entityType: EntityTypeName.CONTENT_PLAYLIST,
      tableType: MainTableDataTypeEnum.ContentPlaylists,
    }),
    [],
  );

  if (!currentView) {
    return null;
  }

  return (
    <>
      <MainView
        title={getPageTitleByTableDataType(filterOptions.tableType)}
        filterOptions={filterOptions}
        options={<TableTabMenu tableType={filterOptions.tableType} currentView={currentView} />}
        view={<PlaylistsTable />}
        info={<PlaylistInfo onCreatePlaylist={setIsCreateModalVisible(true)} />}
        actions={<PlaylistsTableActionsMenu onCreate={setIsCreateModalVisible(true)} />}
      />

      {isCreateModalVisible && <CreatePlaylistModal onClose={setIsCreateModalVisible(false)} />}
    </>
  );
};
