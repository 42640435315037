import { useCallback, type FC } from "react";

import { Loading } from "@ds/components/loaders/loading";
import { TableWithPagination } from "@ds/components/table/main-table";
import {
  Column,
  getEntityIdColumn,
  getEntityNameColumn,
  getGoToDetailsColumn,
  getLastUpdatedColumn,
  getSelectionColumn,
  getStatusColumn,
} from "@ds/components/table/table-columns";
import { TotalSelectedLabel } from "@ds/components/table/total-selected-label";
import { usePagination, useRootDispatch, useRootSelector, useTableDataSorting } from "@ds/hooks";
import { formatAbsoluteDate } from "@ds/utils/date";

import { MainTableDataTypeEnum } from "../../table-data/utils/model";
import { selectDeploymentsIsLoading, selectSelectedDeployments, selectTableDeployments } from "../redux/selectors";
import { DeploymentsRemoteOperation, deploymentsActions } from "../redux/slice";
import { DeploymentStatus } from "./common/deployment-status";

const getStatusBodyTemplate = (deployment: Deployment) => <DeploymentStatus deployment={deployment} />;
const getScheduleDateBodyTemplate = (deployment: Deployment) => formatAbsoluteDate(deployment.params?.scheduled_time);
const getPlayersCountBodyTemplate = (deployment: Deployment) => deployment.total_count || 0;

export const DeploymentsTable: FC = () => {
  const selectedEntities = useRootSelector(selectSelectedDeployments);
  const entities = useRootSelector(selectTableDeployments);

  const isLoading = useRootSelector(selectDeploymentsIsLoading);
  const checkboxIsLoading = useRootSelector(state =>
    selectDeploymentsIsLoading(state, DeploymentsRemoteOperation.CHECKBOXES),
  );

  const [pagination, setPagination] = usePagination(MainTableDataTypeEnum.Deployments);
  const [{ sortOrder, sortField }, setSorting] = useTableDataSorting(MainTableDataTypeEnum.Deployments);

  const dispatch = useRootDispatch();
  const onToggleRowSelected = useCallback(
    (entry: Deployment) => dispatch(deploymentsActions.toggleSelected(entry.id)),
    [dispatch],
  );

  const onSingleRowSelectHandler = useCallback(
    (entry: Deployment) => dispatch(deploymentsActions.selectDeployments([entry])),
    [dispatch],
  );

  const onToggleAllHandler = useCallback(
    (isSelectedAll: boolean) => {
      if (isSelectedAll) {
        dispatch(deploymentsActions.fetchDeployments(undefined, undefined, DeploymentsRemoteOperation.CHECKBOXES));
      } else {
        dispatch(deploymentsActions.selectDeployments([]));
      }
    },
    [dispatch],
  );

  return (
    <Loading isLoading={isLoading}>
      <TableWithPagination
        value={entities}
        selection={selectedEntities}
        onSingleRowSelect={onSingleRowSelectHandler}
        toggleRowSelected={onToggleRowSelected}
        loading={isLoading}
        sortField={sortField}
        sortOrder={sortOrder}
        onSort={setSorting}
        pagination={pagination}
        onPageChange={setPagination}
      >
        {getSelectionColumn({
          total: pagination.total,
          selected: selectedEntities.length,
          isLoading: checkboxIsLoading,
          onToggleAll: onToggleAllHandler,
        })}

        {getEntityIdColumn()}
        {getEntityNameColumn({ style: { flexGrow: 4 } })}
        <Column field="type" header="Type" className="center capitalize" style={{ flexGrow: 1 }} sortable />
        <Column field="total_count" header="# Players" body={getPlayersCountBodyTemplate} className="center" sortable />
        <Column
          field="params.scheduled_time"
          header="Schedule"
          body={getScheduleDateBodyTemplate}
          style={{ flexGrow: 2 }}
          resizeable={false}
        />

        {getStatusColumn({ body: getStatusBodyTemplate })}
        {getLastUpdatedColumn()}
        {getGoToDetailsColumn()}
      </TableWithPagination>

      <TotalSelectedLabel selectedCount={checkboxIsLoading ? Number(pagination.total) : selectedEntities.length} />
    </Loading>
  );
};
