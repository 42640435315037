import { createSelector } from "@reduxjs/toolkit";
import { ListIteratee, Many, orderBy, uniq } from "lodash";

import { DetailsTableDataTypeEnum, MainTableDataTypeEnum } from "@ds/modules/table-data/utils/model";

import { type RootState } from "@ds/store/bootstrap";
import { selectorsExtractIds, selectorsOutputEntitiesByIds } from "@ds/store/global-selectors";

import { LoadStatus } from "@ds/utils/reducer";

import { DeviceSyncsRemoteOperation, entitySelectors } from "./slice";

const extractSortIteratees = (
  _: RootState,
  _ids: number[] | string[],
  sortIteratees?: Many<ListIteratee<DeviceSync>>,
) => sortIteratees;

const extractSortOrder = (
  _: RootState,
  _ids: number[] | string[],
  _sortIteratees?: Many<ListIteratee<DeviceSync>>,
  sortOrders?: Many<boolean | "asc" | "desc">,
) => sortOrders;

const selectBaseState = (state: RootState) => state.deviceSyncs;

const selectLoadStatus = (state: RootState, operation = DeviceSyncsRemoteOperation.CRUD) =>
  selectBaseState(state).loadStatusMap[operation];

export const selectDeviceSyncsIsLoading = (state: RootState, operation = DeviceSyncsRemoteOperation.CRUD) =>
  selectLoadStatus(state, operation) === LoadStatus.Loading;

export const selectTableDeviceSyncIds = (
  state: RootState,
  tableType: MainTableDataTypeEnum | DetailsTableDataTypeEnum = DetailsTableDataTypeEnum.DeploymentDeviceSyncs,
) => selectBaseState(state).tableIds[tableType];

export const selectSelectedDeviceSyncIds = (
  state: RootState,
  tableType: MainTableDataTypeEnum | DetailsTableDataTypeEnum = DetailsTableDataTypeEnum.DeploymentDeviceSyncs,
) => selectBaseState(state).selectedIds[tableType];

export const selectDeviceSyncById = (state: RootState, id: number) =>
  entitySelectors.selectById(selectBaseState(state), id) as DeviceSync;

export const selectDeviceSyncIds = (state: RootState) => entitySelectors.selectIds(selectBaseState(state));
export const selectDeviceSyncs = (state: RootState) => entitySelectors.selectAll(selectBaseState(state));

export const selectDeviceSyncsByIds = createSelector(
  selectBaseState,
  selectorsExtractIds,
  selectorsOutputEntitiesByIds(() => entitySelectors),
);

export const selectTableDeviceSyncs = createSelector(
  selectBaseState,
  selectTableDeviceSyncIds,
  selectorsOutputEntitiesByIds(() => entitySelectors),
);

export const selectSelectedDeviceSyncs = createSelector(
  selectBaseState,
  selectSelectedDeviceSyncIds,
  selectorsOutputEntitiesByIds(() => entitySelectors),
);

export const selectDeviceSyncsByDeviceIds = createSelector(
  selectBaseState,
  selectorsExtractIds,
  extractSortIteratees,
  extractSortOrder,
  (state, deviceIds, sortIteratees, sortOrder) => {
    const normalizedDeviceIds = uniq(deviceIds as number[]);
    const result = entitySelectors.selectAll(state).filter(({ device_id }) => normalizedDeviceIds.includes(device_id));
    return sortIteratees ? orderBy(result, sortIteratees, sortOrder) : result;
  },
);

export const selectDeviceSyncsByDeploymentId = createSelector(
  selectBaseState,
  selectorsExtractIds,
  (state, deploymentIds) => {
    const normalizedDeploymentIds = uniq(deploymentIds as number[]);
    return entitySelectors
      .selectAll(state)
      .filter(({ deployment_id }) => normalizedDeploymentIds.includes(deployment_id));
  },
);
