import { useCallback, type FC } from "react";
import { type DeepPartial } from "react-hook-form";

import { useGetAggregatedEntityIds } from "@ds/hooks/use-get-aggregated-entity-ids";
import { useParseQueryIds } from "@ds/hooks/use-parse-query-ids";
import { useReduxEntitiesFetch } from "@ds/hooks/use-redux-entities-fetch";

import { EntityTypeName } from "@ds/model/entity-model";

import { DetailsView } from "@ds/components/details-view";
import { AbsoluteLoader } from "@ds/components/loaders/loading";
import { LOCATION_ID_PARAM } from "@ds/constants/router";
import { QueryParamType, useDeepRootSelector, useRootDispatch, useRootSelector } from "@ds/hooks";
import { pluralizeTotalSelectedTitle } from "@ds/utils/localization/common";
import { isMultiple } from "@ds/utils/numbers";

import { selectDeploymentsIsLoading } from "../deployments/redux/selectors";
import { DeploymentsRemoteOperation } from "../deployments/redux/slice";
import { MainTableDataTypeEnum } from "../table-data/utils/model";
import { LocationDetailsAddressCard } from "./components/details/location-details-address-card";
import { LocationDetailsExperiencesCard } from "./components/details/location-details-experiences-card";
import { LocationDetailsInfoCard } from "./components/details/location-details-info-card";
import { LocationDetailsOpenHoursCard } from "./components/details/location-details-open-hours-card";
import { LocationDetailsPlayersCard } from "./components/details/location-details-players-card";
import { selectAggregatedLocationByIds, selectLocationsIsLoading } from "./redux/selectors";
import { locationsActions } from "./redux/slice";

export const LocationDetailsPage: FC = () => {
  const queryIds = useParseQueryIds(LOCATION_ID_PARAM, QueryParamType.Number);
  const location = useDeepRootSelector(state => selectAggregatedLocationByIds(state, queryIds));
  const aggregatedLocationIds = useGetAggregatedEntityIds(location);

  const isLoading = useRootSelector(selectLocationsIsLoading);
  const isDeploying = useRootSelector(state => selectDeploymentsIsLoading(state, DeploymentsRemoteOperation.DEPLOYING));

  const [toggleRefetch] = useReduxEntitiesFetch(
    { entityType: EntityTypeName.LOCATION, filters: { id: queryIds } },
    { initialLoad: true },
  );

  const dispatch = useRootDispatch();
  const onSubmitHandler = useCallback(
    (changedFields: DeepPartial<Locality>) =>
      dispatch(locationsActions.updateLocations(aggregatedLocationIds, changedFields)),
    [dispatch, aggregatedLocationIds],
  );

  if (!location) {
    return <AbsoluteLoader isLoading />;
  }

  return (
    <DetailsView
      title={
        isMultiple(location?.metadata?.totalAggregated)
          ? pluralizeTotalSelectedTitle("location", location?.metadata?.totalAggregated)
          : location.name
      }
      tableType={MainTableDataTypeEnum.Locations}
      isLoading={isLoading || isDeploying}
      onRefresh={toggleRefetch}
    >
      <LocationDetailsInfoCard location={location} onSubmit={onSubmitHandler} />
      <LocationDetailsAddressCard location={location} onSubmit={onSubmitHandler} />
      <LocationDetailsOpenHoursCard location={location} onSubmit={onSubmitHandler} />
      <LocationDetailsExperiencesCard location={location} />
      <LocationDetailsPlayersCard location={location} />
    </DetailsView>
  );
};
