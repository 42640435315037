import { type EntitySelectors, type EntityState } from "@reduxjs/toolkit";
import { ListIteratee, Many, isFunction, orderBy } from "lodash";

import { type RootState } from "./bootstrap";

export const selectorsExtractId = (_: RootState, id: number | string) => id;
export const selectorsExtractIds = (_: RootState, ids: number[] | string[]) => ids;

export const selectorsIdsExtractSortIteratees = <T>(
  _: RootState,
  _ids: number[] | string[],
  sortIteratees?: Many<ListIteratee<T>>,
) => sortIteratees;

export const selectorsIdsExtractSortOrder = <T>(
  _: RootState,
  _ids: number[] | string[],
  _sortIteratees?: Many<ListIteratee<T>>,
  sortOrders?: Many<boolean | "asc" | "desc">,
) => sortOrders;

type Selectors<T> = EntitySelectors<T, EntityState<T>>;
type GetSelectorsFn<T> = () => EntitySelectors<T, EntityState<T>>;

export const selectorsOutputEntitiesByIds =
  <T extends Entity>(localSelectorsOrFn: Selectors<T> | GetSelectorsFn<T>) =>
  (
    state: EntityState<T>,
    ids?: number[] | string[],
    sortIteratees?: Many<ListIteratee<T>>,
    sortOrders?: Many<boolean | "asc" | "desc">,
  ) => {
    const entitySelectors = isFunction(localSelectorsOrFn) ? localSelectorsOrFn() : localSelectorsOrFn;
    const result = ids?.map(id => entitySelectors.selectById(state, id)).flatMap(_ => (_ ? [_] : [])) || [];
    return sortIteratees ? orderBy(result, sortIteratees, sortOrders) : result;
  };
