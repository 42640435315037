import { useCallback } from "react";
import { type Path } from "react-hook-form";

import { AggregatedValue } from "@ds/components/entities";
import { BooleanTriStateCheckbox, YesNoCheckbox } from "@ds/components/forms/inputs";
import { getNormalAndProxyFieldsMap, isMultipleFieldValuesInAggregatedEntity } from "@ds/utils/entities";
import { getProperty } from "@ds/utils/properties";

export const BooleanDisplayValue = <T extends Entity>({
  entity,
  field,
  isDirty,
  useTriStateCheckbox,
}: {
  entity: T;
  field: Path<T>;
  isDirty?: boolean;
  useTriStateCheckbox?: boolean;
}) => {
  const fieldsMap = getNormalAndProxyFieldsMap(entity, field);
  const fieldPath = isMultipleFieldValuesInAggregatedEntity(entity, fieldsMap.normal.path)
    ? `${fieldsMap.proxy.path}`
    : `${fieldsMap.normal.path}`;

  const getCheckboxElement = useCallback(
    (checkboxEntity: T, checkboxField: Path<T>) =>
      useTriStateCheckbox ? (
        <BooleanTriStateCheckbox isChecked={getProperty(checkboxEntity, checkboxField)} aria-disabled disabled />
      ) : (
        <YesNoCheckbox isChecked={getProperty(checkboxEntity, checkboxField)} />
      ),
    [useTriStateCheckbox],
  );

  return (
    <AggregatedValue
      entity={entity}
      field={fieldPath as Path<T>}
      formatter={getCheckboxElement}
      multipleFormatter={getCheckboxElement}
      shouldShowEditedValue={isDirty}
    />
  );
};
