import {
  cloneElement,
  useCallback,
  useEffect,
  useMemo,
  type FC,
  type MouseEvent,
  type ReactElement,
  type ReactNode,
} from "react";
import styled from "styled-components";

import { useVisibility } from "@ds/hooks/use-visibility";

import { useOnMouseEventPreventDefaultHandler } from "@ds/utils/handlers";

import { Button, ButtonType } from "../forms/buttons";
import { renderErrorMessage } from "../forms/labels";
import { UnassignedLabel } from "../forms/labels/common";
import { BaseValidationTitle, ValidationInputWrapper } from "../forms/labels/styles";
import { IconType } from "../icons";
import { type EditableDetailsCardRowChildProps } from "./editable-details-card-row-decorator";

export const DetailsCardRowWrapper = styled.div`
  min-height: 70px;
  display: flex;
  align-items: center;
  padding: 16px 0;
  border-bottom: 1px solid var(--textColor010);
`;

export const DetailsCardRowLabel = styled.div`
  height: 100%;
  min-width: 26%;
  display: flex;
  align-items: center;
  color: var(--textColor060);
  font-weight: 600;
`;

const DetailsCardRowValue = styled.div<{
  $showInlineEditControls: boolean;
}>`
  height: 100%;
  min-height: 35px;
  display: flex;
  flex-flow: column;
  flex-grow: 1;
  justify-content: center;
  padding-left: 5px;

  ${({ $showInlineEditControls }) =>
    $showInlineEditControls &&
    `&:hover {
      background-color: rgba(0, 0, 0, 0.02);
      border-radius: 7px;
      cursor: pointer;
      animation: inline-editing-hover 150ms ease-in forwards 0 alternate;
    }`}

  @keyframes inline-editing-hover {
    0% {
      transform: scale(1);
    }

    100% {
      transform: scale(1.01);
    }
  }
`;

const DetailsCardRowButtonsBlock = styled.div`
  min-width: 40px;
  display: flex;
  justify-content: flex-end;
`;

const InputWrapper = styled(ValidationInputWrapper)<{ width?: string }>`
  width: ${({ width }) => (width ? width : "400px")};

  .p-inputtext,
  .p-dropdown,
  .p-autocomplete > .p-autocomplete-input {
    width: 100%;
  }
`;

export const DetailsCardRow: FC<{
  label: string;
  editableSettings?: EditableDetailsCardRowChildProps;
  isRequired?: boolean;
  children?: ReactNode;
}> = ({ children, label, editableSettings, isRequired }) => {
  const [isEditEnabled, setIsEditEnabled] = useVisibility();
  const onMouseDownHandler = useOnMouseEventPreventDefaultHandler();

  const showInlineEditControls = useMemo(
    () => (!isEditEnabled && editableSettings?.input && !editableSettings.isEditHidden) || false,
    [isEditEnabled, editableSettings?.input, editableSettings?.isEditHidden],
  );

  useEffect(() => {
    if (editableSettings?.shouldEditWholeCard && !editableSettings.isEditHidden) {
      setIsEditEnabled(editableSettings.isEditing)();
    }
  }, [editableSettings, setIsEditEnabled]);

  const onEditClickHandler = useCallback(
    (e: MouseEvent) => {
      if (showInlineEditControls && (e.target as HTMLAnchorElement).tagName.toLowerCase() !== "a") {
        editableSettings?.onEdit();
        setIsEditEnabled(true)();
      }

      e.preventDefault();
    },
    [editableSettings, showInlineEditControls, setIsEditEnabled],
  );

  const onCancelClickHandler = useCallback(() => {
    editableSettings?.onCancel();
    setIsEditEnabled(false)();
  }, [editableSettings, setIsEditEnabled]);

  const onSaveClickHandler = useCallback(() => {
    editableSettings?.onSave();
    setIsEditEnabled(false)();
  }, [editableSettings, setIsEditEnabled]);

  const cardLabel = useMemo(() => {
    if (editableSettings?.shouldEditWholeCard && !isEditEnabled) {
      return null;
    }

    return (
      <DetailsCardRowLabel>
        <BaseValidationTitle $isRequired={!!isRequired}>{label}</BaseValidationTitle>
        {!isEditEnabled && editableSettings?.isDirty && (
          <Button
            ariaLabel="details undo button"
            buttonType={ButtonType.Round}
            icon={IconType.Undo}
            className="ml-auto mr-3"
            onClick={editableSettings?.onReset}
          />
        )}
      </DetailsCardRowLabel>
    );
  }, [isRequired, label, editableSettings, isEditEnabled]);

  const cardValue = useMemo(() => {
    if (isEditEnabled) {
      return (
        <DetailsCardRowValue $showInlineEditControls={showInlineEditControls}>
          <InputWrapper
            aria-required={!!isRequired}
            $isValid={!editableSettings?.error}
            width={editableSettings?.width}
          >
            {editableSettings?.isInlineEditingEnabled
              ? cloneElement(editableSettings?.input as ReactElement, {
                  isDisabled: editableSettings?.isEditHidden,
                  onBlur: onSaveClickHandler,
                })
              : cloneElement(editableSettings?.input as ReactElement, { isDisabled: editableSettings?.isEditHidden })}
          </InputWrapper>

          {renderErrorMessage(editableSettings?.error)}
        </DetailsCardRowValue>
      );
    }

    return (
      <DetailsCardRowValue $showInlineEditControls={showInlineEditControls} onMouseDown={onEditClickHandler}>
        {children || <UnassignedLabel />}
        {renderErrorMessage(editableSettings?.error)}
      </DetailsCardRowValue>
    );
  }, [
    children,
    isRequired,
    editableSettings,
    showInlineEditControls,
    isEditEnabled,
    onEditClickHandler,
    onSaveClickHandler,
  ]);

  return (
    <DetailsCardRowWrapper>
      {cardLabel}
      {cardValue}

      <DetailsCardRowButtonsBlock className="ml-auto">
        {showInlineEditControls && !editableSettings?.shouldEditWholeCard && (
          <Button
            ariaLabel="details edit button"
            buttonType={ButtonType.Round}
            icon={IconType.Update}
            onClick={onEditClickHandler}
            isDisabled={editableSettings?.isEditing}
            onMouseDown={onMouseDownHandler}
          />
        )}

        {isEditEnabled && (
          <Button
            ariaLabel="details cancel button"
            buttonType={ButtonType.Round}
            icon={IconType.Close}
            onClick={onCancelClickHandler}
            onMouseDown={onMouseDownHandler}
          />
        )}

        {isEditEnabled && !editableSettings?.isInlineEditingEnabled && (
          <Button
            ariaLabel="details save button"
            buttonType={ButtonType.Round}
            icon={IconType.Save}
            onClick={onSaveClickHandler}
            onMouseDown={onMouseDownHandler}
          />
        )}
      </DetailsCardRowButtonsBlock>
    </DetailsCardRowWrapper>
  );
};

export const EditableDetailsCardRow: FC<{
  label: string;
  editableSettings?: EditableDetailsCardRowChildProps;
  isRequired?: boolean;
  children?: ReactNode;
}> = ({ label, editableSettings, isRequired }) => {
  const [isEditEnabled, setIsEditEnabled] = useVisibility();
  const onMouseDownHandler = useOnMouseEventPreventDefaultHandler();

  const showInlineEditControls = useMemo(
    () => (!isEditEnabled && editableSettings?.input && !editableSettings.isEditHidden) || false,
    [isEditEnabled, editableSettings?.input, editableSettings?.isEditHidden],
  );

  useEffect(() => {
    if (editableSettings?.shouldEditWholeCard && !editableSettings.isEditHidden) {
      setIsEditEnabled(editableSettings.isEditing)();
    }
  }, [editableSettings, setIsEditEnabled]);

  const cardLabel = useMemo(() => {
    if (editableSettings?.shouldEditWholeCard && !isEditEnabled) {
      return null;
    }

    return (
      <DetailsCardRowLabel>
        <BaseValidationTitle $isRequired={!!isRequired}>{label}</BaseValidationTitle>
        {!isEditEnabled && editableSettings?.isDirty && (
          <Button
            ariaLabel="details undo button"
            buttonType={ButtonType.Round}
            icon={IconType.Undo}
            className="ml-auto mr-3"
            onClick={editableSettings?.onReset}
          />
        )}
      </DetailsCardRowLabel>
    );
  }, [isRequired, label, editableSettings, isEditEnabled]);

  const cardValue = useMemo(
    () => (
      <DetailsCardRowValue $showInlineEditControls={false}>
        <InputWrapper aria-required={!!isRequired} $isValid={!editableSettings?.error} width={editableSettings?.width}>
          {cloneElement(editableSettings?.input as ReactElement, { isDisabled: editableSettings?.isEditHidden })}
        </InputWrapper>

        {renderErrorMessage(editableSettings?.error)}
      </DetailsCardRowValue>
    ),
    [isRequired, editableSettings],
  );

  return (
    <DetailsCardRowWrapper>
      {cardLabel}
      {cardValue}

      <DetailsCardRowButtonsBlock className="ml-auto">
        {showInlineEditControls && !editableSettings?.shouldEditWholeCard && (
          <Button
            ariaLabel="details edit button"
            buttonType={ButtonType.Round}
            icon={IconType.Update}
            isDisabled={editableSettings?.isEditing}
            onMouseDown={onMouseDownHandler}
          />
        )}
      </DetailsCardRowButtonsBlock>
    </DetailsCardRowWrapper>
  );
};
