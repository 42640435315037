import prettyBytes from "pretty-bytes";
import { useCallback, type FC } from "react";
import styled from "styled-components";

import { DEFAULT_TABLE_TAB } from "@ds/modules/table-data/redux/slice";
import { MainTableDataTypeEnum } from "@ds/modules/table-data/utils/model";

import { DndDropzone } from "@ds/components/dnd-dropzone";
import { Loading } from "@ds/components/loaders/loading";
import { TableWithPagination } from "@ds/components/table/main-table";
import { Column, getEntityIdColumn, getSelectionColumn } from "@ds/components/table/table-columns";
import { TotalSelectedLabel } from "@ds/components/table/total-selected-label";
import { useDeepRootSelector, usePagination, useRootDispatch, useRootSelector, useTableDataSorting } from "@ds/hooks";

import {
  selectContentAssetsIsLoading,
  selectSelectedContentAssets,
  selectTableContentAssets,
} from "../redux/selectors/common-selectors";
import { ContentAssetsRemoteOperation, contentAssetsActions } from "../redux/slice";
import { createContentAssetFiltersByTableType, getSupportedFormatsByTableType } from "../utils/helpers";
import { ContentAssetThumbnail } from "./common/content-asset-thumbnail";

const TableWrapper = styled.div`
  height: 100%;
  .p-datatable-tbody tr {
    height: 150px;

    &.p-datatable-emptymessage {
      height: initial;
    }
  }
`;

const getPreviewBodyTemplate = (asset: ContentAsset) => <ContentAssetThumbnail asset={asset} />;
const getDescriptionBodyTemplate = (asset: ContentAsset) => (
  <div className="text-sm">
    <div className="flex align-items-center">
      <i className="pi pi-paperclip mr-2"></i>
      <div className="mt-1">
        <div className="font-medium">{asset.name}</div>
        <div className="text-xs font-italic">{asset.content_type}</div>
      </div>
    </div>
    <div className="mt-5 flex align-items-center">
      <i className="pi pi-info-circle mr-2"></i>
      <span>{prettyBytes(asset.file_size)}</span>
    </div>
  </div>
);

export const ContentAssetsTable: FC<{ tableType: MainTableDataTypeEnum; onUpload: (files: File[]) => void }> = ({
  tableType,
  onUpload,
}) => {
  const [selectedEntities, entities] = useDeepRootSelector(state => [
    selectSelectedContentAssets(state, tableType),
    selectTableContentAssets(state, tableType),
  ]);

  const isLoading = useRootSelector(selectContentAssetsIsLoading);
  const checkboxIsLoading = useRootSelector(state =>
    selectContentAssetsIsLoading(state, ContentAssetsRemoteOperation.CHECKBOXES),
  );

  const queryFilters = createContentAssetFiltersByTableType({ tableType });
  const [pagination, setPagination] = usePagination(tableType, DEFAULT_TABLE_TAB, queryFilters);
  const [{ sortOrder, sortField }, setSorting] = useTableDataSorting(tableType, DEFAULT_TABLE_TAB, queryFilters);

  const dispatch = useRootDispatch();
  const onToggleRowHandler = useCallback(
    (asset: ContentAsset) => dispatch(contentAssetsActions.toggleSelected(asset.id, tableType)),
    [dispatch, tableType],
  );

  const onSingleRowSelectHandler = useCallback(
    (asset: ContentAsset) => dispatch(contentAssetsActions.selectContentAssets([asset], tableType)),
    [dispatch, tableType],
  );

  const onToggleAllHandler = useCallback(
    (isSelectedAll: boolean) => {
      if (isSelectedAll) {
        dispatch(
          contentAssetsActions.fetchContentAssets(queryFilters, undefined, ContentAssetsRemoteOperation.CHECKBOXES),
        );
      } else {
        dispatch(contentAssetsActions.selectContentAssets([], tableType));
      }
    },
    [dispatch, queryFilters, tableType],
  );

  return (
    <Loading isLoading={isLoading}>
      <DndDropzone acceptMediaTypes={getSupportedFormatsByTableType(tableType)} onUpload={onUpload}>
        <TableWrapper>
          <TableWithPagination
            value={entities}
            selection={selectedEntities}
            onSingleRowSelect={onSingleRowSelectHandler}
            toggleRowSelected={onToggleRowHandler}
            loading={isLoading}
            sortField={sortField}
            sortOrder={sortOrder}
            onSort={setSorting}
            pagination={pagination}
            onPageChange={setPagination}
          >
            {getSelectionColumn({
              total: pagination.total,
              selected: selectedEntities.length,
              isLoading: checkboxIsLoading,
              onToggleAll: onToggleAllHandler,
            })}

            {getEntityIdColumn()}
            <Column body={getPreviewBodyTemplate} className="center w200" resizeable={false} />
            <Column body={getDescriptionBodyTemplate} resizeable={false} />
          </TableWithPagination>

          <TotalSelectedLabel selectedCount={checkboxIsLoading ? Number(pagination.total) : selectedEntities.length} />
        </TableWrapper>
      </DndDropzone>
    </Loading>
  );
};
