import { cloneDeep } from "lodash";
import { replace } from "redux-first-history";
import { call, put, race, select, take, takeLatest, takeLeading, type SagaReturnType } from "redux-saga/effects";

import { iotEventPlaylistUpdate } from "@ds/modules/iot/redux/actions";
import { toastShowErrorAction, toastShowSuccessAction } from "@ds/modules/notifications/redux/actions";
import { isIotTableEntityAddedOrDeleted, selectTableData } from "@ds/modules/table-data/redux/selectors";
import { convertToApiQueryInfo } from "@ds/modules/table-data/utils/common";
import { MainTableDataTypeEnum } from "@ds/modules/table-data/utils/model";

import { UnexpectedError } from "@ds/utils/errors";
import { logger } from "@ds/utils/logger";
import { UNLIMITED_PAGINATION } from "@ds/utils/query";
import { takeLatestOrEvery } from "@ds/utils/saga-helpers";
import { isErrorLike } from "@ds/utils/type-guards/error-guards";

import { playlistsService } from "../utils/api";
import { normalize } from "../utils/normalizer";
import { selectPlaylistById, selectPlaylistsByIds, selectTablePlaylists } from "./selectors";
import { PlaylistsRemoteOperation, playlistsActions } from "./slice";

function* fetchPlaylists({ meta }: ReturnType<typeof playlistsActions.fetchPlaylists>) {
  try {
    const { sorting, pagination, queryInfo, isFetchedAlready } = selectTableData(
      yield select(),
      meta.options.tableType || MainTableDataTypeEnum.ContentPlaylists,
      undefined,
      meta.filters,
    );

    const newMeta = cloneDeep(meta);
    const resultFromCache: QueryOutput<Playlist> = { items: [] };

    if (!newMeta.options.cache?.disableCache) {
      if (isFetchedAlready && newMeta.options.tableType) {
        resultFromCache.items = selectTablePlaylists(yield select(), newMeta.options.tableType);
        newMeta.options.cache = {
          ...newMeta.options.cache,
          fetchedFromCache: true,
        };
      } else if (newMeta.filters.id && Object.keys(newMeta.filters).length === 1) {
        resultFromCache.items = selectPlaylistsByIds(yield select(), newMeta.filters.id);
        if (resultFromCache.items.length === newMeta.filters.id.length) {
          newMeta.options.cache = {
            ...newMeta.options.cache,
            fetchedFromCache: true,
          };
        }
      }
    }

    let result: QueryOutput<Playlist> = newMeta.options.cache?.fetchedFromCache
      ? resultFromCache
      : yield call(
          [playlistsService, playlistsService.getPlaylists],
          convertToApiQueryInfo(sorting, queryInfo),
          newMeta.options.tableType ? pagination : UNLIMITED_PAGINATION,
        );

    result = newMeta.options.cache?.fetchedFromCache ? result : normalize(result);
    result = newMeta.options.tableType ? result : { items: result.items };

    yield put(playlistsActions.fetchPlaylistsSucceeded(result, newMeta));
  } catch (err) {
    const errorTitle = "Fetch playlists";
    if (isErrorLike(err)) {
      yield put(playlistsActions.fetchPlaylistsFailed(meta, err));
      yield put(toastShowErrorAction(err, errorTitle));
    } else {
      logger.error(`${errorTitle}: ${UnexpectedError}`);
      yield put(toastShowErrorAction(UnexpectedError, errorTitle));
    }
  }
}

function* fetchPlaylist({ payload, meta }: ReturnType<typeof playlistsActions.fetchPlaylist>) {
  try {
    const newMeta = cloneDeep(meta);
    let result = selectPlaylistById(yield select(), payload);
    if (result && !newMeta.options.cache?.disableCache) {
      newMeta.options.cache = {
        ...newMeta.options.cache,
        fetchedFromCache: true,
      };
    } else {
      result = yield call([playlistsService, playlistsService.getPlaylist], payload);
      result = normalize(result);
    }

    yield put(playlistsActions.fetchPlaylistSucceeded(result, newMeta));
  } catch (err) {
    const errorTitle = "Fetch Playlist";
    if (isErrorLike(err)) {
      yield put(playlistsActions.fetchPlaylistFailed(payload, meta, err));
      yield put(toastShowErrorAction(err, errorTitle));
    } else {
      logger.error(`${errorTitle}: ${UnexpectedError}`);
      yield put(toastShowErrorAction(UnexpectedError, errorTitle));
    }
  }
}

function* createPlaylist({ payload, meta }: ReturnType<typeof playlistsActions.createPlaylist>) {
  try {
    const result: SagaReturnType<typeof playlistsService.createPlaylist> = yield call(
      [playlistsService, playlistsService.createPlaylist],
      payload,
    );

    yield put(playlistsActions.createPlaylistSucceeded(normalize(result), meta));
    yield put(toastShowSuccessAction("Playlist was created successfully"));
    yield put(playlistsActions.selectPlaylists([result]));
  } catch (err) {
    const errorTitle = "Create playlist";
    if (isErrorLike(err)) {
      yield put(playlistsActions.createPlaylistFailed(payload, meta, err));
      yield put(toastShowErrorAction(err, errorTitle));
    } else {
      logger.error(`${errorTitle}: ${UnexpectedError}`);
      yield put(toastShowErrorAction(UnexpectedError, errorTitle));
    }
  }
}

function* updatePlaylists({ payload }: ReturnType<typeof playlistsActions.updatePlaylists>) {
  try {
    const result: SagaReturnType<typeof playlistsService.updatePlaylists> = yield call(
      [playlistsService, playlistsService.updatePlaylists],
      payload.ids,
      payload.data,
    );

    yield put(playlistsActions.updatePlaylistsSucceeded(normalize(result)));
    yield put(toastShowSuccessAction("Playlist(s) were updated successfully"));
  } catch (err) {
    const errorTitle = "Update playlist(s)";
    if (isErrorLike(err)) {
      yield put(playlistsActions.updatePlaylistsFailed(payload, err));
      yield put(toastShowErrorAction(err, errorTitle));
    } else {
      logger.error(`${errorTitle}: ${UnexpectedError}`);
      yield put(toastShowErrorAction(UnexpectedError, errorTitle));
    }
  }
}

function* deletePlaylists({ payload, meta }: ReturnType<typeof playlistsActions.deletePlaylists>) {
  try {
    yield call([playlistsService, playlistsService.deletePlaylists], payload);
    yield put(playlistsActions.deletePlaylistsSucceeded(payload, meta));
    yield put(toastShowSuccessAction("Playlist(s) were deleted successfully"));

    if (meta.options?.redirectTo) {
      yield put(replace(meta.options.redirectTo));
    }
  } catch (err) {
    const errorTitle = "Delete playlist(s)";
    if (isErrorLike(err)) {
      yield put(playlistsActions.deletePlaylistsFailed(payload, meta, err));
      yield put(toastShowErrorAction(err, errorTitle));
    } else {
      logger.error(`${errorTitle}: ${UnexpectedError}`);
      yield put(toastShowErrorAction(UnexpectedError, errorTitle));
    }
  }
}

function* refetchPlaylists({ payload }: ReturnType<typeof iotEventPlaylistUpdate>) {
  const isAddedOrDeleted = isIotTableEntityAddedOrDeleted(
    yield select(),
    payload,
    MainTableDataTypeEnum.ContentPlaylists,
  );

  if (isAddedOrDeleted) {
    yield call(
      fetchPlaylists,
      playlistsActions.fetchPlaylists(
        undefined,
        {
          tableType: MainTableDataTypeEnum.ContentPlaylists,
          cache: { disableCache: true },
        },
        PlaylistsRemoteOperation.IOT_FETCH,
      ),
    );
  }

  yield put(playlistsActions.iotUpdate(payload));
}

export function* watchPlaylists() {
  yield takeLatestOrEvery(playlistsActions.fetchPlaylists, function* (action) {
    yield race({
      task: call(fetchPlaylists, action),
      cancel: take([playlistsActions.selectPlaylists, playlistsActions.toggleSelected]),
    });
  });

  yield takeLatestOrEvery(playlistsActions.fetchPlaylist, fetchPlaylist);
  yield takeLeading(playlistsActions.createPlaylist, createPlaylist);
  yield takeLeading(playlistsActions.updatePlaylists, updatePlaylists);
  yield takeLeading(playlistsActions.deletePlaylists, deletePlaylists);
  yield takeLatest(iotEventPlaylistUpdate, refetchPlaylists);
}
