import { type FC } from "react";
import { Navigate, Route, Routes, useLocation } from "react-router-dom";

import { INVITATION_ID_PARAM, MULTIPLE_IDS_PARAM, SETTINGS_PROJECTS_ROUTE } from "@ds/constants/router";
import { useRootSelector } from "@ds/hooks";

import { selectCurrentUserIsTenantAdmin } from "../users/redux/selectors";
import { InvitationDetailsPage } from "./invitation-details-page";
import { InvitationsMainPage } from "./invitations-main-page";

const InvitationsRouteSwitch: FC<{ isAdmin: boolean }> = ({ isAdmin }) => {
  const { search } = useLocation();

  if (!isAdmin) {
    return <Navigate to={SETTINGS_PROJECTS_ROUTE} replace />;
  }

  return search.startsWith(`?${MULTIPLE_IDS_PARAM}`) ? <InvitationDetailsPage /> : <InvitationsMainPage />;
};

const InvitationsDetailsPageSwitch: FC<{ isAdmin: boolean }> = ({ isAdmin }) =>
  isAdmin ? <InvitationDetailsPage /> : <Navigate to={SETTINGS_PROJECTS_ROUTE} replace />;

export const InvitationsRouter: FC = () => {
  const isAdmin = useRootSelector(selectCurrentUserIsTenantAdmin);
  return (
    <Routes>
      <Route path="*" element={<InvitationsRouteSwitch isAdmin={isAdmin} />} />
      <Route path={`:${INVITATION_ID_PARAM}`} element={<InvitationsDetailsPageSwitch isAdmin={isAdmin} />} />
    </Routes>
  );
};
