import { type FC } from "react";
import styled from "styled-components";

import { ButtonType } from "@ds/components/forms/buttons";
import { IconType } from "@ds/components/icons";
import { DropdownButton } from "@ds/components/menu-dropdown/controls";
import { LoadStatus } from "@ds/utils/reducer";

const Screenshot = styled.img`
  transition: opacity 1s ease-in-out;
  width: 100%;
`;

const LoaderWrapper = styled.div`
  width: 100%;
  background: var(--textColor060);
  padding-top: 56.25%;
  position: relative;

  > div {
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    display: grid;
    place-items: center;
  }
`;

const LoaderText = styled.span`
  color: var(--passiveColor);
`;

export const renderLoader = (text: string) => (
  <LoaderWrapper>
    <div>
      <LoaderText>{text}</LoaderText>
    </div>
  </LoaderWrapper>
);

export const PlayerScreenshotOverlay: FC<{
  screenshot: string | null;
  status: LoadStatus;
  onShowMoreClick: () => void;
}> = ({ screenshot, status, onShowMoreClick }) => {
  if (status === LoadStatus.Idle) {
    return (
      <LoaderWrapper>
        <div>
          <DropdownButton
            label="Show more..."
            ariaLabel="screenshot button"
            buttonType={ButtonType.Raised}
            icon={IconType.Replay}
            onClick={onShowMoreClick}
          />
        </div>
      </LoaderWrapper>
    );
  }

  if (status === LoadStatus.Loading) {
    return renderLoader("Loading...");
  }

  return screenshot ? <Screenshot src={screenshot} alt="" /> : null;
};
