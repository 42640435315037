import * as yup from "yup";

import { type Shape } from "@ds/utils/validation";

import { TenantRoleEnum } from "./model";

const usernameShape = {
  username: yup.string().email("Invalid email format").required("Please specify user email"),
};

export const signinValidationSchema = yup.object().shape({
  ...usernameShape,
  password: yup.string().required("Please specify user password"),
});

export const forgotPasswordValidationSchema = yup.object().shape({
  ...usernameShape,
});

export const restorePasswordValidationSchema = signinValidationSchema.shape({
  code: yup.string().required("Please specify restore code"),
});

export const userValidationSchema = yup.object().shape({
  user: yup.object().shape({
    email: yup.string().email("Invalid email format").required("Please specify user email"),
    given_name: yup.string().required("Please specify user first name"),
    family_name: yup.string().required("Please specify user last name"),
  }),
  secrets: yup.object().shape({
    newPassword: yup.string(),
    newPasswordConfirmation: yup.string().oneOf([yup.ref("newPassword")], "Passwords does not match"),
  }),
});

export const changePasswordValidationSchema = {
  step1: yup.object().shape({
    newPassword: yup.string(),
    newPasswordConfirmation: yup.string().oneOf([yup.ref("newPassword")], "Passwords does not match"),
  }),
};

const projectsSchema = yup
  .array()
  .of(yup.object().shape<Shape<Project>>({}).required())
  .min(1, "Please specify at least one project");

export const updateValidationSchema = {
  getInfo: ({
    isUserModifyHimself,
    isUserModifyTenantOwner,
  }: {
    isUserModifyHimself: boolean;
    isUserModifyTenantOwner: boolean;
  }) =>
    yup.object().shape<Shape<User>>({
      given_name: yup.string().required("Please specify user first name"),
      family_name: yup.string().required("Please specify user last name"),
      role: yup
        .string()
        .required("Please choose user role from the list")
        .test("isUserModifyHimself", (selectedRole, ctx) =>
          isUserModifyHimself && selectedRole === TenantRoleEnum.TenantUser
            ? ctx.createError({
                message: "Cannot change role to TenantUser for yourself",
              })
            : true,
        )
        .test("isUserModifyTenantOwner", (selectedRole, ctx) =>
          isUserModifyTenantOwner && selectedRole === TenantRoleEnum.TenantUser
            ? ctx.createError({
                message: "Cannot change role to TenantUser for tenant owner",
              })
            : true,
        ),
    }),
  notifications: yup.object().shape<Shape<User>>({}),
  projects: yup.object().shape<Shape<{ projects: Project[] }>>({
    projects: projectsSchema,
  }),
};
