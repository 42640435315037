import { useCallback, type FC } from "react";
import styled from "styled-components";

import { useGetAggregatedEntityIds } from "@ds/hooks/use-get-aggregated-entity-ids";
import { useVisibility } from "@ds/hooks/use-visibility";

import { isAggregatedEntity } from "@ds/model/entity-model";

import { deploymentsActions, DeploymentsRemoteOperation } from "@ds/modules/deployments/redux/slice";
import { createDeploymentModel, DeploymentScopeEnum } from "@ds/modules/deployments/utils/model";

import { ActionsBar } from "@ds/components/actions-bar";
import { Button, ButtonActionType, ButtonType } from "@ds/components/forms/buttons";
import { IconType } from "@ds/components/icons";
import { MenuWithButton } from "@ds/components/menu-dropdown/menu-with-button";
import { confirmWarning } from "@ds/components/popups/confirmation-dialog";
import { DEVICE_PLAYERS_ROUTE } from "@ds/constants/router";
import { useRootDispatch, useRootSelector } from "@ds/hooks";
import { pluralizeDeleteMessage } from "@ds/utils/localization/common";
import {
  pluralizeDeployDescription,
  pluralizeDeployMessage,
  pluralizeDeployName,
} from "@ds/utils/localization/deployment";

import {
  cecAllOffCommand,
  cecAllOnCommand,
  createCommandMenuItem,
  logsCommandMenuItem,
  rebootCommandMenuItem,
  resetContentMenuItem,
} from "../../constants/actions";
import { areSelectedPlayersHaveTheSameAppletType, selectPlayersByIds } from "../../redux/selectors";
import { playersActions } from "../../redux/slice";
import { createCommandModel } from "../../utils/command-model";
import { PlayerStateEnum } from "../../utils/model";
import { PairPlayerModal } from "../forms/pair-player-form/pair-player-modal";

const Wrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

export const PlayerDetailsInfoCardActionsMenu: FC<{ player: Player }> = ({ player }) => {
  const aggregatedPlayerIds = useGetAggregatedEntityIds(player);
  const aggregatedPlayers = useRootSelector(state => selectPlayersByIds(state, aggregatedPlayerIds));
  const canShowAppletCommands = useRootSelector(areSelectedPlayersHaveTheSameAppletType);

  const [isPairModalVisible, setIsPairModalVisible] = useVisibility();

  const dispatch = useRootDispatch();
  const onDeployClickHandler = useCallback(
    () =>
      confirmWarning({
        message: pluralizeDeployMessage("player", aggregatedPlayerIds.length),
        accept: () =>
          dispatch(
            deploymentsActions.createDeployment(
              {
                ...createDeploymentModel(),
                name: pluralizeDeployName(aggregatedPlayers),
                description: pluralizeDeployDescription(aggregatedPlayers),
                scope: DeploymentScopeEnum.Device,
                targets: aggregatedPlayerIds,
              },
              { remoteOperation: DeploymentsRemoteOperation.DEPLOYING },
            ),
          ),
      }),
    [dispatch, aggregatedPlayerIds, aggregatedPlayers],
  );

  const onDeleteClickHandler = useCallback(
    () =>
      confirmWarning({
        message: pluralizeDeleteMessage("player", aggregatedPlayerIds.length),
        accept: () => dispatch(playersActions.deletePlayers(aggregatedPlayerIds, DEVICE_PLAYERS_ROUTE)),
      }),
    [dispatch, aggregatedPlayerIds],
  );

  const onCommandSelectHandler = useCallback(
    (options: SendCommand) => {
      dispatch(playersActions.stopFetchingScreenshots());
      dispatch(
        playersActions.sendCommandsToPlayers(
          aggregatedPlayers.map(({ id }) => createCommandModel({ ...options, device_id: id })),
        ),
      );
    },
    [dispatch, aggregatedPlayers],
  );

  const isNotOnline = player.state !== PlayerStateEnum.Online;
  return (
    <Wrapper>
      <ActionsBar>
        <MenuWithButton
          menuItems={[
            {
              label: "Pair Device",
              icon: "pi pi-fw pi-sort-alt",
              disabled: isAggregatedEntity(player) || player.state !== PlayerStateEnum.Unpaired,
              command: setIsPairModalVisible(true),
            },
            {
              label: "Deploy Content",
              icon: "pi pi-fw pi-sitemap",
              disabled: aggregatedPlayers.some(({ state }) => state === PlayerStateEnum.Unpaired),
              command: onDeployClickHandler,
            },
            { separator: true },
            rebootCommandMenuItem(onCommandSelectHandler, isNotOnline, aggregatedPlayerIds.length),
            logsCommandMenuItem(onCommandSelectHandler, isNotOnline, aggregatedPlayerIds.length),
            resetContentMenuItem(onCommandSelectHandler, isNotOnline, aggregatedPlayerIds.length),
            { separator: true },
            createCommandMenuItem(
              "cec",
              "CEC On",
              "pi pi-fw pi-window-maximize",
              cecAllOnCommand,
              onCommandSelectHandler,
              !canShowAppletCommands || isNotOnline,
              aggregatedPlayerIds.length,
            ),
            createCommandMenuItem(
              "cec",
              "CEC Off",
              "pi pi-fw pi-window-minimize",
              cecAllOffCommand,
              onCommandSelectHandler,
              !canShowAppletCommands || isNotOnline,
              aggregatedPlayerIds.length,
            ),
          ]}
        />

        <Button
          label="Delete"
          ariaLabel="delete button"
          buttonType={ButtonType.Text}
          actionType={ButtonActionType.Error}
          icon={IconType.Delete}
          onClick={onDeleteClickHandler}
        />
      </ActionsBar>

      {isPairModalVisible && <PairPlayerModal initialValue={player} onClose={setIsPairModalVisible(false)} />}
    </Wrapper>
  );
};
