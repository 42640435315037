import { useEffect, type FC } from "react";
import { useNavigate } from "react-router-dom";
import styled from "styled-components";

import { useRootDispatch, useRootSelector } from "@ds/hooks/redux-hooks";

import { selectCurrentUser } from "@ds/modules/settings/users/redux/selectors";

import { Loading } from "@ds/components/loaders/loading";
import { SIGN_IN_ROUTE } from "@ds/constants/router";
import { useUrlQueryParams } from "@ds/hooks";

import { selectAuthIsLoading, selectCognitoUserIsSignedIn, selectInvitedUser } from "../../redux/selectors";
import { AuthRemoteOperation, authActions } from "../../redux/slice";
import { UserSignUpForm } from "./user-sign-up-form";

const Wrapper = styled.div`
  width: 100vw;
  height: 100vh;
`;

export const UserSignUp: FC = () => {
  const navigate = useNavigate();
  const token = useUrlQueryParams("token")[0];

  const isLoading = useRootSelector(state => selectAuthIsLoading(state, AuthRemoteOperation.INVITE));
  const isCognitoUserSignedIn = useRootSelector(selectCognitoUserIsSignedIn);
  const invitedUserEmail = useRootSelector(selectInvitedUser)?.email;
  const currentUserEmail = useRootSelector(selectCurrentUser)?.email;

  const dispatch = useRootDispatch();
  useEffect(() => {
    if (!token || (currentUserEmail && currentUserEmail === invitedUserEmail)) {
      navigate(SIGN_IN_ROUTE, { replace: true });
    } else {
      dispatch(authActions.fetchInvitationInfo(token));
    }
  }, [dispatch, token, isCognitoUserSignedIn, invitedUserEmail, currentUserEmail, navigate]);

  return <Wrapper>{isLoading ? <Loading isLoading={isLoading} /> : <UserSignUpForm />}</Wrapper>;
};
