import {
  isArray as lodashIsArray,
  isFinite as lodashIsFinite,
  isNumber as lodashIsNumber,
  isPlainObject as lodashIsPlainObject,
  isString as lodashIsString,
} from "lodash";

export const isPlainObject = (obj: unknown): obj is Record<string, unknown> => lodashIsPlainObject(obj);

export const isFiniteNumber = (obj: unknown): obj is number => lodashIsFinite(obj as number);

export const isInfiniteNumber = (obj: unknown): obj is number => lodashIsNumber(obj) && !lodashIsFinite(obj);

export const isNumberArray = (obj: unknown): obj is number[] => lodashIsArray(obj) && obj.every(lodashIsNumber);

export const isStringArray = (obj: unknown): obj is string[] => lodashIsArray(obj) && obj.every(lodashIsString);
