import { useCallback, type FC } from "react";

import { useGetAggregatedEntityIds } from "@ds/hooks/use-get-aggregated-entity-ids";

import { ActionsBar } from "@ds/components/actions-bar";
import { Button, ButtonActionType, ButtonType } from "@ds/components/forms/buttons";
import { IconType } from "@ds/components/icons";
import { confirmWarning } from "@ds/components/popups/confirmation-dialog";
import { CONTENT_PLAYLISTS_ROUTE } from "@ds/constants/router";
import { useRootDispatch } from "@ds/hooks";
import { pluralizeDeleteMessage } from "@ds/utils/localization/common";

import { playlistsActions } from "../../redux/slice";

export const PlaylistDetailsInfoCardActionsMenu: FC<{ playlist: Playlist }> = ({ playlist }) => {
  const aggregatedPlaylistIds = useGetAggregatedEntityIds(playlist);

  const dispatch = useRootDispatch();
  const onDeleteClickHandler = useCallback(
    () =>
      confirmWarning({
        message: pluralizeDeleteMessage("playlist", aggregatedPlaylistIds.length),
        accept: () => dispatch(playlistsActions.deletePlaylists(aggregatedPlaylistIds, CONTENT_PLAYLISTS_ROUTE)),
      }),
    [dispatch, aggregatedPlaylistIds],
  );

  return (
    <ActionsBar>
      <Button
        label="Delete"
        ariaLabel="delete button"
        buttonType={ButtonType.Text}
        actionType={ButtonActionType.Error}
        icon={IconType.Delete}
        onClick={onDeleteClickHandler}
      />
    </ActionsBar>
  );
};
