import { useMemo, type FC } from "react";

import { useCurrentView } from "@ds/hooks/use-current-view";
import { useVisibility } from "@ds/hooks/use-visibility";

import { EntityTypeName } from "@ds/model/entity-model";

import { MainView } from "@ds/components/main-view";
import { TableTabMenu } from "@ds/components/table/table-tab-menu";

import { getPageTitleByTableDataType } from "../table-data/utils/helpers";
import { MainTableDataTypeEnum } from "../table-data/utils/model";
import { ExperiencesTableActionsMenu } from "./components/common/experiences-table-actions-menu";
import { ExperienceInfo } from "./components/experience-info";
import { ExperiencesTable } from "./components/experiences-table";
import { CreateExperienceModal } from "./components/forms/create-experience-form/create-experience-modal";

export const ExperiencesMainPage: FC = () => {
  const [currentView] = useCurrentView();
  const [isCreateModalVisible, setIsCreateModalVisible] = useVisibility();

  const filterOptions = useMemo(
    () => ({
      entityType: EntityTypeName.EXPERIENCE,
      tableType: MainTableDataTypeEnum.Experiences,
    }),
    [],
  );

  if (!currentView) {
    return null;
  }

  return (
    <>
      <MainView
        title={getPageTitleByTableDataType(filterOptions.tableType)}
        filterOptions={filterOptions}
        options={<TableTabMenu tableType={filterOptions.tableType} currentView={currentView} />}
        view={<ExperiencesTable />}
        info={<ExperienceInfo onCreateExperience={setIsCreateModalVisible(true)} />}
        actions={<ExperiencesTableActionsMenu onCreate={setIsCreateModalVisible(true)} />}
      />

      {isCreateModalVisible && <CreateExperienceModal onClose={setIsCreateModalVisible(false)} />}
    </>
  );
};
