import { createSelector } from "@reduxjs/toolkit";

import { DetailsTableDataTypeEnum, MainTableDataTypeEnum } from "@ds/modules/table-data/utils/model";

import { type RootState } from "@ds/store/bootstrap";
import {
  selectorsExtractIds,
  selectorsIdsExtractSortIteratees,
  selectorsIdsExtractSortOrder,
  selectorsOutputEntitiesByIds,
} from "@ds/store/global-selectors";

import { getAggregatedModel } from "@ds/utils/entities";
import { LoadStatus } from "@ds/utils/reducer";

import { DevicePlayersRemoteOperation, entitySelectors } from "./slice";

const selectBaseState = (state: RootState) => state.devices.players;
const selectLoadStatus = (state: RootState, operation = DevicePlayersRemoteOperation.CRUD) =>
  selectBaseState(state).loadStatusMap[operation];

export const selectPlayersIsLoading = (state: RootState, operation = DevicePlayersRemoteOperation.CRUD) =>
  selectLoadStatus(state, operation) === LoadStatus.Loading;

export const selectPlayersIsFailed = (state: RootState, operation = DevicePlayersRemoteOperation.CRUD) =>
  selectLoadStatus(state, operation) === LoadStatus.Failed;

export const selectPlayersIsSucceeded = (state: RootState, operation = DevicePlayersRemoteOperation.CRUD) =>
  selectLoadStatus(state, operation) === LoadStatus.Succeeded;

export const selectPlayersError = (state: RootState) => selectBaseState(state).error;

export const selectTablePlayerIds = (
  state: RootState,
  tableType: MainTableDataTypeEnum | DetailsTableDataTypeEnum = MainTableDataTypeEnum.DevicePlayers,
) => selectBaseState(state).tableIds[tableType];

export const selectSelectedPlayerIds = (
  state: RootState,
  tableType: MainTableDataTypeEnum | DetailsTableDataTypeEnum = MainTableDataTypeEnum.DevicePlayers,
) => selectBaseState(state).selectedIds[tableType];

export const selectPlayerById = (state: RootState, id: number) =>
  entitySelectors.selectById(selectBaseState(state), id) as Player;

export const selectPlayerIds = (state: RootState) => entitySelectors.selectIds(selectBaseState(state));
export const selectPlayers = (state: RootState) => entitySelectors.selectAll(selectBaseState(state));

export const selectPlayersByIds = createSelector(
  selectBaseState,
  selectorsExtractIds,
  selectorsIdsExtractSortIteratees,
  selectorsIdsExtractSortOrder,
  selectorsOutputEntitiesByIds(() => entitySelectors),
);

export const selectTablePlayers = createSelector(
  selectBaseState,
  selectTablePlayerIds,
  selectorsOutputEntitiesByIds(() => entitySelectors),
);

export const selectSelectedPlayers = createSelector(
  selectBaseState,
  selectSelectedPlayerIds,
  selectorsOutputEntitiesByIds(() => entitySelectors),
);

export const selectAggregatedPlayerByIds = createSelector(selectBaseState, selectorsExtractIds, (state, ids) =>
  ids?.length ? getAggregatedModel(selectorsOutputEntitiesByIds(entitySelectors)(state, ids)) : null,
);

export const selectAggregatedPlayerForSelectedEntities = createSelector(selectSelectedPlayers, selectedPlayers =>
  getAggregatedModel(selectedPlayers),
);

export const areSelectedPlayersHaveTheSameAppletType = createSelector(
  selectSelectedPlayers,
  selectAggregatedPlayerForSelectedEntities,
  (selectedPlayers, aggregatedPlayer) =>
    selectedPlayers.every(({ applet_type }) => aggregatedPlayer && applet_type === aggregatedPlayer.applet_type),
);

export const selectLastPairedPlayer = (state: RootState) =>
  selectPlayerById(state, selectBaseState(state).lastPairedPlayerId || 0);

export const selectCommandByPlayerId = (state: RootState, playerId: number) =>
  selectBaseState(state).lastCommandByPlayerId[playerId];

export const selectScreenshot = (state: RootState) => selectBaseState(state).screenshot;

export const selectScreenshotStatus = (state: RootState) => selectBaseState(state).screenshotStatus;
