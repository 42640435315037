import { orderBy } from "lodash";
import { Checkbox as PrimeCheckbox, type CheckboxProps as PrimeCheckboxProps } from "primereact/checkbox";
import {
  MultiStateCheckbox as PrimeMultiStateCheckbox,
  type MultiStateCheckboxChangeEvent,
  type MultiStateCheckboxProps as PrimeMultiStateCheckboxProps,
} from "primereact/multistatecheckbox";
import { forwardRef, useCallback, useEffect, useRef, type FC } from "react";
import styled, { css } from "styled-components";

import { BaseInputMixin } from "./styles";

export type { CheckboxChangeEvent } from "primereact/checkbox";

export const CheckBoxStylesMixin = css<{ disabled?: boolean }>`
  text-align: center;
  vertical-align: middle;
  cursor: ${({ disabled }) => (disabled ? "auto" : "pointer")};

  .p-checkbox-icon {
    height: 14px;
    width: 14px;
  }

  .p-checkbox-box {
    ${BaseInputMixin}

    &.p-highlight {
      border-color: var(--primaryColor080);
      background-color: var(--primaryColor080);
    }
  }

  &[disabled],
  &[aria-disabled="true"],
  &.p-checkbox-disabled {
    cursor: auto;
  }
`;

const StyledCheckbox = styled(PrimeCheckbox)`
  ${CheckBoxStylesMixin}
`;

const StyledMultiStateCheckbox = styled(PrimeMultiStateCheckbox)`
  ${CheckBoxStylesMixin}
`;

const YesNoOption = styled.span`
  width: fit-content;
  padding: 0 5px;
  border-radius: 3px;
`;

const YesOption = styled(YesNoOption)`
  color: var(--green-color);
  background: var(--green-background-color);
`;

const NoOption = styled(YesNoOption)`
  color: var(--red-color);
  background: var(--pink-background-color);
`;

const MixedOption = styled(YesNoOption)`
  color: var(--orange-color);
  background: var(--orange-background-color);
`;

export const YesNoCheckbox: FC<{ isChecked: boolean | null | undefined }> = ({ isChecked: value }) => {
  if (value === null || value === undefined) {
    return <MixedOption>mixed</MixedOption>;
  }

  return value ? <YesOption>yes</YesOption> : <NoOption>no</NoOption>;
};

export const Checkbox = forwardRef<HTMLInputElement, PrimeCheckboxProps>((props, ref) => (
  <StyledCheckbox inputRef={ref} {...props} />
));

export enum TriStateCheckboxValue {
  PARTIAL = "partial",
  ALL = "all",
}

const getTriStateOptions = () =>
  orderBy(
    [
      { value: TriStateCheckboxValue.PARTIAL, icon: "pi pi-minus", order: 1 },
      { value: TriStateCheckboxValue.ALL, icon: "pi pi-check", order: 2 },
    ],
    "order",
  );

export const TriStateCheckbox = forwardRef<
  PrimeMultiStateCheckbox,
  Omit<PrimeMultiStateCheckboxProps, "optionValue" | "options">
>(({ value, autoFocus, onChange, ...rest }, ref) => (
  <StyledMultiStateCheckbox
    ref={ref}
    optionValue="value"
    options={getTriStateOptions()}
    value={value}
    autoFocus={autoFocus}
    onChange={onChange}
    {...rest}
  />
));

export const BooleanTriStateCheckbox = forwardRef<
  PrimeMultiStateCheckbox,
  {
    isChecked: boolean | null | undefined;
    autoFocus?: boolean;
    onChange?: (value?: boolean) => void;
  } & Omit<PrimeMultiStateCheckboxProps, "value" | "onChange">
>(({ isChecked, autoFocus, onChange, ...rest }, ref) => {
  const checkboxRef = useRef<PrimeMultiStateCheckbox | null>(null);
  useEffect(() => {
    const checkboxElement = checkboxRef.current?.getElement();
    if (checkboxElement && autoFocus) {
      checkboxElement.tabIndex = 0;
      checkboxElement.focus();
    }
  }, [checkboxRef, autoFocus]);

  let triStateCheckboxValue: TriStateCheckboxValue | null = null;
  if (isChecked === false) {
    triStateCheckboxValue = null;
  } else if (isChecked === true) {
    triStateCheckboxValue = TriStateCheckboxValue.ALL;
  } else if (isChecked === null || isChecked === undefined) {
    triStateCheckboxValue = TriStateCheckboxValue.PARTIAL;
  }

  const onChangeHandler = useCallback(
    ({ value: newTriStateCheckBoxValue }: MultiStateCheckboxChangeEvent) => {
      if (onChange) {
        let newCheckboxValue = newTriStateCheckBoxValue;
        if (newTriStateCheckBoxValue === null) {
          newCheckboxValue = false;
        } else if (newTriStateCheckBoxValue === TriStateCheckboxValue.ALL) {
          newCheckboxValue = true;
        } else if (newTriStateCheckBoxValue === TriStateCheckboxValue.PARTIAL) {
          newCheckboxValue = null;
        }

        onChange(newCheckboxValue);
      }
    },
    [onChange],
  );

  return (
    <TriStateCheckbox
      ref={v => {
        if (typeof ref === "function") {
          ref(v);
        }

        checkboxRef.current = v;
      }}
      value={triStateCheckboxValue}
      onChange={onChangeHandler}
      autoFocus={autoFocus}
      {...rest}
    />
  );
});
