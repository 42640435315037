import { yupResolver } from "@hookform/resolvers/yup";
import { uniq } from "lodash";
import { useCallback, useEffect, type FC } from "react";
import { type DeepPartial } from "react-hook-form";

import { useGetAggregatedEntityIds } from "@ds/hooks/use-get-aggregated-entity-ids";

import { Wrapper } from "@ds/modules/experiences/components/details/experience-details-players-card";
import {
  selectProjectsByIds,
  selectProjectsIsLoading,
} from "@ds/modules/settings/projects/redux/selectors/common-selectors";
import { projectsActions } from "@ds/modules/settings/projects/redux/slice";

import {
  DetailsCard,
  DetailsCardEditActionsMenu,
  DetailsCardRow,
  EditableDetailsCardDecorator,
  EditableDetailsCardRowDecorator,
  type EditableDetailsCardChildProps,
} from "@ds/components/details-view";
import { MultipleValuesLabelWithLink, UnassignedLabel } from "@ds/components/forms/labels/common";
import { Loading } from "@ds/components/loaders/loading";
import { TextLink } from "@ds/components/text-link";
import { useDeepRootSelector, useRootDispatch, useRootSelector } from "@ds/hooks";
import { isMultipleFieldValuesInAggregatedEntity } from "@ds/utils/entities";
import { composeDetailsRouteByEntityType } from "@ds/utils/router";

import { selectInvitationsByIds } from "../../redux/selectors";
import { updateValidationSchema } from "../../utils/validation-schema";
import { InvitationProjectsControl } from "../controls/invitation-projects-control";

export const InvitationDetailsProjectsCard: FC<{
  invitation: Invitation;
  onSubmit: (changedFields: DeepPartial<Invitation>) => void;
}> = ({ invitation, onSubmit }) => {
  const aggregatedInvitationIds = useGetAggregatedEntityIds(invitation);
  const [aggregatedInvitationProjectsIds, aggregatedInvitationProjects] = useDeepRootSelector(state => {
    const aggregatedInvitations = selectInvitationsByIds(state, aggregatedInvitationIds);
    const aggregatedProjectsIds = uniq(aggregatedInvitations.flatMap(({ project_ids }) => project_ids));
    return [aggregatedProjectsIds, selectProjectsByIds(state, aggregatedProjectsIds)] as const;
  });

  const isLoading = useRootSelector(selectProjectsIsLoading);

  const dispatch = useRootDispatch();
  useEffect(() => {
    if (aggregatedInvitationProjectsIds.length) {
      dispatch(projectsActions.fetchProjects({ id: aggregatedInvitationProjectsIds }));
    }
  }, [dispatch, aggregatedInvitationProjectsIds]);

  const onSubmitHandler = ({ projects }: DeepPartial<InvitationFormData>) =>
    onSubmit({ project_ids: projects?.map(project => project?.id).filter(_ => _) });

  const detailsBody = useCallback(
    (cardProps: EditableDetailsCardChildProps<InvitationFormData>) => {
      if (isMultipleFieldValuesInAggregatedEntity(cardProps.entity.invitation, "project_ids") && !cardProps.isDirty) {
        return <MultipleValuesLabelWithLink to={composeDetailsRouteByEntityType(aggregatedInvitationProjects)} />;
      }

      if (
        !isMultipleFieldValuesInAggregatedEntity(cardProps.entity.invitation, "project_ids") &&
        !cardProps.entity.projects?.length &&
        !cardProps.isEditing
      ) {
        return <UnassignedLabel text="Invitation(s) has no projects" />;
      }

      return (
        <>
          {cardProps.entity.projects?.map(entry => (
            <Wrapper key={`invitation-details-project-${entry?.id}`}>
              <TextLink to={composeDetailsRouteByEntityType(entry)}>{entry.name}</TextLink>
            </Wrapper>
          ))}
        </>
      );
    },
    [aggregatedInvitationProjects],
  );

  return (
    <EditableDetailsCardDecorator
      initialValue={{ invitation, projects: aggregatedInvitationProjects }}
      validationResolver={yupResolver(updateValidationSchema.projects)}
      onSubmit={onSubmitHandler}
    >
      {cardProps => (
        <DetailsCard
          title="Projects"
          icon="pi pi-list"
          actions={
            <DetailsCardEditActionsMenu
              isEditing={cardProps.isEditing}
              isDirty={cardProps.isDirty}
              onEdit={cardProps.onEditRow("projects")}
              onReset={cardProps.onResetForm}
              onSubmit={cardProps.handleSubmit(cardProps.onSubmitForm)}
            />
          }
        >
          <Loading isLoading={isLoading}>
            <EditableDetailsCardRowDecorator
              field="projects"
              input={<InvitationProjectsControl control={cardProps.control} autoFocus excludeLabel />}
              shouldEditWholeCard
              {...cardProps}
            >
              {rowProps => (
                <DetailsCardRow label="Choose projects" editableSettings={rowProps}>
                  {detailsBody(cardProps)}
                </DetailsCardRow>
              )}
            </EditableDetailsCardRowDecorator>
          </Loading>
        </DetailsCard>
      )}
    </EditableDetailsCardDecorator>
  );
};
