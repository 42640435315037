import { type FC } from "react";
import { Controller, type Control } from "react-hook-form";
import styled from "styled-components";

import { assetMediaTypes } from "@ds/modules/content/utils/constants";

import { useDetailsInlineSaving } from "@ds/components/details-view/hooks";
import { Checkbox, type CheckboxChangeEvent } from "@ds/components/forms/inputs";
import { SpanWrapper } from "@ds/components/forms/labels/span-wrapper";
import { useOnMouseEventPreventDefaultHandler } from "@ds/utils/handlers";

const CheckboxGroupWrapper = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  margin-bottom: 10px;
`;

const CheckboxWrapper = styled.label`
  display: flex;
  align-items: center;
  margin-top: 12px;
`;

const WrappedCheckbox = styled(Checkbox)`
  margin-right: 1rem;
`;

const WrappedCheckBoxDescription = styled.span`
  display: flex;
  flex-flow: column;
`;

export const CONTENT_ASSET_MEDIA_TYPE_CHECKBOX_LABEL = "content-asset-media-type-checkbox";

const CheckboxGroup: FC<{
  currentMediaTypes: string[];
  mediaMap: Record<string, string[]>;
  autoFocus?: boolean;
  onChange: (mediaTypes: string[]) => void;
}> = ({ currentMediaTypes, mediaMap, autoFocus, onChange }) => (
  <div>
    {Object.entries(mediaMap).map(([mediaType, extensions]) => (
      <CheckboxWrapper key={`content-asset-media-type-${mediaType}`}>
        <WrappedCheckbox
          aria-label={CONTENT_ASSET_MEDIA_TYPE_CHECKBOX_LABEL}
          value={mediaType}
          checked={currentMediaTypes.includes(mediaType)}
          autoFocus={autoFocus}
          onChange={(e: CheckboxChangeEvent) => {
            onChange(
              e.checked
                ? currentMediaTypes.concat(e.target.value)
                : currentMediaTypes.filter((entry: string) => entry !== e.target.value),
            );
          }}
        />

        <WrappedCheckBoxDescription>
          <div className="font-medium">{mediaType}</div>
          {extensions.join(", ")}
        </WrappedCheckBoxDescription>
      </CheckboxWrapper>
    ))}
  </div>
);

export const PlaylistMediaTypesControl: FC<{
  control: Control<Playlist>;
  autoFocus?: boolean;
  excludeLabel?: boolean;
  onBlur?: () => void;
}> = ({ control, autoFocus, excludeLabel, onBlur }) => {
  const onBlurHandler = useDetailsInlineSaving(onBlur);
  const onMouseDownHandler = useOnMouseEventPreventDefaultHandler();

  return (
    <Controller
      name="media_types"
      control={control as Control<Playlist>}
      render={({ field: { value, onChange, onBlur: onBlurFn }, fieldState }) => {
        const element = (
          <CheckboxGroupWrapper onBlur={onBlurHandler(onBlurFn)} onMouseDown={onMouseDownHandler}>
            <CheckboxGroup
              currentMediaTypes={value}
              mediaMap={assetMediaTypes.video}
              autoFocus={autoFocus}
              onChange={onChange}
            />

            <CheckboxGroup currentMediaTypes={value} mediaMap={assetMediaTypes.image} onChange={onChange} />
            <CheckboxGroup currentMediaTypes={value} mediaMap={assetMediaTypes.audio} onChange={onChange} />
          </CheckboxGroupWrapper>
        );

        if (excludeLabel) {
          return element;
        }

        return (
          <SpanWrapper label="Media Types" className="col-8" error={fieldState.error} isRequired>
            {element}
          </SpanWrapper>
        );
      }}
    />
  );
};
