import { type FC } from "react";
import styled from "styled-components";

import { ActionsBar } from "@ds/components/actions-bar";

import { Button, ButtonType } from "../forms/buttons";
import { IconType } from "../icons";

const Wrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

export const DetailsCardEditActionsMenu: FC<{
  isEditing: boolean;
  isDirty: boolean;
  isEditHidden?: boolean;
  onEdit?: () => void;
  onReset: () => void;
  onSubmit: () => void;
}> = ({ isEditing, isDirty, isEditHidden, onEdit, onReset, onSubmit }) => (
  <Wrapper>
    <ActionsBar>
      {onEdit && !isDirty && !isEditing && !isEditHidden && (
        <Button
          label="Edit"
          ariaLabel="details edit button"
          buttonType={ButtonType.Text}
          icon={IconType.Update}
          onClick={onEdit}
        />
      )}

      {isDirty && (
        <Button
          label="Reset"
          ariaLabel="details reset button"
          buttonType={ButtonType.Text}
          icon={IconType.Undo}
          onClick={onReset}
        />
      )}

      {isDirty && (
        <Button
          label="Save"
          ariaLabel="details save button"
          buttonType={ButtonType.Text}
          icon={IconType.Save}
          onClick={onSubmit}
        />
      )}
    </ActionsBar>
  </Wrapper>
);
