import { type FC } from "react";

import { InfoView, InfoViewCallToAction } from "@ds/components/info-view";
import { getInfoViewTitle } from "@ds/components/info-view/helpers";
import { useDeepRootSelector } from "@ds/hooks";

import { selectAggregatedUserForSelectedEntities } from "../redux/selectors";
import { UserInfoSection } from "./common/user-info-section";
import { UserNotificationsInfoSection } from "./common/user-notifications-info-section";
import { UserProjectsInfoSection } from "./common/user-projects-info-section";

export const UserInfo: FC = () => {
  const aggregatedUser = useDeepRootSelector(selectAggregatedUserForSelectedEntities);
  if (aggregatedUser) {
    return (
      <InfoView infoTitle={getInfoViewTitle(aggregatedUser)}>
        <UserInfoSection user={aggregatedUser} />
        <UserNotificationsInfoSection user={aggregatedUser} />
        <UserProjectsInfoSection user={aggregatedUser} />
      </InfoView>
    );
  }

  return <InfoViewCallToAction infoTitle="Select user" callToActionText="Select an user in the table for more info." />;
};
