import { type FC, type ReactNode } from "react";
import styled from "styled-components";

import { MainTablePaginator } from "../paginator/main-table-paginator";
import { type PaginatorProps } from "../paginator/paginator";
import { DataTable, type DataTableProps } from "./data-table";

const TableWithPaginatorWrapper: FC<{ children: ReactNode }> = ({ children }) => (
  <div className="h-full flex flex-column justify-content-between fadein animation-duration-300">{children}</div>
);

const DataTableWrapper = styled.div`
  height: calc(100vh - 260px);

  .p-datatable > .p-datatable-wrapper > table.p-datatable-table {
    thead.p-datatable-thead {
      background: var(--mainBackgroundColor);

      > tr > th {
        border: none;

        &,
        :hover {
          background: transparent;
        }
      }
    }

    .p-datatable-tbody {
      > tr:not(:last-child) {
        margin-bottom: 5px;
      }
    }
  }
`;

export const TableWithPagination = <T extends Entity>({
  pagination,
  onPageChange,
  children,
  ...rest
}: DataTableProps<T> & PaginatorProps) => (
  <TableWithPaginatorWrapper>
    <DataTableWrapper>
      <DataTable {...rest}>{children}</DataTable>
    </DataTableWrapper>
    <MainTablePaginator pagination={pagination} onPageChange={onPageChange}></MainTablePaginator>
  </TableWithPaginatorWrapper>
);
