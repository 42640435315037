import { isArray, isNumber, isString, orderBy, sortedUniq } from "lodash";

import { EntityTypeName, isAggregatedEntityType } from "@ds/model/entity-model";

import { isPlaylist, isPlaylistArray } from "@ds/modules/content/playlists/utils/model";
import { isDeployment, isDeploymentArray } from "@ds/modules/deployments/utils/model";
import { isPeripheral, isPeripheralArray } from "@ds/modules/devices/peripherals/utils/model";
import { isPlayer, isPlayerArray } from "@ds/modules/devices/players/utils/model";
import { isExperience, isExperienceArray } from "@ds/modules/experiences/utils/model";
import { isLocation, isLocationArray } from "@ds/modules/locations/utils/model";
import { isInvitation, isInvitationArray } from "@ds/modules/settings/invitations/utils/model";
import { isProject, isProjectArray } from "@ds/modules/settings/projects/utils/model";
import { isUser, isUserArray } from "@ds/modules/settings/users/utils/model";

import {
  CONTENT_PLAYLISTS_ROUTE,
  CONTENT_PLAYLIST_DETAILS_ROUTE,
  DEPLOYMENTS_ROUTE,
  DEPLOYMENT_DETAILS_ROUTE,
  DEVICE_PERIPHERALS_ROUTE,
  DEVICE_PERIPHERAL_DETAILS_ROUTE,
  DEVICE_PLAYERS_ROUTE,
  DEVICE_PLAYER_DETAILS_ROUTE,
  EXPERIENCES_ROUTE,
  EXPERIENCE_DETAILS_ROUTE,
  LOCATIONS_ROUTE,
  LOCATION_DETAILS_ROUTE,
  SETTINGS_INVITATIONS_ROUTE,
  SETTINGS_INVITATION_DETAILS_ROUTE,
  SETTINGS_PROJECTS_ROUTE,
  SETTINGS_PROJECT_DETAILS_ROUTE,
  SETTINGS_USERS_ROUTE,
  SETTINGS_USER_DETAILS_ROUTE,
} from "@ds/constants/router";

import { isNumberArray, isStringArray } from "./type-guards/common-guards";

export const composeRoute = (route: string, data: Record<string, string | number>): string =>
  Object.keys(data).reduce((acc, curr) => acc.replace(`:${curr}`, data[curr].toString()), route);

export const composeDetailsRouteByEntityIds = (
  entityIdOrEntitiesIds: number | number[] | string | string[] | undefined,
  typeName: EntityTypeName,
) => {
  if (!entityIdOrEntitiesIds || (isArray(entityIdOrEntitiesIds) && !entityIdOrEntitiesIds.length)) {
    return "";
  }

  let aggregatedRouteQuery = null;

  if (isNumberArray(entityIdOrEntitiesIds)) {
    aggregatedRouteQuery = `?ids=${sortedUniq(orderBy(entityIdOrEntitiesIds.filter(_ => _))).join(",")}`;
  } else if (isStringArray(entityIdOrEntitiesIds)) {
    aggregatedRouteQuery = `?ids=${sortedUniq(orderBy(entityIdOrEntitiesIds.filter(_ => _))).join(",")}`;
  }

  if (isNumber(entityIdOrEntitiesIds) || isNumberArray(entityIdOrEntitiesIds)) {
    if (typeName === EntityTypeName.EXPERIENCE) {
      return isNumberArray(entityIdOrEntitiesIds)
        ? EXPERIENCES_ROUTE + aggregatedRouteQuery
        : composeRoute(EXPERIENCE_DETAILS_ROUTE, { experienceId: entityIdOrEntitiesIds });
    }

    if (typeName === EntityTypeName.DEVICE_PLAYER) {
      return isNumberArray(entityIdOrEntitiesIds)
        ? DEVICE_PLAYERS_ROUTE + aggregatedRouteQuery
        : composeRoute(DEVICE_PLAYER_DETAILS_ROUTE, { playerId: entityIdOrEntitiesIds });
    }

    if (typeName === EntityTypeName.DEVICE_PERIPHERAL) {
      return isNumberArray(entityIdOrEntitiesIds)
        ? DEVICE_PERIPHERALS_ROUTE + aggregatedRouteQuery
        : composeRoute(DEVICE_PERIPHERAL_DETAILS_ROUTE, { peripheralId: entityIdOrEntitiesIds });
    }

    if (typeName === EntityTypeName.LOCATION) {
      return isNumberArray(entityIdOrEntitiesIds)
        ? LOCATIONS_ROUTE + aggregatedRouteQuery
        : composeRoute(LOCATION_DETAILS_ROUTE, { locationId: entityIdOrEntitiesIds });
    }

    if (typeName === EntityTypeName.CONTENT_PLAYLIST) {
      return isNumberArray(entityIdOrEntitiesIds)
        ? CONTENT_PLAYLISTS_ROUTE + aggregatedRouteQuery
        : composeRoute(CONTENT_PLAYLIST_DETAILS_ROUTE, { playlistId: entityIdOrEntitiesIds });
    }

    if (typeName === EntityTypeName.DEPLOYMENT) {
      return isNumberArray(entityIdOrEntitiesIds)
        ? DEPLOYMENTS_ROUTE + aggregatedRouteQuery
        : composeRoute(DEPLOYMENT_DETAILS_ROUTE, { deploymentId: entityIdOrEntitiesIds });
    }

    if (typeName === EntityTypeName.SETTINGS_PROJECT) {
      return isNumberArray(entityIdOrEntitiesIds)
        ? SETTINGS_PROJECTS_ROUTE + aggregatedRouteQuery
        : composeRoute(SETTINGS_PROJECT_DETAILS_ROUTE, { projectId: entityIdOrEntitiesIds });
    }

    if (typeName === EntityTypeName.SETTINGS_USER) {
      return isNumberArray(entityIdOrEntitiesIds)
        ? SETTINGS_USERS_ROUTE + aggregatedRouteQuery
        : composeRoute(SETTINGS_USER_DETAILS_ROUTE, { userId: entityIdOrEntitiesIds });
    }
  } else {
    if (typeName === EntityTypeName.SETTINGS_INVITATION) {
      return isStringArray(entityIdOrEntitiesIds)
        ? SETTINGS_INVITATIONS_ROUTE + aggregatedRouteQuery
        : composeRoute(SETTINGS_INVITATION_DETAILS_ROUTE, { invitationId: entityIdOrEntitiesIds });
    }
  }

  return "";
};

export const composeDetailsRouteByEntityType = <T extends Entity>(
  entityOrEntities: T | T[] | AggregatedEntity<T> | null,
) => {
  if (!entityOrEntities || (isArray(entityOrEntities) && !entityOrEntities.length)) {
    return "";
  }

  let entityIdOrIds = null;
  if (isAggregatedEntityType(entityOrEntities)) {
    entityIdOrIds = entityOrEntities.metadata?.aggregatedIds;
  } else if (isArray(entityOrEntities)) {
    entityIdOrIds = entityOrEntities.filter(_ => _).map(({ id }) => id);
  } else {
    entityIdOrIds = entityOrEntities.id;
  }

  if (isNumber(entityIdOrIds) || isNumberArray(entityIdOrIds)) {
    if (isExperience(entityOrEntities) || isExperienceArray(entityOrEntities)) {
      return composeDetailsRouteByEntityIds(entityIdOrIds, EntityTypeName.EXPERIENCE);
    }

    if (isPlayer(entityOrEntities) || isPlayerArray(entityOrEntities)) {
      return composeDetailsRouteByEntityIds(entityIdOrIds, EntityTypeName.DEVICE_PLAYER);
    }

    if (isPeripheral(entityOrEntities) || isPeripheralArray(entityOrEntities)) {
      return composeDetailsRouteByEntityIds(entityIdOrIds, EntityTypeName.DEVICE_PERIPHERAL);
    }

    if (isLocation(entityOrEntities) || isLocationArray(entityOrEntities)) {
      return composeDetailsRouteByEntityIds(entityIdOrIds, EntityTypeName.LOCATION);
    }

    if (isPlaylist(entityOrEntities) || isPlaylistArray(entityOrEntities)) {
      return composeDetailsRouteByEntityIds(entityIdOrIds, EntityTypeName.CONTENT_PLAYLIST);
    }

    if (isDeployment(entityOrEntities) || isDeploymentArray(entityOrEntities)) {
      return composeDetailsRouteByEntityIds(entityIdOrIds, EntityTypeName.DEPLOYMENT);
    }

    if (isProject(entityOrEntities) || isProjectArray(entityOrEntities)) {
      return composeDetailsRouteByEntityIds(entityIdOrIds, EntityTypeName.SETTINGS_PROJECT);
    }

    if (isUser(entityOrEntities) || isUserArray(entityOrEntities)) {
      return composeDetailsRouteByEntityIds(entityIdOrIds, EntityTypeName.SETTINGS_USER);
    }
  }

  if (isString(entityIdOrIds) || isStringArray(entityIdOrIds)) {
    if (isInvitation(entityOrEntities) || isInvitationArray(entityOrEntities)) {
      return composeDetailsRouteByEntityIds(entityIdOrIds, EntityTypeName.SETTINGS_INVITATION);
    }
  }

  return "";
};

export const getCurrentRootPath = (): string => `/${window.location.pathname.split("/")[1]}`;
