import * as yup from "yup";

const usernameShape = {
  username: yup.string().email("Invalid email format").required("Specify email address"),
};

export const signInValidationSchema = yup.object().shape({
  ...usernameShape,
  password: yup.string().required("Specify password"),
});

export const signUpValidationSchema = yup.object().shape({
  given_name: yup.string().required("Specify first name"),
  family_name: yup.string().required("Specify last name"),
  password: yup.string().required("Specify password"),
  confirmPassword: yup.string().oneOf([yup.ref("password"), undefined], "Passwords do not match"),
});

export const forgotPasswordValidationSchema = yup.object().shape({
  ...usernameShape,
});

export const restorePasswordValidationSchema = signInValidationSchema.shape({
  code: yup.string().required("Specify restore code"),
});
